import React, { useState, useEffect, useContext } from 'react';
import { ArrowUpRight, ArrowDownRight } from 'lucide-react';
import { getStocksData } from '../../api/be-client';
import './StocksTable.css';
import searchIcon from '../../assets/searchIcon.png';
import appl_image from '../../assets/symbolImages/APPL.jpg';
import nflx_image from '../../assets/symbolImages/NFLX.png';
import tsla_image from '../../assets/symbolImages/TSLA.png';
import pg_image from '../../assets/symbolImages/PG.png';
import NVDA_image from '../../assets/symbolImages/NVDA.png';
import AMZN_image from '../../assets/symbolImages/AMZN.png';
import BAC_image from '../../assets/symbolImages/BAC.jpeg';
import BRK_K_image from '../../assets/symbolImages/BRK-K.png';
import CVX_image from '../../assets/symbolImages/CVX.png';
import GOOGL_image from '../../assets/symbolImages/GOOGL.jpeg';
import DIS_image from '../../assets/symbolImages/DIS.jpeg';
import JPM_image from '../../assets/symbolImages/JPM.png';
import MA_image from '../../assets/symbolImages/MA.jpeg';
import META_image from '../../assets/symbolImages/META.png';
import MSFT_image from '../../assets/symbolImages/MSFT.jpeg';
import PYPL_image from '../../assets/symbolImages/PYPL.png';
import UNH_image from '../../assets/symbolImages/UNH.jpeg';
import V_image from '../../assets/symbolImages/V.png';
import WMT_image from '../../assets/symbolImages/WMT.jpeg';
import XON_image from '../../assets/symbolImages/XON.jpeg';
import { appContext } from '../../App';



const stockSymbol = {
  AAPL: appl_image,
  NFLX: nflx_image,
  TSLA: tsla_image,
  PG: pg_image,
  NVDA: NVDA_image,
  AMZN: AMZN_image,
  BAC: BAC_image,
  'BRK-K': BRK_K_image,
  CVX: CVX_image,
  GOOG: GOOGL_image,
  DIS: DIS_image,
  JPM: JPM_image,
  MA: MA_image,
  META: META_image,
  MSFT: MSFT_image,
  PYPL: PYPL_image,
  UNH: UNH_image,
  V: V_image,
  WMT: WMT_image,
  XOM: XON_image,
};

const StocksTable = ({ onStockClick, headerString, dataType }) => {
  const [allStockData, setAllStockData] = useState([]);
  const [displayedStocks, setDisplayedStocks] = useState([]);

  // onChange={()=>setShowSearchModal(true)}
  const { setShowSearchModal } = useContext(appContext)

  // Function to get 8 random stocks from the full dataset
  const getRandomStocks = (stocks) => {

    const shuffled = [...stocks].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 8);
  };

  const fetchStockData = async () => {
    try {
      const response = await getStocksData();

      if (response && response.tickers) {
        const formattedData = response.tickers.map(ticker => {
          const { day, prevDay } = ticker;

          // Check if current day data is valid
          const hasCurrentData = Object.values(day).some(value => value !== 0);

          const dataToUse = hasCurrentData ? day : prevDay;

          return {
            symbol: ticker.ticker,
            volume: dataToUse.v.toLocaleString(),
            price: dataToUse.c.toFixed(2),
            change: hasCurrentData ? ticker.todaysChangePerc.toFixed(2) : null, // Consider handling no-change scenario
          };
        });
        setAllStockData(formattedData);
      }
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };

  // Effect for initial data fetch
  useEffect(() => {
    fetchStockData();
  }, []);

  // Effect for setting up rotation and handling displayed stocks
  useEffect(() => {
    if (allStockData.length > 0) {
      setDisplayedStocks(getRandomStocks(allStockData));
      const rotateInterval = setInterval(() => {
        setDisplayedStocks(getRandomStocks(allStockData));
      }, 10000); // 10 sec

      return () => clearInterval(rotateInterval);
    }
  }, [allStockData]);

  return (
    <div className="stocks-table-container">
      <div className='top-stocks-header'>

        <span className='table-header-title'>
          {headerString}
        </span>
        <br className='only-mobile' />        
        <input
          className="table-search"
          onChange={() => setShowSearchModal(true)}
          placeholder="🔍 Search for a stock..."
        />
      </div>
      <hr />
      <table className="stocks-table">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Volume</th>
            <th>Price</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>
          {displayedStocks.map((stock) => (
            <tr key={stock.symbol}>

              <td className='table-stocks-symbol stock-symbol' onClick={() => { onStockClick(stock.symbol) }}
              >                
                <img className='symbol-image' src={stockSymbol[stock.symbol]} />
                <span> {stock.symbol} </span>
              </td>
              <td>${stock.volume}</td>
              <td className="stock-price">${stock.price}</td>
              <td className={`stock-change ${Math.random() >= 0.5 ? 'positive' : 'negative'}`}>
                {Math.random() >= 0.5 ? (
                  <ArrowUpRight className="change-icon" size={16} />
                ) : (
                  <ArrowDownRight className="change-icon" size={16} />
                )}
                {stock.change}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StocksTable;
