import React, { useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { sendRequest } from '../../api/be-client';
import './pp.css';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

export const Checkout = ({ selectedStock, setShowModal }) => {
    const [{ isPending }] = usePayPalScriptReducer();
    const [showIntro, setShowIntro] = useState(true)

    const nav = useNavigate()
    const onCreateOrder = (data, actions) => {
        // alert("Demo")
        // nav("/post-payment");
        // return;
        setShowIntro(false)


        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: "0.1",
                    },
                },
            ],
        });
    };

    const onApproveOrder = (data, actions) => {
        setShowIntro(true);
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
            const res = await sendRequest(selectedStock.symbol);
            if (res) {
                toast.success("Done");
                setShowModal(false);
                nav("/post-payment");
            } else {
                alert("Error 995. please retry latter");
            }
        });
    };

    return (
        <div className="checkout">
            {isPending ? <p className="loading-text">LOADING...</p> : (
                <div className="paypal-buttons-container">
                    {showIntro && <div>
                        <p>
                            To receive a comprehensive stock analysis, please proceed with a
                            payment of $19.90. We guarantee that you will receive your analysis
                            within 24 hours. The analysis will be delivered directly to your email.
                        </p>
                        <p>
                            Our stock analysis includes detailed technical evaluations, focusing on
                            key aspects such as price trends, market indicators, and chart patterns.
                            This ensures you gain valuable insights into potential investment
                            opportunities. We are committed to providing you with trustworthy

                            guidance to help you make informed decisions.
                        </p>
                    </div>}

                    <PayPalButtons
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                    />
                </div>
            )}
        </div>
    );
};
