import React, { useContext } from 'react';
import "./Home.css";
import StocksTable from '../stocksTable/StocksTable';
import videoSrc from '../../assets/home-1.mp4';
import home2image from '../../assets/home-2-image.png';
import home2imageMobile from '../../assets/home-2-image-mobile.png';
import CryptoTable from '../CryptoTable/CryptoTable';
import { appContext } from '../../App';
import { stocksData } from '../../assets/stockData';
import { useNavigate } from 'react-router-dom';


const Home = () => {
    const nav = useNavigate();
    const { setSelectedStock, setShowPPmodal } = useContext(appContext);
    const onStockClick = (symbol) => {
        const stock = Object.values(stocksData).find(stock =>
            stock.symbol.toLowerCase() === symbol.toLowerCase()
        );

        if (stock) {
            setSelectedStock(stock);
            setShowPPmodal(true);
        } else {
            console.error("Stock not found");
            // Optionally, you can handle the case where the stock is not found
        }
    };
    return (
        <div id='home-container'>
            <div id='home-1-container'>
                <video autoPlay loop muted id='home-1-video'>
                    <source src={videoSrc} type="video/mp4" />
                </video>


                <h1 id='home-1-text-container'>
                    The leading platform for stocks analysis
                    <span className='home-1-inner-text' style={{ fontSize: '35px' }}> Quick, professional, and reliable </span>
                    <div className='home-1-btns-container'>
                        <button onClick={() => { nav("/login") }}>Create free account</button>
                        <button onClick={() => { nav("/how-it-works") }} className='home-1-btn-2'>Learn more</button>
                    </div>
                </h1>

            </div>
            <div id='home-2-container'>
                <img id='home-2-image' className='only-pc' src={home2image}></img>
                <img id='home-2-image' className='only-mobile' src={home2imageMobile}></img>
                <div id='home-2-right-container'>
                    <h2 id='home2-header'> BUY OR SELL <br />
                        Is the best choice for investors </h2>
                    <p id='home-2-text'>
                        At BUYORSELL, we understand how critical it is to make informed decisions in both the stock and cryptocurrency markets. That's why we specialize in connecting traders with top-tier analysts who deliver reliable, actionable insights tailored to your trading needs. Our unique approach ensures the expertise of seasoned professionals is accessible to anyone seeking clear, data-driven guidance.
                        <br />
                        You can choose the analysis approach that suits your needs:
                        <br />
                        <ul>
                            <li>
                                Technical Analysis focuses on identifying key trends, chart patterns, as well as support and resistance levels.
                            </li>
                            <li>
                                Fundamental Analysis dives deep into company financials, industry benchmarks, and growth potential, offering a broader understanding of your investment.
                            </li>
                            <li>
                                AI-Powered Analysis use artificial intelligence to analyze large amounts of data, identify hidden patterns, and provide accurate, real-time insights. This option combines cutting-edge technology with expert knowledge to give you a fresh perspective on the markets.
                            </li>
                        </ul>                        
                        
                        Simply pick the approach that aligns with your goals, and our team will provide you with the insights you need for a clearer market perspective.
                        <br />
                        <br />
                        We carefully select analysts with a proven track record of success, ensuring every analysis meets the highest standards of accuracy and quality. By bridging the gap between traders and experts, we empower our users to navigate the markets with confidence and clarity.</p>
                    <button onClick={() => { nav("/how-it-works") }}> Read more </button>
                </div>

            </div>
            <div id='home-3-container'>
                <StocksTable headerString={"Top Stocks"} onStockClick={onStockClick} />
                <StocksTable headerString={"U.S. Stocks"} onStockClick={onStockClick} />
            </div>
            <div id='home-4-container'>
                <CryptoTable onCryptoClick={onStockClick} />
            </div>
        </div>
    )
};

export default Home;