import React from 'react'
import image1 from "../../../assets/adv1Image.png";
import image2 from "../../../assets/adv2Image.png";
import image3 from "../../../assets/adv3Image.png";
import image4 from "../../../assets/adv4Image.png";


import './Advantages.css'
const Advantages = () => {
    return (
        <>
        <div>
            <h3 className='adv-title'> Our Advantages</h3>
            <p className='adv-text'> At BUYORSELL, we aim to give traders the edge they need in a
                <br />
                fast-paced market. Here’s what sets us apart:
            </p>
            <div className='adv-inner-container'>

                <div className='adv'>
                    <img src={image1} alt="" />
                    <h4 className='adv-inner-title'> Save Time </h4>
                    <p className='adv-inner-text'>
                        Receive detailed analysis reports within 12 hours, enabling you to make timely and well-informed decisions without unnecessary delays
                    </p>
                </div>
                <div className='adv'>
                    <img src={image2} alt="" />
                    <h4 className='adv-inner-title'> Qualified analysis </h4>
                    <p className='adv-inner-text'>
                    Work with analysts who have over 10 years of expertise in the stock market and cryptocurrency trading. Every report is based on accurate and reliable insights
                    </p>
                </div>
                <div className='adv'>
                    <img src={image3} alt="" />
                    <h4 className='adv-inner-title'> Tailored Insights </h4>
                    <p className='adv-inner-text'>
                    Each report is customized to address your specific needs, whether you’re trading stocks or exploring cryptocurrency opportunities
                    </p>
                </div>
                <div className='adv'>
                    <img src={image4} alt="" />
                    <h4 className='adv-inner-title'> Actionable Recommendations </h4>
                    <p className='adv-inner-text'>
                    Gain clear, concise insights designed to support your next step. Our recommendations not only inform but also guide your investment strategies, helping you navigate the market with clarity and confidence
                    </p>
                </div>                
            </div>

        </div>
        </>
    )
}

export default Advantages