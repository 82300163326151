import React, { useContext } from 'react'
import "./BlueTA.css"
import { useLocation } from 'react-router-dom'
import BlueGif from '../../assets/GIF_ARROW.svg';
import { appContext } from '../../App';

const BlueTA = ({userLogged}) => {
  const loc = useLocation();    

  if (loc.pathname.includes("login") || userLogged) {
    return <></>
  }

  return (
    <div className='blue-ta-container' >
      <div className='bta-text-container'>
        <h3 className='bta-text1'>
          READY TO GET STARTED?
          <br />
        </h3>
        <h4 className='bta-text2'>
          Log in now and get exclusive access
        </h4>
      </div>
      <div className='bta-arrows-container'>        
        <img src={BlueGif} alt="SVG" className='only-pc'/>
      </div>
      <button className='bta-btn'> Create free account ➡</button>
    </div>
  )
}

export default BlueTA