import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SiteFooter.css';
import bosLogo from "../../assets/logo.png";
import social_1 from "../../assets/social_1.png";
import social_2 from "../../assets/social_2.png";
import toast from 'react-hot-toast';


const SiteFooter = () => {
  const nav = useNavigate();
  return <div className='footer-container'>
    <div className='footer-up'>
      <div className='footer-logo-container'>
        <img className='footer-logo' onClick={() => {
          nav("/home");
        }} src={bosLogo} alt="" />
        <br />
        <p className='footer-log-text'>
          Deciding whether to buy or sell stocks or crypto? <br />
          BUYORSELL connects you with expert analysts, <br />
          and cutting-edge AI tools, delivering reliable technical, <br />
          fundamental, or AI-driven insights to guide your <br />
          decisions in real time guide your decisions.
        </p>
      </div>
      <div className='footer-links'>

        <ul>
          <li className='footer-li-title'> Quick links </li>

          <li onClick={() => { nav("/home") }}> Home </li>
          <li onClick={() => { nav("/about") }}> About </li>
          <li onClick={() => { nav("/how-it-works") }}> How it Works </li>
          <li onClick={() => { nav("/contact") }}> Contact </li>
          <li onClick={() => { nav("/FAQ") }}> FAQ </li>
          <li onClick={() => { nav("/careers") }}> Careers </li>
        </ul>
        <ul>
          <li className='footer-li-title'> Legal </li>

          <li onClick={() => { nav("/terms-of-use") }}> Terms of Service </li>
          <li onClick={() => { nav("/terms-of-use") }}> Privacy Policy </li>
          <li onClick={() => { nav("/how-it-works") }}> How it Works </li>
          <li onClick={() => { toast("Coming Soon..") }}> API Documentation </li>
        </ul>
        <ul>
          <li className='footer-li-title'> Contact Us </li>

          <li> Email: support@buyorsell.com </li>
          <li> WhatsApp: +972 73786 4932 </li>
          <a> Social </a>
          <div className='footer-social'>
            <img src={social_1} alt="" />
            <img src={social_2} alt="" />

          </div>
        </ul>
      </div>
    </div>
    <hr ></hr>
    <div className='footer-bottom'>
      © 2024 BUYORSELL. All rights reserved.
    </div>
  </div>

};

export default SiteFooter;