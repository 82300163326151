import React from 'react';
import './HowItWorks.css';
import image2 from "../../assets/hiw2Image_.PNG";
import image3 from "../../assets/hiw3Image.png";
import BlueTA from '../blueTA/BlueTA';

const HowItWorks = () => {
  return (
    <div className='hiw-container'>
      <div className='hiw-1-container'>
        <h1 className='about-1-text'>HOW IT WORKS</h1>
      </div>
      <div className='hiw-2-container'>
        <h3 className='hiw-2-title'>Understand the process, <br />
          empower your descision</h3>
        <p className='hiw-2-text'>
          Unlock the power of expert-driven stock analysis by following
          these simple and <br/>straightforward steps. Our seamless process
          ensures you receive reliable, in-<br/>depth insights tailored to
          your investment goals, all in no time.
        </p>
      </div>
      <div className='hiw-3-image-container'>
        <img className='hiw-3-pc only-pc' src={image2} alt="" />
        <img className='about-6-img only-mobile' src={image3} alt="" />
      </div>
            
    </div>
  )  
};

export default HowItWorks;
