import React from 'react';
import './TermsOfUse.css';

const TermsOfUse = () => {
  return (
    <div id="tos-container">
      <div id="tos-header-container">
        <h1 id="tos-header">Terms of Use</h1>
      </div>
      <div id="tos-content-container">
        <p className="tos-text">
          Welcome to BUYORSELL. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions.
        </p>
        <h2 className="tos-subheader">1. Acceptance of Terms</h2>
        <p className="tos-text">
          By using BUYORSELL, you agree to these Terms of Use and our Privacy Policy. If you do not agree, please do not use our services.
        </p>
        <h2 className="tos-subheader">2. Services Provided</h2>
        <p className="tos-text">
          BUYORSELL provides stock and cryptocurrency analysis through technical and fundamental approaches. Our analysts deliver insights tailored to your needs.
        </p>
        <h2 className="tos-subheader">3. User Responsibilities</h2>
        <p className="tos-text">
          You are responsible for ensuring the accuracy of the information you provide and for complying with all applicable laws and regulations.
        </p>
        <h2 className="tos-subheader">4. Limitation of Liability</h2>
        <p className="tos-text">
          BUYORSELL is not liable for any losses or damages arising from the use of our services. All investments carry risk, and you should consult a financial advisor before making decisions.
        </p>
        <h2 className="tos-subheader">5. Modifications</h2>
        <p className="tos-text">
          We reserve the right to modify these terms at any time. Continued use of our services constitutes acceptance of the updated terms.
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;