import { createContext, useEffect, useState } from "react";
import SiteRoutes from "./SiteRoutes";
import { isTokenValid } from "./api/authApi";
import SiteHeader from "./components/siteHeader/SiteHeader";
import SiteFooter from "./components/siteFooter/SiteFooter";
import { useNavigate } from "react-router-dom";
import BlueTA from "./components/blueTA/BlueTA"
import { Toaster } from "react-hot-toast";
import SearchModal from "./components/searchModal/SearchModal";
import PaypalArea from "./components/paypalArea/PaypalArea";

export const appContext = createContext(null);

function App() {
  const [userLogged, setUserLogged] = useState(false);
  const [userName, setUserName] = useState("");
  const [selectedStock, setSelectedStock] = useState({})
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showPPmodal, setShowPPmodal] = useState(false)


  const nav = useNavigate();

  useEffect(() => {
    // check if user "already connected" - if token exists in localStorage
    const token = localStorage.getItem("bos-token");
    if (token) {

      isTokenValid(token).then((res) => {
        if (res) {
          setUserLogged(true);
          setUserName(localStorage.getItem("bos-username"));
          nav("/home")
        } else {
          localStorage.removeItem("token");
        }
      })
    }
  }, []);

  return (
    <div>      
      <Toaster position="top-center" />
      <div id="site-wrapper">
        <appContext.Provider
          value={{ userLogged, setUserLogged, userName, setUserName, setShowSearchModal, setShowPPmodal, setSelectedStock}}
        >

          <SiteHeader setShowSearchModal={setShowSearchModal}/>
          <div id="site-routes-wrapper">
            <SiteRoutes />
          </div>
        </appContext.Provider>
        <br />
        <br />
      </div>
      <SearchModal showSearchModal={showSearchModal} setShowSearchModal={setShowSearchModal} setSelectedStock={setSelectedStock} setShowPPmodal={setShowPPmodal} />
      <PaypalArea showModal={showPPmodal} setShowModal={setShowPPmodal} selectedStock={selectedStock} userLogged={ userLogged} />
      <BlueTA userLogged={ userLogged} />
      <div>
        <SiteFooter />
      </div>
    </div>
  );
}

export default App;