import React from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
import HowItWorks from '../howItWorks/HowItWorks';
// import image1 from "../../assets/aboutTitleImage.jpg";
import image3 from "../../assets/about3Image.jpg";
import image5 from "../../assets/about5Image.jpg";
import image6 from "../../assets/aboutHowItWorksImage_.PNG";

import image7 from "../../assets/aboutHowItWorksMobileImage.PNG";
import Advantages from './Advantages/Advantages';

const About = () => {

  return (
    <div className="about-container">

      <div className="about-title-image-container">
        <h1 className='about-1-text'>About Us</h1>
      </div>

      <div className='about-2-container'>
        <h2 className='about-2-title'> Why choose BUY OR SELL? </h2>
        <p className='about-2-text only-pc'>
          At BUYORSELL, we understand how critical it is to make informed decisions in both the stock and cryptocurrency markets. That's why we offer three specialized approaches—technical, fundamental, and AI-powered analysis—to provide traders with reliable, actionable insights tailored to their needs.
          <br />
          <br />
          Our unique approach ensures the expertise of seasoned professionals is accessible to anyone seeking clear, data-driven guidance. For those interested in technical analysis, we focus on identifying key trends, chart patterns, and support and resistance levels. Meanwhile, our fundamental analysis dives deep into company financials, industry benchmarks, and overall market dynamics to help traders understand the bigger picture. Additionally, our AI-powered analysis leverages advanced technology to process vast amounts of data, uncover hidden patterns, and deliver real-time, data-driven insights.
          <br />
          <br />

          We carefully select analysts with a proven track record of success, ensuring every analysis meets the highest standards of accuracy and quality. By bridging the gap between traders, experts, and cutting-edge AI, we empower our users to navigate the markets with confidence and clarity.
        </p>
        <p className='about-2-text only-mobile'>
          Are you considering whether you should buy or sell Stocks? Get a Technical Analysis Before Every Decision! In the stock market,
          making informed decisions is the key to success. But how would you know if it’s the right time to buy or sell a stock?
          That’s where BUYORSELL comes in – a platform that connects traders with professional technical analysts, providing real-time,
          reliable graphical and technical analyses for any chosen stock in the U.S. stock market.

        </p>
      </div>


      <div className='about-3-container'>
        <img className='about-3-img' src={image3} alt="" />

        <div className='about-3-right only-pc'>
          <p className='about-inner-title'> Our Services </p>
          <p className='about-3-txt'>
            We offer technical, fundamental, and AI-powered stock analysis. On our platform, you can search for any U.S. market stock or cryptocurrency and receive a detailed analysis within 12 hours, helping you understand market trends, gain valuable insights, and make informed investment decisions.
            <br />
            <br />
            We are the premier platform connecting you with top industry experts and advanced technology, providing an extensive range of analytics. For technical analysis, this includes trendlines, support and resistance levels, moving averages, chart patterns, and more. For fundamental analysis, we focus on company financials, industry benchmarks, and key market dynamics. For AI-powered analysis, we use artificial intelligence to identify patterns and provide real-time insights, giving traders a technological edge.
          </p>
        </div>
      </div>


      <div className='only-mobile'>
        <p className='about-inner-title'> Our Services </p>
        <p className='about-3-txt' style={{ maxWidth: '90%', margin: '8% 4%' }}>
          We offer technical, fundamental, and AI-powered stock analysis. On our platform, you can search for any U.S. market stock or cryptocurrency and receive a detailed analysis within 12 hours, helping you understand market trends, gain valuable insights, and make informed investment decisions.
          <br />
          <br />
          We are the premier platform connecting you with top industry experts and advanced technology, providing an extensive range of analytics. For technical analysis, this includes trendlines, support and resistance levels, moving averages, chart patterns, and more. For fundamental analysis, we focus on company financials, industry benchmarks, and key market dynamics. For AI-powered analysis, we use artificial intelligence to identify patterns and provide real-time insights, giving traders a technological edge.
        </p>
      </div>

      <div className='about-4-container'>
        <Advantages />
      </div>

      <div className='about-5-container'>
        <div>
          <p className='about-inner-title'> Our Experts </p>
          <p className='about-5-inner-text'>
            At BUYORSELL, we work with experienced analysts, each with over 10 years of expertise in technical or fundamental analysis.
            <br />
            <br />
            Their proficiency in market trends, chart patterns, and company financials ensures you receive accurate, actionable insights to guide your investment choices with confidence.
            <br />
            With AI-driven capabilities integrated into our services, we offer a seamless combination of expertise and technology to maximize the value of your analysis.
            <br />
            <br />
            Choose the preferred method and collaborate with top analysts for a detailed report
          </p>
        </div>
        <img className='about-3-img only-pc' src={image5} alt="" />
      </div>

      <div className='about-6-container'>
        <img className='about-6-img only-pc' src={image6} alt="" />
        <img className='about-6-img only-mobile' src={image7} alt="" />
      </div>

    </div>
  );
};

export default About;
