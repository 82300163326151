import React from 'react';
import './Careers.css';
import { useNavigate } from 'react-router-dom';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const JobCard = ({ title, requirements }) => (
  <div className="job-card">
    <div className='job-card-title-container'>
      <h2 className='job-card-title'>{title} <RocketLaunchIcon /></h2>
      <button className='job-card-button'> Apply for this position </button>
    </div>
    <div className='job-req-container'>
      {requirements.map((req, index) => (
        <p className='job-req' key={index}>{req}</p>
      ))}
    </div>
  </div>
);

const Careers = () => {
  const nav = useNavigate();

  return <>
    <div className='careers-1-container'>
      <h1 className='careers-1-text'>CAREERS</h1>
    </div>

    <JobCard
      title="Product Manager (Finance Background)"
      requirements={[
        "Strong experience in financial markets and product management",
        "5+ years of experience in a leadership role",
        "Excellent communication and team collaboration skills",
        "Experience working closely with developers and clients"
      ]}
    />

    <JobCard
      title="AI/ML Engineer"
      requirements={[
        "Expertise in machine learning algorithms and financial modeling",
        "Proficiency in Python, TensorFlow, and SQL",
        "Experience with time series analysis and predictive modeling",
        "3+ years of experience in quantitative finance or related field"
      ]}
    />
  </>
};

export default Careers;