import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Checkout } from "./Checkout";
import { Modal } from "react-bootstrap";
import './pp.css';
import { useState } from "react";



const initialOptions = {
  "client-id": "AZXNCIOs88aDB_ODMRh7ZIwZ3AGZ6TuVBUQLtoFQV0QfUUm8FGiaboOuwgZM86UX_qCccXSKIeEvpQpO",
  currency: "USD",
  intent: "capture",
};

export default function PaypalArea({ showModal, setShowModal, selectedStock, userLogged }) {
  const [showCheckout, setShowCheckout] = useState(false);
    

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <div className="pp-close-btn-container">
        <button className="pp-close-btn"
          onClick={() => {
            if (!showCheckout) 
              setShowModal(false)
            else 
              setShowCheckout(false)
          }}>X</button></div>
      <div className="modal-content">
        {showCheckout && <PayPalScriptProvider options={initialOptions}>
          <Checkout selectedStock={selectedStock} setShowModal={setShowModal} />
        </PayPalScriptProvider>}

        {!showCheckout && (
          <div className="pre-checkout-container">            
            <h2 className="pre-checkout-title">{selectedStock.symbol} </h2>
            <h2 className="pre-checkout-title">{selectedStock.name} </h2>
            {selectedStock.country !== "" && <p className="pre-checkout-detail"><strong>Country:</strong> {selectedStock.country}</p>}
            {selectedStock.sector !== "" && <p className="pre-checkout-detail"><strong>Sector:</strong> {selectedStock.sector}</p>}
            {selectedStock.industry !== "" && <p className="pre-checkout-detail"><strong>Industry:</strong> {selectedStock.industry}</p>}
            <button className="pre-checkout-button" disabled={!userLogged}
              onClick={() => setShowCheckout(true)}>Get Analysis</button>
              {!userLogged && <p id="pre-checkout-non-register-msg"> You must be logged in to request analysis</p>}
          </div>
        )}
      </div>
    </Modal>
  );
}
