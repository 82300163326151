export const stocksData = {
    "AACG": {
        "symbol": "AACG",
        "name": "ATA Creativity Global American Depositary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "AADI": {
        "symbol": "AADI",
        "name": "Aadi Bioscience Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AAL": {
        "symbol": "AAL",
        "name": "American Airlines Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "AAME": {
        "symbol": "AAME",
        "name": "Atlantic American Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "AAOI": {
        "symbol": "AAOI",
        "name": "Applied Optoelectronics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AAON": {
        "symbol": "AAON",
        "name": "AAON Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "AAPL": {
        "symbol": "AAPL",
        "name": "Apple Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Manufacturing"
    },
    "ABAT": {
        "symbol": "ABAT",
        "name": "American Battery Technology Company Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "ABCL": {
        "symbol": "ABCL",
        "name": "AbCellera Biologics Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Pharmaceuticals and Biotechnology"
    },
    "ABEO": {
        "symbol": "ABEO",
        "name": "Abeona Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ABL": {
        "symbol": "ABL",
        "name": "Abacus Life Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "ABLLL": {
        "symbol": "ABLLL",
        "name": "Abacus Life Inc. 9.875% Fixed Rate Senior Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "ABLLW": {
        "symbol": "ABLLW",
        "name": "Abacus Life Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "ABLV": {
        "symbol": "ABLV",
        "name": "Able View Global Inc. Class B Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "ABNB": {
        "symbol": "ABNB",
        "name": "Airbnb Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Diversified Commercial Services"
    },
    "ABOS": {
        "symbol": "ABOS",
        "name": "Acumen Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ABSI": {
        "symbol": "ABSI",
        "name": "Absci Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "ABTS": {
        "symbol": "ABTS",
        "name": "Abits Group Inc Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "ABUS": {
        "symbol": "ABUS",
        "name": "Arbutus Biopharma Corporation Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ABVC": {
        "symbol": "ABVC",
        "name": "ABVC BioPharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ABVE": {
        "symbol": "ABVE",
        "name": "Above Food Ingredients Inc. Common Stock",
        "country": "Canada",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "ABVEW": {
        "symbol": "ABVEW",
        "name": "Above Food Ingredients Inc. Warrants",
        "country": "Canada",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "ABVX": {
        "symbol": "ABVX",
        "name": "Abivax SA American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACAB": {
        "symbol": "ACAB",
        "name": "Atlantic Coastal Acquisition Corp. II Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ACABU": {
        "symbol": "ACABU",
        "name": "Atlantic Coastal Acquisition Corp. II Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ACAD": {
        "symbol": "ACAD",
        "name": "ACADIA Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACB": {
        "symbol": "ACB",
        "name": "Aurora Cannabis Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "ACCD": {
        "symbol": "ACCD",
        "name": "Accolade Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ACDC": {
        "symbol": "ACDC",
        "name": "ProFrac Holding Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oilfield Services/Equipment"
    },
    "ACET": {
        "symbol": "ACET",
        "name": "Adicet Bio Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACGL": {
        "symbol": "ACGL",
        "name": "Arch Capital Group Ltd. Common Stock",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ACGLN": {
        "symbol": "ACGLN",
        "name": "Arch Capital Group Ltd. Depositary Shares each Representing a 1/1000th Interest in a 4.550% Non-Cumulative Preferred Share Series G",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ACGLO": {
        "symbol": "ACGLO",
        "name": "Arch Capital Group Ltd. Depositary Shares Each Representing 1/1000th Interest in a Share of 5.45% Non-Cumulative Preferred Shares Series F",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ACHC": {
        "symbol": "ACHC",
        "name": "Acadia Healthcare Company Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ACHL": {
        "symbol": "ACHL",
        "name": "Achilles Therapeutics plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ACHV": {
        "symbol": "ACHV",
        "name": "Achieve Life Sciences Inc. Common Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "ACIC": {
        "symbol": "ACIC",
        "name": "American Coastal Insurance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ACIU": {
        "symbol": "ACIU",
        "name": "AC Immune SA Common Stock",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACIW": {
        "symbol": "ACIW",
        "name": "ACI Worldwide Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ACLS": {
        "symbol": "ACLS",
        "name": "Axcelis Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ACLX": {
        "symbol": "ACLX",
        "name": "Arcellx Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ACMR": {
        "symbol": "ACMR",
        "name": "ACM Research Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ACNB": {
        "symbol": "ACNB",
        "name": "ACNB Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ACNT": {
        "symbol": "ACNT",
        "name": "Ascent Industries Co. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "ACON": {
        "symbol": "ACON",
        "name": "Aclarion Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ACONW": {
        "symbol": "ACONW",
        "name": "Aclarion Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ACRS": {
        "symbol": "ACRS",
        "name": "Aclaris Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACRV": {
        "symbol": "ACRV",
        "name": "Acrivon Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACST": {
        "symbol": "ACST",
        "name": "Acasti Pharma Inc. Class A Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACT": {
        "symbol": "ACT",
        "name": "Enact Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "ACTG": {
        "symbol": "ACTG",
        "name": "Acacia Research Corporation (Acacia Tech) Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "ACTU": {
        "symbol": "ACTU",
        "name": "Actuate Therapeutics Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ACVA": {
        "symbol": "ACVA",
        "name": "ACV Auctions Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ACXP": {
        "symbol": "ACXP",
        "name": "Acurx Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ADAG": {
        "symbol": "ADAG",
        "name": "Adagene Inc. American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ADAP": {
        "symbol": "ADAP",
        "name": "Adaptimmune Therapeutics plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ADBE": {
        "symbol": "ADBE",
        "name": "Adobe Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ADD": {
        "symbol": "ADD",
        "name": "Color Star Technology Co. Ltd. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "ADEA": {
        "symbol": "ADEA",
        "name": "Adeia Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ADGM": {
        "symbol": "ADGM",
        "name": "Adagio Medical Holdings Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ADI": {
        "symbol": "ADI",
        "name": "Analog Devices Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ADIL": {
        "symbol": "ADIL",
        "name": "Adial Pharmaceuticals Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ADMA": {
        "symbol": "ADMA",
        "name": "ADMA Biologics Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ADN": {
        "symbol": "ADN",
        "name": "Advent Technologies Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ADNWW": {
        "symbol": "ADNWW",
        "name": "Advent Technologies Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ADP": {
        "symbol": "ADP",
        "name": "Automatic Data Processing Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ADPT": {
        "symbol": "ADPT",
        "name": "Adaptive Biotechnologies Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ADSE": {
        "symbol": "ADSE",
        "name": "ADS-TEC ENERGY PLC Ordinary Shares",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "ADSEW": {
        "symbol": "ADSEW",
        "name": "ADS-TEC ENERGY PLC Warrant",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "ADSK": {
        "symbol": "ADSK",
        "name": "Autodesk Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ADTN": {
        "symbol": "ADTN",
        "name": "ADTRAN Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "ADTX": {
        "symbol": "ADTX",
        "name": "Aditxt Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ADUS": {
        "symbol": "ADUS",
        "name": "Addus HomeCare Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "ADV": {
        "symbol": "ADV",
        "name": "Advantage Solutions Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ADVM": {
        "symbol": "ADVM",
        "name": "Adverum Biotechnologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ADVWW": {
        "symbol": "ADVWW",
        "name": "Advantage Solutions Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ADXN": {
        "symbol": "ADXN",
        "name": "Addex Therapeutics Ltd American Depositary Shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AEAE": {
        "symbol": "AEAE",
        "name": "AltEnergy Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "AEAEW": {
        "symbol": "AEAEW",
        "name": "AltEnergy Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "AEHL": {
        "symbol": "AEHL",
        "name": "Antelope Enterprise Holdings Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Building Materials"
    },
    "AEHR": {
        "symbol": "AEHR",
        "name": "Aehr Test Systems Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "AEI": {
        "symbol": "AEI",
        "name": "Alset Inc. Common Stock (TX)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "AEIS": {
        "symbol": "AEIS",
        "name": "Advanced Energy Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "AEMD": {
        "symbol": "AEMD",
        "name": "Aethlon Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "AENT": {
        "symbol": "AENT",
        "name": "Alliance Entertainment Holding Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Durable Goods"
    },
    "AENTW": {
        "symbol": "AENTW",
        "name": "Alliance Entertainment Holding Corporation Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Durable Goods"
    },
    "AEP": {
        "symbol": "AEP",
        "name": "American Electric Power Company Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "AERT": {
        "symbol": "AERT",
        "name": "Aeries Technology Inc. Class A Ordinary Share",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "AERTW": {
        "symbol": "AERTW",
        "name": "Aeries Technology Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "AEYE": {
        "symbol": "AEYE",
        "name": "AudioEye Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AFBI": {
        "symbol": "AFBI",
        "name": "Affinity Bancshares Inc. Common Stock (MD)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "AFCG": {
        "symbol": "AFCG",
        "name": "AFC Gamma Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "AFJK": {
        "symbol": "AFJK",
        "name": "Aimei Health Technology Co. Ltd Ordinary Share",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "AFJKU": {
        "symbol": "AFJKU",
        "name": "Aimei Health Technology Co. Ltd Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "AFMD": {
        "symbol": "AFMD",
        "name": "Affimed N.V.",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AFRI": {
        "symbol": "AFRI",
        "name": "Forafric Global PLC Ordinary Shares",
        "country": "Gibraltar",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "AFRIW": {
        "symbol": "AFRIW",
        "name": "Forafric Global PLC Warrants",
        "country": "Gibraltar",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "AFRM": {
        "symbol": "AFRM",
        "name": "Affirm Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "AFYA": {
        "symbol": "AFYA",
        "name": "Afya Limited Class A Common Shares",
        "country": "Brazil",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "AGAE": {
        "symbol": "AGAE",
        "name": "Allied Gaming & Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "AGBA": {
        "symbol": "AGBA",
        "name": "AGBA Group Holding Limited Ordinary Share",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "AGBAW": {
        "symbol": "AGBAW",
        "name": "AGBA Group Holding Limited Warrant",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "AGEN": {
        "symbol": "AGEN",
        "name": "Agenus Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AGFY": {
        "symbol": "AGFY",
        "name": "Agrify Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "AGIO": {
        "symbol": "AGIO",
        "name": "Agios Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AGMH": {
        "symbol": "AGMH",
        "name": "AGM Group Holdings Inc. Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AGNC": {
        "symbol": "AGNC",
        "name": "AGNC Investment Corp. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGNCL": {
        "symbol": "AGNCL",
        "name": "AGNC Investment Corp. Depositary Shares Each Representing a 1/1000th Interest in a Share of 7.75% Series G Fixed-Rate Reset Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGNCM": {
        "symbol": "AGNCM",
        "name": "AGNC Investment Corp. Depositary Shares rep 6.875% Series D Fixed-to-Floating Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGNCN": {
        "symbol": "AGNCN",
        "name": "AGNC Investment Corp. Depositary Shares Each Representing a 1/1000th Interest in a Share of 7.00% Series C Fixed-To-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGNCO": {
        "symbol": "AGNCO",
        "name": "AGNC Investment Corp. Depositary Shares each representing a 1/1000th interest in a share of 6.50% Series E Fixed-to-Floating Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGNCP": {
        "symbol": "AGNCP",
        "name": "AGNC Investment Corp. Depositary Shares Each Representing a 1/1000th Interest in a Share of 6.125% Series F Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "AGRI": {
        "symbol": "AGRI",
        "name": "AgriFORCE  Growing Systems Ltd. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "AGYS": {
        "symbol": "AGYS",
        "name": "Agilysys Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AHCO": {
        "symbol": "AHCO",
        "name": "AdaptHealth Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "AHG": {
        "symbol": "AHG",
        "name": "Akso Health Group ADS",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "AIEV": {
        "symbol": "AIEV",
        "name": "Thunder Power Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "AIFF": {
        "symbol": "AIFF",
        "name": "Firefly Neuroscience Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AIHS": {
        "symbol": "AIHS",
        "name": "Senmiao Technology Limited Common Stock",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "AILE": {
        "symbol": "AILE",
        "name": "iLearningEngines Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AILEW": {
        "symbol": "AILEW",
        "name": "iLearningEngines Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AIMAU": {
        "symbol": "AIMAU",
        "name": "Aimfinity Investment Corp. I Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "AIMBU": {
        "symbol": "AIMBU",
        "name": "Aimfinity Investment Corp. I Subunit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "AIMD": {
        "symbol": "AIMD",
        "name": "Ainos Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AIMDW": {
        "symbol": "AIMDW",
        "name": "Ainos Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AIOT": {
        "symbol": "AIOT",
        "name": "PowerFleet Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "AIP": {
        "symbol": "AIP",
        "name": "Arteris Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AIRE": {
        "symbol": "AIRE",
        "name": "reAlpha Tech Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "AIRG": {
        "symbol": "AIRG",
        "name": "Airgain Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "AIRJ": {
        "symbol": "AIRJ",
        "name": "Montana Technologies Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "AIRJW": {
        "symbol": "AIRJW",
        "name": "Montana Technologies Corporation Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "AIRS": {
        "symbol": "AIRS",
        "name": "AirSculpt Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "AIRT": {
        "symbol": "AIRT",
        "name": "Air T Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "AIRTP": {
        "symbol": "AIRTP",
        "name": "Air T Inc. Air T Funding Alpha Income Trust Preferred Securities",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "AISP": {
        "symbol": "AISP",
        "name": "Airship AI Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AISPW": {
        "symbol": "AISPW",
        "name": "Airship AI Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AITR": {
        "symbol": "AITR",
        "name": "AI TRANSPORTATION ACQUISITION CORP Ordinary shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "AITRR": {
        "symbol": "AITRR",
        "name": "AI TRANSPORTATION ACQUISITION CORP Right",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "AITRU": {
        "symbol": "AITRU",
        "name": "AI TRANSPORTATION ACQUISITION CORP Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "AIXI": {
        "symbol": "AIXI",
        "name": "XIAO-I Corporation American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AKAM": {
        "symbol": "AKAM",
        "name": "Akamai Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "AKAN": {
        "symbol": "AKAN",
        "name": "Akanda Corp. Common Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "AKBA": {
        "symbol": "AKBA",
        "name": "Akebia Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AKRO": {
        "symbol": "AKRO",
        "name": "Akero Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AKTS": {
        "symbol": "AKTS",
        "name": "Akoustis Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "AKTX": {
        "symbol": "AKTX",
        "name": "Akari Therapeutics plc ADS",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AKYA": {
        "symbol": "AKYA",
        "name": "Akoya BioSciences Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "ALAB": {
        "symbol": "ALAB",
        "name": "Astera Labs Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ALAR": {
        "symbol": "ALAR",
        "name": "Alarum Technologies Ltd. American Depositary Share",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ALBT": {
        "symbol": "ALBT",
        "name": "Avalon GloboCare Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALCE": {
        "symbol": "ALCE",
        "name": "Alternus Clean Energy Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "ALCO": {
        "symbol": "ALCO",
        "name": "Alico Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ALCY": {
        "symbol": "ALCY",
        "name": "Alchemy Investments Acquisition Corp 1 Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ALCYW": {
        "symbol": "ALCYW",
        "name": "Alchemy Investments Acquisition Corp 1 Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ALDX": {
        "symbol": "ALDX",
        "name": "Aldeyra Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALEC": {
        "symbol": "ALEC",
        "name": "Alector Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALF": {
        "symbol": "ALF",
        "name": "Centurion Acquisition Corp. Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ALFUU": {
        "symbol": "ALFUU",
        "name": "Centurion Acquisition Corp. Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ALFUW": {
        "symbol": "ALFUW",
        "name": "Centurion Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ALGM": {
        "symbol": "ALGM",
        "name": "Allegro MicroSystems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ALGN": {
        "symbol": "ALGN",
        "name": "Align Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "ALGS": {
        "symbol": "ALGS",
        "name": "Aligos Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALGT": {
        "symbol": "ALGT",
        "name": "Allegiant Travel Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "ALHC": {
        "symbol": "ALHC",
        "name": "Alignment Healthcare Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ALKS": {
        "symbol": "ALKS",
        "name": "Alkermes plc Ordinary Shares",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALKT": {
        "symbol": "ALKT",
        "name": "Alkami Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ALLK": {
        "symbol": "ALLK",
        "name": "Allakos Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALLO": {
        "symbol": "ALLO",
        "name": "Allogene Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALLR": {
        "symbol": "ALLR",
        "name": "Allarity Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALLT": {
        "symbol": "ALLT",
        "name": "Allot Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "ALMS": {
        "symbol": "ALMS",
        "name": "Alumis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALNT": {
        "symbol": "ALNT",
        "name": "Allient Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "ALNY": {
        "symbol": "ALNY",
        "name": "Alnylam Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALOT": {
        "symbol": "ALOT",
        "name": "AstroNova Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "ALPP": {
        "symbol": "ALPP",
        "name": "Alpine 4 Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ALRM": {
        "symbol": "ALRM",
        "name": "Alarm.com Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ALRN": {
        "symbol": "ALRN",
        "name": "Aileron Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALRS": {
        "symbol": "ALRS",
        "name": "Alerus Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ALSA": {
        "symbol": "ALSA",
        "name": "Alpha Star Acquisition Corporation Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ALSAR": {
        "symbol": "ALSAR",
        "name": "Alpha Star Acquisition Corporation Rights",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ALT": {
        "symbol": "ALT",
        "name": "Altimmune Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALTI": {
        "symbol": "ALTI",
        "name": "AlTi Global Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "ALTO": {
        "symbol": "ALTO",
        "name": "Alto Ingredients Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ALTR": {
        "symbol": "ALTR",
        "name": "Altair Engineering Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ALTS": {
        "symbol": "ALTS",
        "name": "ALT5 Sigma Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "ALVO": {
        "symbol": "ALVO",
        "name": "Alvotech Ordinary Shares",
        "country": "Luxembourg",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALVOW": {
        "symbol": "ALVOW",
        "name": "Alvotech Warrant",
        "country": "Luxembourg",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALVR": {
        "symbol": "ALVR",
        "name": "AlloVir Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ALXO": {
        "symbol": "ALXO",
        "name": "ALX Oncology Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ALZN": {
        "symbol": "ALZN",
        "name": "Alzamend Neuro Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AMAL": {
        "symbol": "AMAL",
        "name": "Amalgamated Financial Corp. Common Stock (DE)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "AMAT": {
        "symbol": "AMAT",
        "name": "Applied Materials Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AMBA": {
        "symbol": "AMBA",
        "name": "Ambarella Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AMCX": {
        "symbol": "AMCX",
        "name": "AMC Networks Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "AMD": {
        "symbol": "AMD",
        "name": "Advanced Micro Devices Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AMED": {
        "symbol": "AMED",
        "name": "Amedisys Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "AMGN": {
        "symbol": "AMGN",
        "name": "Amgen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AMIX": {
        "symbol": "AMIX",
        "name": "Autonomix Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "AMKR": {
        "symbol": "AMKR",
        "name": "Amkor Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AMLI": {
        "symbol": "AMLI",
        "name": "American Lithium Corp. Common Stock",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "AMLX": {
        "symbol": "AMLX",
        "name": "Amylyx Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AMPG": {
        "symbol": "AMPG",
        "name": "Amplitech Group Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "AMPH": {
        "symbol": "AMPH",
        "name": "Amphastar Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AMPL": {
        "symbol": "AMPL",
        "name": "Amplitude Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AMRK": {
        "symbol": "AMRK",
        "name": "A-Mark Precious Metals Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Other Specialty Stores"
    },
    "AMRN": {
        "symbol": "AMRN",
        "name": "Amarin Corporation plc",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AMRX": {
        "symbol": "AMRX",
        "name": "Amneal Pharmaceuticals Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AMSC": {
        "symbol": "AMSC",
        "name": "American Superconductor Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Metal Fabrications"
    },
    "AMSF": {
        "symbol": "AMSF",
        "name": "AMERISAFE Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "AMST": {
        "symbol": "AMST",
        "name": "Amesite Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AMSWA": {
        "symbol": "AMSWA",
        "name": "American Software Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AMTX": {
        "symbol": "AMTX",
        "name": "Aemetis Inc. (DE) Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "AMWD": {
        "symbol": "AMWD",
        "name": "American Woodmark Corporation Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Forest Products"
    },
    "AMZN": {
        "symbol": "AMZN",
        "name": "Amazon.com Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "ANAB": {
        "symbol": "ANAB",
        "name": "AnaptysBio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANDE": {
        "symbol": "ANDE",
        "name": "Andersons Inc. (The) Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "ANEB": {
        "symbol": "ANEB",
        "name": "Anebulo Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANGH": {
        "symbol": "ANGH",
        "name": "Anghami Inc. Ordinary Shares",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "ANGHW": {
        "symbol": "ANGHW",
        "name": "Anghami Inc. Warrants",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "ANGI": {
        "symbol": "ANGI",
        "name": "Angi Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "ANGO": {
        "symbol": "ANGO",
        "name": "AngioDynamics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ANIK": {
        "symbol": "ANIK",
        "name": "Anika Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ANIP": {
        "symbol": "ANIP",
        "name": "ANI Pharmaceuticals Inc.",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANIX": {
        "symbol": "ANIX",
        "name": "Anixa Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANL": {
        "symbol": "ANL",
        "name": "Adlai Nortye Ltd. American Depositary Shares",
        "country": "Cayman Islands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANNX": {
        "symbol": "ANNX",
        "name": "Annexon Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANSC": {
        "symbol": "ANSC",
        "name": "Agriculture & Natural Solutions Acquisition Corporation Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ANSCU": {
        "symbol": "ANSCU",
        "name": "Agriculture & Natural Solutions Acquisition Corporation Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ANSCW": {
        "symbol": "ANSCW",
        "name": "Agriculture & Natural Solutions Acquisition Corporation Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "ANSS": {
        "symbol": "ANSS",
        "name": "ANSYS Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ANTE": {
        "symbol": "ANTE",
        "name": "AirNet Technology Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "ANTX": {
        "symbol": "ANTX",
        "name": "AN2 Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ANY": {
        "symbol": "ANY",
        "name": "Sphere 3D Corp. Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "AOSL": {
        "symbol": "AOSL",
        "name": "Alpha and Omega Semiconductor Limited Common Shares",
        "country": "Bermuda",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AOUT": {
        "symbol": "AOUT",
        "name": "American Outdoor Brands Inc. Common Stock ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "APA": {
        "symbol": "APA",
        "name": "APA Corporation Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "APCX": {
        "symbol": "APCX",
        "name": "AppTech Payments Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "APDN": {
        "symbol": "APDN",
        "name": "Applied DNA Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "APEI": {
        "symbol": "APEI",
        "name": "American Public Education Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "APGE": {
        "symbol": "APGE",
        "name": "Apogee Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "API": {
        "symbol": "API",
        "name": "Agora Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "APLD": {
        "symbol": "APLD",
        "name": "Applied Digital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "APLM": {
        "symbol": "APLM",
        "name": "Apollomics Inc. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APLMW": {
        "symbol": "APLMW",
        "name": "Apollomics Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APLS": {
        "symbol": "APLS",
        "name": "Apellis Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APLT": {
        "symbol": "APLT",
        "name": "Applied Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APM": {
        "symbol": "APM",
        "name": "Aptorum Group Limited Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APOG": {
        "symbol": "APOG",
        "name": "Apogee Enterprises Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "APP": {
        "symbol": "APP",
        "name": "Applovin Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "APPF": {
        "symbol": "APPF",
        "name": "AppFolio Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "APPN": {
        "symbol": "APPN",
        "name": "Appian Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "APPS": {
        "symbol": "APPS",
        "name": "Digital Turbine Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "APRE": {
        "symbol": "APRE",
        "name": "Aprea Therapeutics Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APTO": {
        "symbol": "APTO",
        "name": "Aptose Biosciences Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "APVO": {
        "symbol": "APVO",
        "name": "Aptevo Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "APWC": {
        "symbol": "APWC",
        "name": "Asia Pacific Wire & Cable Corporation Ltd. Ordinary Shares (Bermuda)",
        "country": "Taiwan",
        "sector": "Industrials",
        "industry": "Telecommunications Equipment"
    },
    "APXI": {
        "symbol": "APXI",
        "name": "APx Acquisition Corp. I Class A Ordinary Share",
        "country": "Mexico",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "APYX": {
        "symbol": "APYX",
        "name": "Apyx Medical Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "AQB": {
        "symbol": "AQB",
        "name": "AquaBounty Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Meat/Poultry/Fish"
    },
    "AQMS": {
        "symbol": "AQMS",
        "name": "Aqua Metals Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "AQST": {
        "symbol": "AQST",
        "name": "Aquestive Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AQU": {
        "symbol": "AQU",
        "name": "Aquaron Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "AQUNU": {
        "symbol": "AQUNU",
        "name": "Aquaron Acquisition Corp. Units",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ARAY": {
        "symbol": "ARAY",
        "name": "Accuray Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ARBB": {
        "symbol": "ARBB",
        "name": "ARB IOT Group Limited Ordinary Shares",
        "country": "Malaysia",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ARBE": {
        "symbol": "ARBE",
        "name": "Arbe Robotics Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ARBEW": {
        "symbol": "ARBEW",
        "name": "Arbe Robotics Ltd. Warrant",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ARBK": {
        "symbol": "ARBK",
        "name": "Argo Blockchain plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ARBKL": {
        "symbol": "ARBKL",
        "name": "Argo Blockchain plc 8.75% Senior Notes due 2026",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ARCB": {
        "symbol": "ARCB",
        "name": "ArcBest Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "ARCC": {
        "symbol": "ARCC",
        "name": "Ares Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ARCT": {
        "symbol": "ARCT",
        "name": "Arcturus Therapeutics Holdings Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ARDX": {
        "symbol": "ARDX",
        "name": "Ardelyx Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AREB": {
        "symbol": "AREB",
        "name": "American Rebel Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "AREBW": {
        "symbol": "AREBW",
        "name": "American Rebel Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "AREC": {
        "symbol": "AREC",
        "name": "American Resources Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "ARGX": {
        "symbol": "ARGX",
        "name": "argenx SE American Depositary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ARHS": {
        "symbol": "ARHS",
        "name": "Arhaus Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "ARKO": {
        "symbol": "ARKO",
        "name": "ARKO Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "ARKOW": {
        "symbol": "ARKOW",
        "name": "ARKO Corp. Warrant",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "ARKR": {
        "symbol": "ARKR",
        "name": "Ark Restaurants Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "ARLP": {
        "symbol": "ARLP",
        "name": "Alliance Resource Partners L.P. Common Units representing Limited Partners Interests",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "ARM": {
        "symbol": "ARM",
        "name": "Arm Holdings plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AROW": {
        "symbol": "AROW",
        "name": "Arrow Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ARQ": {
        "symbol": "ARQ",
        "name": "Arq Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ARQQ": {
        "symbol": "ARQQ",
        "name": "Arqit Quantum Inc. Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ARQQW": {
        "symbol": "ARQQW",
        "name": "Arqit Quantum Inc. Warrants",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ARQT": {
        "symbol": "ARQT",
        "name": "Arcutis Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ARRY": {
        "symbol": "ARRY",
        "name": "Array Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "ARTL": {
        "symbol": "ARTL",
        "name": "Artelo Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ARTNA": {
        "symbol": "ARTNA",
        "name": "Artesian Resources Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "ARTV": {
        "symbol": "ARTV",
        "name": "Artiva Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ARTW": {
        "symbol": "ARTW",
        "name": "Art's-Way Manufacturing Co. Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "ARVN": {
        "symbol": "ARVN",
        "name": "Arvinas Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ARWR": {
        "symbol": "ARWR",
        "name": "Arrowhead Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ASLE": {
        "symbol": "ASLE",
        "name": "AerSale Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "ASMB": {
        "symbol": "ASMB",
        "name": "Assembly Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ASML": {
        "symbol": "ASML",
        "name": "ASML Holding N.V. New York Registry Shares",
        "country": "Netherlands",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ASND": {
        "symbol": "ASND",
        "name": "Ascendis Pharma A/S American Depositary Shares",
        "country": "Denmark",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ASNS": {
        "symbol": "ASNS",
        "name": "Actelis Networks Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ASO": {
        "symbol": "ASO",
        "name": "Academy Sports and Outdoors Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "ASPI": {
        "symbol": "ASPI",
        "name": "ASP Isotopes Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ASPS": {
        "symbol": "ASPS",
        "name": "Altisource Portfolio Solutions S.A. Common Stock",
        "country": "Luxembourg",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "ASRT": {
        "symbol": "ASRT",
        "name": "Assertio Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ASRV": {
        "symbol": "ASRV",
        "name": "AmeriServ Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ASST": {
        "symbol": "ASST",
        "name": "Asset Entities Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ASTC": {
        "symbol": "ASTC",
        "name": "Astrotech Corporation (DE) Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "ASTE": {
        "symbol": "ASTE",
        "name": "Astec Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "ASTH": {
        "symbol": "ASTH",
        "name": "Astrana Health Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "ASTI": {
        "symbol": "ASTI",
        "name": "Ascent Solar Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ASTL": {
        "symbol": "ASTL",
        "name": "Algoma Steel Group Inc. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "ASTLW": {
        "symbol": "ASTLW",
        "name": "Algoma Steel Group Inc. Warrant",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "ASTS": {
        "symbol": "ASTS",
        "name": "AST SpaceMobile Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "ASTSW": {
        "symbol": "ASTSW",
        "name": "AST SpaceMobile Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "ASUR": {
        "symbol": "ASUR",
        "name": "Asure Software Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ASYS": {
        "symbol": "ASYS",
        "name": "Amtech Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ATAI": {
        "symbol": "ATAI",
        "name": "ATAI Life Sciences N.V. Common Shares",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATAT": {
        "symbol": "ATAT",
        "name": "Atour Lifestyle Holdings Limited American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "ATCOL": {
        "symbol": "ATCOL",
        "name": "Atlas Corp. 7.125% Notes due 2027",
        "country": "United Kingdom",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "ATEC": {
        "symbol": "ATEC",
        "name": "Alphatec Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ATER": {
        "symbol": "ATER",
        "name": "Aterian Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "ATEX": {
        "symbol": "ATEX",
        "name": "Anterix Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ATGL": {
        "symbol": "ATGL",
        "name": "Alpha Technology Group Limited Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ATHA": {
        "symbol": "ATHA",
        "name": "Athira Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ATHE": {
        "symbol": "ATHE",
        "name": "Alterity Therapeutics Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATIF": {
        "symbol": "ATIF",
        "name": "ATIF Holdings Limited Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "ATLC": {
        "symbol": "ATLC",
        "name": "Atlanticus Holdings Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ATLCL": {
        "symbol": "ATLCL",
        "name": "Atlanticus Holdings Corporation 6.125% Senior Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ATLCP": {
        "symbol": "ATLCP",
        "name": "Atlanticus Holdings Corporation 7.625% Series B Cumulative Perpetual Preferred Stock no par value per share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ATLCZ": {
        "symbol": "ATLCZ",
        "name": "Atlanticus Holdings Corporation 9.25% Senior Notes due 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ATLO": {
        "symbol": "ATLO",
        "name": "Ames National Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ATLX": {
        "symbol": "ATLX",
        "name": "Atlas Lithium Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "ATMC": {
        "symbol": "ATMC",
        "name": "AlphaTime Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ATMCR": {
        "symbol": "ATMCR",
        "name": "AlphaTime Acquisition Corp Right",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ATMCW": {
        "symbol": "ATMCW",
        "name": "AlphaTime Acquisition Corp Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ATMV": {
        "symbol": "ATMV",
        "name": "AlphaVest Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ATNF": {
        "symbol": "ATNF",
        "name": "180 Life Sciences Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATNFW": {
        "symbol": "ATNFW",
        "name": "180 Life Sciences Corp. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATNI": {
        "symbol": "ATNI",
        "name": "ATN International Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ATOM": {
        "symbol": "ATOM",
        "name": "Atomera Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ATOS": {
        "symbol": "ATOS",
        "name": "Atossa Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATPC": {
        "symbol": "ATPC",
        "name": "Agape ATP Corporation Common Stock",
        "country": "Malaysia",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "ATRA": {
        "symbol": "ATRA",
        "name": "Atara Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ATRC": {
        "symbol": "ATRC",
        "name": "AtriCure Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ATRO": {
        "symbol": "ATRO",
        "name": "Astronics Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "ATSG": {
        "symbol": "ATSG",
        "name": "Air Transport Services Group Inc",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "ATXG": {
        "symbol": "ATXG",
        "name": "Addentax Group Corp. Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "ATXI": {
        "symbol": "ATXI",
        "name": "Avenue Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATXS": {
        "symbol": "ATXS",
        "name": "Astria Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ATYR": {
        "symbol": "ATYR",
        "name": "aTyr Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AUBN": {
        "symbol": "AUBN",
        "name": "Auburn National Bancorporation Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "AUDC": {
        "symbol": "AUDC",
        "name": "AudioCodes Ltd. Common Stock",
        "country": "Israel",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "AUGX": {
        "symbol": "AUGX",
        "name": "Augmedix Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "AUID": {
        "symbol": "AUID",
        "name": "authID Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AUPH": {
        "symbol": "AUPH",
        "name": "Aurinia Pharmaceuticals Inc Ordinary Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AUR": {
        "symbol": "AUR",
        "name": "Aurora Innovation Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AURA": {
        "symbol": "AURA",
        "name": "Aura Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AUROW": {
        "symbol": "AUROW",
        "name": "Aurora Innovation Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AUTL": {
        "symbol": "AUTL",
        "name": "Autolus Therapeutics plc American Depositary Share",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AUUD": {
        "symbol": "AUUD",
        "name": "Auddia Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AUUDW": {
        "symbol": "AUUDW",
        "name": "Auddia Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AVAH": {
        "symbol": "AVAH",
        "name": "Aveanna Healthcare Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "AVAV": {
        "symbol": "AVAV",
        "name": "AeroVironment Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "AVBP": {
        "symbol": "AVBP",
        "name": "ArriVent BioPharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AVDL": {
        "symbol": "AVDL",
        "name": "Avadel Pharmaceuticals plc Ordinary Share",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AVDX": {
        "symbol": "AVDX",
        "name": "AvidXchange Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AVGO": {
        "symbol": "AVGO",
        "name": "Broadcom Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AVGR": {
        "symbol": "AVGR",
        "name": "Avinger Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "AVIR": {
        "symbol": "AVIR",
        "name": "Atea Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AVNW": {
        "symbol": "AVNW",
        "name": "Aviat Networks Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "AVO": {
        "symbol": "AVO",
        "name": "Mission Produce Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "AVPT": {
        "symbol": "AVPT",
        "name": "AvePoint Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AVPTW": {
        "symbol": "AVPTW",
        "name": "AvePoint Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AVT": {
        "symbol": "AVT",
        "name": "Avnet Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "AVTE": {
        "symbol": "AVTE",
        "name": "Aerovate Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AVTX": {
        "symbol": "AVTX",
        "name": "Avalo Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AVXL": {
        "symbol": "AVXL",
        "name": "Anavex Life Sciences Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "AWH": {
        "symbol": "AWH",
        "name": "Aspira Women's Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "AWRE": {
        "symbol": "AWRE",
        "name": "Aware Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "AXDX": {
        "symbol": "AXDX",
        "name": "Accelerate Diagnostics Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "AXGN": {
        "symbol": "AXGN",
        "name": "Axogen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "AXNX": {
        "symbol": "AXNX",
        "name": "Axonics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "AXON": {
        "symbol": "AXON",
        "name": "Axon Enterprise Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Ordnance And Accessories"
    },
    "AXSM": {
        "symbol": "AXSM",
        "name": "Axsome Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AXTI": {
        "symbol": "AXTI",
        "name": "AXT Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "AY": {
        "symbol": "AY",
        "name": "Atlantica Sustainable Infrastructure plc Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "AYRO": {
        "symbol": "AYRO",
        "name": "AYRO Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "AYTU": {
        "symbol": "AYTU",
        "name": "Aytu BioPharma Inc.  Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AZ": {
        "symbol": "AZ",
        "name": "A2Z Cust2Mate Solutions Corp. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "AZI": {
        "symbol": "AZI",
        "name": "Autozi Internet Technology (Global) Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "AZN": {
        "symbol": "AZN",
        "name": "AstraZeneca PLC American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "AZPN": {
        "symbol": "AZPN",
        "name": "Aspen Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "AZTA": {
        "symbol": "AZTA",
        "name": "Azenta Inc.",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "BACK": {
        "symbol": "BACK",
        "name": "IMAC Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "BAER": {
        "symbol": "BAER",
        "name": "Bridger Aerospace Group Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "BAERW": {
        "symbol": "BAERW",
        "name": "Bridger Aerospace Group Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "BAFN": {
        "symbol": "BAFN",
        "name": "BayFirst Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BAND": {
        "symbol": "BAND",
        "name": "Bandwidth Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BANF": {
        "symbol": "BANF",
        "name": "BancFirst Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BANFP": {
        "symbol": "BANFP",
        "name": "BancFirst Corporation - BFC Capital Trust II Cumulative Trust Preferred Securities",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BANL": {
        "symbol": "BANL",
        "name": "CBL International Limited Ordinary Shares",
        "country": "Malaysia",
        "sector": "Energy",
        "industry": "Oil Refining/Marketing"
    },
    "BANR": {
        "symbol": "BANR",
        "name": "Banner Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BANX": {
        "symbol": "BANX",
        "name": "ArrowMark Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "BAOS": {
        "symbol": "BAOS",
        "name": "Baosheng Media Group Holdings Limited Ordinary shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "BASE": {
        "symbol": "BASE",
        "name": "Couchbase Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BATRA": {
        "symbol": "BATRA",
        "name": "Atlanta Braves Holdings Inc. Series A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "BATRK": {
        "symbol": "BATRK",
        "name": "Atlanta Braves Holdings Inc. Series C Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "BAYA": {
        "symbol": "BAYA",
        "name": "Bayview Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BBCP": {
        "symbol": "BBCP",
        "name": "Concrete Pumping Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "BBGI": {
        "symbol": "BBGI",
        "name": "Beasley Broadcast Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "BBIO": {
        "symbol": "BBIO",
        "name": "BridgeBio Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BBLG": {
        "symbol": "BBLG",
        "name": "Bone Biologics Corp Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "BBSI": {
        "symbol": "BBSI",
        "name": "Barrett Business Services Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "BCAB": {
        "symbol": "BCAB",
        "name": "BioAtla Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BCAL": {
        "symbol": "BCAL",
        "name": "California BanCorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BCAN": {
        "symbol": "BCAN",
        "name": "Femto Technologies Inc. Common Stock",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BCAX": {
        "symbol": "BCAX",
        "name": "Bicara Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BCBP": {
        "symbol": "BCBP",
        "name": "BCB Bancorp Inc. (NJ) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "BCDA": {
        "symbol": "BCDA",
        "name": "BioCardia Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BCG": {
        "symbol": "BCG",
        "name": "Binah Capital Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BCGWW": {
        "symbol": "BCGWW",
        "name": "Binah Capital Group Inc. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BCLI": {
        "symbol": "BCLI",
        "name": "Brainstorm Cell Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BCML": {
        "symbol": "BCML",
        "name": "BayCom Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BCOV": {
        "symbol": "BCOV",
        "name": "Brightcove Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "BCOW": {
        "symbol": "BCOW",
        "name": "1895 Bancorp of Wisconsin Inc. (MD) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "BCPC": {
        "symbol": "BCPC",
        "name": "Balchem Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "BCRX": {
        "symbol": "BCRX",
        "name": "BioCryst Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BCSA": {
        "symbol": "BCSA",
        "name": "Blockchain Coinvestors Acquisition Corp. I Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BCSAU": {
        "symbol": "BCSAU",
        "name": "Blockchain Coinvestors Acquisition Corp. I Unit",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BCSAW": {
        "symbol": "BCSAW",
        "name": "Blockchain Coinvestors Acquisition Corp. I Warrant",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BCTX": {
        "symbol": "BCTX",
        "name": "BriaCell Therapeutics Corp. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BCTXW": {
        "symbol": "BCTXW",
        "name": "BriaCell Therapeutics Corp. Warrant",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BCYC": {
        "symbol": "BCYC",
        "name": "Bicycle Therapeutics plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BDRX": {
        "symbol": "BDRX",
        "name": "Biodexa Pharmaceuticals plc American Depositary Shs",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BDSX": {
        "symbol": "BDSX",
        "name": "Biodesix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "BDTX": {
        "symbol": "BDTX",
        "name": "Black Diamond Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BEAM": {
        "symbol": "BEAM",
        "name": "Beam Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BEAT": {
        "symbol": "BEAT",
        "name": "Heartbeam Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "BEATW": {
        "symbol": "BEATW",
        "name": "Heartbeam Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "BECN": {
        "symbol": "BECN",
        "name": "Beacon Roofing Supply Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "BEEM": {
        "symbol": "BEEM",
        "name": "Beam Global Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "BELFA": {
        "symbol": "BELFA",
        "name": "Bel Fuse Inc. Class A Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BELFB": {
        "symbol": "BELFB",
        "name": "Bel Fuse Inc. Class B Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BENF": {
        "symbol": "BENF",
        "name": "Beneficient Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BENFW": {
        "symbol": "BENFW",
        "name": "Beneficient Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BETR": {
        "symbol": "BETR",
        "name": "Better Home & Finance Holding Company Class A Common Stock",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BETRW": {
        "symbol": "BETRW",
        "name": "Better Home & Finance Holding Company Warrant",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BFC": {
        "symbol": "BFC",
        "name": "Bank First Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BFIN": {
        "symbol": "BFIN",
        "name": "BankFinancial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "BFRG": {
        "symbol": "BFRG",
        "name": "Bullfrog AI Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BFRGW": {
        "symbol": "BFRGW",
        "name": "Bullfrog AI Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BFRI": {
        "symbol": "BFRI",
        "name": "Biofrontera Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BFRIW": {
        "symbol": "BFRIW",
        "name": "Biofrontera Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BFST": {
        "symbol": "BFST",
        "name": "Business First Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BGC": {
        "symbol": "BGC",
        "name": "BGC Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "BGFV": {
        "symbol": "BGFV",
        "name": "Big 5 Sporting Goods Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "BGLC": {
        "symbol": "BGLC",
        "name": "BioNexus Gene Lab Corp Common stock",
        "country": "Malaysia",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "BGM": {
        "symbol": "BGM",
        "name": "Qilian International Holding Group Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BGNE": {
        "symbol": "BGNE",
        "name": "BeiGene Ltd. American Depositary Shares",
        "country": "Cayman Islands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BHAC": {
        "symbol": "BHAC",
        "name": "Focus Impact BH3 Acquisition Company Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BHACW": {
        "symbol": "BHACW",
        "name": "Focus Impact BH3 Acquisition Company Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BHAT": {
        "symbol": "BHAT",
        "name": "Blue Hat Interactive Entertainment Technology Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "BHF": {
        "symbol": "BHF",
        "name": "Brighthouse Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHFAL": {
        "symbol": "BHFAL",
        "name": "Brighthouse Financial Inc. 6.25% Junior Subordinated Debentures due 2058",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHFAM": {
        "symbol": "BHFAM",
        "name": "Brighthouse Financial Inc. Depositary shares each representing a 1/1000th Interest in a Share of 4.625% Non-Cumulative Preferred Stock Series D",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHFAN": {
        "symbol": "BHFAN",
        "name": "Brighthouse Financial Inc. Depositary shares each representing a 1/1000th interest in a share of 5.375% Non-Cumulative Preferred Stock Series C",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHFAO": {
        "symbol": "BHFAO",
        "name": "Brighthouse Financial Inc. Depositary Shares 6.75% Non-Cumulative Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHFAP": {
        "symbol": "BHFAP",
        "name": "Brighthouse Financial Inc. Depositary Shares 6.6% Non-Cumulative Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Life Insurance"
    },
    "BHIL": {
        "symbol": "BHIL",
        "name": "Benson Hill Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BHRB": {
        "symbol": "BHRB",
        "name": "Burke & Herbert Financial Services Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BIAF": {
        "symbol": "BIAF",
        "name": "bioAffinity Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "BIAFW": {
        "symbol": "BIAFW",
        "name": "bioAffinity Technologies Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "BIDU": {
        "symbol": "BIDU",
        "name": "Baidu Inc. ADS",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "BIGC": {
        "symbol": "BIGC",
        "name": "BigCommerce Holdings Inc. Series 1 Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BIIB": {
        "symbol": "BIIB",
        "name": "Biogen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BILI": {
        "symbol": "BILI",
        "name": "Bilibili Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "BIOA": {
        "symbol": "BIOA",
        "name": "BioAge Labs Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BIOR": {
        "symbol": "BIOR",
        "name": "Biora Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BIOX": {
        "symbol": "BIOX",
        "name": "Bioceres Crop Solutions Corp. Ordinary Shares",
        "country": "Argentina",
        "sector": "Industrials",
        "industry": "Agricultural Chemicals"
    },
    "BIRD": {
        "symbol": "BIRD",
        "name": "Allbirds Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "BITF": {
        "symbol": "BITF",
        "name": "Bitfarms Ltd. Common Stock",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BIVI": {
        "symbol": "BIVI",
        "name": "BioVie Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BJDX": {
        "symbol": "BJDX",
        "name": "Bluejay Diagnostics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "BJRI": {
        "symbol": "BJRI",
        "name": "BJ's Restaurants Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "BKHA": {
        "symbol": "BKHA",
        "name": "Black Hawk Acquisition Corporation Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BKNG": {
        "symbol": "BKNG",
        "name": "Booking Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "BKR": {
        "symbol": "BKR",
        "name": "Baker Hughes Company Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "BKYI": {
        "symbol": "BKYI",
        "name": "BIO-key International Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BL": {
        "symbol": "BL",
        "name": "BlackLine Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BLAC": {
        "symbol": "BLAC",
        "name": "Bellevue Life Sciences Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BLBD": {
        "symbol": "BLBD",
        "name": "Blue Bird Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "BLBX": {
        "symbol": "BLBX",
        "name": "Blackboxstocks Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "BLDE": {
        "symbol": "BLDE",
        "name": "Blade Air Mobility Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "BLDEW": {
        "symbol": "BLDEW",
        "name": "Blade Air Mobility Inc. Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "BLDP": {
        "symbol": "BLDP",
        "name": "Ballard Power Systems Inc. Common Shares",
        "country": "Canada",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "BLEU": {
        "symbol": "BLEU",
        "name": "bleuacacia ltd Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BLEUR": {
        "symbol": "BLEUR",
        "name": "bleuacacia ltd Rights",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BLEUU": {
        "symbol": "BLEUU",
        "name": "bleuacacia ltd Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BLEUW": {
        "symbol": "BLEUW",
        "name": "bleuacacia ltd Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BLFS": {
        "symbol": "BLFS",
        "name": "BioLife Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "BLFY": {
        "symbol": "BLFY",
        "name": "Blue Foundry Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "BLIN": {
        "symbol": "BLIN",
        "name": "Bridgeline Digital Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BLKB": {
        "symbol": "BLKB",
        "name": "Blackbaud Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BLMN": {
        "symbol": "BLMN",
        "name": "Bloomin' Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "BLMZ": {
        "symbol": "BLMZ",
        "name": "BloomZ Inc. Ordinary Shares",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "BLNK": {
        "symbol": "BLNK",
        "name": "Blink Charging Co. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "BLRX": {
        "symbol": "BLRX",
        "name": "BioLineRx Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BLTE": {
        "symbol": "BLTE",
        "name": "Belite Bio Inc American Depositary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BLUE": {
        "symbol": "BLUE",
        "name": "bluebird bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BLZE": {
        "symbol": "BLZE",
        "name": "Backblaze Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BMBL": {
        "symbol": "BMBL",
        "name": "Bumble Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "BMEA": {
        "symbol": "BMEA",
        "name": "Biomea Fusion Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BMR": {
        "symbol": "BMR",
        "name": "Beamr Imaging Ltd. Ordinary Share",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BMRA": {
        "symbol": "BMRA",
        "name": "Biomerica Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "BMRC": {
        "symbol": "BMRC",
        "name": "Bank of Marin Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BMRN": {
        "symbol": "BMRN",
        "name": "BioMarin Pharmaceutical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BNAI": {
        "symbol": "BNAI",
        "name": "Brand Engagement Network Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "BNAIW": {
        "symbol": "BNAIW",
        "name": "Brand Engagement Network Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "BNGO": {
        "symbol": "BNGO",
        "name": "Bionano Genomics Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "BNIXW": {
        "symbol": "BNIXW",
        "name": "Bannix Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BNOX": {
        "symbol": "BNOX",
        "name": "Bionomics Limited American Depository Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BNR": {
        "symbol": "BNR",
        "name": "Burning Rock Biotech Limited American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "BNRG": {
        "symbol": "BNRG",
        "name": "Brenmiller Energy Ltd Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Building Products"
    },
    "BNTC": {
        "symbol": "BNTC",
        "name": "Benitec Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BNTX": {
        "symbol": "BNTX",
        "name": "BioNTech SE American Depositary Share",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "BNZI": {
        "symbol": "BNZI",
        "name": "Banzai International Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BNZIW": {
        "symbol": "BNZIW",
        "name": "Banzai International Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BOCN": {
        "symbol": "BOCN",
        "name": "Blue Ocean Acquisition Corp Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BOCNW": {
        "symbol": "BOCNW",
        "name": "Blue Ocean Acquisition Corp Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BOF": {
        "symbol": "BOF",
        "name": "BranchOut Food Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BOKF": {
        "symbol": "BOKF",
        "name": "BOK Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BOLD": {
        "symbol": "BOLD",
        "name": "Boundless Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BOLT": {
        "symbol": "BOLT",
        "name": "Bolt Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BON": {
        "symbol": "BON",
        "name": "Bon Natural Life Limited Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "BOOM": {
        "symbol": "BOOM",
        "name": "DMC Global Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "BOSC": {
        "symbol": "BOSC",
        "name": "B.O.S. Better Online Solutions Common Stock",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "BOTJ": {
        "symbol": "BOTJ",
        "name": "Bank of the James Financial Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BOWN": {
        "symbol": "BOWN",
        "name": "Bowen Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BOWNR": {
        "symbol": "BOWNR",
        "name": "Bowen Acquisition Corp Rights",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BOXL": {
        "symbol": "BOXL",
        "name": "Boxlight Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "BPMC": {
        "symbol": "BPMC",
        "name": "Blueprint Medicines Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BPOP": {
        "symbol": "BPOP",
        "name": "Popular Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BPOPM": {
        "symbol": "BPOPM",
        "name": "Popular Inc. Popular Capital Trust II - 6.125% Cumulative Monthly Income Trust Preferred Securities",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BPRN": {
        "symbol": "BPRN",
        "name": "Princeton Bancorp Inc. Common Stock (PA)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BPTH": {
        "symbol": "BPTH",
        "name": "Bio-Path Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BPYPM": {
        "symbol": "BPYPM",
        "name": "Brookfield Property Partners L.P. 6.25% Class A Cumulative Redeemable Preferred Units Series 1",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "BPYPN": {
        "symbol": "BPYPN",
        "name": "Brookfield Property Partners L.P. 5.750% Class A Cumulative Redeemable Perpetual Preferred Units Series 3",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "BPYPO": {
        "symbol": "BPYPO",
        "name": "Brookfield Property Partners L.P. 6.375% Class A Cumulative Redeemable Perpetual Preferred Units Series 2",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "BPYPP": {
        "symbol": "BPYPP",
        "name": "Brookfield Property Partners L.P. 6.50% Class A Cumulative Redeemable Perpetual Preferred Units",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "BRAC": {
        "symbol": "BRAC",
        "name": "Broad Capital Acquisition Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BRACR": {
        "symbol": "BRACR",
        "name": "Broad Capital Acquisition Corp Rights",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BRAG": {
        "symbol": "BRAG",
        "name": "Bragg Gaming Group Inc. Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Office Equipment/Supplies/Services"
    },
    "BREA": {
        "symbol": "BREA",
        "name": "Brera Holdings PLC Class B Ordinary Shares",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "BRFH": {
        "symbol": "BRFH",
        "name": "Barfresh Food Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BRID": {
        "symbol": "BRID",
        "name": "Bridgford Foods Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Specialty Foods"
    },
    "BRKHU": {
        "symbol": "BRKHU",
        "name": "BurTech Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BRKHW": {
        "symbol": "BRKHW",
        "name": "BurTech Acquisition Corp. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BRKL": {
        "symbol": "BRKL",
        "name": "Brookline Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "BRKR": {
        "symbol": "BRKR",
        "name": "Bruker Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "BRLS": {
        "symbol": "BRLS",
        "name": "Borealis Foods Inc. Class A Common Shares",
        "country": "Kazakhstan",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BRLSW": {
        "symbol": "BRLSW",
        "name": "Borealis Foods Inc. Warrant",
        "country": "Kazakhstan",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BRLT": {
        "symbol": "BRLT",
        "name": "Brilliant Earth Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "BRNS": {
        "symbol": "BRNS",
        "name": "Barinthus Biotherapeutics plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BROG": {
        "symbol": "BROG",
        "name": "Brooge Energy Limited Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Energy",
        "industry": "Integrated oil Companies"
    },
    "BROGW": {
        "symbol": "BROGW",
        "name": "Brooge Holdings Limited Warrant expiring 12/20/2024",
        "country": "United Kingdom",
        "sector": "Energy",
        "industry": "Integrated oil Companies"
    },
    "BRTX": {
        "symbol": "BRTX",
        "name": "BioRestorative Therapies Inc. Common Stock (NV)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Managed Health Care"
    },
    "BRY": {
        "symbol": "BRY",
        "name": "Berry Corporation (bry) Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "BRZE": {
        "symbol": "BRZE",
        "name": "Braze Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BSBK": {
        "symbol": "BSBK",
        "name": "Bogota Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "BSET": {
        "symbol": "BSET",
        "name": "Bassett Furniture Industries Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "BSFC": {
        "symbol": "BSFC",
        "name": "Blue Star Foods Corp. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "BSIIU": {
        "symbol": "BSIIU",
        "name": "Black Spade Acquisition II Co Unit",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BSLK": {
        "symbol": "BSLK",
        "name": "Bolt Projects Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "BSLKW": {
        "symbol": "BSLKW",
        "name": "Bolt Projects Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "BSRR": {
        "symbol": "BSRR",
        "name": "Sierra Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BSVN": {
        "symbol": "BSVN",
        "name": "Bank7 Corp. Common stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BSY": {
        "symbol": "BSY",
        "name": "Bentley Systems Incorporated Class B Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "BTAI": {
        "symbol": "BTAI",
        "name": "BioXcel Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BTBD": {
        "symbol": "BTBD",
        "name": "BT Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "BTBDW": {
        "symbol": "BTBDW",
        "name": "BT Brands Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "BTBT": {
        "symbol": "BTBT",
        "name": "Bit Digital Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTCS": {
        "symbol": "BTCS",
        "name": "BTCS Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTCT": {
        "symbol": "BTCT",
        "name": "BTC Digital Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "BTCTW": {
        "symbol": "BTCTW",
        "name": "BTC Digital Ltd. Warrant",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "BTDR": {
        "symbol": "BTDR",
        "name": "Bitdeer Technologies Group Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTM": {
        "symbol": "BTM",
        "name": "Bitcoin Depot Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTMD": {
        "symbol": "BTMD",
        "name": "Biote Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "BTMWW": {
        "symbol": "BTMWW",
        "name": "Bitcoin Depot Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTOC": {
        "symbol": "BTOC",
        "name": "Armlogi Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Office Equipment/Supplies/Services"
    },
    "BTOG": {
        "symbol": "BTOG",
        "name": "Bit Origin Limited Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "BTSG": {
        "symbol": "BTSG",
        "name": "BrightSpring Health Services Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "BTSGU": {
        "symbol": "BTSGU",
        "name": "BrightSpring Health Services Inc. Tangible Equity Unit",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "BUJA": {
        "symbol": "BUJA",
        "name": "Bukit Jalil Global Acquisition 1 Ltd. Ordinary Shares",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BUJAR": {
        "symbol": "BUJAR",
        "name": "Bukit Jalil Global Acquisition 1 Ltd. Rights",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BUJAU": {
        "symbol": "BUJAU",
        "name": "Bukit Jalil Global Acquisition 1 Ltd. Unit",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BUJAW": {
        "symbol": "BUJAW",
        "name": "Bukit Jalil Global Acquisition 1 Ltd. Warrants",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BUSE": {
        "symbol": "BUSE",
        "name": "First Busey Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BVFL": {
        "symbol": "BVFL",
        "name": "BV Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "BVS": {
        "symbol": "BVS",
        "name": "Bioventus Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "BWAY": {
        "symbol": "BWAY",
        "name": "BrainsWay Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "BWB": {
        "symbol": "BWB",
        "name": "Bridgewater Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BWBBP": {
        "symbol": "BWBBP",
        "name": "Bridgewater Bancshares Inc. Depositary Shares Each Representing a 1/100th Interest in a Share of 5.875% Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BWEN": {
        "symbol": "BWEN",
        "name": "Broadwind Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Metal Fabrications"
    },
    "BWFG": {
        "symbol": "BWFG",
        "name": "Bankwell Financial Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "BWIN": {
        "symbol": "BWIN",
        "name": "The Baldwin Insurance Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "BWMN": {
        "symbol": "BWMN",
        "name": "Bowman Consulting Group Ltd. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "BYFC": {
        "symbol": "BYFC",
        "name": "Broadway Financial Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "BYND": {
        "symbol": "BYND",
        "name": "Beyond Meat Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "BYNO": {
        "symbol": "BYNO",
        "name": "byNordic Acquisition Corporation Class A Common Stock",
        "country": "Sweden",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BYNOW": {
        "symbol": "BYNOW",
        "name": "byNordic Acquisition Corporation Warrant",
        "country": "Sweden",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "BYRN": {
        "symbol": "BYRN",
        "name": "Byrna Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "BYSI": {
        "symbol": "BYSI",
        "name": "BeyondSpring Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "BZ": {
        "symbol": "BZ",
        "name": "KANZHUN LIMITED American Depository Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "BZFD": {
        "symbol": "BZFD",
        "name": "BuzzFeed Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "BZFDW": {
        "symbol": "BZFDW",
        "name": "BuzzFeed Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "BZUN": {
        "symbol": "BZUN",
        "name": "Baozun Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "CAAS": {
        "symbol": "CAAS",
        "name": "China Automotive Systems Inc. Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CABA": {
        "symbol": "CABA",
        "name": "Cabaletta Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CAC": {
        "symbol": "CAC",
        "name": "Camden National Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CACC": {
        "symbol": "CACC",
        "name": "Credit Acceptance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CACO": {
        "symbol": "CACO",
        "name": "Caravelle International Group Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "CADL": {
        "symbol": "CADL",
        "name": "Candel Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CAKE": {
        "symbol": "CAKE",
        "name": "Cheesecake Factory Incorporated (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "CALC": {
        "symbol": "CALC",
        "name": "CalciMedica Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CALM": {
        "symbol": "CALM",
        "name": "Cal-Maine Foods Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "CAMT": {
        "symbol": "CAMT",
        "name": "Camtek Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "CAN": {
        "symbol": "CAN",
        "name": "Canaan Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CAPNU": {
        "symbol": "CAPNU",
        "name": "Cayson Acquisition Corp Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CAPR": {
        "symbol": "CAPR",
        "name": "Capricor Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CAPT": {
        "symbol": "CAPT",
        "name": "Captivision Inc. Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CAR": {
        "symbol": "CAR",
        "name": "Avis Budget Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Rental/Leasing Companies"
    },
    "CARA": {
        "symbol": "CARA",
        "name": "Cara Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CARE": {
        "symbol": "CARE",
        "name": "Carter Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CARG": {
        "symbol": "CARG",
        "name": "CarGurus Inc. Class A Common Stock ",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CARM": {
        "symbol": "CARM",
        "name": "Carisma Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CART": {
        "symbol": "CART",
        "name": "Maplebear Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CARV": {
        "symbol": "CARV",
        "name": "Carver Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CASH": {
        "symbol": "CASH",
        "name": "Pathward Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CASI": {
        "symbol": "CASI",
        "name": "CASI Pharmaceuticals Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CASS": {
        "symbol": "CASS",
        "name": "Cass Information Systems Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CASY": {
        "symbol": "CASY",
        "name": "Casey's General Stores Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "CATY": {
        "symbol": "CATY",
        "name": "Cathay General Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CBAN": {
        "symbol": "CBAN",
        "name": "Colony Bankcorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CBAT": {
        "symbol": "CBAT",
        "name": "CBAK Energy Technology Inc. Common Stock",
        "country": "China",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "CBFV": {
        "symbol": "CBFV",
        "name": "CB Financial Services Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CBNK": {
        "symbol": "CBNK",
        "name": "Capital Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CBRG": {
        "symbol": "CBRG",
        "name": "Chain Bridge I Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CBRL": {
        "symbol": "CBRL",
        "name": "Cracker Barrel Old Country Store Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "CBSH": {
        "symbol": "CBSH",
        "name": "Commerce Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CBUS": {
        "symbol": "CBUS",
        "name": "Cibus Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Agricultural Chemicals"
    },
    "CCAP": {
        "symbol": "CCAP",
        "name": "Crescent Capital BDC Inc. Common stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CCB": {
        "symbol": "CCB",
        "name": "Coastal Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CCBG": {
        "symbol": "CCBG",
        "name": "Capital City Bank Group Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CCCC": {
        "symbol": "CCCC",
        "name": "C4 Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CCCS": {
        "symbol": "CCCS",
        "name": "CCC Intelligent Solutions Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CCD": {
        "symbol": "CCD",
        "name": "Calamos Dynamic Convertible & Income Fund Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "CCEC": {
        "symbol": "CCEC",
        "name": "Capital Clean Energy Carriers Corp. Common Share",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "CCEP": {
        "symbol": "CCEP",
        "name": "Coca-Cola Europacific Partners plc Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "CCG": {
        "symbol": "CCG",
        "name": "Cheche Group Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "CCGWW": {
        "symbol": "CCGWW",
        "name": "Cheche Group Inc. Warrant",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "CCIX": {
        "symbol": "CCIX",
        "name": "Churchill Capital Corp IX Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CCLD": {
        "symbol": "CCLD",
        "name": "CareCloud Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CCLDO": {
        "symbol": "CCLDO",
        "name": "CareCloud Inc. 8.75% Series B Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CCLDP": {
        "symbol": "CCLDP",
        "name": "CareCloud Inc. 11% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CCNE": {
        "symbol": "CCNE",
        "name": "CNB Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CCNEP": {
        "symbol": "CCNEP",
        "name": "CNB Financial Corporation Depositary Shares each representing a 1/40th ownership interest in a share of 7.125% Series A Fixed-Rate Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CCOI": {
        "symbol": "CCOI",
        "name": "Cogent Communications Holdings Inc.",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "CCRN": {
        "symbol": "CCRN",
        "name": "Cross Country Healthcare Inc. Common Stock $0.0001 Par Value",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "CCSI": {
        "symbol": "CCSI",
        "name": "Consensus Cloud Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CCTG": {
        "symbol": "CCTG",
        "name": "CCSC Technology International Holdings Limited Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Building Products"
    },
    "CDAQ": {
        "symbol": "CDAQ",
        "name": "Compass Digital Acquisition Corp. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CDAQU": {
        "symbol": "CDAQU",
        "name": "Compass Digital Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CDIO": {
        "symbol": "CDIO",
        "name": "Cardio Diagnostics Holdings Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "CDIOW": {
        "symbol": "CDIOW",
        "name": "Cardio Diagnostics Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "CDLX": {
        "symbol": "CDLX",
        "name": "Cardlytics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "CDMO": {
        "symbol": "CDMO",
        "name": "Avid Bioservices Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CDNA": {
        "symbol": "CDNA",
        "name": "CareDx Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "CDNS": {
        "symbol": "CDNS",
        "name": "Cadence Design Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CDRO": {
        "symbol": "CDRO",
        "name": "Codere Online Luxembourg S.A. Ordinary Shares",
        "country": "Luxembourg",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "CDROW": {
        "symbol": "CDROW",
        "name": "Codere Online Luxembourg S.A. Warrants",
        "country": "Luxembourg",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "CDT": {
        "symbol": "CDT",
        "name": "Conduit Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CDTG": {
        "symbol": "CDTG",
        "name": "CDT Environmental Technology Investment Holdings Limited ordinary shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Miscellaneous"
    },
    "CDTX": {
        "symbol": "CDTX",
        "name": "Cidara Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CDW": {
        "symbol": "CDW",
        "name": "CDW Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "CDXC": {
        "symbol": "CDXC",
        "name": "ChromaDex Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "CDXS": {
        "symbol": "CDXS",
        "name": "Codexis Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "CDZI": {
        "symbol": "CDZI",
        "name": "CADIZ Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "CDZIP": {
        "symbol": "CDZIP",
        "name": "Cadiz Inc. Depositary Shares",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "CEAD": {
        "symbol": "CEAD",
        "name": "CEA Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CEADW": {
        "symbol": "CEADW",
        "name": "CEA Industries Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CECO": {
        "symbol": "CECO",
        "name": "CECO Environmental Corp. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Pollution Control Equipment"
    },
    "CEG": {
        "symbol": "CEG",
        "name": "Constellation Energy Corporation Common Stock ",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "CELC": {
        "symbol": "CELC",
        "name": "Celcuity Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "CELH": {
        "symbol": "CELH",
        "name": "Celsius Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "CELU": {
        "symbol": "CELU",
        "name": "Celularity Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CELUW": {
        "symbol": "CELUW",
        "name": "Celularity Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CELZ": {
        "symbol": "CELZ",
        "name": "Creative Medical Technology Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CENN": {
        "symbol": "CENN",
        "name": "Cenntro Inc. Common Stock",
        "country": "New Zealand",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "CENT": {
        "symbol": "CENT",
        "name": "Central Garden & Pet Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "CENTA": {
        "symbol": "CENTA",
        "name": "Central Garden & Pet Company Class A Common Stock Nonvoting",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "CENX": {
        "symbol": "CENX",
        "name": "Century Aluminum Company Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aluminum"
    },
    "CEP": {
        "symbol": "CEP",
        "name": "Cantor Equity Partners Inc. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CERO": {
        "symbol": "CERO",
        "name": "CERo Therapeutics Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CEROW": {
        "symbol": "CEROW",
        "name": "CERo Therapeutics Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CERS": {
        "symbol": "CERS",
        "name": "Cerus Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CERT": {
        "symbol": "CERT",
        "name": "Certara Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CETX": {
        "symbol": "CETX",
        "name": "Cemtrex Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "CETY": {
        "symbol": "CETY",
        "name": "Clean Energy Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Metal Fabrications"
    },
    "CEVA": {
        "symbol": "CEVA",
        "name": "CEVA Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "CFB": {
        "symbol": "CFB",
        "name": "CrossFirst Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CFBK": {
        "symbol": "CFBK",
        "name": "CF Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CFFI": {
        "symbol": "CFFI",
        "name": "C&F Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CFFN": {
        "symbol": "CFFN",
        "name": "Capitol Federal Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CFFS": {
        "symbol": "CFFS",
        "name": "CF Acquisition Corp. VII Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CFFSW": {
        "symbol": "CFFSW",
        "name": "CF Acquisition Corp. VII Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CFLT": {
        "symbol": "CFLT",
        "name": "Confluent Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CFSB": {
        "symbol": "CFSB",
        "name": "CFSB Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CG": {
        "symbol": "CG",
        "name": "The Carlyle Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CGABL": {
        "symbol": "CGABL",
        "name": "The Carlyle Group Inc. 4.625% Subordinated Notes due 2061",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CGBD": {
        "symbol": "CGBD",
        "name": "Carlyle Secured Lending Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CGBDL": {
        "symbol": "CGBDL",
        "name": "Carlyle Secured Lending Inc. 8.20% Notes due 2028",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CGBS": {
        "symbol": "CGBS",
        "name": "Crown LNG Holdings Limited Ordinary Shares",
        "country": "Jersey",
        "sector": "Utilities",
        "industry": "Oil/Gas Transmission"
    },
    "CGBSW": {
        "symbol": "CGBSW",
        "name": "Crown LNG Holdings Limited Warrants",
        "country": "Jersey",
        "sector": "Utilities",
        "industry": "Oil/Gas Transmission"
    },
    "CGC": {
        "symbol": "CGC",
        "name": "Canopy Growth Corporation Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "CGEM": {
        "symbol": "CGEM",
        "name": "Cullinan Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CGEN": {
        "symbol": "CGEN",
        "name": "Compugen Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CGNT": {
        "symbol": "CGNT",
        "name": "Cognyte Software Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CGNX": {
        "symbol": "CGNX",
        "name": "Cognex Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CGO": {
        "symbol": "CGO",
        "name": "Calamos Global Total Return Fund Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CGON": {
        "symbol": "CGON",
        "name": "CG Oncology Inc. Common stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CGTX": {
        "symbol": "CGTX",
        "name": "Cognition Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CHCI": {
        "symbol": "CHCI",
        "name": "Comstock Holding Companies Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "CHCO": {
        "symbol": "CHCO",
        "name": "City Holding Company Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CHDN": {
        "symbol": "CHDN",
        "name": "Churchill Downs Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "CHEF": {
        "symbol": "CHEF",
        "name": "The Chefs' Warehouse Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Food Distributors"
    },
    "CHEK": {
        "symbol": "CHEK",
        "name": "Check-Cap Ltd. Ordinary Share",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical Electronics"
    },
    "CHI": {
        "symbol": "CHI",
        "name": "Calamos Convertible Opportunities and Income Fund Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CHK": {
        "symbol": "CHK",
        "name": "Chesapeake Energy Corporation Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "CHKEL": {
        "symbol": "CHKEL",
        "name": "Chesapeake Energy Corporation Class C Warrants",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "CHKEW": {
        "symbol": "CHKEW",
        "name": "Chesapeake Energy Corporation Class A Warrants",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "CHKEZ": {
        "symbol": "CHKEZ",
        "name": "Chesapeake Energy Corporation Class B Warrants",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "CHKP": {
        "symbol": "CHKP",
        "name": "Check Point Software Technologies Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CHMG": {
        "symbol": "CHMG",
        "name": "Chemung Financial Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CHNR": {
        "symbol": "CHNR",
        "name": "China Natural Resources Inc. Common Stock",
        "country": "Hong Kong",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "CHR": {
        "symbol": "CHR",
        "name": "Cheer Holding Inc. Class A Ordinary Share",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CHRD": {
        "symbol": "CHRD",
        "name": "Chord Energy Corporation Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "CHRS": {
        "symbol": "CHRS",
        "name": "Coherus BioSciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CHRW": {
        "symbol": "CHRW",
        "name": "C.H. Robinson Worldwide Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "CHSCL": {
        "symbol": "CHSCL",
        "name": "CHS Inc Class B Cumulative Redeemable Preferred Stock Series 4",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "CHSCM": {
        "symbol": "CHSCM",
        "name": "CHS Inc Class B Reset Rate Cumulative Redeemable Preferred Stock Series 3",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "CHSCN": {
        "symbol": "CHSCN",
        "name": "CHS Inc Preferred Class B Series 2 Reset Rate",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "CHSCO": {
        "symbol": "CHSCO",
        "name": "CHS Inc. Class B Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "CHSCP": {
        "symbol": "CHSCP",
        "name": "CHS Inc. 8%  Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "CHSN": {
        "symbol": "CHSN",
        "name": "Chanson International Holding Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "CHTR": {
        "symbol": "CHTR",
        "name": "Charter Communications Inc. Class A Common Stock New",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "CHUY": {
        "symbol": "CHUY",
        "name": "Chuy's Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "CHW": {
        "symbol": "CHW",
        "name": "Calamos Global Dynamic Income Fund Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CHX": {
        "symbol": "CHX",
        "name": "ChampionX Corporation Common Stock ",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CHY": {
        "symbol": "CHY",
        "name": "Calamos Convertible and High Income Fund Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CIFR": {
        "symbol": "CIFR",
        "name": "Cipher Mining Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CIFRW": {
        "symbol": "CIFRW",
        "name": "Cipher Mining Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CIGI": {
        "symbol": "CIGI",
        "name": "Colliers International Group Inc. Subordinate Voting Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "CINF": {
        "symbol": "CINF",
        "name": "Cincinnati Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "CING": {
        "symbol": "CING",
        "name": "Cingulate Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CINGW": {
        "symbol": "CINGW",
        "name": "Cingulate Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CISO": {
        "symbol": "CISO",
        "name": "CISO Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "CISS": {
        "symbol": "CISS",
        "name": "C3is Inc. Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "CITE": {
        "symbol": "CITE",
        "name": "Cartica Acquisition Corp Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CIVB": {
        "symbol": "CIVB",
        "name": "Civista Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CJET": {
        "symbol": "CJET",
        "name": "Chijet Motor Company Inc. Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "CJJD": {
        "symbol": "CJJD",
        "name": "China Jo-Jo Drugstores Inc. (Cayman Islands) Ordinary Shares",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Retail-Drug Stores and Proprietary Stores"
    },
    "CKPT": {
        "symbol": "CKPT",
        "name": "Checkpoint Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLAR": {
        "symbol": "CLAR",
        "name": "Clarus Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "CLBK": {
        "symbol": "CLBK",
        "name": "Columbia Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CLBT": {
        "symbol": "CLBT",
        "name": "Cellebrite DI Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CLDX": {
        "symbol": "CLDX",
        "name": "Celldex Therapeutics Inc.",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "CLEU": {
        "symbol": "CLEU",
        "name": "China Liberal Education Holdings Limited Ordinary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "CLFD": {
        "symbol": "CLFD",
        "name": "Clearfield Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "CLGN": {
        "symbol": "CLGN",
        "name": "CollPlant Biotechnologies Ltd Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "CLIR": {
        "symbol": "CLIR",
        "name": "ClearSign Technologies Corporation Common Stock (DE)",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CLLS": {
        "symbol": "CLLS",
        "name": "Cellectis S.A. American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLMB": {
        "symbol": "CLMB",
        "name": "Climb Global Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Retail: Computer Software & Peripheral Equipment"
    },
    "CLMT": {
        "symbol": "CLMT",
        "name": "Calumet Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Integrated oil Companies"
    },
    "CLNE": {
        "symbol": "CLNE",
        "name": "Clean Energy Fuels Corp. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Natural Gas Distribution"
    },
    "CLNN": {
        "symbol": "CLNN",
        "name": "Clene Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLNNW": {
        "symbol": "CLNNW",
        "name": "Clene Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLOV": {
        "symbol": "CLOV",
        "name": "Clover Health Investments Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "CLPS": {
        "symbol": "CLPS",
        "name": "CLPS Incorporation Common Stock",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CLPT": {
        "symbol": "CLPT",
        "name": "ClearPoint Neuro Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "CLRB": {
        "symbol": "CLRB",
        "name": "Cellectar Biosciences Inc.  Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLRC": {
        "symbol": "CLRC",
        "name": "ClimateRock Class A Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CLRCR": {
        "symbol": "CLRCR",
        "name": "ClimateRock Right",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CLRO": {
        "symbol": "CLRO",
        "name": "ClearOne Inc. (DE) Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "CLSD": {
        "symbol": "CLSD",
        "name": "Clearside Biomedical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CLSK": {
        "symbol": "CLSK",
        "name": "CleanSpark Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CLST": {
        "symbol": "CLST",
        "name": "Catalyst Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CLWT": {
        "symbol": "CLWT",
        "name": "Euro Tech Holdings Company Limited Common Stock",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Professional and commerical equipment"
    },
    "CMAX": {
        "symbol": "CMAX",
        "name": "CareMax Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Hospital/Nursing Management"
    },
    "CMAXW": {
        "symbol": "CMAXW",
        "name": "CareMax Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Hospital/Nursing Management"
    },
    "CMBM": {
        "symbol": "CMBM",
        "name": "Cambium Networks Corporation Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "CMCO": {
        "symbol": "CMCO",
        "name": "Columbus McKinnon Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "CMCSA": {
        "symbol": "CMCSA",
        "name": "Comcast Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "CMCT": {
        "symbol": "CMCT",
        "name": "Creative Media & Community Trust Corporation Common stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "CME": {
        "symbol": "CME",
        "name": "CME Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "CMLS": {
        "symbol": "CMLS",
        "name": "Cumulus Media Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "CMMB": {
        "symbol": "CMMB",
        "name": "Chemomab Therapeutics Ltd. American Depositary Share",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CMND": {
        "symbol": "CMND",
        "name": "Clearmind Medicine Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CMPO": {
        "symbol": "CMPO",
        "name": "CompoSecure Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CMPOW": {
        "symbol": "CMPOW",
        "name": "CompoSecure Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CMPR": {
        "symbol": "CMPR",
        "name": "Cimpress plc Ordinary Shares (Ireland)",
        "country": "Netherlands",
        "sector": "Consumer Discretionary",
        "industry": "Publishing"
    },
    "CMPS": {
        "symbol": "CMPS",
        "name": "COMPASS Pathways Plc American Depository Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CMPX": {
        "symbol": "CMPX",
        "name": "Compass Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CMRX": {
        "symbol": "CMRX",
        "name": "Chimerix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CMTL": {
        "symbol": "CMTL",
        "name": "Comtech Telecommunications Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "CNDT": {
        "symbol": "CNDT",
        "name": "Conduent Incorporated Common Stock ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CNET": {
        "symbol": "CNET",
        "name": "ZW Data Action Technologies Inc. Common Stock",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "CNEY": {
        "symbol": "CNEY",
        "name": "CN Energy Group Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "CNFR": {
        "symbol": "CNFR",
        "name": "Conifer Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "CNFRZ": {
        "symbol": "CNFRZ",
        "name": "Conifer Holdings Inc. 9.75% Senior Unsecured Notes due 2028",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CNOB": {
        "symbol": "CNOB",
        "name": "ConnectOne Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CNOBP": {
        "symbol": "CNOBP",
        "name": "ConnectOne Bancorp Inc. Depositary Shares each representing a 1/40th interest in a share of 5.25% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CNSL": {
        "symbol": "CNSL",
        "name": "Consolidated Communications Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "CNSP": {
        "symbol": "CNSP",
        "name": "CNS Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CNTA": {
        "symbol": "CNTA",
        "name": "Centessa Pharmaceuticals plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CNTB": {
        "symbol": "CNTB",
        "name": "Connect Biopharma Holdings Limited American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CNTM": {
        "symbol": "CNTM",
        "name": "ConnectM Technology Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "CNTX": {
        "symbol": "CNTX",
        "name": "Context Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CNTY": {
        "symbol": "CNTY",
        "name": "Century Casinos Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "CNVS": {
        "symbol": "CNVS",
        "name": "Cineverse Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Video Chains"
    },
    "CNXC": {
        "symbol": "CNXC",
        "name": "Concentrix Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CNXN": {
        "symbol": "CNXN",
        "name": "PC Connection Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "COCH": {
        "symbol": "COCH",
        "name": "Envoy Medical Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "COCO": {
        "symbol": "COCO",
        "name": "The Vita Coco Company Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "COCP": {
        "symbol": "COCP",
        "name": "Cocrystal Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CODA": {
        "symbol": "CODA",
        "name": "Coda Octopus Group Inc. Common stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CODX": {
        "symbol": "CODX",
        "name": "Co-Diagnostics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "COEP": {
        "symbol": "COEP",
        "name": "Coeptis Therapeutics Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "COEPW": {
        "symbol": "COEPW",
        "name": "Coeptis Therapeutics Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "COFS": {
        "symbol": "COFS",
        "name": "ChoiceOne Financial Services Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "COGT": {
        "symbol": "COGT",
        "name": "Cogent Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "COHU": {
        "symbol": "COHU",
        "name": "Cohu Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "COIN": {
        "symbol": "COIN",
        "name": "Coinbase Global Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "COKE": {
        "symbol": "COKE",
        "name": "Coca-Cola Consolidated Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "COLB": {
        "symbol": "COLB",
        "name": "Columbia Banking System Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "COLL": {
        "symbol": "COLL",
        "name": "Collegium Pharmaceutical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "COLM": {
        "symbol": "COLM",
        "name": "Columbia Sportswear Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "COMM": {
        "symbol": "COMM",
        "name": "CommScope Holding Company Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "COO": {
        "symbol": "COO",
        "name": "The Cooper Companies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "COOP": {
        "symbol": "COOP",
        "name": "Mr. Cooper Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "COOT": {
        "symbol": "COOT",
        "name": "Australian Oilseeds Holdings Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "COOTW": {
        "symbol": "COOTW",
        "name": "Australian Oilseeds Holdings Limited Warrant",
        "country": "Australia",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "CORT": {
        "symbol": "CORT",
        "name": "Corcept Therapeutics Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CORZ": {
        "symbol": "CORZ",
        "name": "Core Scientific Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CORZW": {
        "symbol": "CORZW",
        "name": "Core Scientific Inc. Tranche 1 Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CORZZ": {
        "symbol": "CORZZ",
        "name": "Core Scientific Inc. Tranche 2 Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "COSM": {
        "symbol": "COSM",
        "name": "Cosmos Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Other Pharmaceuticals"
    },
    "COST": {
        "symbol": "COST",
        "name": "Costco Wholesale Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Department/Specialty Retail Stores"
    },
    "COYA": {
        "symbol": "COYA",
        "name": "Coya Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CPB": {
        "symbol": "CPB",
        "name": "Campbell Soup Company Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "CPBI": {
        "symbol": "CPBI",
        "name": "Central Plains Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "CPHC": {
        "symbol": "CPHC",
        "name": "Canterbury Park Holding Corporation 'New' Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "CPIX": {
        "symbol": "CPIX",
        "name": "Cumberland Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CPOP": {
        "symbol": "CPOP",
        "name": "Pop Culture Group Co. Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "CPRT": {
        "symbol": "CPRT",
        "name": "Copart Inc. (DE) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "CPRX": {
        "symbol": "CPRX",
        "name": "Catalyst Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CPSH": {
        "symbol": "CPSH",
        "name": "CPS Technologies Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Building Materials"
    },
    "CPSS": {
        "symbol": "CPSS",
        "name": "Consumer Portfolio Services Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "CPTN": {
        "symbol": "CPTN",
        "name": "Cepton Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CPTNW": {
        "symbol": "CPTNW",
        "name": "Cepton Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CPZ": {
        "symbol": "CPZ",
        "name": "Calamos Long/Short Equity & Dynamic Income Trust Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "CRAI": {
        "symbol": "CRAI",
        "name": "CRA International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "CRBP": {
        "symbol": "CRBP",
        "name": "Corbus Pharmaceuticals Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CRBU": {
        "symbol": "CRBU",
        "name": "Caribou Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CRCT": {
        "symbol": "CRCT",
        "name": "Cricut Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "CRDF": {
        "symbol": "CRDF",
        "name": "Cardiff Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CRDL": {
        "symbol": "CRDL",
        "name": "Cardiol Therapeutics Inc. Class A Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CRDO": {
        "symbol": "CRDO",
        "name": "Credo Technology Group Holding Ltd Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CREG": {
        "symbol": "CREG",
        "name": "Smart Powerr Corp. Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CRESW": {
        "symbol": "CRESW",
        "name": "Cresud S.A.C.I.F. y A. Warrant",
        "country": "Argentina",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "CRESY": {
        "symbol": "CRESY",
        "name": "Cresud S.A.C.I.F. y A. American Depositary Shares",
        "country": "Argentina",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "CREV": {
        "symbol": "CREV",
        "name": "Carbon Revolution Public Limited Ordinary Shares",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CREVW": {
        "symbol": "CREVW",
        "name": "Carbon Revolution Public Limited Company Warrant",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CREX": {
        "symbol": "CREX",
        "name": "Creative Realities Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CRGO": {
        "symbol": "CRGO",
        "name": "Freightos Limited Ordinary shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "CRGOW": {
        "symbol": "CRGOW",
        "name": "Freightos Limited Warrants",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "CRGX": {
        "symbol": "CRGX",
        "name": "CARGO Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CRIS": {
        "symbol": "CRIS",
        "name": "Curis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CRKN": {
        "symbol": "CRKN",
        "name": "Crown Electrokinetics Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "CRMD": {
        "symbol": "CRMD",
        "name": "CorMedix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CRML": {
        "symbol": "CRML",
        "name": "Critical Metals Corp. Ordinary Shares",
        "country": "British Virgin Islands",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "CRMLW": {
        "symbol": "CRMLW",
        "name": "Critical Metals Corp. Warrants",
        "country": "British Virgin Islands",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "CRMT": {
        "symbol": "CRMT",
        "name": "America's Car-Mart Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "CRNC": {
        "symbol": "CRNC",
        "name": "Cerence Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CRNT": {
        "symbol": "CRNT",
        "name": "Ceragon Networks Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "CRNX": {
        "symbol": "CRNX",
        "name": "Crinetics Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CRON": {
        "symbol": "CRON",
        "name": "Cronos Group Inc. Common Share",
        "country": "Canada",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "CROX": {
        "symbol": "CROX",
        "name": "Crocs Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Shoe Manufacturing"
    },
    "CRSP": {
        "symbol": "CRSP",
        "name": "CRISPR Therapeutics AG Common Shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CRSR": {
        "symbol": "CRSR",
        "name": "Corsair Gaming Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "CRTO": {
        "symbol": "CRTO",
        "name": "Criteo S.A. American Depositary Shares",
        "country": "France",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "CRUS": {
        "symbol": "CRUS",
        "name": "Cirrus Logic Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CRVL": {
        "symbol": "CRVL",
        "name": "CorVel Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "CRVO": {
        "symbol": "CRVO",
        "name": "CervoMed Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CRVS": {
        "symbol": "CRVS",
        "name": "Corvus Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CRWD": {
        "symbol": "CRWD",
        "name": "CrowdStrike Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CRWS": {
        "symbol": "CRWS",
        "name": "Crown Crafts Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Textiles"
    },
    "CSBR": {
        "symbol": "CSBR",
        "name": "Champions Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CSCI": {
        "symbol": "CSCI",
        "name": "COSCIENS Biopharma Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CSCO": {
        "symbol": "CSCO",
        "name": "Cisco Systems Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "CSGP": {
        "symbol": "CSGP",
        "name": "CoStar Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CSGS": {
        "symbol": "CSGS",
        "name": "CSG Systems International Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CSIQ": {
        "symbol": "CSIQ",
        "name": "Canadian Solar Inc. Common Shares (ON)",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CSLM": {
        "symbol": "CSLM",
        "name": "CSLM Acquisition Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CSLR": {
        "symbol": "CSLR",
        "name": "Complete Solaria Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CSLRW": {
        "symbol": "CSLRW",
        "name": "Complete Solaria Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "CSPI": {
        "symbol": "CSPI",
        "name": "CSP Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CSQ": {
        "symbol": "CSQ",
        "name": "Calamos Strategic Total Return Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "CSTE": {
        "symbol": "CSTE",
        "name": "Caesarstone Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Building Materials"
    },
    "CSTL": {
        "symbol": "CSTL",
        "name": "Castle Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "CSWC": {
        "symbol": "CSWC",
        "name": "Capital Southwest Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Textiles"
    },
    "CSWCZ": {
        "symbol": "CSWCZ",
        "name": "Capital Southwest Corporation 7.75% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "CSWI": {
        "symbol": "CSWI",
        "name": "CSW Industrials Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Home Furnishings"
    },
    "CSX": {
        "symbol": "CSX",
        "name": "CSX Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Railroads"
    },
    "CTAS": {
        "symbol": "CTAS",
        "name": "Cintas Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "CTBI": {
        "symbol": "CTBI",
        "name": "Community Trust Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CTCX": {
        "symbol": "CTCX",
        "name": "Carmell Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "CTHR": {
        "symbol": "CTHR",
        "name": "Charles & Colvard Ltd Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "CTKB": {
        "symbol": "CTKB",
        "name": "Cytek Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "CTLP": {
        "symbol": "CTLP",
        "name": "Cantaloupe Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Office Equipment/Supplies/Services"
    },
    "CTMX": {
        "symbol": "CTMX",
        "name": "CytomX Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CTNM": {
        "symbol": "CTNM",
        "name": "Contineum Therapeutics Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CTNT": {
        "symbol": "CTNT",
        "name": "Cheetah Net Supply Chain Service Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "CTOR": {
        "symbol": "CTOR",
        "name": "Citius Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CTRM": {
        "symbol": "CTRM",
        "name": "Castor Maritime Inc. Common Shares",
        "country": "Cyprus",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "CTRN": {
        "symbol": "CTRN",
        "name": "Citi Trends Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "CTSH": {
        "symbol": "CTSH",
        "name": "Cognizant Technology Solutions Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CTSO": {
        "symbol": "CTSO",
        "name": "Cytosorbents Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "CTXR": {
        "symbol": "CTXR",
        "name": "Citius Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CUB": {
        "symbol": "CUB",
        "name": "Lionheart Holdings Class A Ordinary Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "CUBA": {
        "symbol": "CUBA",
        "name": "Herzfeld Caribbean Basin Fund Inc. (The) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "CUBWU": {
        "symbol": "CUBWU",
        "name": "Lionheart Holdings Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "CUBWW": {
        "symbol": "CUBWW",
        "name": "Lionheart Holdings Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "CUE": {
        "symbol": "CUE",
        "name": "Cue Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CURI": {
        "symbol": "CURI",
        "name": "CuriosityStream Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "CURIW": {
        "symbol": "CURIW",
        "name": "CuriosityStream Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "CURR": {
        "symbol": "CURR",
        "name": "Currenc Group Inc. Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "CUTR": {
        "symbol": "CUTR",
        "name": "Cutera Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "CVAC": {
        "symbol": "CVAC",
        "name": "CureVac N.V. Ordinary Shares",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CVBF": {
        "symbol": "CVBF",
        "name": "CVB Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CVCO": {
        "symbol": "CVCO",
        "name": "Cavco Industries Inc. Common Stock When Issued",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "CVGI": {
        "symbol": "CVGI",
        "name": "Commercial Vehicle Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "CVGW": {
        "symbol": "CVGW",
        "name": "Calavo Growers Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Specialty Foods"
    },
    "CVKD": {
        "symbol": "CVKD",
        "name": "Cadrenal Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CVLT": {
        "symbol": "CVLT",
        "name": "Commvault Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CVRX": {
        "symbol": "CVRX",
        "name": "CVRx Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "CVV": {
        "symbol": "CVV",
        "name": "CVD Equipment Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "CWBC": {
        "symbol": "CWBC",
        "name": "Community West Bancshares Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CWCO": {
        "symbol": "CWCO",
        "name": "Consolidated Water Co. Ltd. Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "CWD": {
        "symbol": "CWD",
        "name": "CaliberCos Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "CWST": {
        "symbol": "CWST",
        "name": "Casella Waste Systems Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Environmental Services"
    },
    "CXAI": {
        "symbol": "CXAI",
        "name": "CXApp Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CXAIW": {
        "symbol": "CXAIW",
        "name": "CXApp Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CXDO": {
        "symbol": "CXDO",
        "name": "Crexendo Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "CYBR": {
        "symbol": "CYBR",
        "name": "CyberArk Software Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "CYCC": {
        "symbol": "CYCC",
        "name": "Cyclacel Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CYCCP": {
        "symbol": "CYCCP",
        "name": "Cyclacel Pharmaceuticals Inc. 6% Convertible Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CYCN": {
        "symbol": "CYCN",
        "name": "Cyclerion Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CYN": {
        "symbol": "CYN",
        "name": "Cyngn Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "CYRX": {
        "symbol": "CYRX",
        "name": "CryoPort Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CYTH": {
        "symbol": "CYTH",
        "name": "Cyclo Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "CYTK": {
        "symbol": "CYTK",
        "name": "Cytokinetics Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CYTO": {
        "symbol": "CYTO",
        "name": "Altamira Therapeutics Ltd. Common Shares",
        "country": "Bermuda",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "CZFS": {
        "symbol": "CZFS",
        "name": "Citizens Financial Services Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CZNC": {
        "symbol": "CZNC",
        "name": "Citizens & Northern Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "CZR": {
        "symbol": "CZR",
        "name": "Caesars Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "CZWI": {
        "symbol": "CZWI",
        "name": "Citizens Community Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "DADA": {
        "symbol": "DADA",
        "name": "Dada Nexus Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "DAIO": {
        "symbol": "DAIO",
        "name": "Data I/O Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "DAKT": {
        "symbol": "DAKT",
        "name": "Daktronics Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "DALN": {
        "symbol": "DALN",
        "name": "DallasNews Corporation Series A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "DARE": {
        "symbol": "DARE",
        "name": "Dare Bioscience Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DASH": {
        "symbol": "DASH",
        "name": "DoorDash Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "DATS": {
        "symbol": "DATS",
        "name": "DatChat Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "DATSW": {
        "symbol": "DATSW",
        "name": "DatChat Inc. Series A Warrant",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "DAVE": {
        "symbol": "DAVE",
        "name": "Dave Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "DAVEW": {
        "symbol": "DAVEW",
        "name": "Dave Inc. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "DAWN": {
        "symbol": "DAWN",
        "name": "Day One Biopharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DBGI": {
        "symbol": "DBGI",
        "name": "Digital Brands Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "DBGIW": {
        "symbol": "DBGIW",
        "name": "Digital Brands Group Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "DBVT": {
        "symbol": "DBVT",
        "name": "DBV Technologies S.A. American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "DBX": {
        "symbol": "DBX",
        "name": "Dropbox Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DCBO": {
        "symbol": "DCBO",
        "name": "Docebo Inc. Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DCGO": {
        "symbol": "DCGO",
        "name": "DocGo Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "DCOM": {
        "symbol": "DCOM",
        "name": "Dime Community Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "DCOMG": {
        "symbol": "DCOMG",
        "name": "Dime Community Bancshares Inc. 9.000% Fixed-to-Floating Rate Subordinated Notes due 2034",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "DCOMP": {
        "symbol": "DCOMP",
        "name": "Dime Community Bancshares Inc. Fixed-Rate Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "DCTH": {
        "symbol": "DCTH",
        "name": "Delcath Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DDI": {
        "symbol": "DDI",
        "name": "DoubleDown Interactive Co. Ltd. American Depository Shares",
        "country": "South Korea",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "DDOG": {
        "symbol": "DDOG",
        "name": "Datadog Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DECA": {
        "symbol": "DECA",
        "name": "Denali Capital Acquisition Corp. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "DENN": {
        "symbol": "DENN",
        "name": "Denny's Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "DERM": {
        "symbol": "DERM",
        "name": "Journey Medical Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DFLI": {
        "symbol": "DFLI",
        "name": "Dragonfly Energy Holdings Corp. Common Stock (NV)",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "DFLIW": {
        "symbol": "DFLIW",
        "name": "Dragonfly Energy Holdings Corp. Warrant",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "DGHI": {
        "symbol": "DGHI",
        "name": "Digihost Technology Inc. Common Subordinate Voting Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "DGICA": {
        "symbol": "DGICA",
        "name": "Donegal Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "DGICB": {
        "symbol": "DGICB",
        "name": "Donegal Group Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "DGII": {
        "symbol": "DGII",
        "name": "Digi International Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "DGLY": {
        "symbol": "DGLY",
        "name": "Digital Ally Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "DH": {
        "symbol": "DH",
        "name": "Definitive Healthcare Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DHAI": {
        "symbol": "DHAI",
        "name": "DIH Holdings US Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DHAIW": {
        "symbol": "DHAIW",
        "name": "DIH Holding US Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DHC": {
        "symbol": "DHC",
        "name": "Diversified Healthcare Trust Common Shares of Beneficial Interest",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "DHCNI": {
        "symbol": "DHCNI",
        "name": "Diversified Healthcare Trust 5.625% Senior Notes due 2042",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "DHCNL": {
        "symbol": "DHCNL",
        "name": "Diversified Healthcare Trust 6.25% Senior Notes Due 2046",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "DHIL": {
        "symbol": "DHIL",
        "name": "Diamond Hill Investment Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "DIBS": {
        "symbol": "DIBS",
        "name": "1stdibs.com Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "DIOD": {
        "symbol": "DIOD",
        "name": "Diodes Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "DIST": {
        "symbol": "DIST",
        "name": "Distoken Acquisition Corporation Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "DJCO": {
        "symbol": "DJCO",
        "name": "Daily Journal Corp. (S.C.) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "DJT": {
        "symbol": "DJT",
        "name": "Trump Media & Technology Group Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "DJTWW": {
        "symbol": "DJTWW",
        "name": "Trump Media & Technology Group Corp. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "DKNG": {
        "symbol": "DKNG",
        "name": "DraftKings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "DLHC": {
        "symbol": "DLHC",
        "name": "DLH Holdings Corp.",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "DLO": {
        "symbol": "DLO",
        "name": "DLocal Limited Class A Common Shares",
        "country": "Uruguay",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "DLPN": {
        "symbol": "DLPN",
        "name": "Dolphin Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "DLTH": {
        "symbol": "DLTH",
        "name": "Duluth Holdings Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "DLTR": {
        "symbol": "DLTR",
        "name": "Dollar Tree Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Department/Specialty Retail Stores"
    },
    "DMAC": {
        "symbol": "DMAC",
        "name": "DiaMedica Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DMLP": {
        "symbol": "DMLP",
        "name": "Dorchester Minerals L.P. Common Units Representing Limited Partnership Interests",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "DMRC": {
        "symbol": "DMRC",
        "name": "Digimarc Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "DNLI": {
        "symbol": "DNLI",
        "name": "Denali Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "DNTH": {
        "symbol": "DNTH",
        "name": "Dianthus Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DNUT": {
        "symbol": "DNUT",
        "name": "Krispy Kreme Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "DOCU": {
        "symbol": "DOCU",
        "name": "DocuSign Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DOGZ": {
        "symbol": "DOGZ",
        "name": "Dogness (International) Corporation Class A Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "DOMH": {
        "symbol": "DOMH",
        "name": "Dominari Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DOMO": {
        "symbol": "DOMO",
        "name": "Domo Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DOOO": {
        "symbol": "DOOO",
        "name": "BRP Inc. (Recreational Products) Common Subordinate Voting Shares",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "DORM": {
        "symbol": "DORM",
        "name": "Dorman Products Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "DOX": {
        "symbol": "DOX",
        "name": "Amdocs Limited Ordinary Shares",
        "country": "Guernsey",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "DOYU": {
        "symbol": "DOYU",
        "name": "DouYu International Holdings Limited ADS",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "DPCSW": {
        "symbol": "DPCSW",
        "name": "DP Cap Acquisition Corp I Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "DPRO": {
        "symbol": "DPRO",
        "name": "Draganfly Inc. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "DRCT": {
        "symbol": "DRCT",
        "name": "Direct Digital Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "DRIO": {
        "symbol": "DRIO",
        "name": "DarioHealth Corp. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DRMA": {
        "symbol": "DRMA",
        "name": "Dermata Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DRMAW": {
        "symbol": "DRMAW",
        "name": "Dermata Therapeutics Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DRRX": {
        "symbol": "DRRX",
        "name": "DURECT Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DRS": {
        "symbol": "DRS",
        "name": "Leonardo DRS Inc. Common Stock",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "DRTS": {
        "symbol": "DRTS",
        "name": "Alpha Tau Medical Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DRTSW": {
        "symbol": "DRTSW",
        "name": "Alpha Tau Medical Ltd. Warrant",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DRUG": {
        "symbol": "DRUG",
        "name": "Bright Minds Biosciences Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DRVN": {
        "symbol": "DRVN",
        "name": "Driven Brands Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "DSGN": {
        "symbol": "DSGN",
        "name": "Design Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DSGR": {
        "symbol": "DSGR",
        "name": "Distribution Solutions Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "DSGX": {
        "symbol": "DSGX",
        "name": "Descartes Systems Group Inc. (The) Common Stock",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DSP": {
        "symbol": "DSP",
        "name": "Viant Technology Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "DSWL": {
        "symbol": "DSWL",
        "name": "Deswell Industries Inc. Common Shares",
        "country": "Macau",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "DSY": {
        "symbol": "DSY",
        "name": "Big Tree Cloud Holdings Limited Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Containers/Packaging"
    },
    "DSYWW": {
        "symbol": "DSYWW",
        "name": "Big Tree Cloud Holdings Limited Warrants",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Containers/Packaging"
    },
    "DTCK": {
        "symbol": "DTCK",
        "name": "Davis Commodities Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "DTI": {
        "symbol": "DTI",
        "name": "Drilling Tools International Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "DTIL": {
        "symbol": "DTIL",
        "name": "Precision BioSciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "DTSQ": {
        "symbol": "DTSQ",
        "name": "DT Cloud Star Acquisition Corporation Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "DTSQU": {
        "symbol": "DTSQU",
        "name": "DT Cloud Star Acquisition Corporation Units",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "DTSS": {
        "symbol": "DTSS",
        "name": "Datasea Inc. Common Stock",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DTST": {
        "symbol": "DTST",
        "name": "Data Storage Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "DTSTW": {
        "symbol": "DTSTW",
        "name": "Data Storage Corporation Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "DUET": {
        "symbol": "DUET",
        "name": "DUET Acquisition Corp. Class A Common Stock",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "DUETW": {
        "symbol": "DUETW",
        "name": "DUET Acquisition Corp. Warrant",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "DUO": {
        "symbol": "DUO",
        "name": "Fangdd Network Group Ltd. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "DUOL": {
        "symbol": "DUOL",
        "name": "Duolingo Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DUOT": {
        "symbol": "DUOT",
        "name": "Duos Technologies Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "DVAX": {
        "symbol": "DVAX",
        "name": "Dynavax Technologies Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "DWSN": {
        "symbol": "DWSN",
        "name": "Dawson Geophysical Company Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "DXCM": {
        "symbol": "DXCM",
        "name": "DexCom Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DXLG": {
        "symbol": "DXLG",
        "name": "Destination XL Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "DXPE": {
        "symbol": "DXPE",
        "name": "DXP Enterprises Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "DXR": {
        "symbol": "DXR",
        "name": "Daxor Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "DXYN": {
        "symbol": "DXYN",
        "name": "Dixie Group Inc. (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "DYAI": {
        "symbol": "DYAI",
        "name": "Dyadic International Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "DYCQ": {
        "symbol": "DYCQ",
        "name": "DT Cloud Acquisition Corporation Ordinary Shares",
        "country": "United Kingdom",
        "sector": "",
        "industry": ""
    },
    "DYN": {
        "symbol": "DYN",
        "name": "Dyne Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EA": {
        "symbol": "EA",
        "name": "Electronic Arts Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "EAST": {
        "symbol": "EAST",
        "name": "Eastside Distilling Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "EBAY": {
        "symbol": "EBAY",
        "name": "eBay Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "EBC": {
        "symbol": "EBC",
        "name": "Eastern Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "EBMT": {
        "symbol": "EBMT",
        "name": "Eagle Bancorp Montana Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "EBON": {
        "symbol": "EBON",
        "name": "Ebang International Holdings Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "EBTC": {
        "symbol": "EBTC",
        "name": "Enterprise Bancorp Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ECBK": {
        "symbol": "ECBK",
        "name": "ECB Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "ECDA": {
        "symbol": "ECDA",
        "name": "ECD Automotive Design Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "ECDAW": {
        "symbol": "ECDAW",
        "name": "ECD Automotive Design Inc. Warrant ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "ECOR": {
        "symbol": "ECOR",
        "name": "electroCore Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "ECPG": {
        "symbol": "ECPG",
        "name": "Encore Capital Group Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "ECX": {
        "symbol": "ECX",
        "name": "ECARX Holdings Inc. Class A Ordinary shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ECXWW": {
        "symbol": "ECXWW",
        "name": "ECARX Holdings Inc. Warrants",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "EDAP": {
        "symbol": "EDAP",
        "name": "EDAP TMS S.A. American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "EDBL": {
        "symbol": "EDBL",
        "name": "Edible Garden AG Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "EDBLW": {
        "symbol": "EDBLW",
        "name": "Edible Garden AG Incorporated Warrant",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "EDIT": {
        "symbol": "EDIT",
        "name": "Editas Medicine Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "EDRY": {
        "symbol": "EDRY",
        "name": "EuroDry Ltd. Common Shares ",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "EDSA": {
        "symbol": "EDSA",
        "name": "Edesa Biotech Inc. Common Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EDTK": {
        "symbol": "EDTK",
        "name": "Skillful Craftsman Education Technology Limited Ordinary Share",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "EDUC": {
        "symbol": "EDUC",
        "name": "Educational Development Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "EEFT": {
        "symbol": "EEFT",
        "name": "Euronet Worldwide Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "EEIQ": {
        "symbol": "EEIQ",
        "name": "EpicQuest Education Group International Limited Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "EFOI": {
        "symbol": "EFOI",
        "name": "Energy Focus Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Building Products"
    },
    "EFSC": {
        "symbol": "EFSC",
        "name": "Enterprise Financial Services Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "EFSCP": {
        "symbol": "EFSCP",
        "name": "Enterprise Financial Services Corporation Depositary Shares Each Representing a 1/40th Interest in a Share of 5% Fixed Rate Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "EGAN": {
        "symbol": "EGAN",
        "name": "eGain Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "EGBN": {
        "symbol": "EGBN",
        "name": "Eagle Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "EGHT": {
        "symbol": "EGHT",
        "name": "8x8 Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "EGRX": {
        "symbol": "EGRX",
        "name": "Eagle Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EH": {
        "symbol": "EH",
        "name": "EHang Holdings Limited ADS",
        "country": "China",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "EHGO": {
        "symbol": "EHGO",
        "name": "Eshallgo Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "EHTH": {
        "symbol": "EHTH",
        "name": "eHealth Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "EJH": {
        "symbol": "EJH",
        "name": "E-Home Household Service Holdings Limited Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "EKSO": {
        "symbol": "EKSO",
        "name": "Ekso Bionics Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "ELAB": {
        "symbol": "ELAB",
        "name": "Elevai Labs Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ELBM": {
        "symbol": "ELBM",
        "name": "Electra Battery Materials Corporation Common Stock",
        "country": "Canada",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ELDN": {
        "symbol": "ELDN",
        "name": "Eledon Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ELEV": {
        "symbol": "ELEV",
        "name": "Elevation Oncology Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ELSE": {
        "symbol": "ELSE",
        "name": "Electro-Sensors Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "ELTK": {
        "symbol": "ELTK",
        "name": "Eltek Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "ELTX": {
        "symbol": "ELTX",
        "name": "Elicio Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ELUT": {
        "symbol": "ELUT",
        "name": "Elutia Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ELVA": {
        "symbol": "ELVA",
        "name": "Electrovaya Inc. Common Shares",
        "country": "Canada",
        "sector": "",
        "industry": ""
    },
    "ELVN": {
        "symbol": "ELVN",
        "name": "Enliven Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ELWS": {
        "symbol": "ELWS",
        "name": "Earlyworks Co. Ltd. American Depositary Shares",
        "country": "Japan",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ELYM": {
        "symbol": "ELYM",
        "name": "Eliem Therapeutics Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EM": {
        "symbol": "EM",
        "name": "Smart Share Global Limited American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "EMBC": {
        "symbol": "EMBC",
        "name": "Embecta Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "EMCG": {
        "symbol": "EMCG",
        "name": "Embrace Change Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "EMKR": {
        "symbol": "EMKR",
        "name": "EMCORE Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "EML": {
        "symbol": "EML",
        "name": "Eastern Company (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Machinery/Components"
    },
    "ENG": {
        "symbol": "ENG",
        "name": "ENGlobal Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Military/Government/Technical"
    },
    "ENGN": {
        "symbol": "ENGN",
        "name": "enGene Holdings Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ENLT": {
        "symbol": "ENLT",
        "name": "Enlight Renewable Energy Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "ENLV": {
        "symbol": "ENLV",
        "name": "Enlivex Therapeutics Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ENPH": {
        "symbol": "ENPH",
        "name": "Enphase Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ENSC": {
        "symbol": "ENSC",
        "name": "Ensysce Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ENSG": {
        "symbol": "ENSG",
        "name": "The Ensign Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Hospital/Nursing Management"
    },
    "ENTA": {
        "symbol": "ENTA",
        "name": "Enanta Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ENTG": {
        "symbol": "ENTG",
        "name": "Entegris Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "ENTO": {
        "symbol": "ENTO",
        "name": "Entero Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ENTX": {
        "symbol": "ENTX",
        "name": "Entera Bio Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ENVB": {
        "symbol": "ENVB",
        "name": "Enveric Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ENVX": {
        "symbol": "ENVX",
        "name": "Enovix Corporation Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "EOLS": {
        "symbol": "EOLS",
        "name": "Evolus Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EOSE": {
        "symbol": "EOSE",
        "name": "Eos Energy Enterprises Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "EOSEW": {
        "symbol": "EOSEW",
        "name": "Eos Energy Enterprises Inc. Warrant",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "EPIX": {
        "symbol": "EPIX",
        "name": "ESSA Pharma Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EPOW": {
        "symbol": "EPOW",
        "name": "Sunrise New Energy Co. Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "EPRX": {
        "symbol": "EPRX",
        "name": "Eupraxia Pharmaceuticals Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Pharmaceuticals and Biotechnology"
    },
    "EPSN": {
        "symbol": "EPSN",
        "name": "Epsilon Energy Ltd. Common Share",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "EQ": {
        "symbol": "EQ",
        "name": "Equillium Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EQIX": {
        "symbol": "EQIX",
        "name": "Equinix Inc. Common Stock REIT",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "ERAS": {
        "symbol": "ERAS",
        "name": "Erasca Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ERIC": {
        "symbol": "ERIC",
        "name": "Ericsson American Depositary Shares",
        "country": "Sweden",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "ERIE": {
        "symbol": "ERIE",
        "name": "Erie Indemnity Company Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "ERII": {
        "symbol": "ERII",
        "name": "Energy Recovery Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ERNA": {
        "symbol": "ERNA",
        "name": "Eterna Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ESCA": {
        "symbol": "ESCA",
        "name": "Escalade Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "ESEA": {
        "symbol": "ESEA",
        "name": "Euroseas Ltd. Common Stock (Marshall Islands)",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "ESGL": {
        "symbol": "ESGL",
        "name": "ESGL Holdings Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Utilities",
        "industry": "Environmental Services"
    },
    "ESGLW": {
        "symbol": "ESGLW",
        "name": "ESGL Holdings Limited Warrants",
        "country": "Singapore",
        "sector": "Utilities",
        "industry": "Environmental Services"
    },
    "ESGR": {
        "symbol": "ESGR",
        "name": "Enstar Group Limited Ordinary Shares",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ESGRO": {
        "symbol": "ESGRO",
        "name": "Enstar Group Limited Depository Shares 7.00% Perpetual Non-Cumulative Preference Shares Series E",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ESGRP": {
        "symbol": "ESGRP",
        "name": "Enstar Group Limited Depositary Shares Each Representing 1/1000th of an interest in Preference Shares",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ESHA": {
        "symbol": "ESHA",
        "name": "ESH Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ESHAR": {
        "symbol": "ESHAR",
        "name": "ESH Acquisition Corp. Right",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ESLA": {
        "symbol": "ESLA",
        "name": "Estrella Immunopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ESLAW": {
        "symbol": "ESLAW",
        "name": "Estrella Immunopharma Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ESLT": {
        "symbol": "ESLT",
        "name": "Elbit Systems Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "ESOA": {
        "symbol": "ESOA",
        "name": "Energy Services of America Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Water Sewer Pipeline Comm & Power Line Construction"
    },
    "ESPR": {
        "symbol": "ESPR",
        "name": "Esperion Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ESQ": {
        "symbol": "ESQ",
        "name": "Esquire Financial Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "ESSA": {
        "symbol": "ESSA",
        "name": "ESSA Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "ESTA": {
        "symbol": "ESTA",
        "name": "Establishment Labs Holdings Inc. Common Shares",
        "country": "Costa Rica",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "ETHA": {
        "symbol": "ETHA",
        "name": "iShares Ethereum Trust ETF",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "ETNB": {
        "symbol": "ETNB",
        "name": "89bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ETON": {
        "symbol": "ETON",
        "name": "Eton Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ETSY": {
        "symbol": "ETSY",
        "name": "Etsy Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "EU": {
        "symbol": "EU",
        "name": "enCore Energy Corp. Common Shares",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Other Metals and Minerals"
    },
    "EUDA": {
        "symbol": "EUDA",
        "name": "EUDA Health Holdings Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "EUDAW": {
        "symbol": "EUDAW",
        "name": "EUDA Health Holdings Limited Warrant",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "EURK": {
        "symbol": "EURK",
        "name": "Eureka Acquisition Corp Class A Ordinary Share",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "EURKR": {
        "symbol": "EURKR",
        "name": "Eureka Acquisition Corp Right",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "EURKU": {
        "symbol": "EURKU",
        "name": "Eureka Acquisition Corp Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "EVAX": {
        "symbol": "EVAX",
        "name": "Evaxion Biotech A/S American Depositary Share",
        "country": "Denmark",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "EVCM": {
        "symbol": "EVCM",
        "name": "EverCommerce Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "EVER": {
        "symbol": "EVER",
        "name": "EverQuote Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "EVGN": {
        "symbol": "EVGN",
        "name": "Evogene Ltd Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Agricultural Chemicals"
    },
    "EVGO": {
        "symbol": "EVGO",
        "name": "EVgo Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "EVGOW": {
        "symbol": "EVGOW",
        "name": "EVgo Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "EVGR": {
        "symbol": "EVGR",
        "name": "Evergreen Corporation Class A Ordinary Share",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "EVGRW": {
        "symbol": "EVGRW",
        "name": "Evergreen Corporation Warrant",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "EVLV": {
        "symbol": "EVLV",
        "name": "Evolv Technologies Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "EVLVW": {
        "symbol": "EVLVW",
        "name": "Evolv Technologies Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "EVO": {
        "symbol": "EVO",
        "name": "Evotec SE American Depositary Shares",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EVOK": {
        "symbol": "EVOK",
        "name": "Evoke Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EVRG": {
        "symbol": "EVRG",
        "name": "Evergy Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "EVTV": {
        "symbol": "EVTV",
        "name": "Envirotech Vehicles Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "EWBC": {
        "symbol": "EWBC",
        "name": "East West Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "EWCZ": {
        "symbol": "EWCZ",
        "name": "European Wax Center Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "EWTX": {
        "symbol": "EWTX",
        "name": "Edgewise Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EXAI": {
        "symbol": "EXAI",
        "name": "Exscientia Plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "EXAS": {
        "symbol": "EXAS",
        "name": "Exact Sciences Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "EXC": {
        "symbol": "EXC",
        "name": "Exelon Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "EXEL": {
        "symbol": "EXEL",
        "name": "Exelixis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "EXFY": {
        "symbol": "EXFY",
        "name": "Expensify Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "EXLS": {
        "symbol": "EXLS",
        "name": "ExlService Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "EXPE": {
        "symbol": "EXPE",
        "name": "Expedia Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "EXPI": {
        "symbol": "EXPI",
        "name": "eXp World Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "EXPO": {
        "symbol": "EXPO",
        "name": "Exponent Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "EXTR": {
        "symbol": "EXTR",
        "name": "Extreme Networks Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "EYE": {
        "symbol": "EYE",
        "name": "National Vision Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "EYEN": {
        "symbol": "EYEN",
        "name": "Eyenovia Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "EYPT": {
        "symbol": "EYPT",
        "name": "EyePoint Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "EZFL": {
        "symbol": "EZFL",
        "name": "EzFill Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "EZGO": {
        "symbol": "EZGO",
        "name": "EZGO Technologies Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "EZPW": {
        "symbol": "EZPW",
        "name": "EZCORP Inc. Class A Non Voting Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "FA": {
        "symbol": "FA",
        "name": "First Advantage Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "FAAS": {
        "symbol": "FAAS",
        "name": "DigiAsia Corp. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FAASW": {
        "symbol": "FAASW",
        "name": "DigiAsia Corp. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FAMI": {
        "symbol": "FAMI",
        "name": "Farmmi Inc. Ordinary Shares",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "FANG": {
        "symbol": "FANG",
        "name": "Diamondback Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "FANH": {
        "symbol": "FANH",
        "name": "Fanhua Inc. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "FARM": {
        "symbol": "FARM",
        "name": "Farmer Brothers Company Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "FARO": {
        "symbol": "FARO",
        "name": "FARO Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "FAST": {
        "symbol": "FAST",
        "name": "Fastenal Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "FAT": {
        "symbol": "FAT",
        "name": "FAT Brands Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "FATBB": {
        "symbol": "FATBB",
        "name": "FAT Brands Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "FATBP": {
        "symbol": "FATBP",
        "name": "FAT Brands Inc. 8.25% Series B Cumulative Preferred Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "FATBW": {
        "symbol": "FATBW",
        "name": "FAT Brands Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "FATE": {
        "symbol": "FATE",
        "name": "Fate Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "FBIO": {
        "symbol": "FBIO",
        "name": "Fortress Biotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FBIOP": {
        "symbol": "FBIOP",
        "name": "Fortress Biotech Inc. 9.375% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FBIZ": {
        "symbol": "FBIZ",
        "name": "First Business Financial Services Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FBLG": {
        "symbol": "FBLG",
        "name": "FibroBiologics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FBNC": {
        "symbol": "FBNC",
        "name": "First Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FBRX": {
        "symbol": "FBRX",
        "name": "Forte Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FBYD": {
        "symbol": "FBYD",
        "name": "Falcon's Beyond Global Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "FBYDW": {
        "symbol": "FBYDW",
        "name": "Falcon's Beyond Global Inc. Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "FCAP": {
        "symbol": "FCAP",
        "name": "First Capital Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FCBC": {
        "symbol": "FCBC",
        "name": "First Community Bankshares Inc. (VA) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FCCO": {
        "symbol": "FCCO",
        "name": "First Community Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FCEL": {
        "symbol": "FCEL",
        "name": "FuelCell Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "FCFS": {
        "symbol": "FCFS",
        "name": "FirstCash Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "FCNCA": {
        "symbol": "FCNCA",
        "name": "First Citizens BancShares Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FCNCO": {
        "symbol": "FCNCO",
        "name": "First Citizens BancShares Inc. 5.625% Non-Cumulative Perpetual Preferred Stock Series C",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FCNCP": {
        "symbol": "FCNCP",
        "name": "First Citizens BancShares Inc. Depositary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FCUV": {
        "symbol": "FCUV",
        "name": "Focus Universal Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "FDBC": {
        "symbol": "FDBC",
        "name": "Fidelity D & D Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FDMT": {
        "symbol": "FDMT",
        "name": "4D Molecular Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "FDSB": {
        "symbol": "FDSB",
        "name": "Fifth District Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FDUS": {
        "symbol": "FDUS",
        "name": "Fidus Investment Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "FEAM": {
        "symbol": "FEAM",
        "name": "5E Advanced Materials Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "FEBO": {
        "symbol": "FEBO",
        "name": "Fenbo Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "FEIM": {
        "symbol": "FEIM",
        "name": "Frequency Electronics Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "FELE": {
        "symbol": "FELE",
        "name": "Franklin Electric Co. Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Metal Fabrications"
    },
    "FEMY": {
        "symbol": "FEMY",
        "name": "Femasys Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "FENC": {
        "symbol": "FENC",
        "name": "Fennec Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "FER": {
        "symbol": "FER",
        "name": "Ferrovial SE Ordinary Shares",
        "country": "Netherlands",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "FFBC": {
        "symbol": "FFBC",
        "name": "First Financial Bancorp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FFIC": {
        "symbol": "FFIC",
        "name": "Flushing Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FFIE": {
        "symbol": "FFIE",
        "name": "Faraday Future Intelligent Electric Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "FFIEW": {
        "symbol": "FFIEW",
        "name": "Faraday Future Intelligent Electric Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "FFIN": {
        "symbol": "FFIN",
        "name": "First Financial Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FFIV": {
        "symbol": "FFIV",
        "name": "F5 Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "FFNW": {
        "symbol": "FFNW",
        "name": "First Financial Northwest Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "FGBI": {
        "symbol": "FGBI",
        "name": "First Guaranty Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FGBIP": {
        "symbol": "FGBIP",
        "name": "First Guaranty Bancshares Inc. 6.75% Series A Fixed-Rate Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FGEN": {
        "symbol": "FGEN",
        "name": "FibroGen Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FGF": {
        "symbol": "FGF",
        "name": "Fundamental Global Inc. Common Stock ",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "FGFPP": {
        "symbol": "FGFPP",
        "name": "Fundamental Global Inc. 8.00% Cumulative Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "FGI": {
        "symbol": "FGI",
        "name": "FGI Industries Ltd. Ordinary Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "FGIWW": {
        "symbol": "FGIWW",
        "name": "FGI Industries Ltd. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "FHB": {
        "symbol": "FHB",
        "name": "First Hawaiian Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FHTX": {
        "symbol": "FHTX",
        "name": "Foghorn Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FIAC": {
        "symbol": "FIAC",
        "name": "Focus Impact Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FIACU": {
        "symbol": "FIACU",
        "name": "Focus Impact Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FIACW": {
        "symbol": "FIACW",
        "name": "Focus Impact Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FIBK": {
        "symbol": "FIBK",
        "name": "First Interstate BancSystem Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FINW": {
        "symbol": "FINW",
        "name": "FinWise Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FIP": {
        "symbol": "FIP",
        "name": "FTAI Infrastructure Inc. Common Stock ",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil Refining/Marketing"
    },
    "FISI": {
        "symbol": "FISI",
        "name": "Financial Institutions Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FITB": {
        "symbol": "FITB",
        "name": "Fifth Third Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FITBI": {
        "symbol": "FITBI",
        "name": "Fifth Third Bancorp Depositary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FITBO": {
        "symbol": "FITBO",
        "name": "Fifth Third Bancorp Depositary Shares each representing a 1/1000th ownership interest in a share of Non-Cumulative Perpetual Preferred Stock Series K",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FITBP": {
        "symbol": "FITBP",
        "name": "Fifth Third Bancorp Depositary Shares each representing 1/40th share of Fifth Third 6.00% Non-Cumulative Perpetual Class B Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FIVE": {
        "symbol": "FIVE",
        "name": "Five Below Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Department/Specialty Retail Stores"
    },
    "FIVN": {
        "symbol": "FIVN",
        "name": "Five9 Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "FIZZ": {
        "symbol": "FIZZ",
        "name": "National Beverage Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "FKWL": {
        "symbol": "FKWL",
        "name": "Franklin Wireless Corp. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "FLD": {
        "symbol": "FLD",
        "name": "FTAC Emerald Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FLDDU": {
        "symbol": "FLDDU",
        "name": "FTAC Emerald Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FLDDW": {
        "symbol": "FLDDW",
        "name": "FTAC Emerald Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FLEX": {
        "symbol": "FLEX",
        "name": "Flex Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "FLGC": {
        "symbol": "FLGC",
        "name": "Flora Growth Corp. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FLGT": {
        "symbol": "FLGT",
        "name": "Fulgent Genetics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "FLIC": {
        "symbol": "FLIC",
        "name": "First of Long Island Corporation (The) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FLL": {
        "symbol": "FLL",
        "name": "Full House Resorts Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "FLNC": {
        "symbol": "FLNC",
        "name": "Fluence Energy Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "FLNT": {
        "symbol": "FLNT",
        "name": "Fluent Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "FLUX": {
        "symbol": "FLUX",
        "name": "Flux Power Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "FLWS": {
        "symbol": "FLWS",
        "name": "1-800-FLOWERS.COM Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "FLXS": {
        "symbol": "FLXS",
        "name": "Flexsteel Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "FLYE": {
        "symbol": "FLYE",
        "name": "Fly-E Group Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "FLYW": {
        "symbol": "FLYW",
        "name": "Flywire Corporation Voting Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "FMAO": {
        "symbol": "FMAO",
        "name": "Farmers & Merchants Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FMBH": {
        "symbol": "FMBH",
        "name": "First Mid Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FMNB": {
        "symbol": "FMNB",
        "name": "Farmers National Banc Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FMST": {
        "symbol": "FMST",
        "name": "Foremost Lithium Resource & Technology Ltd. Common stock",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Other Metals and Minerals"
    },
    "FMSTW": {
        "symbol": "FMSTW",
        "name": "Foremost Lithium Resource & Technology Ltd. Warrant",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Other Metals and Minerals"
    },
    "FNGR": {
        "symbol": "FNGR",
        "name": "FingerMotion Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FNKO": {
        "symbol": "FNKO",
        "name": "Funko Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "FNLC": {
        "symbol": "FNLC",
        "name": "First Bancorp Inc  (ME) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FNVT": {
        "symbol": "FNVT",
        "name": "Finnovate Acquisition Corp. Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FNVTW": {
        "symbol": "FNVTW",
        "name": "Finnovate Acquisition Corp. Warrants",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FNWB": {
        "symbol": "FNWB",
        "name": "First Northwest Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "FNWD": {
        "symbol": "FNWD",
        "name": "Finward Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FOLD": {
        "symbol": "FOLD",
        "name": "Amicus Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FONR": {
        "symbol": "FONR",
        "name": "Fonar Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "FORA": {
        "symbol": "FORA",
        "name": "Forian Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "FORD": {
        "symbol": "FORD",
        "name": "Forward Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "FORLW": {
        "symbol": "FORLW",
        "name": "Four Leaf Acquisition Corporation Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FORM": {
        "symbol": "FORM",
        "name": "FormFactor Inc. FormFactor Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "FORR": {
        "symbol": "FORR",
        "name": "Forrester Research Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "FORTY": {
        "symbol": "FORTY",
        "name": "Formula Systems (1985) Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "FOSL": {
        "symbol": "FOSL",
        "name": "Fossil Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "FOSLL": {
        "symbol": "FOSLL",
        "name": "Fossil Group Inc. 7% Senior Notes due 2026",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "FOX": {
        "symbol": "FOX",
        "name": "Fox Corporation Class B Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "FOXA": {
        "symbol": "FOXA",
        "name": "Fox Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "FOXF": {
        "symbol": "FOXF",
        "name": "Fox Factory Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "FOXX": {
        "symbol": "FOXX",
        "name": "Foxx Development Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "FPAY": {
        "symbol": "FPAY",
        "name": "FlexShopper Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "FRAF": {
        "symbol": "FRAF",
        "name": "Franklin Financial Services Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FRBA": {
        "symbol": "FRBA",
        "name": "First Bank Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "FRES": {
        "symbol": "FRES",
        "name": "Fresh2 Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "FRGT": {
        "symbol": "FRGT",
        "name": "Freight Technologies Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "FRHC": {
        "symbol": "FRHC",
        "name": "Freedom Holding Corp. Common Stock",
        "country": "Kazakhstan",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "FRLA": {
        "symbol": "FRLA",
        "name": "Fortune Rise Acquisition Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "FRME": {
        "symbol": "FRME",
        "name": "First Merchants Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FRMEP": {
        "symbol": "FRMEP",
        "name": "First Merchants Corporation Depository Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FROG": {
        "symbol": "FROG",
        "name": "JFrog Ltd. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FRPH": {
        "symbol": "FRPH",
        "name": "FRP Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "FRPT": {
        "symbol": "FRPT",
        "name": "Freshpet Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "FRSH": {
        "symbol": "FRSH",
        "name": "Freshworks Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FRST": {
        "symbol": "FRST",
        "name": "Primis Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FRSX": {
        "symbol": "FRSX",
        "name": "Foresight Autonomous Holdings Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "FRZA": {
        "symbol": "FRZA",
        "name": "Forza X1 Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Marine Transportation"
    },
    "FSBC": {
        "symbol": "FSBC",
        "name": "Five Star Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FSBW": {
        "symbol": "FSBW",
        "name": "FS Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "FSEA": {
        "symbol": "FSEA",
        "name": "First Seacoast Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FSFG": {
        "symbol": "FSFG",
        "name": "First Savings Financial Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "FSHP": {
        "symbol": "FSHP",
        "name": "Flag Ship Acquisition Corp. Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "FSLR": {
        "symbol": "FSLR",
        "name": "First Solar Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "FSTR": {
        "symbol": "FSTR",
        "name": "L.B. Foster Company Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "FSUN": {
        "symbol": "FSUN",
        "name": "FirstSun Capital Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FSV": {
        "symbol": "FSV",
        "name": "FirstService Corporation Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "FTAI": {
        "symbol": "FTAI",
        "name": "FTAI Aviation Ltd. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "FTAIM": {
        "symbol": "FTAIM",
        "name": "FTAI Aviation Ltd. 9.500% Fixed-Rate Reset Series D Cumulative Perpetual Redeemable Preferred Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "FTAIN": {
        "symbol": "FTAIN",
        "name": "FTAI Aviation Ltd. 8.25% Fixed - Rate Reset Series C Cumulative Perpetual Redeemable Preferred Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "FTAIO": {
        "symbol": "FTAIO",
        "name": "FTAI Aviation Ltd. 8.00% Fixed-to-Floating Rate Series B Cumulative Perpetual Redeemable Preferred Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "FTAIP": {
        "symbol": "FTAIP",
        "name": "FTAI Aviation Ltd. 8.25% Fixed to Floating Rate Series A Cumulative Perpetual Redeemable Preferred Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "FTCI": {
        "symbol": "FTCI",
        "name": "FTC Solar Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "FTDR": {
        "symbol": "FTDR",
        "name": "Frontdoor Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Diversified Commercial Services"
    },
    "FTEK": {
        "symbol": "FTEK",
        "name": "Fuel Tech Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Pollution Control Equipment"
    },
    "FTEL": {
        "symbol": "FTEL",
        "name": "Fitell Corporation Ordinary Shares",
        "country": "Australia",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "FTFT": {
        "symbol": "FTFT",
        "name": "Future FinTech Group Inc. Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "FTHM": {
        "symbol": "FTHM",
        "name": "Fathom Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "FTII": {
        "symbol": "FTII",
        "name": "FutureTech II Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FTIIW": {
        "symbol": "FTIIW",
        "name": "FutureTech II Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "FTLF": {
        "symbol": "FTLF",
        "name": "FitLife Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "FTNT": {
        "symbol": "FTNT",
        "name": "Fortinet Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "FTRE": {
        "symbol": "FTRE",
        "name": "Fortrea Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "FUFU": {
        "symbol": "FUFU",
        "name": "BitFuFu Inc. Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "FUFUW": {
        "symbol": "FUFUW",
        "name": "BitFuFu Inc. Warrant",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "FULC": {
        "symbol": "FULC",
        "name": "Fulcrum Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "FULT": {
        "symbol": "FULT",
        "name": "Fulton Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FULTP": {
        "symbol": "FULTP",
        "name": "Fulton Financial Corporation Depositary Shares Each Representing a 1/40th Interest in a Share of Fixed Rate Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FUNC": {
        "symbol": "FUNC",
        "name": "First United Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FUND": {
        "symbol": "FUND",
        "name": "Sprott Focus Trust Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "FUSB": {
        "symbol": "FUSB",
        "name": "First US Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FUTU": {
        "symbol": "FUTU",
        "name": "Futu Holdings Limited American Depositary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "FVCB": {
        "symbol": "FVCB",
        "name": "FVCBankcorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FVNNU": {
        "symbol": "FVNNU",
        "name": "Future Vision II Acquisition Corporation Units",
        "country": "China",
        "sector": "",
        "industry": ""
    },
    "FWONA": {
        "symbol": "FWONA",
        "name": "Liberty Media Corporation Series A Liberty Formula One Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "FWONK": {
        "symbol": "FWONK",
        "name": "Liberty Media Corporation Series C Liberty Formula One Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "FWRD": {
        "symbol": "FWRD",
        "name": "Forward Air Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "FWRG": {
        "symbol": "FWRG",
        "name": "First Watch Restaurant Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "FXNC": {
        "symbol": "FXNC",
        "name": "First National Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "FYBR": {
        "symbol": "FYBR",
        "name": "Frontier Communications Parent Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "GABC": {
        "symbol": "GABC",
        "name": "German American Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "GAIA": {
        "symbol": "GAIA",
        "name": "Gaia Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "GAIN": {
        "symbol": "GAIN",
        "name": "Gladstone Investment Corporation Business Development Company",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GAINL": {
        "symbol": "GAINL",
        "name": "Gladstone Investment Corporation 8.00% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GAINN": {
        "symbol": "GAINN",
        "name": "Gladstone Investment Corporation 5.00% Notes Due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GAINZ": {
        "symbol": "GAINZ",
        "name": "Gladstone Investment Corporation 4.875% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GALT": {
        "symbol": "GALT",
        "name": "Galectin Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GAMB": {
        "symbol": "GAMB",
        "name": "Gambling.com Group Limited Ordinary Shares",
        "country": "Jersey",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "GAME": {
        "symbol": "GAME",
        "name": "GameSquare Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "GAN": {
        "symbol": "GAN",
        "name": "GAN Limited Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GANX": {
        "symbol": "GANX",
        "name": "Gain Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GASS": {
        "symbol": "GASS",
        "name": "StealthGas Inc. Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "GATE": {
        "symbol": "GATE",
        "name": "Marblegate Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GAUZ": {
        "symbol": "GAUZ",
        "name": "Gauzy Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "GBBK": {
        "symbol": "GBBK",
        "name": "Global Blockchain Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GBBKR": {
        "symbol": "GBBKR",
        "name": "Global Blockchain Acquisition Corp. Right",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GBBKW": {
        "symbol": "GBBKW",
        "name": "Global Blockchain Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GBDC": {
        "symbol": "GBDC",
        "name": "Golub Capital BDC Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GBIO": {
        "symbol": "GBIO",
        "name": "Generation Bio Co. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GBNY": {
        "symbol": "GBNY",
        "name": "Generations Bancorp NY Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "GCBC": {
        "symbol": "GCBC",
        "name": "Greene County Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "GCMG": {
        "symbol": "GCMG",
        "name": "GCM Grosvenor Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "GCMGW": {
        "symbol": "GCMGW",
        "name": "GCM Grosvenor Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "GCT": {
        "symbol": "GCT",
        "name": "GigaCloud Technology Inc Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "GCTK": {
        "symbol": "GCTK",
        "name": "GlucoTrack Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "GDC": {
        "symbol": "GDC",
        "name": "GD Culture Group Limited Common Stock",
        "country": "China",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "GDEN": {
        "symbol": "GDEN",
        "name": "Golden Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "GDEV": {
        "symbol": "GDEV",
        "name": "GDEV Inc. Ordinary Shares",
        "country": "Cyprus",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GDHG": {
        "symbol": "GDHG",
        "name": "Golden Heaven Group Holdings Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "GDRX": {
        "symbol": "GDRX",
        "name": "GoodRx Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GDS": {
        "symbol": "GDS",
        "name": "GDS Holdings Limited ADS",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GDST": {
        "symbol": "GDST",
        "name": "Goldenstone Acquisition Limited Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GDSTR": {
        "symbol": "GDSTR",
        "name": "Goldenstone Acquisition Limited Rights",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GDTC": {
        "symbol": "GDTC",
        "name": "CytoMed Therapeutics Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GDYN": {
        "symbol": "GDYN",
        "name": "Grid Dynamics Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GECC": {
        "symbol": "GECC",
        "name": "Great Elm Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GECCH": {
        "symbol": "GECCH",
        "name": "Great Elm Capital Corp. 8.125% Notes Due 2029",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GECCI": {
        "symbol": "GECCI",
        "name": "Great Elm Capital Corp. 8.50% NOTES DUE 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "GECCM": {
        "symbol": "GECCM",
        "name": "Great Elm Capital Corp. 6.75% Notes Due 2025",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GECCO": {
        "symbol": "GECCO",
        "name": "Great Elm Capital Corp. 5.875% Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "GECCZ": {
        "symbol": "GECCZ",
        "name": "Great Elm Capital Corp. 8.75% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "GEG": {
        "symbol": "GEG",
        "name": "Great Elm Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GEGGL": {
        "symbol": "GEGGL",
        "name": "Great Elm Group Inc.  7.25% Notes due 2027",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GEHC": {
        "symbol": "GEHC",
        "name": "GE HealthCare Technologies Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Electronics"
    },
    "GEN": {
        "symbol": "GEN",
        "name": "Gen Digital Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GENE": {
        "symbol": "GENE",
        "name": "Genetic Technologies Ltd ADS",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GENK": {
        "symbol": "GENK",
        "name": "GEN Restaurant Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "GEOS": {
        "symbol": "GEOS",
        "name": "Geospace Technologies Corporation Common Stock (Texas)",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "GERN": {
        "symbol": "GERN",
        "name": "Geron Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GEVO": {
        "symbol": "GEVO",
        "name": "Gevo Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "GFAI": {
        "symbol": "GFAI",
        "name": "Guardforce AI Co. Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "GFAIW": {
        "symbol": "GFAIW",
        "name": "Guardforce AI Co. Limited Warrant",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "GFS": {
        "symbol": "GFS",
        "name": "GlobalFoundries Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "GGAL": {
        "symbol": "GGAL",
        "name": "Grupo Financiero Galicia S.A. American Depositary Shares",
        "country": "Argentina",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "GGR": {
        "symbol": "GGR",
        "name": "Gogoro Inc. Ordinary Shares",
        "country": "Taiwan",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "GGROW": {
        "symbol": "GGROW",
        "name": "Gogoro Inc. Warrant",
        "country": "Taiwan",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "GH": {
        "symbol": "GH",
        "name": "Guardant Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "GHIX": {
        "symbol": "GHIX",
        "name": "Gores Holdings IX Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GHIXW": {
        "symbol": "GHIXW",
        "name": "Gores Holdings IX Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GHRS": {
        "symbol": "GHRS",
        "name": "GH Research PLC Ordinary Shares",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GHSI": {
        "symbol": "GHSI",
        "name": "Guardion Health Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GIFI": {
        "symbol": "GIFI",
        "name": "Gulf Island Fabrication Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "GIFT": {
        "symbol": "GIFT",
        "name": "RDE Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "GIG": {
        "symbol": "GIG",
        "name": "GigCapital7 Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GIGGU": {
        "symbol": "GIGGU",
        "name": "GigCapital7 Corp. Unit",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GIGGW": {
        "symbol": "GIGGW",
        "name": "GigCapital7 Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GIGM": {
        "symbol": "GIGM",
        "name": "GigaMedia Limited Ordinary Shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GIII": {
        "symbol": "GIII",
        "name": "G-III Apparel Group LTD. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "GILD": {
        "symbol": "GILD",
        "name": "Gilead Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GILT": {
        "symbol": "GILT",
        "name": "Gilat Satellite Networks Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "GIPR": {
        "symbol": "GIPR",
        "name": "Generation Income Properties Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "GLAC": {
        "symbol": "GLAC",
        "name": "Global Lights Acquisition Corp Ordinary Shares",
        "country": "China",
        "sector": "",
        "industry": ""
    },
    "GLAD": {
        "symbol": "GLAD",
        "name": "Gladstone Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Textiles"
    },
    "GLADZ": {
        "symbol": "GLADZ",
        "name": "Gladstone Capital Corporation 7.75% Notes due 2028",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GLBE": {
        "symbol": "GLBE",
        "name": "Global-E Online Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GLBS": {
        "symbol": "GLBS",
        "name": "Globus Maritime Limited Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "GLBZ": {
        "symbol": "GLBZ",
        "name": "Glen Burnie Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "GLDD": {
        "symbol": "GLDD",
        "name": "Great Lakes Dredge & Dock Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "GLE": {
        "symbol": "GLE",
        "name": "Global Engine Group Holding Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GLLI": {
        "symbol": "GLLI",
        "name": "Globalink Investment Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Consumer Electronics/Appliances"
    },
    "GLMD": {
        "symbol": "GLMD",
        "name": "Galmed Pharmaceuticals Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GLNG": {
        "symbol": "GLNG",
        "name": "Golar Lng Ltd",
        "country": "Bermuda",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "GLPG": {
        "symbol": "GLPG",
        "name": "Galapagos NV American Depositary Shares",
        "country": "Belgium",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GLPI": {
        "symbol": "GLPI",
        "name": "Gaming and Leisure Properties Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "GLRE": {
        "symbol": "GLRE",
        "name": "Greenlight Capital Re Ltd. Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "GLSI": {
        "symbol": "GLSI",
        "name": "Greenwich LifeSciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GLST": {
        "symbol": "GLST",
        "name": "Global Star Acquisition Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Consumer Electronics/Appliances"
    },
    "GLSTR": {
        "symbol": "GLSTR",
        "name": "Global Star Acquisition Inc. Right",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GLSTU": {
        "symbol": "GLSTU",
        "name": "Global Star Acquisition Inc. Unit",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Consumer Electronics/Appliances"
    },
    "GLTO": {
        "symbol": "GLTO",
        "name": "Galecto Inc. Common Stock",
        "country": "Denmark",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GLUE": {
        "symbol": "GLUE",
        "name": "Monte Rosa Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GLXG": {
        "symbol": "GLXG",
        "name": "Galaxy Payroll Group Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "GLYC": {
        "symbol": "GLYC",
        "name": "GlycoMimetics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GMAB": {
        "symbol": "GMAB",
        "name": "Genmab A/S ADS",
        "country": "Denmark",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GMGI": {
        "symbol": "GMGI",
        "name": "Golden Matrix Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GMM": {
        "symbol": "GMM",
        "name": "Global Mofy AI Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GNFT": {
        "symbol": "GNFT",
        "name": "GENFIT S.A. American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GNLN": {
        "symbol": "GNLN",
        "name": "Greenlane Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Durable Goods"
    },
    "GNLX": {
        "symbol": "GNLX",
        "name": "Genelux Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GNPX": {
        "symbol": "GNPX",
        "name": "Genprex Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GNSS": {
        "symbol": "GNSS",
        "name": "Genasys Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Consumer Electronics/Appliances"
    },
    "GNTA": {
        "symbol": "GNTA",
        "name": "Genenta Science S.p.A. American Depositary Shares",
        "country": "Italy",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GNTX": {
        "symbol": "GNTX",
        "name": "Gentex Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "GO": {
        "symbol": "GO",
        "name": "Grocery Outlet Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "GOCO": {
        "symbol": "GOCO",
        "name": "GoHealth Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "GODN": {
        "symbol": "GODN",
        "name": "Golden Star Acquisition Corporation Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GOEV": {
        "symbol": "GOEV",
        "name": "Canoo Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "GOEVW": {
        "symbol": "GOEVW",
        "name": "Canoo Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "GOGL": {
        "symbol": "GOGL",
        "name": "Golden Ocean Group Limited Common Stock",
        "country": "Bermuda",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "GOGO": {
        "symbol": "GOGO",
        "name": "Gogo Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "GOOD": {
        "symbol": "GOOD",
        "name": "Gladstone Commercial Corporation Real Estate Investment Trust",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate"
    },
    "GOODN": {
        "symbol": "GOODN",
        "name": "Gladstone Commercial Corporation 6.625% Series E Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate"
    },
    "GOODO": {
        "symbol": "GOODO",
        "name": "Gladstone Commercial Corporation 6.00% Series G Cumulative Redeemable Preferred Stock par value $0.001 per share",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate"
    },
    "GOOG": {
        "symbol": "GOOG",
        "name": "Alphabet Inc. Class C Capital Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GOOGL": {
        "symbol": "GOOGL",
        "name": "Alphabet Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "GORV": {
        "symbol": "GORV",
        "name": "Lazydays Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "GOSS": {
        "symbol": "GOSS",
        "name": "Gossamer Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GOVX": {
        "symbol": "GOVX",
        "name": "GeoVax Labs Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GOVXW": {
        "symbol": "GOVXW",
        "name": "GeoVax Labs Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GP": {
        "symbol": "GP",
        "name": "GreenPower Motor Company Inc. Common Shares",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "GPAT": {
        "symbol": "GPAT",
        "name": "GP-Act III Acquisition Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GPATW": {
        "symbol": "GPATW",
        "name": "GP-Act III Acquisition Corp. Warrants",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GPCR": {
        "symbol": "GPCR",
        "name": "Structure Therapeutics Inc. American Depositary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GPRE": {
        "symbol": "GPRE",
        "name": "Green Plains Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "GPRO": {
        "symbol": "GPRO",
        "name": "GoPro Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Machinery/Components"
    },
    "GRAB": {
        "symbol": "GRAB",
        "name": "Grab Holdings Limited Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "GRABW": {
        "symbol": "GRABW",
        "name": "Grab Holdings Limited Warrant",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "GRAL": {
        "symbol": "GRAL",
        "name": "GRAIL Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "GRDI": {
        "symbol": "GRDI",
        "name": "GRIID Infrastructure Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GRDIW": {
        "symbol": "GRDIW",
        "name": "GRIID Infrastructure Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GREE": {
        "symbol": "GREE",
        "name": "Greenidge Generation Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GREEL": {
        "symbol": "GREEL",
        "name": "Greenidge Generation Holdings Inc. 8.50% Senior Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "GRFS": {
        "symbol": "GRFS",
        "name": "Grifols S.A. American Depositary Shares",
        "country": "Spain",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GRI": {
        "symbol": "GRI",
        "name": "GRI Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GRNQ": {
        "symbol": "GRNQ",
        "name": "Greenpro Capital Corp. Common Stock",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GROW": {
        "symbol": "GROW",
        "name": "U.S. Global Investors Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "GRPN": {
        "symbol": "GRPN",
        "name": "Groupon Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "GRRR": {
        "symbol": "GRRR",
        "name": "Gorilla Technology Group Inc. Ordinary shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GRRRW": {
        "symbol": "GRRRW",
        "name": "Gorilla Technology Group Inc. Warrant",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GRTS": {
        "symbol": "GRTS",
        "name": "Gritstone bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "GRVY": {
        "symbol": "GRVY",
        "name": "GRAVITY Co. Ltd. American Depository Shares",
        "country": "South Korea",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "GRWG": {
        "symbol": "GRWG",
        "name": "GrowGeneration Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "GRYP": {
        "symbol": "GRYP",
        "name": "Gryphon Digital Mining Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GSBC": {
        "symbol": "GSBC",
        "name": "Great Southern Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "GSHD": {
        "symbol": "GSHD",
        "name": "Goosehead Insurance Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "GSIT": {
        "symbol": "GSIT",
        "name": "GSI Technology Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "GSIW": {
        "symbol": "GSIW",
        "name": "Garden Stage Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "GSM": {
        "symbol": "GSM",
        "name": "Ferroglobe PLC Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "GSMGW": {
        "symbol": "GSMGW",
        "name": "Cheer Holding Inc. Warrant expiring 2/13/2025",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "GSUN": {
        "symbol": "GSUN",
        "name": "Golden Sun Health Technology Group Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "GT": {
        "symbol": "GT",
        "name": "The Goodyear Tire & Rubber Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "GTAC": {
        "symbol": "GTAC",
        "name": "Global Technology Acquisition Corp. I Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GTACW": {
        "symbol": "GTACW",
        "name": "Global Technology Acquisition Corp. I Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "GTBP": {
        "symbol": "GTBP",
        "name": "GT Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GTEC": {
        "symbol": "GTEC",
        "name": "Greenland Technologies Holding Corporation Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "GTI": {
        "symbol": "GTI",
        "name": "Graphjet Technology Class A Ordinary Shares",
        "country": "Malaysia",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "GTIM": {
        "symbol": "GTIM",
        "name": "Good Times Restaurants Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "GTLB": {
        "symbol": "GTLB",
        "name": "GitLab Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GTX": {
        "symbol": "GTX",
        "name": "Garrett Motion Inc. Common Stock",
        "country": "Switzerland",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "GURE": {
        "symbol": "GURE",
        "name": "Gulf Resources Inc. (NV) Common Stock",
        "country": "China",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "GUTS": {
        "symbol": "GUTS",
        "name": "Fractyl Health Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "GV": {
        "symbol": "GV",
        "name": "Visionary Holdings Inc. Common Shares",
        "country": "Canada",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "GVH": {
        "symbol": "GVH",
        "name": "Globavend Holdings Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "GVP": {
        "symbol": "GVP",
        "name": "GSE Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GWAV": {
        "symbol": "GWAV",
        "name": "Greenwave Technology Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "GWRS": {
        "symbol": "GWRS",
        "name": "Global Water Resources Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "GXAI": {
        "symbol": "GXAI",
        "name": "Gaxos.ai Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "GYRE": {
        "symbol": "GYRE",
        "name": "Gyre Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "GYRO": {
        "symbol": "GYRO",
        "name": "Gyrodyne LLC Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Building operators"
    },
    "HAFC": {
        "symbol": "HAFC",
        "name": "Hanmi Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HAIA": {
        "symbol": "HAIA",
        "name": "Healthcare AI Acquisition Corp. Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HAIAU": {
        "symbol": "HAIAU",
        "name": "Healthcare AI Acquisition Corp. Unit",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HAIAW": {
        "symbol": "HAIAW",
        "name": "Healthcare AI Acquisition Corp. Warrants",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HAIN": {
        "symbol": "HAIN",
        "name": "Hain Celestial Group Inc. (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "HALO": {
        "symbol": "HALO",
        "name": "Halozyme Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "HAO": {
        "symbol": "HAO",
        "name": "Haoxi Health Technology Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "HAS": {
        "symbol": "HAS",
        "name": "Hasbro Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "HAYN": {
        "symbol": "HAYN",
        "name": "Haynes International Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "HBAN": {
        "symbol": "HBAN",
        "name": "Huntington Bancshares Incorporated Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HBANL": {
        "symbol": "HBANL",
        "name": "Huntington Bancshares Incorporated Depositary Shares Each Representing a 1/40th Interest in a Share of 6.875% Series J Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HBANM": {
        "symbol": "HBANM",
        "name": "Huntington Bancshares Incorporated Depositary Shares each representing a 1/1000th interest in a share of Huntington Series I Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HBANP": {
        "symbol": "HBANP",
        "name": "Huntington Bancshares Incorporated Depositary Shares 4.500% Series H Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HBCP": {
        "symbol": "HBCP",
        "name": "Home Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "HBIO": {
        "symbol": "HBIO",
        "name": "Harvard Bioscience Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "HBNC": {
        "symbol": "HBNC",
        "name": "Horizon Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HBT": {
        "symbol": "HBT",
        "name": "HBT Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HCAT": {
        "symbol": "HCAT",
        "name": "Health Catalyst Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "HCKT": {
        "symbol": "HCKT",
        "name": "Hackett Group Inc (The). Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "HCM": {
        "symbol": "HCM",
        "name": "HUTCHMED (China) Limited American Depositary Shares",
        "country": "Hong Kong",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HCP": {
        "symbol": "HCP",
        "name": "HashiCorp Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "HCSG": {
        "symbol": "HCSG",
        "name": "Healthcare Services Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Hospital/Nursing Management"
    },
    "HCTI": {
        "symbol": "HCTI",
        "name": "Healthcare Triangle Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "HCVI": {
        "symbol": "HCVI",
        "name": "Hennessy Capital Investment Corp. VI Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HCVIU": {
        "symbol": "HCVIU",
        "name": "Hennessy Capital Investment Corp. VI Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HCVIW": {
        "symbol": "HCVIW",
        "name": "Hennessy Capital Investment Corp. VI Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HCWB": {
        "symbol": "HCWB",
        "name": "HCW Biologics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HDL": {
        "symbol": "HDL",
        "name": "SUPER HI INTERNATIONAL HOLDING LTD. American Depositary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "HDSN": {
        "symbol": "HDSN",
        "name": "Hudson Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "HEAR": {
        "symbol": "HEAR",
        "name": "Turtle Beach Corporation Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "HEES": {
        "symbol": "HEES",
        "name": "H&E Equipment Services Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "HELE": {
        "symbol": "HELE",
        "name": "Helen of Troy Limited Common Stock",
        "country": "Bermuda",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "HEPA": {
        "symbol": "HEPA",
        "name": "Hepion Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HEPS": {
        "symbol": "HEPS",
        "name": "D-Market Electronic Services & Trading American Depositary Shares",
        "country": "Turkey",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "HFBL": {
        "symbol": "HFBL",
        "name": "Home Federal Bancorp Inc. of Louisiana Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "HFFG": {
        "symbol": "HFFG",
        "name": "HF Foods Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Food Distributors"
    },
    "HFWA": {
        "symbol": "HFWA",
        "name": "Heritage Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "HGBL": {
        "symbol": "HGBL",
        "name": "Heritage Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "HHGC": {
        "symbol": "HHGC",
        "name": "HHG Capital Corporation Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HHGCW": {
        "symbol": "HHGCW",
        "name": "HHG Capital Corporation Warrant",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HHS": {
        "symbol": "HHS",
        "name": "Harte Hanks Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "HIFS": {
        "symbol": "HIFS",
        "name": "Hingham Institution for Savings Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "HIHO": {
        "symbol": "HIHO",
        "name": "Highway Holdings Limited Common Stock",
        "country": "Hong Kong",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "HIMX": {
        "symbol": "HIMX",
        "name": "Himax Technologies Inc. American Depositary Shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "HITI": {
        "symbol": "HITI",
        "name": "High Tide Inc. Common Shares",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "HIVE": {
        "symbol": "HIVE",
        "name": "HIVE Digital Technologies Ltd. Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "HKIT": {
        "symbol": "HKIT",
        "name": "Hitek Global Inc. Class A Ordinary Share",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "HLIT": {
        "symbol": "HLIT",
        "name": "Harmonic Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "HLMN": {
        "symbol": "HLMN",
        "name": "Hillman Solutions Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Machinery/Components"
    },
    "HLNE": {
        "symbol": "HLNE",
        "name": "Hamilton Lane Incorporated Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "HLP": {
        "symbol": "HLP",
        "name": "Hongli Group Inc. Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "HLVX": {
        "symbol": "HLVX",
        "name": "HilleVax Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "HLXB": {
        "symbol": "HLXB",
        "name": "Helix Acquisition Corp. II Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "HMNF": {
        "symbol": "HMNF",
        "name": "HMN Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "HMST": {
        "symbol": "HMST",
        "name": "HomeStreet Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HNNA": {
        "symbol": "HNNA",
        "name": "Hennessy Advisors Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "HNNAZ": {
        "symbol": "HNNAZ",
        "name": "Hennessy Advisors Inc. 4.875% Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "HNRG": {
        "symbol": "HNRG",
        "name": "Hallador Energy Company Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "HNST": {
        "symbol": "HNST",
        "name": "The Honest Company Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "HNVR": {
        "symbol": "HNVR",
        "name": "Hanover Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HOFT": {
        "symbol": "HOFT",
        "name": "Hooker Furnishings Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "HOFV": {
        "symbol": "HOFV",
        "name": "Hall of Fame Resort & Entertainment Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "HOFVW": {
        "symbol": "HOFVW",
        "name": "Hall of Fame Resort &amp; Entertainment Company Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "HOLO": {
        "symbol": "HOLO",
        "name": "MicroCloud Hologram Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "HOLOW": {
        "symbol": "HOLOW",
        "name": "MicroCloud Hologram Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "HOLX": {
        "symbol": "HOLX",
        "name": "Hologic Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Electronics"
    },
    "HON": {
        "symbol": "HON",
        "name": "Honeywell International Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "HONDU": {
        "symbol": "HONDU",
        "name": "HCM II Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HONE": {
        "symbol": "HONE",
        "name": "HarborOne Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HOOD": {
        "symbol": "HOOD",
        "name": "Robinhood Markets Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "HOOK": {
        "symbol": "HOOK",
        "name": "HOOKIPA Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HOPE": {
        "symbol": "HOPE",
        "name": "Hope Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HOTH": {
        "symbol": "HOTH",
        "name": "Hoth Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HOUR": {
        "symbol": "HOUR",
        "name": "Hour Loop Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "HOVNP": {
        "symbol": "HOVNP",
        "name": "Hovnanian Enterprises Inc Dep Shr Srs A Pfd",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "HOVR": {
        "symbol": "HOVR",
        "name": "New Horizon Aircraft Ltd. Class A Ordinary Share",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "HOVRW": {
        "symbol": "HOVRW",
        "name": "New Horizon Aircraft Ltd. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "HOWL": {
        "symbol": "HOWL",
        "name": "Werewolf Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HPAI": {
        "symbol": "HPAI",
        "name": "Helport AI Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "HPAIW": {
        "symbol": "HPAIW",
        "name": "Helport AI Limited Warrants",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "HPH": {
        "symbol": "HPH",
        "name": "Highest Performances Holdings Inc. American Depository Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "HPK": {
        "symbol": "HPK",
        "name": "HighPeak Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "HPKEW": {
        "symbol": "HPKEW",
        "name": "HighPeak Energy Inc. Warrant",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "HQI": {
        "symbol": "HQI",
        "name": "HireQuest Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "HQY": {
        "symbol": "HQY",
        "name": "HealthEquity Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "HRMY": {
        "symbol": "HRMY",
        "name": "Harmony Biosciences Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HROW": {
        "symbol": "HROW",
        "name": "Harrow Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HROWL": {
        "symbol": "HROWL",
        "name": "Harrow Inc. 8.625% Senior Notes due 2026",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HROWM": {
        "symbol": "HROWM",
        "name": "Harrow  Inc. 11.875% Senior Notes due 2027",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HRTX": {
        "symbol": "HRTX",
        "name": "Heron Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "HRYU": {
        "symbol": "HRYU",
        "name": "Hanryu Holdings Inc. Common Stock",
        "country": "South Korea",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "HRZN": {
        "symbol": "HRZN",
        "name": "Horizon Technology Finance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "HSAI": {
        "symbol": "HSAI",
        "name": "Hesai Group American Depositary Share each ADS represents one Class B ordinary share",
        "country": "China",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "HSCS": {
        "symbol": "HSCS",
        "name": "Heart Test Laboratories Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "HSCSW": {
        "symbol": "HSCSW",
        "name": "Heart Test Laboratories Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "HSDT": {
        "symbol": "HSDT",
        "name": "Helius Medical Technologies Inc. Class A Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "HSIC": {
        "symbol": "HSIC",
        "name": "Henry Schein Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "HSII": {
        "symbol": "HSII",
        "name": "Heidrick & Struggles International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "HSON": {
        "symbol": "HSON",
        "name": "Hudson Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "HSPO": {
        "symbol": "HSPO",
        "name": "Horizon Space Acquisition I Corp. Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HSPOR": {
        "symbol": "HSPOR",
        "name": "Horizon Space Acquisition I Corp. Right",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "HST": {
        "symbol": "HST",
        "name": "Host Hotels & Resorts Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "HSTM": {
        "symbol": "HSTM",
        "name": "HealthStream Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "HTBI": {
        "symbol": "HTBI",
        "name": "HomeTrust Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "HTBK": {
        "symbol": "HTBK",
        "name": "Heritage Commerce Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HTCR": {
        "symbol": "HTCR",
        "name": "Heartcore Enterprises Inc. Common Stock",
        "country": "Japan",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "HTHT": {
        "symbol": "HTHT",
        "name": "H World Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "HTIA": {
        "symbol": "HTIA",
        "name": "Healthcare Trust Inc. 7.375% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "HTIBP": {
        "symbol": "HTIBP",
        "name": "Healthcare Trust Inc. 7.125% Series B Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "HTLD": {
        "symbol": "HTLD",
        "name": "Heartland Express Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "HTLF": {
        "symbol": "HTLF",
        "name": "Heartland Financial USA Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HTLFP": {
        "symbol": "HTLFP",
        "name": "Heartland Financial USA Inc. Depositary Shares each representing a 1/400th ownership interest in a share of 7.00% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series E",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HTOO": {
        "symbol": "HTOO",
        "name": "Fusion Fuel Green PLC Class A Ordinary Shares",
        "country": "Ireland",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "HTOOW": {
        "symbol": "HTOOW",
        "name": "Fusion Fuel Green PLC Warrant",
        "country": "Ireland",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "HTZ": {
        "symbol": "HTZ",
        "name": "Hertz Global Holdings Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Rental/Leasing Companies"
    },
    "HTZWW": {
        "symbol": "HTZWW",
        "name": "Hertz Global Holdings Inc Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Rental/Leasing Companies"
    },
    "HUBC": {
        "symbol": "HUBC",
        "name": "Hub Cyber Security Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "HUBCW": {
        "symbol": "HUBCW",
        "name": "Hub Cyber Security Ltd. Warrant 2/27/28",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "HUBCZ": {
        "symbol": "HUBCZ",
        "name": "Hub Cyber Security Ltd. Warrant 8/22/2025",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "HUBG": {
        "symbol": "HUBG",
        "name": "Hub Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "HUDI": {
        "symbol": "HUDI",
        "name": "Huadi International Group Co. Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "HUIZ": {
        "symbol": "HUIZ",
        "name": "Huize Holding Limited American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "HUMA": {
        "symbol": "HUMA",
        "name": "Humacyte Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "HUMAW": {
        "symbol": "HUMAW",
        "name": "Humacyte Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "HURC": {
        "symbol": "HURC",
        "name": "Hurco Companies Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "HURN": {
        "symbol": "HURN",
        "name": "Huron Consulting Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "HUT": {
        "symbol": "HUT",
        "name": "Hut 8 Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "HWBK": {
        "symbol": "HWBK",
        "name": "Hawthorn Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HWC": {
        "symbol": "HWC",
        "name": "Hancock Whitney Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HWCPZ": {
        "symbol": "HWCPZ",
        "name": "Hancock Whitney Corporation 6.25% Subordinated Notes due 2060",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "HWH": {
        "symbol": "HWH",
        "name": "HWH International Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Other Pharmaceuticals"
    },
    "HWKN": {
        "symbol": "HWKN",
        "name": "Hawkins Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Specialty Chemicals"
    },
    "HYFM": {
        "symbol": "HYFM",
        "name": "Hydrofarm Holdings Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "HYMC": {
        "symbol": "HYMC",
        "name": "Hycroft Mining Holding Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "HYMCL": {
        "symbol": "HYMCL",
        "name": "Hycroft Mining Holding Corporation Warrants",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "HYMCW": {
        "symbol": "HYMCW",
        "name": "Hycroft Mining Holding Corporation  Warrant",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "HYPR": {
        "symbol": "HYPR",
        "name": "Hyperfine Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "HYZN": {
        "symbol": "HYZN",
        "name": "Hyzon Motors Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "HYZNW": {
        "symbol": "HYZNW",
        "name": "Hyzon Motors Inc. Warrants",
        "country": "United States",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "IAC": {
        "symbol": "IAC",
        "name": "IAC Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "IART": {
        "symbol": "IART",
        "name": "Integra LifeSciences Holdings Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IAS": {
        "symbol": "IAS",
        "name": "Integral Ad Science Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "IBAC": {
        "symbol": "IBAC",
        "name": "IB Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "IBACR": {
        "symbol": "IBACR",
        "name": "IB Acquisition Corp. Right",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "IBCP": {
        "symbol": "IBCP",
        "name": "Independent Bank Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "IBEX": {
        "symbol": "IBEX",
        "name": "IBEX Limited Common Shares",
        "country": "Bermuda",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "IBG": {
        "symbol": "IBG",
        "name": "Innovation Beverage Group Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "IBKR": {
        "symbol": "IBKR",
        "name": "Interactive Brokers Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "IBOC": {
        "symbol": "IBOC",
        "name": "International Bancshares Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "IBRX": {
        "symbol": "IBRX",
        "name": "ImmunityBio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IBTX": {
        "symbol": "IBTX",
        "name": "Independent Bank Group Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ICAD": {
        "symbol": "ICAD",
        "name": "iCAD Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ICCC": {
        "symbol": "ICCC",
        "name": "ImmuCell Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "ICCH": {
        "symbol": "ICCH",
        "name": "ICC Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ICCM": {
        "symbol": "ICCM",
        "name": "IceCure Medical Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ICCT": {
        "symbol": "ICCT",
        "name": "iCoreConnect Inc. Common stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ICFI": {
        "symbol": "ICFI",
        "name": "ICF International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "ICG": {
        "symbol": "ICG",
        "name": "Intchains Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ICHR": {
        "symbol": "ICHR",
        "name": "Ichor Holdings Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ICLK": {
        "symbol": "ICLK",
        "name": "iClick Interactive Asia Group Limited American Depositary Shares",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ICLR": {
        "symbol": "ICLR",
        "name": "ICON plc Ordinary Shares",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "ICMB": {
        "symbol": "ICMB",
        "name": "Investcorp Credit Management BDC Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "ICON": {
        "symbol": "ICON",
        "name": "Icon Energy Corp. Common stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "ICU": {
        "symbol": "ICU",
        "name": "SeaStar Medical Holding Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ICUCW": {
        "symbol": "ICUCW",
        "name": "SeaStar Medical Holding Corporation Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ICUI": {
        "symbol": "ICUI",
        "name": "ICU Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IDAI": {
        "symbol": "IDAI",
        "name": "T Stamp Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "IDCC": {
        "symbol": "IDCC",
        "name": "InterDigital Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "IDN": {
        "symbol": "IDN",
        "name": "Intellicheck Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "IDXX": {
        "symbol": "IDXX",
        "name": "IDEXX Laboratories Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "IDYA": {
        "symbol": "IDYA",
        "name": "IDEAYA Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IEP": {
        "symbol": "IEP",
        "name": "Icahn Enterprises L.P. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "IESC": {
        "symbol": "IESC",
        "name": "IES Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Engineering & Construction"
    },
    "IFBD": {
        "symbol": "IFBD",
        "name": "Infobird Co. Ltd Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "IFRX": {
        "symbol": "IFRX",
        "name": "InflaRx N.V. Common Stock",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IGIC": {
        "symbol": "IGIC",
        "name": "International General Insurance Holdings Ltd. Ordinary Share",
        "country": "Jordan",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "IGMS": {
        "symbol": "IGMS",
        "name": "IGM Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IGTA": {
        "symbol": "IGTA",
        "name": "Inception Growth Acquisition Limited Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IGTAU": {
        "symbol": "IGTAU",
        "name": "Inception Growth Acquisition Limited Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IGTAW": {
        "symbol": "IGTAW",
        "name": "Inception Growth Acquisition Limited Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IHRT": {
        "symbol": "IHRT",
        "name": "iHeartMedia Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "III": {
        "symbol": "III",
        "name": "Information Services Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "IIIV": {
        "symbol": "IIIV",
        "name": "i3 Verticals Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "IINN": {
        "symbol": "IINN",
        "name": "Inspira Technologies Oxy B.H.N. Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IINNW": {
        "symbol": "IINNW",
        "name": "Inspira Technologies Oxy B.H.N. Ltd. Warrant",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IKNA": {
        "symbol": "IKNA",
        "name": "Ikena Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IKT": {
        "symbol": "IKT",
        "name": "Inhibikase Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ILAG": {
        "symbol": "ILAG",
        "name": "Intelligent Living Application Group Inc. Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Office Equipment/Supplies/Services"
    },
    "ILMN": {
        "symbol": "ILMN",
        "name": "Illumina Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ILPT": {
        "symbol": "ILPT",
        "name": "Industrial Logistics Properties Trust Common Shares of Beneficial Interest",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "IMAB": {
        "symbol": "IMAB",
        "name": "I-MAB American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMCC": {
        "symbol": "IMCC",
        "name": "IM Cannabis Corp. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "IMCR": {
        "symbol": "IMCR",
        "name": "Immunocore Holdings plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IMKTA": {
        "symbol": "IMKTA",
        "name": "Ingles Markets Incorporated Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "IMMP": {
        "symbol": "IMMP",
        "name": "Immutep Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMMR": {
        "symbol": "IMMR",
        "name": "Immersion Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "IMMX": {
        "symbol": "IMMX",
        "name": "Immix Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMNM": {
        "symbol": "IMNM",
        "name": "Immunome Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMNN": {
        "symbol": "IMNN",
        "name": "Imunon Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMOS": {
        "symbol": "IMOS",
        "name": "ChipMOS TECHNOLOGIES INC. American Depositary Shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "IMPP": {
        "symbol": "IMPP",
        "name": "Imperial Petroleum Inc. Common Shares",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "IMPPP": {
        "symbol": "IMPPP",
        "name": "Imperial Petroleum Inc. 8.75% Series A Cumulative Redeemable Perpetual Preferred Shares",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "IMRN": {
        "symbol": "IMRN",
        "name": "Immuron Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMRX": {
        "symbol": "IMRX",
        "name": "Immuneering Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMTE": {
        "symbol": "IMTE",
        "name": "Integrated Media Technology Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Consumer Staples",
        "industry": "Consumer Electronics/Appliances"
    },
    "IMTX": {
        "symbol": "IMTX",
        "name": "Immatics N.V. Ordinary Shares",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IMTXW": {
        "symbol": "IMTXW",
        "name": "Immatics N.V. Warrants",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IMUX": {
        "symbol": "IMUX",
        "name": "Immunic Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IMVT": {
        "symbol": "IMVT",
        "name": "Immunovant Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IMXI": {
        "symbol": "IMXI",
        "name": "International Money Express Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "INAB": {
        "symbol": "INAB",
        "name": "IN8bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "INAQ": {
        "symbol": "INAQ",
        "name": "Insight Acquisition Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "INAQU": {
        "symbol": "INAQU",
        "name": "Insight Acquisition Corp. Units",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "INAQW": {
        "symbol": "INAQW",
        "name": "Insight Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "INBK": {
        "symbol": "INBK",
        "name": "First Internet Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "INBKZ": {
        "symbol": "INBKZ",
        "name": "First Internet Bancorp 6.0% Fixed-to-Floating Rate Subordinated Notes Due 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "INBS": {
        "symbol": "INBS",
        "name": "Intelligent Bio Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "INBX": {
        "symbol": "INBX",
        "name": "Inhibrx Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "INCR": {
        "symbol": "INCR",
        "name": "Intercure Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INCY": {
        "symbol": "INCY",
        "name": "Incyte Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "INDB": {
        "symbol": "INDB",
        "name": "Independent Bank Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "INDI": {
        "symbol": "INDI",
        "name": "indie Semiconductor Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "INDP": {
        "symbol": "INDP",
        "name": "Indaptus Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INDV": {
        "symbol": "INDV",
        "name": "Indivior PLC Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INFN": {
        "symbol": "INFN",
        "name": "Infinera Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "INGN": {
        "symbol": "INGN",
        "name": "Inogen Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "INHD": {
        "symbol": "INHD",
        "name": "Inno Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "INKT": {
        "symbol": "INKT",
        "name": "MiNK Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "INM": {
        "symbol": "INM",
        "name": "InMed Pharmaceuticals Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INMB": {
        "symbol": "INMB",
        "name": "INmune Bio Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "INMD": {
        "symbol": "INMD",
        "name": "InMode Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "INNV": {
        "symbol": "INNV",
        "name": "InnovAge Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "INO": {
        "symbol": "INO",
        "name": "Inovio Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "INOD": {
        "symbol": "INOD",
        "name": "Innodata Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "INSE": {
        "symbol": "INSE",
        "name": "Inspired Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "INSG": {
        "symbol": "INSG",
        "name": "Inseego Corp. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "INSM": {
        "symbol": "INSM",
        "name": "Insmed Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INTA": {
        "symbol": "INTA",
        "name": "Intapp Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "INTC": {
        "symbol": "INTC",
        "name": "Intel Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "INTE": {
        "symbol": "INTE",
        "name": "Integral Acquisition Corporation 1 Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "INTG": {
        "symbol": "INTG",
        "name": "Intergroup Corporation (The) Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Building operators"
    },
    "INTJ": {
        "symbol": "INTJ",
        "name": "Intelligent Group Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "INTR": {
        "symbol": "INTR",
        "name": "Inter & Co. Inc. Class A Common Shares",
        "country": "Brazil",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "INTS": {
        "symbol": "INTS",
        "name": "Intensity Therapeutics Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "INTU": {
        "symbol": "INTU",
        "name": "Intuit Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "INTZ": {
        "symbol": "INTZ",
        "name": "Intrusion Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "INVA": {
        "symbol": "INVA",
        "name": "Innoviva Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "INVE": {
        "symbol": "INVE",
        "name": "Identiv Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "INVO": {
        "symbol": "INVO",
        "name": "INVO BioScience Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "INVZ": {
        "symbol": "INVZ",
        "name": "Innoviz Technologies Ltd. Ordinary shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "INVZW": {
        "symbol": "INVZW",
        "name": "Innoviz Technologies Ltd. Warrant",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "INZY": {
        "symbol": "INZY",
        "name": "Inozyme Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IOBT": {
        "symbol": "IOBT",
        "name": "IO Biotech Inc. Common Stock",
        "country": "Denmark",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IONR": {
        "symbol": "IONR",
        "name": "ioneer Ltd American Depositary Shares",
        "country": "Australia",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "IONS": {
        "symbol": "IONS",
        "name": "Ionis Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IOSP": {
        "symbol": "IOSP",
        "name": "Innospec Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "IOVA": {
        "symbol": "IOVA",
        "name": "Iovance Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IPA": {
        "symbol": "IPA",
        "name": "ImmunoPrecise Antibodies Ltd. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IPAR": {
        "symbol": "IPAR",
        "name": "Inter Parfums Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "IPDN": {
        "symbol": "IPDN",
        "name": "Professional Diversity Network Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "IPGP": {
        "symbol": "IPGP",
        "name": "IPG Photonics Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "IPHA": {
        "symbol": "IPHA",
        "name": "Innate Pharma S.A. ADS",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IPSC": {
        "symbol": "IPSC",
        "name": "Century Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IPW": {
        "symbol": "IPW",
        "name": "iPower Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "IPWR": {
        "symbol": "IPWR",
        "name": "Ideal Power Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "IPX": {
        "symbol": "IPX",
        "name": "IperionX Limited American Depositary Share",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Other Metals and Minerals"
    },
    "IPXX": {
        "symbol": "IPXX",
        "name": "Inflection Point Acquisition Corp. II Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IPXXU": {
        "symbol": "IPXXU",
        "name": "Inflection Point Acquisition Corp. II Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IPXXW": {
        "symbol": "IPXXW",
        "name": "Inflection Point Acquisition Corp. II Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IQ": {
        "symbol": "IQ",
        "name": "iQIYI Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Video Chains"
    },
    "IRBT": {
        "symbol": "IRBT",
        "name": "iRobot Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Appliances"
    },
    "IRDM": {
        "symbol": "IRDM",
        "name": "Iridium Communications Inc Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "IREN": {
        "symbol": "IREN",
        "name": "Iris Energy Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "IRIX": {
        "symbol": "IRIX",
        "name": "IRIDEX Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "IRMD": {
        "symbol": "IRMD",
        "name": "iRadimed Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IROH": {
        "symbol": "IROH",
        "name": "Iron Horse Acquisitions Corp. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "IROHR": {
        "symbol": "IROHR",
        "name": "Iron Horse Acquisitions Corp. Right",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "IROHW": {
        "symbol": "IROHW",
        "name": "Iron Horse Acquisitions Corp. Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "IRON": {
        "symbol": "IRON",
        "name": "Disc Medicine Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IROQ": {
        "symbol": "IROQ",
        "name": "IF Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "IRTC": {
        "symbol": "IRTC",
        "name": "iRhythm Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "IRWD": {
        "symbol": "IRWD",
        "name": "Ironwood Pharmaceuticals Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ISPC": {
        "symbol": "ISPC",
        "name": "iSpecimen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "ISPO": {
        "symbol": "ISPO",
        "name": "Inspirato Incorporated Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ISPOW": {
        "symbol": "ISPOW",
        "name": "Inspirato Incorporated Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ISPR": {
        "symbol": "ISPR",
        "name": "Ispire Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "ISRG": {
        "symbol": "ISRG",
        "name": "Intuitive Surgical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "ISRL": {
        "symbol": "ISRL",
        "name": "Israel Acquisitions Corp Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ISRLU": {
        "symbol": "ISRLU",
        "name": "Israel Acquisitions Corp Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ISRLW": {
        "symbol": "ISRLW",
        "name": "Israel Acquisitions Corp Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ISSC": {
        "symbol": "ISSC",
        "name": "Innovative Solutions and Support Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ISTR": {
        "symbol": "ISTR",
        "name": "Investar Holding Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ITCI": {
        "symbol": "ITCI",
        "name": "Intra-Cellular Therapies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ITI": {
        "symbol": "ITI",
        "name": "Iteris Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ITIC": {
        "symbol": "ITIC",
        "name": "Investors Title Company Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "ITOS": {
        "symbol": "ITOS",
        "name": "iTeos Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ITRI": {
        "symbol": "ITRI",
        "name": "Itron Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "ITRM": {
        "symbol": "ITRM",
        "name": "Iterum Therapeutics plc Ordinary Share",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ITRN": {
        "symbol": "ITRN",
        "name": "Ituran Location and Control Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "IVA": {
        "symbol": "IVA",
        "name": "Inventiva S.A. American Depository Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IVAC": {
        "symbol": "IVAC",
        "name": "Intevac Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "IVCA": {
        "symbol": "IVCA",
        "name": "Investcorp India Acquisition Corp. Class A Ordinary Share",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVCB": {
        "symbol": "IVCB",
        "name": "Investcorp Europe Acquisition Corp I Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVCBW": {
        "symbol": "IVCBW",
        "name": "Investcorp Europe Acquisition Corp I Warrant",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVCP": {
        "symbol": "IVCP",
        "name": "Swiftmerge Acquisition Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVCPU": {
        "symbol": "IVCPU",
        "name": "Swiftmerge Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVCPW": {
        "symbol": "IVCPW",
        "name": "Swiftmerge Acquisition Corp. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IVDA": {
        "symbol": "IVDA",
        "name": "Iveda Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "IVDAW": {
        "symbol": "IVDAW",
        "name": "Iveda Solutions Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "IVP": {
        "symbol": "IVP",
        "name": "Inspire Veterinary Partners Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "IVVD": {
        "symbol": "IVVD",
        "name": "Invivyd Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "IXAQ": {
        "symbol": "IXAQ",
        "name": "IX Acquisition Corp. Class A Ordinary Share",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IXAQW": {
        "symbol": "IXAQW",
        "name": "IX Acquisition Corp. Warrant",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "IXHL": {
        "symbol": "IXHL",
        "name": "Incannex Healthcare Inc. Common Stock",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "IZEA": {
        "symbol": "IZEA",
        "name": "IZEA Worldwide Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "IZM": {
        "symbol": "IZM",
        "name": "ICZOOM Group Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Video Chains"
    },
    "JACK": {
        "symbol": "JACK",
        "name": "Jack In The Box Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "JAGX": {
        "symbol": "JAGX",
        "name": "Jaguar Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "JAKK": {
        "symbol": "JAKK",
        "name": "JAKKS Pacific Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "JAMF": {
        "symbol": "JAMF",
        "name": "Jamf Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "JANX": {
        "symbol": "JANX",
        "name": "Janux Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "JAZZ": {
        "symbol": "JAZZ",
        "name": "Jazz Pharmaceuticals plc Common Stock (Ireland)",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "JBDI": {
        "symbol": "JBDI",
        "name": "JBDI Holdings Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "",
        "industry": ""
    },
    "JBHT": {
        "symbol": "JBHT",
        "name": "J.B. Hunt Transport Services Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "JBLU": {
        "symbol": "JBLU",
        "name": "JetBlue Airways Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "JBSS": {
        "symbol": "JBSS",
        "name": "John B. Sanfilippo & Son Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Specialty Foods"
    },
    "JCSE": {
        "symbol": "JCSE",
        "name": "JE Cleantech Holdings Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "JCTCF": {
        "symbol": "JCTCF",
        "name": "Jewett-Cameron Trading Company Common Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "JD": {
        "symbol": "JD",
        "name": "JD.com Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "JDZG": {
        "symbol": "JDZG",
        "name": "JIADE LIMITED Common stock",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "JFBR": {
        "symbol": "JFBR",
        "name": "Jeffs' Brands Ltd Ordinary Shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "JFBRW": {
        "symbol": "JFBRW",
        "name": "Jeffs' Brands Ltd Warrant",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "JFIN": {
        "symbol": "JFIN",
        "name": "Jiayin Group Inc. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "JFU": {
        "symbol": "JFU",
        "name": "9F Inc. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "JG": {
        "symbol": "JG",
        "name": "Aurora Mobile Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "JJSF": {
        "symbol": "JJSF",
        "name": "J & J Snack Foods Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Specialty Foods"
    },
    "JKHY": {
        "symbol": "JKHY",
        "name": "Jack Henry & Associates Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "JL": {
        "symbol": "JL",
        "name": "J-Long Group Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "JMSB": {
        "symbol": "JMSB",
        "name": "John Marshall Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "JNVR": {
        "symbol": "JNVR",
        "name": "Janover Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "JOUT": {
        "symbol": "JOUT",
        "name": "Johnson Outdoors Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "JRSH": {
        "symbol": "JRSH",
        "name": "Jerash Holdings (US) Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "JRVR": {
        "symbol": "JRVR",
        "name": "James River Group Holdings Ltd. Common Shares",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "JSM": {
        "symbol": "JSM",
        "name": "Navient Corporation 6% Senior Notes due December 15 2043",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "JSPR": {
        "symbol": "JSPR",
        "name": "Jasper Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "JSPRW": {
        "symbol": "JSPRW",
        "name": "Japer Therapeutics Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "JTAI": {
        "symbol": "JTAI",
        "name": "Jet.AI Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "JUNE": {
        "symbol": "JUNE",
        "name": "Junee Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "",
        "industry": ""
    },
    "JVA": {
        "symbol": "JVA",
        "name": "Coffee Holding Co. Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "JVSA": {
        "symbol": "JVSA",
        "name": "JV SPAC Acquisition Corp. Class A Ordinary Share",
        "country": "China",
        "sector": "",
        "industry": ""
    },
    "JVSAU": {
        "symbol": "JVSAU",
        "name": "JVSPAC Acquisition Corp. Unit",
        "country": "China",
        "sector": "",
        "industry": ""
    },
    "JWEL": {
        "symbol": "JWEL",
        "name": "Jowell Global Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "JXJT": {
        "symbol": "JXJT",
        "name": "JX Luxventure Limited Common Stock",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Apparel"
    },
    "JYD": {
        "symbol": "JYD",
        "name": "Jayud Global Logistics Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "JYNT": {
        "symbol": "JYNT",
        "name": "The Joint Corp. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "JZ": {
        "symbol": "JZ",
        "name": "Jianzhi Education Technology Group Company Limited American Depositary Shs",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "JZXN": {
        "symbol": "JZXN",
        "name": "Jiuzi Holdings Inc. Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "KACL": {
        "symbol": "KACL",
        "name": "Kairous Acquisition Corp. Limited Ordinary Shares",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "KACLR": {
        "symbol": "KACLR",
        "name": "Kairous Acquisition Corp. Limited Rights",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "KALA": {
        "symbol": "KALA",
        "name": "KALA BIO Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KALU": {
        "symbol": "KALU",
        "name": "Kaiser Aluminum Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "KALV": {
        "symbol": "KALV",
        "name": "KalVista Pharmaceuticals Inc. Common Stock",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KARO": {
        "symbol": "KARO",
        "name": "Karooooo Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "KAVL": {
        "symbol": "KAVL",
        "name": "Kaival Brands Innovations Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "KC": {
        "symbol": "KC",
        "name": "Kingsoft Cloud Holdings Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "KDLY": {
        "symbol": "KDLY",
        "name": "Kindly MD Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "KDLYW": {
        "symbol": "KDLYW",
        "name": "Kindly MD Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "KDP": {
        "symbol": "KDP",
        "name": "Keurig Dr Pepper Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "KE": {
        "symbol": "KE",
        "name": "Kimball Electronics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "KELYA": {
        "symbol": "KELYA",
        "name": "Kelly Services Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "KELYB": {
        "symbol": "KELYB",
        "name": "Kelly Services Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "KEQU": {
        "symbol": "KEQU",
        "name": "Kewaunee Scientific Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Medical Specialities"
    },
    "KFFB": {
        "symbol": "KFFB",
        "name": "Kentucky First Federal Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "KGEI": {
        "symbol": "KGEI",
        "name": "Kolibri Global Energy Inc. Common stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "KHC": {
        "symbol": "KHC",
        "name": "The Kraft Heinz Company Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "KIDS": {
        "symbol": "KIDS",
        "name": "OrthoPediatrics Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "KINS": {
        "symbol": "KINS",
        "name": "Kingstone Companies Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "KIRK": {
        "symbol": "KIRK",
        "name": "Kirkland's Inc. COMMONSTOCK",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "KITT": {
        "symbol": "KITT",
        "name": "Nauticus Robotics Inc. Common stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "KITTW": {
        "symbol": "KITTW",
        "name": "Nauticus Robotics Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "KLAC": {
        "symbol": "KLAC",
        "name": "KLA Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "KLIC": {
        "symbol": "KLIC",
        "name": "Kulicke and Soffa Industries Inc. Common Stock",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "KLTO": {
        "symbol": "KLTO",
        "name": "Klotho Neurosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KLTOW": {
        "symbol": "KLTOW",
        "name": "Klotho Neurosciences Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KLTR": {
        "symbol": "KLTR",
        "name": "Kaltura Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "KLXE": {
        "symbol": "KLXE",
        "name": "KLX Energy Services Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oilfield Services/Equipment"
    },
    "KMDA": {
        "symbol": "KMDA",
        "name": "Kamada Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KNDI": {
        "symbol": "KNDI",
        "name": "Kandi Technologies Group Inc. Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "KNSA": {
        "symbol": "KNSA",
        "name": "Kiniksa Pharmaceuticals International plc Class A Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KOD": {
        "symbol": "KOD",
        "name": "Kodiak Sciences Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KOPN": {
        "symbol": "KOPN",
        "name": "Kopin Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "KOSS": {
        "symbol": "KOSS",
        "name": "Koss Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Consumer Electronics/Appliances"
    },
    "KPLT": {
        "symbol": "KPLT",
        "name": "Katapult Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "KPLTW": {
        "symbol": "KPLTW",
        "name": "Katapult Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "KPRX": {
        "symbol": "KPRX",
        "name": "Kiora Pharmaceuticals Inc.  Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KPTI": {
        "symbol": "KPTI",
        "name": "Karyopharm Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KRKR": {
        "symbol": "KRKR",
        "name": "36Kr Holdings Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "KRMD": {
        "symbol": "KRMD",
        "name": "KORU Medical Systems Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "KRNT": {
        "symbol": "KRNT",
        "name": "Kornit Digital Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "KRNY": {
        "symbol": "KRNY",
        "name": "Kearny Financial Corp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "KRON": {
        "symbol": "KRON",
        "name": "Kronos Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KROS": {
        "symbol": "KROS",
        "name": "Keros Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KRRO": {
        "symbol": "KRRO",
        "name": "Korro Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KRT": {
        "symbol": "KRT",
        "name": "Karat Packaging Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "KRUS": {
        "symbol": "KRUS",
        "name": "Kura Sushi USA Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "KRYS": {
        "symbol": "KRYS",
        "name": "Krystal Biotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KSCP": {
        "symbol": "KSCP",
        "name": "Knightscope Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "KSPI": {
        "symbol": "KSPI",
        "name": "Joint Stock Company Kaspi.kz American Depository Shares",
        "country": "Kazakhstan",
        "sector": "",
        "industry": ""
    },
    "KTCC": {
        "symbol": "KTCC",
        "name": "Key Tronic Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "KTOS": {
        "symbol": "KTOS",
        "name": "Kratos Defense & Security Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "KTRA": {
        "symbol": "KTRA",
        "name": "Kintara Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KTTA": {
        "symbol": "KTTA",
        "name": "Pasithea Therapeutics Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KTTAW": {
        "symbol": "KTTAW",
        "name": "Pasithea Therapeutics Corp. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KURA": {
        "symbol": "KURA",
        "name": "Kura Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KVAC": {
        "symbol": "KVAC",
        "name": "Keen Vision Acquisition Corporation Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "KVACW": {
        "symbol": "KVACW",
        "name": "Keen Vision Acquisition Corporation Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "KVHI": {
        "symbol": "KVHI",
        "name": "KVH Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "KWE": {
        "symbol": "KWE",
        "name": "KWESST Micro Systems Inc. Common Stock",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "KWESW": {
        "symbol": "KWESW",
        "name": "KWESST Micro Systems Inc. Warrant",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "KXIN": {
        "symbol": "KXIN",
        "name": "Kaixin Holdings Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "KYMR": {
        "symbol": "KYMR",
        "name": "Kymera Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KYTX": {
        "symbol": "KYTX",
        "name": "Kyverna Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "KZIA": {
        "symbol": "KZIA",
        "name": "Kazia Therapeutics Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "KZR": {
        "symbol": "KZR",
        "name": "Kezar Life Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LAB": {
        "symbol": "LAB",
        "name": "Standard BioTools Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "LAES": {
        "symbol": "LAES",
        "name": "SEALSQ Corp Ordinary Shares",
        "country": "Switzerland",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "LAKE": {
        "symbol": "LAKE",
        "name": "Lakeland Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "LAMR": {
        "symbol": "LAMR",
        "name": "Lamar Advertising Company Class A Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LANC": {
        "symbol": "LANC",
        "name": "Lancaster Colony Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "LAND": {
        "symbol": "LAND",
        "name": "Gladstone Land Corporation Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LANDM": {
        "symbol": "LANDM",
        "name": "Gladstone Land Corporation 5.00% Series D Cumulative Term Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LANDO": {
        "symbol": "LANDO",
        "name": "Gladstone Land Corporation 6.00% Series B Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LANDP": {
        "symbol": "LANDP",
        "name": "Gladstone Land Corporation 6.00% Series C Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LARK": {
        "symbol": "LARK",
        "name": "Landmark Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "LASE": {
        "symbol": "LASE",
        "name": "Laser Photonics Corporation Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "LASR": {
        "symbol": "LASR",
        "name": "nLIGHT Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "LATG": {
        "symbol": "LATG",
        "name": "Chenghe Acquisition I Co. Class A Ordinary Shares",
        "country": "Mexico",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "LATGU": {
        "symbol": "LATGU",
        "name": "Chenghe Acquisition I Co. Unit",
        "country": "Mexico",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "LAUR": {
        "symbol": "LAUR",
        "name": "Laureate Education Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "LAZR": {
        "symbol": "LAZR",
        "name": "Luminar Technologies Inc.  Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "LBPH": {
        "symbol": "LBPH",
        "name": "Longboard Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LBRDA": {
        "symbol": "LBRDA",
        "name": "Liberty Broadband Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LBRDK": {
        "symbol": "LBRDK",
        "name": "Liberty Broadband Corporation Class C Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LBRDP": {
        "symbol": "LBRDP",
        "name": "Liberty Broadband Corporation Series A Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LBTYA": {
        "symbol": "LBTYA",
        "name": "Liberty Global Ltd. Class A Common Shares",
        "country": "United Kingdom",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LBTYB": {
        "symbol": "LBTYB",
        "name": "Liberty Global Ltd. Class B Common Shares",
        "country": "United Kingdom",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LBTYK": {
        "symbol": "LBTYK",
        "name": "Liberty Global Ltd. Class C Common Shares",
        "country": "United Kingdom",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LCFY": {
        "symbol": "LCFY",
        "name": "Locafy Limited Ordinary Share",
        "country": "Australia",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "LCFYW": {
        "symbol": "LCFYW",
        "name": "Locafy Limited Warrant",
        "country": "Australia",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "LCID": {
        "symbol": "LCID",
        "name": "Lucid Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "LCNB": {
        "symbol": "LCNB",
        "name": "LCNB Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "LCUT": {
        "symbol": "LCUT",
        "name": "Lifetime Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Machinery/Components"
    },
    "LCW": {
        "symbol": "LCW",
        "name": "Learn CW Investment Corporation Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "LDTC": {
        "symbol": "LDTC",
        "name": "LeddarTech Holdings Inc. Common Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LDTCW": {
        "symbol": "LDTCW",
        "name": "LeddarTech Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LDWY": {
        "symbol": "LDWY",
        "name": "Lendway Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "LE": {
        "symbol": "LE",
        "name": "Lands' End Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "LECO": {
        "symbol": "LECO",
        "name": "Lincoln Electric Holdings Inc. Common Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "LEDS": {
        "symbol": "LEDS",
        "name": "SemiLEDS Corporation Common Stock",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "LEE": {
        "symbol": "LEE",
        "name": "Lee Enterprises Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "LEGH": {
        "symbol": "LEGH",
        "name": "Legacy Housing Corporation Common Stock (TX)",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "LEGN": {
        "symbol": "LEGN",
        "name": "Legend Biotech Corporation American Depositary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LENZ": {
        "symbol": "LENZ",
        "name": "LENZ Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "LESL": {
        "symbol": "LESL",
        "name": "Leslie's Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "LEXX": {
        "symbol": "LEXX",
        "name": "Lexaria Bioscience Corp. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LEXXW": {
        "symbol": "LEXXW",
        "name": "Lexaria Bioscience Corp. Warrant",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LFCR": {
        "symbol": "LFCR",
        "name": "Lifecore Biomedical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LFLY": {
        "symbol": "LFLY",
        "name": "Leafly Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LFLYW": {
        "symbol": "LFLYW",
        "name": "Leafly Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LFMD": {
        "symbol": "LFMD",
        "name": "LifeMD Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "LFMDP": {
        "symbol": "LFMDP",
        "name": "LifeMD Inc. 8.875% Series A Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "LFST": {
        "symbol": "LFST",
        "name": "LifeStance Health Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "LFUS": {
        "symbol": "LFUS",
        "name": "Littelfuse Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Electrical Products"
    },
    "LFVN": {
        "symbol": "LFVN",
        "name": "Lifevantage Corporation Common Stock (Delaware)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LFWD": {
        "symbol": "LFWD",
        "name": "Lifeward Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "LGCB": {
        "symbol": "LGCB",
        "name": "Linkage Global Inc Ordinary Shares",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "LGCL": {
        "symbol": "LGCL",
        "name": "Lucas GC Limited Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "LGHL": {
        "symbol": "LGHL",
        "name": "Lion Group Holding Ltd. American Depositary Share",
        "country": "China",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "LGHLW": {
        "symbol": "LGHLW",
        "name": "Lion Group Holding Ltd. Warrant",
        "country": "China",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "LGIH": {
        "symbol": "LGIH",
        "name": "LGI Homes Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "LGMK": {
        "symbol": "LGMK",
        "name": "LogicMark Inc. Common Stock (NV)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "LGND": {
        "symbol": "LGND",
        "name": "Ligand Pharmaceuticals Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LGO": {
        "symbol": "LGO",
        "name": "Largo Inc. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "LGVN": {
        "symbol": "LGVN",
        "name": "Longeveron Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LI": {
        "symbol": "LI",
        "name": "Li Auto Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "LICN": {
        "symbol": "LICN",
        "name": "Lichen China Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "LIDR": {
        "symbol": "LIDR",
        "name": "AEye Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "LIDRW": {
        "symbol": "LIDRW",
        "name": "AEye Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "LIF": {
        "symbol": "LIF",
        "name": "Life360 Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "LIFW": {
        "symbol": "LIFW",
        "name": "MSP Recovery Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LIFWW": {
        "symbol": "LIFWW",
        "name": "MSP Recovery Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LIFWZ": {
        "symbol": "LIFWZ",
        "name": "MSP Recovery Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LILA": {
        "symbol": "LILA",
        "name": "Liberty Latin America Ltd. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LILAK": {
        "symbol": "LILAK",
        "name": "Liberty Latin America Ltd. Class C Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "LILM": {
        "symbol": "LILM",
        "name": "Lilium NV Class A Ordinary Shares",
        "country": "Germany",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "LILMW": {
        "symbol": "LILMW",
        "name": "Lilium N.V. Warrants",
        "country": "Germany",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "LIN": {
        "symbol": "LIN",
        "name": "Linde plc Ordinary Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "LINC": {
        "symbol": "LINC",
        "name": "Lincoln Educational Services Corporation Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "LIND": {
        "symbol": "LIND",
        "name": "Lindblad Expeditions Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "LINE": {
        "symbol": "LINE",
        "name": "Lineage Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LINK": {
        "symbol": "LINK",
        "name": "Interlink Electronics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "LION": {
        "symbol": "LION",
        "name": "Lionsgate Studios Corp. Common Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "LIPO": {
        "symbol": "LIPO",
        "name": "Lipella Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LIQT": {
        "symbol": "LIQT",
        "name": "LiqTech International Inc. Common Stock",
        "country": "Denmark",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "LITE": {
        "symbol": "LITE",
        "name": "Lumentum Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "LITM": {
        "symbol": "LITM",
        "name": "Snow Lake Resources Ltd. Common Shares",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Other Metals and Minerals"
    },
    "LIVE": {
        "symbol": "LIVE",
        "name": "Live Ventures Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "LIVN": {
        "symbol": "LIVN",
        "name": "LivaNova PLC Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "LIXT": {
        "symbol": "LIXT",
        "name": "Lixte Biotechnology Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LIXTW": {
        "symbol": "LIXTW",
        "name": "Lixte Biotechnology Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LKCO": {
        "symbol": "LKCO",
        "name": "Luokung Technology Corp Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LKFN": {
        "symbol": "LKFN",
        "name": "Lakeland Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "LKQ": {
        "symbol": "LKQ",
        "name": "LKQ Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "LLYVA": {
        "symbol": "LLYVA",
        "name": "Liberty Media Corporation Series A Liberty Live Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "LLYVK": {
        "symbol": "LLYVK",
        "name": "Liberty Media Corporation Series C Liberty Live Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "LMAT": {
        "symbol": "LMAT",
        "name": "LeMaitre Vascular Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "LMB": {
        "symbol": "LMB",
        "name": "Limbach Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "LMFA": {
        "symbol": "LMFA",
        "name": "LM Funding America Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "LMNR": {
        "symbol": "LMNR",
        "name": "Limoneira Co Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "LNKB": {
        "symbol": "LNKB",
        "name": "LINKBANCORP Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "LNSR": {
        "symbol": "LNSR",
        "name": "LENSAR Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "LNT": {
        "symbol": "LNT",
        "name": "Alliant Energy Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "LNTH": {
        "symbol": "LNTH",
        "name": "Lantheus Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "LNW": {
        "symbol": "LNW",
        "name": "Light & Wonder Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LNZA": {
        "symbol": "LNZA",
        "name": "LanzaTech Global Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "LNZAW": {
        "symbol": "LNZAW",
        "name": "LanzaTech Global Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "LOAN": {
        "symbol": "LOAN",
        "name": "Manhattan Bridge Capital Inc",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "LOBO": {
        "symbol": "LOBO",
        "name": "LOBO EV TECHNOLOGIES LTD. Ordinary shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "LOCO": {
        "symbol": "LOCO",
        "name": "El Pollo Loco Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "LOGC": {
        "symbol": "LOGC",
        "name": "ContextLogic Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "LOGI": {
        "symbol": "LOGI",
        "name": "Logitech International S.A. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "LOOP": {
        "symbol": "LOOP",
        "name": "Loop Industries Inc. Common Stock",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "LOPE": {
        "symbol": "LOPE",
        "name": "Grand Canyon Education Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "LOT": {
        "symbol": "LOT",
        "name": "Lotus Technology Inc. American Depositary Shares",
        "country": "China",
        "sector": "",
        "industry": ""
    },
    "LOTWW": {
        "symbol": "LOTWW",
        "name": "Lotus Technology Inc. Warrants",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "LOVE": {
        "symbol": "LOVE",
        "name": "The Lovesac Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "LPAA": {
        "symbol": "LPAA",
        "name": "Launch One Acquisition Corp. Class A Ordinary shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "LPAAU": {
        "symbol": "LPAAU",
        "name": "Launch One Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "LPAAW": {
        "symbol": "LPAAW",
        "name": "Launch One Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "LPCN": {
        "symbol": "LPCN",
        "name": "Lipocine Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LPLA": {
        "symbol": "LPLA",
        "name": "LPL Financial Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "LPRO": {
        "symbol": "LPRO",
        "name": "Open Lending Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "LPSN": {
        "symbol": "LPSN",
        "name": "LivePerson Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LPTH": {
        "symbol": "LPTH",
        "name": "LightPath Technologies Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "LPTX": {
        "symbol": "LPTX",
        "name": "Leap Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LQDA": {
        "symbol": "LQDA",
        "name": "Liquidia Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LQDT": {
        "symbol": "LQDT",
        "name": "Liquidity Services Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "LQR": {
        "symbol": "LQR",
        "name": "LQR House Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "LRCX": {
        "symbol": "LRCX",
        "name": "Lam Research Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "LRE": {
        "symbol": "LRE",
        "name": "Lead Real Estate Co. Ltd American Depositary Shares",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "LRFC": {
        "symbol": "LRFC",
        "name": "Logan Ridge Finance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "LRHC": {
        "symbol": "LRHC",
        "name": "La Rosa Holdings Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "LRMR": {
        "symbol": "LRMR",
        "name": "Larimar Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LSAK": {
        "symbol": "LSAK",
        "name": "Lesaka Technologies Inc. Common Stock",
        "country": "South Africa",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "LSB": {
        "symbol": "LSB",
        "name": "LakeShore Biopharma Co. Ltd Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LSBK": {
        "symbol": "LSBK",
        "name": "Lake Shore Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "LSBPW": {
        "symbol": "LSBPW",
        "name": "LakeShore Biopharma Co. Ltd Warrants",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LSCC": {
        "symbol": "LSCC",
        "name": "Lattice Semiconductor Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "LSEA": {
        "symbol": "LSEA",
        "name": "Landsea Homes Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "LSEAW": {
        "symbol": "LSEAW",
        "name": "Landsea Homes Corporation Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "LSH": {
        "symbol": "LSH",
        "name": "Lakeside Holding Limited Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "LSTA": {
        "symbol": "LSTA",
        "name": "Lisata Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "LSTR": {
        "symbol": "LSTR",
        "name": "Landstar System Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "LTBR": {
        "symbol": "LTBR",
        "name": "Lightbridge Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "LTRN": {
        "symbol": "LTRN",
        "name": "Lantern Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LTRX": {
        "symbol": "LTRX",
        "name": "Lantronix Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "LTRY": {
        "symbol": "LTRY",
        "name": "Lottery.com Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LTRYW": {
        "symbol": "LTRYW",
        "name": "Lottery.com Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "LUCD": {
        "symbol": "LUCD",
        "name": "Lucid Diagnostics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "LUCY": {
        "symbol": "LUCY",
        "name": "Innovative Eyewear Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "LUCYW": {
        "symbol": "LUCYW",
        "name": "Innovative Eyewear Inc. Series A Warrants 8/16/27",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "LULU": {
        "symbol": "LULU",
        "name": "lululemon athletica inc. Common Stock",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "LUMO": {
        "symbol": "LUMO",
        "name": "Lumos Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LUNA": {
        "symbol": "LUNA",
        "name": "Luna Innovations Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "LUNG": {
        "symbol": "LUNG",
        "name": "Pulmonx Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "LUNR": {
        "symbol": "LUNR",
        "name": "Intuitive Machines Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "LUNRW": {
        "symbol": "LUNRW",
        "name": "Intuitive Machines Inc. Warrants",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "LUXH": {
        "symbol": "LUXH",
        "name": "LuxUrban Hotels Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "LUXHP": {
        "symbol": "LUXHP",
        "name": "LuxUrban Hotels Inc. 13.00% Series A Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "LVLU": {
        "symbol": "LVLU",
        "name": "Lulu's Fashion Lounge Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "LVO": {
        "symbol": "LVO",
        "name": "LiveOne Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "LVRO": {
        "symbol": "LVRO",
        "name": "Lavoro Limited Class A Ordinary Shares",
        "country": "Brazil",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "LVROW": {
        "symbol": "LVROW",
        "name": "Lavoro Limited Warrant",
        "country": "Brazil",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "LVTX": {
        "symbol": "LVTX",
        "name": "LAVA Therapeutics N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LWAY": {
        "symbol": "LWAY",
        "name": "Lifeway Foods Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "LWLG": {
        "symbol": "LWLG",
        "name": "Lightwave Logic Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Containers/Packaging"
    },
    "LX": {
        "symbol": "LX",
        "name": "LexinFintech Holdings Ltd. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "LXEH": {
        "symbol": "LXEH",
        "name": "Lixiang Education Holding Co. Ltd. American Depositary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "LXEO": {
        "symbol": "LXEO",
        "name": "Lexeo Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "LXRX": {
        "symbol": "LXRX",
        "name": "Lexicon Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LYEL": {
        "symbol": "LYEL",
        "name": "Lyell Immunopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "LYFT": {
        "symbol": "LYFT",
        "name": "Lyft Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "LYRA": {
        "symbol": "LYRA",
        "name": "Lyra Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "LYT": {
        "symbol": "LYT",
        "name": "Lytus Technologies Holdings PTV. Ltd. Common Shares",
        "country": "United Arab Emirates",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "LYTS": {
        "symbol": "LYTS",
        "name": "LSI Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Building Products"
    },
    "LZ": {
        "symbol": "LZ",
        "name": "LegalZoom.com Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MACI": {
        "symbol": "MACI",
        "name": "Melar Acquisition Corp. I Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "MACIW": {
        "symbol": "MACIW",
        "name": "Melar Acquisition Corp. I Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "MAMA": {
        "symbol": "MAMA",
        "name": "Mama's Creations Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Specialty Foods"
    },
    "MAMO": {
        "symbol": "MAMO",
        "name": "Massimo Group Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "MANH": {
        "symbol": "MANH",
        "name": "Manhattan Associates Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MAPS": {
        "symbol": "MAPS",
        "name": "WM Technology Inc. Class A Common Stock ",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MAPSW": {
        "symbol": "MAPSW",
        "name": "WM Technology Inc. Warrants ",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MAR": {
        "symbol": "MAR",
        "name": "Marriott International Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "MARA": {
        "symbol": "MARA",
        "name": "MARA Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MARPS": {
        "symbol": "MARPS",
        "name": "Marine Petroleum Trust Units of Beneficial Interest",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "MARX": {
        "symbol": "MARX",
        "name": "Mars Acquisition Corp. Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MASI": {
        "symbol": "MASI",
        "name": "Masimo Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "MASS": {
        "symbol": "MASS",
        "name": "908 Devices Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "MAT": {
        "symbol": "MAT",
        "name": "Mattel Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "MATH": {
        "symbol": "MATH",
        "name": "Metalpha Technology Holding Limited Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "MATW": {
        "symbol": "MATW",
        "name": "Matthews International Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Metal Fabrications"
    },
    "MAXN": {
        "symbol": "MAXN",
        "name": "Maxeon Solar Technologies Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MAYS": {
        "symbol": "MAYS",
        "name": "J. W. Mays Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Building operators"
    },
    "MBAV": {
        "symbol": "MBAV",
        "name": "M3-Brigade Acquisition V Corp. Class A Ordinary shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "MBAVU": {
        "symbol": "MBAVU",
        "name": "M3-Brigade Acquisition V Corp. Units",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MBAVW": {
        "symbol": "MBAVW",
        "name": "M3-Brigade Acquisition V Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MBCN": {
        "symbol": "MBCN",
        "name": "Middlefield Banc Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBIN": {
        "symbol": "MBIN",
        "name": "Merchants Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBINM": {
        "symbol": "MBINM",
        "name": "Merchants Bancorp Depositary Shares Each Representing a 1/40th Interest in a Share of 8.25% Fixed-Rate Reset Series D Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBINN": {
        "symbol": "MBINN",
        "name": "Merchants Bancorp Depositary Shares Preferred Series C",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBINO": {
        "symbol": "MBINO",
        "name": "Merchants Bancorp Depositary Shares Each Representing a 1/40th Interest in a Share of Series B  Fixed-to-Floating Rate",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBIO": {
        "symbol": "MBIO",
        "name": "Mustang Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MBLY": {
        "symbol": "MBLY",
        "name": "Mobileye Global Inc. Class A Common Stock",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MBNKP": {
        "symbol": "MBNKP",
        "name": "Medallion Bank Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock Series F",
        "country": "United States",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "MBOT": {
        "symbol": "MBOT",
        "name": "Microbot Medical Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "MBRX": {
        "symbol": "MBRX",
        "name": "Moleculin Biotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MBUU": {
        "symbol": "MBUU",
        "name": "Malibu Boats Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Marine Transportation"
    },
    "MBWM": {
        "symbol": "MBWM",
        "name": "Mercantile Bank Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MBX": {
        "symbol": "MBX",
        "name": "MBX Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MCAA": {
        "symbol": "MCAA",
        "name": "Mountain & Co. I Acquisition Corp. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MCAG": {
        "symbol": "MCAG",
        "name": "Mountain Crest Acquisition Corp. V Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MCBS": {
        "symbol": "MCBS",
        "name": "MetroCity Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MCFT": {
        "symbol": "MCFT",
        "name": "MasterCraft Boat Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Marine Transportation"
    },
    "MCHP": {
        "symbol": "MCHP",
        "name": "Microchip Technology Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MCHX": {
        "symbol": "MCHX",
        "name": "Marchex Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "MCRB": {
        "symbol": "MCRB",
        "name": "Seres Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MCRI": {
        "symbol": "MCRI",
        "name": "Monarch Casino & Resort Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "MCVT": {
        "symbol": "MCVT",
        "name": "Mill City Ventures III Ltd. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "MDAI": {
        "symbol": "MDAI",
        "name": "Spectral AI Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MDAIW": {
        "symbol": "MDAIW",
        "name": "Spectral AI Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MDB": {
        "symbol": "MDB",
        "name": "MongoDB Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MDBH": {
        "symbol": "MDBH",
        "name": "MDB Capital Holdings LLC Class A common",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MDGL": {
        "symbol": "MDGL",
        "name": "Madrigal Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MDIA": {
        "symbol": "MDIA",
        "name": "Mediaco Holding Inc. Class A Common Stock ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "MDJH": {
        "symbol": "MDJH",
        "name": "MDJM LTD Ordinary Share",
        "country": "China",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "MDLZ": {
        "symbol": "MDLZ",
        "name": "Mondelez International Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "MDRR": {
        "symbol": "MDRR",
        "name": "Medalist Diversified REIT Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "MDRRP": {
        "symbol": "MDRRP",
        "name": "Medalist Diversified REIT Inc. Series A Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "MDWD": {
        "symbol": "MDWD",
        "name": "MediWound Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "MDXG": {
        "symbol": "MDXG",
        "name": "MiMedx Group Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MDXH": {
        "symbol": "MDXH",
        "name": "MDxHealth SA Ordinary Shares",
        "country": "Belgium",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "ME": {
        "symbol": "ME",
        "name": "23andMe Holding Co. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MEDP": {
        "symbol": "MEDP",
        "name": "Medpace Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "MEGL": {
        "symbol": "MEGL",
        "name": "Magic Empire Global Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MEIP": {
        "symbol": "MEIP",
        "name": "MEI Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MELI": {
        "symbol": "MELI",
        "name": "MercadoLibre Inc. Common Stock",
        "country": "Argentina",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "MEOH": {
        "symbol": "MEOH",
        "name": "Methanex Corporation Common Stock",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "MERC": {
        "symbol": "MERC",
        "name": "Mercer International Inc. Common Stock",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Paper"
    },
    "MESA": {
        "symbol": "MESA",
        "name": "Mesa Air Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "MESO": {
        "symbol": "MESO",
        "name": "Mesoblast Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "META": {
        "symbol": "META",
        "name": "Meta Platforms Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "METC": {
        "symbol": "METC",
        "name": "Ramaco Resources Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "METCB": {
        "symbol": "METCB",
        "name": "Ramaco Resources Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "METCL": {
        "symbol": "METCL",
        "name": "Ramaco Resources Inc. 9.00% Senior Notes due 2026",
        "country": "United States",
        "sector": "Energy",
        "industry": "Coal Mining"
    },
    "MFH": {
        "symbol": "MFH",
        "name": "Mercurity Fintech Holding Inc. Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MFI": {
        "symbol": "MFI",
        "name": "mF International Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MFIC": {
        "symbol": "MFIC",
        "name": "MidCap Financial Investment Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "MFICL": {
        "symbol": "MFICL",
        "name": "MidCap Financial Investment Corporation 8.00% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "MFIN": {
        "symbol": "MFIN",
        "name": "Medallion Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MGEE": {
        "symbol": "MGEE",
        "name": "MGE Energy Inc",
        "country": "United States",
        "sector": "Energy",
        "industry": "Electric Utilities: Central"
    },
    "MGIC": {
        "symbol": "MGIC",
        "name": "Magic Software Enterprises Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MGIH": {
        "symbol": "MGIH",
        "name": "Millennium Group International Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Publishing"
    },
    "MGNI": {
        "symbol": "MGNI",
        "name": "Magnite Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "MGNX": {
        "symbol": "MGNX",
        "name": "MacroGenics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MGOL": {
        "symbol": "MGOL",
        "name": "MGO Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "MGPI": {
        "symbol": "MGPI",
        "name": "MGP Ingredients Inc.",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "MGRC": {
        "symbol": "MGRC",
        "name": "McGrath RentCorp Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "MGRM": {
        "symbol": "MGRM",
        "name": "Monogram Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MGRX": {
        "symbol": "MGRX",
        "name": "Mangoceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "MGTX": {
        "symbol": "MGTX",
        "name": "MeiraGTx Holdings plc Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "MGX": {
        "symbol": "MGX",
        "name": "Metagenomi Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "MGYR": {
        "symbol": "MGYR",
        "name": "Magyar Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "MHLD": {
        "symbol": "MHLD",
        "name": "Maiden Holdings Ltd.",
        "country": "Bermuda",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "MHUA": {
        "symbol": "MHUA",
        "name": "Meihua International Medical Technologies Co. Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MIDD": {
        "symbol": "MIDD",
        "name": "Middleby Corporation (The) Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "MIGI": {
        "symbol": "MIGI",
        "name": "Mawson Infrastructure Group Inc. Common Stock",
        "country": "Australia",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MIND": {
        "symbol": "MIND",
        "name": "MIND Technology Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "MIRA": {
        "symbol": "MIRA",
        "name": "MIRA Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MIRM": {
        "symbol": "MIRM",
        "name": "Mirum Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MIST": {
        "symbol": "MIST",
        "name": "Milestone Pharmaceuticals Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MITA": {
        "symbol": "MITA",
        "name": "Coliseum Acquisition Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MITAW": {
        "symbol": "MITAW",
        "name": "Coliseum Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MITK": {
        "symbol": "MITK",
        "name": "Mitek Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "MKDW": {
        "symbol": "MKDW",
        "name": "MKDWELL Tech Inc. Ordinary Share",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Consumer Electronics/Appliances"
    },
    "MKDWW": {
        "symbol": "MKDWW",
        "name": "MKDWELL Tech Inc. Warrants",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Consumer Electronics/Appliances"
    },
    "MKSI": {
        "symbol": "MKSI",
        "name": "MKS Instruments Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "MKTW": {
        "symbol": "MKTW",
        "name": "MarketWise Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MKTX": {
        "symbol": "MKTX",
        "name": "MarketAxess Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "MLAB": {
        "symbol": "MLAB",
        "name": "Mesa Laboratories Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "MLCO": {
        "symbol": "MLCO",
        "name": "Melco Resorts & Entertainment Limited American Depositary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "MLEC": {
        "symbol": "MLEC",
        "name": "Moolec Science SA Ordinary Shares",
        "country": "Luxembourg",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MLECW": {
        "symbol": "MLECW",
        "name": "Moolec Science SA Warrant",
        "country": "Luxembourg",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MLGO": {
        "symbol": "MLGO",
        "name": "MicroAlgo Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MLKN": {
        "symbol": "MLKN",
        "name": "MillerKnoll Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Office Equipment/Supplies/Services"
    },
    "MLTX": {
        "symbol": "MLTX",
        "name": "MoonLake Immunotherapeutics Class A Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MLYS": {
        "symbol": "MLYS",
        "name": "Mineralys Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MMLP": {
        "symbol": "MMLP",
        "name": "Martin Midstream Partners L.P. Limited Partnership",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil Refining/Marketing"
    },
    "MMSI": {
        "symbol": "MMSI",
        "name": "Merit Medical Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MMV": {
        "symbol": "MMV",
        "name": "MultiMetaVerse Holdings Limited Class A Ordinary Share",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MMYT": {
        "symbol": "MMYT",
        "name": "MakeMyTrip Limited Ordinary Shares",
        "country": "India",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "MNDO": {
        "symbol": "MNDO",
        "name": "MIND C.T.I. Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MNDR": {
        "symbol": "MNDR",
        "name": "Mobile-health Network Solutions Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MNDY": {
        "symbol": "MNDY",
        "name": "monday.com Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MNKD": {
        "symbol": "MNKD",
        "name": "MannKind Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MNMD": {
        "symbol": "MNMD",
        "name": "Mind Medicine (MindMed) Inc. Common Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "MNOV": {
        "symbol": "MNOV",
        "name": "Medicinova Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MNPR": {
        "symbol": "MNPR",
        "name": "Monopar Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MNRO": {
        "symbol": "MNRO",
        "name": "Monro Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "MNSB": {
        "symbol": "MNSB",
        "name": "MainStreet Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MNSBP": {
        "symbol": "MNSBP",
        "name": "MainStreet Bancshares Inc. Depositary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MNST": {
        "symbol": "MNST",
        "name": "Monster Beverage Corporation",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "MNTK": {
        "symbol": "MNTK",
        "name": "Montauk Renewables Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Natural Gas Distribution"
    },
    "MNTS": {
        "symbol": "MNTS",
        "name": "Momentus Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "MNTSW": {
        "symbol": "MNTSW",
        "name": "Momentus Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "MNTX": {
        "symbol": "MNTX",
        "name": "Manitex International Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "MNY": {
        "symbol": "MNY",
        "name": "MoneyHero Limited Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "MNYWW": {
        "symbol": "MNYWW",
        "name": "MoneyHero Limited Warrants",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "MOB": {
        "symbol": "MOB",
        "name": "Mobilicom Limited American Depositary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "MOBX": {
        "symbol": "MOBX",
        "name": "Mobix Labs Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MODD": {
        "symbol": "MODD",
        "name": "Modular Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "MODV": {
        "symbol": "MODV",
        "name": "ModivCare Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "MOFG": {
        "symbol": "MOFG",
        "name": "MidWestOne Financial Gp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MOGO": {
        "symbol": "MOGO",
        "name": "Mogo Inc. Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "MOLN": {
        "symbol": "MOLN",
        "name": "Molecular Partners AG American Depositary Shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "MOMO": {
        "symbol": "MOMO",
        "name": "Hello Group Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MOND": {
        "symbol": "MOND",
        "name": "Mondee Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "MORN": {
        "symbol": "MORN",
        "name": "Morningstar Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "MOVE": {
        "symbol": "MOVE",
        "name": "Movano Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "MPAA": {
        "symbol": "MPAA",
        "name": "Motorcar Parts  of America Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "MPB": {
        "symbol": "MPB",
        "name": "Mid Penn Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MPWR": {
        "symbol": "MPWR",
        "name": "Monolithic Power Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MQ": {
        "symbol": "MQ",
        "name": "Marqeta Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MRAM": {
        "symbol": "MRAM",
        "name": "Everspin Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MRBK": {
        "symbol": "MRBK",
        "name": "Meridian Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MRCC": {
        "symbol": "MRCC",
        "name": "Monroe Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "MRCY": {
        "symbol": "MRCY",
        "name": "Mercury Systems Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "MREO": {
        "symbol": "MREO",
        "name": "Mereo BioPharma Group plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRIN": {
        "symbol": "MRIN",
        "name": "Marin Software Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MRKR": {
        "symbol": "MRKR",
        "name": "Marker Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRM": {
        "symbol": "MRM",
        "name": "MEDIROM Healthcare Technologies Inc. American Depositary Share",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "MRNA": {
        "symbol": "MRNA",
        "name": "Moderna Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "MRNO": {
        "symbol": "MRNO",
        "name": "Murano Global Investments PLC Ordinary Shares",
        "country": "Mexico",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "MRNOW": {
        "symbol": "MRNOW",
        "name": "Murano Global Investments PLC Warrants",
        "country": "Mexico",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "MRNS": {
        "symbol": "MRNS",
        "name": "Marinus Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRSN": {
        "symbol": "MRSN",
        "name": "Mersana Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRTN": {
        "symbol": "MRTN",
        "name": "Marten Transport Ltd. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "MRUS": {
        "symbol": "MRUS",
        "name": "Merus N.V. Common Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRVI": {
        "symbol": "MRVI",
        "name": "Maravai LifeSciences Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MRVL": {
        "symbol": "MRVL",
        "name": "Marvell Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MRX": {
        "symbol": "MRX",
        "name": "Marex Group plc Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "MSAI": {
        "symbol": "MSAI",
        "name": "MultiSensor AI Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "MSAIW": {
        "symbol": "MSAIW",
        "name": "MultiSensor AI Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "MSBI": {
        "symbol": "MSBI",
        "name": "Midland States Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MSBIP": {
        "symbol": "MSBIP",
        "name": "Midland States Bancorp Inc. Depositary Shares Each Representing a 1/40th Interest in a Share of 7.750% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MSEX": {
        "symbol": "MSEX",
        "name": "Middlesex Water Company Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "MSFT": {
        "symbol": "MSFT",
        "name": "Microsoft Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MSGM": {
        "symbol": "MSGM",
        "name": "Motorsport Games Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MSS": {
        "symbol": "MSS",
        "name": "Maison Solutions Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "MSSA": {
        "symbol": "MSSA",
        "name": "Metal Sky Star Acquisition Corporation Ordinary shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MSSAU": {
        "symbol": "MSSAU",
        "name": "Metal Sky Star Acquisition Corporation Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MSSAW": {
        "symbol": "MSSAW",
        "name": "Metal Sky Star Acquisition Corporation Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "MSTR": {
        "symbol": "MSTR",
        "name": "MicroStrategy Incorporated Common Stock Class A",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MTC": {
        "symbol": "MTC",
        "name": "MMTec Inc. Common Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MTCH": {
        "symbol": "MTCH",
        "name": "Match Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "MTEK": {
        "symbol": "MTEK",
        "name": "Maris-Tech Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "MTEKW": {
        "symbol": "MTEKW",
        "name": "Maris-Tech Ltd. Warrants",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "MTEM": {
        "symbol": "MTEM",
        "name": "Molecular Templates Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MTEN": {
        "symbol": "MTEN",
        "name": "Mingteng International Corporation Inc. Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Building Products"
    },
    "MTEX": {
        "symbol": "MTEX",
        "name": "Mannatech Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "MTLS": {
        "symbol": "MTLS",
        "name": "Materialise NV American Depositary Shares",
        "country": "Belgium",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MTRX": {
        "symbol": "MTRX",
        "name": "Matrix Service Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "MTSI": {
        "symbol": "MTSI",
        "name": "MACOM Technology Solutions Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MTTR": {
        "symbol": "MTTR",
        "name": "Matterport Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MU": {
        "symbol": "MU",
        "name": "Micron Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MULN": {
        "symbol": "MULN",
        "name": "Mullen Automotive Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "MURA": {
        "symbol": "MURA",
        "name": "Mural Oncology plc Ordinary Shares ",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MVBF": {
        "symbol": "MVBF",
        "name": "MVB Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MVIS": {
        "symbol": "MVIS",
        "name": "MicroVision Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "MVST": {
        "symbol": "MVST",
        "name": "Microvast Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "MVSTW": {
        "symbol": "MVSTW",
        "name": "Microvast Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "MXCT": {
        "symbol": "MXCT",
        "name": "MaxCyte Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "MXL": {
        "symbol": "MXL",
        "name": "MaxLinear Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "MYFW": {
        "symbol": "MYFW",
        "name": "First Western Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "MYGN": {
        "symbol": "MYGN",
        "name": "Myriad Genetics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "MYNA": {
        "symbol": "MYNA",
        "name": "Mynaric AG American Depository Shares",
        "country": "Germany",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "MYNZ": {
        "symbol": "MYNZ",
        "name": "Mainz Biomed N.V. Ordinary Shares",
        "country": "Germany",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "MYPS": {
        "symbol": "MYPS",
        "name": "PLAYSTUDIOS Inc.  Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "MYRG": {
        "symbol": "MYRG",
        "name": "MYR Group Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Water Sewer Pipeline Comm & Power Line Construction"
    },
    "MYSZ": {
        "symbol": "MYSZ",
        "name": "My Size Inc. Common Stock",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NA": {
        "symbol": "NA",
        "name": "Nano Labs Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "NAAS": {
        "symbol": "NAAS",
        "name": "NaaS Technology Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "NAII": {
        "symbol": "NAII",
        "name": "Natural Alternatives International Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "NAMS": {
        "symbol": "NAMS",
        "name": "NewAmsterdam Pharma Company N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NAMSW": {
        "symbol": "NAMSW",
        "name": "NewAmsterdam Pharma Company N.V. Warrant",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NAOV": {
        "symbol": "NAOV",
        "name": "NanoVibronix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "NARI": {
        "symbol": "NARI",
        "name": "Inari Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NATH": {
        "symbol": "NATH",
        "name": "Nathan's Famous Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "NATR": {
        "symbol": "NATR",
        "name": "Nature's Sunshine Products Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NAUT": {
        "symbol": "NAUT",
        "name": "Nautilus Biotechnolgy Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "NAVI": {
        "symbol": "NAVI",
        "name": "Navient Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "NB": {
        "symbol": "NB",
        "name": "NioCorp Developments Ltd. Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "NBBK": {
        "symbol": "NBBK",
        "name": "NB Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "NBIX": {
        "symbol": "NBIX",
        "name": "Neurocrine Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "NBN": {
        "symbol": "NBN",
        "name": "Northeast Bank Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NBTB": {
        "symbol": "NBTB",
        "name": "NBT Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NBTX": {
        "symbol": "NBTX",
        "name": "Nanobiotix S.A. American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NCI": {
        "symbol": "NCI",
        "name": "Neo-Concept International Group Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "NCMI": {
        "symbol": "NCMI",
        "name": "National CineMedia Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "NCNA": {
        "symbol": "NCNA",
        "name": "NuCana plc American Depositary Share",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NCNC": {
        "symbol": "NCNC",
        "name": "noco-noco Inc. Ordinary Share",
        "country": "Singapore",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "NCNO": {
        "symbol": "NCNO",
        "name": "nCino Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NCPL": {
        "symbol": "NCPL",
        "name": "Netcapital Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "NCRA": {
        "symbol": "NCRA",
        "name": "Nocera Inc. Common Stock",
        "country": "Taiwan",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "NCSM": {
        "symbol": "NCSM",
        "name": "NCS Multistage Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oilfield Services/Equipment"
    },
    "NCTY": {
        "symbol": "NCTY",
        "name": "The9 Limited American Depository Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "NDAQ": {
        "symbol": "NDAQ",
        "name": "Nasdaq Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "NDLS": {
        "symbol": "NDLS",
        "name": "Noodles & Company Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "NDRA": {
        "symbol": "NDRA",
        "name": "ENDRA Life Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "NDSN": {
        "symbol": "NDSN",
        "name": "Nordson Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NECB": {
        "symbol": "NECB",
        "name": "NorthEast Community Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "NEGG": {
        "symbol": "NEGG",
        "name": "Newegg Commerce Inc. Common Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "NEO": {
        "symbol": "NEO",
        "name": "NeoGenomics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Precision Instruments"
    },
    "NEOG": {
        "symbol": "NEOG",
        "name": "Neogen Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "NEON": {
        "symbol": "NEON",
        "name": "Neonode Inc. Common Stock",
        "country": "Sweden",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "NEOV": {
        "symbol": "NEOV",
        "name": "NeoVolta Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "NEPH": {
        "symbol": "NEPH",
        "name": "Nephros Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NERV": {
        "symbol": "NERV",
        "name": "Minerva Neurosciences Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NETD": {
        "symbol": "NETD",
        "name": "Nabors Energy Transition Corp. II Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "NETDW": {
        "symbol": "NETDW",
        "name": "Nabors Energy Transition Corp. II Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "NEWT": {
        "symbol": "NEWT",
        "name": "NewtekOne Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NEWTG": {
        "symbol": "NEWTG",
        "name": "NewtekOne Inc. 8.50% Fixed Rate Senior Notes due 2029",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "NEWTH": {
        "symbol": "NEWTH",
        "name": "NewtekOne Inc. 8.625% Fixed Rate Senior Notes due 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NEWTI": {
        "symbol": "NEWTI",
        "name": "NewtekOne Inc. 8.00% Fixed Rate Senior Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NEWTZ": {
        "symbol": "NEWTZ",
        "name": "NewtekOne Inc. 5.50% Notes Due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NEXN": {
        "symbol": "NEXN",
        "name": "Nexxen International Ltd. American Depository Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "NEXT": {
        "symbol": "NEXT",
        "name": "NextDecade Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Oil & Gas Production"
    },
    "NFBK": {
        "symbol": "NFBK",
        "name": "Northfield Bancorp Inc. Common Stock (Delaware)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "NFE": {
        "symbol": "NFE",
        "name": "New Fortress Energy Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Oil/Gas Transmission"
    },
    "NFLX": {
        "symbol": "NFLX",
        "name": "Netflix Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Video Chains"
    },
    "NGNE": {
        "symbol": "NGNE",
        "name": "Neurogene Inc. Common Stock ",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NHTC": {
        "symbol": "NHTC",
        "name": "Natural Health Trends Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Specialties"
    },
    "NICE": {
        "symbol": "NICE",
        "name": "NICE Ltd American Depositary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NICK": {
        "symbol": "NICK",
        "name": "Nicholas Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "NIOBW": {
        "symbol": "NIOBW",
        "name": "NioCorp Developments Ltd. Warrant",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "NIPG": {
        "symbol": "NIPG",
        "name": "NIP Group Inc. American Depositary Shares",
        "country": "Sweden",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "NISN": {
        "symbol": "NISN",
        "name": "NiSun International Enterprise Development Group Co. Ltd. Class A Common Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "NITO": {
        "symbol": "NITO",
        "name": "N2OFF Inc. Common Stock",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Agricultural Chemicals"
    },
    "NIU": {
        "symbol": "NIU",
        "name": "Niu Technologies American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "NIVF": {
        "symbol": "NIVF",
        "name": "NewGenIvf Group Limited Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "NIVFW": {
        "symbol": "NIVFW",
        "name": "NewGenIvf Group Limited Warrants",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "NKGN": {
        "symbol": "NKGN",
        "name": "NKGen Biotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "NKGNW": {
        "symbol": "NKGNW",
        "name": "NKGen Biotech Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "NKLA": {
        "symbol": "NKLA",
        "name": "Nikola Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "NKSH": {
        "symbol": "NKSH",
        "name": "National Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NKTR": {
        "symbol": "NKTR",
        "name": "Nektar Therapeutics  Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NKTX": {
        "symbol": "NKTX",
        "name": "Nkarta Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NLSP": {
        "symbol": "NLSP",
        "name": "NLS Pharmaceutics Ltd. Ordinary Shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NLSPW": {
        "symbol": "NLSPW",
        "name": "NLS Pharmaceutics Ltd. Warrant",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NMFC": {
        "symbol": "NMFC",
        "name": "New Mountain Finance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "NMFCZ": {
        "symbol": "NMFCZ",
        "name": "New Mountain Finance Corporation 8.250% Notes due 2028",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "NMHI": {
        "symbol": "NMHI",
        "name": "Nature's Miracle Holding Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NMHIW": {
        "symbol": "NMHIW",
        "name": "Nature's Miracle Holding Inc. Warrants",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NMIH": {
        "symbol": "NMIH",
        "name": "NMI Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "NMRA": {
        "symbol": "NMRA",
        "name": "Neumora Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "NMRK": {
        "symbol": "NMRK",
        "name": "Newmark Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "NMTC": {
        "symbol": "NMTC",
        "name": "NeuroOne Medical Technologies Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NN": {
        "symbol": "NN",
        "name": "NextNav Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NNAVW": {
        "symbol": "NNAVW",
        "name": "NextNav Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NNBR": {
        "symbol": "NNBR",
        "name": "NN Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NNDM": {
        "symbol": "NNDM",
        "name": "Nano Dimension Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "NNE": {
        "symbol": "NNE",
        "name": "Nano Nuclear Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "NNOX": {
        "symbol": "NNOX",
        "name": "NANO-X IMAGING LTD Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical Electronics"
    },
    "NODK": {
        "symbol": "NODK",
        "name": "NI Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "NOTV": {
        "symbol": "NOTV",
        "name": "Inotiv Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "NOVT": {
        "symbol": "NOVT",
        "name": "Novanta Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "NPAB": {
        "symbol": "NPAB",
        "name": "New Providence Acquisition Corp. II Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "NPABU": {
        "symbol": "NPABU",
        "name": "New Providence Acquisition Corp. II Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "NPABW": {
        "symbol": "NPABW",
        "name": "New Providence Acquisition Corp. II Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "NPCE": {
        "symbol": "NPCE",
        "name": "Neuropace Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NRBO": {
        "symbol": "NRBO",
        "name": "NeuroBo Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NRC": {
        "symbol": "NRC",
        "name": "National Research Corporation Common Stock (Delaware)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "NRDS": {
        "symbol": "NRDS",
        "name": "NerdWallet Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "NRIM": {
        "symbol": "NRIM",
        "name": "Northrim BanCorp Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "NRIX": {
        "symbol": "NRIX",
        "name": "Nurix Therapeutics Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NRSN": {
        "symbol": "NRSN",
        "name": "NeuroSense Therapeutics Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NRSNW": {
        "symbol": "NRSNW",
        "name": "NeuroSense Therapeutics Ltd. Warrant",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NRXP": {
        "symbol": "NRXP",
        "name": "NRX Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NRXPW": {
        "symbol": "NRXPW",
        "name": "NRX Pharmaceuticals Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NSIT": {
        "symbol": "NSIT",
        "name": "Insight Enterprises Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "NSPR": {
        "symbol": "NSPR",
        "name": "InspireMD Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NSSC": {
        "symbol": "NSSC",
        "name": "NAPCO Security Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "NSTS": {
        "symbol": "NSTS",
        "name": "NSTS Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "NSYS": {
        "symbol": "NSYS",
        "name": "Nortech Systems Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "NTAP": {
        "symbol": "NTAP",
        "name": "NetApp Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "NTBL": {
        "symbol": "NTBL",
        "name": "Notable Labs Ltd. Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NTCT": {
        "symbol": "NTCT",
        "name": "NetScout Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "NTES": {
        "symbol": "NTES",
        "name": "NetEase Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NTGR": {
        "symbol": "NTGR",
        "name": "NETGEAR Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "NTIC": {
        "symbol": "NTIC",
        "name": "Northern Technologies International Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "NTLA": {
        "symbol": "NTLA",
        "name": "Intellia Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "NTNX": {
        "symbol": "NTNX",
        "name": "Nutanix Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NTRA": {
        "symbol": "NTRA",
        "name": "Natera Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "NTRB": {
        "symbol": "NTRB",
        "name": "Nutriband Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "NTRBW": {
        "symbol": "NTRBW",
        "name": "Nutriband Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "NTRP": {
        "symbol": "NTRP",
        "name": "NextTrip Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "NTRS": {
        "symbol": "NTRS",
        "name": "Northern Trust Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NTRSO": {
        "symbol": "NTRSO",
        "name": "Northern Trust Corporation Depositary Shares Each Representing a 1/1000th Interest in a Share of Series E Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NTWK": {
        "symbol": "NTWK",
        "name": "NetSol Technologies Inc. Common  Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NUKK": {
        "symbol": "NUKK",
        "name": "Nukkleus Inc. Common Stock",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "NUKKW": {
        "symbol": "NUKKW",
        "name": "Nukkleus Inc. Warrants",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "NURO": {
        "symbol": "NURO",
        "name": "NeuroMetrix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NUTX": {
        "symbol": "NUTX",
        "name": "Nutex Health Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "NUVL": {
        "symbol": "NUVL",
        "name": "Nuvalent Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NUWE": {
        "symbol": "NUWE",
        "name": "Nuwellis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "NUZE": {
        "symbol": "NUZE",
        "name": "NuZee Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "NVA": {
        "symbol": "NVA",
        "name": "Nova Minerals Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "NVAC": {
        "symbol": "NVAC",
        "name": "NorthView Acquisition Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NVACR": {
        "symbol": "NVACR",
        "name": "NorthView Acquisition Corporation Rights",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NVAWW": {
        "symbol": "NVAWW",
        "name": "Nova Minerals Limited Warrant",
        "country": "Australia",
        "sector": "",
        "industry": ""
    },
    "NVAX": {
        "symbol": "NVAX",
        "name": "Novavax Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "NVCR": {
        "symbol": "NVCR",
        "name": "NovoCure Limited Ordinary Shares",
        "country": "Jersey",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NVCT": {
        "symbol": "NVCT",
        "name": "Nuvectis Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NVDA": {
        "symbol": "NVDA",
        "name": "NVIDIA Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "NVEC": {
        "symbol": "NVEC",
        "name": "NVE Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "NVEE": {
        "symbol": "NVEE",
        "name": "NV5 Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "NVEI": {
        "symbol": "NVEI",
        "name": "Nuvei Corporation Subordinate Voting Shares",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "NVFY": {
        "symbol": "NVFY",
        "name": "Nova Lifestyle Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "NVMI": {
        "symbol": "NVMI",
        "name": "Nova Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "NVNI": {
        "symbol": "NVNI",
        "name": "Nvni Group Limited Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NVNIW": {
        "symbol": "NVNIW",
        "name": "Nvni Group Limited Warrants",
        "country": "Cayman Islands",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NVNO": {
        "symbol": "NVNO",
        "name": "enVVeno Medical Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NVOS": {
        "symbol": "NVOS",
        "name": "Novo Integrated Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NVTS": {
        "symbol": "NVTS",
        "name": "Navitas Semiconductor Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "NVVE": {
        "symbol": "NVVE",
        "name": "Nuvve Holding Corp. Common Stock",
        "country": "China",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "NVVEW": {
        "symbol": "NVVEW",
        "name": "Nuvve Holding Corp. Warrant",
        "country": "China",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "NVX": {
        "symbol": "NVX",
        "name": "NOVONIX Limited American Depository Shares",
        "country": "Australia",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "NWBI": {
        "symbol": "NWBI",
        "name": "Northwest Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NWE": {
        "symbol": "NWE",
        "name": "NorthWestern Energy Group Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "NWFL": {
        "symbol": "NWFL",
        "name": "Norwood Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "NWGL": {
        "symbol": "NWGL",
        "name": "Nature Wood Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Energy",
        "industry": ""
    },
    "NWL": {
        "symbol": "NWL",
        "name": "Newell Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "NWPX": {
        "symbol": "NWPX",
        "name": "Northwest Pipe Company Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "NWS": {
        "symbol": "NWS",
        "name": "News Corporation Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "NWSA": {
        "symbol": "NWSA",
        "name": "News Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "NWTN": {
        "symbol": "NWTN",
        "name": "NWTN Inc. Class B Ordinary Shares",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "NWTNW": {
        "symbol": "NWTNW",
        "name": "NWTN Inc. Warrant",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "NXGL": {
        "symbol": "NXGL",
        "name": "NexGel Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NXGLW": {
        "symbol": "NXGLW",
        "name": "NexGel Inc Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "NXL": {
        "symbol": "NXL",
        "name": "Nexalin Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "NXPI": {
        "symbol": "NXPI",
        "name": "NXP Semiconductors N.V. Common Stock",
        "country": "Netherlands",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "NXPL": {
        "symbol": "NXPL",
        "name": "NextPlat Corp Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "NXPLW": {
        "symbol": "NXPLW",
        "name": "NextPlat Corp Warrants",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "NXST": {
        "symbol": "NXST",
        "name": "Nexstar Media Group Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "NXT": {
        "symbol": "NXT",
        "name": "Nextracker Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "NXTC": {
        "symbol": "NXTC",
        "name": "NextCure Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "NXTT": {
        "symbol": "NXTT",
        "name": "Next Technology Holding Inc. Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "NXU": {
        "symbol": "NXU",
        "name": "Nxu Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "NYAX": {
        "symbol": "NYAX",
        "name": "Nayax Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "NYMT": {
        "symbol": "NYMT",
        "name": "New York Mortgage Trust Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYMTI": {
        "symbol": "NYMTI",
        "name": "New York Mortgage Trust Inc. 9.125% Senior Notes Due 2029",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYMTL": {
        "symbol": "NYMTL",
        "name": "New York Mortgage Trust Inc. 6.875% Series F Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock $0.01 par value per share",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYMTM": {
        "symbol": "NYMTM",
        "name": "New York Mortgage Trust Inc. 7.875% Series E Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYMTN": {
        "symbol": "NYMTN",
        "name": "New York Mortgage Trust Inc. 8.00% Series D Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYMTZ": {
        "symbol": "NYMTZ",
        "name": "New York Mortgage Trust Inc. 7.000% Series G Cumulative Redeemable Preferred Stock $0.01 par value per share",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "NYXH": {
        "symbol": "NYXH",
        "name": "Nyxoah SA Ordinary Shares",
        "country": "Belgium",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "OABI": {
        "symbol": "OABI",
        "name": "OmniAb Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "OABIW": {
        "symbol": "OABIW",
        "name": "OmniAb Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "OAKU": {
        "symbol": "OAKU",
        "name": "Oak Woods Acquisition Corporation Class A Ordinary Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "OAKUR": {
        "symbol": "OAKUR",
        "name": "Oak Woods Acquisition Corporation Right",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "OB": {
        "symbol": "OB",
        "name": "Outbrain Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "OBIO": {
        "symbol": "OBIO",
        "name": "Orchestra BioMed Holdings Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "OBLG": {
        "symbol": "OBLG",
        "name": "Oblong Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "OBT": {
        "symbol": "OBT",
        "name": "Orange County Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OCC": {
        "symbol": "OCC",
        "name": "Optical Cable Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Telecommunications Equipment"
    },
    "OCCI": {
        "symbol": "OCCI",
        "name": "OFS Credit Company Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "OCCIN": {
        "symbol": "OCCIN",
        "name": "OFS Credit Company Inc. 5.25% Series E Term Preferred Stock Due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "OCCIO": {
        "symbol": "OCCIO",
        "name": "OFS Credit Company Inc. 6.125% Series C Term Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Trusts Except Educational Religious and Charitable"
    },
    "OCEA": {
        "symbol": "OCEA",
        "name": "Ocean Biomedical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCEAW": {
        "symbol": "OCEAW",
        "name": "Ocean Biomediacal Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCFC": {
        "symbol": "OCFC",
        "name": "OceanFirst Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OCFCP": {
        "symbol": "OCFCP",
        "name": "OceanFirst Financial Corp. Depositary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OCG": {
        "symbol": "OCG",
        "name": "Oriental Culture Holding LTD Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "OCGN": {
        "symbol": "OCGN",
        "name": "Ocugen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "OCS": {
        "symbol": "OCS",
        "name": "Oculis Holding AG Ordinary shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCSAW": {
        "symbol": "OCSAW",
        "name": "Oculis Holding AG Warrants",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCSL": {
        "symbol": "OCSL",
        "name": "Oaktree Specialty Lending Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "OCTO": {
        "symbol": "OCTO",
        "name": "Eightco Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "OCUL": {
        "symbol": "OCUL",
        "name": "Ocular Therapeutix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCUP": {
        "symbol": "OCUP",
        "name": "Ocuphire Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OCX": {
        "symbol": "OCX",
        "name": "Oncocyte Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "ODD": {
        "symbol": "ODD",
        "name": "ODDITY Tech Ltd. Class A Ordinary Shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "ODFL": {
        "symbol": "ODFL",
        "name": "Old Dominion Freight Line Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "ODP": {
        "symbol": "ODP",
        "name": "The ODP Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "ODVWZ": {
        "symbol": "ODVWZ",
        "name": "Osisko Development Corp. Warrant expiring 5/27/2027",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "OESX": {
        "symbol": "OESX",
        "name": "Orion Energy Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Building Products"
    },
    "OFIX": {
        "symbol": "OFIX",
        "name": "Orthofix Medical Inc. Common Stock (DE)",
        "country": "Curacao",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "OFLX": {
        "symbol": "OFLX",
        "name": "Omega Flex Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "OFS": {
        "symbol": "OFS",
        "name": "OFS Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "OFSSH": {
        "symbol": "OFSSH",
        "name": "OFS Capital Corporation 4.95% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "OGI": {
        "symbol": "OGI",
        "name": "Organigram Holdings Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OKTA": {
        "symbol": "OKTA",
        "name": "Okta Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "OKYO": {
        "symbol": "OKYO",
        "name": "OKYO Pharma Limited Ordinary Shares",
        "country": "Guernsey",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "OLB": {
        "symbol": "OLB",
        "name": "The OLB Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "OLED": {
        "symbol": "OLED",
        "name": "Universal Display Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "OLLI": {
        "symbol": "OLLI",
        "name": "Ollie's Bargain Outlet Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Department/Specialty Retail Stores"
    },
    "OLMA": {
        "symbol": "OLMA",
        "name": "Olema Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OLPX": {
        "symbol": "OLPX",
        "name": "Olaplex Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "OM": {
        "symbol": "OM",
        "name": "Outset Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "OMAB": {
        "symbol": "OMAB",
        "name": "Grupo Aeroportuario del Centro Norte S.A.B. de C.V. ADS",
        "country": "Mexico",
        "sector": "Consumer Discretionary",
        "industry": "Aerospace"
    },
    "OMCL": {
        "symbol": "OMCL",
        "name": "Omnicell Inc. Common Stock ($0.001 par value)",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Manufacturing"
    },
    "OMER": {
        "symbol": "OMER",
        "name": "Omeros Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OMEX": {
        "symbol": "OMEX",
        "name": "Odyssey Marine Exploration Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "OMGA": {
        "symbol": "OMGA",
        "name": "Omega Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "OMH": {
        "symbol": "OMH",
        "name": "Ohmyhome Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "OMIC": {
        "symbol": "OMIC",
        "name": "Singular Genomics Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "ON": {
        "symbol": "ON",
        "name": "ON Semiconductor Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "ONB": {
        "symbol": "ONB",
        "name": "Old National Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ONBPO": {
        "symbol": "ONBPO",
        "name": "Old National Bancorp Depositary Shares Each Representing a 1/40th Interest in a Share of Series C Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ONBPP": {
        "symbol": "ONBPP",
        "name": "Old National Bancorp Depositary Shares Each Representing a 1/40th Interest in a Share of Series A Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ONCO": {
        "symbol": "ONCO",
        "name": "Onconetix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ONCT": {
        "symbol": "ONCT",
        "name": "Oncternal Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ONCY": {
        "symbol": "ONCY",
        "name": "Oncolytics Biotech Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ONDS": {
        "symbol": "ONDS",
        "name": "Ondas Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "ONEW": {
        "symbol": "ONEW",
        "name": "OneWater Marine Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto & Home Supply Stores"
    },
    "ONFO": {
        "symbol": "ONFO",
        "name": "Onfolio Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ONFOW": {
        "symbol": "ONFOW",
        "name": "Onfolio Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ONMD": {
        "symbol": "ONMD",
        "name": "OneMedNet Corp Class A Common Stock",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Commercial Physical & Biological Resarch"
    },
    "ONVO": {
        "symbol": "ONVO",
        "name": "Organovo Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ONYX": {
        "symbol": "ONYX",
        "name": "Onyx Acquisition Co. I Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "OP": {
        "symbol": "OP",
        "name": "OceanPal Inc. Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "OPAL": {
        "symbol": "OPAL",
        "name": "OPAL Fuels Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Natural Gas Distribution"
    },
    "OPBK": {
        "symbol": "OPBK",
        "name": "OP Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OPCH": {
        "symbol": "OPCH",
        "name": "Option Care Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "OPEN": {
        "symbol": "OPEN",
        "name": "Opendoor Technologies Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "OPHC": {
        "symbol": "OPHC",
        "name": "OptimumBank Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OPI": {
        "symbol": "OPI",
        "name": "Office Properties Income Trust Common Shares of Beneficial Interest",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "OPINL": {
        "symbol": "OPINL",
        "name": "Office Properties Income Trust 6.375% Senior Notes due 2050",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "OPK": {
        "symbol": "OPK",
        "name": "OPKO Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OPOF": {
        "symbol": "OPOF",
        "name": "Old Point Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OPRA": {
        "symbol": "OPRA",
        "name": "Opera Limited American Depositary Shares",
        "country": "Norway",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "OPRT": {
        "symbol": "OPRT",
        "name": "Oportun Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "OPRX": {
        "symbol": "OPRX",
        "name": "OptimizeRx Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "OPT": {
        "symbol": "OPT",
        "name": "Opthea Limited American Depositary Shares",
        "country": "Australia",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "OPTN": {
        "symbol": "OPTN",
        "name": "OptiNose Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OPTX": {
        "symbol": "OPTX",
        "name": "Syntec Optics Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "OPTXW": {
        "symbol": "OPTXW",
        "name": "Syntec Optics Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "OPXS": {
        "symbol": "OPXS",
        "name": "Optex Systems Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "ORGN": {
        "symbol": "ORGN",
        "name": "Origin Materials Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ORGNW": {
        "symbol": "ORGNW",
        "name": "Origin Materials Inc. Warrants",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ORGO": {
        "symbol": "ORGO",
        "name": "Organogenesis Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ORGS": {
        "symbol": "ORGS",
        "name": "Orgenesis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ORIC": {
        "symbol": "ORIC",
        "name": "Oric Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ORKA": {
        "symbol": "ORKA",
        "name": "Oruka Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "ORKT": {
        "symbol": "ORKT",
        "name": "Orangekloud Technology Inc. Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ORLY": {
        "symbol": "ORLY",
        "name": "O'Reilly Automotive Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto & Home Supply Stores"
    },
    "ORMP": {
        "symbol": "ORMP",
        "name": "Oramed Pharmaceuticals Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ORRF": {
        "symbol": "ORRF",
        "name": "Orrstown Financial Services Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OS": {
        "symbol": "OS",
        "name": "OneStream Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "OSBC": {
        "symbol": "OSBC",
        "name": "Old Second Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OSIS": {
        "symbol": "OSIS",
        "name": "OSI Systems Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "OSPN": {
        "symbol": "OSPN",
        "name": "OneSpan Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "OSS": {
        "symbol": "OSS",
        "name": "One Stop Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Manufacturing"
    },
    "OST": {
        "symbol": "OST",
        "name": "Ostin Technology Group Co. Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "OSUR": {
        "symbol": "OSUR",
        "name": "OraSure Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "OSW": {
        "symbol": "OSW",
        "name": "OneSpaWorld Holdings Limited Common Shares",
        "country": "Bahamas",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "OTEX": {
        "symbol": "OTEX",
        "name": "Open Text Corporation Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "OTLK": {
        "symbol": "OTLK",
        "name": "Outlook Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "OTLY": {
        "symbol": "OTLY",
        "name": "Oatly Group AB American Depositary Shares",
        "country": "Sweden",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "OTRK": {
        "symbol": "OTRK",
        "name": "Ontrak Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "OTTR": {
        "symbol": "OTTR",
        "name": "Otter Tail Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "OVBC": {
        "symbol": "OVBC",
        "name": "Ohio Valley Banc Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OVID": {
        "symbol": "OVID",
        "name": "Ovid Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "OVLY": {
        "symbol": "OVLY",
        "name": "Oak Valley Bancorp (CA) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OXBR": {
        "symbol": "OXBR",
        "name": "Oxbridge Re Holdings Limited Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "OXLC": {
        "symbol": "OXLC",
        "name": "Oxford Lane Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "OXLCI": {
        "symbol": "OXLCI",
        "name": "Oxford Lane Capital Corp. 8.75% Notes due 2030",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "OXLCL": {
        "symbol": "OXLCL",
        "name": "Oxford Lane Capital Corp. 6.75% Notes due 2031",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "OXLCN": {
        "symbol": "OXLCN",
        "name": "Oxford Lane Capital Corp. 7.125% Series 2029 Term Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "OXLCO": {
        "symbol": "OXLCO",
        "name": "Oxford Lane Capital Corp. Preferred Stock Shares 6.00% Series 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "OXLCP": {
        "symbol": "OXLCP",
        "name": "Oxford Lane Capital Corp. 6.25% Series 2027 Term Preferred Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "OXLCZ": {
        "symbol": "OXLCZ",
        "name": "Oxford Lane Capital Corp. 5.00% Notes due 2027",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "OXSQ": {
        "symbol": "OXSQ",
        "name": "Oxford Square Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "OXSQG": {
        "symbol": "OXSQG",
        "name": "Oxford Square Capital Corp. 5.50% Notes due 2028",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "OXSQZ": {
        "symbol": "OXSQZ",
        "name": "Oxford Square Capital Corp. 6.25% Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "OZK": {
        "symbol": "OZK",
        "name": "Bank OZK Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "OZKAP": {
        "symbol": "OZKAP",
        "name": "Bank OZK 4.625% Series A Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PAA": {
        "symbol": "PAA",
        "name": "Plains All American Pipeline L.P. Common Units representing Limited Partner Interests",
        "country": "United States",
        "sector": "Energy",
        "industry": "Natural Gas Distribution"
    },
    "PACB": {
        "symbol": "PACB",
        "name": "Pacific Biosciences of California Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "PAGP": {
        "symbol": "PAGP",
        "name": "Plains GP Holdings L.P. Class A Units representing Limited Partner Interests",
        "country": "United States",
        "sector": "Energy",
        "industry": "Natural Gas Distribution"
    },
    "PAHC": {
        "symbol": "PAHC",
        "name": "Phibro Animal Health Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PAL": {
        "symbol": "PAL",
        "name": "Proficient Auto Logistics Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "PALI": {
        "symbol": "PALI",
        "name": "Palisade Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PALT": {
        "symbol": "PALT",
        "name": "Paltalk Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PANL": {
        "symbol": "PANL",
        "name": "Pangaea Logistics Solutions Ltd. Common Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "PANW": {
        "symbol": "PANW",
        "name": "Palo Alto Networks Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "PARA": {
        "symbol": "PARA",
        "name": "Paramount Global Class B Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "PARAA": {
        "symbol": "PARAA",
        "name": "Paramount Global Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "PASG": {
        "symbol": "PASG",
        "name": "Passage Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PATK": {
        "symbol": "PATK",
        "name": "Patrick Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "PAVM": {
        "symbol": "PAVM",
        "name": "PAVmed Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PAVMZ": {
        "symbol": "PAVMZ",
        "name": "PAVmed Inc. Series Z Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PAVS": {
        "symbol": "PAVS",
        "name": "Paranovus Entertainment Technology Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "PAX": {
        "symbol": "PAX",
        "name": "Patria Investments Limited Class A Common Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "PAYO": {
        "symbol": "PAYO",
        "name": "Payoneer Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PAYS": {
        "symbol": "PAYS",
        "name": "Paysign Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PAYX": {
        "symbol": "PAYX",
        "name": "Paychex Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "PBBK": {
        "symbol": "PBBK",
        "name": "PB Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "PBFS": {
        "symbol": "PBFS",
        "name": "Pioneer Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "PBHC": {
        "symbol": "PBHC",
        "name": "Pathfinder Bancorp Inc. Common Stock (MD)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PBM": {
        "symbol": "PBM",
        "name": "Psyence Biomedical Ltd. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PBMWW": {
        "symbol": "PBMWW",
        "name": "Psyence Biomedical Ltd. Warrant",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PBPB": {
        "symbol": "PBPB",
        "name": "Potbelly Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "PBYI": {
        "symbol": "PBYI",
        "name": "Puma Biotechnology Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PC": {
        "symbol": "PC",
        "name": "Premium Catering (Holdings) Limited Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "PCAR": {
        "symbol": "PCAR",
        "name": "PACCAR Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "PCB": {
        "symbol": "PCB",
        "name": "PCB Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PCH": {
        "symbol": "PCH",
        "name": "PotlatchDeltic Corporation Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "PCRX": {
        "symbol": "PCRX",
        "name": "Pacira BioSciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PCSA": {
        "symbol": "PCSA",
        "name": "Processa Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PCSC": {
        "symbol": "PCSC",
        "name": "Perceptive Capital Solutions Corp Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "PCT": {
        "symbol": "PCT",
        "name": "PureCycle Technologies Inc. Common stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "PCTTU": {
        "symbol": "PCTTU",
        "name": "PureCycle Technologies Inc. Unit",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "PCTTW": {
        "symbol": "PCTTW",
        "name": "PureCycle Technologies Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "PCTY": {
        "symbol": "PCTY",
        "name": "Paylocity Holding Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PCVX": {
        "symbol": "PCVX",
        "name": "Vaxcyte Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PCYO": {
        "symbol": "PCYO",
        "name": "Pure Cycle Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "PDCO": {
        "symbol": "PDCO",
        "name": "Patterson Companies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "PDD": {
        "symbol": "PDD",
        "name": "PDD Holdings Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PDEX": {
        "symbol": "PDEX",
        "name": "Pro-Dex Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PDFS": {
        "symbol": "PDFS",
        "name": "PDF Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PDLB": {
        "symbol": "PDLB",
        "name": "Ponce Financial Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "PDSB": {
        "symbol": "PDSB",
        "name": "PDS Biotechnology Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PDYN": {
        "symbol": "PDYN",
        "name": "Palladyne AI Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PDYNW": {
        "symbol": "PDYNW",
        "name": "Palladyne AI Corp Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PEBK": {
        "symbol": "PEBK",
        "name": "Peoples Bancorp of North Carolina Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PEBO": {
        "symbol": "PEBO",
        "name": "Peoples Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PECO": {
        "symbol": "PECO",
        "name": "Phillips Edison & Company Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "PEGA": {
        "symbol": "PEGA",
        "name": "Pegasystems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "PEGY": {
        "symbol": "PEGY",
        "name": "Pineapple Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "PENN": {
        "symbol": "PENN",
        "name": "PENN Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "PEP": {
        "symbol": "PEP",
        "name": "PepsiCo Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "PEPG": {
        "symbol": "PEPG",
        "name": "PepGen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PERI": {
        "symbol": "PERI",
        "name": "Perion Network Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "PESI": {
        "symbol": "PESI",
        "name": "Perma-Fix Environmental Services Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Environmental Services"
    },
    "PET": {
        "symbol": "PET",
        "name": "Wag! Group Co. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "PETQ": {
        "symbol": "PETQ",
        "name": "PetIQ Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Other Pharmaceuticals"
    },
    "PETS": {
        "symbol": "PETS",
        "name": "PetMed Express Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Retail-Drug Stores and Proprietary Stores"
    },
    "PETWW": {
        "symbol": "PETWW",
        "name": "Wag! Group Co Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "PETZ": {
        "symbol": "PETZ",
        "name": "TDH Holdings Inc. Common Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "PEV": {
        "symbol": "PEV",
        "name": "Phoenix Motor Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Construction/Ag Equipment/Trucks"
    },
    "PFBC": {
        "symbol": "PFBC",
        "name": "Preferred Bank Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PFC": {
        "symbol": "PFC",
        "name": "Premier Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PFG": {
        "symbol": "PFG",
        "name": "Principal Financial Group Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Accident &Health Insurance"
    },
    "PFIE": {
        "symbol": "PFIE",
        "name": "Profire Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "PFIS": {
        "symbol": "PFIS",
        "name": "Peoples Financial Services Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PFMT": {
        "symbol": "PFMT",
        "name": "Performant Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "PFTA": {
        "symbol": "PFTA",
        "name": "Perception Capital Corp. III Class A Ordinary Share",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PFTAU": {
        "symbol": "PFTAU",
        "name": "Perception Capital Corp. III Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PFTAW": {
        "symbol": "PFTAW",
        "name": "Perception Capital Corp. III Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PFX": {
        "symbol": "PFX",
        "name": "PhenixFIN Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PFXNZ": {
        "symbol": "PFXNZ",
        "name": "PhenixFIN Corporation  5.25% Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PGC": {
        "symbol": "PGC",
        "name": "Peapack-Gladstone Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "PGEN": {
        "symbol": "PGEN",
        "name": "Precigen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PGHL": {
        "symbol": "PGHL",
        "name": "Primega Group Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "General Bldg Contractors - Nonresidential Bldgs"
    },
    "PGNY": {
        "symbol": "PGNY",
        "name": "Progyny Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "PGY": {
        "symbol": "PGY",
        "name": "Pagaya Technologies Ltd. Class A Ordinary Shares",
        "country": "Israel",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PGYWW": {
        "symbol": "PGYWW",
        "name": "Pagaya Technologies Ltd. Warrants",
        "country": "Israel",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PHAR": {
        "symbol": "PHAR",
        "name": "Pharming Group N.V. ADS each representing 10 ordinary shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PHAT": {
        "symbol": "PHAT",
        "name": "Phathom Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PHIO": {
        "symbol": "PHIO",
        "name": "Phio Pharmaceuticals Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PHUN": {
        "symbol": "PHUN",
        "name": "Phunware Inc. Common Stock",
        "country": "Greece",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "PHVS": {
        "symbol": "PHVS",
        "name": "Pharvaris N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PI": {
        "symbol": "PI",
        "name": "Impinj Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "PIII": {
        "symbol": "PIII",
        "name": "P3 Health Partners Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "PIIIW": {
        "symbol": "PIIIW",
        "name": "P3 Health Partners Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "PIK": {
        "symbol": "PIK",
        "name": "Kidpik Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "PINC": {
        "symbol": "PINC",
        "name": "Premier Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "PIRS": {
        "symbol": "PIRS",
        "name": "Pieris Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PITA": {
        "symbol": "PITA",
        "name": "Heramba Electric plc Ordinary Shares",
        "country": "Germany",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "PITAW": {
        "symbol": "PITAW",
        "name": "Heramba Electric plc Warrants",
        "country": "Germany",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "PIXY": {
        "symbol": "PIXY",
        "name": "ShiftPixy Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "PKBK": {
        "symbol": "PKBK",
        "name": "Parke Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PKOH": {
        "symbol": "PKOH",
        "name": "Park-Ohio Holdings Corp. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "PLAB": {
        "symbol": "PLAB",
        "name": "Photronics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "PLAO": {
        "symbol": "PLAO",
        "name": "Patria Latin American Opportunity Acquisition Corp. Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PLAOW": {
        "symbol": "PLAOW",
        "name": "Patria Latin American Opportunity Acquisition Corp. Warrant",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PLAY": {
        "symbol": "PLAY",
        "name": "Dave & Buster's Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "PLBC": {
        "symbol": "PLBC",
        "name": "Plumas Bancorp",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "PLBY": {
        "symbol": "PLBY",
        "name": "PLBY Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "PLCE": {
        "symbol": "PLCE",
        "name": "Children's Place Inc. (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "PLL": {
        "symbol": "PLL",
        "name": "Piedmont Lithium Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "PLMJ": {
        "symbol": "PLMJ",
        "name": "Plum Acquisition Corp. III Class A Ordinary Shares",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "PLMR": {
        "symbol": "PLMR",
        "name": "Palomar Holdings Inc. Common stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "PLPC": {
        "symbol": "PLPC",
        "name": "Preformed Line Products Company Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Water Sewer Pipeline Comm & Power Line Construction"
    },
    "PLRX": {
        "symbol": "PLRX",
        "name": "Pliant Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PLSE": {
        "symbol": "PLSE",
        "name": "Pulse Biosciences Inc Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PLTK": {
        "symbol": "PLTK",
        "name": "Playtika Holding Corp. Common Stock",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "PLUG": {
        "symbol": "PLUG",
        "name": "Plug Power Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "PLUR": {
        "symbol": "PLUR",
        "name": "Pluri Inc. Common Stock",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PLUS": {
        "symbol": "PLUS",
        "name": "ePlus inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Retail: Computer Software & Peripheral Equipment"
    },
    "PLXS": {
        "symbol": "PLXS",
        "name": "Plexus Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "PLYA": {
        "symbol": "PLYA",
        "name": "Playa Hotels & Resorts N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "PMAX": {
        "symbol": "PMAX",
        "name": "Powell Max Limited Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "PMCB": {
        "symbol": "PMCB",
        "name": "PharmaCyte  Biotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PMD": {
        "symbol": "PMD",
        "name": "Psychemedics Corporation",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "PMEC": {
        "symbol": "PMEC",
        "name": "Primech Holdings Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Diversified Commercial Services"
    },
    "PMN": {
        "symbol": "PMN",
        "name": "ProMIS Neurosciences Inc. Common Shares (ON)",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PMTS": {
        "symbol": "PMTS",
        "name": "CPI Card Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Publishing"
    },
    "PMVP": {
        "symbol": "PMVP",
        "name": "PMV Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PNBK": {
        "symbol": "PNBK",
        "name": "Patriot National Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PNFP": {
        "symbol": "PNFP",
        "name": "Pinnacle Financial Partners Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PNFPP": {
        "symbol": "PNFPP",
        "name": "Pinnacle Financial Partners Inc. Depositary shares of Pinnacle Financial Partners Inc. each representing a 1/40th Interest in a share of its 6.75% Fixed-Rate Non-Cumulative Perpetual Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PNRG": {
        "symbol": "PNRG",
        "name": "PrimeEnergy Resources Corporation Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "PNTG": {
        "symbol": "PNTG",
        "name": "The Pennant Group Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "POAI": {
        "symbol": "POAI",
        "name": "Predictive Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "POCI": {
        "symbol": "POCI",
        "name": "Precision Optics Corporation Inc. Common stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "PODC": {
        "symbol": "PODC",
        "name": "PodcastOne Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "PODD": {
        "symbol": "PODD",
        "name": "Insulet Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "POET": {
        "symbol": "POET",
        "name": "POET Technologies Inc. Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "POLA": {
        "symbol": "POLA",
        "name": "Polar Power Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "POLEU": {
        "symbol": "POLEU",
        "name": "Andretti Acquisition Corp. II Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "POOL": {
        "symbol": "POOL",
        "name": "Pool Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "POWI": {
        "symbol": "POWI",
        "name": "Power Integrations Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "POWL": {
        "symbol": "POWL",
        "name": "Powell Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Electrical Products"
    },
    "POWW": {
        "symbol": "POWW",
        "name": "AMMO Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Ordnance And Accessories"
    },
    "POWWP": {
        "symbol": "POWWP",
        "name": "AMMO Inc. 8.75% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Ordnance And Accessories"
    },
    "PPBI": {
        "symbol": "PPBI",
        "name": "Pacific Premier Bancorp Inc",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PPBT": {
        "symbol": "PPBT",
        "name": "Purple Biotech Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PPC": {
        "symbol": "PPC",
        "name": "Pilgrim's Pride Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Meat/Poultry/Fish"
    },
    "PPIH": {
        "symbol": "PPIH",
        "name": "Perma-Pipe International Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Pollution Control Equipment"
    },
    "PPSI": {
        "symbol": "PPSI",
        "name": "Pioneer Power Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "PPTA": {
        "symbol": "PPTA",
        "name": "Perpetua Resources Corp. Common Shares",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "PPYA": {
        "symbol": "PPYA",
        "name": "Papaya Growth Opportunity Corp. I Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PPYAW": {
        "symbol": "PPYAW",
        "name": "Papaya Growth Opportunity Corp. I Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PRAA": {
        "symbol": "PRAA",
        "name": "PRA Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PRAX": {
        "symbol": "PRAX",
        "name": "Praxis Precision Medicines Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRCH": {
        "symbol": "PRCH",
        "name": "Porch Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PRCT": {
        "symbol": "PRCT",
        "name": "PROCEPT BioRobotics Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PRDO": {
        "symbol": "PRDO",
        "name": "Perdoceo Education Corporation Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "PRE": {
        "symbol": "PRE",
        "name": "Prenetics Global Limited Class A Ordinary Share",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PRENW": {
        "symbol": "PRENW",
        "name": "Prenetics Global Limited Warrant",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PRFT": {
        "symbol": "PRFT",
        "name": "Perficient Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "PRFX": {
        "symbol": "PRFX",
        "name": "PainReform Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRGS": {
        "symbol": "PRGS",
        "name": "Progress Software Corporation Common Stock (DE)",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PRLD": {
        "symbol": "PRLD",
        "name": "Prelude Therapeutics Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRLH": {
        "symbol": "PRLH",
        "name": "Pearl Holdings Acquisition Corp Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PRME": {
        "symbol": "PRME",
        "name": "Prime Medicine Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PROC": {
        "symbol": "PROC",
        "name": "Procaps Group S.A. Ordinary Shares",
        "country": "Luxembourg",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PROCW": {
        "symbol": "PROCW",
        "name": "Procaps Group S.A. Warrants",
        "country": "Luxembourg",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PROF": {
        "symbol": "PROF",
        "name": "Profound Medical Corp. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PROK": {
        "symbol": "PROK",
        "name": "ProKidney Corp. Class A Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PROP": {
        "symbol": "PROP",
        "name": "Prairie Operating Co. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PROV": {
        "symbol": "PROV",
        "name": "Provident Financial Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "PRPH": {
        "symbol": "PRPH",
        "name": "ProPhase Labs Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRPL": {
        "symbol": "PRPL",
        "name": "Purple Innovation Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "PRPO": {
        "symbol": "PRPO",
        "name": "Precipio Inc.  Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "PRQR": {
        "symbol": "PRQR",
        "name": "ProQR Therapeutics N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRSO": {
        "symbol": "PRSO",
        "name": "Peraso Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "PRTA": {
        "symbol": "PRTA",
        "name": "Prothena Corporation plc Ordinary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRTC": {
        "symbol": "PRTC",
        "name": "PureTech Health plc American Depositary Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PRTG": {
        "symbol": "PRTG",
        "name": "Portage Biotech Inc. Common Stock",
        "country": "Canada",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "PRTH": {
        "symbol": "PRTH",
        "name": "Priority Technology Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PRTS": {
        "symbol": "PRTS",
        "name": "CarParts.com Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto & Home Supply Stores"
    },
    "PRVA": {
        "symbol": "PRVA",
        "name": "Privia Health Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "PRZO": {
        "symbol": "PRZO",
        "name": "ParaZero Technologies Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "PSEC": {
        "symbol": "PSEC",
        "name": "Prospect Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PSHG": {
        "symbol": "PSHG",
        "name": "Performance Shipping Inc. Common Shares",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "PSIG": {
        "symbol": "PSIG",
        "name": "PS International Group Ltd. Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "PSMT": {
        "symbol": "PSMT",
        "name": "PriceSmart Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Department/Specialty Retail Stores"
    },
    "PSNL": {
        "symbol": "PSNL",
        "name": "Personalis Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "PSNY": {
        "symbol": "PSNY",
        "name": "Polestar Automotive Holding UK PLC Class A ADS",
        "country": "Sweden",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "PSNYW": {
        "symbol": "PSNYW",
        "name": "Polestar Automotive Holding UK PLC Class C-1 ADS (ADW)",
        "country": "Sweden",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "PSTV": {
        "symbol": "PSTV",
        "name": "PLUS THERAPEUTICS Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "PSTX": {
        "symbol": "PSTX",
        "name": "Poseida Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "PT": {
        "symbol": "PT",
        "name": "Pintec Technology Holdings Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "PTC": {
        "symbol": "PTC",
        "name": "PTC Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PTCT": {
        "symbol": "PTCT",
        "name": "PTC Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PTEN": {
        "symbol": "PTEN",
        "name": "Patterson-UTI Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "PTGX": {
        "symbol": "PTGX",
        "name": "Protagonist Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PTHL": {
        "symbol": "PTHL",
        "name": "Pheton Holdings Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "PTIX": {
        "symbol": "PTIX",
        "name": "Protagenic Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PTIXW": {
        "symbol": "PTIXW",
        "name": "Protagenic Therapeutics Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PTLO": {
        "symbol": "PTLO",
        "name": "Portillo's Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "PTMN": {
        "symbol": "PTMN",
        "name": "Portman Ridge Finance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PTON": {
        "symbol": "PTON",
        "name": "Peloton Interactive Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "PTPI": {
        "symbol": "PTPI",
        "name": "Petros Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PTSI": {
        "symbol": "PTSI",
        "name": "P.A.M. Transportation Services Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "PTVE": {
        "symbol": "PTVE",
        "name": "Pactiv Evergreen Inc. Common stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Containers/Packaging"
    },
    "PUBM": {
        "symbol": "PUBM",
        "name": "PubMatic Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "PULM": {
        "symbol": "PULM",
        "name": "Pulmatrix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PVBC": {
        "symbol": "PVBC",
        "name": "Provident Bancorp Inc. (MD) Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "PWM": {
        "symbol": "PWM",
        "name": "Prestige Wealth Inc. Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PWOD": {
        "symbol": "PWOD",
        "name": "Penns Woods Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "PWP": {
        "symbol": "PWP",
        "name": "Perella Weinberg Partners Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "PWUPU": {
        "symbol": "PWUPU",
        "name": "PowerUp Acquisition Corp. Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PWUPW": {
        "symbol": "PWUPW",
        "name": "PowerUp Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "PXDT": {
        "symbol": "PXDT",
        "name": "Pixie Dust Technologies Inc. American Depositary Shares ",
        "country": "Japan",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "PXLW": {
        "symbol": "PXLW",
        "name": "Pixelworks Inc.  Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "PXS": {
        "symbol": "PXS",
        "name": "Pyxis Tankers Inc. Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "PXSAP": {
        "symbol": "PXSAP",
        "name": "Pyxis Tankers Inc. 7.75% Series A Cumulative Convertible Preferred Shares",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "PXSAW": {
        "symbol": "PXSAW",
        "name": "Pyxis Tankers Inc. Warrant",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "PYCR": {
        "symbol": "PYCR",
        "name": "Paycor HCM Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "PYPD": {
        "symbol": "PYPD",
        "name": "PolyPid Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PYPL": {
        "symbol": "PYPL",
        "name": "PayPal Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "PYXS": {
        "symbol": "PYXS",
        "name": "Pyxis Oncology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "PZZA": {
        "symbol": "PZZA",
        "name": "Papa John's International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "QCOM": {
        "symbol": "QCOM",
        "name": "QUALCOMM Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "QCRH": {
        "symbol": "QCRH",
        "name": "QCR Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "QDEL": {
        "symbol": "QDEL",
        "name": "QuidelOrtho Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "QETA": {
        "symbol": "QETA",
        "name": "Quetta Acquisition Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "QFIN": {
        "symbol": "QFIN",
        "name": "Qifu Technology Inc. American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "QH": {
        "symbol": "QH",
        "name": "Quhuo Limited American Depository Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "QIPT": {
        "symbol": "QIPT",
        "name": "Quipt Home Medical Corp. Common Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "QLGN": {
        "symbol": "QLGN",
        "name": "Qualigen Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "QLYS": {
        "symbol": "QLYS",
        "name": "Qualys Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "QMCO": {
        "symbol": "QMCO",
        "name": "Quantum Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "QMMM": {
        "symbol": "QMMM",
        "name": "QMMM Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "QNCX": {
        "symbol": "QNCX",
        "name": "Quince Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "QNRX": {
        "symbol": "QNRX",
        "name": "Quoin Pharmaceuticals Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "QNST": {
        "symbol": "QNST",
        "name": "QuinStreet Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "QNTM": {
        "symbol": "QNTM",
        "name": "Quantum Biopharma Ltd. Class B Subordinate Voting Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "QOMO": {
        "symbol": "QOMO",
        "name": "Qomolangma Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "QQQX": {
        "symbol": "QQQX",
        "name": "Nuveen NASDAQ 100 Dynamic Overwrite Fund Shares of Beneficial Interest",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "QRHC": {
        "symbol": "QRHC",
        "name": "Quest Resource Holding Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Environmental Services"
    },
    "QRTEA": {
        "symbol": "QRTEA",
        "name": "Qurate Retail Inc. Series A Common Stock ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "QRTEB": {
        "symbol": "QRTEB",
        "name": "Qurate Retail Inc. Series B Common Stock ",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "QRTEP": {
        "symbol": "QRTEP",
        "name": "Qurate Retail Inc. 8.0% Fixed Rate Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "QRVO": {
        "symbol": "QRVO",
        "name": "Qorvo Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "QSG": {
        "symbol": "QSG",
        "name": "QuantaSing Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "QSI": {
        "symbol": "QSI",
        "name": "Quantum-Si Incorporated Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "QSIAW": {
        "symbol": "QSIAW",
        "name": "Quantum-Si Incorporated Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "QTI": {
        "symbol": "QTI",
        "name": "QT Imaging Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "QTRX": {
        "symbol": "QTRX",
        "name": "Quanterix Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "QTTB": {
        "symbol": "QTTB",
        "name": "Q32 Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "QUBT": {
        "symbol": "QUBT",
        "name": "Quantum Computing Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "QUIK": {
        "symbol": "QUIK",
        "name": "QuickLogic Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "QURE": {
        "symbol": "QURE",
        "name": "uniQure N.V. Ordinary Shares",
        "country": "Netherlands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "QXO": {
        "symbol": "QXO",
        "name": "QXO Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RAIL": {
        "symbol": "RAIL",
        "name": "FreightCar America Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Railroads"
    },
    "RAND": {
        "symbol": "RAND",
        "name": "Rand Capital Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "RANI": {
        "symbol": "RANI",
        "name": "Rani Therapeutics Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RAPP": {
        "symbol": "RAPP",
        "name": "Rapport Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RAPT": {
        "symbol": "RAPT",
        "name": "RAPT Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RARE": {
        "symbol": "RARE",
        "name": "Ultragenyx Pharmaceutical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RAVE": {
        "symbol": "RAVE",
        "name": "Rave Restaurant Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Food Distributors"
    },
    "RAY": {
        "symbol": "RAY",
        "name": "Raytech Holding Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Appliances"
    },
    "RAYA": {
        "symbol": "RAYA",
        "name": "Erayak Power Solution Group Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Metal Fabrications"
    },
    "RBB": {
        "symbol": "RBB",
        "name": "RBB Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "RBBN": {
        "symbol": "RBBN",
        "name": "Ribbon Communications Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RBCAA": {
        "symbol": "RBCAA",
        "name": "Republic Bancorp Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "RBKB": {
        "symbol": "RBKB",
        "name": "Rhinebeck Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "RCAT": {
        "symbol": "RCAT",
        "name": "Red Cat Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "RCEL": {
        "symbol": "RCEL",
        "name": "Avita Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "RCKT": {
        "symbol": "RCKT",
        "name": "Rocket Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RCKTW": {
        "symbol": "RCKTW",
        "name": "Rocket Pharmaceuticals Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RCKY": {
        "symbol": "RCKY",
        "name": "Rocky Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Shoe Manufacturing"
    },
    "RCM": {
        "symbol": "RCM",
        "name": "R1 RCM Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "RCMT": {
        "symbol": "RCMT",
        "name": "RCM Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "RCON": {
        "symbol": "RCON",
        "name": "Recon Technology Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Energy",
        "industry": "Oilfield Services/Equipment"
    },
    "RCRT": {
        "symbol": "RCRT",
        "name": "Recruiter.com Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RCRTW": {
        "symbol": "RCRTW",
        "name": "Recruiter.com Group Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RDCM": {
        "symbol": "RDCM",
        "name": "Radcom Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "RDFN": {
        "symbol": "RDFN",
        "name": "Redfin Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "RDHL": {
        "symbol": "RDHL",
        "name": "Redhill Biopharma Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RDI": {
        "symbol": "RDI",
        "name": "Reading International Inc Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "RDIB": {
        "symbol": "RDIB",
        "name": "Reading International Inc Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Movies/Entertainment"
    },
    "RDNT": {
        "symbol": "RDNT",
        "name": "RadNet Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "RDUS": {
        "symbol": "RDUS",
        "name": "Radius Recycling Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "RDVT": {
        "symbol": "RDVT",
        "name": "Red Violet Inc. Common Stock ",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "RDWR": {
        "symbol": "RDWR",
        "name": "Radware Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RDZN": {
        "symbol": "RDZN",
        "name": "Roadzen Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "RDZNW": {
        "symbol": "RDZNW",
        "name": "Roadzen Inc. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "REAL": {
        "symbol": "REAL",
        "name": "The RealReal Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "REAX": {
        "symbol": "REAX",
        "name": "The Real Brokerage Inc. Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "REBN": {
        "symbol": "REBN",
        "name": "Reborn Coffee Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "RECT": {
        "symbol": "RECT",
        "name": "Rectitude Holdings Ltd Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Machinery/Components"
    },
    "REE": {
        "symbol": "REE",
        "name": "REE Automotive Ltd. Class A Ordinary Shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "REFI": {
        "symbol": "REFI",
        "name": "Chicago Atlantic Real Estate Finance Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "REFR": {
        "symbol": "REFR",
        "name": "Research Frontiers Incorporated Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "REG": {
        "symbol": "REG",
        "name": "Regency Centers Corporation Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "REGCO": {
        "symbol": "REGCO",
        "name": "Regency Centers Corporation 5.875% Series B Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "REGCP": {
        "symbol": "REGCP",
        "name": "Regency Centers Corporation 6.25% Series A Cumulative Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "REGN": {
        "symbol": "REGN",
        "name": "Regeneron Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "REKR": {
        "symbol": "REKR",
        "name": "Rekor Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "RELI": {
        "symbol": "RELI",
        "name": "Reliance Global Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "RELIW": {
        "symbol": "RELIW",
        "name": "Reliance Global Group Inc. Series A Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "RELL": {
        "symbol": "RELL",
        "name": "Richardson Electronics Ltd. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "RELY": {
        "symbol": "RELY",
        "name": "Remitly Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RENB": {
        "symbol": "RENB",
        "name": "Renovaro Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RENE": {
        "symbol": "RENE",
        "name": "Cartesian Growth Corporation II Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "RENEW": {
        "symbol": "RENEW",
        "name": "Cartesian Growth Corporation II Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "RENT": {
        "symbol": "RENT",
        "name": "Rent the Runway Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "REPL": {
        "symbol": "REPL",
        "name": "Replimune Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RETO": {
        "symbol": "RETO",
        "name": "ReTo Eco-Solutions Inc. Class A Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "REVB": {
        "symbol": "REVB",
        "name": "Revelation Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "REVBW": {
        "symbol": "REVBW",
        "name": "Revelation Biosciences Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "REYN": {
        "symbol": "REYN",
        "name": "Reynolds Consumer Products Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Containers/Packaging"
    },
    "RFACU": {
        "symbol": "RFACU",
        "name": "RF Acquisition Corp. Unit",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "RFAI": {
        "symbol": "RFAI",
        "name": "RF Acquisition Corp II Ordinary Shares",
        "country": "Singapore",
        "sector": "",
        "industry": ""
    },
    "RFAIR": {
        "symbol": "RFAIR",
        "name": "RF Acquisition Corp II Right",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "RFAIU": {
        "symbol": "RFAIU",
        "name": "RF Acquisition Corp II Unit",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "RFIL": {
        "symbol": "RFIL",
        "name": "RF Industries Ltd. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "RGC": {
        "symbol": "RGC",
        "name": "Regencell Bioscience Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "RGCO": {
        "symbol": "RGCO",
        "name": "RGC Resources Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Oil & Gas Production"
    },
    "RGEN": {
        "symbol": "RGEN",
        "name": "Repligen Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RGF": {
        "symbol": "RGF",
        "name": "The Real Good Food Company Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "RGLD": {
        "symbol": "RGLD",
        "name": "Royal Gold Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Precious Metals"
    },
    "RGLS": {
        "symbol": "RGLS",
        "name": "Regulus Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RGNX": {
        "symbol": "RGNX",
        "name": "REGENXBIO Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RGP": {
        "symbol": "RGP",
        "name": "Resources Connection Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RGS": {
        "symbol": "RGS",
        "name": "Regis Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "RGTI": {
        "symbol": "RGTI",
        "name": "Rigetti Computing Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RGTIW": {
        "symbol": "RGTIW",
        "name": "Rigetti Computing Inc. Warrants",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RICK": {
        "symbol": "RICK",
        "name": "RCI Hospitality Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "RIGL": {
        "symbol": "RIGL",
        "name": "Rigel Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RILY": {
        "symbol": "RILY",
        "name": "B. Riley Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYG": {
        "symbol": "RILYG",
        "name": "B. Riley Financial Inc. 5.00% Senior Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYK": {
        "symbol": "RILYK",
        "name": "B. Riley Financial Inc. 5.50% Senior Notes Due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYL": {
        "symbol": "RILYL",
        "name": "B. Riley Financial Inc. Depositary Shares each representing 1/1000th in a share of 7.375% Series B Cumulative Perpetual Preferred Stock par value $0.0001",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYM": {
        "symbol": "RILYM",
        "name": "B. Riley Financial Inc. 6.375% Senior Notes due 2025",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYN": {
        "symbol": "RILYN",
        "name": "B. Riley Financial Inc. 6.50% Senior Notes Due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYP": {
        "symbol": "RILYP",
        "name": "B. Riley Financial Inc. Depositary Shares each representing a 1/1000th fractional interest in a share of Series A Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYT": {
        "symbol": "RILYT",
        "name": "B. Riley Financial Inc. 6.00% Senior Notes Due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RILYZ": {
        "symbol": "RILYZ",
        "name": "B. Riley Financial Inc. 5.25% Senior Notes due 2028",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "RIME": {
        "symbol": "RIME",
        "name": "Algorhythm Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Diversified Electronic Products"
    },
    "RIOT": {
        "symbol": "RIOT",
        "name": "Riot Platforms Inc. Common Stock ",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "RITR": {
        "symbol": "RITR",
        "name": "Reitar Logtech Holdings Limited Ordinary shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "RIVN": {
        "symbol": "RIVN",
        "name": "Rivian Automotive Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "RKDA": {
        "symbol": "RKDA",
        "name": "Arcadia Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "RKLB": {
        "symbol": "RKLB",
        "name": "Rocket Lab USA Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "RLAY": {
        "symbol": "RLAY",
        "name": "Relay Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RLMD": {
        "symbol": "RLMD",
        "name": "Relmada Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RLYB": {
        "symbol": "RLYB",
        "name": "Rallybio Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RMBI": {
        "symbol": "RMBI",
        "name": "Richmond Mutual Bancorporation Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "RMBL": {
        "symbol": "RMBL",
        "name": "RumbleOn Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "RMBS": {
        "symbol": "RMBS",
        "name": "Rambus Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "RMCF": {
        "symbol": "RMCF",
        "name": "Rocky Mountain Chocolate Factory Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Specialty Foods"
    },
    "RMCO": {
        "symbol": "RMCO",
        "name": "Royalty Management Holding Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "RMCOW": {
        "symbol": "RMCOW",
        "name": "Royalty Management Holding Corporation Warrant",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "RMNI": {
        "symbol": "RMNI",
        "name": "Rimini Street Inc. (DE) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RMR": {
        "symbol": "RMR",
        "name": "The RMR Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "RMTI": {
        "symbol": "RMTI",
        "name": "Rockwell Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RNA": {
        "symbol": "RNA",
        "name": "Avidity Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RNAC": {
        "symbol": "RNAC",
        "name": "Cartesian Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RNAZ": {
        "symbol": "RNAZ",
        "name": "TransCode Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RNLX": {
        "symbol": "RNLX",
        "name": "Renalytix plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "RNW": {
        "symbol": "RNW",
        "name": "ReNew Energy Global plc Class A Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "RNWWW": {
        "symbol": "RNWWW",
        "name": "ReNew Energy Global plc Warrant",
        "country": "United Kingdom",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "RNXT": {
        "symbol": "RNXT",
        "name": "RenovoRx Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ROAD": {
        "symbol": "ROAD",
        "name": "Construction Partners Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "ROCK": {
        "symbol": "ROCK",
        "name": "Gibraltar Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "ROCL": {
        "symbol": "ROCL",
        "name": "Roth CH Acquisition V Co. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ROCLW": {
        "symbol": "ROCLW",
        "name": "Roth CH Acquisition V Co. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ROIC": {
        "symbol": "ROIC",
        "name": "Retail Opportunity Investments Corp. Common Stock (MD)",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "ROIV": {
        "symbol": "ROIV",
        "name": "Roivant Sciences Ltd. Common Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ROKU": {
        "symbol": "ROKU",
        "name": "Roku Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "ROMA": {
        "symbol": "ROMA",
        "name": "Roma Green Finance Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "ROOT": {
        "symbol": "ROOT",
        "name": "Root Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "ROP": {
        "symbol": "ROP",
        "name": "Roper Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "ROST": {
        "symbol": "ROST",
        "name": "Ross Stores Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "RPAY": {
        "symbol": "RPAY",
        "name": "Repay Holdings Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RPD": {
        "symbol": "RPD",
        "name": "Rapid7 Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "RPHM": {
        "symbol": "RPHM",
        "name": "Reneo Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RPID": {
        "symbol": "RPID",
        "name": "Rapid Micro Biosystems Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "RPRX": {
        "symbol": "RPRX",
        "name": "Royalty Pharma plc Class A Ordinary Shares ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RPTX": {
        "symbol": "RPTX",
        "name": "Repare Therapeutics Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RR": {
        "symbol": "RR",
        "name": "Richtech Robotics Inc. Class B Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "RRBI": {
        "symbol": "RRBI",
        "name": "Red River Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "RRGB": {
        "symbol": "RRGB",
        "name": "Red Robin Gourmet Burgers Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "RRR": {
        "symbol": "RRR",
        "name": "Red Rock Resorts Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "RSLS": {
        "symbol": "RSLS",
        "name": "ReShape Lifesciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "RSSS": {
        "symbol": "RSSS",
        "name": "Research Solutions Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "RSVR": {
        "symbol": "RSVR",
        "name": "Reservoir Media Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "RSVRW": {
        "symbol": "RSVRW",
        "name": "Reservoir Media Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "RTC": {
        "symbol": "RTC",
        "name": "Baijiayun Group Ltd. Class A Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "RUM": {
        "symbol": "RUM",
        "name": "Rumble Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "RUMBW": {
        "symbol": "RUMBW",
        "name": "Rumble Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "RUN": {
        "symbol": "RUN",
        "name": "Sunrun Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "RUSHA": {
        "symbol": "RUSHA",
        "name": "Rush Enterprises Inc. Common Stock Cl A",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "RUSHB": {
        "symbol": "RUSHB",
        "name": "Rush Enterprises Inc. Class B",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "RVMD": {
        "symbol": "RVMD",
        "name": "Revolution Medicines Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RVMDW": {
        "symbol": "RVMDW",
        "name": "Revolution Medicines Inc. Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "RVNC": {
        "symbol": "RVNC",
        "name": "Revance Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RVPH": {
        "symbol": "RVPH",
        "name": "Reviva Pharmaceuticals Holdings Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RVPHW": {
        "symbol": "RVPHW",
        "name": "Reviva Pharmaceuticals Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RVSB": {
        "symbol": "RVSB",
        "name": "Riverview Bancorp Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "RVSN": {
        "symbol": "RVSN",
        "name": "Rail Vision Ltd. Ordinary Share",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Railroads"
    },
    "RVSNW": {
        "symbol": "RVSNW",
        "name": "Rail Vision Ltd. Warrant",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Railroads"
    },
    "RVYL": {
        "symbol": "RVYL",
        "name": "Ryvyl Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "RWAY": {
        "symbol": "RWAY",
        "name": "Runway Growth Finance Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "RWAYL": {
        "symbol": "RWAYL",
        "name": "Runway Growth Finance Corp. 7.50% Notes due 2027",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "RWAYZ": {
        "symbol": "RWAYZ",
        "name": "Runway Growth Finance Corp. 8.00% Notes due 2027",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "RXRX": {
        "symbol": "RXRX",
        "name": "Recursion Pharmaceuticals Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "RXST": {
        "symbol": "RXST",
        "name": "RxSight Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "RXT": {
        "symbol": "RXT",
        "name": "Rackspace Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "RYAAY": {
        "symbol": "RYAAY",
        "name": "Ryanair Holdings plc American Depositary Shares",
        "country": "Ireland",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "RYTM": {
        "symbol": "RYTM",
        "name": "Rhythm Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RZLT": {
        "symbol": "RZLT",
        "name": "Rezolute Inc. Common Stock (NV)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "RZLV": {
        "symbol": "RZLV",
        "name": "Rezolve AI Limited Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "RZLVW": {
        "symbol": "RZLVW",
        "name": "Rezolve AI Limited Warrants",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SABR": {
        "symbol": "SABR",
        "name": "Sabre Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SABS": {
        "symbol": "SABS",
        "name": "SAB Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SABSW": {
        "symbol": "SABSW",
        "name": "SAB Biotherapeutics Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SAFT": {
        "symbol": "SAFT",
        "name": "Safety Insurance Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "SAGE": {
        "symbol": "SAGE",
        "name": "Sage Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SAIA": {
        "symbol": "SAIA",
        "name": "Saia Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "SAIC": {
        "symbol": "SAIC",
        "name": "Science Applications International Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SAIH": {
        "symbol": "SAIH",
        "name": "SAIHEAT Limited Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SAIHW": {
        "symbol": "SAIHW",
        "name": "SAIHEAT Limited Warrant",
        "country": "Singapore",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SAMG": {
        "symbol": "SAMG",
        "name": "Silvercrest Asset Management Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "SANA": {
        "symbol": "SANA",
        "name": "Sana Biotechnology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SANG": {
        "symbol": "SANG",
        "name": "Sangoma Technologies Corporation Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SANM": {
        "symbol": "SANM",
        "name": "Sanmina Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "SANW": {
        "symbol": "SANW",
        "name": "S&W Seed Company Common Stock (NV)",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "SASR": {
        "symbol": "SASR",
        "name": "Sandy Spring Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SATL": {
        "symbol": "SATL",
        "name": "Satellogic Inc. Class A Ordinary Shares",
        "country": "Uruguay",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "SATLW": {
        "symbol": "SATLW",
        "name": "Satellogic Inc. Warrant",
        "country": "Uruguay",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "SATS": {
        "symbol": "SATS",
        "name": "EchoStar  Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "SAVA": {
        "symbol": "SAVA",
        "name": "Cassava Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SBAC": {
        "symbol": "SBAC",
        "name": "SBA Communications Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SBC": {
        "symbol": "SBC",
        "name": "SBC Medical Group Holdings Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "SBCF": {
        "symbol": "SBCF",
        "name": "Seacoast Banking Corporation of Florida Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SBCWW": {
        "symbol": "SBCWW",
        "name": "SBC Medical Group Holdings Incorporated Warrants",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "SBET": {
        "symbol": "SBET",
        "name": "SharpLink Gaming Inc. Common Stock",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SBFG": {
        "symbol": "SBFG",
        "name": "SB Financial Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SBFM": {
        "symbol": "SBFM",
        "name": "Sunshine Biopharma Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SBFMW": {
        "symbol": "SBFMW",
        "name": "Sunshine Biopharma Inc. Warrant",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SBGI": {
        "symbol": "SBGI",
        "name": "Sinclair Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "SBLK": {
        "symbol": "SBLK",
        "name": "Star Bulk Carriers Corp. Common Shares",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "SBRA": {
        "symbol": "SBRA",
        "name": "Sabra Health Care REIT Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SBSI": {
        "symbol": "SBSI",
        "name": "Southside Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SBT": {
        "symbol": "SBT",
        "name": "Sterling Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "SBUX": {
        "symbol": "SBUX",
        "name": "Starbucks Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "SCHL": {
        "symbol": "SCHL",
        "name": "Scholastic Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Books"
    },
    "SCKT": {
        "symbol": "SCKT",
        "name": "Socket Mobile Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Manufacturing"
    },
    "SCLX": {
        "symbol": "SCLX",
        "name": "Scilex Holding Company Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SCLXW": {
        "symbol": "SCLXW",
        "name": "Scilex Holding Company Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SCNI": {
        "symbol": "SCNI",
        "name": "Scinai Immunotherapeutics Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SCNX": {
        "symbol": "SCNX",
        "name": "Scienture Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Other Pharmaceuticals"
    },
    "SCOR": {
        "symbol": "SCOR",
        "name": "comScore Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SCPH": {
        "symbol": "SCPH",
        "name": "scPharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SCSC": {
        "symbol": "SCSC",
        "name": "ScanSource Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Retail: Computer Software & Peripheral Equipment"
    },
    "SCVL": {
        "symbol": "SCVL",
        "name": "Shoe Carnival Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "SCWO": {
        "symbol": "SCWO",
        "name": "374Water Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Metal Fabrications"
    },
    "SCWX": {
        "symbol": "SCWX",
        "name": "SecureWorks Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SCYX": {
        "symbol": "SCYX",
        "name": "SCYNEXIS Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SDA": {
        "symbol": "SDA",
        "name": "SunCar Technology Group Inc. Class A Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "SDAWW": {
        "symbol": "SDAWW",
        "name": "SunCar Technology Group Inc. Warrant",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Automotive Aftermarket"
    },
    "SDGR": {
        "symbol": "SDGR",
        "name": "Schrodinger Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SDIG": {
        "symbol": "SDIG",
        "name": "Stronghold Digital Mining Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SDOT": {
        "symbol": "SDOT",
        "name": "Sadot Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "SDST": {
        "symbol": "SDST",
        "name": "Stardust Power Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "SDSTW": {
        "symbol": "SDSTW",
        "name": "Stardust Power Inc. Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SEAT": {
        "symbol": "SEAT",
        "name": "Vivid Seats Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "SEATW": {
        "symbol": "SEATW",
        "name": "Vivid Seats Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "SEDG": {
        "symbol": "SEDG",
        "name": "SolarEdge Technologies Inc. Common Stock",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SEED": {
        "symbol": "SEED",
        "name": "Origin Agritech Limited Common Stock",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "SEEL": {
        "symbol": "SEEL",
        "name": "Seelos Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SEER": {
        "symbol": "SEER",
        "name": "Seer Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "SEIC": {
        "symbol": "SEIC",
        "name": "SEI Investments Company Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "SELF": {
        "symbol": "SELF",
        "name": "Global Self Storage Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SELX": {
        "symbol": "SELX",
        "name": "Semilux International Ltd. Ordinary Shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SENEA": {
        "symbol": "SENEA",
        "name": "Seneca Foods Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SENEB": {
        "symbol": "SENEB",
        "name": "Seneca Foods Corp. Class B Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SERA": {
        "symbol": "SERA",
        "name": "Sera Prognostics Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "SERV": {
        "symbol": "SERV",
        "name": "Serve Robotics Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "SEVN": {
        "symbol": "SEVN",
        "name": "Seven Hills Realty Trust Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SEZL": {
        "symbol": "SEZL",
        "name": "Sezzle Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SFBC": {
        "symbol": "SFBC",
        "name": "Sound Financial Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "SFIX": {
        "symbol": "SFIX",
        "name": "Stitch Fix Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "SFM": {
        "symbol": "SFM",
        "name": "Sprouts Farmers Market Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "SFNC": {
        "symbol": "SFNC",
        "name": "Simmons First National Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SFST": {
        "symbol": "SFST",
        "name": "Southern First Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SFWL": {
        "symbol": "SFWL",
        "name": "Shengfeng Development Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "SGA": {
        "symbol": "SGA",
        "name": "Saga Communications Inc. Class A Common Stock (FL)",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "SGBX": {
        "symbol": "SGBX",
        "name": "Safe & Green Holdings Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "SGC": {
        "symbol": "SGC",
        "name": "Superior Group of Companies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "SGD": {
        "symbol": "SGD",
        "name": "Safe and Green Development Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "SGH": {
        "symbol": "SGH",
        "name": "SMART Global Holdings Inc. Ordinary Shares",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SGHT": {
        "symbol": "SGHT",
        "name": "Sight Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SGLY": {
        "symbol": "SGLY",
        "name": "Singularity Future Technology Ltd. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Oil Refining/Marketing"
    },
    "SGMA": {
        "symbol": "SGMA",
        "name": "SigmaTron International Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "SGML": {
        "symbol": "SGML",
        "name": "Sigma Lithium Corporation Common Shares",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "SGMO": {
        "symbol": "SGMO",
        "name": "Sangamo Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SGMT": {
        "symbol": "SGMT",
        "name": "Sagimet Biosciences Inc. Series A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SGRP": {
        "symbol": "SGRP",
        "name": "SPAR Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SGRY": {
        "symbol": "SGRY",
        "name": "Surgery Partners Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Hospital/Nursing Management"
    },
    "SHBI": {
        "symbol": "SHBI",
        "name": "Shore Bancshares Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SHC": {
        "symbol": "SHC",
        "name": "Sotera Health Company Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "SHCR": {
        "symbol": "SHCR",
        "name": "Sharecare Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "SHCRW": {
        "symbol": "SHCRW",
        "name": "Sharecare Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "SHEN": {
        "symbol": "SHEN",
        "name": "Shenandoah Telecommunications Co Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "SHFS": {
        "symbol": "SHFS",
        "name": "SHF Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SHFSW": {
        "symbol": "SHFSW",
        "name": "SHF Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SHIM": {
        "symbol": "SHIM",
        "name": "Shimmick Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "SHIP": {
        "symbol": "SHIP",
        "name": "Seanergy Maritime Holdings Corp. Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "SHLS": {
        "symbol": "SHLS",
        "name": "Shoals Technologies Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SHLT": {
        "symbol": "SHLT",
        "name": "SHL Telemedicine Ltd American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "SHMD": {
        "symbol": "SHMD",
        "name": "SCHMID Group N.V. Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "SHMDW": {
        "symbol": "SHMDW",
        "name": "SCHMID Group N.V. Warrants",
        "country": "Cayman Islands",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "SHOO": {
        "symbol": "SHOO",
        "name": "Steven Madden Ltd. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Shoe Manufacturing"
    },
    "SHOT": {
        "symbol": "SHOT",
        "name": "Safety Shot Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "SHOTW": {
        "symbol": "SHOTW",
        "name": "Safety Shot Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "SHPH": {
        "symbol": "SHPH",
        "name": "Shuttle Pharmaceuticals Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SHYF": {
        "symbol": "SHYF",
        "name": "The Shyft Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "SIBN": {
        "symbol": "SIBN",
        "name": "SI-BONE Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SIDU": {
        "symbol": "SIDU",
        "name": "Sidus Space Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "SIEB": {
        "symbol": "SIEB",
        "name": "Siebert Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "SIFY": {
        "symbol": "SIFY",
        "name": "Sify Technologies Limited American Depositary Shares",
        "country": "India",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SIGA": {
        "symbol": "SIGA",
        "name": "SIGA Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SIGI": {
        "symbol": "SIGI",
        "name": "Selective Insurance Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "SIGIP": {
        "symbol": "SIGIP",
        "name": "Selective Insurance Group Inc. Depositary Shares each representing a 1/1000th interest in a share of 4.60% Non-Cumulative Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "SILC": {
        "symbol": "SILC",
        "name": "Silicom Ltd Ordinary Shares",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "SILO": {
        "symbol": "SILO",
        "name": "Silo Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "SIMA": {
        "symbol": "SIMA",
        "name": "SIM Acquisition Corp. I Class A Ordinary Shares",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "SIMAW": {
        "symbol": "SIMAW",
        "name": "SIM Acquisition Corp. I Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "SIMO": {
        "symbol": "SIMO",
        "name": "Silicon Motion Technology Corporation American Depositary Shares",
        "country": "Taiwan",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SINT": {
        "symbol": "SINT",
        "name": "SiNtx Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SIRI": {
        "symbol": "SIRI",
        "name": "SiriusXM Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "SISI": {
        "symbol": "SISI",
        "name": "Shineco Inc. Common Stock",
        "country": "China",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "SITM": {
        "symbol": "SITM",
        "name": "SiTime Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SJ": {
        "symbol": "SJ",
        "name": "Scienjoy Holding Corporation Class A Ordinary Shares ",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SKGR": {
        "symbol": "SKGR",
        "name": "SK Growth Opportunities Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "SKGRW": {
        "symbol": "SKGRW",
        "name": "SK Growth Opportunities Corporation Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "SKIN": {
        "symbol": "SKIN",
        "name": "The Beauty Health Company Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SKWD": {
        "symbol": "SKWD",
        "name": "Skyward Specialty Insurance Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "SKYE": {
        "symbol": "SKYE",
        "name": "Skye Bioscience Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SKYT": {
        "symbol": "SKYT",
        "name": "SkyWater Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SKYW": {
        "symbol": "SKYW",
        "name": "SkyWest Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "SKYX": {
        "symbol": "SKYX",
        "name": "SKYX Platforms Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Building Products"
    },
    "SLAB": {
        "symbol": "SLAB",
        "name": "Silicon Laboratories Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SLDB": {
        "symbol": "SLDB",
        "name": "Solid Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SLDP": {
        "symbol": "SLDP",
        "name": "Solid Power Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "SLDPW": {
        "symbol": "SLDPW",
        "name": "Solid Power Inc. Warrant",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "SLE": {
        "symbol": "SLE",
        "name": "Super League Enterprise Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "SLGL": {
        "symbol": "SLGL",
        "name": "Sol-Gel Technologies Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SLM": {
        "symbol": "SLM",
        "name": "SLM Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SLMBP": {
        "symbol": "SLMBP",
        "name": "SLM Corporation Floating Rate Non-Cumulative Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SLN": {
        "symbol": "SLN",
        "name": "Silence Therapeutics Plc American Depository Share",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SLNG": {
        "symbol": "SLNG",
        "name": "Stabilis Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Oil/Gas Transmission"
    },
    "SLNH": {
        "symbol": "SLNH",
        "name": "Soluna Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SLNHP": {
        "symbol": "SLNHP",
        "name": "Soluna Holdings Inc 9.0% Series A Cumulative Perpetual Preferred Stock ",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SLNO": {
        "symbol": "SLNO",
        "name": "Soleno Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "SLP": {
        "symbol": "SLP",
        "name": "Simulations Plus Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SLRC": {
        "symbol": "SLRC",
        "name": "SLR Investment Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "SLRN": {
        "symbol": "SLRN",
        "name": "ACELYRIN INC. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SLRX": {
        "symbol": "SLRX",
        "name": "Salarius Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SLS": {
        "symbol": "SLS",
        "name": "SELLAS Life Sciences Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SLXN": {
        "symbol": "SLXN",
        "name": "Silexion Therapeutics Corp Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SLXNW": {
        "symbol": "SLXNW",
        "name": "Silexion Therapeutics Corp Warrant",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SMBC": {
        "symbol": "SMBC",
        "name": "Southern Missouri Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "SMCI": {
        "symbol": "SMCI",
        "name": "Super Micro Computer Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Manufacturing"
    },
    "SMID": {
        "symbol": "SMID",
        "name": "Smith-Midland Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Building Materials"
    },
    "SMLR": {
        "symbol": "SMLR",
        "name": "Semler Scientific Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "SMMT": {
        "symbol": "SMMT",
        "name": "Summit Therapeutics Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SMPL": {
        "symbol": "SMPL",
        "name": "The Simply Good Foods Company Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SMSI": {
        "symbol": "SMSI",
        "name": "Smith Micro Software Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SMTC": {
        "symbol": "SMTC",
        "name": "Semtech Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SMTI": {
        "symbol": "SMTI",
        "name": "Sanara MedTech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "SMTK": {
        "symbol": "SMTK",
        "name": "SmartKem Inc. Common Stock",
        "country": "United Kingdom",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SMX": {
        "symbol": "SMX",
        "name": "SMX (Security Matters) Public Limited Company Class A Ordinary Shares",
        "country": "Ireland",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "SMXT": {
        "symbol": "SMXT",
        "name": "Solarmax Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "SMXWW": {
        "symbol": "SMXWW",
        "name": "SMX (Security Matters) Public Limited Company Warrant",
        "country": "Ireland",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "SNAL": {
        "symbol": "SNAL",
        "name": "Snail Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SNAX": {
        "symbol": "SNAX",
        "name": "Stryve Foods Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SNAXW": {
        "symbol": "SNAXW",
        "name": "Stryve Foods Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SNBR": {
        "symbol": "SNBR",
        "name": "Sleep Number Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "SNCR": {
        "symbol": "SNCR",
        "name": "Synchronoss Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SNCRL": {
        "symbol": "SNCRL",
        "name": "Synchronoss Technologies Inc. 8.375% Senior Notes due 2026",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNCY": {
        "symbol": "SNCY",
        "name": "Sun Country Airlines Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "SND": {
        "symbol": "SND",
        "name": "Smart Sand Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "SNDL": {
        "symbol": "SNDL",
        "name": "SNDL Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "SNDX": {
        "symbol": "SNDX",
        "name": "Syndax Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNES": {
        "symbol": "SNES",
        "name": "SenesTech Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Agricultural Chemicals"
    },
    "SNEX": {
        "symbol": "SNEX",
        "name": "StoneX Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "SNFCA": {
        "symbol": "SNFCA",
        "name": "Security National Financial Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SNGX": {
        "symbol": "SNGX",
        "name": "Soligenix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNOA": {
        "symbol": "SNOA",
        "name": "Sonoma Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNPS": {
        "symbol": "SNPS",
        "name": "Synopsys Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SNPX": {
        "symbol": "SNPX",
        "name": "Synaptogenix Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNSE": {
        "symbol": "SNSE",
        "name": "Sensei Biotherapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SNT": {
        "symbol": "SNT",
        "name": "Senstar Technologies Corporation Common Shares",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "SNTG": {
        "symbol": "SNTG",
        "name": "Sentage Holdings Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SNTI": {
        "symbol": "SNTI",
        "name": "Senti Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SNY": {
        "symbol": "SNY",
        "name": "Sanofi ADS",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SOBR": {
        "symbol": "SOBR",
        "name": "SOBR Safe Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Newspapers/Magazines"
    },
    "SOFI": {
        "symbol": "SOFI",
        "name": "SoFi Technologies Inc. Common Stock  ",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SOGP": {
        "symbol": "SOGP",
        "name": "Sound Group Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SOHO": {
        "symbol": "SOHO",
        "name": "Sotherly Hotels Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SOHOB": {
        "symbol": "SOHOB",
        "name": "Sotherly Hotels Inc. 8.0% Series B Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SOHON": {
        "symbol": "SOHON",
        "name": "Sotherly Hotels Inc. 8.25% Series D Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SOHOO": {
        "symbol": "SOHOO",
        "name": "Sotherly Hotels Inc. 7.875% Series C Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SOHU": {
        "symbol": "SOHU",
        "name": "Sohu.com Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SOND": {
        "symbol": "SOND",
        "name": "Sonder Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "SONDW": {
        "symbol": "SONDW",
        "name": "Sonder Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "SONM": {
        "symbol": "SONM",
        "name": "Sonim Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Telecommunications Equipment"
    },
    "SONN": {
        "symbol": "SONN",
        "name": "Sonnet BioTherapeutics Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SONO": {
        "symbol": "SONO",
        "name": "Sonos Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Consumer Electronics/Appliances"
    },
    "SOPA": {
        "symbol": "SOPA",
        "name": "Society Pass Incorporated Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SOPH": {
        "symbol": "SOPH",
        "name": "SOPHiA GENETICS SA Ordinary Shares",
        "country": "Switzerland",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SOTK": {
        "symbol": "SOTK",
        "name": "Sono-Tek Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "SOUN": {
        "symbol": "SOUN",
        "name": "SoundHound AI Inc Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SOUNW": {
        "symbol": "SOUNW",
        "name": "SoundHound AI Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SOWG": {
        "symbol": "SOWG",
        "name": "Sow Good Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "SPAI": {
        "symbol": "SPAI",
        "name": "Safe Pro Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "SPCB": {
        "symbol": "SPCB",
        "name": "SuperCom Ltd. Ordinary Shares (Israel)",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SPFI": {
        "symbol": "SPFI",
        "name": "South Plains Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SPGC": {
        "symbol": "SPGC",
        "name": "Sacks Parente Golf Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "SPI": {
        "symbol": "SPI",
        "name": "SPI Energy Co. Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SPKL": {
        "symbol": "SPKL",
        "name": "Spark I Acquisition Corp. Class A Ordinary Share",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SPKLU": {
        "symbol": "SPKLU",
        "name": "Spark I Acquisition Corp. Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SPKLW": {
        "symbol": "SPKLW",
        "name": "Spark I Acquisition Corp. Warrant",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SPNS": {
        "symbol": "SPNS",
        "name": "Sapiens International Corporation N.V. Common Shares (Cayman Islands)",
        "country": "Curacao",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SPOK": {
        "symbol": "SPOK",
        "name": "Spok Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "SPPL": {
        "symbol": "SPPL",
        "name": "SIMPPLE LTD. Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "SPRB": {
        "symbol": "SPRB",
        "name": "Spruce Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SPRC": {
        "symbol": "SPRC",
        "name": "SciSparc Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SPRO": {
        "symbol": "SPRO",
        "name": "Spero Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SPRY": {
        "symbol": "SPRY",
        "name": "ARS Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SPSC": {
        "symbol": "SPSC",
        "name": "SPS Commerce Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SPT": {
        "symbol": "SPT",
        "name": "Sprout Social Inc Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SPTN": {
        "symbol": "SPTN",
        "name": "SpartanNash Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Food Distributors"
    },
    "SPWH": {
        "symbol": "SPWH",
        "name": "Sportsman's Warehouse Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "SQFT": {
        "symbol": "SQFT",
        "name": "Presidio Property Trust Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SQFTP": {
        "symbol": "SQFTP",
        "name": "Presidio Property Trust Inc. 9.375% Series D Cumulative Redeemable Perpetual Preferred Stock $0.01 par value per share",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SQFTW": {
        "symbol": "SQFTW",
        "name": "Presidio Property Trust Inc. Series A Common Stock Purchase Warrants",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SRAD": {
        "symbol": "SRAD",
        "name": "Sportradar Group AG Class A Ordinary Shares",
        "country": "Switzerland",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "SRBK": {
        "symbol": "SRBK",
        "name": "SR Bancorp Inc. Common stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "SRCE": {
        "symbol": "SRCE",
        "name": "1st Source Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SRCL": {
        "symbol": "SRCL",
        "name": "Stericycle Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Environmental Services"
    },
    "SRDX": {
        "symbol": "SRDX",
        "name": "Surmodics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SRM": {
        "symbol": "SRM",
        "name": "SRM Entertainment Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "SRPT": {
        "symbol": "SRPT",
        "name": "Sarepta Therapeutics Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SRRK": {
        "symbol": "SRRK",
        "name": "Scholar Rock Holding Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SRTS": {
        "symbol": "SRTS",
        "name": "Sensus Healthcare Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SRZN": {
        "symbol": "SRZN",
        "name": "Surrozen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SRZNW": {
        "symbol": "SRZNW",
        "name": "Surrozen Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SSBI": {
        "symbol": "SSBI",
        "name": "Summit State Bank Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SSBK": {
        "symbol": "SSBK",
        "name": "Southern States Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "SSIC": {
        "symbol": "SSIC",
        "name": "Silver Spike Investment Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SSKN": {
        "symbol": "SSKN",
        "name": "Strata Skin Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "SSNC": {
        "symbol": "SSNC",
        "name": "SS&C Technologies Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SSP": {
        "symbol": "SSP",
        "name": "E.W. Scripps Company (The) Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Broadcasting"
    },
    "SSRM": {
        "symbol": "SSRM",
        "name": "SSR Mining Inc. Common Stock",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Precious Metals"
    },
    "SSSS": {
        "symbol": "SSSS",
        "name": "SuRo Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SSSSL": {
        "symbol": "SSSSL",
        "name": "SuRo Capital Corp. 6.00% Notes due 2026",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "SSTI": {
        "symbol": "SSTI",
        "name": "SoundThinking Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SSYS": {
        "symbol": "SSYS",
        "name": "Stratasys Ltd. Ordinary Shares (Israel)",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "STAA": {
        "symbol": "STAA",
        "name": "STAAR Surgical Company Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Ophthalmic Goods"
    },
    "STAF": {
        "symbol": "STAF",
        "name": "Staffing 360 Solutions Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "STBA": {
        "symbol": "STBA",
        "name": "S&T Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "STBX": {
        "symbol": "STBX",
        "name": "Starbox Group Holdings Ltd. Class A Ordinary Shares",
        "country": "Malaysia",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "STCN": {
        "symbol": "STCN",
        "name": "Steel Connect Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "STEC": {
        "symbol": "STEC",
        "name": "Santech Holdings Limited American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "STEP": {
        "symbol": "STEP",
        "name": "StepStone Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "STER": {
        "symbol": "STER",
        "name": "Sterling Check Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "STGW": {
        "symbol": "STGW",
        "name": "Stagwell Inc. Class A Common Stock",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "STHO": {
        "symbol": "STHO",
        "name": "Star Holdings Shares of Beneficial Interest",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate"
    },
    "STI": {
        "symbol": "STI",
        "name": "Solidion Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "STIM": {
        "symbol": "STIM",
        "name": "Neuronetics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "STKH": {
        "symbol": "STKH",
        "name": "Steakholder Foods Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "STKL": {
        "symbol": "STKL",
        "name": "SunOpta Inc. Common Stock",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Farming/Seeds/Milling"
    },
    "STKS": {
        "symbol": "STKS",
        "name": "The ONE Group Hospitality Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "STLD": {
        "symbol": "STLD",
        "name": "Steel Dynamics Inc.",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "STNE": {
        "symbol": "STNE",
        "name": "StoneCo Ltd. Class A Common Shares",
        "country": "Brazil",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "STOK": {
        "symbol": "STOK",
        "name": "Stoke Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "STRA": {
        "symbol": "STRA",
        "name": "Strategic Education Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "STRL": {
        "symbol": "STRL",
        "name": "Sterling Infrastructure Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Military/Government/Technical"
    },
    "STRM": {
        "symbol": "STRM",
        "name": "Streamline Health Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "STRO": {
        "symbol": "STRO",
        "name": "Sutro Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "STRR": {
        "symbol": "STRR",
        "name": "Star Equity Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "STRRP": {
        "symbol": "STRRP",
        "name": "Star Equity Holdings Inc. Series A Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "STRS": {
        "symbol": "STRS",
        "name": "Stratus Properties Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Homebuilding"
    },
    "STRT": {
        "symbol": "STRT",
        "name": "STRATTEC SECURITY CORPORATION Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "STSS": {
        "symbol": "STSS",
        "name": "Sharps Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "STSSW": {
        "symbol": "STSSW",
        "name": "Sharps Technology Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "STTK": {
        "symbol": "STTK",
        "name": "Shattuck Labs Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "STX": {
        "symbol": "STX",
        "name": "Seagate Technology Holdings PLC Ordinary Shares (Ireland)",
        "country": "Ireland",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "SUGP": {
        "symbol": "SUGP",
        "name": "SU Group Holdings Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "SUNS": {
        "symbol": "SUNS",
        "name": "Sunrise Realty Trust Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SUPN": {
        "symbol": "SUPN",
        "name": "Supernus Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SURG": {
        "symbol": "SURG",
        "name": "SurgePays Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "SURGW": {
        "symbol": "SURGW",
        "name": "SurgePays Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "SUUN": {
        "symbol": "SUUN",
        "name": "SolarBank Corporation Common Stock",
        "country": "Canada",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "SVC": {
        "symbol": "SVC",
        "name": "Service Properties Trust Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "SVCO": {
        "symbol": "SVCO",
        "name": "Silvaco Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "SVII": {
        "symbol": "SVII",
        "name": "Spring Valley Acquisition Corp. II Class A Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "SVIIR": {
        "symbol": "SVIIR",
        "name": "Spring Valley Acquisition Corp. II Rights",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SVIIW": {
        "symbol": "SVIIW",
        "name": "Spring Valley Acquisition Corp. II Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "SVMH": {
        "symbol": "SVMH",
        "name": "SRIVARU Holding Limited Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "SVMHW": {
        "symbol": "SVMHW",
        "name": "SRIVARU Holding Limited Warrant",
        "country": "Cayman Islands",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "SVRA": {
        "symbol": "SVRA",
        "name": "Savara Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SVRE": {
        "symbol": "SVRE",
        "name": "SaverOne 2014 Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SVREW": {
        "symbol": "SVREW",
        "name": "SaverOne 2014 Ltd. Warrant",
        "country": "Israel",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SWAG": {
        "symbol": "SWAG",
        "name": "Stran & Company Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "SWAGW": {
        "symbol": "SWAGW",
        "name": "Stran & Company Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "SWBI": {
        "symbol": "SWBI",
        "name": "Smith & Wesson Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Ordnance And Accessories"
    },
    "SWIM": {
        "symbol": "SWIM",
        "name": "Latham Group Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Plastic Products"
    },
    "SWIN": {
        "symbol": "SWIN",
        "name": "Solowin Holdings Ordinary Share",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "SWKH": {
        "symbol": "SWKH",
        "name": "SWK Holdings Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Diversified Financial Services"
    },
    "SWKHL": {
        "symbol": "SWKHL",
        "name": "SWK Holdings Corporation 9.00% Senior Notes due 2027",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "SWKS": {
        "symbol": "SWKS",
        "name": "Skyworks Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SWTX": {
        "symbol": "SWTX",
        "name": "SpringWorks Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "SWVL": {
        "symbol": "SWVL",
        "name": "Swvl Holdings Corp Class A Common Shares",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SWVLW": {
        "symbol": "SWVLW",
        "name": "Swvl Holdings Corp Warrant",
        "country": "United Arab Emirates",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "SXTC": {
        "symbol": "SXTC",
        "name": "China SXT Pharmaceuticals Inc. Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SXTP": {
        "symbol": "SXTP",
        "name": "60 Degrees Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SXTPW": {
        "symbol": "SXTPW",
        "name": "60 Degrees Pharmaceuticals Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SY": {
        "symbol": "SY",
        "name": "So-Young International Inc. American Depository Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "SYBT": {
        "symbol": "SYBT",
        "name": "Stock Yards Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "SYBX": {
        "symbol": "SYBX",
        "name": "Synlogic Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SYM": {
        "symbol": "SYM",
        "name": "Symbotic Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "SYNA": {
        "symbol": "SYNA",
        "name": "Synaptics Incorporated Common Stock $0.001 Par Value",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "SYPR": {
        "symbol": "SYPR",
        "name": "Sypris Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "SYRA": {
        "symbol": "SYRA",
        "name": "Syra Health Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "SYRE": {
        "symbol": "SYRE",
        "name": "Spyre Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SYRS": {
        "symbol": "SYRS",
        "name": "Syros Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "SYT": {
        "symbol": "SYT",
        "name": "SYLA Technologies Co. Ltd. American Depositary Shares",
        "country": "Japan",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "SYTA": {
        "symbol": "SYTA",
        "name": "Siyata Mobile Inc. Common Shares",
        "country": "Canada",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "SYTAW": {
        "symbol": "SYTAW",
        "name": "Siyata Mobile Inc. Warrant",
        "country": "Canada",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "TACT": {
        "symbol": "TACT",
        "name": "TransAct Technologies Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "TAIT": {
        "symbol": "TAIT",
        "name": "Taitron Components Incorporated Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "TALK": {
        "symbol": "TALK",
        "name": "Talkspace Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "TALKW": {
        "symbol": "TALKW",
        "name": "Talkspace Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "TANH": {
        "symbol": "TANH",
        "name": "Tantech Holdings Ltd. Common Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "TAOP": {
        "symbol": "TAOP",
        "name": "Taoping Inc. Ordinary Shares ",
        "country": "Hong Kong",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "TARA": {
        "symbol": "TARA",
        "name": "Protara Therapeutics Inc.  Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TARS": {
        "symbol": "TARS",
        "name": "Tarsus Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TASK": {
        "symbol": "TASK",
        "name": "TaskUs Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TATT": {
        "symbol": "TATT",
        "name": "TAT Technologies Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Industrials",
        "industry": "Aerospace"
    },
    "TAYD": {
        "symbol": "TAYD",
        "name": "Taylor Devices Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "TBBK": {
        "symbol": "TBBK",
        "name": "The Bancorp Inc Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TBIO": {
        "symbol": "TBIO",
        "name": "Telesis Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "TBLA": {
        "symbol": "TBLA",
        "name": "Taboola.com Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TBLAW": {
        "symbol": "TBLAW",
        "name": "Taboola.com Ltd. Warrant",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TBLD": {
        "symbol": "TBLD",
        "name": "Thornburg Income Builder Opportunities Trust Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "TBMC": {
        "symbol": "TBMC",
        "name": "Trailblazer Merger Corporation I Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TBMCR": {
        "symbol": "TBMCR",
        "name": "Trailblazer Merger Corporation I Rights",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TBNK": {
        "symbol": "TBNK",
        "name": "Territorial Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "TBPH": {
        "symbol": "TBPH",
        "name": "Theravance Biopharma Inc. Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TBRG": {
        "symbol": "TBRG",
        "name": "TruBridge Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TC": {
        "symbol": "TC",
        "name": "TuanChe Limited American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "TCBI": {
        "symbol": "TCBI",
        "name": "Texas Capital Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TCBIO": {
        "symbol": "TCBIO",
        "name": "Texas Capital Bancshares Inc. Depositary Shares 5.75% Fixed Rate Non-Cumulative Perpetual Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TCBK": {
        "symbol": "TCBK",
        "name": "TriCo Bancshares Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TCBP": {
        "symbol": "TCBP",
        "name": "TC BioPharm (Holdings) plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TCBPW": {
        "symbol": "TCBPW",
        "name": "TC BioPharm (Holdings) plc Warrants",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TCBS": {
        "symbol": "TCBS",
        "name": "Texas Community Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "TCBX": {
        "symbol": "TCBX",
        "name": "Third Coast Bancshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "TCJH": {
        "symbol": "TCJH",
        "name": "Top KingWin Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "TCMD": {
        "symbol": "TCMD",
        "name": "Tactile Systems Technology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TCOM": {
        "symbol": "TCOM",
        "name": "Trip.com Group Limited American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "TCPC": {
        "symbol": "TCPC",
        "name": "BlackRock TCP Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "TCRT": {
        "symbol": "TCRT",
        "name": "Alaunos Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TCRX": {
        "symbol": "TCRX",
        "name": "TScan Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TCTM": {
        "symbol": "TCTM",
        "name": "TCTM Kids IT Education Inc. American Depositary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "TCX": {
        "symbol": "TCX",
        "name": "Tucows Inc. Class A Common Stock",
        "country": "Canada",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TDTH": {
        "symbol": "TDTH",
        "name": "Trident Digital Tech Holdings Ltd American Depository Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "TDUP": {
        "symbol": "TDUP",
        "name": "ThredUp Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "TEAM": {
        "symbol": "TEAM",
        "name": "Atlassian Corporation Class A Common Stock",
        "country": "Australia",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "TECH": {
        "symbol": "TECH",
        "name": "Bio-Techne Corp Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TECTP": {
        "symbol": "TECTP",
        "name": "Tectonic Financial Inc. 9.00% Fixed-to-Floating Rate Series B Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TECX": {
        "symbol": "TECX",
        "name": "Tectonic Therapeutic Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TELA": {
        "symbol": "TELA",
        "name": "TELA Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TELO": {
        "symbol": "TELO",
        "name": "Telomir Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TEM": {
        "symbol": "TEM",
        "name": "Tempus AI Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TENB": {
        "symbol": "TENB",
        "name": "Tenable Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "TENX": {
        "symbol": "TENX",
        "name": "Tenax Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TER": {
        "symbol": "TER",
        "name": "Teradyne Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "TERN": {
        "symbol": "TERN",
        "name": "Terns Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TETE": {
        "symbol": "TETE",
        "name": "Technology & Telecommunication Acquisition Corporation Class A Ordinary Shares",
        "country": "Malaysia",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TFFP": {
        "symbol": "TFFP",
        "name": "TFF Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TFIN": {
        "symbol": "TFIN",
        "name": "Triumph Financial Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TFINP": {
        "symbol": "TFINP",
        "name": "Triumph Financial Inc. Depositary Shares Each Representing a 1/40th Interest in a Share of 7.125% Series C Fixed-Rate Non-Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TFSL": {
        "symbol": "TFSL",
        "name": "TFS Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "TGAA": {
        "symbol": "TGAA",
        "name": "Target Global Acquisition I Corp. Class A Ordinary Share",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TGAAW": {
        "symbol": "TGAAW",
        "name": "Target Global Acquisition I Corp. Warrant",
        "country": "Cayman Islands",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TGL": {
        "symbol": "TGL",
        "name": "Treasure Global Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "TGTX": {
        "symbol": "TGTX",
        "name": "TG Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TH": {
        "symbol": "TH",
        "name": "Target Hospitality Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "THAR": {
        "symbol": "THAR",
        "name": "Tharimmune Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "THCH": {
        "symbol": "THCH",
        "name": "TH International Limited Ordinary shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "THCP": {
        "symbol": "THCP",
        "name": "Thunder Bridge Capital Partners IV Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Consumer Electronics/Appliances"
    },
    "THCPW": {
        "symbol": "THCPW",
        "name": "Thunder Bridge Capital Partners IV Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Consumer Electronics/Appliances"
    },
    "THFF": {
        "symbol": "THFF",
        "name": "First Financial Corporation Indiana Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "THRD": {
        "symbol": "THRD",
        "name": "Third Harmonic Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "THRM": {
        "symbol": "THRM",
        "name": "Gentherm Inc Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "THRY": {
        "symbol": "THRY",
        "name": "Thryv Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "THTX": {
        "symbol": "THTX",
        "name": "Theratechnologies Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TIGO": {
        "symbol": "TIGO",
        "name": "Millicom International Cellular S.A. Common Stock",
        "country": "Luxembourg",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "TIGR": {
        "symbol": "TIGR",
        "name": "UP Fintech Holding Ltd American Depositary Share representing fifteen Class A Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "TIL": {
        "symbol": "TIL",
        "name": "Instil Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TILE": {
        "symbol": "TILE",
        "name": "Interface Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "TIPT": {
        "symbol": "TIPT",
        "name": "Tiptree Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "TIRX": {
        "symbol": "TIRX",
        "name": "TIAN RUIXIANG Holdings Ltd Class A Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "TITN": {
        "symbol": "TITN",
        "name": "Titan Machinery Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "TIVC": {
        "symbol": "TIVC",
        "name": "Tivic Health Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "TKLF": {
        "symbol": "TKLF",
        "name": "Yoshitsu Co. Ltd American Depositary Shares",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "TKNO": {
        "symbol": "TKNO",
        "name": "Alpha Teknova Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "TLF": {
        "symbol": "TLF",
        "name": "Tandy Leather Factory Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "TLGYU": {
        "symbol": "TLGYU",
        "name": "TLGY Acquisition Corporation Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TLGYW": {
        "symbol": "TLGYW",
        "name": "TLGY Acquisition Corporation Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TLN": {
        "symbol": "TLN",
        "name": "Talen Energy Corporation Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "TLPH": {
        "symbol": "TLPH",
        "name": "Talphera Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TLRY": {
        "symbol": "TLRY",
        "name": "Tilray Brands Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "TLS": {
        "symbol": "TLS",
        "name": "Telos Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TLSA": {
        "symbol": "TLSA",
        "name": "Tiziana Life Sciences Ltd. Common Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TLSI": {
        "symbol": "TLSI",
        "name": "TriSalus Life Sciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "TLSIW": {
        "symbol": "TLSIW",
        "name": "TriSalus Life Sciences Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "TMC": {
        "symbol": "TMC",
        "name": "TMC the metals company Inc. Common Stock",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "TMCI": {
        "symbol": "TMCI",
        "name": "Treace Medical Concepts Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TMCWW": {
        "symbol": "TMCWW",
        "name": "TMC the metals company Inc. Warrants",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "TMDX": {
        "symbol": "TMDX",
        "name": "TransMedics Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "TMTC": {
        "symbol": "TMTC",
        "name": "TMT Acquisition Corp Ordinary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "TMUS": {
        "symbol": "TMUS",
        "name": "T-Mobile US Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "TNDM": {
        "symbol": "TNDM",
        "name": "Tandem Diabetes Care Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TNFA": {
        "symbol": "TNFA",
        "name": "TNF Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "TNGX": {
        "symbol": "TNGX",
        "name": "Tango Therapeutics Inc.",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TNON": {
        "symbol": "TNON",
        "name": "Tenon Medical Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TNONW": {
        "symbol": "TNONW",
        "name": "Tenon Medical Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TNXP": {
        "symbol": "TNXP",
        "name": "Tonix Pharmaceuticals Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TNYA": {
        "symbol": "TNYA",
        "name": "Tenaya Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TOI": {
        "symbol": "TOI",
        "name": "The Oncology Institute Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "TOIIW": {
        "symbol": "TOIIW",
        "name": "The Oncology Institute Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "TOMZ": {
        "symbol": "TOMZ",
        "name": "TOMI Environmental Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "TOP": {
        "symbol": "TOP",
        "name": "TOP Financial Group Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "TORO": {
        "symbol": "TORO",
        "name": "Toro Corp. Common Stock",
        "country": "Cyprus",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "TOUR": {
        "symbol": "TOUR",
        "name": "Tuniu Corporation American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "TOWN": {
        "symbol": "TOWN",
        "name": "TowneBank Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TOYO": {
        "symbol": "TOYO",
        "name": "TOYO Co. Ltd Ordinary Shares",
        "country": "Japan",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "TPCS": {
        "symbol": "TPCS",
        "name": "TechPrecision Corporation Common stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "TPG": {
        "symbol": "TPG",
        "name": "TPG Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "TPGXL": {
        "symbol": "TPGXL",
        "name": "TPG Operating Group II L.P. 6.950% Fixed-Rate Junior Subordinated Notes due 2064",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "TPIC": {
        "symbol": "TPIC",
        "name": "TPI Composites Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "TPST": {
        "symbol": "TPST",
        "name": "Tempest Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TRAW": {
        "symbol": "TRAW",
        "name": "Traws Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TRDA": {
        "symbol": "TRDA",
        "name": "Entrada Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TREE": {
        "symbol": "TREE",
        "name": "LendingTree Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "TRIB": {
        "symbol": "TRIB",
        "name": "Trinity Biotech plc American Depositary Shares",
        "country": "Ireland",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "TRIN": {
        "symbol": "TRIN",
        "name": "Trinity Capital Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "TRINI": {
        "symbol": "TRINI",
        "name": "Trinity Capital Inc. 7.875% Notes Due 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "TRINL": {
        "symbol": "TRINL",
        "name": "Trinity Capital Inc. 7.00% Notes Due 2025",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "TRINZ": {
        "symbol": "TRINZ",
        "name": "Trinity Capital Inc. 7.875% Notes due 2029",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "TRIP": {
        "symbol": "TRIP",
        "name": "TripAdvisor Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TRMB": {
        "symbol": "TRMB",
        "name": "Trimble Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "TRMD": {
        "symbol": "TRMD",
        "name": "TORM plc Class A Common Stock",
        "country": "United Kingdom",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "TRMK": {
        "symbol": "TRMK",
        "name": "Trustmark Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TRML": {
        "symbol": "TRML",
        "name": "Tourmaline Bio Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TRNR": {
        "symbol": "TRNR",
        "name": "Interactive Strength Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Consumer Electronics/Appliances"
    },
    "TRNS": {
        "symbol": "TRNS",
        "name": "Transcat Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Electrical Products"
    },
    "TROO": {
        "symbol": "TROO",
        "name": "TROOPS Inc. Ordinary Shares ",
        "country": "China",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "TROW": {
        "symbol": "TROW",
        "name": "T. Rowe Price Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "TRS": {
        "symbol": "TRS",
        "name": "TriMas Corporation Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "TRSG": {
        "symbol": "TRSG",
        "name": "Tungray Technologies Inc Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "TRST": {
        "symbol": "TRST",
        "name": "TrustCo Bank Corp NY Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "TRUE": {
        "symbol": "TRUE",
        "name": "TrueCar Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TRUG": {
        "symbol": "TRUG",
        "name": "TruGolf Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "TRUP": {
        "symbol": "TRUP",
        "name": "Trupanion Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "TRVG": {
        "symbol": "TRVG",
        "name": "trivago N.V. American Depositary Shares",
        "country": "Germany",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TRVI": {
        "symbol": "TRVI",
        "name": "Trevi Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TRVN": {
        "symbol": "TRVN",
        "name": "Trevena Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TSAT": {
        "symbol": "TSAT",
        "name": "Telesat Corporation Class A Common Shares and Class B Variable Voting Shares ",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "TSBK": {
        "symbol": "TSBK",
        "name": "Timberland Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "TSBX": {
        "symbol": "TSBX",
        "name": "Turnstone Biologics Corp. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TSCO": {
        "symbol": "TSCO",
        "name": "Tractor Supply Company Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "RETAIL: Building Materials"
    },
    "TSEM": {
        "symbol": "TSEM",
        "name": "Tower Semiconductor Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "TSHA": {
        "symbol": "TSHA",
        "name": "Taysha Gene Therapies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TSLA": {
        "symbol": "TSLA",
        "name": "Tesla Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "TSVT": {
        "symbol": "TSVT",
        "name": "2seventy bio Inc. Common Stock ",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TTD": {
        "symbol": "TTD",
        "name": "The Trade Desk Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "TTEC": {
        "symbol": "TTEC",
        "name": "TTEC Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "TTEK": {
        "symbol": "TTEK",
        "name": "Tetra Tech Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Military/Government/Technical"
    },
    "TTGT": {
        "symbol": "TTGT",
        "name": "TechTarget Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "TTMI": {
        "symbol": "TTMI",
        "name": "TTM Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electrical Products"
    },
    "TTNP": {
        "symbol": "TTNP",
        "name": "Titan Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TTOO": {
        "symbol": "TTOO",
        "name": "T2 Biosystems Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "TTSH": {
        "symbol": "TTSH",
        "name": "Tile Shop Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Home Furnishings"
    },
    "TTWO": {
        "symbol": "TTWO",
        "name": "Take-Two Interactive Software Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "TURB": {
        "symbol": "TURB",
        "name": "Turbo Energy S.A. American Depositary Shares",
        "country": "Spain",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "TURN": {
        "symbol": "TURN",
        "name": "180 Degree Capital Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance/Investors Services"
    },
    "TUSK": {
        "symbol": "TUSK",
        "name": "Mammoth Energy Services Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oilfield Services/Equipment"
    },
    "TVGN": {
        "symbol": "TVGN",
        "name": "Tevogen Bio Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TVGNW": {
        "symbol": "TVGNW",
        "name": "Tevogen Bio Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TVTX": {
        "symbol": "TVTX",
        "name": "Travere Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TW": {
        "symbol": "TW",
        "name": "Tradeweb Markets Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "TWFG": {
        "symbol": "TWFG",
        "name": "TWFG Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "TWG": {
        "symbol": "TWG",
        "name": "Top Wealth Group Holding Limited Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "TWIN": {
        "symbol": "TWIN",
        "name": "Twin Disc Incorporated Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "TWKS": {
        "symbol": "TWKS",
        "name": "Thoughtworks Holding Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "TWST": {
        "symbol": "TWST",
        "name": "Twist Bioscience Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "TXG": {
        "symbol": "TXG",
        "name": "10x Genomics Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Biotechnology: Laboratory Analytical Instruments"
    },
    "TXMD": {
        "symbol": "TXMD",
        "name": "TherapeuticsMD Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TXN": {
        "symbol": "TXN",
        "name": "Texas Instruments Incorporated Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "TXRH": {
        "symbol": "TXRH",
        "name": "Texas Roadhouse Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "TYGO": {
        "symbol": "TYGO",
        "name": "Tigo Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "TYRA": {
        "symbol": "TYRA",
        "name": "Tyra Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "TZOO": {
        "symbol": "TZOO",
        "name": "Travelzoo Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Advertising"
    },
    "UAL": {
        "symbol": "UAL",
        "name": "United Airlines Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "UBCP": {
        "symbol": "UBCP",
        "name": "United Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UBFO": {
        "symbol": "UBFO",
        "name": "United Security Bancshares Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UBSI": {
        "symbol": "UBSI",
        "name": "United Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UBX": {
        "symbol": "UBX",
        "name": "Unity Biotechnology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "UBXG": {
        "symbol": "UBXG",
        "name": "U-BX Technology Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "UCAR": {
        "symbol": "UCAR",
        "name": "U Power Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "UCL": {
        "symbol": "UCL",
        "name": "uCloudlink Group Inc. American Depositary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Telecommunications Equipment"
    },
    "UCTT": {
        "symbol": "UCTT",
        "name": "Ultra Clean Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "UDMY": {
        "symbol": "UDMY",
        "name": "Udemy Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "UEIC": {
        "symbol": "UEIC",
        "name": "Universal Electronics Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Consumer Electronics/Appliances"
    },
    "UFCS": {
        "symbol": "UFCS",
        "name": "United Fire Group Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Property-Casualty Insurers"
    },
    "UFPI": {
        "symbol": "UFPI",
        "name": "UFP Industries Inc. Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Forest Products"
    },
    "UFPT": {
        "symbol": "UFPT",
        "name": "UFP Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "UG": {
        "symbol": "UG",
        "name": "United-Guardian Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "UGRO": {
        "symbol": "UGRO",
        "name": "urban-gro Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "UHG": {
        "symbol": "UHG",
        "name": "United Homes Group Inc Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "UHGWW": {
        "symbol": "UHGWW",
        "name": "United Homes Group Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Homebuilding"
    },
    "UK": {
        "symbol": "UK",
        "name": "Ucommune International Ltd Ordinary Shares",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "UKOMW": {
        "symbol": "UKOMW",
        "name": "Ucommune International Ltd Warrant expiring 11/17/2025",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ULBI": {
        "symbol": "ULBI",
        "name": "Ultralife Corporation Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ULCC": {
        "symbol": "ULCC",
        "name": "Frontier Group Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Air Freight/Delivery Services"
    },
    "ULH": {
        "symbol": "ULH",
        "name": "Universal Logistics Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "ULTA": {
        "symbol": "ULTA",
        "name": "Ulta Beauty Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "ULY": {
        "symbol": "ULY",
        "name": "Urgent.ly Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "UMBF": {
        "symbol": "UMBF",
        "name": "UMB Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UNB": {
        "symbol": "UNB",
        "name": "Union Bankshares Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UNCY": {
        "symbol": "UNCY",
        "name": "Unicycive Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "UNIT": {
        "symbol": "UNIT",
        "name": "Uniti Group Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "UNTY": {
        "symbol": "UNTY",
        "name": "Unity Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UONE": {
        "symbol": "UONE",
        "name": "Urban One Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "UONEK": {
        "symbol": "UONEK",
        "name": "Urban One Inc. Class D Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Broadcasting"
    },
    "UPBD": {
        "symbol": "UPBD",
        "name": "Upbound Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "UPC": {
        "symbol": "UPC",
        "name": "Universe Pharmaceuticals Inc. Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "UPLD": {
        "symbol": "UPLD",
        "name": "Upland Software Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "UPST": {
        "symbol": "UPST",
        "name": "Upstart Holdings Inc. Common stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "UPWK": {
        "symbol": "UPWK",
        "name": "Upwork Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "UPXI": {
        "symbol": "UPXI",
        "name": "Upexi Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "URBN": {
        "symbol": "URBN",
        "name": "Urban Outfitters Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "URGN": {
        "symbol": "URGN",
        "name": "UroGen Pharma Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "UROY": {
        "symbol": "UROY",
        "name": "Uranium Royalty Corp. Common Stock",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "USAP": {
        "symbol": "USAP",
        "name": "Universal Stainless & Alloy Products Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Steel/Iron Ore"
    },
    "USAU": {
        "symbol": "USAU",
        "name": "U.S. Gold Corp. Common Stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Metal Mining"
    },
    "USCB": {
        "symbol": "USCB",
        "name": "USCB Financial Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "USEA": {
        "symbol": "USEA",
        "name": "United Maritime Corporation Common Stock",
        "country": "Greece",
        "sector": "Consumer Discretionary",
        "industry": "Marine Transportation"
    },
    "USEG": {
        "symbol": "USEG",
        "name": "U.S. Energy Corp. Common Stock (DE)",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "USGO": {
        "symbol": "USGO",
        "name": "U.S. GoldMining Inc. Common stock",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "USGOW": {
        "symbol": "USGOW",
        "name": "U.S. GoldMining Inc. Warrant",
        "country": "United States",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "USIO": {
        "symbol": "USIO",
        "name": "Usio Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "USLM": {
        "symbol": "USLM",
        "name": "United States Lime & Minerals Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Mining & Quarrying of Nonmetallic Minerals (No Fuels)"
    },
    "UTHR": {
        "symbol": "UTHR",
        "name": "United Therapeutics Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "UTMD": {
        "symbol": "UTMD",
        "name": "Utah Medical Products Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "UTSI": {
        "symbol": "UTSI",
        "name": "UTStarcom Holdings Corp. Ordinary Shares",
        "country": "China",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "UVSP": {
        "symbol": "UVSP",
        "name": "Univest Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "UXIN": {
        "symbol": "UXIN",
        "name": "Uxin Limited ADS",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "VABK": {
        "symbol": "VABK",
        "name": "Virginia National Bankshares Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VACHU": {
        "symbol": "VACHU",
        "name": "Voyager Acquisition Corp Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "VALN": {
        "symbol": "VALN",
        "name": "Valneva SE American Depositary Shares",
        "country": "France",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VALU": {
        "symbol": "VALU",
        "name": "Value Line Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "VANI": {
        "symbol": "VANI",
        "name": "Vivani Medical Inc. Common Stock (DE)",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "VBFC": {
        "symbol": "VBFC",
        "name": "Village Bank and Trust Financial Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VBNK": {
        "symbol": "VBNK",
        "name": "VersaBank Common Shares",
        "country": "Canada",
        "sector": "Finance",
        "industry": "Commercial Banks"
    },
    "VBTX": {
        "symbol": "VBTX",
        "name": "Veritex Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VC": {
        "symbol": "VC",
        "name": "Visteon Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "VCEL": {
        "symbol": "VCEL",
        "name": "Vericel Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VCICU": {
        "symbol": "VCICU",
        "name": "Vine Hill Capital Investment Corp. Unit",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "VCIG": {
        "symbol": "VCIG",
        "name": "VCI Global Limited Ordinary Share",
        "country": "Malaysia",
        "sector": "Consumer Discretionary",
        "industry": "Diversified Commercial Services"
    },
    "VCNX": {
        "symbol": "VCNX",
        "name": "Vaccinex Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VCSA": {
        "symbol": "VCSA",
        "name": "Vacasa Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Diversified Commercial Services"
    },
    "VCTR": {
        "symbol": "VCTR",
        "name": "Victory Capital Holdings Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "VCYT": {
        "symbol": "VCYT",
        "name": "Veracyte Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "VECO": {
        "symbol": "VECO",
        "name": "Veeco Instruments Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "VEEA": {
        "symbol": "VEEA",
        "name": "Veea Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "VEEAW": {
        "symbol": "VEEAW",
        "name": "Veea Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "VEEE": {
        "symbol": "VEEE",
        "name": "Twin Vee PowerCats Co. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Marine Transportation"
    },
    "VEON": {
        "symbol": "VEON",
        "name": "VEON Ltd. ADS",
        "country": "Netherlands",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "VERA": {
        "symbol": "VERA",
        "name": "Vera Therapeutics Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VERB": {
        "symbol": "VERB",
        "name": "Verb Technology Company Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "VERI": {
        "symbol": "VERI",
        "name": "Veritone Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VERO": {
        "symbol": "VERO",
        "name": "Venus Concept Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "VERU": {
        "symbol": "VERU",
        "name": "Veru Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VERV": {
        "symbol": "VERV",
        "name": "Verve Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VERX": {
        "symbol": "VERX",
        "name": "Vertex Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "VEV": {
        "symbol": "VEV",
        "name": "Vicinity Motor Corp. Common Stock",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "VFF": {
        "symbol": "VFF",
        "name": "Village Farms International Inc. Common Shares",
        "country": "Canada",
        "sector": "",
        "industry": ""
    },
    "VFS": {
        "symbol": "VFS",
        "name": "VinFast Auto Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "VFSWW": {
        "symbol": "VFSWW",
        "name": "VinFast Auto Ltd. Warrant",
        "country": "Singapore",
        "sector": "",
        "industry": ""
    },
    "VGAS": {
        "symbol": "VGAS",
        "name": "Verde Clean Fuels Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "VGASW": {
        "symbol": "VGASW",
        "name": "Verde Clean Fuels Inc. Warrant",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "VIASP": {
        "symbol": "VIASP",
        "name": "Via Renewables Inc. 8.75% Series A Fixed-to-Floating Rate Cumulative Redeemable Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "VIAV": {
        "symbol": "VIAV",
        "name": "Viavi Solutions Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "VICR": {
        "symbol": "VICR",
        "name": "Vicor Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "VIGL": {
        "symbol": "VIGL",
        "name": "Vigil Neuroscience Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VINC": {
        "symbol": "VINC",
        "name": "Vincerx Pharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VINO": {
        "symbol": "VINO",
        "name": "Gaucho Group Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Homebuilding"
    },
    "VINP": {
        "symbol": "VINP",
        "name": "Vinci Partners Investments Ltd. Class A Common Shares",
        "country": "Brazil",
        "sector": "Finance",
        "industry": "Investment Managers"
    },
    "VIOT": {
        "symbol": "VIOT",
        "name": "Viomi Technology Co. Ltd American Depositary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Consumer Electronics/Appliances"
    },
    "VIR": {
        "symbol": "VIR",
        "name": "Vir Biotechnology Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VIRC": {
        "symbol": "VIRC",
        "name": "Virco Manufacturing Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "VIRI": {
        "symbol": "VIRI",
        "name": "Virios Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VIRT": {
        "symbol": "VIRT",
        "name": "Virtu Financial Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "VIRX": {
        "symbol": "VIRX",
        "name": "Viracta Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VISL": {
        "symbol": "VISL",
        "name": "Vislink Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "VITL": {
        "symbol": "VITL",
        "name": "Vital Farms Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Packaged Foods"
    },
    "VIVK": {
        "symbol": "VIVK",
        "name": "Vivakor Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Environmental Services"
    },
    "VKTX": {
        "symbol": "VKTX",
        "name": "Viking Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VLCN": {
        "symbol": "VLCN",
        "name": "Volcon Inc. Common stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "VLGEA": {
        "symbol": "VLGEA",
        "name": "Village Super Market Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Food Chains"
    },
    "VLY": {
        "symbol": "VLY",
        "name": "Valley National Bancorp Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VLYPN": {
        "symbol": "VLYPN",
        "name": "Valley National Bancorp 8.250% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series C",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "VLYPO": {
        "symbol": "VLYPO",
        "name": "Valley National Bancorp 5.50% Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock Series B",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VLYPP": {
        "symbol": "VLYPP",
        "name": "Valley National Bancorp 6.25% Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "VMAR": {
        "symbol": "VMAR",
        "name": "Vision Marine Technologies Inc. Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Marine Transportation"
    },
    "VMD": {
        "symbol": "VMD",
        "name": "Viemed Healthcare Inc. Common Shares",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Misc Health and Biotechnology Services"
    },
    "VMEO": {
        "symbol": "VMEO",
        "name": "Vimeo Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "VNDA": {
        "symbol": "VNDA",
        "name": "Vanda Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VNET": {
        "symbol": "VNET",
        "name": "VNET Group Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "VNOM": {
        "symbol": "VNOM",
        "name": "Viper Energy Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Oil & Gas Production"
    },
    "VOD": {
        "symbol": "VOD",
        "name": "Vodafone Group Plc American Depositary Shares",
        "country": "United Kingdom",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "VOR": {
        "symbol": "VOR",
        "name": "Vor Biopharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VOXR": {
        "symbol": "VOXR",
        "name": "Vox Royalty Corp. Common Stock",
        "country": "Canada",
        "sector": "Basic Materials",
        "industry": "Precious Metals"
    },
    "VOXX": {
        "symbol": "VOXX",
        "name": "VOXX International Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "VRA": {
        "symbol": "VRA",
        "name": "Vera Bradley Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Apparel"
    },
    "VRAR": {
        "symbol": "VRAR",
        "name": "The Glimpse Group Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRAX": {
        "symbol": "VRAX",
        "name": "Virax Biolabs Group Limited Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: In Vitro & In Vivo Diagnostic Substances"
    },
    "VRCA": {
        "symbol": "VRCA",
        "name": "Verrica Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VRDN": {
        "symbol": "VRDN",
        "name": "Viridian Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "VREX": {
        "symbol": "VREX",
        "name": "Varex Imaging Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "VRM": {
        "symbol": "VRM",
        "name": "Vroom Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Retail-Auto Dealers and Gas Stations"
    },
    "VRME": {
        "symbol": "VRME",
        "name": "VerifyMe Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRMEW": {
        "symbol": "VRMEW",
        "name": "VerifyMe Inc. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRNA": {
        "symbol": "VRNA",
        "name": "Verona Pharma plc American Depositary Share",
        "country": "United Kingdom",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VRNS": {
        "symbol": "VRNS",
        "name": "Varonis Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "VRNT": {
        "symbol": "VRNT",
        "name": "Verint Systems Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRPX": {
        "symbol": "VRPX",
        "name": "Virpax Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VRRM": {
        "symbol": "VRRM",
        "name": "Verra Mobility Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "VRSK": {
        "symbol": "VRSK",
        "name": "Verisk Analytics Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRSN": {
        "symbol": "VRSN",
        "name": "VeriSign Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VRTX": {
        "symbol": "VRTX",
        "name": "Vertex Pharmaceuticals Incorporated Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VS": {
        "symbol": "VS",
        "name": "Versus Systems Inc. Common Shares",
        "country": "Canada",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VSAT": {
        "symbol": "VSAT",
        "name": "ViaSat Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "VSEC": {
        "symbol": "VSEC",
        "name": "VSE Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Military/Government/Technical"
    },
    "VSEE": {
        "symbol": "VSEE",
        "name": "VSee Health Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "VSEEW": {
        "symbol": "VSEEW",
        "name": "VSee Health Inc. Warrant",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Nursing Services"
    },
    "VSME": {
        "symbol": "VSME",
        "name": "VS Media Holdings Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Publishing"
    },
    "VSSYW": {
        "symbol": "VSSYW",
        "name": "Versus Systems Inc. Class A Warrants",
        "country": "Canada",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "VSTA": {
        "symbol": "VSTA",
        "name": "Vasta Platform Limited Class A Ordinary Shares",
        "country": "Brazil",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "VSTE": {
        "symbol": "VSTE",
        "name": "Vast Renewables Limited Ordinary Shares",
        "country": "Australia",
        "sector": "Industrials",
        "industry": "Building Products"
    },
    "VSTEW": {
        "symbol": "VSTEW",
        "name": "Vast Renewables Limited Warrants",
        "country": "Australia",
        "sector": "Industrials",
        "industry": "Building Products"
    },
    "VSTM": {
        "symbol": "VSTM",
        "name": "Verastem Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VTGN": {
        "symbol": "VTGN",
        "name": "Vistagen Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VTNR": {
        "symbol": "VTNR",
        "name": "Vertex Energy Inc Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Integrated oil Companies"
    },
    "VTRS": {
        "symbol": "VTRS",
        "name": "Viatris Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VTSI": {
        "symbol": "VTSI",
        "name": "VirTra Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Miscellaneous manufacturing industries"
    },
    "VTVT": {
        "symbol": "VTVT",
        "name": "vTv Therapeutics Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VTYX": {
        "symbol": "VTYX",
        "name": "Ventyx Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "VUZI": {
        "symbol": "VUZI",
        "name": "Vuzix Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "VVOS": {
        "symbol": "VVOS",
        "name": "Vivos Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "VVPR": {
        "symbol": "VVPR",
        "name": "VivoPower International PLC Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "VXRT": {
        "symbol": "VXRT",
        "name": "Vaxart Inc Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VYGR": {
        "symbol": "VYGR",
        "name": "Voyager Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "VYNE": {
        "symbol": "VYNE",
        "name": "VYNE Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "WABC": {
        "symbol": "WABC",
        "name": "Westamerica Bancorporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WAFD": {
        "symbol": "WAFD",
        "name": "WaFd Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WAFDP": {
        "symbol": "WAFDP",
        "name": "WaFd Inc. Depositary Shares",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WAFU": {
        "symbol": "WAFU",
        "name": "Wah Fu Education Group Limited Ordinary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "WALD": {
        "symbol": "WALD",
        "name": "Waldencast plc Class A Ordinary Share",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Package Goods/Cosmetics"
    },
    "WASH": {
        "symbol": "WASH",
        "name": "Washington Trust Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WATT": {
        "symbol": "WATT",
        "name": "Energous Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Radio And Television Broadcasting And Communications Equipment"
    },
    "WAVE": {
        "symbol": "WAVE",
        "name": "Eco Wave Power Global AB (publ) American Depositary Shares",
        "country": "Israel",
        "sector": "Utilities",
        "industry": "Electric Utilities: Central"
    },
    "WAVS": {
        "symbol": "WAVS",
        "name": "Western Acquisition Ventures Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "WAY": {
        "symbol": "WAY",
        "name": "Waystar Holding Corp. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "WB": {
        "symbol": "WB",
        "name": "Weibo Corporation American Depositary Share",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "WBA": {
        "symbol": "WBA",
        "name": "Walgreens Boots Alliance Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Retail-Drug Stores and Proprietary Stores"
    },
    "WBD": {
        "symbol": "WBD",
        "name": "Warner Bros. Discovery Inc. Series A Common Stock ",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Cable & Other Pay Television Services"
    },
    "WBTN": {
        "symbol": "WBTN",
        "name": "WEBTOON Entertainment Inc. Common stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Publishing"
    },
    "WBUY": {
        "symbol": "WBUY",
        "name": "WEBUY GLOBAL LTD. Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "WDAY": {
        "symbol": "WDAY",
        "name": "Workday Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "WDC": {
        "symbol": "WDC",
        "name": "Western Digital Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Electronic Components"
    },
    "WDFC": {
        "symbol": "WDFC",
        "name": "WD-40 Company Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "WEN": {
        "symbol": "WEN",
        "name": "Wendy's Company (The) Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "WERN": {
        "symbol": "WERN",
        "name": "Werner Enterprises Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Trucking Freight/Courier Services"
    },
    "WEST": {
        "symbol": "WEST",
        "name": "Westrock Coffee Company Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "WESTW": {
        "symbol": "WESTW",
        "name": "Westrock Coffee Company Warrants",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "WETH": {
        "symbol": "WETH",
        "name": "Wetouch Technology Inc. Common Stock",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "WEYS": {
        "symbol": "WEYS",
        "name": "Weyco Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Apparel"
    },
    "WFCF": {
        "symbol": "WFCF",
        "name": "Where Food Comes From Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "WFRD": {
        "symbol": "WFRD",
        "name": "Weatherford International plc Ordinary Shares",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "WGS": {
        "symbol": "WGS",
        "name": "GeneDx Holdings Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Retail: Computer Software & Peripheral Equipment"
    },
    "WGSWW": {
        "symbol": "WGSWW",
        "name": "GeneDx Holdings Corp. Warrant",
        "country": "United States",
        "sector": "Technology",
        "industry": "Retail: Computer Software & Peripheral Equipment"
    },
    "WHF": {
        "symbol": "WHF",
        "name": "WhiteHorse Finance Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance Companies"
    },
    "WHFCL": {
        "symbol": "WHFCL",
        "name": "WhiteHorse Finance Inc. 7.875% Notes due 2028",
        "country": "United States",
        "sector": "",
        "industry": ""
    },
    "WHLM": {
        "symbol": "WHLM",
        "name": "Wilhelmina International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "WHLR": {
        "symbol": "WHLR",
        "name": "Wheeler Real Estate Investment Trust Inc. Common Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "WHLRD": {
        "symbol": "WHLRD",
        "name": "Wheeler Real Estate Investment Trust Inc. Series D Cumulative Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "WHLRL": {
        "symbol": "WHLRL",
        "name": "Wheeler Real Estate Investment Trust Inc. 7.00% Senior Subordinated Convertible Notes Due 2031",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "WHLRP": {
        "symbol": "WHLRP",
        "name": "Wheeler Real Estate Investment Trust Inc. Class B Preferred Stock",
        "country": "United States",
        "sector": "Real Estate",
        "industry": "Real Estate Investment Trusts"
    },
    "WILC": {
        "symbol": "WILC",
        "name": "G. Willi-Food International  Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Consumer Discretionary",
        "industry": "Food Distributors"
    },
    "WIMI": {
        "symbol": "WIMI",
        "name": "WiMi Hologram Cloud Inc. American Depositary Share",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "WINA": {
        "symbol": "WINA",
        "name": "Winmark Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "WING": {
        "symbol": "WING",
        "name": "Wingstop Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "WINT": {
        "symbol": "WINT",
        "name": "Windtree Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "WINV": {
        "symbol": "WINV",
        "name": "WinVest Acquisition Corp. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "WINVR": {
        "symbol": "WINVR",
        "name": "WinVest Acquisition Corp. Right",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "WISA": {
        "symbol": "WISA",
        "name": "WiSA Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Semiconductors"
    },
    "WIX": {
        "symbol": "WIX",
        "name": "Wix.com Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "WKEY": {
        "symbol": "WKEY",
        "name": "WISeKey International Holding Ltd American Depositary Shares",
        "country": "Switzerland",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "WKHS": {
        "symbol": "WKHS",
        "name": "Workhorse Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Manufacturing"
    },
    "WKSP": {
        "symbol": "WKSP",
        "name": "Worksport Ltd. Common Stock",
        "country": "Canada",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "WLDN": {
        "symbol": "WLDN",
        "name": "Willdan Group Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Military/Government/Technical"
    },
    "WLDS": {
        "symbol": "WLDS",
        "name": "Wearable Devices Ltd. Ordinary Share",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "WLDSW": {
        "symbol": "WLDSW",
        "name": "Wearable Devices Ltd. Warrant",
        "country": "Israel",
        "sector": "Telecommunications",
        "industry": "Computer Communications Equipment"
    },
    "WLFC": {
        "symbol": "WLFC",
        "name": "Willis Lease Finance Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Industrial Specialties"
    },
    "WLGS": {
        "symbol": "WLGS",
        "name": "Wang & Lee Group Inc. Ordinary Shares",
        "country": "Hong Kong",
        "sector": "Consumer Discretionary",
        "industry": "Engineering & Construction"
    },
    "WMG": {
        "symbol": "WMG",
        "name": "Warner Music Group Corp. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "WMPN": {
        "symbol": "WMPN",
        "name": "William Penn Bancorporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Banks"
    },
    "WNEB": {
        "symbol": "WNEB",
        "name": "Western New England Bancorp Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "WNW": {
        "symbol": "WNW",
        "name": "Meiwu Technology Company Limited Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Catalog/Specialty Distribution"
    },
    "WOK": {
        "symbol": "WOK",
        "name": "WORK Medical Technology Group LTD Ordinary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "WOOF": {
        "symbol": "WOOF",
        "name": "Petco Health and Wellness Company Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "WORX": {
        "symbol": "WORX",
        "name": "SCWorx Corp. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Services-Misc. Amusement & Recreation"
    },
    "WPRT": {
        "symbol": "WPRT",
        "name": "Westport Fuel Systems Inc Common Shares",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "WRAP": {
        "symbol": "WRAP",
        "name": "Wrap Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Ordnance And Accessories"
    },
    "WRLD": {
        "symbol": "WRLD",
        "name": "World Acceptance Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Finance: Consumer Services"
    },
    "WSBC": {
        "symbol": "WSBC",
        "name": "WesBanco Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WSBCP": {
        "symbol": "WSBCP",
        "name": "WesBanco Inc. Depositary Shares Each Representing a 1/40th Interest in a Share of 6.75% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series A",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WSBF": {
        "symbol": "WSBF",
        "name": "Waterstone Financial Inc. Common Stock (MD)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Savings Institutions"
    },
    "WSC": {
        "symbol": "WSC",
        "name": "WillScot Holdings Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Misc Corporate Leasing Services"
    },
    "WSFS": {
        "symbol": "WSFS",
        "name": "WSFS Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WTBA": {
        "symbol": "WTBA",
        "name": "West Bancorporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WTFC": {
        "symbol": "WTFC",
        "name": "Wintrust Financial Corporation Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WTFCM": {
        "symbol": "WTFCM",
        "name": "Wintrust Financial Corporation Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock Series D",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WTFCP": {
        "symbol": "WTFCP",
        "name": "Wintrust Financial Corporation Depositary Shares Each Representing a 1/1000th Interest in a Share of 6.875% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock Series E",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "WTMAR": {
        "symbol": "WTMAR",
        "name": "Welsbach Technology Metals Acquisition Corp. one right to receive 1/10th of a share of common stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "WTO": {
        "symbol": "WTO",
        "name": "UTime Limited Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Consumer Electronics/Appliances"
    },
    "WTW": {
        "symbol": "WTW",
        "name": "Willis Towers Watson Public Limited Company Ordinary Shares",
        "country": "United Kingdom",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "WULF": {
        "symbol": "WULF",
        "name": "TeraWulf Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "WVE": {
        "symbol": "WVE",
        "name": "Wave Life Sciences Ltd. Ordinary Shares",
        "country": "Singapore",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "WVVI": {
        "symbol": "WVVI",
        "name": "Willamette Valley Vineyards Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "WVVIP": {
        "symbol": "WVVIP",
        "name": "Willamette Valley Vineyards Inc. Series A Redeemable Preferred Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Beverages (Production/Distribution)"
    },
    "WW": {
        "symbol": "WW",
        "name": "WW International Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "WWD": {
        "symbol": "WWD",
        "name": "Woodward Inc. Common Stock",
        "country": "United States",
        "sector": "Energy",
        "industry": "Industrial Machinery/Components"
    },
    "WYNN": {
        "symbol": "WYNN",
        "name": "Wynn Resorts Limited Common stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Hotels/Resorts"
    },
    "XAIR": {
        "symbol": "XAIR",
        "name": "Beyond Air Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "XBIO": {
        "symbol": "XBIO",
        "name": "Xenetic Biosciences Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XBIT": {
        "symbol": "XBIT",
        "name": "XBiotech Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XBP": {
        "symbol": "XBP",
        "name": "XBP Europe Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "XBPEW": {
        "symbol": "XBPEW",
        "name": "XBP Europe Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "XCH": {
        "symbol": "XCH",
        "name": "XCHG Limited American Depositary Share",
        "country": "Germany",
        "sector": "",
        "industry": ""
    },
    "XCUR": {
        "symbol": "XCUR",
        "name": "Exicure Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XEL": {
        "symbol": "XEL",
        "name": "Xcel Energy Inc. Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Power Generation"
    },
    "XELA": {
        "symbol": "XELA",
        "name": "Exela Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "XELAP": {
        "symbol": "XELAP",
        "name": "Exela Technologies Inc. 6.00% Series B Cumulative Convertible Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "XELB": {
        "symbol": "XELB",
        "name": "Xcel Brands Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Multi-Sector Companies"
    },
    "XENE": {
        "symbol": "XENE",
        "name": "Xenon Pharmaceuticals Inc. Common Shares",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XERS": {
        "symbol": "XERS",
        "name": "Xeris Biopharma Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XFIN": {
        "symbol": "XFIN",
        "name": "ExcelFin Acquisition Corp Class A Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "XFINU": {
        "symbol": "XFINU",
        "name": "ExcelFin Acquisition Corp Unit",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "XFINW": {
        "symbol": "XFINW",
        "name": "ExcelFin Acquisition Corp Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "XFOR": {
        "symbol": "XFOR",
        "name": "X4 Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "XGN": {
        "symbol": "XGN",
        "name": "Exagen Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical Specialities"
    },
    "XHG": {
        "symbol": "XHG",
        "name": "XChange TEC.INC American Depositary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Real Estate"
    },
    "XLO": {
        "symbol": "XLO",
        "name": "Xilio Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XMTR": {
        "symbol": "XMTR",
        "name": "Xometry Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "XNCR": {
        "symbol": "XNCR",
        "name": "Xencor Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XNET": {
        "symbol": "XNET",
        "name": "Xunlei Limited American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "XOMA": {
        "symbol": "XOMA",
        "name": "XOMA Royalty Corporation Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XOMAO": {
        "symbol": "XOMAO",
        "name": "XOMA Royalty Corporation Depositary Shares Rep Series B 8.375% Cumulative Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XOMAP": {
        "symbol": "XOMAP",
        "name": "XOMA Royalty Corporation 8.625% Series A Cumulative Perpetual Preferred Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XOS": {
        "symbol": "XOS",
        "name": "Xos Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "XOSWW": {
        "symbol": "XOSWW",
        "name": "Xos Inc. Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Auto Parts:O.E.M."
    },
    "XP": {
        "symbol": "XP",
        "name": "XP Inc. Class A Common Stock",
        "country": "Brazil",
        "sector": "Finance",
        "industry": "Investment Bankers/Brokers/Service"
    },
    "XPEL": {
        "symbol": "XPEL",
        "name": "XPEL Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Specialties"
    },
    "XPON": {
        "symbol": "XPON",
        "name": "Expion360 Inc. Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "XRAY": {
        "symbol": "XRAY",
        "name": "DENTSPLY SIRONA Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "XRTX": {
        "symbol": "XRTX",
        "name": "XORTX Therapeutics Inc. Common Stock",
        "country": "Canada",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XRX": {
        "symbol": "XRX",
        "name": "Xerox Holdings Corporation Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer peripheral equipment"
    },
    "XTIA": {
        "symbol": "XTIA",
        "name": "XTI Aerospace Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "XTKG": {
        "symbol": "XTKG",
        "name": "X3 Holdings Co. Ltd. Ordinary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "XTLB": {
        "symbol": "XTLB",
        "name": "XTL Biopharmaceuticals Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "XWEL": {
        "symbol": "XWEL",
        "name": "XWELL Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Other Consumer Services"
    },
    "XXII": {
        "symbol": "XXII",
        "name": "22nd Century Group Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": " Medicinal Chemicals and Botanical Products "
    },
    "XYLO": {
        "symbol": "XYLO",
        "name": "Xylo Technologies Ltd. American Depositary Shares",
        "country": "Israel",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "YGMZ": {
        "symbol": "YGMZ",
        "name": "MingZhu Logistics Holdings Limited Ordinary Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "YHGJ": {
        "symbol": "YHGJ",
        "name": "Yunhong Green CTI Ltd. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Specialty Chemicals"
    },
    "YHNAU": {
        "symbol": "YHNAU",
        "name": "YHN Acquisition I Limited Unit",
        "country": "Hong Kong",
        "sector": "",
        "industry": ""
    },
    "YI": {
        "symbol": "YI",
        "name": "111 Inc. American Depositary Shares",
        "country": "China",
        "sector": "Consumer Staples",
        "industry": "Retail-Drug Stores and Proprietary Stores"
    },
    "YIBO": {
        "symbol": "YIBO",
        "name": "Planet Image International Limited Class A Ordinary Shares",
        "country": "China",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "YJ": {
        "symbol": "YJ",
        "name": "Yunji Inc. American Depository Shares",
        "country": "China",
        "sector": "Consumer Discretionary",
        "industry": "Other Specialty Stores"
    },
    "YMAB": {
        "symbol": "YMAB",
        "name": "Y-mAbs Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "YORW": {
        "symbol": "YORW",
        "name": "York Water Company (The) Common Stock",
        "country": "United States",
        "sector": "Utilities",
        "industry": "Water Supply"
    },
    "YOSH": {
        "symbol": "YOSH",
        "name": "Yoshiharu Global Co. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Restaurants"
    },
    "YOTA": {
        "symbol": "YOTA",
        "name": "Yotta Acquisition Corporation Common Stock",
        "country": "United States",
        "sector": "Consumer Staples",
        "industry": "Farming/Seeds/Milling"
    },
    "YQ": {
        "symbol": "YQ",
        "name": "17 Education & Technology Group Inc. American Depositary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "YTRA": {
        "symbol": "YTRA",
        "name": "Yatra Online Inc. Ordinary Shares",
        "country": "India",
        "sector": "Consumer Discretionary",
        "industry": "Transportation Services"
    },
    "YXT": {
        "symbol": "YXT",
        "name": "YXT.COM GROUP HOLDING LIMITED American Depository Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "YY": {
        "symbol": "YY",
        "name": "JOYY Inc. American Depositary Shares",
        "country": "China",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "YYAI": {
        "symbol": "YYAI",
        "name": "Connexa Sports Technologies Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Recreational Games/Products/Toys"
    },
    "YYGH": {
        "symbol": "YYGH",
        "name": "YY Group Holding Limited Class A Ordinary Shares",
        "country": "Singapore",
        "sector": "Consumer Discretionary",
        "industry": "Professional Services"
    },
    "Z": {
        "symbol": "Z",
        "name": "Zillow Group Inc. Class C Capital Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ZAPP": {
        "symbol": "ZAPP",
        "name": "Zapp Electric Vehicles Group Limited Ordinary Shares",
        "country": "Thailand",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "ZAPPW": {
        "symbol": "ZAPPW",
        "name": "Zapp Electric Vehicles Group Limited Warrant",
        "country": "Thailand",
        "sector": "Consumer Discretionary",
        "industry": "Motor Vehicles"
    },
    "ZBAO": {
        "symbol": "ZBAO",
        "name": "Zhibao Technology Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Finance",
        "industry": "Specialty Insurers"
    },
    "ZBIO": {
        "symbol": "ZBIO",
        "name": "Zenas BioPharma Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZBRA": {
        "symbol": "ZBRA",
        "name": "Zebra Technologies Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Industrial Machinery/Components"
    },
    "ZCAR": {
        "symbol": "ZCAR",
        "name": "Zoomcar Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Rental/Leasing Companies"
    },
    "ZCARW": {
        "symbol": "ZCARW",
        "name": "Zoomcar Holdings Inc. Warrants",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Rental/Leasing Companies"
    },
    "ZCMD": {
        "symbol": "ZCMD",
        "name": "Zhongchao Inc. Class A Ordinary Shares",
        "country": "China",
        "sector": "Real Estate",
        "industry": "Other Consumer Services"
    },
    "ZD": {
        "symbol": "ZD",
        "name": "Ziff Davis Inc. Common Stock",
        "country": "United States",
        "sector": "Telecommunications",
        "industry": "Telecommunications Equipment"
    },
    "ZENV": {
        "symbol": "ZENV",
        "name": "Zenvia Inc. Class A Common Stock",
        "country": "Brazil",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ZEO": {
        "symbol": "ZEO",
        "name": "Zeo Energy Corporation Class A Common Stock",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ZEOWW": {
        "symbol": "ZEOWW",
        "name": "Zeo Energy Corporation Warrants",
        "country": "United States",
        "sector": "Miscellaneous",
        "industry": "Industrial Machinery/Components"
    },
    "ZEUS": {
        "symbol": "ZEUS",
        "name": "Olympic Steel Inc. Common Stock",
        "country": "United States",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "ZG": {
        "symbol": "ZG",
        "name": "Zillow Group Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Business Services"
    },
    "ZI": {
        "symbol": "ZI",
        "name": "ZoomInfo Technologies Inc Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Prepackaged Software"
    },
    "ZIMV": {
        "symbol": "ZIMV",
        "name": "ZimVie Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Medical/Dental Instruments"
    },
    "ZION": {
        "symbol": "ZION",
        "name": "Zions Bancorporation N.A. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ZIONL": {
        "symbol": "ZIONL",
        "name": "Zions Bancorporation 6.95% Fixed-to-Floating Rate Subordinated Notes",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ZIONO": {
        "symbol": "ZIONO",
        "name": "Zions Bancorporation N.A. Dep Shs Repstg 1/40th Perp Pfd Ser G",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ZIONP": {
        "symbol": "ZIONP",
        "name": "Zions Bancorporation N.A. Depositary Shares (Each representing 1/40th Interest in a Share of Series A Floating-Rate Non-Cumulative Perpetual Preferred Stock)",
        "country": "United States",
        "sector": "Finance",
        "industry": "Major Banks"
    },
    "ZJYL": {
        "symbol": "ZJYL",
        "name": "JIN MEDICAL INTERNATIONAL LTD. Ordinary Shares",
        "country": "",
        "sector": "Health Care",
        "industry": "Industrial Specialties"
    },
    "ZKIN": {
        "symbol": "ZKIN",
        "name": "ZK International Group Co. Ltd Ordinary Share",
        "country": "China",
        "sector": "Industrials",
        "industry": "Metal Fabrications"
    },
    "ZLAB": {
        "symbol": "ZLAB",
        "name": "Zai Lab Limited American Depositary Shares",
        "country": "China",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZM": {
        "symbol": "ZM",
        "name": "Zoom Video Communications Inc. Class A Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "Computer Software: Programming Data Processing"
    },
    "ZNTL": {
        "symbol": "ZNTL",
        "name": "Zentalis Pharmaceuticals Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZOOZ": {
        "symbol": "ZOOZ",
        "name": "ZOOZ Power Ltd. Ordinary Shares",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ZOOZW": {
        "symbol": "ZOOZW",
        "name": "ZOOZ Power Ltd. Warrant",
        "country": "Israel",
        "sector": "Technology",
        "industry": "Industrial Machinery/Components"
    },
    "ZPTA": {
        "symbol": "ZPTA",
        "name": "Zapata Computing Holdings Inc. Common Stock",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ZPTAW": {
        "symbol": "ZPTAW",
        "name": "Zapata Computing Holdings Inc. Warrant",
        "country": "United States",
        "sector": "Finance",
        "industry": "Blank Checks"
    },
    "ZS": {
        "symbol": "ZS",
        "name": "Zscaler Inc. Common Stock",
        "country": "United States",
        "sector": "Technology",
        "industry": "EDP Services"
    },
    "ZTEK": {
        "symbol": "ZTEK",
        "name": "Zentek Ltd. Common Stock",
        "country": "Canada",
        "sector": "Industrials",
        "industry": "Major Chemicals"
    },
    "ZUMZ": {
        "symbol": "ZUMZ",
        "name": "Zumiez Inc. Common Stock",
        "country": "United States",
        "sector": "Consumer Discretionary",
        "industry": "Clothing/Shoe/Accessory Stores"
    },
    "ZURA": {
        "symbol": "ZURA",
        "name": "Zura Bio Limited Class A Ordinary Shares",
        "country": "Cayman Islands",
        "sector": "Health Care",
        "industry": "Biotechnology: Biological Products (No Diagnostic Substances)"
    },
    "ZVRA": {
        "symbol": "ZVRA",
        "name": "Zevra Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZVSA": {
        "symbol": "ZVSA",
        "name": "ZyVersa Therapeutics Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZYME": {
        "symbol": "ZYME",
        "name": "Zymeworks Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Pharmaceutical Preparations"
    },
    "ZYXI": {
        "symbol": "ZYXI",
        "name": "Zynex Inc. Common Stock",
        "country": "United States",
        "sector": "Health Care",
        "industry": "Biotechnology: Electromedical & Electrotherapeutic Apparatus"
    },
    "A": {
        "symbol": "A",
        "name": "Agilent Technologies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AA": {
        "symbol": "AA",
        "name": "Alcoa Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAA": {
        "symbol": "AAA",
        "name": "Alternative Access First Priority CLO Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAAU": {
        "symbol": "AAAU",
        "name": "Goldman Sachs Physical Gold ETF Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AACT": {
        "symbol": "AACT",
        "name": "Ares Acquisition Corporation II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AACT U": {
        "symbol": "AACT U",
        "name": "Ares Acquisition Corporation II Units, each consisting of one Class A ordinary share and one-half of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AACT WS": {
        "symbol": "AACT WS",
        "name": "Ares Acquisition Corporation II Redeemable Warrants, each whole warrant exercisable for one Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AADR": {
        "symbol": "AADR",
        "name": "AdvisorShares Dorsey Wright ADR ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAM": {
        "symbol": "AAM",
        "name": "AA Mission Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAM U": {
        "symbol": "AAM U",
        "name": "AA Mission Acquisition Corp. Units, each consisting of one Class A Ordinary Share and one-half of on",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAM WS": {
        "symbol": "AAM WS",
        "name": "AA Mission Acquisition Corp. Warrants, each whole warrant entitles the holder to purchase one Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAP": {
        "symbol": "AAP",
        "name": "ADVANCE AUTO PARTS INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPB": {
        "symbol": "AAPB",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long AAPL Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPD": {
        "symbol": "AAPD",
        "name": "Direxion Daily AAPL Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPR": {
        "symbol": "AAPR",
        "name": "Innovator Equity Defined Protection ETF - 2 Yr to April 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPU": {
        "symbol": "AAPU",
        "name": "Direxion Shares ETF Trust Direxion Daily AAPL Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPX": {
        "symbol": "AAPX",
        "name": "T-Rex 2X Long Apple Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAPY": {
        "symbol": "AAPY",
        "name": "Kurv Yield Premium Strategy Apple (AAPL) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAT": {
        "symbol": "AAT",
        "name": "AMERICAN ASSETS TRUST, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AAXJ": {
        "symbol": "AAXJ",
        "name": "iShares MSCI All Country Asia ex Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AB": {
        "symbol": "AB",
        "name": "AllianceBernstein Holding, L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABBV": {
        "symbol": "ABBV",
        "name": "ABBVIE INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABCB": {
        "symbol": "ABCB",
        "name": "Ameris Bancorp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABCS": {
        "symbol": "ABCS",
        "name": "Alpha Blue Capital US Small-Mid Cap Dynamic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABEQ": {
        "symbol": "ABEQ",
        "name": "Absolute Select Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABEV": {
        "symbol": "ABEV",
        "name": "AMBEV S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABG": {
        "symbol": "ABG",
        "name": "Asbury Automotive Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABLVW": {
        "symbol": "ABLVW",
        "name": "Able View Global Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABM": {
        "symbol": "ABM",
        "name": "ABM Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABNY": {
        "symbol": "ABNY",
        "name": "YieldMax ABNB Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABR": {
        "symbol": "ABR",
        "name": "Arbor Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABR PRD": {
        "symbol": "ABR PRD",
        "name": "Arbor Realty Trust, Inc. 6.375% Series D Cumulative Redeemable Preferred Stock, Liquidation Preferen",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABR PRE": {
        "symbol": "ABR PRE",
        "name": "Arbor Realty Trust, Inc. 6.25% Series E Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABR PRF": {
        "symbol": "ABR PRF",
        "name": "Arbor Realty Trust, Inc. 6.25% Series F Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ABT": {
        "symbol": "ABT",
        "name": "Abbott Laboratories",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AC": {
        "symbol": "AC",
        "name": "Associated Capital Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACA": {
        "symbol": "ACA",
        "name": "Arcosa, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACABW": {
        "symbol": "ACABW",
        "name": "Atlantic Coastal Acquisition Corp. II Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACCO": {
        "symbol": "ACCO",
        "name": "Acco Brands Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACEL": {
        "symbol": "ACEL",
        "name": "Accel Entertainment, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACES": {
        "symbol": "ACES",
        "name": "ALPS Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACHR": {
        "symbol": "ACHR",
        "name": "Archer Aviation Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACHR WS": {
        "symbol": "ACHR WS",
        "name": "Archer Aviation Inc. Redeemable Warrants, each whole warrant exercisable for one Class A common stoc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACI": {
        "symbol": "ACI",
        "name": "Albertsons Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACIO": {
        "symbol": "ACIO",
        "name": "Aptus Collared Investment Opportunity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACM": {
        "symbol": "ACM",
        "name": "Aecom",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACN": {
        "symbol": "ACN",
        "name": "Accenture PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACP": {
        "symbol": "ACP",
        "name": "abrdn Income Credit Strategies Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACP PRA": {
        "symbol": "ACP PRA",
        "name": "abrdn Income Credit Strategies Fund  5.250% Series A Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACR": {
        "symbol": "ACR",
        "name": "ACRES Commercial Realty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACR PRC": {
        "symbol": "ACR PRC",
        "name": "ACRES Commercial Realty Corp. 8.625% Fixed-to-Floating Series C Cumulative Redeemable Preferred Stoc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACR PRD": {
        "symbol": "ACR PRD",
        "name": "ACRES Commercial Realty Corp. 7.875% Series D Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACRE": {
        "symbol": "ACRE",
        "name": "Ares Commercial Real Estate Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACSI": {
        "symbol": "ACSI",
        "name": "American Customer Satisfaction ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACTV": {
        "symbol": "ACTV",
        "name": "LeaderShares Activist Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACU": {
        "symbol": "ACU",
        "name": "Acme United Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACV": {
        "symbol": "ACV",
        "name": "Virtus Diversified Income & Convertible Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACVF": {
        "symbol": "ACVF",
        "name": "American Conservative Values ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACWI": {
        "symbol": "ACWI",
        "name": "iShares MSCI ACWI ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACWV": {
        "symbol": "ACWV",
        "name": "iShares MSCI Global Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ACWX": {
        "symbol": "ACWX",
        "name": "iShares MSCI ACWI ex US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADC": {
        "symbol": "ADC",
        "name": "Agree Realty Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADC PRA": {
        "symbol": "ADC PRA",
        "name": "Agree Realty Corporation Depositary Shares, each representing 1/1,000th of a 4.250% Series A Cumulat",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADCT": {
        "symbol": "ADCT",
        "name": "ADC Therapeutics SA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADFI": {
        "symbol": "ADFI",
        "name": "Anfield Dynamic Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADIV": {
        "symbol": "ADIV",
        "name": "SmartETFs Asia Pacific Dividend Builder ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADM": {
        "symbol": "ADM",
        "name": "Archer Daniels Midland Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADME": {
        "symbol": "ADME",
        "name": "Aptus Behavioral Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADNT": {
        "symbol": "ADNT",
        "name": "Adient plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADPV": {
        "symbol": "ADPV",
        "name": "Adaptiv Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADRT": {
        "symbol": "ADRT",
        "name": "Ault Disruptive Technologies Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADRT U": {
        "symbol": "ADRT U",
        "name": "Ault Disruptive Technologies Corporation Units, each consisting of one share of Common Stock, and th",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADT": {
        "symbol": "ADT",
        "name": "ADT Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADVE": {
        "symbol": "ADVE",
        "name": "Matthews Asia Dividend Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ADX": {
        "symbol": "ADX",
        "name": "Adams Diversified Equity Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AE": {
        "symbol": "AE",
        "name": "Adams Resources & Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEAEU": {
        "symbol": "AEAEU",
        "name": "AltEnergy Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEE": {
        "symbol": "AEE",
        "name": "Ameren Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEF": {
        "symbol": "AEF",
        "name": "abrdn Emerging Markets Equity Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEFC": {
        "symbol": "AEFC",
        "name": "Aegon Funding Company LLC 5.10% Subordinated Notes due 2049",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEG": {
        "symbol": "AEG",
        "name": "Aegon Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEM": {
        "symbol": "AEM",
        "name": "Agnico Eagle Mines Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEO": {
        "symbol": "AEO",
        "name": "American Eagle Outfitters",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEON": {
        "symbol": "AEON",
        "name": "AEON Biopharma, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AER": {
        "symbol": "AER",
        "name": "Aercap Holdings N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AES": {
        "symbol": "AES",
        "name": "AES Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AESI": {
        "symbol": "AESI",
        "name": "Atlas Energy Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AESR": {
        "symbol": "AESR",
        "name": "Anfield U.S. Equity Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AETH": {
        "symbol": "AETH",
        "name": "Bitwise Ethereum Strategy",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEVA": {
        "symbol": "AEVA",
        "name": "Aeva Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AEVA WS": {
        "symbol": "AEVA WS",
        "name": "Aeva Technologies, Inc. Redeemable Warrants, each Warrant to purchase one-fifth (1/5) of a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFB": {
        "symbol": "AFB",
        "name": "Alliance National Municipal Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFG": {
        "symbol": "AFG",
        "name": "American Financial Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFGB": {
        "symbol": "AFGB",
        "name": "American Financial Group, Inc. 5.875% Subordinated Debentures due 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFGC": {
        "symbol": "AFGC",
        "name": "American Financial Group, Inc. 5.125% Subordinated Debentures due 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFGD": {
        "symbol": "AFGD",
        "name": "American Financial Group, Inc. 5.625% Subordinated Debentures due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFGE": {
        "symbol": "AFGE",
        "name": "American Financial Group, Inc. 4.500% Subordinated Debentures due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFIF": {
        "symbol": "AFIF",
        "name": "Anfield Universal Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFJKR": {
        "symbol": "AFJKR",
        "name": "Aimei Health Technology Co., Ltd Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFK": {
        "symbol": "AFK",
        "name": "VanEck Africa Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFL": {
        "symbol": "AFL",
        "name": "Aflac Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFLG": {
        "symbol": "AFLG",
        "name": "First Trust Active Factor Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFMC": {
        "symbol": "AFMC",
        "name": "First Trust Active Factor Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFSM": {
        "symbol": "AFSM",
        "name": "First Trust Active Factor Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AFTY": {
        "symbol": "AFTY",
        "name": "Pacer CSOP FTSE China A50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AG": {
        "symbol": "AG",
        "name": "FIRST MAJESTIC SILVER CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGCO": {
        "symbol": "AGCO",
        "name": "AGCO Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGD": {
        "symbol": "AGD",
        "name": "abrdn Global Dynamic Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGG": {
        "symbol": "AGG",
        "name": "iShares Core U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGGH": {
        "symbol": "AGGH",
        "name": "Simplify Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGGS": {
        "symbol": "AGGS",
        "name": "Harbor Disciplined Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGGY": {
        "symbol": "AGGY",
        "name": "WisdomTree Yield Enhanced U.S. Aggregate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGI": {
        "symbol": "AGI",
        "name": "Alamos Gold Inc. Class A Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGIH": {
        "symbol": "AGIH",
        "name": "iShares Inflation Hedged U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGIX": {
        "symbol": "AGIX",
        "name": "KraneShares Artificial Intelligence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGL": {
        "symbol": "AGL",
        "name": "agilon health, inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM": {
        "symbol": "AGM",
        "name": "Federal Agricultural Mortgage Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM A": {
        "symbol": "AGM A",
        "name": "Federal Agricultural Mortgage Corporation Class A Voting",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM PRD": {
        "symbol": "AGM PRD",
        "name": "Federal Agricultural Mortgage Corporation 5.700% Non-Cumulative Preferred Stock, Series D",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM PRE": {
        "symbol": "AGM PRE",
        "name": "Federal Agricultural Mortgage Corporation 5.750% Non-Cumulative Preferred Stock, Series E",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM PRF": {
        "symbol": "AGM PRF",
        "name": "Federal Agricultural Mortgage Corporation 5.250% Non-Cumulative Preferred Stock, Series F",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGM PRG": {
        "symbol": "AGM PRG",
        "name": "Federal Agricultural Mortgage Corporation 4.875% Non-Cumulative Preferred Stock, Series G",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGMI": {
        "symbol": "AGMI",
        "name": "Themes Silver Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGNG": {
        "symbol": "AGNG",
        "name": "Global X Funds Global X Aging Population ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGO": {
        "symbol": "AGO",
        "name": "Assured Guaranty, LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGOX": {
        "symbol": "AGOX",
        "name": "Adaptive Alpha Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGQ": {
        "symbol": "AGQ",
        "name": "ProShares Ultra Silver",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGQI": {
        "symbol": "AGQI",
        "name": "First Trust Active Global Quality Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGR": {
        "symbol": "AGR",
        "name": "Avangrid, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGRH": {
        "symbol": "AGRH",
        "name": "iShares Interest Rate Hedged U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGRO": {
        "symbol": "AGRO",
        "name": "ADECOAGRO S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGS": {
        "symbol": "AGS",
        "name": "PlayAGS, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGX": {
        "symbol": "AGX",
        "name": "Argan, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGZ": {
        "symbol": "AGZ",
        "name": "iShares Agency Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AGZD": {
        "symbol": "AGZD",
        "name": "WisdomTree Trust WisdomTree Interest Rate Hedged U.S. Aggregate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHH": {
        "symbol": "AHH",
        "name": "Armada Hoffler Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHH PRA": {
        "symbol": "AHH PRA",
        "name": "Armada Hoffler Properties, Inc. 6.75% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHL PRC": {
        "symbol": "AHL PRC",
        "name": "Aspen Insurance Holdings Limited 5.95% Fixed-to-Floating Rate Perpetual Non-Cumulative Preference Sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHL PRD": {
        "symbol": "AHL PRD",
        "name": "Aspen Insurance Holdings Limited 5.625% Perpetual Non-Cumulative Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHL PRE": {
        "symbol": "AHL PRE",
        "name": "Aspen Insurance Holdings Limited Depositary Shares, each representing a 1/1000th interest in a share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHLT": {
        "symbol": "AHLT",
        "name": "American Beacon AHL Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHOY": {
        "symbol": "AHOY",
        "name": "Newday Ocean Health ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHR": {
        "symbol": "AHR",
        "name": "American Healthcare REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT": {
        "symbol": "AHT",
        "name": "Ashford Hospitality Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT PRD": {
        "symbol": "AHT PRD",
        "name": "Ashford Hospitality Trust, Inc. 8.45% Series D",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT PRF": {
        "symbol": "AHT PRF",
        "name": "Ashford Hospitality Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT PRG": {
        "symbol": "AHT PRG",
        "name": "Ashford Hospitality Trust, Inc   7.375% Series G Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT PRH": {
        "symbol": "AHT PRH",
        "name": "Ashford Hospitality Trust, Inc. 7.50 Percent Series H Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHT PRI": {
        "symbol": "AHT PRI",
        "name": "Ashford Hospitality Trust, Inc. 7.50% Series I Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AHYB": {
        "symbol": "AHYB",
        "name": "American Century Select High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AI": {
        "symbol": "AI",
        "name": "C3.ai, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIA": {
        "symbol": "AIA",
        "name": "iShares Asia 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIBD": {
        "symbol": "AIBD",
        "name": "Direxion Daily AI and Big Data Bear 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIBU": {
        "symbol": "AIBU",
        "name": "Direxion Daily AI and Big Data Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIEQ": {
        "symbol": "AIEQ",
        "name": "Amplify AI Powered Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIFD": {
        "symbol": "AIFD",
        "name": "TCW Artificial Intelligence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIG": {
        "symbol": "AIG",
        "name": "American International Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIM": {
        "symbol": "AIM",
        "name": "AIM ImmunoTech Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIMAW": {
        "symbol": "AIMAW",
        "name": "Aimfinity Investment Corp. I Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIN": {
        "symbol": "AIN",
        "name": "Albany International Corp Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIO": {
        "symbol": "AIO",
        "name": "Virtus Artificial Intelligence & Technology Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIPI": {
        "symbol": "AIPI",
        "name": "REX AI Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIQ": {
        "symbol": "AIQ",
        "name": "Global X Funds Global X Artificial Intelligence & Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIR": {
        "symbol": "AIR",
        "name": "AAR Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIRI": {
        "symbol": "AIRI",
        "name": "Air Industries Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIRL": {
        "symbol": "AIRL",
        "name": "Themes Airlines ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIRR": {
        "symbol": "AIRR",
        "name": "First Trust RBA American Industrial Renaissance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIT": {
        "symbol": "AIT",
        "name": "Applied Industrial Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIV": {
        "symbol": "AIV",
        "name": "Apartment Investment and Management Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIVI": {
        "symbol": "AIVI",
        "name": "WisdomTree International AI Enhanced Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIVL": {
        "symbol": "AIVL",
        "name": "WisdomTree U.S. AI Enhanced Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIYY": {
        "symbol": "AIYY",
        "name": "YieldMax AI Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIZ": {
        "symbol": "AIZ",
        "name": "Assurant, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AIZN": {
        "symbol": "AIZN",
        "name": "Assurant, Inc. 5.25% Subordinated Notes due 2061",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AJAN": {
        "symbol": "AJAN",
        "name": "Innovator Equity Defined Protection ETF - 2 Yr to January 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AJG": {
        "symbol": "AJG",
        "name": "Arthur J. Gallagher & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AJUL": {
        "symbol": "AJUL",
        "name": "Innovator Equity Defined Protection ETF - 2 Yr to July 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AJX": {
        "symbol": "AJX",
        "name": "Great Ajax Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AKA": {
        "symbol": "AKA",
        "name": "a.k.a. Brands Holding Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AKO A": {
        "symbol": "AKO A",
        "name": "Embotelladora Andina S.A. Series A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AKO B": {
        "symbol": "AKO B",
        "name": "Embotelladora Andina S.A. Series B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AKR": {
        "symbol": "AKR",
        "name": "Acadia Realty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AL": {
        "symbol": "AL",
        "name": "Air Lease Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AL PRA": {
        "symbol": "AL PRA",
        "name": "Air Lease Corporation 6.150% Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock, Series",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALAI": {
        "symbol": "ALAI",
        "name": "Alger AI Enablers & Adopters ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALB": {
        "symbol": "ALB",
        "name": "Albemarle Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALB PRA": {
        "symbol": "ALB PRA",
        "name": "Albemarle Corporation Depositary Shares each representing a 1/20th interest in a share of 7.25% Seri",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALC": {
        "symbol": "ALC",
        "name": "Alcon Inc. Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALCYU": {
        "symbol": "ALCYU",
        "name": "Alchemy Investments Acquisition Corp 1 Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALE": {
        "symbol": "ALE",
        "name": "ALLETE, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALEX": {
        "symbol": "ALEX",
        "name": "Alexander & Baldwin, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALG": {
        "symbol": "ALG",
        "name": "Alamo Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALIT": {
        "symbol": "ALIT",
        "name": "Alight, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALK": {
        "symbol": "ALK",
        "name": "Alaska Air Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALL": {
        "symbol": "ALL",
        "name": "The Allstate Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALL PRB": {
        "symbol": "ALL PRB",
        "name": "The Allstate Corporation 5.100%  Fixed-To-Floating Rate Subordinated Debentures due 2053",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALL PRH": {
        "symbol": "ALL PRH",
        "name": "The Allstate Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALL PRI": {
        "symbol": "ALL PRI",
        "name": "The Allstate Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALL PRJ": {
        "symbol": "ALL PRJ",
        "name": "The Allstate Corporation Depositary Shares each representing a 1/1,000th interest in a share of Fixe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALLE": {
        "symbol": "ALLE",
        "name": "Allegion Public Limited Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALLY": {
        "symbol": "ALLY",
        "name": "Ally Financial Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALSAU": {
        "symbol": "ALSAU",
        "name": "Alpha Star Acquisition Corporation Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALSAW": {
        "symbol": "ALSAW",
        "name": "Alpha Star Acquisition Corporation Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALSN": {
        "symbol": "ALSN",
        "name": "ALLISON TRANSMISSION HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALTG": {
        "symbol": "ALTG",
        "name": "Alta Equipment Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALTG PRA": {
        "symbol": "ALTG PRA",
        "name": "Alta Equipment Group Inc. Depositary Shares (each representing 1/1000th in a share of 10% Series A C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALTL": {
        "symbol": "ALTL",
        "name": "Pacer Lunt Large Cap Alternator ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALTM": {
        "symbol": "ALTM",
        "name": "Arcadium Lithium plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALTY": {
        "symbol": "ALTY",
        "name": "Global X Funds Global X Alternative Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALUM": {
        "symbol": "ALUM",
        "name": "USCF Aluminum Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALUR": {
        "symbol": "ALUR",
        "name": "Allurion Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALUR WS": {
        "symbol": "ALUR WS",
        "name": "Allurion Technologies, Inc. Warrants, each whole warrant exercisable to purchase 1.420455 shares of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALV": {
        "symbol": "ALV",
        "name": "Autoliv, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ALX": {
        "symbol": "ALX",
        "name": "Alexander's Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AM": {
        "symbol": "AM",
        "name": "Antero Midstream Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMAX": {
        "symbol": "AMAX",
        "name": "Adaptive Hedged Multi-Asset Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBC": {
        "symbol": "AMBC",
        "name": "Ambac Financial Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBI": {
        "symbol": "AMBI",
        "name": "Ambipar Emergency Response",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBI WS": {
        "symbol": "AMBI WS",
        "name": "Ambipar Emergency Response Warrants to purchase Class A ordinary shares, each whole warrant exercisa",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBO": {
        "symbol": "AMBO",
        "name": "Ambow Education Holding Ltd. American Depository Shares (each representing twenty (20) Class A Ordin",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBP": {
        "symbol": "AMBP",
        "name": "Ardagh Metal Packaging S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMBP WS": {
        "symbol": "AMBP WS",
        "name": "Ardagh Metal Packaging S.A. Warrants, each exercisable for one Share at an exercise price of $11.50",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMC": {
        "symbol": "AMC",
        "name": "AMC ENTERTAINMENT HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMCR": {
        "symbol": "AMCR",
        "name": "Amcor plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMDL": {
        "symbol": "AMDL",
        "name": "GraniteShares 2x Long AMD Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMDS": {
        "symbol": "AMDS",
        "name": "GraniteShares 1x Short AMD Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMDY": {
        "symbol": "AMDY",
        "name": "YieldMax AMD Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AME": {
        "symbol": "AME",
        "name": "Ametek, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMG": {
        "symbol": "AMG",
        "name": "Affiliated Managers Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMH": {
        "symbol": "AMH",
        "name": "AMERICAN HOMES 4 RENT",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMH PRG": {
        "symbol": "AMH PRG",
        "name": "American Homes 4 Rent Series G cumulative redeemable perpetual preferred shares of beneficial intere",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMH PRH": {
        "symbol": "AMH PRH",
        "name": "American Homes 4 Rent Series H cumulative redeemable perpetual Preferred Shares of Beneficial Intere",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMID": {
        "symbol": "AMID",
        "name": "Argent Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMJB": {
        "symbol": "AMJB",
        "name": "Alerian MLP Index ETNs due January 28 2044",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMLP": {
        "symbol": "AMLP",
        "name": "Alerian MLP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMN": {
        "symbol": "AMN",
        "name": "AMN Healthcare Services",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMNA": {
        "symbol": "AMNA",
        "name": "ETRACS Alerian Midstream Energy Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMND": {
        "symbol": "AMND",
        "name": "ETRACS Alerian Midstream Energy High Dividend Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMOM": {
        "symbol": "AMOM",
        "name": "Qraft AI-Enhanced US Large Cap Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMP": {
        "symbol": "AMP",
        "name": "Ameriprise Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPD": {
        "symbol": "AMPD",
        "name": "CNIC ICE U.S. Carbon Neutral Power Futures Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPGW": {
        "symbol": "AMPGW",
        "name": "Amplitech Group, Inc. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPS": {
        "symbol": "AMPS",
        "name": "Altus Power, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPX": {
        "symbol": "AMPX",
        "name": "Amprius Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPX WS": {
        "symbol": "AMPX WS",
        "name": "Amprius Technologies, Inc. Warrants to purchase one share of Common Stock at an exercise price of $1",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMPY": {
        "symbol": "AMPY",
        "name": "Amplify Energy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMR": {
        "symbol": "AMR",
        "name": "Alpha Metallurgical Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMRC": {
        "symbol": "AMRC",
        "name": "Ameresco, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMS": {
        "symbol": "AMS",
        "name": "American Shared Hospital Services",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMT": {
        "symbol": "AMT",
        "name": "American Tower Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMTB": {
        "symbol": "AMTB",
        "name": "Amerant Bancorp Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMTD": {
        "symbol": "AMTD",
        "name": "AMTD IDEA Group American Depositary Shares, each representing six (6) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMTM": {
        "symbol": "AMTM",
        "name": "Amentum Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMTR": {
        "symbol": "AMTR",
        "name": "ETRACS Alerian Midstream Energy Total Return Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMUB": {
        "symbol": "AMUB",
        "name": "ETRACS Alerian MLP Index ETN Series B due July 18, 2042",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMWL": {
        "symbol": "AMWL",
        "name": "American Well Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMX": {
        "symbol": "AMX",
        "name": "America Movil S.A.B de C.V American Depositary Shares (each representing the right to receive twenty",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZA": {
        "symbol": "AMZA",
        "name": "InfraCap MLP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZD": {
        "symbol": "AMZD",
        "name": "Direxion Daily AMZN Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZP": {
        "symbol": "AMZP",
        "name": "Kurv Yield Premium Strategy Amazon (AMZN) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZU": {
        "symbol": "AMZU",
        "name": "Direxion Shares ETF Trust Direxion Daily AMZN Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZY": {
        "symbol": "AMZY",
        "name": "YieldMax AMZN Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AMZZ": {
        "symbol": "AMZZ",
        "name": "GraniteShares 2x Long AMZN Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AN": {
        "symbol": "AN",
        "name": "AutoNation, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANET": {
        "symbol": "ANET",
        "name": "Arista Networks",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANEW": {
        "symbol": "ANEW",
        "name": "ProShares MSCI Transformational Changes ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANF": {
        "symbol": "ANF",
        "name": "Abercrombie & Fitch Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANG PRA": {
        "symbol": "ANG PRA",
        "name": "American National Group Inc. Depositary Shares, each representing a 1/1,000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANG PRB": {
        "symbol": "ANG PRB",
        "name": "American National Group Inc.  Depositary Shares, each representing a 1/1, 000th interest in a share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANGL": {
        "symbol": "ANGL",
        "name": "VanEck Fallen Angel High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANRO": {
        "symbol": "ANRO",
        "name": "Alto Neuroscience Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ANVS": {
        "symbol": "ANVS",
        "name": "Annovis Bio, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOA": {
        "symbol": "AOA",
        "name": "iShares Core Aggressive Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOCT": {
        "symbol": "AOCT",
        "name": "Innovator Equity Defined Protection ETF - 2 Yr to October 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOD": {
        "symbol": "AOD",
        "name": "abrdn Total Dynamic Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOHY": {
        "symbol": "AOHY",
        "name": "Angel Oak High Yield  Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOK": {
        "symbol": "AOK",
        "name": "iShares Core Conservative Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOM": {
        "symbol": "AOM",
        "name": "iShares Core Moderate Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOMN": {
        "symbol": "AOMN",
        "name": "Angel Oak Mortgage REIT, Inc. 9.500% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOMR": {
        "symbol": "AOMR",
        "name": "Angel Oak Mortgage REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AON": {
        "symbol": "AON",
        "name": "Aon plc Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOR": {
        "symbol": "AOR",
        "name": "iShares Core Growth Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AORT": {
        "symbol": "AORT",
        "name": "Artivion, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOS": {
        "symbol": "AOS",
        "name": "A.O. Smith Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AOTG": {
        "symbol": "AOTG",
        "name": "AOT Growth and Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AP": {
        "symbol": "AP",
        "name": "Ampco-Pittsburgh Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AP WS": {
        "symbol": "AP WS",
        "name": "Ampco-Pittsburgh Corporation Series A Warrants to purchase Shares of common stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APAM": {
        "symbol": "APAM",
        "name": "ARTISAN PARTNERS ASSET MANAGEMENT INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APCB": {
        "symbol": "APCB",
        "name": "ActivePassive Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APCXW": {
        "symbol": "APCXW",
        "name": "AppTech Payments Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APD": {
        "symbol": "APD",
        "name": "Air Products & Chemicals, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APG": {
        "symbol": "APG",
        "name": "APi Group Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APH": {
        "symbol": "APH",
        "name": "Amphenol Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APIE": {
        "symbol": "APIE",
        "name": "ActivePassive International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APLE": {
        "symbol": "APLE",
        "name": "Apple Hospitality REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APLY": {
        "symbol": "APLY",
        "name": "YieldMax AAPL Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APMU": {
        "symbol": "APMU",
        "name": "ActivePassive Intermediate Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APO": {
        "symbol": "APO",
        "name": "Apollo Global Management, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APO PRA": {
        "symbol": "APO PRA",
        "name": "Apollo Global Management, Inc. 6.75% Series A Mandatory Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APOC": {
        "symbol": "APOC",
        "name": "Innovator Equity Defined Protection ETF - 6mo Apr/Oct",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APOS": {
        "symbol": "APOS",
        "name": "Apollo Global Management, Inc. 7.625% Fixed-Rate Resettable Junior Subordinated Notes due 2053",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRD": {
        "symbol": "APRD",
        "name": "Innovator Premium Income 10 Barrier ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRH": {
        "symbol": "APRH",
        "name": "Innovator Premium Income 20 Barrier ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRJ": {
        "symbol": "APRJ",
        "name": "Innovator Premium Income 30 Barrier ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRP": {
        "symbol": "APRP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRQ": {
        "symbol": "APRQ",
        "name": "Innovator Preium Income 40 Barrier ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRT": {
        "symbol": "APRT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Apr ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRW": {
        "symbol": "APRW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Apr ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APRZ": {
        "symbol": "APRZ",
        "name": "TrueShares Structured Outcome (April) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APT": {
        "symbol": "APT",
        "name": "Alpha Pro Tech, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APTV": {
        "symbol": "APTV",
        "name": "Aptiv PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APUE": {
        "symbol": "APUE",
        "name": "ActivePassive U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APXIU": {
        "symbol": "APXIU",
        "name": "APx Acquisition Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "APXIW": {
        "symbol": "APXIW",
        "name": "APx Acquisition Corp. I Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AQN": {
        "symbol": "AQN",
        "name": "Algonquin Power & Utilities Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AQNB": {
        "symbol": "AQNB",
        "name": "Algonquin Power & Utilities Corp. 6.20% Fixed-to-Floating Subordinated Notes Series 2019-A due July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AQUNR": {
        "symbol": "AQUNR",
        "name": "Aquaron Acquisition Corp. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AQWA": {
        "symbol": "AQWA",
        "name": "Global X Clean Water ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AR": {
        "symbol": "AR",
        "name": "ANTERO RESOURCES CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARB": {
        "symbol": "ARB",
        "name": "AltShares Merger Arbitrage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARC": {
        "symbol": "ARC",
        "name": "ARC Document Solutions, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARCH": {
        "symbol": "ARCH",
        "name": "Arch Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARCM": {
        "symbol": "ARCM",
        "name": "Arrow Reserve Capital Management ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARCO": {
        "symbol": "ARCO",
        "name": "ARCOS DORADOS HOLDINGS INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARDC": {
        "symbol": "ARDC",
        "name": "ARES DYNAMIC CREDIT ALLOCATION FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARDT": {
        "symbol": "ARDT",
        "name": "Ardent Health Partners, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARE": {
        "symbol": "ARE",
        "name": "Alexandria Real Estate Equities, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AREA": {
        "symbol": "AREA",
        "name": "Harbor AlphaEdge Next Generation REITs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AREN": {
        "symbol": "AREN",
        "name": "The Arena Group Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARES": {
        "symbol": "ARES",
        "name": "Ares Management Corporation Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARGD": {
        "symbol": "ARGD",
        "name": "Argo Group US, Inc. 6.500% Senior Notes due 2042",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARGO PRA": {
        "symbol": "ARGO PRA",
        "name": "Argo Group International Holdings, Inc. Depositary Shares, Each Representing a 1/1,000th Interest in",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARGT": {
        "symbol": "ARGT",
        "name": "Global X MSCI Argentina ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARI": {
        "symbol": "ARI",
        "name": "APOLLO COMMERCIAL REAL ESTATE FINANCE, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARIS": {
        "symbol": "ARIS",
        "name": "Aris Water Solutions, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKA": {
        "symbol": "ARKA",
        "name": "ARK 21Shares Active Bitcoin Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKB": {
        "symbol": "ARKB",
        "name": "ARK 21Shares Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKC": {
        "symbol": "ARKC",
        "name": "ARK 21Shares Active On-Chain Bitcoin Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKD": {
        "symbol": "ARKD",
        "name": "ARK 21Shares Blockchain and Digital Economy Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKF": {
        "symbol": "ARKF",
        "name": "ARK Fintech Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKG": {
        "symbol": "ARKG",
        "name": "ARK Genomic Revolution ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKK": {
        "symbol": "ARKK",
        "name": "ARK Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKQ": {
        "symbol": "ARKQ",
        "name": "ARK Autonomous Technology & Robotics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKW": {
        "symbol": "ARKW",
        "name": "ARK Next Generation Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKX": {
        "symbol": "ARKX",
        "name": "ARK Space Exploration & Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKY": {
        "symbol": "ARKY",
        "name": "ARK 21Shares Active Bitcoin Ethereum Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARKZ": {
        "symbol": "ARKZ",
        "name": "ARK 21Shares Active Ethereum Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARL": {
        "symbol": "ARL",
        "name": "American Realty Investors, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARLO": {
        "symbol": "ARLO",
        "name": "Arlo Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARLU": {
        "symbol": "ARLU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped Apr ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARMK": {
        "symbol": "ARMK",
        "name": "ARAMARK",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARMN": {
        "symbol": "ARMN",
        "name": "Aris Mining Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARMP": {
        "symbol": "ARMP",
        "name": "Armata Pharmaceuticals, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AROC": {
        "symbol": "AROC",
        "name": "Archrock Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARP": {
        "symbol": "ARP",
        "name": "PMV Adaptive Risk Parity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARR": {
        "symbol": "ARR",
        "name": "ARMOUR Residential REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARR PRC": {
        "symbol": "ARR PRC",
        "name": "ARMOUR Residential REIT, Inc. 7.00% Series C Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARTY": {
        "symbol": "ARTY",
        "name": "iShares Future AI & Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARVR": {
        "symbol": "ARVR",
        "name": "First Trust Indxx Metaverse ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ARW": {
        "symbol": "ARW",
        "name": "Arrow Electronics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AS": {
        "symbol": "AS",
        "name": "Amer Sports, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASA": {
        "symbol": "ASA",
        "name": "ASA GOLD AND PRECIOUS METALS LIMITED",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASAI": {
        "symbol": "ASAI",
        "name": "Sendas Distribuidora S.A. American Depositary Share (each representing five common shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASAN": {
        "symbol": "ASAN",
        "name": "Asana, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASB": {
        "symbol": "ASB",
        "name": "Associated Banc-Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASB PRE": {
        "symbol": "ASB PRE",
        "name": "Associated Banc-Corp Depositary Shares, each representing a 1/40th interest in a share of 5.875% Non",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASB PRF": {
        "symbol": "ASB PRF",
        "name": "Associated Banc-Corp Depositary Shares, each representing a 1/40th interest in a share of Associated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASBA": {
        "symbol": "ASBA",
        "name": "Associated Banc-Corp 6.625% Fixed-Rate Reset Subordinated Notes due 2033",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASC": {
        "symbol": "ASC",
        "name": "ARDMORE SHIPPING CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASEA": {
        "symbol": "ASEA",
        "name": "Global X FTSE Southeast Asia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASET": {
        "symbol": "ASET",
        "name": "FlexShares Real Assets Allocation Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASG": {
        "symbol": "ASG",
        "name": "LIBERTY ALL-STAR GROWTH FD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASGI": {
        "symbol": "ASGI",
        "name": "abrdn Global Infrastructure Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASGN": {
        "symbol": "ASGN",
        "name": "ASGN Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASH": {
        "symbol": "ASH",
        "name": "Ashland Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASHR": {
        "symbol": "ASHR",
        "name": "Xtrackers Harvest CSI 300 China A-Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASHS": {
        "symbol": "ASHS",
        "name": "Xtrackers Harvest CSI 500 China A-Shares Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASIA": {
        "symbol": "ASIA",
        "name": "Matthews Pacific Tiger Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASIX": {
        "symbol": "ASIX",
        "name": "AdvanSix Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASM": {
        "symbol": "ASM",
        "name": "Avino Silver & Gold Mines Ltd. (Canada)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASMF": {
        "symbol": "ASMF",
        "name": "Virtus AlphaSimplex Managed Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASPN": {
        "symbol": "ASPN",
        "name": "Aspen Aerogels, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASR": {
        "symbol": "ASR",
        "name": "Grupo Aeroportuario del Sureste, S.A. de C.V. American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ASX": {
        "symbol": "ASX",
        "name": "ASE Technology Holding Co., Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATCH": {
        "symbol": "ATCH",
        "name": "AtlasClear Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATCO PRD": {
        "symbol": "ATCO PRD",
        "name": "Atlas Corp. 7.95% Series D Cumulative Redeemable Perpetual Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATCO PRH": {
        "symbol": "ATCO PRH",
        "name": "Atlas Corp. 7.875% Cumulative Redeemable Perpetual Preferred Shares - Series H",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEK": {
        "symbol": "ATEK",
        "name": "Athena Technology Acquisition Corp. II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEK U": {
        "symbol": "ATEK U",
        "name": "Athena Technology Acquisition Corp. II Units, each consisting of one share of Class A common stock,",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEK WS": {
        "symbol": "ATEK WS",
        "name": "Athena Technology Acquisition Corp. II Redeemable Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEN": {
        "symbol": "ATEN",
        "name": "A10 NETWORKS INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST": {
        "symbol": "ATEST",
        "name": "Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST A": {
        "symbol": "ATEST A",
        "name": "Test Symbol Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST B": {
        "symbol": "ATEST B",
        "name": "Test Symbol Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST C": {
        "symbol": "ATEST C",
        "name": "Test Symbol Class C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST G": {
        "symbol": "ATEST G",
        "name": "TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST H": {
        "symbol": "ATEST H",
        "name": "TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST L": {
        "symbol": "ATEST L",
        "name": "TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATEST Z": {
        "symbol": "ATEST Z",
        "name": "NYSE MKT TEST SYMBOL SIFMA / DTCC Eligible",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATFV": {
        "symbol": "ATFV",
        "name": "Alger 35 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATGE": {
        "symbol": "ATGE",
        "name": "Adtalem Global Education Inc. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATH PRA": {
        "symbol": "ATH PRA",
        "name": "Athene Holding Ltd. Depositary Shares, Each Representing a 1/1,000th Interest in a 6.35% Fixed-to-Fl",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATH PRB": {
        "symbol": "ATH PRB",
        "name": "Athene Holding Ltd. Depositary Shares, Each Representing a 1/1,000th Interest in a 5.625% Fixed Rate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATH PRC": {
        "symbol": "ATH PRC",
        "name": "Athene Holding Ltd. Depositary Shares, each representing a 1/1, 000th Interest in a Share of 6.375%",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATH PRD": {
        "symbol": "ATH PRD",
        "name": "Athene Holding Ltd. Depositary Shares, Each Representing a 1/1,000th Interest in a 4.875% Fixed-Rate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATH PRE": {
        "symbol": "ATH PRE",
        "name": "Athene Holding Ltd. Depositary Shares, Each Representing a 1/1,000th Interest in a 7.750% Fixed-Rate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATHM": {
        "symbol": "ATHM",
        "name": "Autohome Inc. American Depositary Shares, each representing four Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATHS": {
        "symbol": "ATHS",
        "name": "Athene Holding Ltd. 7.250% Fixed-Rate Reset Junior Subordinated Debentures due 2064",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATI": {
        "symbol": "ATI",
        "name": "ATI Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATIP": {
        "symbol": "ATIP",
        "name": "ATI Physical Therapy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATKR": {
        "symbol": "ATKR",
        "name": "Atkore Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATMCU": {
        "symbol": "ATMCU",
        "name": "AlphaTime Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATMP": {
        "symbol": "ATMP",
        "name": "iPath Select MLP ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATMU": {
        "symbol": "ATMU",
        "name": "Atmus Filtration Technologies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATMVR": {
        "symbol": "ATMVR",
        "name": "AlphaVest Acquisition Corp Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATMVU": {
        "symbol": "ATMVU",
        "name": "AlphaVest Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATNM": {
        "symbol": "ATNM",
        "name": "Actinium Pharmaceuticals, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATO": {
        "symbol": "ATO",
        "name": "Atmos Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATR": {
        "symbol": "ATR",
        "name": "AptarGroup, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATS": {
        "symbol": "ATS",
        "name": "ATS Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ATUS": {
        "symbol": "ATUS",
        "name": "Altice USA, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AU": {
        "symbol": "AU",
        "name": "AngloGold Ashanti plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUB": {
        "symbol": "AUB",
        "name": "Atlantic Union Bankshares Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUB PRA": {
        "symbol": "AUB PRA",
        "name": "Atlantic Union Bankshares Corporation Depositary Shares, Each Representing a 1/400th Interest in a S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGM": {
        "symbol": "AUGM",
        "name": "FT Vest U.S. Equity Max Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGP": {
        "symbol": "AUGP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGT": {
        "symbol": "AUGT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Aug ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGU": {
        "symbol": "AUGU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped Aug ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGW": {
        "symbol": "AUGW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Aug ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUGZ": {
        "symbol": "AUGZ",
        "name": "TrueShares Structured Outcome (August) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUMI": {
        "symbol": "AUMI",
        "name": "Themes Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUMN": {
        "symbol": "AUMN",
        "name": "Golden Minerals Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUNA": {
        "symbol": "AUNA",
        "name": "Auna S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUSF": {
        "symbol": "AUSF",
        "name": "Global X Adaptive U.S. Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AUST": {
        "symbol": "AUST",
        "name": "Austin Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVA": {
        "symbol": "AVA",
        "name": "Avista Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVAL": {
        "symbol": "AVAL",
        "name": "GRUPO AVAL ACCIONES Y VALORES S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVB": {
        "symbol": "AVB",
        "name": "AvalonBay Communities, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVD": {
        "symbol": "AVD",
        "name": "American Vanguard Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVDE": {
        "symbol": "AVDE",
        "name": "Avantis International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVDS": {
        "symbol": "AVDS",
        "name": "Avantis International Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVDV": {
        "symbol": "AVDV",
        "name": "Avantis International Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVEE": {
        "symbol": "AVEE",
        "name": "Avantis Emerging Markets Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVEM": {
        "symbol": "AVEM",
        "name": "Avantis Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVES": {
        "symbol": "AVES",
        "name": "Avantis Emerging Markets Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVGE": {
        "symbol": "AVGE",
        "name": "Avantis All Equity Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVGV": {
        "symbol": "AVGV",
        "name": "Avantis All Equity Markets Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVGX": {
        "symbol": "AVGX",
        "name": "Defiance Daily Target 2X Long AVGO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVIE": {
        "symbol": "AVIE",
        "name": "Avantis Inflation Focused Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVIG": {
        "symbol": "AVIG",
        "name": "Avantis Core Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVIV": {
        "symbol": "AVIV",
        "name": "Avantis International Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVK": {
        "symbol": "AVK",
        "name": "Advent Convertible and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVK RT": {
        "symbol": "AVK RT",
        "name": "Advent Convertible and Income Fund Rights (expiring October 17, 2024)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVL": {
        "symbol": "AVL",
        "name": "Direxion Daily AVGO Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVLC": {
        "symbol": "AVLC",
        "name": "Avantis U.S. Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVLV": {
        "symbol": "AVLV",
        "name": "Avantis U.S. Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVMA": {
        "symbol": "AVMA",
        "name": "Avantis Moderate Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVMC": {
        "symbol": "AVMC",
        "name": "Avantis U.S. Mid Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVMU": {
        "symbol": "AVMU",
        "name": "Avantis Core Municipal Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVMV": {
        "symbol": "AVMV",
        "name": "Avantis U.S. Mid Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVNM": {
        "symbol": "AVNM",
        "name": "Avantis All International Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVNS": {
        "symbol": "AVNS",
        "name": "Avanos Medical, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVNT": {
        "symbol": "AVNT",
        "name": "Avient Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVNV": {
        "symbol": "AVNV",
        "name": "Avantis All International Markets Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVRE": {
        "symbol": "AVRE",
        "name": "Avantis Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVS": {
        "symbol": "AVS",
        "name": "Direxion Daily AVGO Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVSC": {
        "symbol": "AVSC",
        "name": "Avantis U.S. Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVSD": {
        "symbol": "AVSD",
        "name": "Avantis Responsible International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVSE": {
        "symbol": "AVSE",
        "name": "Avantis Responsible Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVSF": {
        "symbol": "AVSF",
        "name": "Avantis Short-Term Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVSU": {
        "symbol": "AVSU",
        "name": "Avantis Responsible U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVTR": {
        "symbol": "AVTR",
        "name": "Avantor, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVUS": {
        "symbol": "AVUS",
        "name": "Avantis U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVUV": {
        "symbol": "AVUV",
        "name": "Avantis U.S. Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVXC": {
        "symbol": "AVXC",
        "name": "Avantis Emerging Markets ex-China Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AVY": {
        "symbol": "AVY",
        "name": "Avery Dennison Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWAY": {
        "symbol": "AWAY",
        "name": "Amplify Travel Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWEG": {
        "symbol": "AWEG",
        "name": "Alger Weatherbie Enduring Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWF": {
        "symbol": "AWF",
        "name": "ALLIANCEBERSTEIN GLOBAL HIGH INCOME FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWI": {
        "symbol": "AWI",
        "name": "Armstrong World Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWK": {
        "symbol": "AWK",
        "name": "American Water Works Company, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWP": {
        "symbol": "AWP",
        "name": "abrdn Global Premier Properties Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWR": {
        "symbol": "AWR",
        "name": "American States Water Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AWX": {
        "symbol": "AWX",
        "name": "Avalon Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AX": {
        "symbol": "AX",
        "name": "Axos Financial, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXIL": {
        "symbol": "AXIL",
        "name": "AXIL Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXL": {
        "symbol": "AXL",
        "name": "American Axle & Manufacturing Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXP": {
        "symbol": "AXP",
        "name": "American Express Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXR": {
        "symbol": "AXR",
        "name": "AMREP Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXS": {
        "symbol": "AXS",
        "name": "Axis Capital Holders Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXS PRE": {
        "symbol": "AXS PRE",
        "name": "AXIS Capital Holdings Limited Depositary Shares, each representing 1/100th interest in a share of th",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AXTA": {
        "symbol": "AXTA",
        "name": "Axalta Coating Systems Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AYI": {
        "symbol": "AYI",
        "name": "Acuity Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZEK": {
        "symbol": "AZEK",
        "name": "The AZEK Company Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZNH": {
        "symbol": "AZNH",
        "name": "AstraZeneca PLC ADRhedged",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZO": {
        "symbol": "AZO",
        "name": "AutoZone, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZTD": {
        "symbol": "AZTD",
        "name": "Aztlan Global Stock Selection DM SMID ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZTR": {
        "symbol": "AZTR",
        "name": "Azitra Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZUL": {
        "symbol": "AZUL",
        "name": "Azul S.A. American Depositary Shares (each representing three preferred shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "AZZ": {
        "symbol": "AZZ",
        "name": "AZZ Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "B": {
        "symbol": "B",
        "name": "Barnes Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BA": {
        "symbol": "BA",
        "name": "Boeing Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAB": {
        "symbol": "BAB",
        "name": "Invesco Taxable Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BABA": {
        "symbol": "BABA",
        "name": "Alibaba Group Holding Limited American Depositary Shares, each represents eight Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BABO": {
        "symbol": "BABO",
        "name": "YieldMax BABA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BABX": {
        "symbol": "BABX",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long BABA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC": {
        "symbol": "BAC",
        "name": "Bank of America Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRB": {
        "symbol": "BAC PRB",
        "name": "Bank of America Corporation Depositary Shares, each representing a 1/1,000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRE": {
        "symbol": "BAC PRE",
        "name": "Bank of America Corporation Depositary Shares (representing 1/1,000th of a share of Floating Rate No",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRK": {
        "symbol": "BAC PRK",
        "name": "Bank of America Corporation Depositary Shares, each representing a 1/1,000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRL": {
        "symbol": "BAC PRL",
        "name": "Bank Of America Corporation 7.25% Non-Cumulative Perpetual Convertible Preferred Stock, Series L",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRM": {
        "symbol": "BAC PRM",
        "name": "Bank of America Corporation Depositary Shares, each representing a 1/1,000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRN": {
        "symbol": "BAC PRN",
        "name": "Bank of America Corporation Depositary shares, each representing 1/1,000th interest in a share of 5.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRO": {
        "symbol": "BAC PRO",
        "name": "Bank of America Corporation Depositary shares, each representing 1/1,000th interest in a share of 4.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRP": {
        "symbol": "BAC PRP",
        "name": "Bank of America Corporation Depositary Shares, each representing a 1/1,000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRQ": {
        "symbol": "BAC PRQ",
        "name": "Bank of America Corporation Depositary shares, each representing 1/1,000th interest in a share of 4.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAC PRS": {
        "symbol": "BAC PRS",
        "name": "Bank of America Corporation Depositary shares, each representing 1/1,000th interest in a share of 4.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAH": {
        "symbol": "BAH",
        "name": "Booz Allen Hamilton Holding Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAK": {
        "symbol": "BAK",
        "name": "Braskem S.A. American Depositary Shares (Each representing Two Class A  Preferred Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BALI": {
        "symbol": "BALI",
        "name": "iShares Advantage Large Cap Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BALL": {
        "symbol": "BALL",
        "name": "Ball Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BALT": {
        "symbol": "BALT",
        "name": "Innovator Defined Wealth Shield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BALY": {
        "symbol": "BALY",
        "name": "Bally's Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAM": {
        "symbol": "BAM",
        "name": "Brookfield Asset Management Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMA": {
        "symbol": "BAMA",
        "name": "Brookstone Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMB": {
        "symbol": "BAMB",
        "name": "Brookstone Intermediate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMD": {
        "symbol": "BAMD",
        "name": "Brookstone Dividend Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMG": {
        "symbol": "BAMG",
        "name": "Brookstone Growth Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMO": {
        "symbol": "BAMO",
        "name": "Brookstone Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMU": {
        "symbol": "BAMU",
        "name": "Brookstone Ultra-Short Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMV": {
        "symbol": "BAMV",
        "name": "Brookstone Value Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAMY": {
        "symbol": "BAMY",
        "name": "Brookstone Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BANC": {
        "symbol": "BANC",
        "name": "Banc of California, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BANC PRF": {
        "symbol": "BANC PRF",
        "name": "Banc of California, Inc. Depositary Shares, each representing a 1/40th interest in a share of 7.75%",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAP": {
        "symbol": "BAP",
        "name": "Credicorp LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAPR": {
        "symbol": "BAPR",
        "name": "Innovator U.S. Equity Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAR": {
        "symbol": "BAR",
        "name": "GraniteShares Gold Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BARK": {
        "symbol": "BARK",
        "name": "BARK, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BARK WS": {
        "symbol": "BARK WS",
        "name": "BARK, Inc. Redeemable Warrants, each whole warrant exercisable for shares of Common Stock at an exer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BATL": {
        "symbol": "BATL",
        "name": "Battalion Oil Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BATT": {
        "symbol": "BATT",
        "name": "Amplify Lithium & Battery Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAUG": {
        "symbol": "BAUG",
        "name": "Innovator U.S. Equity Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAX": {
        "symbol": "BAX",
        "name": "Baxter International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAYAR": {
        "symbol": "BAYAR",
        "name": "Bayview Acquisition Corp Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BAYAU": {
        "symbol": "BAYAU",
        "name": "Bayview Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BB": {
        "symbol": "BB",
        "name": "BlackBerry Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBAG": {
        "symbol": "BBAG",
        "name": "JPMorgan BetaBuilders U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBAI": {
        "symbol": "BBAI",
        "name": "BigBear.ai Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBAI WS": {
        "symbol": "BBAI WS",
        "name": "BigBear.ai Holdings, Inc. Redeemable warrants, each full warrant exercisable for one share of common",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBAR": {
        "symbol": "BBAR",
        "name": "Banco BBVA Argentina S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBAX": {
        "symbol": "BBAX",
        "name": "JPMorgan BetaBuilders Developed Asia Pacific-ex Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBBI": {
        "symbol": "BBBI",
        "name": "BondBloxx BBB Rated 5-10 Year Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBBL": {
        "symbol": "BBBL",
        "name": "BondBloxx BBB Rated 10+ Year Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBBS": {
        "symbol": "BBBS",
        "name": "BondBloxx BBB Rated 1-5 Year Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBC": {
        "symbol": "BBC",
        "name": "Virtus LifeSci Biotech Clinical Trials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBCA": {
        "symbol": "BBCA",
        "name": "JPMorgan BetaBuilders Canada ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBCB": {
        "symbol": "BBCB",
        "name": "JPMorgan BetaBuilders USD Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBD": {
        "symbol": "BBD",
        "name": "Banco Bradesco S.A. American Depositary Shares (Each representing one Preferred Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBDC": {
        "symbol": "BBDC",
        "name": "Barings BDC, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBDO": {
        "symbol": "BBDO",
        "name": "Banco Bradesco S.A. American Depositary Shares (Each representing one  Common Share, no par value)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBEM": {
        "symbol": "BBEM",
        "name": "JPMorgan BetaBuilders Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBEU": {
        "symbol": "BBEU",
        "name": "JPMorgan BetaBuilders Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBH": {
        "symbol": "BBH",
        "name": "VanEck Biotech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBHY": {
        "symbol": "BBHY",
        "name": "JPMorgan BetaBuilders USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBIB": {
        "symbol": "BBIB",
        "name": "JPMorgan BetaBuilders U.S. Treasury Bond 3-10 Year ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBIN": {
        "symbol": "BBIN",
        "name": "JPMorgan BetaBuilders International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBIP": {
        "symbol": "BBIP",
        "name": "JPMorgan BetaBuilders U.S. TIPS 0-5 Year ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBJP": {
        "symbol": "BBJP",
        "name": "JPMorgan BetaBuilders Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBLB": {
        "symbol": "BBLB",
        "name": "JPMorgan BetaBuilders U.S. Treasury Bond 20+ Year ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBLGW": {
        "symbol": "BBLGW",
        "name": "Bone Biologics Corp Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBLU": {
        "symbol": "BBLU",
        "name": "EA Bridgeway Blue Chip ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBMC": {
        "symbol": "BBMC",
        "name": "JPMorgan BetaBuilders U.S. Mid Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBN": {
        "symbol": "BBN",
        "name": "BlackRock Taxable Municipal Bond Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBP": {
        "symbol": "BBP",
        "name": "Virtus LifeSci Biotech Products ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBRE": {
        "symbol": "BBRE",
        "name": "JPMorgan BetaBuilders MSCI U.S. REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBSA": {
        "symbol": "BBSA",
        "name": "JPMorgan BetaBuilders 1-5 Year U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBSB": {
        "symbol": "BBSB",
        "name": "JPMorgan BetaBuilders U.S. Treasury Bond 1-3 Year ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBSC": {
        "symbol": "BBSC",
        "name": "JPMorgan BetaBuilders U.S. Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBU": {
        "symbol": "BBU",
        "name": "Brookfield Business Partners L.P.Limited Partnership Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBUC": {
        "symbol": "BBUC",
        "name": "Brookfield Business Corporation Class A Exchangeable Subordinate Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBUS": {
        "symbol": "BBUS",
        "name": "JPMorgan BetaBuilders U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBVA": {
        "symbol": "BBVA",
        "name": "Banco Bilbao Vizcaya Argentaria, S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBW": {
        "symbol": "BBW",
        "name": "Build-A-Bear Workshop, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBWI": {
        "symbol": "BBWI",
        "name": "Bath & Body Works, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BBY": {
        "symbol": "BBY",
        "name": "Best Buy Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BC": {
        "symbol": "BC",
        "name": "Brunswick Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BC PRA": {
        "symbol": "BC PRA",
        "name": "Brunswick Corporation 6.500% Senior Notes due 2048",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BC PRB": {
        "symbol": "BC PRB",
        "name": "Brunswick Corporation 6.625% Senior Notes due 2049",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BC PRC": {
        "symbol": "BC PRC",
        "name": "Brunswick Corporation 6.375% Notes due 2049",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCAT": {
        "symbol": "BCAT",
        "name": "BlackRock Capital Allocation Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCC": {
        "symbol": "BCC",
        "name": "Boise Cascade Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCD": {
        "symbol": "BCD",
        "name": "abrdn Bloomberg All Commodity Longer Dated Strategy K-1 Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCDF": {
        "symbol": "BCDF",
        "name": "Horizon Kinetics Blockchain Development ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCE": {
        "symbol": "BCE",
        "name": "BCE, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCH": {
        "symbol": "BCH",
        "name": "Banco de ChileAmerican Depositary Shares (Each representing 200 shares of Common Stock)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCHP": {
        "symbol": "BCHP",
        "name": "Principal Focused Blue Chip ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCI": {
        "symbol": "BCI",
        "name": "abrdn Bloomberg All Commodity Strategy K-1 Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCIL": {
        "symbol": "BCIL",
        "name": "Bancreek International Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCIM": {
        "symbol": "BCIM",
        "name": "abrdn Bloomberg Industrial Metals Strategy K-1 Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCO": {
        "symbol": "BCO",
        "name": "The Brink's Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCS": {
        "symbol": "BCS",
        "name": "Barclays PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCSF": {
        "symbol": "BCSF",
        "name": "Bain Capital Specialty Finance, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCUS": {
        "symbol": "BCUS",
        "name": "Bancreek U.S. Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCV": {
        "symbol": "BCV",
        "name": "Bancroft Fund Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCV PRA": {
        "symbol": "BCV PRA",
        "name": "Bancroft Fund Ltd 5.375% Series A Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BCX": {
        "symbol": "BCX",
        "name": "BLACKROCK RESOURCES & COMMODITIES STRATEGY TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDC": {
        "symbol": "BDC",
        "name": "Belden Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDCX": {
        "symbol": "BDCX",
        "name": "ETRACS Quarterly Pay 1.5x Leveraged MarketVector BDC Liquid Index ETN due June 10, 2050",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDCZ": {
        "symbol": "BDCZ",
        "name": "ETRACS MarketVector Business Development Companies Liquid Index ETN due April 26, 2041",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDEC": {
        "symbol": "BDEC",
        "name": "Innovator U.S. Equity Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDGS": {
        "symbol": "BDGS",
        "name": "Bridges Capital Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDIV": {
        "symbol": "BDIV",
        "name": "AAM Brentview Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDJ": {
        "symbol": "BDJ",
        "name": "BlackRock Enhanced Equity Dividend Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDL": {
        "symbol": "BDL",
        "name": "Flanigan's Enterprises Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDMD": {
        "symbol": "BDMD",
        "name": "Baird Medical Investment Holdings Ltd Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDMDW": {
        "symbol": "BDMDW",
        "name": "Baird Medical Investment Holdings Ltd Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDN": {
        "symbol": "BDN",
        "name": "Brandywine Realty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDRY": {
        "symbol": "BDRY",
        "name": "Breakwave Dry Bulk Shipping ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDVG": {
        "symbol": "BDVG",
        "name": "IMGP Berkshire Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BDX": {
        "symbol": "BDX",
        "name": "Becton, Dickinson and Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BE": {
        "symbol": "BE",
        "name": "Bloom Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEDU": {
        "symbol": "BEDU",
        "name": "Bright Scholar Education Holdings Limited American Depositary Shares, each representing four (4) Cla",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEDZ": {
        "symbol": "BEDZ",
        "name": "AdvisorShares Hotel ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEEP": {
        "symbol": "BEEP",
        "name": "Mobile Infrastructure Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEEZ": {
        "symbol": "BEEZ",
        "name": "Honeytree U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEKE": {
        "symbol": "BEKE",
        "name": "KE Holdings Inc. American Depositary Shares (each representing three Class A Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BELT": {
        "symbol": "BELT",
        "name": "BlackRock ETF Trust iShares Long-Term U.S. Equity Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEMB": {
        "symbol": "BEMB",
        "name": "iShares J.P. Morgan Broad USD Emerging Markets Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEN": {
        "symbol": "BEN",
        "name": "Franklin Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEP": {
        "symbol": "BEP",
        "name": "Brookfield Renewable Partners L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEP PRA": {
        "symbol": "BEP PRA",
        "name": "Brookfield Renewable Partners L.P. 5.25% Class A Preferred Limited Partnership Units, Series 17",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEPC": {
        "symbol": "BEPC",
        "name": "Brookfield Renewable Corporation Class A Subordinate Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEPH": {
        "symbol": "BEPH",
        "name": "Brookfield BRP Holdings (Canada) Inc. 4.625% Perpetual Subordinated Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEPI": {
        "symbol": "BEPI",
        "name": "Brookfield BRP Holdings (Canada) Inc. 4.875% Perpetual Subordinated Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEPJ": {
        "symbol": "BEPJ",
        "name": "Brookfield BRP Holdings (Canada) Inc. 7.250% Perpetual Subordinated Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BERY": {
        "symbol": "BERY",
        "name": "Berry Global Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BERZ": {
        "symbol": "BERZ",
        "name": "MicroSectors FANG & Innovation -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BEST": {
        "symbol": "BEST",
        "name": "BEST Inc. American Depositary Shares, each representing twenty (20) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BETE": {
        "symbol": "BETE",
        "name": "ProShares Bitcoin & Ether Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BETH": {
        "symbol": "BETH",
        "name": "ProShares Bitcoin & Ether Market Cap Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BETZ": {
        "symbol": "BETZ",
        "name": "Roundhill Sports Betting & iGaming ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BF A": {
        "symbol": "BF A",
        "name": "Brown-Forman Corporation Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BF B": {
        "symbol": "BF B",
        "name": "Brown-Forman Corporation Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFAC": {
        "symbol": "BFAC",
        "name": "Battery Future Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFAC U": {
        "symbol": "BFAC U",
        "name": "Battery Future Acquisition Corp. Units, each consisting of one Class A ordinary share and one-half o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFAC WS": {
        "symbol": "BFAC WS",
        "name": "Battery Future Acquisition Corp. Warrants, each whole warrant exercisable for one Class A ordinary s",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFAM": {
        "symbol": "BFAM",
        "name": "BRIGHT HORIZONS FAMILY SOLUTIONS INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFEB": {
        "symbol": "BFEB",
        "name": "Innovator U.S. Equity Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFH": {
        "symbol": "BFH",
        "name": "Bread Financial Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFIX": {
        "symbol": "BFIX",
        "name": "Build Bond Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFK": {
        "symbol": "BFK",
        "name": "BlackRock Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFLY": {
        "symbol": "BFLY",
        "name": "Butterfly Network, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFLY WS": {
        "symbol": "BFLY WS",
        "name": "Butterfly Network, Inc. Redeemable Warrants, each whole warrant exercisable for one share of Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFOR": {
        "symbol": "BFOR",
        "name": "Barron's 400 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFS": {
        "symbol": "BFS",
        "name": "Saul Centers, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFS PRD": {
        "symbol": "BFS PRD",
        "name": "Saul Centers, Inc. Depositary Shares, each representing 1/100th of a share of 6.125% Series D Cumula",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFS PRE": {
        "symbol": "BFS PRE",
        "name": "Saul Centers, Inc. Depositary Shares, each representing 1/100th of a share of 6.000% Series E Cumula",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BFZ": {
        "symbol": "BFZ",
        "name": "BLACKROCK CA MUNICIPAL INC TR",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BG": {
        "symbol": "BG",
        "name": "Bunge Global SA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGB": {
        "symbol": "BGB",
        "name": "Blackstone Strategic Credit 2027 Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGH": {
        "symbol": "BGH",
        "name": "Barings Global Short Duration High Yield Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGI": {
        "symbol": "BGI",
        "name": "Birks Group Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGIG": {
        "symbol": "BGIG",
        "name": "Bahl & Gaynor Income Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGLD": {
        "symbol": "BGLD",
        "name": "FT Vest Gold Strategy Quarterly Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGR": {
        "symbol": "BGR",
        "name": "Blackrock Energy and Resources Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGRN": {
        "symbol": "BGRN",
        "name": "iShares Trust iShares USD Green Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGRO": {
        "symbol": "BGRO",
        "name": "BlackRock ETF Trust iShares Large Cap Growth Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGS": {
        "symbol": "BGS",
        "name": "B&G Foods, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGSF": {
        "symbol": "BGSF",
        "name": "BGSF, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGT": {
        "symbol": "BGT",
        "name": "BlackRock Floating Rate Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGX": {
        "symbol": "BGX",
        "name": "Blackstone Long-Short Credit Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BGY": {
        "symbol": "BGY",
        "name": "BlackRock Enhanced International Dividend Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BH": {
        "symbol": "BH",
        "name": "Biglari Holdings Inc. Class B Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BH A": {
        "symbol": "BH A",
        "name": "Biglari Holdings Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHACU": {
        "symbol": "BHACU",
        "name": "Focus Impact BH3 Acquisition Company Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHB": {
        "symbol": "BHB",
        "name": "Bar Harbor Bankshares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHC": {
        "symbol": "BHC",
        "name": "Bausch\u00c3\u00bfHealth\u00c3\u00bfCompanies\u00c3\u00bfInc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHE": {
        "symbol": "BHE",
        "name": "Benchmark Electronics",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHK": {
        "symbol": "BHK",
        "name": "BLACKROCK CORE BD TR",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHLB": {
        "symbol": "BHLB",
        "name": "Berkshire Hills Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHM": {
        "symbol": "BHM",
        "name": "Bluerock Homes Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHP": {
        "symbol": "BHP",
        "name": "BHP Group Limited American Depositary Shares (Each representing two Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHR": {
        "symbol": "BHR",
        "name": "Braemar Hotels & Resorts Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHR PRB": {
        "symbol": "BHR PRB",
        "name": "Braemar Hotels & Resorts Inc. 5.50% Series B Cumulative Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHR PRD": {
        "symbol": "BHR PRD",
        "name": "Braemar Hotels & Resorts Inc. 8.25% Series D Cumulative Preferred Stock, par value $0.01 per share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHV": {
        "symbol": "BHV",
        "name": "BlackRock Virginia Muni Bond Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHVN": {
        "symbol": "BHVN",
        "name": "Biohaven Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BHYB": {
        "symbol": "BHYB",
        "name": "Xtrackers USD High Yield BB-B ex Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIB": {
        "symbol": "BIB",
        "name": "ProShares Ultra NASDAQ Biotechnology",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIBL": {
        "symbol": "BIBL",
        "name": "Inspire 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIGZ": {
        "symbol": "BIGZ",
        "name": "BlackRock Innovation and Growth Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIL": {
        "symbol": "BIL",
        "name": "SPDR Bloomberg 1-3 Month T-Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BILD": {
        "symbol": "BILD",
        "name": "Macquarie Global Listed Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BILL": {
        "symbol": "BILL",
        "name": "BILL Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BILS": {
        "symbol": "BILS",
        "name": "SPDR Bloomberg 3-12 Month T-Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BILZ": {
        "symbol": "BILZ",
        "name": "PIMCO Ultra Short Government Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BINC": {
        "symbol": "BINC",
        "name": "iShares Flexible Income Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BINV": {
        "symbol": "BINV",
        "name": "Brandes International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIO": {
        "symbol": "BIO",
        "name": "Bio-Rad Laboratories, Inc.Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIO B": {
        "symbol": "BIO B",
        "name": "Bio-Rad Laboratories, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIP": {
        "symbol": "BIP",
        "name": "Brookfield Infrastructure Partners L.P. Limited Partnership Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIP PRA": {
        "symbol": "BIP PRA",
        "name": "Brookfield Infrastructure Partners L.P. 5.125% Class A Preferred Limited Partnership Units, Series 1",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIP PRB": {
        "symbol": "BIP PRB",
        "name": "Brookfield Infrastructure Partners L.P. 5.000% Class A Preferred Limited Partnership Units, Series 1",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIPC": {
        "symbol": "BIPC",
        "name": "Brookfield Infrastructure Corporation Class A Subordinate Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIPH": {
        "symbol": "BIPH",
        "name": "Brookfield Infrastructure Finance ULC 5.000% Subordinated Notes due 2081",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIPI": {
        "symbol": "BIPI",
        "name": "BIP Bermuda Holdings I Limited 5.125% Perpetual Subordinated Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIPJ": {
        "symbol": "BIPJ",
        "name": "Brookfield Infrastructure Finance ULC 7.250% Subordinated Notes due 2084",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIRK": {
        "symbol": "BIRK",
        "name": "Birkenstock Holding plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIS": {
        "symbol": "BIS",
        "name": "ProShares UltraShort NASDAQ Biotechnology",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIT": {
        "symbol": "BIT",
        "name": "BLACKROCK MULTI-SECTOR INCOME TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITB": {
        "symbol": "BITB",
        "name": "Bitwise Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITC": {
        "symbol": "BITC",
        "name": "Bitwise Bitcoin Strategy Optimum Roll ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITI": {
        "symbol": "BITI",
        "name": "ProShares Short Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITO": {
        "symbol": "BITO",
        "name": "ProShares Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITQ": {
        "symbol": "BITQ",
        "name": "Bitwise Crypto Industry Innovators ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITS": {
        "symbol": "BITS",
        "name": "Global X Blockchain & Bitcoin Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITU": {
        "symbol": "BITU",
        "name": "ProShares Ultra Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BITX": {
        "symbol": "BITX",
        "name": "2x Bitcoin Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIV": {
        "symbol": "BIV",
        "name": "Vanguard Intermediate-Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BIZD": {
        "symbol": "BIZD",
        "name": "VanEck BDC Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BJ": {
        "symbol": "BJ",
        "name": "BJs Wholesale Club Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BJAN": {
        "symbol": "BJAN",
        "name": "Innovator U.S. Equity Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BJK": {
        "symbol": "BJK",
        "name": "VanEck Gaming ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BJUL": {
        "symbol": "BJUL",
        "name": "Innovator U.S. Equity Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BJUN": {
        "symbol": "BJUN",
        "name": "Innovator U.S. Equity Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BK": {
        "symbol": "BK",
        "name": "Bank of New York Mellon Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKAG": {
        "symbol": "BKAG",
        "name": "BNY Mellon Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKCH": {
        "symbol": "BKCH",
        "name": "Global X Blockchain ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKCI": {
        "symbol": "BKCI",
        "name": "BNY Mellon Concentrated International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKD": {
        "symbol": "BKD",
        "name": "Brookdale Senior Living, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKDT": {
        "symbol": "BKDT",
        "name": "Brookdale Senior Living Inc. 7.00% Tangible Equity Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKE": {
        "symbol": "BKE",
        "name": "The Buckle, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKEM": {
        "symbol": "BKEM",
        "name": "BNY Mellon Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKF": {
        "symbol": "BKF",
        "name": "iShares MSCI BIC ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKGI": {
        "symbol": "BKGI",
        "name": "BNY Mellon Global Infrastructure Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKH": {
        "symbol": "BKH",
        "name": "Black Hills Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKHAR": {
        "symbol": "BKHAR",
        "name": "Black Hawk Acquisition Corporation Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKHAU": {
        "symbol": "BKHAU",
        "name": "Black Hawk Acquisition Corporation Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKHY": {
        "symbol": "BKHY",
        "name": "BNY Mellon High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKIE": {
        "symbol": "BKIE",
        "name": "BNY Mellon International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKIV": {
        "symbol": "BKIV",
        "name": "BNY Mellon Innovators ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKKT": {
        "symbol": "BKKT",
        "name": "Bakkt Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKKT WS": {
        "symbol": "BKKT WS",
        "name": "Bakkt Holdings, Inc Warrants to purchase Class A Common Stock Each Warrant entitles to purchase 1/25",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKLC": {
        "symbol": "BKLC",
        "name": "BNY Mellon US Large Cap Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKLN": {
        "symbol": "BKLN",
        "name": "Invesco Senior Loan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKMC": {
        "symbol": "BKMC",
        "name": "BNY Mellon US Mid Cap Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKN": {
        "symbol": "BKN",
        "name": "BlackRock Investment Quality Municipal Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKSE": {
        "symbol": "BKSE",
        "name": "BNY Mellon US Small Cap Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKSY": {
        "symbol": "BKSY",
        "name": "BlackSky Technology Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKSY WS": {
        "symbol": "BKSY WS",
        "name": "BlackSky Technology Inc. Redeemable Warrants, each whole Warrant exercisable for one-eighth (1/8th)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKT": {
        "symbol": "BKT",
        "name": "BlackRock Income Trust Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKTI": {
        "symbol": "BKTI",
        "name": "BK Technologies Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKU": {
        "symbol": "BKU",
        "name": "Bankunited, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKUI": {
        "symbol": "BKUI",
        "name": "BNY Mellon Ultra Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKV": {
        "symbol": "BKV",
        "name": "BKV Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BKWO": {
        "symbol": "BKWO",
        "name": "BNY Mellon Women's Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLACR": {
        "symbol": "BLACR",
        "name": "Bellevue Life Sciences Acquisition Corp. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLACU": {
        "symbol": "BLACU",
        "name": "Bellevue Life Sciences Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLACW": {
        "symbol": "BLACW",
        "name": "Bellevue Life Sciences Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLCN": {
        "symbol": "BLCN",
        "name": "Siren ETF Trust Siren Nasdaq NexGen Economy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLCO": {
        "symbol": "BLCO",
        "name": "Bausch + Lomb Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLCR": {
        "symbol": "BLCR",
        "name": "BlackRock ETF Trust iShares Large Cap Core Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLCV": {
        "symbol": "BLCV",
        "name": "iShares Large Cap Value Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLD": {
        "symbol": "BLD",
        "name": "TopBuild Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLDG": {
        "symbol": "BLDG",
        "name": "Cambria Global Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLDR": {
        "symbol": "BLDR",
        "name": "Builders FirstSource, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLE": {
        "symbol": "BLE",
        "name": "BlackRock Municipal Income Trust II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLES": {
        "symbol": "BLES",
        "name": "Inspire Global Hope ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLK": {
        "symbol": "BLK",
        "name": "Blackrock, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLKC": {
        "symbol": "BLKC",
        "name": "Invesco Alerian Galaxy Blockchain Users and Decentralized Commerce ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLLD": {
        "symbol": "BLLD",
        "name": "JPMorgan Sustainable Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLND": {
        "symbol": "BLND",
        "name": "Blend Labs, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLOK": {
        "symbol": "BLOK",
        "name": "Amplify Transformational Data Sharing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLV": {
        "symbol": "BLV",
        "name": "Vanguard Long-Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLW": {
        "symbol": "BLW",
        "name": "Blackrock Limited Duration Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BLX": {
        "symbol": "BLX",
        "name": "Banco Latinoamericano de Comercio Exterior, S.A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMA": {
        "symbol": "BMA",
        "name": "Banco Macro S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMAR": {
        "symbol": "BMAR",
        "name": "Innovator U.S. Equity Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMAY": {
        "symbol": "BMAY",
        "name": "Innovator U.S. Equity Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMDL": {
        "symbol": "BMDL",
        "name": "VictoryShares WestEnd Economic Cycle Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BME": {
        "symbol": "BME",
        "name": "Blackrock Health Sciences Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMED": {
        "symbol": "BMED",
        "name": "iShares Health Innovation Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMEZ": {
        "symbol": "BMEZ",
        "name": "BlackRock Health Sciences Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMI": {
        "symbol": "BMI",
        "name": "Badger Meter, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BML PRG": {
        "symbol": "BML PRG",
        "name": "Bank Of America Corporation Depositary Shares(Each representing a 1/1200th interest in a share of Ba",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BML PRH": {
        "symbol": "BML PRH",
        "name": "Bank Of America Corporation Depositary Shares(Each representing a 1/1200th interest in a share of Ba",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BML PRJ": {
        "symbol": "BML PRJ",
        "name": "Bank of America Depositary Shares(Each representing 1/1200th interest in a share of Bank of America",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BML PRL": {
        "symbol": "BML PRL",
        "name": "Bank of America Depositary Shares(Each representing 1/1200th interest in a share of Bank of America",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMN": {
        "symbol": "BMN",
        "name": "BlackRock 2037 Municipal Target Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMO": {
        "symbol": "BMO",
        "name": "Bank of Montreal",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMTX": {
        "symbol": "BMTX",
        "name": "BM Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMTX WS": {
        "symbol": "BMTX WS",
        "name": "BM Technologies, Inc. Warrants, each warrant exercisable for one share of Common Stock at an exercis",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMVP": {
        "symbol": "BMVP",
        "name": "Invesco Bloomberg MVP Multi-factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BMY": {
        "symbol": "BMY",
        "name": "Bristol-Myers Squibb Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BN": {
        "symbol": "BN",
        "name": "Brookfield Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BND": {
        "symbol": "BND",
        "name": "Vanguard Total Bond Market",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNDC": {
        "symbol": "BNDC",
        "name": "FlexShares Core Select Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNDD": {
        "symbol": "BNDD",
        "name": "Quadratic Deflation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNDI": {
        "symbol": "BNDI",
        "name": "NEOS Enhanced Income Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNDW": {
        "symbol": "BNDW",
        "name": "Vanguard Total World Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNDX": {
        "symbol": "BNDX",
        "name": "Vanguard Total International Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNED": {
        "symbol": "BNED",
        "name": "Barnes & Noble Education, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNGE": {
        "symbol": "BNGE",
        "name": "First Trust S-Network Streaming and Gaming ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNH": {
        "symbol": "BNH",
        "name": "Brookfield Finance Inc. 4.625% Subordinated Notes due October 16, 2080",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNIX": {
        "symbol": "BNIX",
        "name": "Bannix Acquisition Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNIXR": {
        "symbol": "BNIXR",
        "name": "Bannix Acquisition Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNJ": {
        "symbol": "BNJ",
        "name": "Brookfield Finance I (UK) plc 4.50% Perpetual Subordinated Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNL": {
        "symbol": "BNL",
        "name": "Broadstone Net Lease, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNO": {
        "symbol": "BNO",
        "name": "United States Brent Oil Fund, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNOV": {
        "symbol": "BNOV",
        "name": "Innovator U.S. Equity Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNS": {
        "symbol": "BNS",
        "name": "Bank of Nova Scotia",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNT": {
        "symbol": "BNT",
        "name": "Brookfield Wealth Solutions Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BNY": {
        "symbol": "BNY",
        "name": "BlackRock New York Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOAT": {
        "symbol": "BOAT",
        "name": "SonicShares Global Shipping ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOC": {
        "symbol": "BOC",
        "name": "Boston Omaha Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOCNU": {
        "symbol": "BOCNU",
        "name": "Blue Ocean Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOCT": {
        "symbol": "BOCT",
        "name": "Innovator U.S. Equity Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BODI": {
        "symbol": "BODI",
        "name": "The Beachbody Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOE": {
        "symbol": "BOE",
        "name": "BlackRock Enhanced Global Dividend Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOH": {
        "symbol": "BOH",
        "name": "Bank of Hawaii Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOH PRA": {
        "symbol": "BOH PRA",
        "name": "Bank of Hawaii Corporation Depositary Shares Each Representing a 1/40th Interest in a Share of 4.375",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOH PRB": {
        "symbol": "BOH PRB",
        "name": "Bank of Hawaii Corporation Depositary Shares, Each Representing a 1/40th Interest in a Share of 8.00",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOIL": {
        "symbol": "BOIL",
        "name": "ProShares Ultra Bloomberg Natural Gas",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOND": {
        "symbol": "BOND",
        "name": "PIMCO Active Bond Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOOT": {
        "symbol": "BOOT",
        "name": "Boot Barn Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BORR": {
        "symbol": "BORR",
        "name": "Borr Drilling Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOTT": {
        "symbol": "BOTT",
        "name": "Themes Robotics & Automation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOTZ": {
        "symbol": "BOTZ",
        "name": "Global X Funds Global X Robotics & Artificial Intelligence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOUT": {
        "symbol": "BOUT",
        "name": "Innovator IBD Breakout Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOW": {
        "symbol": "BOW",
        "name": "Bowhead Specialty Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOWL": {
        "symbol": "BOWL",
        "name": "Bowlero Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOWNU": {
        "symbol": "BOWNU",
        "name": "Bowen Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOX": {
        "symbol": "BOX",
        "name": "BOX, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BOXX": {
        "symbol": "BOXX",
        "name": "Alpha Architect 1-3 Month Box ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BP": {
        "symbol": "BP",
        "name": "BP p.l.c.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BPAY": {
        "symbol": "BPAY",
        "name": "iShares FinTech Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BPT": {
        "symbol": "BPT",
        "name": "BP Prudhoe Bay Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BQ": {
        "symbol": "BQ",
        "name": "Boqii Holding Limited American Depositary Shares (each representing fifteen (15) Class A Ordinary Sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BR": {
        "symbol": "BR",
        "name": "Broadridge Financial Solutions Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRACU": {
        "symbol": "BRACU",
        "name": "Broad Capital Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRAZ": {
        "symbol": "BRAZ",
        "name": "Global X Brazil Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRBR": {
        "symbol": "BRBR",
        "name": "BellRing Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRBS": {
        "symbol": "BRBS",
        "name": "Blue Ridge Bankshares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRC": {
        "symbol": "BRC",
        "name": "Brady Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRCC": {
        "symbol": "BRCC",
        "name": "BRC Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRDG": {
        "symbol": "BRDG",
        "name": "Bridge Investment Group Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRF": {
        "symbol": "BRF",
        "name": "VanEck Brazil Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRFS": {
        "symbol": "BRFS",
        "name": "BRF - Brasil Foods S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRHY": {
        "symbol": "BRHY",
        "name": "BlackRock ETF Trust II iShares High Yield Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRK A": {
        "symbol": "BRK A",
        "name": "Berkshire Hathaway Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRK B": {
        "symbol": "BRK B",
        "name": "BERKSHIRE HATHAWAY Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRKH": {
        "symbol": "BRKH",
        "name": "BurTech Acquisition Corp. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRLN": {
        "symbol": "BRLN",
        "name": "iShares Floating Rate Loan Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRN": {
        "symbol": "BRN",
        "name": "Barnwell Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRNY": {
        "symbol": "BRNY",
        "name": "Burney U.S. Factor Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRO": {
        "symbol": "BRO",
        "name": "Brown & Brown, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BROS": {
        "symbol": "BROS",
        "name": "Dutch Bros Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRRR": {
        "symbol": "BRRR",
        "name": "Coinshares Valkyrie Bitcoin Fund Common Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRSP": {
        "symbol": "BRSP",
        "name": "BrightSpire Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRT": {
        "symbol": "BRT",
        "name": "BRT Apartments Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRTR": {
        "symbol": "BRTR",
        "name": "BlackRock ETF Trust II iShares Total Return Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRW": {
        "symbol": "BRW",
        "name": "Saba Capital Income & Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRX": {
        "symbol": "BRX",
        "name": "BRIXMOR PROPERTY GROUP INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BRZU": {
        "symbol": "BRZU",
        "name": "Direxion Daily MSCI Brazil Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSAC": {
        "symbol": "BSAC",
        "name": "Banco Santander-Chile",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSBR": {
        "symbol": "BSBR",
        "name": "BANCO SANTANDER (BRASIL) SA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCO": {
        "symbol": "BSCO",
        "name": "Invesco BulletShares 2024 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCP": {
        "symbol": "BSCP",
        "name": "Invesco BulletShares 2025 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCQ": {
        "symbol": "BSCQ",
        "name": "Invesco BulletShares 2026 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCR": {
        "symbol": "BSCR",
        "name": "Invesco BulletShares 2027 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCS": {
        "symbol": "BSCS",
        "name": "Invesco BulletShares 2028 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCT": {
        "symbol": "BSCT",
        "name": "Invesco BulletShares 2029 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCU": {
        "symbol": "BSCU",
        "name": "Invesco BulletShares 2030 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCV": {
        "symbol": "BSCV",
        "name": "Invesco BulletShares 2031 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCW": {
        "symbol": "BSCW",
        "name": "Invesco BulletShares 2032 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCX": {
        "symbol": "BSCX",
        "name": "Invesco BulletShares 2033 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSCY": {
        "symbol": "BSCY",
        "name": "Invesco BulletShares 2034 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSEP": {
        "symbol": "BSEP",
        "name": "Innovator U.S. Equity Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSIG": {
        "symbol": "BSIG",
        "name": "BrightSphere Investment Group Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSII": {
        "symbol": "BSII",
        "name": "Black Spade Acquisition II Co Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSIIW": {
        "symbol": "BSIIW",
        "name": "Black Spade Acquisition II Co Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJO": {
        "symbol": "BSJO",
        "name": "Invesco BulletShares 2024 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJP": {
        "symbol": "BSJP",
        "name": "Invesco BulletShares 2025 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJQ": {
        "symbol": "BSJQ",
        "name": "Invesco BulletShares 2026 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJR": {
        "symbol": "BSJR",
        "name": "Invesco BulletShares 2027 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJS": {
        "symbol": "BSJS",
        "name": "Invesco BulletShares 2028 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJT": {
        "symbol": "BSJT",
        "name": "Invesco BulletShares 2029 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJU": {
        "symbol": "BSJU",
        "name": "Invesco BulletShares 2030 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJV": {
        "symbol": "BSJV",
        "name": "Invesco BulletShares 2031 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSJW": {
        "symbol": "BSJW",
        "name": "Invesco BulletShares 2032 High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSL": {
        "symbol": "BSL",
        "name": "Blackstone Senior Floating Rate 2027 Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSM": {
        "symbol": "BSM",
        "name": "Black Stone Minerals, L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMC": {
        "symbol": "BSMC",
        "name": "Brandes U.S. Small-Mid Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMO": {
        "symbol": "BSMO",
        "name": "Invesco BulletShares 2024 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMP": {
        "symbol": "BSMP",
        "name": "Invesco BulletShares 2025 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMQ": {
        "symbol": "BSMQ",
        "name": "Invesco BulletShares 2026 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMR": {
        "symbol": "BSMR",
        "name": "Invesco BulletShares 2027 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMS": {
        "symbol": "BSMS",
        "name": "Invesco BulletShares 2028 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMT": {
        "symbol": "BSMT",
        "name": "Invesco BulletShares 2029 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMU": {
        "symbol": "BSMU",
        "name": "Invesco BulletShares 2030 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMV": {
        "symbol": "BSMV",
        "name": "Invesco BulletShares 2031 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMW": {
        "symbol": "BSMW",
        "name": "Invesco BulletShares 2032 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSMY": {
        "symbol": "BSMY",
        "name": "Invesco BulletShares 2034 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSR": {
        "symbol": "BSR",
        "name": "Beacon Selective Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSSX": {
        "symbol": "BSSX",
        "name": "Invesco BulletShares 2033 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BST": {
        "symbol": "BST",
        "name": "BlackRock Science and Technology Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSTP": {
        "symbol": "BSTP",
        "name": "Innovator Buffer Step-Up Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSTZ": {
        "symbol": "BSTZ",
        "name": "BlackRock Science and Technology Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSV": {
        "symbol": "BSV",
        "name": "Vanguard Short-Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSVO": {
        "symbol": "BSVO",
        "name": "EA Bridgeway Omni Small-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BSX": {
        "symbol": "BSX",
        "name": "Boston Scientific Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTA": {
        "symbol": "BTA",
        "name": "Blackrock Long-Term Municipal Advantage Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTAL": {
        "symbol": "BTAL",
        "name": "AGF U.S. Market Neutral Anti-Beta Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTC": {
        "symbol": "BTC",
        "name": "Grayscale Bitcoin Mini Trust (BTC)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTCL": {
        "symbol": "BTCL",
        "name": "T-Rex 2X Long Bitcoin Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTCM": {
        "symbol": "BTCM",
        "name": "BIT Mining Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTCO": {
        "symbol": "BTCO",
        "name": "Invesco Galaxy Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTCW": {
        "symbol": "BTCW",
        "name": "WisdomTree Bitcoin Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTCZ": {
        "symbol": "BTCZ",
        "name": "T-Rex 2X Inverse Bitcoin Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTE": {
        "symbol": "BTE",
        "name": "Baytex Energy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTF": {
        "symbol": "BTF",
        "name": "Valkyrie ETF Trust II Valkyrie Bitcoin and Ether Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTFX": {
        "symbol": "BTFX",
        "name": "Valkyrie Bitcoin Futures Leveraged Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTG": {
        "symbol": "BTG",
        "name": "B2Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTI": {
        "symbol": "BTI",
        "name": "British American Tobacco p.l.c. American Depositary Shares, American Depositary Shares, each represe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTO": {
        "symbol": "BTO",
        "name": "John Hancock Financial Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTOP": {
        "symbol": "BTOP",
        "name": "Bitwise Bitcoin and Ether Equal Weight Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTR": {
        "symbol": "BTR",
        "name": "Beacon Tactical Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTRN": {
        "symbol": "BTRN",
        "name": "Global X Bitcoin Trend Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTT": {
        "symbol": "BTT",
        "name": "BlackRock Municipal 2030 Target Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTTR": {
        "symbol": "BTTR",
        "name": "Better Choice Company Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTU": {
        "symbol": "BTU",
        "name": "Peabody Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTZ": {
        "symbol": "BTZ",
        "name": "BlackRock Credit Allocation Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUCK": {
        "symbol": "BUCK",
        "name": "Simplify Stable Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUD": {
        "symbol": "BUD",
        "name": "Anheuser-Busch INBEV SA/NV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFB": {
        "symbol": "BUFB",
        "name": "Innovator Laddered Allocation Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFC": {
        "symbol": "BUFC",
        "name": "AB Conservative Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFD": {
        "symbol": "BUFD",
        "name": "FT Vest Laddered Deep Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFF": {
        "symbol": "BUFF",
        "name": "Innovator Laddered Allocation Power Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFG": {
        "symbol": "BUFG",
        "name": "FT Vest Buffered Allocation Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFP": {
        "symbol": "BUFP",
        "name": "PGIM Laddered Fund of Buffer 12 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFQ": {
        "symbol": "BUFQ",
        "name": "FT Vest Laddered Nasdaq Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFR": {
        "symbol": "BUFR",
        "name": "FT Vest Laddered Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFS": {
        "symbol": "BUFS",
        "name": "FT Vest Laddered Small Cap Moderate Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFT": {
        "symbol": "BUFT",
        "name": "FT Vest Buffered Allocation Defensive ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFY": {
        "symbol": "BUFY",
        "name": "FT Vest Laddered International Moderate Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUFZ": {
        "symbol": "BUFZ",
        "name": "FT Vest Laddered Moderate Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUG": {
        "symbol": "BUG",
        "name": "Global X Cybersecurity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUI": {
        "symbol": "BUI",
        "name": "BlackRock Utilities, Infrastructure & Power Opportunities Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUL": {
        "symbol": "BUL",
        "name": "Pacer US Cash Cows Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BULD": {
        "symbol": "BULD",
        "name": "Pacer BlueStar Engineering the Future ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BULZ": {
        "symbol": "BULZ",
        "name": "MicroSectors FANG & Innovation 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUR": {
        "symbol": "BUR",
        "name": "Burford Capital Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BURL": {
        "symbol": "BURL",
        "name": "BURLINGTON STORES, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BURU": {
        "symbol": "BURU",
        "name": "Nuburu, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUSA": {
        "symbol": "BUSA",
        "name": "Brandes U.S. Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUXX": {
        "symbol": "BUXX",
        "name": "Strive Enhanced Income Short Maturity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUYO": {
        "symbol": "BUYO",
        "name": "KraneShares Man Buyout Beta Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUYW": {
        "symbol": "BUYW",
        "name": "Main BuyWrite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUYZ": {
        "symbol": "BUYZ",
        "name": "Franklin Disruptive Commerce ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BUZZ": {
        "symbol": "BUZZ",
        "name": "VanEck Social Sentiment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BV": {
        "symbol": "BV",
        "name": "BrightView Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BVN": {
        "symbol": "BVN",
        "name": "Compania de Minas Buenaventura S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BW": {
        "symbol": "BW",
        "name": "Babcock & Wilcox Enterprises, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BW PRA": {
        "symbol": "BW PRA",
        "name": "Babcock & Wilcox Enterprises, Inc. 7.75% Series A Cumulative Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWA": {
        "symbol": "BWA",
        "name": "BorgWarner Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWEB": {
        "symbol": "BWEB",
        "name": "Bitwise Web3 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWET": {
        "symbol": "BWET",
        "name": "Breakwave Tanker Shipping ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWG": {
        "symbol": "BWG",
        "name": "BrandywineGLOBAL Global Income Opportunities Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWLP": {
        "symbol": "BWLP",
        "name": "BW LPG Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWMX": {
        "symbol": "BWMX",
        "name": "Betterware de Mexico, S.A.P.I. de C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWNB": {
        "symbol": "BWNB",
        "name": "Babcock & Wilcox Enterprises, Inc. 6.50% Senior Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWSN": {
        "symbol": "BWSN",
        "name": "Babcock & Wilcox Enterprises, Inc. 8.125% Senior Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWTG": {
        "symbol": "BWTG",
        "name": "Brendan Wood TopGun Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWX": {
        "symbol": "BWX",
        "name": "SPDR Bloomberg International Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWXT": {
        "symbol": "BWXT",
        "name": "BWX Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BWZ": {
        "symbol": "BWZ",
        "name": "SPDR Bloomberg Short Term International Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BX": {
        "symbol": "BX",
        "name": "Blackstone Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BXC": {
        "symbol": "BXC",
        "name": "BlueLinx Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BXMT": {
        "symbol": "BXMT",
        "name": "Blackstone Mortgage Trust, Inc. (NEW)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BXMX": {
        "symbol": "BXMX",
        "name": "NUVEEN S&P BUY-WRITE INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BXP": {
        "symbol": "BXP",
        "name": "BXP, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BXSL": {
        "symbol": "BXSL",
        "name": "Blackstone Secured Lending Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BY": {
        "symbol": "BY",
        "name": "Byline Bancorp, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYD": {
        "symbol": "BYD",
        "name": "Boyd Gaming Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYLD": {
        "symbol": "BYLD",
        "name": "iShares Yield Optimized Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYM": {
        "symbol": "BYM",
        "name": "BLACKROCK MUNICIPAL INCOME QUALITY TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYNOU": {
        "symbol": "BYNOU",
        "name": "byNordic Acquisition Corporation Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYON": {
        "symbol": "BYON",
        "name": "Beyond, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYRE": {
        "symbol": "BYRE",
        "name": "Principal Real Estate Active Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BYU": {
        "symbol": "BYU",
        "name": "BAIYU Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BZH": {
        "symbol": "BZH",
        "name": "Beazer Homes USA, Inc. New",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BZQ": {
        "symbol": "BZQ",
        "name": "ProShares UltraShort MSCI Brazil Capped",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "C": {
        "symbol": "C",
        "name": "Citigroup Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "C PRN": {
        "symbol": "C PRN",
        "name": "Citigroup Capital XIII 7.875% Fixed Rate/Floating Rate Trust Preferred Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CA": {
        "symbol": "CA",
        "name": "Xtrackers California Municipal Bonds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAAA": {
        "symbol": "CAAA",
        "name": "First Trust Commercial Mortgage Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAAP": {
        "symbol": "CAAP",
        "name": "Corporacion America Airports S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CABO": {
        "symbol": "CABO",
        "name": "Cable One, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CACI": {
        "symbol": "CACI",
        "name": "CACI INTERNATIONAL CLA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CADE": {
        "symbol": "CADE",
        "name": "Cadence Bank",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CADE PRA": {
        "symbol": "CADE PRA",
        "name": "Cadence Bank 5.50% Series A Non- Cumulative Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAE": {
        "symbol": "CAE",
        "name": "CAE INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAF": {
        "symbol": "CAF",
        "name": "MORGAN STANLEY CHINA A SHARE FUND, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAFG": {
        "symbol": "CAFG",
        "name": "Pacer US Small Cap Cash Cows Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAFX": {
        "symbol": "CAFX",
        "name": "Congress Intermediate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAG": {
        "symbol": "CAG",
        "name": "Conagra Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAH": {
        "symbol": "CAH",
        "name": "Cardinal Health, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAL": {
        "symbol": "CAL",
        "name": "Caleres Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CALF": {
        "symbol": "CALF",
        "name": "Pacer US Small Cap Cash Cows 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CALI": {
        "symbol": "CALI",
        "name": "BlackRock ETF Trust II iShares Short-Term California Muni Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CALX": {
        "symbol": "CALX",
        "name": "CALIX, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAML": {
        "symbol": "CAML",
        "name": "Congress Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAMX": {
        "symbol": "CAMX",
        "name": "Cambiar Aggressive Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CANC": {
        "symbol": "CANC",
        "name": "Tema Oncology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CANE": {
        "symbol": "CANE",
        "name": "Teucrium Sugar Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CANF": {
        "symbol": "CANF",
        "name": "Can-Fite BioPharma Ltd. American Depositary Shares, each representing three hundred (300) Ordinary S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CANG": {
        "symbol": "CANG",
        "name": "Cango Inc. American Depositary Shares,  each representing two (2) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CANQ": {
        "symbol": "CANQ",
        "name": "Calamos Alternative Nasdaq & Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAOS": {
        "symbol": "CAOS",
        "name": "Alpha Architect Tail Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAPE": {
        "symbol": "CAPE",
        "name": "DoubleLine Shiller CAPE U.S. Equities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAPL": {
        "symbol": "CAPL",
        "name": "CrossAmerica Partners LP Common units representing limited partner interests",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAPTW": {
        "symbol": "CAPTW",
        "name": "Captivision Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARD": {
        "symbol": "CARD",
        "name": "MAX Auto Industry -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARK": {
        "symbol": "CARK",
        "name": "CastleArk Large Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARR": {
        "symbol": "CARR",
        "name": "Carrier Global Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARS": {
        "symbol": "CARS",
        "name": "Cars.com Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARU": {
        "symbol": "CARU",
        "name": "MAX Auto Industry 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARY": {
        "symbol": "CARY",
        "name": "Angel Oak Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CARZ": {
        "symbol": "CARZ",
        "name": "First Trust Exchange-Traded Fund II First Trust S-Network Future Vehicles & Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAT": {
        "symbol": "CAT",
        "name": "Caterpillar Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CATF": {
        "symbol": "CATF",
        "name": "American Century California Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CATH": {
        "symbol": "CATH",
        "name": "Global X S&P 500 Catholic Values ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CATO": {
        "symbol": "CATO",
        "name": "CATO CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CATX": {
        "symbol": "CATX",
        "name": "Perspective Therapeutics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CAVA": {
        "symbol": "CAVA",
        "name": "CAVA Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CB": {
        "symbol": "CB",
        "name": "Chubb Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBL": {
        "symbol": "CBL",
        "name": "CBL & Associates Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBLS": {
        "symbol": "CBLS",
        "name": "Clough Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBNA": {
        "symbol": "CBNA",
        "name": "Chain Bridge Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBO": {
        "symbol": "CBO",
        "name": "NYSE Listed Test Stock For CTS and CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBO PRA": {
        "symbol": "CBO PRA",
        "name": "NMS NYSE Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBOE": {
        "symbol": "CBOE",
        "name": "Cboe Global Markets, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBON": {
        "symbol": "CBON",
        "name": "VanEck China Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBRE": {
        "symbol": "CBRE",
        "name": "CBRE GROUP, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBRGU": {
        "symbol": "CBRGU",
        "name": "Chain Bridge I Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBSE": {
        "symbol": "CBSE",
        "name": "Clough Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBT": {
        "symbol": "CBT",
        "name": "Cabot Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBU": {
        "symbol": "CBU",
        "name": "Community Financial System, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBX": {
        "symbol": "CBX",
        "name": "NYSE Listed Test Stock For CTS and CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CBZ": {
        "symbol": "CBZ",
        "name": "CBIZ, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CC": {
        "symbol": "CC",
        "name": "The Chemours Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCEF": {
        "symbol": "CCEF",
        "name": "Calamos CEF Income & Arbitrage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCEL": {
        "symbol": "CCEL",
        "name": "Cryo-Cell International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCI": {
        "symbol": "CCI",
        "name": "Crown Castle Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCIA": {
        "symbol": "CCIA",
        "name": "Carlyle Credit Income Fund 8.75% Series A Preferred Shares due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCIF": {
        "symbol": "CCIF",
        "name": "Carlyle Credit Income Fund Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCIXU": {
        "symbol": "CCIXU",
        "name": "Churchill Capital Corp IX Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCIXW": {
        "symbol": "CCIXW",
        "name": "Churchill Capital Corp IX Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCJ": {
        "symbol": "CCJ",
        "name": "Cameco Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCK": {
        "symbol": "CCK",
        "name": "Crown Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCL": {
        "symbol": "CCL",
        "name": "Carnival Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCM": {
        "symbol": "CCM",
        "name": "Concord Medical Services Holding Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCMG": {
        "symbol": "CCMG",
        "name": "CCM Global Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCNR": {
        "symbol": "CCNR",
        "name": "Financial Investors Trust ALPS/CoreCommodity Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCO": {
        "symbol": "CCO",
        "name": "Clear Channel Outdoor Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCOR": {
        "symbol": "CCOR",
        "name": "Core Alternative Capital",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCRD": {
        "symbol": "CCRD",
        "name": "CoreCard Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCRV": {
        "symbol": "CCRV",
        "name": "iShares Commodity Curve Carry Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCS": {
        "symbol": "CCS",
        "name": "CENTURY COMMUNITIES, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCSB": {
        "symbol": "CCSB",
        "name": "Carbon Collective Short Duration Green Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCSO": {
        "symbol": "CCSO",
        "name": "Carbon Collective Climate Solutions U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCTS": {
        "symbol": "CCTS",
        "name": "Cactus Acquisition Corp. 1 Limited Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCTSU": {
        "symbol": "CCTSU",
        "name": "Cactus Acquisition Corp. 1 Limited Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCTSW": {
        "symbol": "CCTSW",
        "name": "Cactus Acquisition Corp. 1 Limited Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCU": {
        "symbol": "CCU",
        "name": "Compania Cervecerias Unidas S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CCZ": {
        "symbol": "CCZ",
        "name": "Comcast Holdings Corp. 2.0% Exchangeable Subordinated Debentures due October 15, 2029 (ZONES)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDAQW": {
        "symbol": "CDAQW",
        "name": "Compass Digital Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDC": {
        "symbol": "CDC",
        "name": "VictoryShares US EQ Income Enhanced Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDE": {
        "symbol": "CDE",
        "name": "Coeur Mining, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDEI": {
        "symbol": "CDEI",
        "name": "Calvert US Large-Cap Diversity, Equity and Inclusion Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDL": {
        "symbol": "CDL",
        "name": "VictoryShares US Large Cap High Div Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDLR": {
        "symbol": "CDLR",
        "name": "Cadeler A/S American Depositary Share (each representing four (4) Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDP": {
        "symbol": "CDP",
        "name": "COPT Defense Properties",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDR PRB": {
        "symbol": "CDR PRB",
        "name": "CEDAR REALTY TRUST, INC. 7.25% Series B Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDR PRC": {
        "symbol": "CDR PRC",
        "name": "Cedar Realty Trust, Inc. 6.50% Series C Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDRE": {
        "symbol": "CDRE",
        "name": "Cadre Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDTTW": {
        "symbol": "CDTTW",
        "name": "Conduit Pharmaceuticals Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CDX": {
        "symbol": "CDX",
        "name": "Simplify High Yield PLUS Credit Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CE": {
        "symbol": "CE",
        "name": "Celanese Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEE": {
        "symbol": "CEE",
        "name": "The Central and Eastern Europe Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEF": {
        "symbol": "CEF",
        "name": "Sprott Physical Gold and Silver Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEFA": {
        "symbol": "CEFA",
        "name": "Global X S&P Catholic Values Developed ex-U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEFD": {
        "symbol": "CEFD",
        "name": "ETRACS Monthly Pay 1.5X Leveraged Closed-End Fund Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEFS": {
        "symbol": "CEFS",
        "name": "Saba Closed-End Funds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEIX": {
        "symbol": "CEIX",
        "name": "CONSOL Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CELG RT": {
        "symbol": "CELG RT",
        "name": "Bristol-Myers Squibb Company Contingent Value Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEMB": {
        "symbol": "CEMB",
        "name": "iShares J.P. Morgan EM Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEPU": {
        "symbol": "CEPU",
        "name": "Central Puerto S.A. American Depositary Shares (each represents ten Common Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CERY": {
        "symbol": "CERY",
        "name": "SPDR Bloomberg Enhanced Roll Yield Commodity Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CET": {
        "symbol": "CET",
        "name": "Central Securities Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CETH": {
        "symbol": "CETH",
        "name": "21Shares Core Ethereum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEV": {
        "symbol": "CEV",
        "name": "Eaton Vance California Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CEW": {
        "symbol": "CEW",
        "name": "WisdomTree  Emerging Currency Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CF": {
        "symbol": "CF",
        "name": "CF Industries Holding, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFA": {
        "symbol": "CFA",
        "name": "VictoryShares US 500 Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFCV": {
        "symbol": "CFCV",
        "name": "ClearBridge Focus Value ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFFSU": {
        "symbol": "CFFSU",
        "name": "CF Acquisition Corp. VII Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFG": {
        "symbol": "CFG",
        "name": "Citizens Financial Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFG PRE": {
        "symbol": "CFG PRE",
        "name": "Citizens Financial Group, Inc.Depositary Shares Each Representing 1/40th Interest in a Share of 5.00",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFG PRH": {
        "symbol": "CFG PRH",
        "name": "Citizens Financial Group, Inc. Depositary Shares Each Representing a 1/40th Interest in a Share of 7",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFO": {
        "symbol": "CFO",
        "name": "VictoryShares US 500 Enhanced Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFR": {
        "symbol": "CFR",
        "name": "Cullen/Frost Bankers Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CFR PRB": {
        "symbol": "CFR PRB",
        "name": "Cullen/Frost Bankers, Inc. Depositary Shares, each representing a 1/40th ownership interest in a sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGA": {
        "symbol": "CGA",
        "name": "CHINA GREEN AGRICULTURE INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGAU": {
        "symbol": "CGAU",
        "name": "Centerra Gold Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGBL": {
        "symbol": "CGBL",
        "name": "Capital Group Core Balanced ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGCB": {
        "symbol": "CGCB",
        "name": "Capital Group Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGCP": {
        "symbol": "CGCP",
        "name": "Capital Group Core Plus Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGCV": {
        "symbol": "CGCV",
        "name": "Capital Group Conservative Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGDG": {
        "symbol": "CGDG",
        "name": "Capital Group Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGDV": {
        "symbol": "CGDV",
        "name": "Capital Group Dividend Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGGE": {
        "symbol": "CGGE",
        "name": "Capital Group Global Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGGO": {
        "symbol": "CGGO",
        "name": "Capital Group Global Growth Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGGR": {
        "symbol": "CGGR",
        "name": "Capital Group Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGHM": {
        "symbol": "CGHM",
        "name": "Capital Group Municipal High-Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGIB": {
        "symbol": "CGIB",
        "name": "Capital Group International Bond ETF (USD-Hedged)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGIC": {
        "symbol": "CGIC",
        "name": "Capital Group International Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGIE": {
        "symbol": "CGIE",
        "name": "Capital Group International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGMS": {
        "symbol": "CGMS",
        "name": "Capital Group U.S. Multi-Sector Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGMU": {
        "symbol": "CGMU",
        "name": "Capital Group Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGNG": {
        "symbol": "CGNG",
        "name": "Capital Group New Geography Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGRO": {
        "symbol": "CGRO",
        "name": "CoreValues Alpha Greater China Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGSD": {
        "symbol": "CGSD",
        "name": "Capital Group Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGSM": {
        "symbol": "CGSM",
        "name": "Capital Group Short Duration Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGUI": {
        "symbol": "CGUI",
        "name": "Capital Group Ultra Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGUS": {
        "symbol": "CGUS",
        "name": "Capital Group Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGV": {
        "symbol": "CGV",
        "name": "Conductor Global Equity Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGW": {
        "symbol": "CGW",
        "name": "Invesco S&P Global Water Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CGXU": {
        "symbol": "CGXU",
        "name": "Capital Group International Focus Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHAT": {
        "symbol": "CHAT",
        "name": "Roundhill Generative AI & Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHAU": {
        "symbol": "CHAU",
        "name": "Direxion Daily CSI 300 China A Share Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHCT": {
        "symbol": "CHCT",
        "name": "Community Healthcare Trust Incorporated Common Stock, $0.01 par value per share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHD": {
        "symbol": "CHD",
        "name": "Church & Dwight Co., Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHE": {
        "symbol": "CHE",
        "name": "Chemed Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHEB": {
        "symbol": "CHEB",
        "name": "Chenghe Acquisition II Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHEB U": {
        "symbol": "CHEB U",
        "name": "Chenghe Acquisition II Co. Units, each consisting of one Class A ordinary share and one-half of one",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHEB WS": {
        "symbol": "CHEB WS",
        "name": "Chenghe Acquisition II Co. Redeemable Warrants, each whole warrant exercisable for one Class A ordin",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHGG": {
        "symbol": "CHGG",
        "name": "CHEGG, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHGX": {
        "symbol": "CHGX",
        "name": "AXS Change Finance ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHH": {
        "symbol": "CHH",
        "name": "Choice Hotels Intnl.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHIQ": {
        "symbol": "CHIQ",
        "name": "Global X MSCI China Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHMI": {
        "symbol": "CHMI",
        "name": "CHERRY HILL MORTGAGE INVESTMENT CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHMI PRA": {
        "symbol": "CHMI PRA",
        "name": "Cherry Hill Mortgage Investment Corporation 8.20% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHMI PRB": {
        "symbol": "CHMI PRB",
        "name": "Cherry Hill Mortgage Investment Corporation 8.250% Series B Fixed-to-Floating Rate Cumulative Redeem",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHN": {
        "symbol": "CHN",
        "name": "CHINA FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHPS": {
        "symbol": "CHPS",
        "name": "Xtrackers Semiconductor Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHPT": {
        "symbol": "CHPT",
        "name": "ChargePoint Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHRO": {
        "symbol": "CHRO",
        "name": "Chromocell Therapeutics Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHT": {
        "symbol": "CHT",
        "name": "CHUNGHWA TELECOM CO., LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CHWY": {
        "symbol": "CHWY",
        "name": "Chewy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CI": {
        "symbol": "CI",
        "name": "The Cigna Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIA": {
        "symbol": "CIA",
        "name": "Citizens, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIB": {
        "symbol": "CIB",
        "name": "Bancolombia S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIBR": {
        "symbol": "CIBR",
        "name": "First Trust Exchange-Traded Fund II First Trust NASDAQ Cybersecurity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CICB": {
        "symbol": "CICB",
        "name": "CION Investment Corporation 7.50% Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CID": {
        "symbol": "CID",
        "name": "VictoryShares International High Div Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIEN": {
        "symbol": "CIEN",
        "name": "Ciena Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIF": {
        "symbol": "CIF",
        "name": "MFS Intermediate High Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIG": {
        "symbol": "CIG",
        "name": "Companhia Energetica De Minas Gerais-CEMIG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIG C": {
        "symbol": "CIG C",
        "name": "Companhia Energetica De Minas Gerais-CEMIG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CII": {
        "symbol": "CII",
        "name": "Blackrock Enhanced Captial and Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIK": {
        "symbol": "CIK",
        "name": "Credit Suisse Asset Management Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIL": {
        "symbol": "CIL",
        "name": "VictoryShares International Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIM": {
        "symbol": "CIM",
        "name": "Chimera Investment Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIM PRA": {
        "symbol": "CIM PRA",
        "name": "Chimera Investment Corporation 8.00% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIM PRB": {
        "symbol": "CIM PRB",
        "name": "Chimera Investment Corporation 8.00% Series B Fixed-to-Floating Rate Cumulative Redeemable Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIM PRC": {
        "symbol": "CIM PRC",
        "name": "Chimera Investment Corporation 7.75% Series C Fixed-to-Floating Rate  Cumulative Redeemable  Preferr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIM PRD": {
        "symbol": "CIM PRD",
        "name": "Chimera Investment Corporation 8.00% Series D Fixed-to-Floating Rate Cumulative Redeemable Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIMN": {
        "symbol": "CIMN",
        "name": "Chimera Investment Corporation 9.000% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIMO": {
        "symbol": "CIMO",
        "name": "Chimera Investment Corporation 9.250% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CINT": {
        "symbol": "CINT",
        "name": "CI&T Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIO": {
        "symbol": "CIO",
        "name": "CITY OFFICE REIT, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIO PRA": {
        "symbol": "CIO PRA",
        "name": "City Office REIT, Inc.  6.625% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CION": {
        "symbol": "CION",
        "name": "CION Investment Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CITEU": {
        "symbol": "CITEU",
        "name": "Cartica Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CITEW": {
        "symbol": "CITEW",
        "name": "Cartica Acquisition Corp Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIVI": {
        "symbol": "CIVI",
        "name": "Civitas Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIX": {
        "symbol": "CIX",
        "name": "Comp X International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CIZ": {
        "symbol": "CIZ",
        "name": "VictoryShares Developed Enhanced Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CKX": {
        "symbol": "CKX",
        "name": "CKX Lands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CL": {
        "symbol": "CL",
        "name": "Colgate-Palmolive Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLB": {
        "symbol": "CLB",
        "name": "Core Laboratories Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLBR": {
        "symbol": "CLBR",
        "name": "Colombier Acquisition Corp. II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLBR U": {
        "symbol": "CLBR U",
        "name": "Colombier Acquisition Corp. II Units, each consisting of one Class A ordinary share and one-third of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLBR WS": {
        "symbol": "CLBR WS",
        "name": "Colombier Acquisition Corp. II Warrants, each whole warrant exercisable for one Class A Ordinary Sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLCO": {
        "symbol": "CLCO",
        "name": "Cool Company Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLDI": {
        "symbol": "CLDI",
        "name": "Calidi Biotherapeutics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLDI WS": {
        "symbol": "CLDI WS",
        "name": "Calidi Biotherapeutics, Inc. Redeemable Warrants, each whole warrant exercisable for 1/10th of a sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLDL": {
        "symbol": "CLDL",
        "name": "Direxion Daily Cloud Computing Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLDT": {
        "symbol": "CLDT",
        "name": "CHATHAM LODGING TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLDT PRA": {
        "symbol": "CLDT PRA",
        "name": "Chatham Lodging Trust 6.625% Series A Cumulative Redeemable Preferred Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLF": {
        "symbol": "CLF",
        "name": "Cleveland-Cliffs Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLH": {
        "symbol": "CLH",
        "name": "Clean Harbors, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLIK": {
        "symbol": "CLIK",
        "name": "Click Holdings Limited Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLIP": {
        "symbol": "CLIP",
        "name": "Global X 1-3 Month T-Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLIX": {
        "symbol": "CLIX",
        "name": "ProShares Long Online/Short Stores ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLM": {
        "symbol": "CLM",
        "name": "Cornerstone Strategic Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLNR": {
        "symbol": "CLNR",
        "name": "NYLI Cleaner Transport ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOA": {
        "symbol": "CLOA",
        "name": "BlackRock ETF Trust II iShares AAA CLO Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOB": {
        "symbol": "CLOB",
        "name": "VanEck AA-BB CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOD": {
        "symbol": "CLOD",
        "name": "Themes Cloud Computing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOI": {
        "symbol": "CLOI",
        "name": "VanEck CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOU": {
        "symbol": "CLOU",
        "name": "Global X Cloud Computing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOX": {
        "symbol": "CLOX",
        "name": "Panagram AAA CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLOZ": {
        "symbol": "CLOZ",
        "name": "Panagram BBB-B CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLPR": {
        "symbol": "CLPR",
        "name": "Clipper Realty Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLRCU": {
        "symbol": "CLRCU",
        "name": "ClimateRock Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLRCW": {
        "symbol": "CLRCW",
        "name": "ClimateRock Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLS": {
        "symbol": "CLS",
        "name": "Celestica, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLSE": {
        "symbol": "CLSE",
        "name": "Convergence Long/Short Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLSM": {
        "symbol": "CLSM",
        "name": "Cabana Target Leading Sector Moderate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLVT": {
        "symbol": "CLVT",
        "name": "Clarivate Plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLW": {
        "symbol": "CLW",
        "name": "Clearwater Paper Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLX": {
        "symbol": "CLX",
        "name": "Clorox Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CLYM": {
        "symbol": "CLYM",
        "name": "Climb Bio, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CM": {
        "symbol": "CM",
        "name": "Canadian Imperial Bank of Commerce",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMA": {
        "symbol": "CMA",
        "name": "Comerica Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMBS": {
        "symbol": "CMBS",
        "name": "iShares CMBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMBT": {
        "symbol": "CMBT",
        "name": "CMB.TECH NV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMC": {
        "symbol": "CMC",
        "name": "Commercial Metals Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMCI": {
        "symbol": "CMCI",
        "name": "VanEck CMCI Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMCL": {
        "symbol": "CMCL",
        "name": "Caledonia Mining Corporation Plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMCM": {
        "symbol": "CMCM",
        "name": "Cheetah Mobile Inc. American Depositary Shares, each representing fifty (50) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMDT": {
        "symbol": "CMDT",
        "name": "PIMCO Commodity Strategy Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMDY": {
        "symbol": "CMDY",
        "name": "iShares Bloomberg Roll Select Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMF": {
        "symbol": "CMF",
        "name": "iShares California Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMG": {
        "symbol": "CMG",
        "name": "Chipotle Mexican Grill, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMI": {
        "symbol": "CMI",
        "name": "Cummins Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMP": {
        "symbol": "CMP",
        "name": "Compass Minerals International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMRE": {
        "symbol": "CMRE",
        "name": "Costamare Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMRE PRB": {
        "symbol": "CMRE PRB",
        "name": "Costamare Inc. 7.625% Series B Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMRE PRC": {
        "symbol": "CMRE PRC",
        "name": "COSTAMARE INC. 8.50% Series C Cumulative Redeemable Perpetual Preferred Stock, $0.0001 par value",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMRE PRD": {
        "symbol": "CMRE PRD",
        "name": "Costamare Inc.  8.75% Series D Cumulative Redeemable Perpetual Preferred Stock, $0.0001 par",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMS": {
        "symbol": "CMS",
        "name": "CMS Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMS PRB": {
        "symbol": "CMS PRB",
        "name": "Consumers Energy Company $4.50 Cumulative Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMS PRC": {
        "symbol": "CMS PRC",
        "name": "CMS Energy Corporation Depositary Shares, each representing a 1/1, 000th interest in a share of 4.20",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMSA": {
        "symbol": "CMSA",
        "name": "CMS Energy Corporation 5.625% Junior Subordinated Notes due 2078",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMSC": {
        "symbol": "CMSC",
        "name": "CMS Energy Corporation 5.875% Junior Subordinated Notes due 2078",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMSD": {
        "symbol": "CMSD",
        "name": "CMS Energy Corporation 5.875% Junior Subordinated Notes due 2079",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMT": {
        "symbol": "CMT",
        "name": "Core Molding Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMTG": {
        "symbol": "CMTG",
        "name": "Claros Mortgage Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CMU": {
        "symbol": "CMU",
        "name": "MFS High Yield Municipal Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNA": {
        "symbol": "CNA",
        "name": "CNA Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNAV": {
        "symbol": "CNAV",
        "name": "Mohr Company Nav ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNBS": {
        "symbol": "CNBS",
        "name": "Amplify Seymour Cannabis ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNC": {
        "symbol": "CNC",
        "name": "Centene Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNCR": {
        "symbol": "CNCR",
        "name": "ETF Series Solutions Trust Range Cancer Therapeutics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNEQ": {
        "symbol": "CNEQ",
        "name": "Alger Concentrated Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNF": {
        "symbol": "CNF",
        "name": "CNFinance Holdings Limited American Depositary Shares (\"ADSs\"), each representing twenty (20) Ordina",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNH": {
        "symbol": "CNH",
        "name": "CNH INDUSTRIAL N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNI": {
        "symbol": "CNI",
        "name": "Canadian National Railway",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNK": {
        "symbol": "CNK",
        "name": "Cinemark Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNL": {
        "symbol": "CNL",
        "name": "Collective Mining Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNM": {
        "symbol": "CNM",
        "name": "Core & Main, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNMD": {
        "symbol": "CNMD",
        "name": "CONMED Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNNE": {
        "symbol": "CNNE",
        "name": "Cannae Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNO": {
        "symbol": "CNO",
        "name": "CNO Financial Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNO PRA": {
        "symbol": "CNO PRA",
        "name": "CNO Financial Group, Inc. 5.125% Subordinated Debentures due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNP": {
        "symbol": "CNP",
        "name": "CenterPoint Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNQ": {
        "symbol": "CNQ",
        "name": "Canadian Natural Resources Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNRG": {
        "symbol": "CNRG",
        "name": "SPDR S&P Kensho Clean Power ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNS": {
        "symbol": "CNS",
        "name": "Cohen & Steers Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNX": {
        "symbol": "CNX",
        "name": "CNX Resources Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNXT": {
        "symbol": "CNXT",
        "name": "VanEck ChiNext ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CNYA": {
        "symbol": "CNYA",
        "name": "iShares MSCI China A ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COAL": {
        "symbol": "COAL",
        "name": "Range Global Coal Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COCHW": {
        "symbol": "COCHW",
        "name": "Envoy Medical, Inc Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CODI": {
        "symbol": "CODI",
        "name": "Compass Diversified",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CODI PRA": {
        "symbol": "CODI PRA",
        "name": "Compass Diversified Holdings 7.250% Series A Preferred Shares representing beneficial interest in Co",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CODI PRB": {
        "symbol": "CODI PRB",
        "name": "Compass Diversified Holdings 7.875% Series B Fixed-to-Floating Rate Cumulative Preferred Shares repr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CODI PRC": {
        "symbol": "CODI PRC",
        "name": "Compass Diversified Holdings 7.875% Series C Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COE": {
        "symbol": "COE",
        "name": "51Talk Online Education Group American Depositary Shares, each representing sixty (60) Class A Ordin",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF": {
        "symbol": "COF",
        "name": "Capital One Financial",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF PRI": {
        "symbol": "COF PRI",
        "name": "Capital One Financial Corporation Depositary shares each representing a 1/40th interest in a share o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF PRJ": {
        "symbol": "COF PRJ",
        "name": "Capital One Financial Corporation Depositary Shares, Each Representing a 1/40th Interest in a Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF PRK": {
        "symbol": "COF PRK",
        "name": "Capital One Financial Corporation Depositary Shares, Each Representing a 1/40th Ownership Interest i",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF PRL": {
        "symbol": "COF PRL",
        "name": "Capital One Financial Corporation Depositary Shares, Each Representing a 1/40th Interest in a Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COF PRN": {
        "symbol": "COF PRN",
        "name": "Capital One Financial Corporation Depositary Shares, Each Representing a 1/40th Ownership Interest i",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COHN": {
        "symbol": "COHN",
        "name": "Cohen & Company Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COHR": {
        "symbol": "COHR",
        "name": "Coherent Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COLD": {
        "symbol": "COLD",
        "name": "Americold Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COM": {
        "symbol": "COM",
        "name": "Direxion Auspice Broad Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COMB": {
        "symbol": "COMB",
        "name": "GraniteShares Bloomberg Commodity Broad Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COMP": {
        "symbol": "COMP",
        "name": "Compass, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COMT": {
        "symbol": "COMT",
        "name": "iShares U.S. ETF Trust iShares GSCI Commodity Dynamic Roll Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CON": {
        "symbol": "CON",
        "name": "Concentra Group Holdings Parent, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CONI": {
        "symbol": "CONI",
        "name": "GraniteShares 1x Short COIN Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CONL": {
        "symbol": "CONL",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long COIN Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CONY": {
        "symbol": "CONY",
        "name": "YieldMax COIN Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COOK": {
        "symbol": "COOK",
        "name": "Traeger, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COP": {
        "symbol": "COP",
        "name": "ConocoPhillips",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COPA": {
        "symbol": "COPA",
        "name": "Themes Copper Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COPJ": {
        "symbol": "COPJ",
        "name": "Sprott Junior Copper Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COPP": {
        "symbol": "COPP",
        "name": "Sprott Copper Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COPX": {
        "symbol": "COPX",
        "name": "Global X Copper Miners ETF (NEW)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COR": {
        "symbol": "COR",
        "name": "Cencora, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CORN": {
        "symbol": "CORN",
        "name": "Teucrium Corn Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CORP": {
        "symbol": "CORP",
        "name": "PIMCO Investment Grade Corporate Bond Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COTY": {
        "symbol": "COTY",
        "name": "COTY INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COUR": {
        "symbol": "COUR",
        "name": "Coursera, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COWG": {
        "symbol": "COWG",
        "name": "Pacer US Large Cap Cash Cows Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COWS": {
        "symbol": "COWS",
        "name": "Amplify Cash Flow Dividend Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "COWZ": {
        "symbol": "COWZ",
        "name": "Pacer US Cash Cows 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CP": {
        "symbol": "CP",
        "name": "Canadian Pacific Kansas City Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPA": {
        "symbol": "CPA",
        "name": "Copa Holdings, S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPAC": {
        "symbol": "CPAC",
        "name": "CEMENTOS PACASMAYO S.A.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPAI": {
        "symbol": "CPAI",
        "name": "Counterpoint Quantitative Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPAY": {
        "symbol": "CPAY",
        "name": "Corpay, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPER": {
        "symbol": "CPER",
        "name": "United States Copper Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPF": {
        "symbol": "CPF",
        "name": "Central Pacific Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPHI": {
        "symbol": "CPHI",
        "name": "China Pharma Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPII": {
        "symbol": "CPII",
        "name": "Ionic Inflation Protection ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPK": {
        "symbol": "CPK",
        "name": "Chesapeake Utilities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPLS": {
        "symbol": "CPLS",
        "name": "AB Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPNG": {
        "symbol": "CPNG",
        "name": "Coupang, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPNJ": {
        "symbol": "CPNJ",
        "name": "Calamos Nasdaq-100 Structured Alt Protection ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPNS": {
        "symbol": "CPNS",
        "name": "Calamos Nasdaq-100 Structured Alt Protection ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPRI": {
        "symbol": "CPRI",
        "name": "Capri Holdings Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPRJ": {
        "symbol": "CPRJ",
        "name": "Calamos Russell 2000 Structured Alt Protection ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPRO": {
        "symbol": "CPRO",
        "name": "Calamos Russell 2000 Structured Alt Protection ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPS": {
        "symbol": "CPS",
        "name": "Cooper-Standard Automotive Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPSA": {
        "symbol": "CPSA",
        "name": "Calamos S&P 500 Structured Alt Protection ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPSJ": {
        "symbol": "CPSJ",
        "name": "Calamos S&P 500 Structured Alt Protection ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPSL": {
        "symbol": "CPSL",
        "name": "Calamos Laddered S&P 500 Structured Alt Protection ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPSM": {
        "symbol": "CPSM",
        "name": "Calamos S&P 500 Structured Alt Protection ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPSO": {
        "symbol": "CPSO",
        "name": "Calamos S&P 500 Structured Alt Protection ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPST": {
        "symbol": "CPST",
        "name": "Calamos S&P 500 Structured Alt Protection ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CPT": {
        "symbol": "CPT",
        "name": "Camden Property Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CQP": {
        "symbol": "CQP",
        "name": "Cheniere Energy Partners, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CQQQ": {
        "symbol": "CQQQ",
        "name": "Invesco China Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CR": {
        "symbol": "CR",
        "name": "Crane Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRAK": {
        "symbol": "CRAK",
        "name": "VanEck Oil Refiners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRBG": {
        "symbol": "CRBG",
        "name": "Corebridge Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRBN": {
        "symbol": "CRBN",
        "name": "iShares MSCI ACWI Low Carbon Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRC": {
        "symbol": "CRC",
        "name": "California Resources Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRD A": {
        "symbol": "CRD A",
        "name": "Crawford & Company Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRD B": {
        "symbol": "CRD B",
        "name": "Crawford & Company Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRDT": {
        "symbol": "CRDT",
        "name": "Simplify Opportunistic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRED": {
        "symbol": "CRED",
        "name": "Columbia Research Enhanced Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRF": {
        "symbol": "CRF",
        "name": "Cornerstone Total Return Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRGY": {
        "symbol": "CRGY",
        "name": "Crescent Energy Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRH": {
        "symbol": "CRH",
        "name": "CRH Public Limited Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRI": {
        "symbol": "CRI",
        "name": "Carter's Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRIT": {
        "symbol": "CRIT",
        "name": "Optica Rare Earths & Critical Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRK": {
        "symbol": "CRK",
        "name": "Comstock Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRL": {
        "symbol": "CRL",
        "name": "Charles River Laboratories International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRM": {
        "symbol": "CRM",
        "name": "Salesforce, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRPT": {
        "symbol": "CRPT",
        "name": "First Trust SkyBridge Crypto Industry and Digital Economy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRS": {
        "symbol": "CRS",
        "name": "Carpenter Technology Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRSH": {
        "symbol": "CRSH",
        "name": "YieldMax Short TSLA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRT": {
        "symbol": "CRT",
        "name": "Cross Timbers Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRTC": {
        "symbol": "CRTC",
        "name": "Xtrackers US National Critical Technologies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CRUZ": {
        "symbol": "CRUZ",
        "name": "Defiance Hotel, Airline, and Cruise ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSA": {
        "symbol": "CSA",
        "name": "VictoryShares US Small Cap Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSAN": {
        "symbol": "CSAN",
        "name": "Cosan S.A. American Depositary Shares (each representing four Common Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSB": {
        "symbol": "CSB",
        "name": "VictoryShares US Small Cap High Div Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSD": {
        "symbol": "CSD",
        "name": "Invesco S&P Spin-Off ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSF": {
        "symbol": "CSF",
        "name": "VictoryShares US Discovery Enhanced Volatility Wtd ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSHI": {
        "symbol": "CSHI",
        "name": "NEOS Enhanced Income 1-3 Month T-Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSHP": {
        "symbol": "CSHP",
        "name": "iShares Enhanced Short-Term Bond Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSL": {
        "symbol": "CSL",
        "name": "Carlisle Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSLMR": {
        "symbol": "CSLMR",
        "name": "CSLM Acquisition Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSLMU": {
        "symbol": "CSLMU",
        "name": "CSLM Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSLMW": {
        "symbol": "CSLMW",
        "name": "CSLM Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSM": {
        "symbol": "CSM",
        "name": "ProShares Large Cap Core Plus",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSMD": {
        "symbol": "CSMD",
        "name": "Congress SMid Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSR": {
        "symbol": "CSR",
        "name": "Centerspace",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSTM": {
        "symbol": "CSTM",
        "name": "Constellium SE Class A Ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CSV": {
        "symbol": "CSV",
        "name": "Carriage Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTA": {
        "symbol": "CTA",
        "name": "Simplify Managed Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTA PRA": {
        "symbol": "CTA PRA",
        "name": "EIDP, Inc. Preferred Stock $3.50 Series",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTA PRB": {
        "symbol": "CTA PRB",
        "name": "EIDP, Inc. Preferred Stock $4.50 Series",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTBB": {
        "symbol": "CTBB",
        "name": "Qwest Corporation 6.5% Notes due 2056",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTCXW": {
        "symbol": "CTCXW",
        "name": "Carmell Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTDD": {
        "symbol": "CTDD",
        "name": "Qwest Corporation 6.75% Notes due 2057",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTEC": {
        "symbol": "CTEC",
        "name": "Global X CleanTech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTEST": {
        "symbol": "CTEST",
        "name": "Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTEST A": {
        "symbol": "CTEST A",
        "name": "NYSE CHICAGO TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTEX": {
        "symbol": "CTEX",
        "name": "ProShares S&P Kensho Cleantech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTGO": {
        "symbol": "CTGO",
        "name": "Contango ORE, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTLT": {
        "symbol": "CTLT",
        "name": "CATALENT, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTM": {
        "symbol": "CTM",
        "name": "Castellum, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTO": {
        "symbol": "CTO",
        "name": "CTO Realty Growth, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTO PRA": {
        "symbol": "CTO PRA",
        "name": "CTO Realty Growth, Inc. 6.375% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTOS": {
        "symbol": "CTOS",
        "name": "Custom Truck One Source, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTRA": {
        "symbol": "CTRA",
        "name": "Coterra Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTRE": {
        "symbol": "CTRE",
        "name": "CareTrust REIT, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTRI": {
        "symbol": "CTRI",
        "name": "Centuri Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTS": {
        "symbol": "CTS",
        "name": "CTS Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTV": {
        "symbol": "CTV",
        "name": "Innovid Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTV WS": {
        "symbol": "CTV WS",
        "name": "Innovid Corp. Warrants, each whole warrant exercisable for one share of Common Stock at an exercise",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CTVA": {
        "symbol": "CTVA",
        "name": "Corteva, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUBB": {
        "symbol": "CUBB",
        "name": "Customers Bancorp, Inc. 5.375% Subordinated Notes Due 2034",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUBE": {
        "symbol": "CUBE",
        "name": "CubeSmart",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUBI": {
        "symbol": "CUBI",
        "name": "CUSTOMERS BANCORP INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUBI PRE": {
        "symbol": "CUBI PRE",
        "name": "Customers Bancorp, Inc. Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock, Series E",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUBI PRF": {
        "symbol": "CUBI PRF",
        "name": "Customers Bancorp, Inc  Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock, Series F",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUK": {
        "symbol": "CUK",
        "name": "Carnival PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CULP": {
        "symbol": "CULP",
        "name": "Culp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CURB": {
        "symbol": "CURB",
        "name": "Curbline Properties Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CURE": {
        "symbol": "CURE",
        "name": "Direxion Daily Healthcare Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CURV": {
        "symbol": "CURV",
        "name": "Torrid Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUT": {
        "symbol": "CUT",
        "name": "Invesco MSCI Global Timber ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CUZ": {
        "symbol": "CUZ",
        "name": "Cousins Properties Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVAR": {
        "symbol": "CVAR",
        "name": "Cultivar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVE": {
        "symbol": "CVE",
        "name": "Cenovus Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVE WS": {
        "symbol": "CVE WS",
        "name": "Cenovus Energy Inc. Warrants (each warrant entitles the holder to purchase one common share at an ex",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVEO": {
        "symbol": "CVEO",
        "name": "Civeo Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVI": {
        "symbol": "CVI",
        "name": "CVR ENERGY, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVIE": {
        "symbol": "CVIE",
        "name": "Calvert International Responsible Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVLC": {
        "symbol": "CVLC",
        "name": "Calvert US Large-Cap Core Responsible Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVLG": {
        "symbol": "CVLG",
        "name": "Covenant Logistics Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVM": {
        "symbol": "CVM",
        "name": "Cel-Sci Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVMC": {
        "symbol": "CVMC",
        "name": "Calvert US Mid-Cap Core Responsible Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVNA": {
        "symbol": "CVNA",
        "name": "Carvana Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVR": {
        "symbol": "CVR",
        "name": "Chicago Rivet & Machine Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVRD": {
        "symbol": "CVRD",
        "name": "Madison Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVRT": {
        "symbol": "CVRT",
        "name": "Calamos Convertible Equity Alternative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVS": {
        "symbol": "CVS",
        "name": "CVS HEALTH CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVSB": {
        "symbol": "CVSB",
        "name": "Calvert Ultra-Short Investment Grade ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVSE": {
        "symbol": "CVSE",
        "name": "Calvert US Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVU": {
        "symbol": "CVU",
        "name": "CPI Aerostructures, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVX": {
        "symbol": "CVX",
        "name": "Chevron Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CVY": {
        "symbol": "CVY",
        "name": "Invesco Zacks Multi-Asset Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CW": {
        "symbol": "CW",
        "name": "Curtiss-Wright Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWAN": {
        "symbol": "CWAN",
        "name": "Clearwater Analytics Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWB": {
        "symbol": "CWB",
        "name": "SPDR Bloomberg Convertible Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWEB": {
        "symbol": "CWEB",
        "name": "Direxion Daily CSI China Internet Index Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWEN": {
        "symbol": "CWEN",
        "name": "Clearway Energy, Inc. Class C Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWEN A": {
        "symbol": "CWEN A",
        "name": "Clearway Energy, Inc.  Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWH": {
        "symbol": "CWH",
        "name": "Camping World Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWI": {
        "symbol": "CWI",
        "name": "SPDR MSCI ACWI ex-US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWK": {
        "symbol": "CWK",
        "name": "Cushman & Wakefield plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWS": {
        "symbol": "CWS",
        "name": "AdvisorShares Focused Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CWT": {
        "symbol": "CWT",
        "name": "California Water Service",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CX": {
        "symbol": "CX",
        "name": "Cemex S.A.B. de C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXE": {
        "symbol": "CXE",
        "name": "MFS High Income Municipal Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXH": {
        "symbol": "CXH",
        "name": "MFS Investment Grade Municipal Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXM": {
        "symbol": "CXM",
        "name": "Sprinklr, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXSE": {
        "symbol": "CXSE",
        "name": "WisdomTree Trust WisdomTree China ex-State-Owned Enterprises Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXT": {
        "symbol": "CXT",
        "name": "Crane NXT, Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CXW": {
        "symbol": "CXW",
        "name": "CoreCivic, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CYBN": {
        "symbol": "CYBN",
        "name": "Cybin Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CYD": {
        "symbol": "CYD",
        "name": "China Yuchai International Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CYH": {
        "symbol": "CYH",
        "name": "Community Health Systems, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CYTHW": {
        "symbol": "CYTHW",
        "name": "Cyclo Therapeutics, Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CZA": {
        "symbol": "CZA",
        "name": "Invesco Zacks Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "CZAR": {
        "symbol": "CZAR",
        "name": "Themes Natural Monopoly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "D": {
        "symbol": "D",
        "name": "Dominion Energy, Inc Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAC": {
        "symbol": "DAC",
        "name": "Danaos Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAL": {
        "symbol": "DAL",
        "name": "Delta Air Lines, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DALI": {
        "symbol": "DALI",
        "name": "First Trust DorseyWright DALI 1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAN": {
        "symbol": "DAN",
        "name": "Dana Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAO": {
        "symbol": "DAO",
        "name": "Youdao, Inc. American Depositary Shares, each representing one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAPP": {
        "symbol": "DAPP",
        "name": "VanEck Digital Transformation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAPR": {
        "symbol": "DAPR",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAR": {
        "symbol": "DAR",
        "name": "DARLING INGREDIENTS INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DARP": {
        "symbol": "DARP",
        "name": "Grizzle Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAT": {
        "symbol": "DAT",
        "name": "ProShares Big Data Refiners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAUG": {
        "symbol": "DAUG",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAVA": {
        "symbol": "DAVA",
        "name": "Endava plc American Depositary Shares (each representing one Class A Ordinary Share)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAX": {
        "symbol": "DAX",
        "name": "Global X Funds Global X DAX Germany ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DAY": {
        "symbol": "DAY",
        "name": "Dayforce, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DB": {
        "symbol": "DB",
        "name": "Deutsche Bank Aktiengesellschaft",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBA": {
        "symbol": "DBA",
        "name": "Invesco DB Agriculture Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBAW": {
        "symbol": "DBAW",
        "name": "Xtrackers MSCI All World ex US Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBB": {
        "symbol": "DBB",
        "name": "Invesco DB Base Metals Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBC": {
        "symbol": "DBC",
        "name": "Invesco DB Commodity Index Tracking Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBD": {
        "symbol": "DBD",
        "name": "Diebold Nixdorf, Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBE": {
        "symbol": "DBE",
        "name": "Invesco DB Energy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBEF": {
        "symbol": "DBEF",
        "name": "Xtrackers MSCI EAFE Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBEM": {
        "symbol": "DBEM",
        "name": "Xtrackers MSCI Emerging Markets Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBEU": {
        "symbol": "DBEU",
        "name": "Xtrackers MSCI Europe Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBEZ": {
        "symbol": "DBEZ",
        "name": "Xtrackers MSCI Eurozone Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBI": {
        "symbol": "DBI",
        "name": "Designer Brands Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBJP": {
        "symbol": "DBJP",
        "name": "Xtrackers MSCI Japan Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBL": {
        "symbol": "DBL",
        "name": "DOUBLELINE OPPORTUNISTIC CREDIT FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBMF": {
        "symbol": "DBMF",
        "name": "iMGP DBi Managed Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBND": {
        "symbol": "DBND",
        "name": "DoubleLine Opportunistic Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBO": {
        "symbol": "DBO",
        "name": "Invesco DB Oil Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBP": {
        "symbol": "DBP",
        "name": "Invesco DB Precious Metals Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBRG": {
        "symbol": "DBRG",
        "name": "DigitalBridge Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBRG PRH": {
        "symbol": "DBRG PRH",
        "name": "DigitalBridge Group, Inc. 7.125% Series H Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBRG PRI": {
        "symbol": "DBRG PRI",
        "name": "DigitalBridge Group, Inc. 7.15% Series I Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DBRG PRJ": {
        "symbol": "DBRG PRJ",
        "name": "DigitalBridge Group, Inc. 7.125% Series J Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DC": {
        "symbol": "DC",
        "name": "Dakota Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DC WS": {
        "symbol": "DC WS",
        "name": "Dakota Gold Corp. Warrants, each warrant exercisable for one Common Share at an exercise price of $2",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCAP": {
        "symbol": "DCAP",
        "name": "Unity Wealth Partners Dynamic Capital Appreciation & Options ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCF": {
        "symbol": "DCF",
        "name": "BNY Mellon Alcentra Global Credit Income 2024 Target Term Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCI": {
        "symbol": "DCI",
        "name": "Donaldson Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCMT": {
        "symbol": "DCMT",
        "name": "DoubleLine Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCO": {
        "symbol": "DCO",
        "name": "Ducommun Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCOR": {
        "symbol": "DCOR",
        "name": "Dimensional US Core Equity 1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DCRE": {
        "symbol": "DCRE",
        "name": "DoubleLine Commercial Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DD": {
        "symbol": "DD",
        "name": "DuPont de Nemours, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDC": {
        "symbol": "DDC",
        "name": "DDC Enterprise Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDD": {
        "symbol": "DDD",
        "name": "3D Systems Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDEC": {
        "symbol": "DDEC",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDIV": {
        "symbol": "DDIV",
        "name": "First Trust Exchange-Traded Fund VI First Trust Dorsey Wright Momentum & Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDL": {
        "symbol": "DDL",
        "name": "Dingdong (Cayman) Limited American Depositary Shares (each two representing three Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDLS": {
        "symbol": "DDLS",
        "name": "WisdomTree Dynamic Currency Hedged International SmallCap Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDM": {
        "symbol": "DDM",
        "name": "ProShares Ultra Dow30",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDS": {
        "symbol": "DDS",
        "name": "Dillards Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDT": {
        "symbol": "DDT",
        "name": "Dillard's Capital Trust I 7.50% Capital Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DDWM": {
        "symbol": "DDWM",
        "name": "WisdomTree Dynamic Currency Hedged International Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DE": {
        "symbol": "DE",
        "name": "Deere & Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEA": {
        "symbol": "DEA",
        "name": "Easterly Government Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEC": {
        "symbol": "DEC",
        "name": "Diversified Energy Company plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECAU": {
        "symbol": "DECAU",
        "name": "Denali Capital Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECAW": {
        "symbol": "DECAW",
        "name": "Denali Capital Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECK": {
        "symbol": "DECK",
        "name": "Deckers Outdoor Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECO": {
        "symbol": "DECO",
        "name": "SPDR Galaxy Digital Asset Ecosystem ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECP": {
        "symbol": "DECP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECT": {
        "symbol": "DECT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Dec ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECW": {
        "symbol": "DECW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Dec ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DECZ": {
        "symbol": "DECZ",
        "name": "TrueShares Structured Outcome (December) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEED": {
        "symbol": "DEED",
        "name": "First Trust TCW Securitized Plus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEEF": {
        "symbol": "DEEF",
        "name": "Xtrackers FTSE Developed ex US Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEEP": {
        "symbol": "DEEP",
        "name": "Roundhill Acquirers Deep Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEFI": {
        "symbol": "DEFI",
        "name": "Hashdex Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEHP": {
        "symbol": "DEHP",
        "name": "Dimensional Emerging Markets High Profitability ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEI": {
        "symbol": "DEI",
        "name": "Douglas Emmett, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DELL": {
        "symbol": "DELL",
        "name": "Dell Technologies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEM": {
        "symbol": "DEM",
        "name": "WisdomTree Emerging Markets High Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEMZ": {
        "symbol": "DEMZ",
        "name": "The Advisors' Inner Circle Fund III Democratic Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEO": {
        "symbol": "DEO",
        "name": "Diageo plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DES": {
        "symbol": "DES",
        "name": "WisdomTree U.S. SmallCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DESK": {
        "symbol": "DESK",
        "name": "VanEck Office and Commercial REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DESP": {
        "symbol": "DESP",
        "name": "Despegar.com, Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEUS": {
        "symbol": "DEUS",
        "name": "Xtrackers Russell US Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DEW": {
        "symbol": "DEW",
        "name": "WisdomTree Global High Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAC": {
        "symbol": "DFAC",
        "name": "Dimensional U.S. Core Equity 2 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAE": {
        "symbol": "DFAE",
        "name": "Dimensional Emerging Core Equity Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAI": {
        "symbol": "DFAI",
        "name": "Dimensional International Core Equity Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAR": {
        "symbol": "DFAR",
        "name": "Dimensional US Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAS": {
        "symbol": "DFAS",
        "name": "Dimensional U.S. Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAT": {
        "symbol": "DFAT",
        "name": "Dimensional U.S. Targeted Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAU": {
        "symbol": "DFAU",
        "name": "Dimensional US Core Equity Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAW": {
        "symbol": "DFAW",
        "name": "Dimensional World Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFAX": {
        "symbol": "DFAX",
        "name": "Dimensional World ex U.S. Core Equity 2 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFCA": {
        "symbol": "DFCA",
        "name": "Dimensional California Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFCF": {
        "symbol": "DFCF",
        "name": "Dimensional Core Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFE": {
        "symbol": "DFE",
        "name": "WisdomTree Europe SmallCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFEB": {
        "symbol": "DFEB",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFEM": {
        "symbol": "DFEM",
        "name": "Dimensional Emerging Markets Core Equity 2 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFEN": {
        "symbol": "DFEN",
        "name": "Direxion Daily Aerospace & Defense Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFEV": {
        "symbol": "DFEV",
        "name": "Dimensional Emerging Markets Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFGP": {
        "symbol": "DFGP",
        "name": "Dimensional Global Core Plus Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFGR": {
        "symbol": "DFGR",
        "name": "Dimensional Global Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFGX": {
        "symbol": "DFGX",
        "name": "Dimensional Global ex US Core Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFH": {
        "symbol": "DFH",
        "name": "Dream Finders Homes, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFHY": {
        "symbol": "DFHY",
        "name": "Donoghue Forlines Tactical High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFIC": {
        "symbol": "DFIC",
        "name": "Dimensional International Core Equity 2 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFIN": {
        "symbol": "DFIN",
        "name": "Donnelley Financial Solutions, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFIP": {
        "symbol": "DFIP",
        "name": "Dimensional Inflation-Protected Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFIS": {
        "symbol": "DFIS",
        "name": "Dimensional International Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFIV": {
        "symbol": "DFIV",
        "name": "Dimensional International Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFJ": {
        "symbol": "DFJ",
        "name": "WisdomTree Japan SmallCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFLV": {
        "symbol": "DFLV",
        "name": "Dimensional US Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFND": {
        "symbol": "DFND",
        "name": "Siren DIVCON Dividend Defender ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFNL": {
        "symbol": "DFNL",
        "name": "Davis Select Financial ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFNM": {
        "symbol": "DFNM",
        "name": "Dimensional National Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFNV": {
        "symbol": "DFNV",
        "name": "Donoghue Forlines Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFP": {
        "symbol": "DFP",
        "name": "FLAHERTY & CRUMRINE DYNAMIC PREFERRED AND INCOME FUND INCORPORATED",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFRA": {
        "symbol": "DFRA",
        "name": "Donoghue Forlines Yield Enhanced Real Asset ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFS": {
        "symbol": "DFS",
        "name": "Discover Financial Services",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSB": {
        "symbol": "DFSB",
        "name": "Dimensional Global Sustainability Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSD": {
        "symbol": "DFSD",
        "name": "Dimensional Short-Duration Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSE": {
        "symbol": "DFSE",
        "name": "Dimensional Emerging Markets Sustainability Core 1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSI": {
        "symbol": "DFSI",
        "name": "Dimensional International Sustainability Core 1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSU": {
        "symbol": "DFSU",
        "name": "Dimensional US Sustainability Core 1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFSV": {
        "symbol": "DFSV",
        "name": "Dimensional US Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFUS": {
        "symbol": "DFUS",
        "name": "Dimensional U.S. Equity Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFUV": {
        "symbol": "DFUV",
        "name": "Dimensional US Marketwide Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFVE": {
        "symbol": "DFVE",
        "name": "DoubleLine Fortune 500 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DFVX": {
        "symbol": "DFVX",
        "name": "Dimensional US Large Cap Vector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DG": {
        "symbol": "DG",
        "name": "Dollar General Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGCB": {
        "symbol": "DGCB",
        "name": "Dimensional Global Credit ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGIN": {
        "symbol": "DGIN",
        "name": "VanEck Digital India ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGP": {
        "symbol": "DGP",
        "name": "DB Gold Double Long ETN due February 15, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGRE": {
        "symbol": "DGRE",
        "name": "WisdomTree Trust WisdomTree Emerging Markets Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGRO": {
        "symbol": "DGRO",
        "name": "iShares Core Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGRS": {
        "symbol": "DGRS",
        "name": "WisdomTree Trust WisdomTree U.S. SmallCap Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGRW": {
        "symbol": "DGRW",
        "name": "WisdomTree Trust WisdomTree U.S. Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGS": {
        "symbol": "DGS",
        "name": "WisdomTree Emerging Markets SmallCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGT": {
        "symbol": "DGT",
        "name": "SPDR Global Dow ETF (based on The Global Dow)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGX": {
        "symbol": "DGX",
        "name": "Quest Diagnostics Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DGZ": {
        "symbol": "DGZ",
        "name": "DB Gold Short ETN due February 15, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHF": {
        "symbol": "DHF",
        "name": "BNY Mellon High Yield Strategies Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHI": {
        "symbol": "DHI",
        "name": "D.R. Horton Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHR": {
        "symbol": "DHR",
        "name": "Danaher Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHS": {
        "symbol": "DHS",
        "name": "WisdomTree U.S. High Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHT": {
        "symbol": "DHT",
        "name": "DHT HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHX": {
        "symbol": "DHX",
        "name": "DHI Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DHY": {
        "symbol": "DHY",
        "name": "Credit Suisse High Yield Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIA": {
        "symbol": "DIA",
        "name": "SPDR Dow Jones Industrial Average ETF Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIAL": {
        "symbol": "DIAL",
        "name": "Columbia Diversified Fixed Income Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIAX": {
        "symbol": "DIAX",
        "name": "NUVEEN DOW 30SM DYNAMIC OVERWRITE FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIEM": {
        "symbol": "DIEM",
        "name": "Franklin Emerging Market Core Dividend Tilt Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIG": {
        "symbol": "DIG",
        "name": "ProShares Ultra Energy",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIHP": {
        "symbol": "DIHP",
        "name": "Dimensional International High Profitability ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIM": {
        "symbol": "DIM",
        "name": "WisdomTree International MidCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIN": {
        "symbol": "DIN",
        "name": "Dine Brands Global, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DINO": {
        "symbol": "DINO",
        "name": "HF Sinclair Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DINT": {
        "symbol": "DINT",
        "name": "Davis Select International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIPS": {
        "symbol": "DIPS",
        "name": "YieldMax Short NVDA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIS": {
        "symbol": "DIS",
        "name": "The Walt Disney Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DISO": {
        "symbol": "DISO",
        "name": "YieldMax DIS Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DISTR": {
        "symbol": "DISTR",
        "name": "Distoken Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DISTW": {
        "symbol": "DISTW",
        "name": "Distoken Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DISV": {
        "symbol": "DISV",
        "name": "Dimensional International Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIT": {
        "symbol": "DIT",
        "name": "AMCON Distributing Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIV": {
        "symbol": "DIV",
        "name": "Global X SuperDividend U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVB": {
        "symbol": "DIVB",
        "name": "iShares Core Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVD": {
        "symbol": "DIVD",
        "name": "Altrius Global Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVG": {
        "symbol": "DIVG",
        "name": "Invesco S&P 500 High Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVI": {
        "symbol": "DIVI",
        "name": "Franklin International Core Dividend Tilt Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVL": {
        "symbol": "DIVL",
        "name": "Madison Dividend Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVO": {
        "symbol": "DIVO",
        "name": "Amplify CWP Enhanced Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVP": {
        "symbol": "DIVP",
        "name": "Cullen Enhanced Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVS": {
        "symbol": "DIVS",
        "name": "SmartETFs Dividend Builder ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVY": {
        "symbol": "DIVY",
        "name": "Sound Equity Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DIVZ": {
        "symbol": "DIVZ",
        "name": "Opal Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJAN": {
        "symbol": "DJAN",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJCB": {
        "symbol": "DJCB",
        "name": "ETRACS Bloomberg Commodity Index Total Return ETN Series B due October 31, 2039",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJD": {
        "symbol": "DJD",
        "name": "Invesco Dow Jones Industrial Average Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJIA": {
        "symbol": "DJIA",
        "name": "Global X Dow 30 Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJP": {
        "symbol": "DJP",
        "name": "iPath Bloomberg Commodity Index Total Return ETN due June 12, 2036",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJUL": {
        "symbol": "DJUL",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DJUN": {
        "symbol": "DJUN",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DK": {
        "symbol": "DK",
        "name": "Delek US Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DKL": {
        "symbol": "DKL",
        "name": "DELEK LOGISTICS PARTNERS, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DKS": {
        "symbol": "DKS",
        "name": "Dick's Sporting Goods, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLB": {
        "symbol": "DLB",
        "name": "Dolby Laboratories, Inc.Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLN": {
        "symbol": "DLN",
        "name": "WisdomTree U.S. LargeCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLNG": {
        "symbol": "DLNG",
        "name": "DYNAGAS LNG PARNERS LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLNG PRA": {
        "symbol": "DLNG PRA",
        "name": "Dynagas LNG Partners LP 9.00% Series A Cumulative Redeemable Preferred Units, liquidation preference",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLNG PRB": {
        "symbol": "DLNG PRB",
        "name": "Dynagas LNG Partners LP 8.75% Series B Fixed to Floating Rate Cumulative Redeemable Perpetual Prefer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLR": {
        "symbol": "DLR",
        "name": "Digital Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLR PRJ": {
        "symbol": "DLR PRJ",
        "name": "Digital Realty Trust, Inc. 5.250% Series J Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLR PRK": {
        "symbol": "DLR PRK",
        "name": "Digital Realty Trust, Inc. 5.850% Series K Cumulative Redeemable Preferred Stock, par value $0.01 pe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLR PRL": {
        "symbol": "DLR PRL",
        "name": "Digital Realty Trust, Inc. 5.200% Series L Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLS": {
        "symbol": "DLS",
        "name": "WisdomTree International SmallCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLX": {
        "symbol": "DLX",
        "name": "Deluxe Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DLY": {
        "symbol": "DLY",
        "name": "DoubleLine Yield Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DM": {
        "symbol": "DM",
        "name": "Desktop Metal, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMA": {
        "symbol": "DMA",
        "name": "Destra Multi-Alternative Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMAR": {
        "symbol": "DMAR",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMAT": {
        "symbol": "DMAT",
        "name": "Global X Disruptive Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMAY": {
        "symbol": "DMAY",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMB": {
        "symbol": "DMB",
        "name": "BNY Mellon Municipal Bond Infrastructure Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMBS": {
        "symbol": "DMBS",
        "name": "DoubleLine Mortgage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMCY": {
        "symbol": "DMCY",
        "name": "Democracy International Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMDV": {
        "symbol": "DMDV",
        "name": "AAM S& P Developed Markets High Dividend Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMF": {
        "symbol": "DMF",
        "name": "BNY Mellon Municipal Income Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMO": {
        "symbol": "DMO",
        "name": "Western Asset Mortgage Opportunity Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMXF": {
        "symbol": "DMXF",
        "name": "iShares ESG Advanced MSCI EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMYY": {
        "symbol": "DMYY",
        "name": "dMY Squared Technology Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMYY U": {
        "symbol": "DMYY U",
        "name": "dMY Squared Technology Group, Inc. Units, each consisting of one share of Class A common stock and o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DMYY WS": {
        "symbol": "DMYY WS",
        "name": "dMY Squared Technology Group, Inc. Redeemable Warrants, each whole warrant exercisable for one share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNA": {
        "symbol": "DNA",
        "name": "Ginkgo Bioworks Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNB": {
        "symbol": "DNB",
        "name": "Dun & Bradstreet Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNL": {
        "symbol": "DNL",
        "name": "WisdomTree Global ex-US Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNMR": {
        "symbol": "DNMR",
        "name": "Danimer Scientific, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNN": {
        "symbol": "DNN",
        "name": "Denison Mines Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNOV": {
        "symbol": "DNOV",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNOW": {
        "symbol": "DNOW",
        "name": "DNOW Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DNP": {
        "symbol": "DNP",
        "name": "DNP Select Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOC": {
        "symbol": "DOC",
        "name": "Healthpeak Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOCN": {
        "symbol": "DOCN",
        "name": "DigitalOcean Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOCS": {
        "symbol": "DOCS",
        "name": "Doximity, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOCT": {
        "symbol": "DOCT",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOG": {
        "symbol": "DOG",
        "name": "ProShares Short Dow30",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOGG": {
        "symbol": "DOGG",
        "name": "FT Vest DJIA Dogs 10 Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOL": {
        "symbol": "DOL",
        "name": "WisdomTree International LargeCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOLE": {
        "symbol": "DOLE",
        "name": "Dole plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DON": {
        "symbol": "DON",
        "name": "WisdomTree U.S. MidCap Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOUG": {
        "symbol": "DOUG",
        "name": "Douglas Elliman Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOV": {
        "symbol": "DOV",
        "name": "Dover Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DOW": {
        "symbol": "DOW",
        "name": "Dow Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DPCS": {
        "symbol": "DPCS",
        "name": "DP Cap Acquisition Corp I Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DPCSU": {
        "symbol": "DPCSU",
        "name": "DP Cap Acquisition Corp I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DPG": {
        "symbol": "DPG",
        "name": "Duff & Phelps Utility and Infrastructure Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DPST": {
        "symbol": "DPST",
        "name": "Direxion Daily Regional Banks Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DPZ": {
        "symbol": "DPZ",
        "name": "Domino's Pizza Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DQ": {
        "symbol": "DQ",
        "name": "Daqo New Energy Corp. American Depositary Shares (each representing 5 Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRAG": {
        "symbol": "DRAG",
        "name": "Roundhill China Dragons ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRAI": {
        "symbol": "DRAI",
        "name": "Draco Evolution AI ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRD": {
        "symbol": "DRD",
        "name": "DRDGOLD Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRH": {
        "symbol": "DRH",
        "name": "DiamondRock Hospitality Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRH PRA": {
        "symbol": "DRH PRA",
        "name": "DiamondRock Hospitality Company 8.250% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRI": {
        "symbol": "DRI",
        "name": "Darden Restaurants, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRIP": {
        "symbol": "DRIP",
        "name": "Direxion Daily S&P Oil & Gas Exp. & Prod. Bear 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRIV": {
        "symbol": "DRIV",
        "name": "Global X Autonomous & Electric Vehicles ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRLL": {
        "symbol": "DRLL",
        "name": "Strive U.S. Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRN": {
        "symbol": "DRN",
        "name": "Direxion Daily Real Estate Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRSK": {
        "symbol": "DRSK",
        "name": "Aptus Defined Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRUP": {
        "symbol": "DRUP",
        "name": "GraniteShares Nasdaq Select Disruptors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DRV": {
        "symbol": "DRV",
        "name": "Direxion Daily Real Estate Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSCF": {
        "symbol": "DSCF",
        "name": "Discipline Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSEP": {
        "symbol": "DSEP",
        "name": "FT Vest U.S. Equity Deep Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSI": {
        "symbol": "DSI",
        "name": "iShares MSCI KLD 400 Social ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSL": {
        "symbol": "DSL",
        "name": "DOUBLELINE INCOME SOLUTIONS FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSM": {
        "symbol": "DSM",
        "name": "BNY Mellon Strategic Municipal Bond Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSMC": {
        "symbol": "DSMC",
        "name": "Distillate Small/Mid Cash Flow ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSS": {
        "symbol": "DSS",
        "name": "DSS, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSTL": {
        "symbol": "DSTL",
        "name": "Distillate U.S. Fundamental Stability & Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSTX": {
        "symbol": "DSTX",
        "name": "Distillate International Fundamental Stability & Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSU": {
        "symbol": "DSU",
        "name": "Blackrock Debt Strategies Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSX": {
        "symbol": "DSX",
        "name": "Diana Shipping, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSX PRB": {
        "symbol": "DSX PRB",
        "name": "DIANA SHIPPING INC 8.875% Series B Cumulative Redeemable Perpetual Preferred Shares, $0.01 par value",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DSX WS": {
        "symbol": "DSX WS",
        "name": "Diana Shipping Inc. Warrants to Purchase Common Stock (expiring on or about December 14, 2026)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DT": {
        "symbol": "DT",
        "name": "Dynatrace, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTAN": {
        "symbol": "DTAN",
        "name": "Sparkline International Intangible Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTB": {
        "symbol": "DTB",
        "name": "DTE Energy Company 2020 Series G 4.375% Junior Subordinated Debentures due 2080",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTC": {
        "symbol": "DTC",
        "name": "Solo Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTCR": {
        "symbol": "DTCR",
        "name": "Global X Funds Global X Data Center & Digital Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTD": {
        "symbol": "DTD",
        "name": "WisdomTree U.S. Total Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTE": {
        "symbol": "DTE",
        "name": "DTE Energy Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTEC": {
        "symbol": "DTEC",
        "name": "ALPS Disruptive Technologies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTF": {
        "symbol": "DTF",
        "name": "DTF Tax-Free Income 2028 Term Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTG": {
        "symbol": "DTG",
        "name": "DTE Energy Company 2021 Series E 4.375% Junior Subordinated Debentures",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTH": {
        "symbol": "DTH",
        "name": "WisdomTree International High Dividend  Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTM": {
        "symbol": "DTM",
        "name": "DT Midstream, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTRE": {
        "symbol": "DTRE",
        "name": "First Trust Alerian Disruptive Technology Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTSQR": {
        "symbol": "DTSQR",
        "name": "DT Cloud Star Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DTW": {
        "symbol": "DTW",
        "name": "DTE Energy Company 2017 Series E 5.25% Junior Subordinated Debentures due 2077",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUBS": {
        "symbol": "DUBS",
        "name": "Aptus Large Cap Enhanced Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUETU": {
        "symbol": "DUETU",
        "name": "DUET Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUG": {
        "symbol": "DUG",
        "name": "ProShares UltraShort Energy",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUHP": {
        "symbol": "DUHP",
        "name": "Dimensional US High Profitability ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUK": {
        "symbol": "DUK",
        "name": "Duke Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUK PRA": {
        "symbol": "DUK PRA",
        "name": "Duke Energy Corporation Depositary Shares, each representing a 1/1,000th interest in a share of 5.75",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUKB": {
        "symbol": "DUKB",
        "name": "Duke Energy Corporation 5.625% Junior Subordinated Debentures due 2078",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUKH": {
        "symbol": "DUKH",
        "name": "Ocean Park High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUKQ": {
        "symbol": "DUKQ",
        "name": "Ocean Park Domestic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUKX": {
        "symbol": "DUKX",
        "name": "Ocean Park International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUKZ": {
        "symbol": "DUKZ",
        "name": "Ocean Park Diversified Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DULL": {
        "symbol": "DULL",
        "name": "MicroSectors Gold -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DURA": {
        "symbol": "DURA",
        "name": "VanEck Durable High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUSA": {
        "symbol": "DUSA",
        "name": "Davis Select U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUSB": {
        "symbol": "DUSB",
        "name": "Dimensional Ultrashort Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUSL": {
        "symbol": "DUSL",
        "name": "Direxion Daily Industrials Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DUST": {
        "symbol": "DUST",
        "name": "Direxion Daily Gold Miners Index Bear 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DV": {
        "symbol": "DV",
        "name": "DoubleVerify Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVA": {
        "symbol": "DVA",
        "name": "DaVita Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVAL": {
        "symbol": "DVAL",
        "name": "BrandywineGLOBAL - Dynamic US Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVDN": {
        "symbol": "DVDN",
        "name": "Kingsbarn Dividend Opportunity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVLU": {
        "symbol": "DVLU",
        "name": "First Trust Dorsey Wright Momentum & Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVN": {
        "symbol": "DVN",
        "name": "Devon Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVND": {
        "symbol": "DVND",
        "name": "Touchstone Dividend Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVOL": {
        "symbol": "DVOL",
        "name": "First Trust Dorsey Wright Momentum & Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVY": {
        "symbol": "DVY",
        "name": "iShares Select Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVYA": {
        "symbol": "DVYA",
        "name": "iShares Asia/Pacific Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DVYE": {
        "symbol": "DVYE",
        "name": "iShares Emerging Markets Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWAS": {
        "symbol": "DWAS",
        "name": "Invesco Exchange-Traded Fund Trust II Invesco Dorsey Wright SmallCap Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWAT": {
        "symbol": "DWAT",
        "name": "Arrow Investments Trust Arrow DWA Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWAW": {
        "symbol": "DWAW",
        "name": "AdvisorShares Dorsey Wright FSM All Cap World ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWCR": {
        "symbol": "DWCR",
        "name": "Arrow DWA Country Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWLD": {
        "symbol": "DWLD",
        "name": "Davis Select Worldwide ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWM": {
        "symbol": "DWM",
        "name": "WisdomTree International Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWMF": {
        "symbol": "DWMF",
        "name": "WisdomTree International Multifactor Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWSH": {
        "symbol": "DWSH",
        "name": "AdvisorShares Dorsey Wright Short ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWTX": {
        "symbol": "DWTX",
        "name": "Dogwood Therapeutics, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWUS": {
        "symbol": "DWUS",
        "name": "AdvisorShares Dorsey Wright FSM US Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DWX": {
        "symbol": "DWX",
        "name": "SPDR S&P International Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DX": {
        "symbol": "DX",
        "name": "Dynex Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DX PRC": {
        "symbol": "DX PRC",
        "name": "Dynex Capital, Inc. 6.900% Series C Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXC": {
        "symbol": "DXC",
        "name": "DXC Technology Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXD": {
        "symbol": "DXD",
        "name": "ProShares UltraShort Dow 30",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXIV": {
        "symbol": "DXIV",
        "name": "Dimensional International Vector Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXJ": {
        "symbol": "DXJ",
        "name": "WisdomTree Japan Hedged Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXJS": {
        "symbol": "DXJS",
        "name": "WisdomTree Japan Hedged SmallCap Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXUV": {
        "symbol": "DXUV",
        "name": "Dimensional US Vector Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DXYZ": {
        "symbol": "DXYZ",
        "name": "Destiny Tech100 Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DY": {
        "symbol": "DY",
        "name": "Dycom Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYCQR": {
        "symbol": "DYCQR",
        "name": "DT Cloud Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYCQU": {
        "symbol": "DYCQU",
        "name": "DT Cloud Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYFI": {
        "symbol": "DYFI",
        "name": "IDX Dynamic Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYLD": {
        "symbol": "DYLD",
        "name": "LeaderShares Dynamic Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYLG": {
        "symbol": "DYLG",
        "name": "Global X Dow 30\u00c3\u00bfCovered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYNF": {
        "symbol": "DYNF",
        "name": "iShares U.S. Equity Factor Rotation Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYNI": {
        "symbol": "DYNI",
        "name": "IDX Dynamic Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DYTA": {
        "symbol": "DYTA",
        "name": "SGI Dynamic Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "DZZ": {
        "symbol": "DZZ",
        "name": "DB Gold Double Short ETN due February 15, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "E": {
        "symbol": "E",
        "name": "ENI S.p.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAD": {
        "symbol": "EAD",
        "name": "Allspring Income Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAF": {
        "symbol": "EAF",
        "name": "GrafTech International Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAFG": {
        "symbol": "EAFG",
        "name": "Pacer Developed Markets Cash Cows Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAGG": {
        "symbol": "EAGG",
        "name": "iShares ESG Aware U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAGL": {
        "symbol": "EAGL",
        "name": "Eagle Capital Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAI": {
        "symbol": "EAI",
        "name": "Entergy Arkansas, LLC First Mortgage Bonds, 4.875% Series due September 1, 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EALT": {
        "symbol": "EALT",
        "name": "Innovator U.S. Equity 5 to 15 Buffer ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAOA": {
        "symbol": "EAOA",
        "name": "iShares ESG Aware Aggressive Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAOK": {
        "symbol": "EAOK",
        "name": "iShares ESG Aware Conservative Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAOM": {
        "symbol": "EAOM",
        "name": "iShares ESG Aware Moderate Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAOR": {
        "symbol": "EAOR",
        "name": "iShares ESG Aware Growth Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAPR": {
        "symbol": "EAPR",
        "name": "Innovator Emerging Markets Power Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EARN": {
        "symbol": "EARN",
        "name": "Ellington Credit Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EASG": {
        "symbol": "EASG",
        "name": "Xtrackers MSCI EAFE ESG Leaders Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EAT": {
        "symbol": "EAT",
        "name": "Brinker International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EATV": {
        "symbol": "EATV",
        "name": "VegTech Plant-based Innovation & Climate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EATZ": {
        "symbol": "EATZ",
        "name": "AdvisorShares Restaurant ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EB": {
        "symbol": "EB",
        "name": "Eventbrite, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBF": {
        "symbol": "EBF",
        "name": "Ennis, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBIT": {
        "symbol": "EBIT",
        "name": "Harbor AlphaEdge Small Cap Earners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBIZ": {
        "symbol": "EBIZ",
        "name": "Global X E-commerce ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBND": {
        "symbol": "EBND",
        "name": "SPDR Bloomberg Emerging Markets Local Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBR": {
        "symbol": "EBR",
        "name": "Centrais El\u00e2\u20ac\u0161tricas Brasileiras S.A.- Eletrobr\u00c2\u00a0s American Depositary Shares (Each representing one Co",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBR B": {
        "symbol": "EBR B",
        "name": "Centrais El\u00e2\u20ac\u0161tricas Brasileiras S.A.- Eletrobr\u00c2\u00a0s American Depositary Shares (Each representing one Pr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBS": {
        "symbol": "EBS",
        "name": "Emergent Biosolutions, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EBUF": {
        "symbol": "EBUF",
        "name": "Innovator Emerging Markets 10 Buffer ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EC": {
        "symbol": "EC",
        "name": "Ecopetrol S.A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECAT": {
        "symbol": "ECAT",
        "name": "BlackRock ESG Capital Allocation Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECC": {
        "symbol": "ECC",
        "name": "EAGLE POINT CREDIT COMPANY INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECC PRD": {
        "symbol": "ECC PRD",
        "name": "Eagle Point Credit Company Inc. 6.75% Series D Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECCC": {
        "symbol": "ECCC",
        "name": "Eagle Point Credit Company Inc. 6.50% Series C Term Preferred Stock due 2031",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECCF": {
        "symbol": "ECCF",
        "name": "Eagle Point Credit Company Inc. 8.00% Series F Term Preferred Stock due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECCV": {
        "symbol": "ECCV",
        "name": "Eagle Point Credit Company Inc. 5.375% Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECCW": {
        "symbol": "ECCW",
        "name": "Eagle Point Credit Company Inc .6.75% Notes due 2031",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECCX": {
        "symbol": "ECCX",
        "name": "Eagle Point Credit Company Inc. 6.6875% Notes due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECF": {
        "symbol": "ECF",
        "name": "Ellsworth Growth and Income Fund Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECF PRA": {
        "symbol": "ECF PRA",
        "name": "Ellsworth Growth and Income Fund Ltd. 5.25 percent Series A Cumulative Preferred Shares (Liquidation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECH": {
        "symbol": "ECH",
        "name": "iShares MSCI Chile ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECL": {
        "symbol": "ECL",
        "name": "Ecolab, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECLN": {
        "symbol": "ECLN",
        "name": "First Trust EIP Carbon Impact ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECML": {
        "symbol": "ECML",
        "name": "Euclidean Fundamental Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECNS": {
        "symbol": "ECNS",
        "name": "iShares MSCI China Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECO": {
        "symbol": "ECO",
        "name": "Okeanis Eco Tankers Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECON": {
        "symbol": "ECON",
        "name": "Columbia Research Enhanced Emerging Economies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECOW": {
        "symbol": "ECOW",
        "name": "Pacer Emerging Markets Cash Cows 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ECVT": {
        "symbol": "ECVT",
        "name": "Ecovyst Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ED": {
        "symbol": "ED",
        "name": "Consolidated Edison, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDC": {
        "symbol": "EDC",
        "name": "Direxion Daily Emerging Markets Bull 3X Shares, Shares of beneficial interest, no par value",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDD": {
        "symbol": "EDD",
        "name": "Morgan Stanley Emerging Markets Domestic Debt Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDEN": {
        "symbol": "EDEN",
        "name": "iShares MSCI Denmark ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDF": {
        "symbol": "EDF",
        "name": "Virtus Stone Harbor Emerging Markets Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDGF": {
        "symbol": "EDGF",
        "name": "3EDGE Dynamic Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDGH": {
        "symbol": "EDGH",
        "name": "3EDGE Dynamic Hard Assets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDGI": {
        "symbol": "EDGI",
        "name": "3EDGE Dynamic International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDGU": {
        "symbol": "EDGU",
        "name": "3EDGE Dynamic US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDIV": {
        "symbol": "EDIV",
        "name": "SPDR S&P Emerging Markets Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDN": {
        "symbol": "EDN",
        "name": "Empresa Distribuidora Y Comercializadora Norte S.A. (Edenor)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDOC": {
        "symbol": "EDOC",
        "name": "Global X Telemedicine & Digital Health ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDOG": {
        "symbol": "EDOG",
        "name": "ALPS Emerging Sector Dividend Dogs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDOW": {
        "symbol": "EDOW",
        "name": "First Trust Dow 30 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDR": {
        "symbol": "EDR",
        "name": "Endeavor Group Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDU": {
        "symbol": "EDU",
        "name": "New Oriental Education and Technology Group, Inc. American Depositary Shares (each representing ten",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDV": {
        "symbol": "EDV",
        "name": "Vanguard World Funds Extended Duration ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EDZ": {
        "symbol": "EDZ",
        "name": "Direxion Daily Emerging Markets Bear 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EE": {
        "symbol": "EE",
        "name": "Excelerate Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEA": {
        "symbol": "EEA",
        "name": "The European Equity Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EELV": {
        "symbol": "EELV",
        "name": "Invesco S&P Emerging Markets Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEM": {
        "symbol": "EEM",
        "name": "iShares MSCI Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMA": {
        "symbol": "EEMA",
        "name": "iShares MSCI Emerging Markets Asia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMD": {
        "symbol": "EEMD",
        "name": "AAM S&P Emerging Markets High Dividend Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMO": {
        "symbol": "EEMO",
        "name": "Invesco S&P Emerging Markets Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMS": {
        "symbol": "EEMS",
        "name": "iShares MSCI Emerging Markets Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMV": {
        "symbol": "EEMV",
        "name": "iShares MSCI Emerging Markets Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEMX": {
        "symbol": "EEMX",
        "name": "SPDR MSCI Emerging Markets Fossil Fuel Reserves Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EES": {
        "symbol": "EES",
        "name": "WisdomTree U.S. SmallCap Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EET": {
        "symbol": "EET",
        "name": "ProShares Ultra MSCI Emerging Markets",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EETH": {
        "symbol": "EETH",
        "name": "ProShares Ether ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEV": {
        "symbol": "EEV",
        "name": "ProShares Trust UltraShort MSCI Emerging Markets",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EEX": {
        "symbol": "EEX",
        "name": "Emerald Holding, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFA": {
        "symbol": "EFA",
        "name": "iShares MSCI EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFAA": {
        "symbol": "EFAA",
        "name": "Invesco MSCI EAFE Income Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFAD": {
        "symbol": "EFAD",
        "name": "ProShares MSCI EAFE Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFAS": {
        "symbol": "EFAS",
        "name": "Global X MSCI SuperDividend EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFAV": {
        "symbol": "EFAV",
        "name": "iShares MSCI EAFE Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFAX": {
        "symbol": "EFAX",
        "name": "SPDR MSCI EAFE Fossil Fuel Reserves Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC": {
        "symbol": "EFC",
        "name": "Ellington Financial Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC PRA": {
        "symbol": "EFC PRA",
        "name": "Ellington Financial Inc. 6.750% Series A Fixed-to-Floating Rate Cumulative Redeemable Preferred Stoc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC PRB": {
        "symbol": "EFC PRB",
        "name": "Ellington Financial Inc. 6.250% Series B Fixed-Rate Reset Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC PRC": {
        "symbol": "EFC PRC",
        "name": "Ellington Financial Inc. 8.625% Series C Fixed-Rate Reset Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC PRD": {
        "symbol": "EFC PRD",
        "name": "Ellington Financial Inc. 7.00% Series D Cumulative Perpetual Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFC PRE": {
        "symbol": "EFC PRE",
        "name": "Ellington Financial Inc. 8.250% Series E Fixed-to-Floating Rate Cumulative Redeemable Preferred Stoc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFG": {
        "symbol": "EFG",
        "name": "iShares MSCI EAFE Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFIV": {
        "symbol": "EFIV",
        "name": "SPDR S&P 500 ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFIX": {
        "symbol": "EFIX",
        "name": "First Trust TCW Emerging Markets Debt ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFNL": {
        "symbol": "EFNL",
        "name": "iShares MSCI Finland ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFO": {
        "symbol": "EFO",
        "name": "ProShares Ultra MSCI EAFE",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFR": {
        "symbol": "EFR",
        "name": "Eaton Vance Senior Floating-Rate Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFRA": {
        "symbol": "EFRA",
        "name": "iShares Environmental Infrastructure and Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFSH": {
        "symbol": "EFSH",
        "name": "1847 Holdings LLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFT": {
        "symbol": "EFT",
        "name": "Eaton Vance Floating-Rate Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFU": {
        "symbol": "EFU",
        "name": "ProShares Trust UltraShort MSCI EAFE",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFV": {
        "symbol": "EFV",
        "name": "iShares MSCI EAFE Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFX": {
        "symbol": "EFX",
        "name": "Equifax, Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFXT": {
        "symbol": "EFXT",
        "name": "Enerflex Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EFZ": {
        "symbol": "EFZ",
        "name": "ProShares Trust Short MSCI EAFE",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EG": {
        "symbol": "EG",
        "name": "Everest Group, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EGF": {
        "symbol": "EGF",
        "name": "Blackrock Enhanced Government Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EGO": {
        "symbol": "EGO",
        "name": "Eldorado Gold Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EGP": {
        "symbol": "EGP",
        "name": "EastGroup Properties Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EGUS": {
        "symbol": "EGUS",
        "name": "iShares ESG Aware MSCI USA Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EGY": {
        "symbol": "EGY",
        "name": "Vaalco Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EHAB": {
        "symbol": "EHAB",
        "name": "Enhabit, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EHC": {
        "symbol": "EHC",
        "name": "Encompass Health Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EHI": {
        "symbol": "EHI",
        "name": "Western Asset Global High Income Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EHLS": {
        "symbol": "EHLS",
        "name": "Even Herd Long Short ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIC": {
        "symbol": "EIC",
        "name": "Eagle Point Income Company Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EICA": {
        "symbol": "EICA",
        "name": "Eagle Point Income Company Inc. 5.00% Series A Term Preferred Stock due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EICB": {
        "symbol": "EICB",
        "name": "Eagle Point Income Company Inc. 7.75% Series B Term Preferred Stock Due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EICC": {
        "symbol": "EICC",
        "name": "Eagle Point Income Company Inc. 8.00% Series C Term Preferred Stock due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIDO": {
        "symbol": "EIDO",
        "name": "iShares MSCI Indonesia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIG": {
        "symbol": "EIG",
        "name": "Employers Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIM": {
        "symbol": "EIM",
        "name": "Eaton Vance Municipal Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EINC": {
        "symbol": "EINC",
        "name": "VanEck Energy Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIPI": {
        "symbol": "EIPI",
        "name": "FT Energy Income Partners Enhanced Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIPX": {
        "symbol": "EIPX",
        "name": "FT Energy Income Partners Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIRL": {
        "symbol": "EIRL",
        "name": "iShares MSCI Ireland ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIS": {
        "symbol": "EIS",
        "name": "iShares MSCI Israel ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EIX": {
        "symbol": "EIX",
        "name": "Edison International",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EJAN": {
        "symbol": "EJAN",
        "name": "Innovator Emerging Markets Power Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EJUL": {
        "symbol": "EJUL",
        "name": "Innovator Emerging Markets Power Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EKG": {
        "symbol": "EKG",
        "name": "First Trust Nasdaq Lux Digital Health Solutions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EL": {
        "symbol": "EL",
        "name": "The Estee Lauder Companies Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELA": {
        "symbol": "ELA",
        "name": "Envela Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELAN": {
        "symbol": "ELAN",
        "name": "Elanco Animal Health Incorporated Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELC": {
        "symbol": "ELC",
        "name": "Entergy Louisiana, LLC Collateral Trust Mortgage Bonds, 4.875 % Series due September 1, 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELCV": {
        "symbol": "ELCV",
        "name": "Eventide High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELD": {
        "symbol": "ELD",
        "name": "WisdomTree Emerging Markets Local Debt Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELF": {
        "symbol": "ELF",
        "name": "e.l.f. Beauty, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELLO": {
        "symbol": "ELLO",
        "name": "Ellomay Capital LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELMD": {
        "symbol": "ELMD",
        "name": "Electromed, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELME": {
        "symbol": "ELME",
        "name": "Elme Communities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELP": {
        "symbol": "ELP",
        "name": "Companhia Paranaense de Energia - COPEL American Depositary Shares (each representing four (4) Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELPC": {
        "symbol": "ELPC",
        "name": "Companhia Paranaense de Energia - COPEL American Depositary Shares (each representing four (4) Commo",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELQD": {
        "symbol": "ELQD",
        "name": "iShares ESG Advanced Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELS": {
        "symbol": "ELS",
        "name": "Equity Lifestyle Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ELV": {
        "symbol": "ELV",
        "name": "Elevance Health, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMB": {
        "symbol": "EMB",
        "name": "iShares J.P. Morgan USD Emerging Markets Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMBD": {
        "symbol": "EMBD",
        "name": "Global X Emerging Markets Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMC": {
        "symbol": "EMC",
        "name": "Global X Emerging Markets Great Consumer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCB": {
        "symbol": "EMCB",
        "name": "WisdomTree Emerging Markets Corporate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCC": {
        "symbol": "EMCC",
        "name": "Global X MSCI Emerging Markets Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCGR": {
        "symbol": "EMCGR",
        "name": "Embrace Change Acquisition Corp Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCGU": {
        "symbol": "EMCGU",
        "name": "Embrace Change Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCGW": {
        "symbol": "EMCGW",
        "name": "Embrace Change Acquisition Corp Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMCR": {
        "symbol": "EMCR",
        "name": "Xtrackers Emerging Markets Carbon Reduction and Climate Improvers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMD": {
        "symbol": "EMD",
        "name": "Western Asset Emerging Markets Debt Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMDM": {
        "symbol": "EMDM",
        "name": "First Trust Bloomberg Emerging Market Democracies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMDV": {
        "symbol": "EMDV",
        "name": "ProShares MSCI Emerging Markets Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EME": {
        "symbol": "EME",
        "name": "EMCOR Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMEQ": {
        "symbol": "EMEQ",
        "name": "Macquarie Focused Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMF": {
        "symbol": "EMF",
        "name": "Templeton Emerging Markets Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMGF": {
        "symbol": "EMGF",
        "name": "iShares Emerging Markets Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMHC": {
        "symbol": "EMHC",
        "name": "SPDR Bloomberg Emerging Markets USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMHY": {
        "symbol": "EMHY",
        "name": "iShares J.P. Morgan EM High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMIF": {
        "symbol": "EMIF",
        "name": "iShares Emerging Markets Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMLC": {
        "symbol": "EMLC",
        "name": "VanEck J. P. Morgan EM Local Currency Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMLP": {
        "symbol": "EMLP",
        "name": "First Trust North American Energy Infrastructure Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMM": {
        "symbol": "EMM",
        "name": "Global X Emerging Markets ex-China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMMF": {
        "symbol": "EMMF",
        "name": "WisdomTree Emerging Markets Multifactor Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMN": {
        "symbol": "EMN",
        "name": "Eastman Chemical Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMNT": {
        "symbol": "EMNT",
        "name": "PIMCO Enhanced Short Maturity Active ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMO": {
        "symbol": "EMO",
        "name": "ClearBridge Energy Midstream Opportunity Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMOT": {
        "symbol": "EMOT",
        "name": "First Trust S&P 500 Economic Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMP": {
        "symbol": "EMP",
        "name": "Entergy Mississippi, LLC First Mortgage Bonds, 4.90% Series due October 1, 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMQQ": {
        "symbol": "EMQQ",
        "name": "EMQQ The Emerging Markets Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMR": {
        "symbol": "EMR",
        "name": "Emerson Electric Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMSF": {
        "symbol": "EMSF",
        "name": "Matthews Emerging Markets Sustainable Future Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMSG": {
        "symbol": "EMSG",
        "name": "Xtrackers MSCI Emerging Markets ESG Leaders Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMTL": {
        "symbol": "EMTL",
        "name": "SPDR DoubleLine Emerging Markets Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMTY": {
        "symbol": "EMTY",
        "name": "ProShares Decline of the Retail Store ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMX": {
        "symbol": "EMX",
        "name": "EMX Royalty Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMXC": {
        "symbol": "EMXC",
        "name": "iShares MSCI Emerging Markets ex China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EMXF": {
        "symbol": "EMXF",
        "name": "iShares ESG Advanced MSCI EM ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENB": {
        "symbol": "ENB",
        "name": "Enbridge, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENFN": {
        "symbol": "ENFN",
        "name": "Enfusion, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENFR": {
        "symbol": "ENFR",
        "name": "Alerian Energy Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENGNW": {
        "symbol": "ENGNW",
        "name": "enGene Holdings Inc. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENIC": {
        "symbol": "ENIC",
        "name": "Enel Chile S.A. American Depositary Shares Representing Common Stock (Each representing 50 shares of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENJ": {
        "symbol": "ENJ",
        "name": "Entergy New Orleans, LLC First Mortgage Bonds, 5.0% Series due December 1, 2052",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENLC": {
        "symbol": "ENLC",
        "name": "ENLINK MIDSTREAM, LLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENO": {
        "symbol": "ENO",
        "name": "Entergy New Orleans, LLC First Mortgage Bonds, 5.50% Series due April 1, 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENOR": {
        "symbol": "ENOR",
        "name": "iShares MSCI Norway ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENOV": {
        "symbol": "ENOV",
        "name": "Enovis Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENR": {
        "symbol": "ENR",
        "name": "Energizer Holdings, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENS": {
        "symbol": "ENS",
        "name": "EnerSys, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENSV": {
        "symbol": "ENSV",
        "name": "Enservco Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENV": {
        "symbol": "ENV",
        "name": "ENVESTNET, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENVA": {
        "symbol": "ENVA",
        "name": "Enova International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENX": {
        "symbol": "ENX",
        "name": "Eaton Vance New York Municipal Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENZ": {
        "symbol": "ENZ",
        "name": "Enzo Biochem, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ENZL": {
        "symbol": "ENZL",
        "name": "iShares Trust iShares MSCI New Zealand ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOCT": {
        "symbol": "EOCT",
        "name": "Innovator Emerging Markets Power Buffer ETF-October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOD": {
        "symbol": "EOD",
        "name": "Allspring Global Dividend Opportunity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOG": {
        "symbol": "EOG",
        "name": "EOG Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOI": {
        "symbol": "EOI",
        "name": "Eaton Vance Enhanced Equity Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EONR": {
        "symbol": "EONR",
        "name": "EON Resources Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EONR WS": {
        "symbol": "EONR WS",
        "name": "EON Resources Inc. Warrants, each whole warrant exercisable for three quarters of one share of Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOS": {
        "symbol": "EOS",
        "name": "Eaton Vance Enhance Equity Income Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EOT": {
        "symbol": "EOT",
        "name": "EATON VANCE NATIONAL MUNICIPAL OPPORTUNITIES TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EP": {
        "symbol": "EP",
        "name": "Empire Petroleum Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EP PRC": {
        "symbol": "EP PRC",
        "name": "El Paso Energy Capital Trust I",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPAC": {
        "symbol": "EPAC",
        "name": "Enerpac Tool Group Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPAM": {
        "symbol": "EPAM",
        "name": "EPAM SYSTEMS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPC": {
        "symbol": "EPC",
        "name": "Edgewell Personal Care Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPD": {
        "symbol": "EPD",
        "name": "Enterprise Products Partners L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPHE": {
        "symbol": "EPHE",
        "name": "iShares MSCI Philippines ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPI": {
        "symbol": "EPI",
        "name": "WisdomTree India Earnings Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPM": {
        "symbol": "EPM",
        "name": "Evolution Petroleum Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPOL": {
        "symbol": "EPOL",
        "name": "iShares MSCI Poland ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPP": {
        "symbol": "EPP",
        "name": "iShares MSCI Pacific ex Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPR": {
        "symbol": "EPR",
        "name": "EPR Properties",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPR PRC": {
        "symbol": "EPR PRC",
        "name": "EPR Properties 5.75% Series C Cumulative Convertible Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPR PRE": {
        "symbol": "EPR PRE",
        "name": "EPR Properties 9.00% Series E Cumulative Convertible Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPR PRG": {
        "symbol": "EPR PRG",
        "name": "EPR Properties 5.750% Series G Cumulative Redeemable Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPRF": {
        "symbol": "EPRF",
        "name": "Innovator S&P Investment Grade Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPRT": {
        "symbol": "EPRT",
        "name": "Essential Properties Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPS": {
        "symbol": "EPS",
        "name": "WisdomTree U.S. LargeCap Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPU": {
        "symbol": "EPU",
        "name": "iShares MSCI Peru and Global Exposure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EPV": {
        "symbol": "EPV",
        "name": "ProShares UltraShort FTSE Europe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQAL": {
        "symbol": "EQAL",
        "name": "Invesco Russell 1000 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQBK": {
        "symbol": "EQBK",
        "name": "Equity Bancshares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQC": {
        "symbol": "EQC",
        "name": "Equity Commonwealth",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQC PRD": {
        "symbol": "EQC PRD",
        "name": "Equity Commonwealth 6 1/2% Series D Cumulative Convertible Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQH": {
        "symbol": "EQH",
        "name": "Equitable Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQH PRA": {
        "symbol": "EQH PRA",
        "name": "Equitable Holdings, Inc. Depositary Shares, each representing a 1/1000th interest in a share of Fixe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQH PRC": {
        "symbol": "EQH PRC",
        "name": "Equitable Holdings, Inc. Depositary Shares, each representing a 1/1, 000th interest in a share of Fi",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQIN": {
        "symbol": "EQIN",
        "name": "Columbia U.S. Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQL": {
        "symbol": "EQL",
        "name": "ALPS Equal Sector Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQLS": {
        "symbol": "EQLS",
        "name": "Simplify Market Neutral Equity Long/Short ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQLT": {
        "symbol": "EQLT",
        "name": "iShares MSCI Emerging Markets Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQNR": {
        "symbol": "EQNR",
        "name": "Equinor ASA American Depositary Shares (Each Representing One Ordinary Share, nominal value NOK 2.50",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQR": {
        "symbol": "EQR",
        "name": "Equity Residential",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQRR": {
        "symbol": "EQRR",
        "name": "ProShares Equities for Rising Rates ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQS": {
        "symbol": "EQS",
        "name": "Equus Total Return, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQT": {
        "symbol": "EQT",
        "name": "EQT CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQTY": {
        "symbol": "EQTY",
        "name": "Kovitz Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQUL": {
        "symbol": "EQUL",
        "name": "NYLI Engender Equality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQV": {
        "symbol": "EQV",
        "name": "EQV Ventures Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQV U": {
        "symbol": "EQV U",
        "name": "EQV Ventures Acquisition Corp. Units, each consisting of one Class A ordinary share and one-third of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQV WS": {
        "symbol": "EQV WS",
        "name": "EQV Ventures Acquisition Redeemable warrants, each whole warrant exercisable for one Class A ordinar",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQWL": {
        "symbol": "EQWL",
        "name": "Invesco S&P 100 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EQX": {
        "symbol": "EQX",
        "name": "Equinox Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERC": {
        "symbol": "ERC",
        "name": "Allspring Multi-Sector Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERET": {
        "symbol": "ERET",
        "name": "iShares Environmentally Aware Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERH": {
        "symbol": "ERH",
        "name": "Allspring Utilities and High Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERJ": {
        "symbol": "ERJ",
        "name": "Embraer S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERNZ": {
        "symbol": "ERNZ",
        "name": "TrueShares Active Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERO": {
        "symbol": "ERO",
        "name": "Ero Copper Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERTH": {
        "symbol": "ERTH",
        "name": "Invesco MSCI Sustainable Future ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERX": {
        "symbol": "ERX",
        "name": "Direxion Daily Energy Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ERY": {
        "symbol": "ERY",
        "name": "Direxion Daily Energy Bear 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ES": {
        "symbol": "ES",
        "name": "Eversource Energy",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESAB": {
        "symbol": "ESAB",
        "name": "ESAB Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESBA": {
        "symbol": "ESBA",
        "name": "EMPIRE STATE REALTY OP, L.P.  SERIES ES",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESE": {
        "symbol": "ESE",
        "name": "ESCO Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESG": {
        "symbol": "ESG",
        "name": "FlexShares STOXX US ESG Select Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGA": {
        "symbol": "ESGA",
        "name": "American Century Sustainable Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGB": {
        "symbol": "ESGB",
        "name": "NYLI MacKay ESG Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGD": {
        "symbol": "ESGD",
        "name": "iShares Trust iShares ESG Aware MSCI EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGE": {
        "symbol": "ESGE",
        "name": "iShares, Inc. iShares ESG Aware MSCI EM ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGG": {
        "symbol": "ESGG",
        "name": "FlexShares STOXX Global ESG Select Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGU": {
        "symbol": "ESGU",
        "name": "iShares Trust iShares ESG Aware MSCI USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGV": {
        "symbol": "ESGV",
        "name": "Vanguard ESG U.S. Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGX": {
        "symbol": "ESGX",
        "name": "Tuttle Capital Shareholders First Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESGY": {
        "symbol": "ESGY",
        "name": "American Century Sustainable Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESI": {
        "symbol": "ESI",
        "name": "Element Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESIX": {
        "symbol": "ESIX",
        "name": "SPDR S&P SmallCap 600 ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESML": {
        "symbol": "ESML",
        "name": "iShares ESG Aware MSCI USA Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESMV": {
        "symbol": "ESMV",
        "name": "iShares ESG MSCI USA Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESNT": {
        "symbol": "ESNT",
        "name": "Essent Group LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESP": {
        "symbol": "ESP",
        "name": "Espey Mfg. & Electronics Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESPO": {
        "symbol": "ESPO",
        "name": "VanEck Video Gaming and eSports ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESRT": {
        "symbol": "ESRT",
        "name": "EMPIRE STATE REALTY TRUST, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESS": {
        "symbol": "ESS",
        "name": "Essex Property Trust, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESTC": {
        "symbol": "ESTC",
        "name": "Elastic N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ESUS": {
        "symbol": "ESUS",
        "name": "ETRACS 2x Leveraged MSCI USA ESG Focus TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ET": {
        "symbol": "ET",
        "name": "Energy Transfer LP Common Units representing limited partner interests",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ET PRI": {
        "symbol": "ET PRI",
        "name": "Energy Transfer LP Series I Fixed Rate Perpetual Preferred Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETB": {
        "symbol": "ETB",
        "name": "Eaton Vance Tax-Managed Buy-Write Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETD": {
        "symbol": "ETD",
        "name": "Ethan Allen Interiors Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETEC": {
        "symbol": "ETEC",
        "name": "iShares Breakthrough Environmental Solutions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETG": {
        "symbol": "ETG",
        "name": "Eaton Vance Tax-Advantaged Global Dividend Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETH": {
        "symbol": "ETH",
        "name": "Grayscale Ethereum Mini Trust (ETH)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHD": {
        "symbol": "ETHD",
        "name": "ProShares UltraShort Ether ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHE": {
        "symbol": "ETHE",
        "name": "Grayscale Ethereum Trust (ETH)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHO": {
        "symbol": "ETHO",
        "name": "Amplify Etho Climate Leadership U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHT": {
        "symbol": "ETHT",
        "name": "ProShares Ultra Ether ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHU": {
        "symbol": "ETHU",
        "name": "2x Ether ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHV": {
        "symbol": "ETHV",
        "name": "VanEck Ethereum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETHW": {
        "symbol": "ETHW",
        "name": "Bitwise Ethereum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETI PR": {
        "symbol": "ETI PR",
        "name": "Entergy Texas, Inc. 5.375% Series A Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETJ": {
        "symbol": "ETJ",
        "name": "Eaton Vance Risk - Managed Diversified Equity Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETN": {
        "symbol": "ETN",
        "name": "Eaton Corporation, plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETO": {
        "symbol": "ETO",
        "name": "Eaton Vance Tax-Advantaged Global Div Oppor. Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETR": {
        "symbol": "ETR",
        "name": "Entergy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETV": {
        "symbol": "ETV",
        "name": "Eaton Vance Tax-Managed Buy-Write Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETW": {
        "symbol": "ETW",
        "name": "Eaton Vance Tax-Managed Global Buy-Write Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETWO": {
        "symbol": "ETWO",
        "name": "E2open Parent Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETWO WS": {
        "symbol": "ETWO WS",
        "name": "E2open Parent Holdings, Inc. Warrants, each whole warrant exercisable for one Class A Common Stock a",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETX": {
        "symbol": "ETX",
        "name": "EATON VANCE MUNICIPAL INCOME 2028 TERM TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ETY": {
        "symbol": "ETY",
        "name": "Eaton Vance Tax-Managed Diversified Equity Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUDG": {
        "symbol": "EUDG",
        "name": "WisdomTree Europe Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUDV": {
        "symbol": "EUDV",
        "name": "ProShares MSCI Europe Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUFN": {
        "symbol": "EUFN",
        "name": "iShares MSCI Europe Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUM": {
        "symbol": "EUM",
        "name": "ProShares Trust Short MSCI Emerging Markets",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUO": {
        "symbol": "EUO",
        "name": "ProShares UltraShort Euro",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EURL": {
        "symbol": "EURL",
        "name": "Direxion Daily FTSE Europe Bull 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUSA": {
        "symbol": "EUSA",
        "name": "iShares MSCI USA Equal Weighted ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUSB": {
        "symbol": "EUSB",
        "name": "iShares ESG Advanced Total USD Bond Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EUSC": {
        "symbol": "EUSC",
        "name": "WisdomTree Europe Hedged SmallCap Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EV": {
        "symbol": "EV",
        "name": "Mast Global Battery Recycling & Production ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVAV": {
        "symbol": "EVAV",
        "name": "Direxion Daily Electric and Autonomous Vehicles Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVBN": {
        "symbol": "EVBN",
        "name": "Evans Bancorp Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVC": {
        "symbol": "EVC",
        "name": "Entravision Communication",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVE": {
        "symbol": "EVE",
        "name": "EVe Mobility Acquisition Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVE U": {
        "symbol": "EVE U",
        "name": "EVe Mobility Acquisition Corp Units, each consisting of one Class A ordinary share and one-half of o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVE WS": {
        "symbol": "EVE WS",
        "name": "EVe Mobility Acquisition Corp Redeemable warrants, each whole warrant exercisable for one Class A or",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVEX": {
        "symbol": "EVEX",
        "name": "Eve Holding, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVEX WS": {
        "symbol": "EVEX WS",
        "name": "Eve Holding, Inc. Warrants, each exercisable for one share of Common Stock at an exercise price of $",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVF": {
        "symbol": "EVF",
        "name": "Eaton Vance Senior Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVG": {
        "symbol": "EVG",
        "name": "Eaton Vance Short Duration Diversified Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVGRU": {
        "symbol": "EVGRU",
        "name": "Evergreen Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVH": {
        "symbol": "EVH",
        "name": "Evolent Health, Inc Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVHY": {
        "symbol": "EVHY",
        "name": "Eaton Vance High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVI": {
        "symbol": "EVI",
        "name": "EVI Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVIM": {
        "symbol": "EVIM",
        "name": "Eaton Vance Intermediate Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVLN": {
        "symbol": "EVLN",
        "name": "Eaton Vance Floating-Rate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVLU": {
        "symbol": "EVLU",
        "name": "iShares MSCI Emerging Markets Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVM": {
        "symbol": "EVM",
        "name": "Eaton Vance California Municipal Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVMT": {
        "symbol": "EVMT",
        "name": "Invesco Electric Vehicle Metals Commodity Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVN": {
        "symbol": "EVN",
        "name": "Eaton Vance Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVNT": {
        "symbol": "EVNT",
        "name": "AltShares Event-Driven ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVR": {
        "symbol": "EVR",
        "name": "Evercore Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVRI": {
        "symbol": "EVRI",
        "name": "Everi Holdings Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVSB": {
        "symbol": "EVSB",
        "name": "Eaton Vance Ultra-Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVSD": {
        "symbol": "EVSD",
        "name": "Eaton Vance Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVSM": {
        "symbol": "EVSM",
        "name": "Eaton Vance Short Duration Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVT": {
        "symbol": "EVT",
        "name": "Eaton Vance Tax-Advantaged Dividend Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVTC": {
        "symbol": "EVTC",
        "name": "EVERTEC, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVTL": {
        "symbol": "EVTL",
        "name": "Vertical Aerospace Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVTL WS": {
        "symbol": "EVTL WS",
        "name": "Vertical Aerospace Ltd. Warrants, each whole warrant exercisable for 1/10th of an ordinary share at",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVTR": {
        "symbol": "EVTR",
        "name": "Eaton Vance Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVUS": {
        "symbol": "EVUS",
        "name": "iShares ESG Aware MSCI USA Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVV": {
        "symbol": "EVV",
        "name": "Eaton Vance Limited Duration Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EVX": {
        "symbol": "EVX",
        "name": "VanEck Environmental Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EW": {
        "symbol": "EW",
        "name": "Edwards Lifesciences Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWA": {
        "symbol": "EWA",
        "name": "iShares MSCI Australia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWC": {
        "symbol": "EWC",
        "name": "iShares MSCI Canada ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWD": {
        "symbol": "EWD",
        "name": "iShares MSCI Sweden ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWG": {
        "symbol": "EWG",
        "name": "iShares MSCI Germany ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWH": {
        "symbol": "EWH",
        "name": "iShares MSCI Hong Kong ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWI": {
        "symbol": "EWI",
        "name": "iShares MSCI Italy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWJ": {
        "symbol": "EWJ",
        "name": "iShares MSCI Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWJV": {
        "symbol": "EWJV",
        "name": "iShares MSCI Japan Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWK": {
        "symbol": "EWK",
        "name": "iShares MSCI Belgium ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWL": {
        "symbol": "EWL",
        "name": "iShares MSCI Switzerland ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWM": {
        "symbol": "EWM",
        "name": "iShares MSCI Malaysia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWN": {
        "symbol": "EWN",
        "name": "iShares MSCI Netherlands ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWO": {
        "symbol": "EWO",
        "name": "iShares MSCI Austria ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWP": {
        "symbol": "EWP",
        "name": "iShares MSCI Spain ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWQ": {
        "symbol": "EWQ",
        "name": "iShares MSCI France ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWS": {
        "symbol": "EWS",
        "name": "iShares MSCI Singapore ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWT": {
        "symbol": "EWT",
        "name": "iShares MSCI Taiwan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWU": {
        "symbol": "EWU",
        "name": "iShares MSCI United Kingdom ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWUS": {
        "symbol": "EWUS",
        "name": "iShares MSCI United Kingdom Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWV": {
        "symbol": "EWV",
        "name": "ProShares Trust UltraShort MSCI Japan",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWW": {
        "symbol": "EWW",
        "name": "iShares MSCI Mexico ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWX": {
        "symbol": "EWX",
        "name": "SPDR S&P Emerging Markets Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWY": {
        "symbol": "EWY",
        "name": "iShares MSCI South Korea ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWZ": {
        "symbol": "EWZ",
        "name": "iShares MSCI Brazil ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EWZS": {
        "symbol": "EWZS",
        "name": "iShares MSCI Brazil Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXE": {
        "symbol": "EXE",
        "name": "Expand Energy Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXEEL": {
        "symbol": "EXEEL",
        "name": "Expand Energy Corporation Class C Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXEEW": {
        "symbol": "EXEEW",
        "name": "Expand Energy Corporation Class A Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXEEZ": {
        "symbol": "EXEEZ",
        "name": "Expand Energy Corporation Class B Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXG": {
        "symbol": "EXG",
        "name": "Eaton Vance Tax-Managed Global Diversified Equity Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXI": {
        "symbol": "EXI",
        "name": "iShares Global Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXK": {
        "symbol": "EXK",
        "name": "Endeavour Silver Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXP": {
        "symbol": "EXP",
        "name": "Eagle Materials, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXPD": {
        "symbol": "EXPD",
        "name": "Expeditors International of Washington, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXR": {
        "symbol": "EXR",
        "name": "Extra Space Storage, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EXTO": {
        "symbol": "EXTO",
        "name": "Almacenes Exito S.A. American Depositary Share, each representing eight (8) Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EYEG": {
        "symbol": "EYEG",
        "name": "AB Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EYLD": {
        "symbol": "EYLD",
        "name": "Cambria Emerging Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZA": {
        "symbol": "EZA",
        "name": "iShares MSCI South Africa ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZBC": {
        "symbol": "EZBC",
        "name": "Franklin Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZET": {
        "symbol": "EZET",
        "name": "Franklin Ethereum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZJ": {
        "symbol": "EZJ",
        "name": "ProShares Ultra MSCI Japan",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZM": {
        "symbol": "EZM",
        "name": "WisdomTree U.S. MidCap Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "EZU": {
        "symbol": "EZU",
        "name": "iShare MSCI Eurozone ETF of ISHARES INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "F": {
        "symbol": "F",
        "name": "Ford Motor Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "F PRB": {
        "symbol": "F PRB",
        "name": "Ford Motor Company 6.20% Notes due June 1, 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "F PRC": {
        "symbol": "F PRC",
        "name": "Ford Motor Company 6.000% Notes due December 1, 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "F PRD": {
        "symbol": "F PRD",
        "name": "Ford Motor Company 6.500% Notes due August 15, 2062",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAAR": {
        "symbol": "FAAR",
        "name": "First Trust Alternative Absolute Return Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAB": {
        "symbol": "FAB",
        "name": "First Trust Multi Cap Value AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAD": {
        "symbol": "FAD",
        "name": "First Trust Multi Cap Growth AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAF": {
        "symbol": "FAF",
        "name": "First American Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAIL": {
        "symbol": "FAIL",
        "name": "Cambria Global Tail Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FALN": {
        "symbol": "FALN",
        "name": "iShares Fallen Angels USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAN": {
        "symbol": "FAN",
        "name": "First Trust Global Wind Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAPR": {
        "symbol": "FAPR",
        "name": "FT Vest U.S. Equity Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAS": {
        "symbol": "FAS",
        "name": "Direxion Daily Financial Bull 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAUG": {
        "symbol": "FAUG",
        "name": "FT Vest U.S. Equity Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAX": {
        "symbol": "FAX",
        "name": "abrdn Asia-Pacific Income Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FAZ": {
        "symbol": "FAZ",
        "name": "Direxion Daily Financial Bear 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBCG": {
        "symbol": "FBCG",
        "name": "Fidelity Blue Chip Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBCV": {
        "symbol": "FBCV",
        "name": "Fidelity Blue Chip Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBIN": {
        "symbol": "FBIN",
        "name": "Fortune Brands Innovations, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBK": {
        "symbol": "FBK",
        "name": "FB Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBL": {
        "symbol": "FBL",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long META Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBMS": {
        "symbol": "FBMS",
        "name": "The First Bancshares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBND": {
        "symbol": "FBND",
        "name": "Fidelity Total Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBOT": {
        "symbol": "FBOT",
        "name": "Fidelity Disruptive Automation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBP": {
        "symbol": "FBP",
        "name": "First BanCorp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBRT": {
        "symbol": "FBRT",
        "name": "Franklin BSP Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBRT PRE": {
        "symbol": "FBRT PRE",
        "name": "Franklin BSP Realty Trust, Inc. 7.50% Series E Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBT": {
        "symbol": "FBT",
        "name": "First Trust NYSE Arca Biotechnology Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBTC": {
        "symbol": "FBTC",
        "name": "Fidelity Wise Origin Bitcoin Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBUF": {
        "symbol": "FBUF",
        "name": "Fidelity Dynamic Buffered Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBY": {
        "symbol": "FBY",
        "name": "YieldMax META Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FBZ": {
        "symbol": "FBZ",
        "name": "First Trust Brazil AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FC": {
        "symbol": "FC",
        "name": "Franklin Covey Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCA": {
        "symbol": "FCA",
        "name": "First Trust China AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCAL": {
        "symbol": "FCAL",
        "name": "First Trust California Municipal High income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCEF": {
        "symbol": "FCEF",
        "name": "First Trust Exchange-Traded Fund VIII First Trust Income Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCF": {
        "symbol": "FCF",
        "name": "First Commonwealth Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCFY": {
        "symbol": "FCFY",
        "name": "First Trust S&P 500 Diversified Free Cash Flow ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCG": {
        "symbol": "FCG",
        "name": "First Trust Natural Gas ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCLD": {
        "symbol": "FCLD",
        "name": "Fidelity Cloud Computing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCN": {
        "symbol": "FCN",
        "name": "FTI Consulting, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCO": {
        "symbol": "FCO",
        "name": "abrdn Global Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCOM": {
        "symbol": "FCOM",
        "name": "Fidelity MSCI Communication Services Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCOR": {
        "symbol": "FCOR",
        "name": "Fidelity Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCPI": {
        "symbol": "FCPI",
        "name": "Fidelity Stocks for Inflation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCPT": {
        "symbol": "FCPT",
        "name": "Four Corners Property Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCRX": {
        "symbol": "FCRX",
        "name": "Crescent Capital BDC, Inc. 5.00% Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCSH": {
        "symbol": "FCSH",
        "name": "Federated Hermes Short Duration Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCT": {
        "symbol": "FCT",
        "name": "First Trust Senior Floating Rate Income Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCTE": {
        "symbol": "FCTE",
        "name": "SMI 3Fourteen Full-Cycle Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCTR": {
        "symbol": "FCTR",
        "name": "First Trust Lunt U.S. Factor Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCUS": {
        "symbol": "FCUS",
        "name": "Pinnacle Focused Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCVT": {
        "symbol": "FCVT",
        "name": "First Trust SSI Strategic Convertible Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FCX": {
        "symbol": "FCX",
        "name": "Freeport-McMoran Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDAT": {
        "symbol": "FDAT",
        "name": "Tactical Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDCE": {
        "symbol": "FDCE",
        "name": "Foundations Dynamic Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDCF": {
        "symbol": "FDCF",
        "name": "Fidelity Disruptive Communications ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDD": {
        "symbol": "FDD",
        "name": "First Trust STOXX European Select Dividend Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDEC": {
        "symbol": "FDEC",
        "name": "FT Vest U.S. Equity Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDEM": {
        "symbol": "FDEM",
        "name": "Fidelity Emerging Markets Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDEV": {
        "symbol": "FDEV",
        "name": "Fidelity International Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDFF": {
        "symbol": "FDFF",
        "name": "Fidelity Disruptive Finance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDG": {
        "symbol": "FDG",
        "name": "American Century Focused Dynamic Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDGR": {
        "symbol": "FDGR",
        "name": "Foundations Dynamic Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDHT": {
        "symbol": "FDHT",
        "name": "Fidelity Digital Health ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDHY": {
        "symbol": "FDHY",
        "name": "Fidelity Enhanced High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDIF": {
        "symbol": "FDIF",
        "name": "Fidelity Disruptors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDIG": {
        "symbol": "FDIG",
        "name": "Fidelity Crypto Industry and Digital Payments ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDIS": {
        "symbol": "FDIS",
        "name": "Fidelity MSCI Consumer Discretionary Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDIV": {
        "symbol": "FDIV",
        "name": "MarketDesk Focused U.S. Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDL": {
        "symbol": "FDL",
        "name": "First Trust Morningstar Dividend Leaders Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDLO": {
        "symbol": "FDLO",
        "name": "Fidelity Low Volatility Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDLS": {
        "symbol": "FDLS",
        "name": "Inspire Fidelis Multi Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDM": {
        "symbol": "FDM",
        "name": "First Trust Dow Jones Select MicroCap Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDMO": {
        "symbol": "FDMO",
        "name": "Fidelity Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDN": {
        "symbol": "FDN",
        "name": "First Trust Dow Jones Internet Index Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDND": {
        "symbol": "FDND",
        "name": "FT Vest Dow Jones Internet & Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDNI": {
        "symbol": "FDNI",
        "name": "First Trust Dow Jones International Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDP": {
        "symbol": "FDP",
        "name": "Fresh Del Monte Produce Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDRR": {
        "symbol": "FDRR",
        "name": "Fidelity Dividend ETF for Rising Rates",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDRV": {
        "symbol": "FDRV",
        "name": "Fidelity Electric Vehicles and Future Transportation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDS": {
        "symbol": "FDS",
        "name": "Factset Research Systems",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDT": {
        "symbol": "FDT",
        "name": "First Trust Developed Markets ex-US AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDTB": {
        "symbol": "FDTB",
        "name": "Foundations Dynamic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDTS": {
        "symbol": "FDTS",
        "name": "First Trust Developed Markets ex-US Small Cap AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDTX": {
        "symbol": "FDTX",
        "name": "Fidelity Disruptive Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDV": {
        "symbol": "FDV",
        "name": "Federated Hermes U.S. Strategic Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDVL": {
        "symbol": "FDVL",
        "name": "Foundations Dynamic Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDVV": {
        "symbol": "FDVV",
        "name": "Fidelity High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDWM": {
        "symbol": "FDWM",
        "name": "Fidelity Women's Leadership ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FDX": {
        "symbol": "FDX",
        "name": "FedEx Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FE": {
        "symbol": "FE",
        "name": "FirstEnergy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEBP": {
        "symbol": "FEBP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEBT": {
        "symbol": "FEBT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Feb ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEBW": {
        "symbol": "FEBW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Feb ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEBZ": {
        "symbol": "FEBZ",
        "name": "TrueShares Structured Outcome (February) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEDL": {
        "symbol": "FEDL",
        "name": "ETRACS 2x Leveraged IFED Invest with the Fed TR Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEDM": {
        "symbol": "FEDM",
        "name": "FlexShares ESG & Climate Developed Markets ex-US Core Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEDU": {
        "symbol": "FEDU",
        "name": "Four Seasons Education (Cayman) Inc. American depositary shares, each representing ten (10) Ordinary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEIG": {
        "symbol": "FEIG",
        "name": "FlexShares ESG & Climate Investment Grade Corporate Core Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FELC": {
        "symbol": "FELC",
        "name": "Fidelity Enhanced Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FELG": {
        "symbol": "FELG",
        "name": "Fidelity Enhanced Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FELV": {
        "symbol": "FELV",
        "name": "Fidelity Enhanced Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEM": {
        "symbol": "FEM",
        "name": "First Trust Emerging Markets AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEMB": {
        "symbol": "FEMB",
        "name": "First Trust Emerging Markets Local Currency Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEMS": {
        "symbol": "FEMS",
        "name": "First Trust Emerging Markets Small Cap AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FENG": {
        "symbol": "FENG",
        "name": "Phoenix New Media Limited American Depositary Shares (each representing forty-eight (48) Class A Ord",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FENI": {
        "symbol": "FENI",
        "name": "Fidelity Enhanced International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FENY": {
        "symbol": "FENY",
        "name": "Fidelity MSCI Energy Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEP": {
        "symbol": "FEP",
        "name": "First Trust Europe AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEPI": {
        "symbol": "FEPI",
        "name": "REX FANG & Innovation Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FERG": {
        "symbol": "FERG",
        "name": "Ferguson Enterprises Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FESM": {
        "symbol": "FESM",
        "name": "Fidelity Enhanced Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FET": {
        "symbol": "FET",
        "name": "Forum Energy Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FETH": {
        "symbol": "FETH",
        "name": "Fidelity Ethereum Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEUS": {
        "symbol": "FEUS",
        "name": "FlexShares ESG & Climate US Large Cap Core Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEUZ": {
        "symbol": "FEUZ",
        "name": "First Trust Eurozone AlphaDEX",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEX": {
        "symbol": "FEX",
        "name": "First Trust Large Cap Core AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FEZ": {
        "symbol": "FEZ",
        "name": "SPDR EURO STOXX 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FF": {
        "symbol": "FF",
        "name": "Future Fuel Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFA": {
        "symbol": "FFA",
        "name": "First Trust Enhanced Equity Income Fund.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFC": {
        "symbol": "FFC",
        "name": "Flaherty & Crumrine Preferred and Income Securities Fund Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFEB": {
        "symbol": "FFEB",
        "name": "FT Vest U.S. Equity Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFIU": {
        "symbol": "FFIU",
        "name": "UVA Unconstrained Medium-Term Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFLC": {
        "symbol": "FFLC",
        "name": "Fidelity Fundamental Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFLG": {
        "symbol": "FFLG",
        "name": "Fidelity Fundamental Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFLS": {
        "symbol": "FFLS",
        "name": "The Future Fund Long/Short ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFLV": {
        "symbol": "FFLV",
        "name": "Fidelity Fundamental Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFND": {
        "symbol": "FFND",
        "name": "The Future Fund Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFOG": {
        "symbol": "FFOG",
        "name": "Franklin Focused Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFSM": {
        "symbol": "FFSM",
        "name": "Fidelity Fundamental Small-Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFTY": {
        "symbol": "FFTY",
        "name": "Innovator IBD 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FFWM": {
        "symbol": "FFWM",
        "name": "First Foundation Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FG": {
        "symbol": "FG",
        "name": "F&G Annuities & Life, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FGB": {
        "symbol": "FGB",
        "name": "First Trust Specialty Finance and Financial Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FGD": {
        "symbol": "FGD",
        "name": "First Trust Dow Jones Global Select Dividend Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FGDL": {
        "symbol": "FGDL",
        "name": "Franklin Responsibly Sourced Gold ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FGM": {
        "symbol": "FGM",
        "name": "First Trust Germany AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FGN": {
        "symbol": "FGN",
        "name": "F&G Annuities & Life, Inc. 7.950% Senior Notes due 2053",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHEQ": {
        "symbol": "FHEQ",
        "name": "Fidelity Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHI": {
        "symbol": "FHI",
        "name": "Federated Hermes, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHLC": {
        "symbol": "FHLC",
        "name": "Fidelity MSCI Health Care Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHN": {
        "symbol": "FHN",
        "name": "First Horizon Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHN PRB": {
        "symbol": "FHN PRB",
        "name": "First Horizon Corporation Depositary Shares, each representing a 1/400th interest in a share of Non-",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHN PRC": {
        "symbol": "FHN PRC",
        "name": "First Horizon Corporation Depositary Shares, each representing a 1/400th interest in a share of Non-",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHN PRE": {
        "symbol": "FHN PRE",
        "name": "First Horizon Corporation Depositary Shares, Each Representing a 1/ 4000th Interest in a Share of No",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHN PRF": {
        "symbol": "FHN PRF",
        "name": "First Horizon Corporation Depositary Shares, each representing 1/4000th Interest in a Share of Non-C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FHYS": {
        "symbol": "FHYS",
        "name": "Federated Hermes Short Duration High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FI": {
        "symbol": "FI",
        "name": "Fiserv, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIAT": {
        "symbol": "FIAT",
        "name": "YieldMax Short COIN Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIAX": {
        "symbol": "FIAX",
        "name": "Nicholas Fixed Income Alternative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIBR": {
        "symbol": "FIBR",
        "name": "iShares U.S. Fixed Income Balanced Risk Systematic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FICO": {
        "symbol": "FICO",
        "name": "Fair Isaac Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FICS": {
        "symbol": "FICS",
        "name": "First Trust International Developed Capital Strength ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FID": {
        "symbol": "FID",
        "name": "First Trust Exchange-Traded Fund VI First Trust S&P International Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIDI": {
        "symbol": "FIDI",
        "name": "Fidelity International High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIDU": {
        "symbol": "FIDU",
        "name": "Fidelity MSCI Industrials Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIG": {
        "symbol": "FIG",
        "name": "Simplify Macro Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIGB": {
        "symbol": "FIGB",
        "name": "Fidelity Investment Grade Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIGS": {
        "symbol": "FIGS",
        "name": "FIGS, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIHL": {
        "symbol": "FIHL",
        "name": "Fidelis Insurance Holdings Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIIG": {
        "symbol": "FIIG",
        "name": "First Trust Intermediate Duration Investment Grade Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FILL": {
        "symbol": "FILL",
        "name": "iShares MSCI Global Energy Producers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FINE": {
        "symbol": "FINE",
        "name": "Themes European Luxury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FINS": {
        "symbol": "FINS",
        "name": "Angel Oak Financial Strategies Income Term Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FINV": {
        "symbol": "FINV",
        "name": "FinVolution Group American depositary shares, each representing five Class A ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FINX": {
        "symbol": "FINX",
        "name": "Global X Funds Global X FinTech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIS": {
        "symbol": "FIS",
        "name": "Fidelity National Information Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FISK": {
        "symbol": "FISK",
        "name": "Empire State Realty OP, L.P. Series 250",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FISR": {
        "symbol": "FISR",
        "name": "SPDR SSGA Fixed Income Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FITE": {
        "symbol": "FITE",
        "name": "SPDR S&P Kensho Future Security ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIVA": {
        "symbol": "FIVA",
        "name": "Fidelity International Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIW": {
        "symbol": "FIW",
        "name": "First Trust Water ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIX": {
        "symbol": "FIX",
        "name": "Comfort Systems USA, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIXD": {
        "symbol": "FIXD",
        "name": "First Trust TCW Opportunistic Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FIXT": {
        "symbol": "FIXT",
        "name": "Procure Disaster Recovery Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FJAN": {
        "symbol": "FJAN",
        "name": "FT Vest U.S. Equity Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FJP": {
        "symbol": "FJP",
        "name": "First Trust Japan AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FJUL": {
        "symbol": "FJUL",
        "name": "FT Vest U.S. Equity Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FJUN": {
        "symbol": "FJUN",
        "name": "FT Vest U.S. Equity Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FKU": {
        "symbol": "FKU",
        "name": "First Trust United Kingdom AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FL": {
        "symbol": "FL",
        "name": "Foot Locker, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLAO": {
        "symbol": "FLAO",
        "name": "AllianzIM U.S. Equity 6 Month Floor5 Apr/Oct ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLAU": {
        "symbol": "FLAU",
        "name": "Franklin FTSE Australia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLAX": {
        "symbol": "FLAX",
        "name": "Franklin FTSE Asia ex Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLBL": {
        "symbol": "FLBL",
        "name": "Franklin Senior Loan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLBR": {
        "symbol": "FLBR",
        "name": "Franklin FTSE Brazil ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLC": {
        "symbol": "FLC",
        "name": "Flaherty & Crumrine Total Return Fund Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCA": {
        "symbol": "FLCA",
        "name": "Franklin FTSE Canada ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCB": {
        "symbol": "FLCB",
        "name": "Franklin U.S. Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCC": {
        "symbol": "FLCC",
        "name": "Federated Hermes MDT Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCG": {
        "symbol": "FLCG",
        "name": "Federated Hermes MDT Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCH": {
        "symbol": "FLCH",
        "name": "Franklin FTSE China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCO": {
        "symbol": "FLCO",
        "name": "Franklin Investment Grade Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLCV": {
        "symbol": "FLCV",
        "name": "Federated Hermes MDT Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLDB": {
        "symbol": "FLDB",
        "name": "Fidelity Low Duration Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLDR": {
        "symbol": "FLDR",
        "name": "Fidelity Low Duration Bond Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLDZ": {
        "symbol": "FLDZ",
        "name": "RiverNorth Patriot ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLEE": {
        "symbol": "FLEE",
        "name": "Franklin FTSE Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLEU": {
        "symbol": "FLEU",
        "name": "Franklin FTSE Eurozone ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLGB": {
        "symbol": "FLGB",
        "name": "Franklin FTSE United Kingdom ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLGR": {
        "symbol": "FLGR",
        "name": "Franklin FTSE Germany ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLGV": {
        "symbol": "FLGV",
        "name": "Franklin U.S. Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLHK": {
        "symbol": "FLHK",
        "name": "Franklin FTSE Hong Kong ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLHY": {
        "symbol": "FLHY",
        "name": "Franklin High Yield Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLIA": {
        "symbol": "FLIA",
        "name": "Franklin International Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLIN": {
        "symbol": "FLIN",
        "name": "Franklin FTSE India ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLJH": {
        "symbol": "FLJH",
        "name": "Franklin FTSE Japan Hedged ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLJJ": {
        "symbol": "FLJJ",
        "name": "AllianzIM U.S. Equity 6 Month Floor5 Jan/Jul ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLJP": {
        "symbol": "FLJP",
        "name": "Franklin FTSE Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLKR": {
        "symbol": "FLKR",
        "name": "Franklin FTSE South Korea ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLLA": {
        "symbol": "FLLA",
        "name": "Franklin FTSE Latin America",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLMB": {
        "symbol": "FLMB",
        "name": "Franklin Municipal Green Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLMI": {
        "symbol": "FLMI",
        "name": "Franklin Dynamic Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLMX": {
        "symbol": "FLMX",
        "name": "Franklin FTSE Mexico ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLN": {
        "symbol": "FLN",
        "name": "First Trust Latin America AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLNG": {
        "symbol": "FLNG",
        "name": "FLEX LNG Ltd. Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLO": {
        "symbol": "FLO",
        "name": "Flowers Foods, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLOT": {
        "symbol": "FLOT",
        "name": "iShares Floating Rate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLOW": {
        "symbol": "FLOW",
        "name": "Global X U.S. Cash Flow Kings 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLQL": {
        "symbol": "FLQL",
        "name": "Franklin U.S. Large Cap Multifactor Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLQM": {
        "symbol": "FLQM",
        "name": "Franklin U.S. Mid Cap Multifactor Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLQS": {
        "symbol": "FLQS",
        "name": "Franklin U.S. Small Cap Multifactor Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLR": {
        "symbol": "FLR",
        "name": "Fluor Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLRG": {
        "symbol": "FLRG",
        "name": "Fidelity U.S. Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLRN": {
        "symbol": "FLRN",
        "name": "SPDR Bloomberg Investment Grade Floating Rate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLRT": {
        "symbol": "FLRT",
        "name": "Pacer Pacific Asset Floating Rate High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLS": {
        "symbol": "FLS",
        "name": "Flowserve Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLSA": {
        "symbol": "FLSA",
        "name": "Franklin FTSE Saudi Arabia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLSP": {
        "symbol": "FLSP",
        "name": "Franklin Systematic Style Premia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLSW": {
        "symbol": "FLSW",
        "name": "Franklin FTSE Switzerland ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLTB": {
        "symbol": "FLTB",
        "name": "Fidelity Limited Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLTR": {
        "symbol": "FLTR",
        "name": "VanEck IG Floating Rate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLTW": {
        "symbol": "FLTW",
        "name": "Franklin FTSE Taiwan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLUD": {
        "symbol": "FLUD",
        "name": "Franklin Ultra Short Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLUT": {
        "symbol": "FLUT",
        "name": "Flutter Entertainment plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLV": {
        "symbol": "FLV",
        "name": "American Century Focused Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLX": {
        "symbol": "FLX",
        "name": "BingEx Limited American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLXR": {
        "symbol": "FLXR",
        "name": "TCW Flexible Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLYD": {
        "symbol": "FLYD",
        "name": "MicroSectors Travel -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLYU": {
        "symbol": "FLYU",
        "name": "MicroSectors Travel 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLYX": {
        "symbol": "FLYX",
        "name": "flyExclusive, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FLYX WS": {
        "symbol": "FLYX WS",
        "name": "flyExclusive, Inc. Redeemable warrants, each whole warrant exercisable for one Class A common stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FM": {
        "symbol": "FM",
        "name": "iShares Frontier and Select EM ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMAG": {
        "symbol": "FMAG",
        "name": "Fidelity Magellan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMAR": {
        "symbol": "FMAR",
        "name": "FT Vest U.S. Equity Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMAT": {
        "symbol": "FMAT",
        "name": "Fidelity MSCI Materials Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMAY": {
        "symbol": "FMAY",
        "name": "FT Vest U.S. Equity Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMB": {
        "symbol": "FMB",
        "name": "First Trust Managed Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMC": {
        "symbol": "FMC",
        "name": "FMC Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMCX": {
        "symbol": "FMCX",
        "name": "FM Focus Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMDE": {
        "symbol": "FMDE",
        "name": "Fidelity Enhanced Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMED": {
        "symbol": "FMED",
        "name": "Fidelity Disruptive Medicine ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMET": {
        "symbol": "FMET",
        "name": "Fidelity Metaverse ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMF": {
        "symbol": "FMF",
        "name": "First Trust Managed Futures Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMHI": {
        "symbol": "FMHI",
        "name": "First Trust Municipal High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMN": {
        "symbol": "FMN",
        "name": "Federated Hermes Premier Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMNY": {
        "symbol": "FMNY",
        "name": "First Trust New York Municipal High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMQQ": {
        "symbol": "FMQQ",
        "name": "FMQQ The Next Frontier Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMS": {
        "symbol": "FMS",
        "name": "Fresenius Medical Care AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMX": {
        "symbol": "FMX",
        "name": "FOMENTO ECONOMICO MEXICANO, S.A.B. DE C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FMY": {
        "symbol": "FMY",
        "name": "FIRST TRUST MORTGAGE INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FN": {
        "symbol": "FN",
        "name": "Fabrinet",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNA": {
        "symbol": "FNA",
        "name": "Paragon 28, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNB": {
        "symbol": "FNB",
        "name": "F.N.B. Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNCL": {
        "symbol": "FNCL",
        "name": "Fidelity MSCI Financials Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FND": {
        "symbol": "FND",
        "name": "Floor & Decor Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDA": {
        "symbol": "FNDA",
        "name": "Schwab Fundamental U.S. Small Company ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDB": {
        "symbol": "FNDB",
        "name": "Schwab Fundamental U.S. Broad Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDC": {
        "symbol": "FNDC",
        "name": "Schwab Fundamental International Small Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDE": {
        "symbol": "FNDE",
        "name": "Schwab Fundamental Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDF": {
        "symbol": "FNDF",
        "name": "Schwab Fundamental International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNDX": {
        "symbol": "FNDX",
        "name": "Schwab Fundamental U.S. Large Company ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNF": {
        "symbol": "FNF",
        "name": "Fidelity National Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNGD": {
        "symbol": "FNGD",
        "name": "MicroSectors FANG+ Index -3X Inverse Leveraged ETNs due January 8, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNGG": {
        "symbol": "FNGG",
        "name": "Direxion Daily NYSE FANG+ Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNGO": {
        "symbol": "FNGO",
        "name": "MicroSectors FANG+ Index 2X Leveraged ETNs due January 8, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNGS": {
        "symbol": "FNGS",
        "name": "MicroSectors FANG+ ETNs due January 8, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNGU": {
        "symbol": "FNGU",
        "name": "MicroSectors FANG+ Index 3X Leveraged ETNs due January 8, 2038",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNK": {
        "symbol": "FNK",
        "name": "First Trust Mid Cap Value AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNOV": {
        "symbol": "FNOV",
        "name": "FT Vest U.S. Equity Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNV": {
        "symbol": "FNV",
        "name": "Franco-Nevada Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNVTU": {
        "symbol": "FNVTU",
        "name": "Finnovate Acquisition Corp. Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNX": {
        "symbol": "FNX",
        "name": "First Trust Mid Cap Core AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FNY": {
        "symbol": "FNY",
        "name": "First Trust Mid Cap Growth AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOA": {
        "symbol": "FOA",
        "name": "Finance of America Companies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOCT": {
        "symbol": "FOCT",
        "name": "FT Vest U.S. Equity Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOF": {
        "symbol": "FOF",
        "name": "Cohen & Steers Closed End Opportunity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOR": {
        "symbol": "FOR",
        "name": "Forestar Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FORH": {
        "symbol": "FORH",
        "name": "Formidable ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FORL": {
        "symbol": "FORL",
        "name": "Four Leaf Acquisition Corporation Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FORLU": {
        "symbol": "FORLU",
        "name": "Four Leaf Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOUR": {
        "symbol": "FOUR",
        "name": "Shift4 Payments, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOVL": {
        "symbol": "FOVL",
        "name": "iShares Focused Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOXO": {
        "symbol": "FOXO",
        "name": "FOXO Technologies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FOXXW": {
        "symbol": "FOXXW",
        "name": "Foxx Development Holdings Inc. Warrant expiring 9/26/2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPA": {
        "symbol": "FPA",
        "name": "First Trust Asia Pacific ex-Japan AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPAG": {
        "symbol": "FPAG",
        "name": "FPA Global Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPE": {
        "symbol": "FPE",
        "name": "First Trust Preferred Securities and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPEI": {
        "symbol": "FPEI",
        "name": "First Trust Institutional Preferred Securities and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPF": {
        "symbol": "FPF",
        "name": "FIRST TRUST INTERMEDIATE DURATION PREFERRED & INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPFD": {
        "symbol": "FPFD",
        "name": "Fidelity Preferred Securities & Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPH": {
        "symbol": "FPH",
        "name": "Five Point Holdings, LLC Class A Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPI": {
        "symbol": "FPI",
        "name": "Farmland Partners Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPRO": {
        "symbol": "FPRO",
        "name": "Fidelity Real Estate Investment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPX": {
        "symbol": "FPX",
        "name": "First Trust US Equity Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPXE": {
        "symbol": "FPXE",
        "name": "First Trust IPOX Europe Equity Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FPXI": {
        "symbol": "FPXI",
        "name": "First Trust Exchange-Traded Fund II First Trust International Equity Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FQAL": {
        "symbol": "FQAL",
        "name": "Fidelity Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FR": {
        "symbol": "FR",
        "name": "First Industrial Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRA": {
        "symbol": "FRA",
        "name": "Blackrock Floating Rate Income Strategies Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRD": {
        "symbol": "FRD",
        "name": "Friedman Industries, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRDM": {
        "symbol": "FRDM",
        "name": "Freedom 100 Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FREL": {
        "symbol": "FREL",
        "name": "Fidelity MSCI Real Estate Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FREY": {
        "symbol": "FREY",
        "name": "FREYR Battery, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FREY WS": {
        "symbol": "FREY WS",
        "name": "FREYR Battery, Inc. Warrants, each whole warrant exercisable to purchase one Common Stock at an exer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRGE": {
        "symbol": "FRGE",
        "name": "Forge Global Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRI": {
        "symbol": "FRI",
        "name": "First Trust S&P REIT Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRLAU": {
        "symbol": "FRLAU",
        "name": "Fortune Rise Acquisition Corporation Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRLAW": {
        "symbol": "FRLAW",
        "name": "Fortune Rise Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRNW": {
        "symbol": "FRNW",
        "name": "Fidelity Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRO": {
        "symbol": "FRO",
        "name": "Frontline Plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRT": {
        "symbol": "FRT",
        "name": "Federal Realty Investment Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRT PRC": {
        "symbol": "FRT PRC",
        "name": "Federal Realty Investment Trust Depositary Shares, each representing a 1/1000th interest in a 5.000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FRTY": {
        "symbol": "FRTY",
        "name": "Alger Mid Cap 40 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSBD": {
        "symbol": "FSBD",
        "name": "Fidelity Sustainable Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSCC": {
        "symbol": "FSCC",
        "name": "Federated Hermes MDT Small Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSCO": {
        "symbol": "FSCO",
        "name": "FS Credit Opportunities Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSCS": {
        "symbol": "FSCS",
        "name": "First Trust Exchange-Traded Fund VI First Trust SMID Capital Strength ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSEC": {
        "symbol": "FSEC",
        "name": "Fidelity Investment Grade Securitized ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSEP": {
        "symbol": "FSEP",
        "name": "FT Vest U.S. Equity Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSHPR": {
        "symbol": "FSHPR",
        "name": "Flag Ship Acquisition Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSHPU": {
        "symbol": "FSHPU",
        "name": "Flag Ship Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSI": {
        "symbol": "FSI",
        "name": "Flexible Solutions International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSIG": {
        "symbol": "FSIG",
        "name": "First Trust Limited Duration Investment Grade Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSK": {
        "symbol": "FSK",
        "name": "FS KKR Capital Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSLD": {
        "symbol": "FSLD",
        "name": "Fidelity Sustainable Low Duration Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSLY": {
        "symbol": "FSLY",
        "name": "Fastly, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSM": {
        "symbol": "FSM",
        "name": "Fortuna Mining Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSMB": {
        "symbol": "FSMB",
        "name": "First Trust Short Duration Managed Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSMD": {
        "symbol": "FSMD",
        "name": "Fidelity Small-Mid Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSP": {
        "symbol": "FSP",
        "name": "Franklin Street Properties Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSS": {
        "symbol": "FSS",
        "name": "Federal Signal Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSST": {
        "symbol": "FSST",
        "name": "Fidelity Sustainable U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSTA": {
        "symbol": "FSTA",
        "name": "Fidelity MSCI Consumer Staples Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSYD": {
        "symbol": "FSYD",
        "name": "Fidelity Sustainable High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FSZ": {
        "symbol": "FSZ",
        "name": "First Trust Switzerland AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FT": {
        "symbol": "FT",
        "name": "Franklin Universal Trust Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTA": {
        "symbol": "FTA",
        "name": "First Trust Large Cap Value AlphaDEX Fund (based on the Defined Large Cap Value Index--symbol DEFILV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTAG": {
        "symbol": "FTAG",
        "name": "First Trust Exchange-Traded Fund II First Trust Indxx Global Agriculture ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTBD": {
        "symbol": "FTBD",
        "name": "Fidelity Tactical Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTC": {
        "symbol": "FTC",
        "name": "First Trust Large Cap Growth AlphaDEX Fund (based on the Defined Large Cap Growth Index--symbol DEFI",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTCB": {
        "symbol": "FTCB",
        "name": "First Trust Core Investment Grade ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTCE": {
        "symbol": "FTCE",
        "name": "First Trust New Constructs Core Earnings Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTCS": {
        "symbol": "FTCS",
        "name": "First Trust Capital Strength ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTDS": {
        "symbol": "FTDS",
        "name": "First Trust Dividend Strength ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTEC": {
        "symbol": "FTEC",
        "name": "Fidelity MSCI Information Technology Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTF": {
        "symbol": "FTF",
        "name": "Franklin Limited Duration Income Trust Common Shrs. of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTGC": {
        "symbol": "FTGC",
        "name": "First Trust Global Tactical Commodity Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTGS": {
        "symbol": "FTGS",
        "name": "First Trust Growth Strength ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTHF": {
        "symbol": "FTHF",
        "name": "First Trust Emerging Markets Human Flourishing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTHI": {
        "symbol": "FTHI",
        "name": "First Trust Exchange-Traded Fund VI First Trust BuyWrite Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTHY": {
        "symbol": "FTHY",
        "name": "First Trust High Yield Opportunities 2027 Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTI": {
        "symbol": "FTI",
        "name": "TechnipFMC plc Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTIF": {
        "symbol": "FTIF",
        "name": "First Trust Bloomberg Inflation Sensitive Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTIIU": {
        "symbol": "FTIIU",
        "name": "FutureTech II Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTK": {
        "symbol": "FTK",
        "name": "Flotek Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTLS": {
        "symbol": "FTLS",
        "name": "First Trust Long/Short Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTQI": {
        "symbol": "FTQI",
        "name": "First Trust Exchange-Traded Fund VI First Trust Hedged BuyWrite Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTRB": {
        "symbol": "FTRB",
        "name": "Federated Hermes Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTRI": {
        "symbol": "FTRI",
        "name": "First Trust Exchange-Traded Fund II First Trust Indxx Global Natural Resources Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTS": {
        "symbol": "FTS",
        "name": "Fortis Inc. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTSD": {
        "symbol": "FTSD",
        "name": "Franklin Short Duration U.S. Government ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTSL": {
        "symbol": "FTSL",
        "name": "First Trust Senior Loan Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTSM": {
        "symbol": "FTSM",
        "name": "First Trust Enhanced Short Maturity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTV": {
        "symbol": "FTV",
        "name": "Fortive Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTWO": {
        "symbol": "FTWO",
        "name": "Strive Natural Resources and Security ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXG": {
        "symbol": "FTXG",
        "name": "First Trust Nasdaq Food & Beverage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXH": {
        "symbol": "FTXH",
        "name": "First Trust Nasdaq Pharmaceuticals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXL": {
        "symbol": "FTXL",
        "name": "First Trust Nasdaq Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXN": {
        "symbol": "FTXN",
        "name": "First Trust Nasdaq Oil & Gas ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXO": {
        "symbol": "FTXO",
        "name": "First Trust Nasdaq Bank ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FTXR": {
        "symbol": "FTXR",
        "name": "First Trust Nasdaq Transportation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUBO": {
        "symbol": "FUBO",
        "name": "fuboTV Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUL": {
        "symbol": "FUL",
        "name": "H.B. Fuller Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUMB": {
        "symbol": "FUMB",
        "name": "First Trust Ultra Short Duration Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUN": {
        "symbol": "FUN",
        "name": "Six Flags Entertainment Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUNL": {
        "symbol": "FUNL",
        "name": "CornerCap Fundametrics Large-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FURY": {
        "symbol": "FURY",
        "name": "Fury Gold Mines Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUSI": {
        "symbol": "FUSI",
        "name": "American Century Multisector Floating Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FUTY": {
        "symbol": "FUTY",
        "name": "Fidelity MSCI Utilities Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FV": {
        "symbol": "FV",
        "name": "First Trust Dorsey Wright Focus 5 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FVAL": {
        "symbol": "FVAL",
        "name": "Fidelity Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FVC": {
        "symbol": "FVC",
        "name": "First Trust Dorsey Wright Dynamic Focus 5 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FVD": {
        "symbol": "FVD",
        "name": "First Trust Value Line Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FVR": {
        "symbol": "FVR",
        "name": "FrontView REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FVRR": {
        "symbol": "FVRR",
        "name": "Fiverr International Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FWD": {
        "symbol": "FWD",
        "name": "AB Disruptors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXA": {
        "symbol": "FXA",
        "name": "Invesco CurrencyShares Australian Dollar Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXB": {
        "symbol": "FXB",
        "name": "Invesco CurrencyShares British Pound Sterling Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXC": {
        "symbol": "FXC",
        "name": "Invesco CurrencyShares Canadian Dollar Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXD": {
        "symbol": "FXD",
        "name": "First Trust Consumer Discretionary AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXE": {
        "symbol": "FXE",
        "name": "Invesco CurrencyShares Euro Currency Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXED": {
        "symbol": "FXED",
        "name": "Sound Enhanced Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXF": {
        "symbol": "FXF",
        "name": "Invesco CurrencyShares Swiss Franc Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXG": {
        "symbol": "FXG",
        "name": "First Trust Consumer Staples AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXH": {
        "symbol": "FXH",
        "name": "First Trust Health Care AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXI": {
        "symbol": "FXI",
        "name": "iShares China Large-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXL": {
        "symbol": "FXL",
        "name": "First Trust Technology AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXN": {
        "symbol": "FXN",
        "name": "First Trust Energy AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXO": {
        "symbol": "FXO",
        "name": "First Trust Financials AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXP": {
        "symbol": "FXP",
        "name": "ProShares UltraShort FTSE China 50 of ProShares Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXR": {
        "symbol": "FXR",
        "name": "First Trust Industrials/Producer Durables AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXU": {
        "symbol": "FXU",
        "name": "First Trust Utilities AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXY": {
        "symbol": "FXY",
        "name": "Invesco CurrencyShares Japanese Yen Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FXZ": {
        "symbol": "FXZ",
        "name": "First Trust Materials AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYC": {
        "symbol": "FYC",
        "name": "First Trust Small Cap Growth AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYEE": {
        "symbol": "FYEE",
        "name": "Fidelity Yield Enhanced Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYLD": {
        "symbol": "FYLD",
        "name": "Cambria Foreign Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYLG": {
        "symbol": "FYLG",
        "name": "Global X Financials Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYT": {
        "symbol": "FYT",
        "name": "First Trust Small Cap Value AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "FYX": {
        "symbol": "FYX",
        "name": "First Trust Small Cap Core AlphaDEX Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "G": {
        "symbol": "G",
        "name": "GENPACT LIMITED",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAA": {
        "symbol": "GAA",
        "name": "Cambria Global Asset Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAB": {
        "symbol": "GAB",
        "name": "The Gabelli Equity Trust Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAB PRG": {
        "symbol": "GAB PRG",
        "name": "The Gabelli Equity Trust Inc. Series G Cumulative Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAB PRH": {
        "symbol": "GAB PRH",
        "name": "THE GABELLI EQUITY TRUST INC. 5.00% Series H Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAB PRK": {
        "symbol": "GAB PRK",
        "name": "The Gabelli Equity Trust Inc. 5.00% Series K Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GABF": {
        "symbol": "GABF",
        "name": "Gabelli Financial Services Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAEM": {
        "symbol": "GAEM",
        "name": "Simplify Gamma Emerging Market Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAL": {
        "symbol": "GAL",
        "name": "SPDR SSgA Global Allocation ETF of SSgA Active Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAM": {
        "symbol": "GAM",
        "name": "General American Investors Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAM PRB": {
        "symbol": "GAM PRB",
        "name": "General American Investors Company, Inc  5.95% Cumul Pfd Stock, Ser B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAMR": {
        "symbol": "GAMR",
        "name": "Amplify Video Game Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAP": {
        "symbol": "GAP",
        "name": "The Gap, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAPR": {
        "symbol": "GAPR",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAQ": {
        "symbol": "GAQ",
        "name": "Generation Asia I Acquisition Limited Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GARP": {
        "symbol": "GARP",
        "name": "iShares MSCI USA Quality GARP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAST": {
        "symbol": "GAST",
        "name": "Gabelli Automation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GATEU": {
        "symbol": "GATEU",
        "name": "Marblegate Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GATEW": {
        "symbol": "GATEW",
        "name": "Marblegate Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GATO": {
        "symbol": "GATO",
        "name": "Gatos Silver, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GATX": {
        "symbol": "GATX",
        "name": "GATX Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAU": {
        "symbol": "GAU",
        "name": "Galiano Gold Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GAUG": {
        "symbol": "GAUG",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GB": {
        "symbol": "GB",
        "name": "Global Blue Group Holding AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GB WS": {
        "symbol": "GB WS",
        "name": "Global Blue Group Holding AG Warrants exercisable for one Ordinary Share of Global Blue Group Holdin",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBAB": {
        "symbol": "GBAB",
        "name": "Guggenheim Taxable Municipal Bond & Investment Grade Debt Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBCI": {
        "symbol": "GBCI",
        "name": "Glacier Bancorp Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBF": {
        "symbol": "GBF",
        "name": "iShares Government/Credit Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBIL": {
        "symbol": "GBIL",
        "name": "Goldman Sachs Access Treasury 0-1 Year ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBLD": {
        "symbol": "GBLD",
        "name": "Invesco MSCI Green Building ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBLI": {
        "symbol": "GBLI",
        "name": "Global Indemnity Group, LLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBR": {
        "symbol": "GBR",
        "name": "New Concept Energy Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBTC": {
        "symbol": "GBTC",
        "name": "Grayscale Bitcoin Trust (BTC)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBTG": {
        "symbol": "GBTG",
        "name": "Global Business Travel Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBUY": {
        "symbol": "GBUY",
        "name": "Goldman Sachs Future Consumer Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GBX": {
        "symbol": "GBX",
        "name": "The Greenbrier Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCAD": {
        "symbol": "GCAD",
        "name": "Gabelli Commercial Aerospace and Defense ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCAL": {
        "symbol": "GCAL",
        "name": "Goldman Sachs Dynamic California Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCC": {
        "symbol": "GCC",
        "name": "WisdomTree Enhanced Commodity Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCI": {
        "symbol": "GCI",
        "name": "Gannett Co., Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCLN": {
        "symbol": "GCLN",
        "name": "Goldman Sachs Bloomberg Clean Energy Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCO": {
        "symbol": "GCO",
        "name": "Genesco Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCOR": {
        "symbol": "GCOR",
        "name": "Goldman Sachs Access U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCOW": {
        "symbol": "GCOW",
        "name": "Pacer Global Cash Cows Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCTS": {
        "symbol": "GCTS",
        "name": "GCT Semiconductor Holding, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCTS WS": {
        "symbol": "GCTS WS",
        "name": "GCT Semiconductor Holding, Inc. Warrants, each whole warrant exercisable for one share of Common Sto",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GCV": {
        "symbol": "GCV",
        "name": "The Gabelli Convertible and Income Securities Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GD": {
        "symbol": "GD",
        "name": "General Dynamics Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDDY": {
        "symbol": "GDDY",
        "name": "GoDaddy Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDE": {
        "symbol": "GDE",
        "name": "WisdomTree Efficient Gold Plus Equity Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDEC": {
        "symbol": "GDEC",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDEVW": {
        "symbol": "GDEVW",
        "name": "GDEV Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDIV": {
        "symbol": "GDIV",
        "name": "Harbor Dividend Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDL": {
        "symbol": "GDL",
        "name": "The GDL Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDL PRC": {
        "symbol": "GDL PRC",
        "name": "GDL Fund, The Series C Cumulative Puttable and Callable Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDMA": {
        "symbol": "GDMA",
        "name": "Gadsden Dynamic Multi-Asset ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDMN": {
        "symbol": "GDMN",
        "name": "WisdomTree Efficient Gold Plus Gold Miners Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDO": {
        "symbol": "GDO",
        "name": "Western Asset Global Corporate Defined Opportunity Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDOC": {
        "symbol": "GDOC",
        "name": "Goldman Sachs Future Health Care Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDOT": {
        "symbol": "GDOT",
        "name": "Green Dot Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDSTU": {
        "symbol": "GDSTU",
        "name": "Goldenstone Acquisition Limited Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDSTW": {
        "symbol": "GDSTW",
        "name": "Goldenstone Acquisition Limited Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDV": {
        "symbol": "GDV",
        "name": "The Gabelli Dividend & Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDV PRH": {
        "symbol": "GDV PRH",
        "name": "The Gabelli Dividend & Income Trust 5.375% Series H Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDV PRK": {
        "symbol": "GDV PRK",
        "name": "The Gabelli Dividend & Income Trust 4.250% Series K Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDX": {
        "symbol": "GDX",
        "name": "VanEck Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDXD": {
        "symbol": "GDXD",
        "name": "MicroSectors Gold Miners -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDXJ": {
        "symbol": "GDXJ",
        "name": "VanEck Junior Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDXU": {
        "symbol": "GDXU",
        "name": "MicroSectors Gold Miners 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GDXY": {
        "symbol": "GDXY",
        "name": "YieldMax Gold Miners Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GE": {
        "symbol": "GE",
        "name": "GE Aerospace",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEF": {
        "symbol": "GEF",
        "name": "Greif, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEF B": {
        "symbol": "GEF B",
        "name": "Greif, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEL": {
        "symbol": "GEL",
        "name": "Genesis Energy, L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEM": {
        "symbol": "GEM",
        "name": "Goldman Sachs ActiveBeta Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEMD": {
        "symbol": "GEMD",
        "name": "Goldman Sachs Access Emerging Markets USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GENC": {
        "symbol": "GENC",
        "name": "Gencor Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GENI": {
        "symbol": "GENI",
        "name": "Genius Sports Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GENM": {
        "symbol": "GENM",
        "name": "Genter Capital Municipal Quality Intermediate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GENT": {
        "symbol": "GENT",
        "name": "Genter Capital Taxable Quality Intermediate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEO": {
        "symbol": "GEO",
        "name": "The GEO Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GES": {
        "symbol": "GES",
        "name": "Guess?, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GETY": {
        "symbol": "GETY",
        "name": "Getty Images Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GEV": {
        "symbol": "GEV",
        "name": "GE Vernova Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GF": {
        "symbol": "GF",
        "name": "NEW GERMANY FUND Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFEB": {
        "symbol": "GFEB",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFF": {
        "symbol": "GFF",
        "name": "Griffon Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFGF": {
        "symbol": "GFGF",
        "name": "Guru Favorite Stocks ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFI": {
        "symbol": "GFI",
        "name": "Gold Fields Ltd ADR",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFL": {
        "symbol": "GFL",
        "name": "GFL Environmental Inc. Subordinate Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFOF": {
        "symbol": "GFOF",
        "name": "Grayscale Future of Finance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GFR": {
        "symbol": "GFR",
        "name": "Greenfire Resources Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGB": {
        "symbol": "GGB",
        "name": "Gerdau S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGG": {
        "symbol": "GGG",
        "name": "Graco Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGLL": {
        "symbol": "GGLL",
        "name": "Direxion Shares ETF Trust Direxion Daily GOOGL Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGLS": {
        "symbol": "GGLS",
        "name": "Direxion Daily GOOGL Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGM": {
        "symbol": "GGM",
        "name": "GGM Macro Alignment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGME": {
        "symbol": "GGME",
        "name": "Invesco Next Gen Media and Gaming ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGN": {
        "symbol": "GGN",
        "name": "GAMCO Global Gold, Natural Resources & Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGN PRB": {
        "symbol": "GGN PRB",
        "name": "GAMCO Global Gold Natural Resources & Income Trust 5.00% Series B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGRW": {
        "symbol": "GGRW",
        "name": "Gabelli Growth Innovators ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGT": {
        "symbol": "GGT",
        "name": "THE GABELLI MULTIMEDIA TRUST INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGT PRE": {
        "symbol": "GGT PRE",
        "name": "The Gabelli Multimedia Trust Inc. 5.125 Percent Series E Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGT PRG": {
        "symbol": "GGT PRG",
        "name": "The Gabelli Multimedia Trust Inc. 5.125% Series G Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGUS": {
        "symbol": "GGUS",
        "name": "Goldman Sachs MarketBeta Russell 1000 Growth Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GGZ": {
        "symbol": "GGZ",
        "name": "The Gabelli Global Small and Mid Cap Value Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHC": {
        "symbol": "GHC",
        "name": "GRAHAM HOLDINGS COMPANY",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHG": {
        "symbol": "GHG",
        "name": "GreenTree Hospitality Group Ltd. American depositary shares, each representing one Class A ordinary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHI": {
        "symbol": "GHI",
        "name": "Greystone Housing Impact Investors LP Beneficial Unit Certificates representing assignments of limit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHIXU": {
        "symbol": "GHIXU",
        "name": "Gores Holdings IX, Inc. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHLD": {
        "symbol": "GHLD",
        "name": "Guild Holdings Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHM": {
        "symbol": "GHM",
        "name": "Graham Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHMS": {
        "symbol": "GHMS",
        "name": "Goose Hollow Multi-Strategy Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHTA": {
        "symbol": "GHTA",
        "name": "Goose Hollow Tactical Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHY": {
        "symbol": "GHY",
        "name": "PGIM Global High Yield Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHYB": {
        "symbol": "GHYB",
        "name": "Goldman Sachs Access High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GHYG": {
        "symbol": "GHYG",
        "name": "iShares US & Intl High Yield Corp Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIAX": {
        "symbol": "GIAX",
        "name": "Nicholas Global Equity and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIB": {
        "symbol": "GIB",
        "name": "CGI Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIC": {
        "symbol": "GIC",
        "name": "Global Industrial Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIGB": {
        "symbol": "GIGB",
        "name": "Goldman Sachs Access Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GII": {
        "symbol": "GII",
        "name": "SPDR S&P Global Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIL": {
        "symbol": "GIL",
        "name": "Gildan Activewear Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GINN": {
        "symbol": "GINN",
        "name": "Goldman Sachs Innovate Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GINX": {
        "symbol": "GINX",
        "name": "SGI Enhanced Global Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIPRW": {
        "symbol": "GIPRW",
        "name": "Generation Income Properties Inc Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GIS": {
        "symbol": "GIS",
        "name": "General Mills, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJAN": {
        "symbol": "GJAN",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJH": {
        "symbol": "GJH",
        "name": "Synthetic Fixed-Income Sec STRATS 2004-06, 6.375% US Cellular Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJO": {
        "symbol": "GJO",
        "name": "Synthetic Fixed-Income Securities, Inc. 4.65% Floating Rate STRATS Certificates, Series 2005-4",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJP": {
        "symbol": "GJP",
        "name": "Synthetic Fixed Income Securities on the behalf of STRATS for Dominion Resources  Series 2005-6",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJR": {
        "symbol": "GJR",
        "name": "Synthetic Fixed Income Securities, Inc. Floating Rate Structured Repackaged Asset-Backed Trust Secur",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJS": {
        "symbol": "GJS",
        "name": "Synthetic Fixed-Income Securities STRATS 2006-2 Goldman Sachs Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJT": {
        "symbol": "GJT",
        "name": "Synthetic Fixed-Income Securities STRATS 2006-3 AllState Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJUL": {
        "symbol": "GJUL",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GJUN": {
        "symbol": "GJUN",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GK": {
        "symbol": "GK",
        "name": "AdvisorShares Gerber Kawasaki ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GKOS": {
        "symbol": "GKOS",
        "name": "Glaukos Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GL": {
        "symbol": "GL",
        "name": "Globe Life Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GL PRD": {
        "symbol": "GL PRD",
        "name": "Globe Life Inc. 4.25% Junior Subordinated Debentures due 2061",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLACR": {
        "symbol": "GLACR",
        "name": "Global Lights Acquisition Corp Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLACU": {
        "symbol": "GLACU",
        "name": "Global Lights Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLBL": {
        "symbol": "GLBL",
        "name": "Pacer MSCI World Industry Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLD": {
        "symbol": "GLD",
        "name": "SPDR Gold Trust, SPDR Gold Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLDG": {
        "symbol": "GLDG",
        "name": "GoldMining Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLDI": {
        "symbol": "GLDI",
        "name": "UBS AG ETRACS Gold Shares Covered Call ETNs due February 2, 2033",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLDM": {
        "symbol": "GLDM",
        "name": "SPDR Gold MiniShares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLIN": {
        "symbol": "GLIN",
        "name": "VanEck India Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLL": {
        "symbol": "GLL",
        "name": "ProShares UltraShort Gold",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLLIR": {
        "symbol": "GLLIR",
        "name": "Globalink Investment Inc. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLLIU": {
        "symbol": "GLLIU",
        "name": "Globalink Investment Inc. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLLIW": {
        "symbol": "GLLIW",
        "name": "Globalink Investment Inc. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLO": {
        "symbol": "GLO",
        "name": "Clough Global Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOB": {
        "symbol": "GLOB",
        "name": "GLOBANT S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOF": {
        "symbol": "GLOF",
        "name": "iShares Global Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOP PRA": {
        "symbol": "GLOP PRA",
        "name": "GasLog Partners LP 8.625% Series A Cumulative Redeemable Perpetual Fixed to Floating Rate Preference",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOP PRB": {
        "symbol": "GLOP PRB",
        "name": "GasLog Partners LP 8.200% Series B Cumulative Redeemable Perpetual Fixed to Floating Rate Preference",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOP PRC": {
        "symbol": "GLOP PRC",
        "name": "GasLog Partners LP 8.500% Series C Cumulative Redeemable Perpetual Fixed to Floating Rate Preference",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOV": {
        "symbol": "GLOV",
        "name": "Goldman Sachs ActiveBeta World Low Vol Plus Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLOW": {
        "symbol": "GLOW",
        "name": "VictoryShares WestEnd Global Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLP": {
        "symbol": "GLP",
        "name": "Global Partners LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLP PRB": {
        "symbol": "GLP PRB",
        "name": "Global Partners LP 9.50% Series B Fixed Rate Cumulative Redeemable Perpetual Preferred Units represe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLQ": {
        "symbol": "GLQ",
        "name": "Clough Global Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLRY": {
        "symbol": "GLRY",
        "name": "Inspire Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLSTW": {
        "symbol": "GLSTW",
        "name": "Global Star Acquisition, Inc. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLT": {
        "symbol": "GLT",
        "name": "Glatfelter Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLTR": {
        "symbol": "GLTR",
        "name": "abrdn Physical Precious Metals Basket Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLU": {
        "symbol": "GLU",
        "name": "The Gabelli Utility & Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLU PRA": {
        "symbol": "GLU PRA",
        "name": "The Gabelli Global Utility & Income Trust Series A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLU PRB": {
        "symbol": "GLU PRB",
        "name": "The Gabelli Global Utility & Income Trust Series B Cumulative Puttable and Callable Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLV": {
        "symbol": "GLV",
        "name": "Clough Global Dividend and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GLW": {
        "symbol": "GLW",
        "name": "Corning Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GM": {
        "symbol": "GM",
        "name": "General Motors Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMAR": {
        "symbol": "GMAR",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMAY": {
        "symbol": "GMAY",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GME": {
        "symbol": "GME",
        "name": "GameStop Corp. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMED": {
        "symbol": "GMED",
        "name": "GLOBUS MEDICAL INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMET": {
        "symbol": "GMET",
        "name": "VanEck Green Metals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMF": {
        "symbol": "GMF",
        "name": "SPDR S&P Emerging Asia Pacific ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMMA": {
        "symbol": "GMMA",
        "name": "GammaRoad Market Navigation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMNY": {
        "symbol": "GMNY",
        "name": "Goldman Sachs Dynamic New York Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMOM": {
        "symbol": "GMOM",
        "name": "Cambria Global Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMRE": {
        "symbol": "GMRE",
        "name": "Global Medical REIT Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMRE PRA": {
        "symbol": "GMRE PRA",
        "name": "Global Medical REIT Inc. Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMS": {
        "symbol": "GMS",
        "name": "GMS Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMUB": {
        "symbol": "GMUB",
        "name": "Goldman Sachs Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GMUN": {
        "symbol": "GMUN",
        "name": "Goldman Sachs Community Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNE": {
        "symbol": "GNE",
        "name": "GENIE ENERGY LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNK": {
        "symbol": "GNK",
        "name": "GENCO SHIPPING & TRADING LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNL": {
        "symbol": "GNL",
        "name": "Global Net Lease, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNL PRA": {
        "symbol": "GNL PRA",
        "name": "Global Net Lease, Inc. 7.25% Series A Cumulative Redeemable Preferred Stock, $0.01 par value per sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNL PRB": {
        "symbol": "GNL PRB",
        "name": "Global Net Lease, Inc. 6.875% Series B Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNL PRD": {
        "symbol": "GNL PRD",
        "name": "Global Net Lease, Inc. 7.50% Series D Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNL PRE": {
        "symbol": "GNL PRE",
        "name": "Global Net Lease, Inc. 7.375% Series E Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNMA": {
        "symbol": "GNMA",
        "name": "iShares GNMA Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNOM": {
        "symbol": "GNOM",
        "name": "Global X Genomics & Biotechnology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNOV": {
        "symbol": "GNOV",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNR": {
        "symbol": "GNR",
        "name": "SPDR S&P Global Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNRC": {
        "symbol": "GNRC",
        "name": "GENERAC HOLDINGS INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNS": {
        "symbol": "GNS",
        "name": "Genius Group Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNT": {
        "symbol": "GNT",
        "name": "GAMCO Natural Resources, Gold & Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNT PRA": {
        "symbol": "GNT PRA",
        "name": "GAMCO Natural Resources, Gold & Income Trust 5.2 Percent Series A Cumulative Preferred Shares (Liqui",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNTY": {
        "symbol": "GNTY",
        "name": "Guaranty Bancshares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GNW": {
        "symbol": "GNW",
        "name": "Genworth Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOAU": {
        "symbol": "GOAU",
        "name": "US Global GO Gold and Precious Metal Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOCT": {
        "symbol": "GOCT",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GODNR": {
        "symbol": "GODNR",
        "name": "Golden Star Acquisition Corporation Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GODNU": {
        "symbol": "GODNU",
        "name": "Golden Star Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOEX": {
        "symbol": "GOEX",
        "name": "Global X Gold Explorers ETF (NEW)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOF": {
        "symbol": "GOF",
        "name": "Guggenheim Strategic Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOLD": {
        "symbol": "GOLD",
        "name": "Barrick Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOLF": {
        "symbol": "GOLF",
        "name": "Acushnet Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOLY": {
        "symbol": "GOLY",
        "name": "Strategy Shares Gold-Hedged Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOOP": {
        "symbol": "GOOP",
        "name": "Kurv Yield Premium Strategy Google (GOOGL) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOOS": {
        "symbol": "GOOS",
        "name": "Canada Goose Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOOX": {
        "symbol": "GOOX",
        "name": "T-Rex 2X Long Alphabet Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOOY": {
        "symbol": "GOOY",
        "name": "YieldMax GOOGL Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GORO": {
        "symbol": "GORO",
        "name": "Gold Resource Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOTU": {
        "symbol": "GOTU",
        "name": "Gaotu Techedu Inc. American Depositary Shares, three of which representing two Class A Ordinary Shar",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOVI": {
        "symbol": "GOVI",
        "name": "Invesco Exchange-Traded Fund Trust II Invesco Equal Weight 0-30 Year Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOVT": {
        "symbol": "GOVT",
        "name": "iShares U.S. Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GOVZ": {
        "symbol": "GOVZ",
        "name": "iShares 25+ Year Treasury STRIPS Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPATU": {
        "symbol": "GPATU",
        "name": "GP-Act III Acquisition Corp. Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPC": {
        "symbol": "GPC",
        "name": "Genuine Parts Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPI": {
        "symbol": "GPI",
        "name": "Group 1 Automotive, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPIQ": {
        "symbol": "GPIQ",
        "name": "Goldman Sachs Nasdaq 100 Core Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPIX": {
        "symbol": "GPIX",
        "name": "Goldman Sachs S&P 500 Core Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPJA": {
        "symbol": "GPJA",
        "name": "Georgia Power Company Series 2017A 5.00 Percent Junior Subordinated Notes due October 1, 2077",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPK": {
        "symbol": "GPK",
        "name": "Graphic Packaging Holding Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPMT": {
        "symbol": "GPMT",
        "name": "Granite Point Mortgage Trust Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPMT PRA": {
        "symbol": "GPMT PRA",
        "name": "Granite Point Mortgage Trust Inc. 7.00% Series A Fixed-to-Floating Rate Cumulative Redeemable Prefer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPN": {
        "symbol": "GPN",
        "name": "Global Payments, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPOR": {
        "symbol": "GPOR",
        "name": "Gulfport Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPOW": {
        "symbol": "GPOW",
        "name": "Goldman Sachs North American Pipelines & Power Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPRF": {
        "symbol": "GPRF",
        "name": "Goldman Sachs Access U.S. Preferred Stock and Hybrid Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPRK": {
        "symbol": "GPRK",
        "name": "GEOPARK LIMITED",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPUS": {
        "symbol": "GPUS",
        "name": "Hyperscale Data, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GPUS PRD": {
        "symbol": "GPUS PRD",
        "name": "Hyperscale Data, Inc. 13.00% Series D Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GQI": {
        "symbol": "GQI",
        "name": "Natixis Gateway Quality Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GQQQ": {
        "symbol": "GQQQ",
        "name": "Astoria US Quality Growth Kings ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GQRE": {
        "symbol": "GQRE",
        "name": "FlexShares Global Quality Real Estate Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRAF": {
        "symbol": "GRAF",
        "name": "Graf Global Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRAF U": {
        "symbol": "GRAF U",
        "name": "Graf Global Corp. Units, each consisting of one Class A ordinary share and one-half of one redeemabl",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRAF WS": {
        "symbol": "GRAF WS",
        "name": "Graf Global Corp. Warrants, each whole warrant exercisable for one Class A ordinary share at an exer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRBK": {
        "symbol": "GRBK",
        "name": "Green Brick Partners, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRBK PRA": {
        "symbol": "GRBK PRA",
        "name": "Green Brick Partners, Inc. Depositary Shares (each representing a 1/1000th fractional interest in a",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRC": {
        "symbol": "GRC",
        "name": "The Gorman-Rupp Company Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRDN": {
        "symbol": "GRDN",
        "name": "Guardian Pharmacy Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GREI": {
        "symbol": "GREI",
        "name": "Goldman Sachs Future Real Estate and Infrastructure Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GREK": {
        "symbol": "GREK",
        "name": "Global X MSCI Greece ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRF": {
        "symbol": "GRF",
        "name": "Eagle Capital Growth Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRFX": {
        "symbol": "GRFX",
        "name": "Graphex Group Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRID": {
        "symbol": "GRID",
        "name": "First Trust NASDAQ Clean Edge Smart Grid Infrastructure Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRMN": {
        "symbol": "GRMN",
        "name": "Garmin Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRN": {
        "symbol": "GRN",
        "name": "iPath Series B Carbon Exchange-Traded Notes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRNB": {
        "symbol": "GRNB",
        "name": "VanEck Green Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRND": {
        "symbol": "GRND",
        "name": "Grindr Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRND WS": {
        "symbol": "GRND WS",
        "name": "Grindr Inc. Warrants, each exercisable for one share of Common Stock at an exercise price of $11.50",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRNT": {
        "symbol": "GRNT",
        "name": "Granite Ridge Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GROV": {
        "symbol": "GROV",
        "name": "Grove Collaborative Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GROY": {
        "symbol": "GROY",
        "name": "Gold Royalty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GROY WS": {
        "symbol": "GROY WS",
        "name": "Gold Royalty Corp. Warrants, each exercisable for one common share at an exercise price of $2.25 per",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRP U": {
        "symbol": "GRP U",
        "name": "Granite Real Estate Investment Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRPM": {
        "symbol": "GRPM",
        "name": "Invesco S&P MidCap 400 GARP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRPZ": {
        "symbol": "GRPZ",
        "name": "Invesco S&P SmallCap 600 GARP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRW": {
        "symbol": "GRW",
        "name": "TCW Compounders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GRX": {
        "symbol": "GRX",
        "name": "The Gabelli Healthcare & Wellness Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GS": {
        "symbol": "GS",
        "name": "Goldman Sachs Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GS PRA": {
        "symbol": "GS PRA",
        "name": "Goldman Sachs Group, Inc. (The) Depositary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GS PRC": {
        "symbol": "GS PRC",
        "name": "Goldman Sachs Group Dep. Shares (1/1000 Int. of Floating Rate Non-Cum. Prfrd. Stk., Ser. C)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GS PRD": {
        "symbol": "GS PRD",
        "name": "Goldman Sachs Group, Inc. (Each representing 1/1000th Interest in a Share of Floating Rate Non-cumul",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSAT": {
        "symbol": "GSAT",
        "name": "Globalstar, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSBD": {
        "symbol": "GSBD",
        "name": "Goldman Sachs BDC, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSC": {
        "symbol": "GSC",
        "name": "Goldman Sachs Small Cap Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSEE": {
        "symbol": "GSEE",
        "name": "Goldman Sachs MarketBeta Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSEP": {
        "symbol": "GSEP",
        "name": "FT Vest U.S. Equity Moderate Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSEU": {
        "symbol": "GSEU",
        "name": "Goldman Sachs ActiveBeta Europe Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSEW": {
        "symbol": "GSEW",
        "name": "Goldman Sachs Equal Weight U.S. Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSFP": {
        "symbol": "GSFP",
        "name": "Goldman Sachs Future Planet Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSG": {
        "symbol": "GSG",
        "name": "iShares S&P  GSCI Commodity-Indexed Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSIB": {
        "symbol": "GSIB",
        "name": "Themes Global Systemically Important Banks ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSID": {
        "symbol": "GSID",
        "name": "Goldman Sachs MarketBeta International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSIE": {
        "symbol": "GSIE",
        "name": "Goldman Sachs ActiveBeta International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSIG": {
        "symbol": "GSIG",
        "name": "Goldman Sachs Access Investment Grade Corporate 1-5 Year Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSJY": {
        "symbol": "GSJY",
        "name": "Goldman Sachs ActiveBeta Japan Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSK": {
        "symbol": "GSK",
        "name": "GSK plc American Depositary Shares (Each representing two Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSL": {
        "symbol": "GSL",
        "name": "Global Ship Lease, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSL PRB": {
        "symbol": "GSL PRB",
        "name": "GLOBAL SHIP LEASE, INC. 8.75%  SER B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSLC": {
        "symbol": "GSLC",
        "name": "Goldman Sachs ActiveBeta U.S. Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSPY": {
        "symbol": "GSPY",
        "name": "Gotham Enhanced 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSSC": {
        "symbol": "GSSC",
        "name": "GS ActiveBeta U.S. Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSST": {
        "symbol": "GSST",
        "name": "Goldman Sachs Access Ultra Short Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSUS": {
        "symbol": "GSUS",
        "name": "Goldman Sachs MarketBeta U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GSY": {
        "symbol": "GSY",
        "name": "Invesco Ultra Short Duration ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTACU": {
        "symbol": "GTACU",
        "name": "Global Technology Acquisition Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTE": {
        "symbol": "GTE",
        "name": "Gran Tierra Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTEK": {
        "symbol": "GTEK",
        "name": "Goldman Sachs Future Tech Leaders Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTES": {
        "symbol": "GTES",
        "name": "Gates Industrial Corporation plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTIP": {
        "symbol": "GTIP",
        "name": "Goldman Sachs Access Inflation Protected USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTLS": {
        "symbol": "GTLS",
        "name": "Chart Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTLS PRB": {
        "symbol": "GTLS PRB",
        "name": "Chart Industries, Inc. Depositary Shares, each Representing a 1/20th Interest in a Share of 6.75% Se",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTN": {
        "symbol": "GTN",
        "name": "Gray Television, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTN A": {
        "symbol": "GTN A",
        "name": "Gray Television, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTO": {
        "symbol": "GTO",
        "name": "Invesco Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTR": {
        "symbol": "GTR",
        "name": "WisdomTree Target Range Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GTY": {
        "symbol": "GTY",
        "name": "Getty Realty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUG": {
        "symbol": "GUG",
        "name": "Guggenheim Active Allocation Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUMI": {
        "symbol": "GUMI",
        "name": "Goldman Sachs Ultra Short Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUNR": {
        "symbol": "GUNR",
        "name": "FlexShares Global Upstream Natural Resources Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUNZ": {
        "symbol": "GUNZ",
        "name": "Tuttle Capital Self Defense Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GURU": {
        "symbol": "GURU",
        "name": "Global X Guru Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUSA": {
        "symbol": "GUSA",
        "name": "Goldman Sachs MarketBeta U.S. 1000 Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUSH": {
        "symbol": "GUSH",
        "name": "Direxion Daily S&P Oil & Gas Exp. & Prod. Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUT": {
        "symbol": "GUT",
        "name": "GABELLI UTILITY TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUT PRC": {
        "symbol": "GUT PRC",
        "name": "Gabelli Utility Trust   5.375% Series C Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GUT RT": {
        "symbol": "GUT RT",
        "name": "The Gabelli Utility Trust Rights (expiring October 21, 2024)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVA": {
        "symbol": "GVA",
        "name": "Granite Construction Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVAL": {
        "symbol": "GVAL",
        "name": "Cambria Global Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVI": {
        "symbol": "GVI",
        "name": "iShares Intermediate Government/Credit Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVIP": {
        "symbol": "GVIP",
        "name": "Goldman Sachs Hedge Industry VIP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVLU": {
        "symbol": "GVLU",
        "name": "Gotham 1000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GVUS": {
        "symbol": "GVUS",
        "name": "Goldman Sachs MarketBeta Russell 1000 Value Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GWH": {
        "symbol": "GWH",
        "name": "ESS Tech, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GWH WS": {
        "symbol": "GWH WS",
        "name": "ESS Tech, Inc. Warrant exercisable for one-fifteenth of a share of Common Stock at an exercise price",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GWRE": {
        "symbol": "GWRE",
        "name": "GUIDEWIRE SOFTWARE, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GWW": {
        "symbol": "GWW",
        "name": "W.W. Grainger, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GWX": {
        "symbol": "GWX",
        "name": "SPDR S&P International Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GXC": {
        "symbol": "GXC",
        "name": "SPDR S&P China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GXG": {
        "symbol": "GXG",
        "name": "Global X MSCI Colombia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GXO": {
        "symbol": "GXO",
        "name": "GXO Logistics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GXTG": {
        "symbol": "GXTG",
        "name": "Global X Thematic Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GXUS": {
        "symbol": "GXUS",
        "name": "Goldman Sachs MarketBeta Total International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "GYLD": {
        "symbol": "GYLD",
        "name": "Arrow Dow Jones Global Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "H": {
        "symbol": "H",
        "name": "Hyatt Hotels Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HACK": {
        "symbol": "HACK",
        "name": "Amplify Cybersecurity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAE": {
        "symbol": "HAE",
        "name": "Haemonetics Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAFN": {
        "symbol": "HAFN",
        "name": "Hafnia Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAIL": {
        "symbol": "HAIL",
        "name": "SPDR S&P Kensho Smart Mobility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAL": {
        "symbol": "HAL",
        "name": "Halliburton Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAP": {
        "symbol": "HAP",
        "name": "VanEck Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAPI": {
        "symbol": "HAPI",
        "name": "Harbor Human Capital Factor US Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAPR": {
        "symbol": "HAPR",
        "name": "Innovator Premium Income 9 Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAPS": {
        "symbol": "HAPS",
        "name": "Harbor Human Capital Factor US Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAPY": {
        "symbol": "HAPY",
        "name": "Harbor Human Capital Factor Unconstrained ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HARD": {
        "symbol": "HARD",
        "name": "Simplify Commodities Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HART": {
        "symbol": "HART",
        "name": "NYLI Healthy Hearts ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HASI": {
        "symbol": "HASI",
        "name": "HA Sustainable Infrastructure Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAUS": {
        "symbol": "HAUS",
        "name": "Residential REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAUZ": {
        "symbol": "HAUZ",
        "name": "Xtrackers International Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAWX": {
        "symbol": "HAWX",
        "name": "iShares Currency Hedged MSCI ACWI ex U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HAYW": {
        "symbol": "HAYW",
        "name": "Hayward Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HBB": {
        "symbol": "HBB",
        "name": "Hamilton Beach Brands Holding Company Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HBI": {
        "symbol": "HBI",
        "name": "Hanesbrands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HBM": {
        "symbol": "HBM",
        "name": "Hudbay Minerals Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCA": {
        "symbol": "HCA",
        "name": "HCA Healthcare, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCC": {
        "symbol": "HCC",
        "name": "Warrior Met Coal, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCI": {
        "symbol": "HCI",
        "name": "HCI Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCMT": {
        "symbol": "HCMT",
        "name": "Direxion HCM Tactical Enhanced US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCOM": {
        "symbol": "HCOM",
        "name": "Hartford Schroders Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCOW": {
        "symbol": "HCOW",
        "name": "Amplify Cash Flow High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCRB": {
        "symbol": "HCRB",
        "name": "Hartford Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCWC": {
        "symbol": "HCWC",
        "name": "Healthy Choice Wellness Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HCXY": {
        "symbol": "HCXY",
        "name": "Hercules Capital, Inc. 6.25% Notes due 2033",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HD": {
        "symbol": "HD",
        "name": "Home Depot, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDB": {
        "symbol": "HDB",
        "name": "HDFC Bank Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDEF": {
        "symbol": "HDEF",
        "name": "Xtrackers MSCI EAFE High Dividend Yield Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDG": {
        "symbol": "HDG",
        "name": "ProShares Hedge Replication ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDGE": {
        "symbol": "HDGE",
        "name": "AdvisorShares Ranger Equity Bear ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDLB": {
        "symbol": "HDLB",
        "name": "ETRACS Monthly Pay 2xLeveraged US High Dividend Low Volatility ETN Series B due October 21, 2049",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDMV": {
        "symbol": "HDMV",
        "name": "First Trust Horizon Managed Volatility Developed International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDRO": {
        "symbol": "HDRO",
        "name": "Defiance Next Gen H2 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDUS": {
        "symbol": "HDUS",
        "name": "Hartford Disciplined US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HDV": {
        "symbol": "HDV",
        "name": "iShares Core High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HE": {
        "symbol": "HE",
        "name": "Hawaiian Electric Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEAT": {
        "symbol": "HEAT",
        "name": "Touchstone Climate Transition ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HECO": {
        "symbol": "HECO",
        "name": "SPDR Galaxy Hedged Digital Asset Ecosystem ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEDJ": {
        "symbol": "HEDJ",
        "name": "WisdomTree Europe Hedged Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEEM": {
        "symbol": "HEEM",
        "name": "iShares Currency Hedged MSCI Emerging Markets",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEFA": {
        "symbol": "HEFA",
        "name": "iShares Currency Hedged MSCI EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEGD": {
        "symbol": "HEGD",
        "name": "Swan Hedged Equity US Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEI": {
        "symbol": "HEI",
        "name": "HEICO Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEI A": {
        "symbol": "HEI A",
        "name": "HEICO CORP CL A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEJD": {
        "symbol": "HEJD",
        "name": "VictoryShares Hedged Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HELO": {
        "symbol": "HELO",
        "name": "JPMorgan Hedged Equity Laddered Overlay ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HELX": {
        "symbol": "HELX",
        "name": "Franklin Genomic Advancements ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEQ": {
        "symbol": "HEQ",
        "name": "JOHN HANCOCK HEDGED EQUITY & INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEQT": {
        "symbol": "HEQT",
        "name": "Simplify Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HERD": {
        "symbol": "HERD",
        "name": "Pacer Cash Cows Fund of Funds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HERO": {
        "symbol": "HERO",
        "name": "Global X Video Games & Esports ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HES": {
        "symbol": "HES",
        "name": "Hess Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HESM": {
        "symbol": "HESM",
        "name": "Hess Midstream LP Class A Share representing a limited partner Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEWJ": {
        "symbol": "HEWJ",
        "name": "iShares Currency Hedged MSCI Japan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HEZU": {
        "symbol": "HEZU",
        "name": "iShares Currency Hedged MSCI Eurozone ETF of ISHARES TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HF": {
        "symbol": "HF",
        "name": "DGA Core Plus Absolute Return ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HFGO": {
        "symbol": "HFGO",
        "name": "Hartford Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HFND": {
        "symbol": "HFND",
        "name": "Unlimited HFND Multi-Strategy Return Tracker ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HFRO": {
        "symbol": "HFRO",
        "name": "Highland Opportunities and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HFRO PRA": {
        "symbol": "HFRO PRA",
        "name": "Highland Opportunities and Income Fund 5.375% Series A Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HFXI": {
        "symbol": "HFXI",
        "name": "NYLI FTSE International Equity Currency Neutral ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HG": {
        "symbol": "HG",
        "name": "Hamilton Insurance Group, Ltd. Class B Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HGER": {
        "symbol": "HGER",
        "name": "Harbor Commodity All-Weather Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HGLB": {
        "symbol": "HGLB",
        "name": "Highland Global Allocation Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HGTY": {
        "symbol": "HGTY",
        "name": "Hagerty, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HGV": {
        "symbol": "HGV",
        "name": "Hilton Grand Vacations Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HHH": {
        "symbol": "HHH",
        "name": "Howard Hughes Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HI": {
        "symbol": "HI",
        "name": "Hillenbrand, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIBL": {
        "symbol": "HIBL",
        "name": "Direxion Daily S&P 500 High Beta Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIBS": {
        "symbol": "HIBS",
        "name": "Direxion Daily S&P 500 High Beta Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIDE": {
        "symbol": "HIDE",
        "name": "Alpha Architect High Inflation and Deflation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIDV": {
        "symbol": "HIDV",
        "name": "AB US High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIE": {
        "symbol": "HIE",
        "name": "Miller/Howard High Income Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIG": {
        "symbol": "HIG",
        "name": "The Hartford Financial Services Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIG PRG": {
        "symbol": "HIG PRG",
        "name": "The Hartford Financial Services Group, Inc. Depositary Shares each representing a 1/1,000th interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIGH": {
        "symbol": "HIGH",
        "name": "Simplify Enhanced Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HII": {
        "symbol": "HII",
        "name": "Huntington Ingalls Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIMS": {
        "symbol": "HIMS",
        "name": "Hims & Hers Health, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIO": {
        "symbol": "HIO",
        "name": "Western Asset High Income Opportunity Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIPO": {
        "symbol": "HIPO",
        "name": "Hippo Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIPS": {
        "symbol": "HIPS",
        "name": "GraniteShares HIPS US High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HISF": {
        "symbol": "HISF",
        "name": "First Trust Exchange-Traded Fund IV First Trust High Income Strategic Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIW": {
        "symbol": "HIW",
        "name": "Highwoods Properties Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIX": {
        "symbol": "HIX",
        "name": "Western Asset High Income Fund II, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HIYS": {
        "symbol": "HIYS",
        "name": "Invesco High Yield Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HJAN": {
        "symbol": "HJAN",
        "name": "Innovator Premium Income 9 Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HJUL": {
        "symbol": "HJUL",
        "name": "Innovator Premium Income 9 Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HKD": {
        "symbol": "HKD",
        "name": "AMTD Digital Inc. American Depositary Shares (every five of which represent two Class A Ordinary Sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HKND": {
        "symbol": "HKND",
        "name": "Humankind US Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HL": {
        "symbol": "HL",
        "name": "Hecla Mining Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HL PRB": {
        "symbol": "HL PRB",
        "name": "Hecla Mining Company Series B Cumulative Convertible Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLAL": {
        "symbol": "HLAL",
        "name": "Wahed FTSE USA Shariah ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLF": {
        "symbol": "HLF",
        "name": "Herbalife Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLGE": {
        "symbol": "HLGE",
        "name": "Hartford Longevity Economy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLI": {
        "symbol": "HLI",
        "name": "Houlihan Lokey, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLIO": {
        "symbol": "HLIO",
        "name": "Helios Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLLY": {
        "symbol": "HLLY",
        "name": "Holley Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLLY WS": {
        "symbol": "HLLY WS",
        "name": "Holley Inc. Redeemable Warrants, each whole warrant exercisable for one share of Common Stock at an",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLN": {
        "symbol": "HLN",
        "name": "Haleon plc American Depositary Shares (Each representing two Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLT": {
        "symbol": "HLT",
        "name": "Hilton Worldwide Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HLX": {
        "symbol": "HLX",
        "name": "Helix Energy Solutions Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HMC": {
        "symbol": "HMC",
        "name": "Honda Motor Co., Ltd. American Depositary Share, each representing three (3) shares of Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HMN": {
        "symbol": "HMN",
        "name": "Horace Mann Educators Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HMOP": {
        "symbol": "HMOP",
        "name": "Hartford Municipal Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HMY": {
        "symbol": "HMY",
        "name": "Harmony Gold Mining Company Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HNDL": {
        "symbol": "HNDL",
        "name": "Strategy Shares Nasdaq 7HANDL Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HNI": {
        "symbol": "HNI",
        "name": "HNI Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HNW": {
        "symbol": "HNW",
        "name": "Pioneer Diversified High Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOCT": {
        "symbol": "HOCT",
        "name": "Innovator Premium Income 9 Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HODL": {
        "symbol": "HODL",
        "name": "VanEck Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOG": {
        "symbol": "HOG",
        "name": "Harley-Davidson, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOMB": {
        "symbol": "HOMB",
        "name": "Home BancShares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOMZ": {
        "symbol": "HOMZ",
        "name": "Hoya Capital Housing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOND": {
        "symbol": "HOND",
        "name": "HCM II Acquisition Corp. Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HONDW": {
        "symbol": "HONDW",
        "name": "HCM II Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOUS": {
        "symbol": "HOUS",
        "name": "Anywhere Real Estate Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HOV": {
        "symbol": "HOV",
        "name": "Hovnanian Enterprises, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HP": {
        "symbol": "HP",
        "name": "Helmerich & Payne, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPE": {
        "symbol": "HPE",
        "name": "Hewlett Packard Enterprise Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPE PRC": {
        "symbol": "HPE PRC",
        "name": "Hewlett Packard Enterprise Company 7.625% Series C Mandatory Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPF": {
        "symbol": "HPF",
        "name": "John Hancock Preferred Income Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPI": {
        "symbol": "HPI",
        "name": "John Hancock Preferred Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPP": {
        "symbol": "HPP",
        "name": "Hudson Pacific Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPP PRC": {
        "symbol": "HPP PRC",
        "name": "Hudson Pacific Properties, Inc. 4.750% Series C Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPQ": {
        "symbol": "HPQ",
        "name": "HP Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HPS": {
        "symbol": "HPS",
        "name": "John Hancock Preferred Income Fund III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HQGO": {
        "symbol": "HQGO",
        "name": "Hartford US Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HQH": {
        "symbol": "HQH",
        "name": "abrdn Healthcare Investors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HQL": {
        "symbol": "HQL",
        "name": "abrdn Life Sciences Investors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HR": {
        "symbol": "HR",
        "name": "Healthcare Realty Trust Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HRB": {
        "symbol": "HRB",
        "name": "H&R Block, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HRI": {
        "symbol": "HRI",
        "name": "Herc Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HRL": {
        "symbol": "HRL",
        "name": "Hormel Foods Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HRTG": {
        "symbol": "HRTG",
        "name": "HERITAGE INSURANCE HOLDINGS INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HRTS": {
        "symbol": "HRTS",
        "name": "Tema ETF Trust Tema GLP-1, Obesity & Cardiometabolic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSBC": {
        "symbol": "HSBC",
        "name": "HSBC Holdings PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSBH": {
        "symbol": "HSBH",
        "name": "HSBC Holdings plc ADRhedged",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSCZ": {
        "symbol": "HSCZ",
        "name": "iShares Currency Hedged MSCI EAFE Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSHP": {
        "symbol": "HSHP",
        "name": "Himalaya Shipping Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSMV": {
        "symbol": "HSMV",
        "name": "First Trust Horizon Managed Volatility Small/Mid ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSPOU": {
        "symbol": "HSPOU",
        "name": "Horizon Space Acquisition I Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSPOW": {
        "symbol": "HSPOW",
        "name": "Horizon Space Acquisition I Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSRT": {
        "symbol": "HSRT",
        "name": "Hartford AAA CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSUN": {
        "symbol": "HSUN",
        "name": "Hartford Strategic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HSY": {
        "symbol": "HSY",
        "name": "The Hershey Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTAB": {
        "symbol": "HTAB",
        "name": "Hartford Schroders Tax-Aware Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTCO": {
        "symbol": "HTCO",
        "name": "Caravelle International Group Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTD": {
        "symbol": "HTD",
        "name": "John Hancock Tax-Advantaged Dividend Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTEC": {
        "symbol": "HTEC",
        "name": "ROBO Global Healthcare Technology and Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTFB": {
        "symbol": "HTFB",
        "name": "Horizon Technology Finance Corporation 4.875% Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTFC": {
        "symbol": "HTFC",
        "name": "Horizon Technology Finance Corporation 6.25% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTGC": {
        "symbol": "HTGC",
        "name": "Hercules Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTH": {
        "symbol": "HTH",
        "name": "HILLTOP HOLDINGS INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTLM": {
        "symbol": "HTLM",
        "name": "HomesToLife Ltd Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTRB": {
        "symbol": "HTRB",
        "name": "Hartford Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HTUS": {
        "symbol": "HTUS",
        "name": "Hull Tactical US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUBB": {
        "symbol": "HUBB",
        "name": "Hubbell Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUBS": {
        "symbol": "HUBS",
        "name": "HUBSPOT, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUDA": {
        "symbol": "HUDA",
        "name": "Hudson Acquisition I Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUDAR": {
        "symbol": "HUDAR",
        "name": "Hudson Acquisition I Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUDAU": {
        "symbol": "HUDAU",
        "name": "Hudson Acquisition I Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUM": {
        "symbol": "HUM",
        "name": "Humana Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUN": {
        "symbol": "HUN",
        "name": "Huntsman Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUSA": {
        "symbol": "HUSA",
        "name": "Houston American Energy Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUSV": {
        "symbol": "HUSV",
        "name": "First Trust Horizon Managed Volatility Domestic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HUYA": {
        "symbol": "HUYA",
        "name": "HUYA Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HVT": {
        "symbol": "HVT",
        "name": "Haverty Furniture Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HVT A": {
        "symbol": "HVT A",
        "name": "Haverty Furniture Companies, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HWAY": {
        "symbol": "HWAY",
        "name": "Themes US Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HWM": {
        "symbol": "HWM",
        "name": "Howmet Aerospace Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HWM PR": {
        "symbol": "HWM PR",
        "name": "Howmet Aerospace Inc. $3.75 Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HXL": {
        "symbol": "HXL",
        "name": "Hexcel Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HY": {
        "symbol": "HY",
        "name": "Hyster-Yale, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYAC": {
        "symbol": "HYAC",
        "name": "Haymaker Acquisition Corp. 4",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYAC U": {
        "symbol": "HYAC U",
        "name": "Haymaker Acquisition Corp. 4 Units, each consisting one Class A Ordinary Share and one-half of one r",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYAC WS": {
        "symbol": "HYAC WS",
        "name": "Haymaker Acquisition Corp. 4 Warrants, each whole warrant exercisable for one Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYB": {
        "symbol": "HYB",
        "name": "New America High Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYBB": {
        "symbol": "HYBB",
        "name": "iShares BB Rated Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYBI": {
        "symbol": "HYBI",
        "name": "NEOS Enhanced Income Credit Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYBL": {
        "symbol": "HYBL",
        "name": "SPDR Blackstone High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYD": {
        "symbol": "HYD",
        "name": "VanEck High Yield Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYDB": {
        "symbol": "HYDB",
        "name": "iShares High Yield Systematic Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYDR": {
        "symbol": "HYDR",
        "name": "Global X Hydrogen ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYDW": {
        "symbol": "HYDW",
        "name": "Xtrackers Low Beta High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYEM": {
        "symbol": "HYEM",
        "name": "VanEck Emerging Markets High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYFI": {
        "symbol": "HYFI",
        "name": "AB High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYG": {
        "symbol": "HYG",
        "name": "iShares iBoxx $ High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYGH": {
        "symbol": "HYGH",
        "name": "iShares Interest Rate Hedged High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYGI": {
        "symbol": "HYGI",
        "name": "iShares Inflation Hedged High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYGV": {
        "symbol": "HYGV",
        "name": "FlexShares High Yield Value-Scored Bond Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYGW": {
        "symbol": "HYGW",
        "name": "iShares High Yield Corporate Bond BuyWrite Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYHG": {
        "symbol": "HYHG",
        "name": "ProShares High Yield-Interest Rate Hedged ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYI": {
        "symbol": "HYI",
        "name": "Western Asset High Yield Defined Opportunity Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYIN": {
        "symbol": "HYIN",
        "name": "WisdomTree Alternative Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYKE": {
        "symbol": "HYKE",
        "name": "Vest 2 Year Interest Rate Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYLB": {
        "symbol": "HYLB",
        "name": "Xtrackers USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYLG": {
        "symbol": "HYLG",
        "name": "Global X Health Care Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYLN": {
        "symbol": "HYLN",
        "name": "Hyliion Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYLS": {
        "symbol": "HYLS",
        "name": "First Trust Exchange-Traded Fund IV First Trust Tactical High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYMB": {
        "symbol": "HYMB",
        "name": "SPDR Nuveen Bloomberg High Yield Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYMU": {
        "symbol": "HYMU",
        "name": "iShares High Yield Muni Income Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYRM": {
        "symbol": "HYRM",
        "name": "Xtrackers Risk Managed USD High Yield Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYS": {
        "symbol": "HYS",
        "name": "PIMCO 0-5 Year High Yield Corporate Bond Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYSA": {
        "symbol": "HYSA",
        "name": "BondBloxx USD High Yield Bond Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYSD": {
        "symbol": "HYSD",
        "name": "Columbia Short Duration High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYT": {
        "symbol": "HYT",
        "name": "BLACKROCK CORPORATE HIGH YIELD FUND INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYTR": {
        "symbol": "HYTR",
        "name": "CP High Yield Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYUP": {
        "symbol": "HYUP",
        "name": "Xtrackers High Beta High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYXF": {
        "symbol": "HYXF",
        "name": "iShares Trust iShares ESG Advanced High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYXU": {
        "symbol": "HYXU",
        "name": "iShares Global ex USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HYZD": {
        "symbol": "HYZD",
        "name": "WisdomTree Trust WisdomTree Interest Rate Hedged High Yield Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "HZO": {
        "symbol": "HZO",
        "name": "MarineMax, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAE": {
        "symbol": "IAE",
        "name": "VOYA ASIA PACIFIC HIGH DIVIDEND EQUITY INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAF": {
        "symbol": "IAF",
        "name": "abrdn Australia Equity Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAG": {
        "symbol": "IAG",
        "name": "IAMGold Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAGG": {
        "symbol": "IAGG",
        "name": "iShares Core International Aggregate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAI": {
        "symbol": "IAI",
        "name": "iShares U.S. Broker-Dealers & Securities Exchanges ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAK": {
        "symbol": "IAK",
        "name": "iShares U.S. Insurance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAPR": {
        "symbol": "IAPR",
        "name": "Innovator International Developed Power Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAT": {
        "symbol": "IAT",
        "name": "iShares U.S. Regional Banks ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAU": {
        "symbol": "IAU",
        "name": "iShares Gold Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAUG": {
        "symbol": "IAUG",
        "name": "Innovator International Developed Power Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAUM": {
        "symbol": "IAUM",
        "name": "iShares Gold Trust Micro",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IAUX": {
        "symbol": "IAUX",
        "name": "i-80 Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBAT": {
        "symbol": "IBAT",
        "name": "iShares Energy Storage & Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBB": {
        "symbol": "IBB",
        "name": "iShares Biotechnology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBBQ": {
        "symbol": "IBBQ",
        "name": "Invesco Nasdaq Biotechnology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBD": {
        "symbol": "IBD",
        "name": "Inspire Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDP": {
        "symbol": "IBDP",
        "name": "iShares iBonds Dec 2024 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDQ": {
        "symbol": "IBDQ",
        "name": "iShares iBonds Dec 2025 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDR": {
        "symbol": "IBDR",
        "name": "iShares iBonds Dec 2026 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDS": {
        "symbol": "IBDS",
        "name": "iShares iBonds Dec 2027 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDT": {
        "symbol": "IBDT",
        "name": "iShares iBonds Dec 2028 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDU": {
        "symbol": "IBDU",
        "name": "iShares iBonds Dec 2029 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDV": {
        "symbol": "IBDV",
        "name": "iShares iBonds Dec 2030 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDW": {
        "symbol": "IBDW",
        "name": "iShares iBonds Dec 2031 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDX": {
        "symbol": "IBDX",
        "name": "iShares iBonds Dec 2032 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDY": {
        "symbol": "IBDY",
        "name": "iShares iBonds Dec 2033 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBDZ": {
        "symbol": "IBDZ",
        "name": "iShares iBonds Dec 2034 Term Corporate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBGA": {
        "symbol": "IBGA",
        "name": "iShares iBonds Dec 2044 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBGK": {
        "symbol": "IBGK",
        "name": "iShares iBonds Dec 2054 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHD": {
        "symbol": "IBHD",
        "name": "iShares iBonds 2024 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHE": {
        "symbol": "IBHE",
        "name": "iShares iBonds 2025 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHF": {
        "symbol": "IBHF",
        "name": "iShares iBonds 2026 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHG": {
        "symbol": "IBHG",
        "name": "iShares iBonds 2027 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHH": {
        "symbol": "IBHH",
        "name": "iShares iBonds 2028 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHI": {
        "symbol": "IBHI",
        "name": "iShares iBonds 2029 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHJ": {
        "symbol": "IBHJ",
        "name": "iShares iBonds 2030 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBHK": {
        "symbol": "IBHK",
        "name": "iShares iBonds 2031 Term High Yield and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIA": {
        "symbol": "IBIA",
        "name": "iShares iBonds Oct 2024 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIB": {
        "symbol": "IBIB",
        "name": "iShares iBonds Oct 2025 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIC": {
        "symbol": "IBIC",
        "name": "iShares iBonds Oct 2026 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBID": {
        "symbol": "IBID",
        "name": "iShares iBonds Oct 2027 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIE": {
        "symbol": "IBIE",
        "name": "iShares iBonds Oct 2028 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIF": {
        "symbol": "IBIF",
        "name": "iShares iBonds Oct 2029 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIG": {
        "symbol": "IBIG",
        "name": "iShares iBonds Oct 2030 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIH": {
        "symbol": "IBIH",
        "name": "iShares iBonds Oct 2031 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBII": {
        "symbol": "IBII",
        "name": "iShares iBonds Oct 2032 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIJ": {
        "symbol": "IBIJ",
        "name": "iShares iBonds Oct 2033 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIK": {
        "symbol": "IBIK",
        "name": "iShares iBonds Oct 2034 Term TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIO": {
        "symbol": "IBIO",
        "name": "iBio, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBIT": {
        "symbol": "IBIT",
        "name": "iShares Bitcoin Trust ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBLC": {
        "symbol": "IBLC",
        "name": "iShares Blockchain and Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBM": {
        "symbol": "IBM",
        "name": "International Business Machines Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMM": {
        "symbol": "IBMM",
        "name": "iShares iBonds Dec 2024 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMN": {
        "symbol": "IBMN",
        "name": "iShares iBonds Dec 2025 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMO": {
        "symbol": "IBMO",
        "name": "iShares iBonds Dec 2026 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMP": {
        "symbol": "IBMP",
        "name": "iShares iBonds Dec 2027 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMQ": {
        "symbol": "IBMQ",
        "name": "iShares iBonds Dec 2028 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMR": {
        "symbol": "IBMR",
        "name": "iShares iBonds Dec 2029 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBMS": {
        "symbol": "IBMS",
        "name": "iShares iBonds Dec 2030 Term Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBN": {
        "symbol": "IBN",
        "name": "ICICI Bank Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBND": {
        "symbol": "IBND",
        "name": "SPDR Bloomberg International Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBO": {
        "symbol": "IBO",
        "name": "Impact BioMedical, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBOT": {
        "symbol": "IBOT",
        "name": "VanEck Robotics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBP": {
        "symbol": "IBP",
        "name": "INSTALLED BUILDING PRODUCTS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBRN": {
        "symbol": "IBRN",
        "name": "iShares Neuroscience and Healthcare ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTA": {
        "symbol": "IBTA",
        "name": "Ibotta, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTE": {
        "symbol": "IBTE",
        "name": "iShares iBonds Dec 2024 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTF": {
        "symbol": "IBTF",
        "name": "iShares iBonds Dec 2025 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTG": {
        "symbol": "IBTG",
        "name": "iShares iBonds Dec 2026 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTH": {
        "symbol": "IBTH",
        "name": "iShares iBonds Dec 2027 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTI": {
        "symbol": "IBTI",
        "name": "iShares iBonds Dec 2028 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTJ": {
        "symbol": "IBTJ",
        "name": "iShares iBonds Dec 2029 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTK": {
        "symbol": "IBTK",
        "name": "iShares iBonds Dec 2030 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTL": {
        "symbol": "IBTL",
        "name": "iShares iBonds Dec 2031 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTM": {
        "symbol": "IBTM",
        "name": "iShares iBonds Dec 2032 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTO": {
        "symbol": "IBTO",
        "name": "iShares iBonds Dec 2033 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBTP": {
        "symbol": "IBTP",
        "name": "iShares iBonds Dec 2034 Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBUF": {
        "symbol": "IBUF",
        "name": "Innovator International Developed 10 Buffer ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IBUY": {
        "symbol": "IBUY",
        "name": "Amplify Online Retail ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICAP": {
        "symbol": "ICAP",
        "name": "InfraCap Equity Income Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICE": {
        "symbol": "ICE",
        "name": "Intercontinental Exchange  Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICF": {
        "symbol": "ICF",
        "name": "iShares Cohen & Steers REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICL": {
        "symbol": "ICL",
        "name": "ICL Group Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICLN": {
        "symbol": "ICLN",
        "name": "iShares Global Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICLO": {
        "symbol": "ICLO",
        "name": "Invesco AAA CLO Floating Rate Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICOP": {
        "symbol": "ICOP",
        "name": "iShares Copper and Metals Mining ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICOW": {
        "symbol": "ICOW",
        "name": "Pacer Developed Markets International Cash Cows 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICR PRA": {
        "symbol": "ICR PRA",
        "name": "InPoint Commercial Real Estate Income, Inc. 6.75% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICSH": {
        "symbol": "ICSH",
        "name": "iShares Ultra Short-Term Bond Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ICVT": {
        "symbol": "ICVT",
        "name": "iShares Convertible Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDA": {
        "symbol": "IDA",
        "name": "IDACORP, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDAT": {
        "symbol": "IDAT",
        "name": "iShares Future Cloud 5G and Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDE": {
        "symbol": "IDE",
        "name": "VOYA INFRASTRUCTURE, INDUSTRIALS AND MATERIALS FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDEC": {
        "symbol": "IDEC",
        "name": "Innovator International Developed Power Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDEV": {
        "symbol": "IDEV",
        "name": "iShares Core MSCI International Developed Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDGT": {
        "symbol": "IDGT",
        "name": "iShares U.S. Digital Infrastructure and Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDHQ": {
        "symbol": "IDHQ",
        "name": "Invesco S&P International Developed Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDLV": {
        "symbol": "IDLV",
        "name": "Invesco S&P International Developed Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDMO": {
        "symbol": "IDMO",
        "name": "Invesco S&P International Developed Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDNA": {
        "symbol": "IDNA",
        "name": "iShares Genomics Immunology and Healthcare ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDOG": {
        "symbol": "IDOG",
        "name": "ALPS International Sector Dividend Dogs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDR": {
        "symbol": "IDR",
        "name": "Idaho Strategic Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDRV": {
        "symbol": "IDRV",
        "name": "iShares Self-Driving EV and Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDT": {
        "symbol": "IDT",
        "name": "IDT Corporation Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDU": {
        "symbol": "IDU",
        "name": "iShares U.S. Utilities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDUB": {
        "symbol": "IDUB",
        "name": "Aptus International Enhanced Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDV": {
        "symbol": "IDV",
        "name": "iShares International Select Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDVO": {
        "symbol": "IDVO",
        "name": "Amplify CWP International Enhanced Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IDX": {
        "symbol": "IDX",
        "name": "VanEck Indonesia Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IE": {
        "symbol": "IE",
        "name": "Ivanhoe Electric Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEDI": {
        "symbol": "IEDI",
        "name": "iShares U.S. Consumer Focused ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEF": {
        "symbol": "IEF",
        "name": "iShares 7-10 Year Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEFA": {
        "symbol": "IEFA",
        "name": "iShares Core MSCI EAFE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEI": {
        "symbol": "IEI",
        "name": "iShares 3-7 Year Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEMG": {
        "symbol": "IEMG",
        "name": "iShares Core MSCI Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEO": {
        "symbol": "IEO",
        "name": "iShares U.S. Oil & Gas Exploration & Production ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IETC": {
        "symbol": "IETC",
        "name": "iShares U.S. Tech Independence Focused ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEUR": {
        "symbol": "IEUR",
        "name": "iShares Core MSCI Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEUS": {
        "symbol": "IEUS",
        "name": "iShares MSCI Europe Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEV": {
        "symbol": "IEV",
        "name": "iShares Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEX": {
        "symbol": "IEX",
        "name": "IDEX Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IEZ": {
        "symbol": "IEZ",
        "name": "iShares U.S. Oil Equipment & Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFEB": {
        "symbol": "IFEB",
        "name": "Innovator International Developed Power Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFED": {
        "symbol": "IFED",
        "name": "ETRACS IFED Invest with the Fed TR Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFF": {
        "symbol": "IFF",
        "name": "International Flavors & Fragrances Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFGL": {
        "symbol": "IFGL",
        "name": "iShares International Developed Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFN": {
        "symbol": "IFN",
        "name": "The India Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFRA": {
        "symbol": "IFRA",
        "name": "iShares U.S. Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFS": {
        "symbol": "IFS",
        "name": "Intercorp Financial Services Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IFV": {
        "symbol": "IFV",
        "name": "First Trust Dorsey Wright International Focus 5 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IG": {
        "symbol": "IG",
        "name": "Principal Investment Grade Corporate Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGA": {
        "symbol": "IGA",
        "name": "VOYA GLOBAL ADVANTAGE AND PREMIUM OPPORTUNITY FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGBH": {
        "symbol": "IGBH",
        "name": "iShares Interest Rate Hedged Long-Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGC": {
        "symbol": "IGC",
        "name": "IGC Pharma, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGD": {
        "symbol": "IGD",
        "name": "VOYA GLOBAL EQUITY DIVIDEND AND PREMIUM OPPORTUNITY FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGE": {
        "symbol": "IGE",
        "name": "iShares North American Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGEB": {
        "symbol": "IGEB",
        "name": "iShares Investment Grade Systematic Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGF": {
        "symbol": "IGF",
        "name": "iShares Global Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGHG": {
        "symbol": "IGHG",
        "name": "ProShares Investment Grade-Interest Rate Hedged",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGI": {
        "symbol": "IGI",
        "name": "Western Asset Investment Grade Defined Opportunity Trust Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGIB": {
        "symbol": "IGIB",
        "name": "iShares Trust iShares 5-10 Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGLB": {
        "symbol": "IGLB",
        "name": "iShares 10+ Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGLD": {
        "symbol": "IGLD",
        "name": "FT Vest Gold Strategy Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGM": {
        "symbol": "IGM",
        "name": "iShares Expanded Tech Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGOV": {
        "symbol": "IGOV",
        "name": "iShares 1-3 Year International Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGPT": {
        "symbol": "IGPT",
        "name": "Invesco AI and Next Gen Software ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGR": {
        "symbol": "IGR",
        "name": "CBRE Global Real Estate Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGRO": {
        "symbol": "IGRO",
        "name": "iShares International Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGSB": {
        "symbol": "IGSB",
        "name": "iShares Trust iShares 1-5 Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGT": {
        "symbol": "IGT",
        "name": "International Game Technology PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGTAR": {
        "symbol": "IGTAR",
        "name": "Inception Growth Acquisition Limited Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGTR": {
        "symbol": "IGTR",
        "name": "Innovator Gradient Tactical Rotation Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGV": {
        "symbol": "IGV",
        "name": "iShares Expanded Tech-Software Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IGZ": {
        "symbol": "IGZ",
        "name": "NYSE Arca Listed Test Stock For CTS CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IH": {
        "symbol": "IH",
        "name": "iHuman Inc. American depositary shares, each representing five Class A ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHAK": {
        "symbol": "IHAK",
        "name": "iShares Cybersecurity and Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHD": {
        "symbol": "IHD",
        "name": "VOYA EMERGING MARKETS HIGH INCOME DIVIDEND EQUITY FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHDG": {
        "symbol": "IHDG",
        "name": "WisdomTree International Hedged Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHE": {
        "symbol": "IHE",
        "name": "iShares U.S. Pharmaceuticals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHF": {
        "symbol": "IHF",
        "name": "iShares U.S. Healthcare Providers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHG": {
        "symbol": "IHG",
        "name": "InterContinental Hotels Group Plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHI": {
        "symbol": "IHI",
        "name": "iShares U.S. Medical Devices ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHS": {
        "symbol": "IHS",
        "name": "IHS Holding Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHT": {
        "symbol": "IHT",
        "name": "InnSuites Hospitality Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHTA": {
        "symbol": "IHTA",
        "name": "Invesco High Income 2024 Target Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHY": {
        "symbol": "IHY",
        "name": "VanEck International High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IHYF": {
        "symbol": "IHYF",
        "name": "Invesco High Yield Bond Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIF": {
        "symbol": "IIF",
        "name": "Morgan Stanley India Investment Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIGD": {
        "symbol": "IIGD",
        "name": "Invesco Investment Grade Defensive ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIIN": {
        "symbol": "IIIN",
        "name": "Insteel Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIM": {
        "symbol": "IIM",
        "name": "INVESCO VALUE MUNICIPAL INCOME TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIPR": {
        "symbol": "IIPR",
        "name": "Innovative Industrial Properties, Inc. Common stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IIPR PRA": {
        "symbol": "IIPR PRA",
        "name": "Innovative Industrial Properties, Inc.9.00 Percent Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJAN": {
        "symbol": "IJAN",
        "name": "Innovator International Developed Power Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJH": {
        "symbol": "IJH",
        "name": "iShares Core S&P Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJJ": {
        "symbol": "IJJ",
        "name": "iShares S&P Mid-Cap 400 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJK": {
        "symbol": "IJK",
        "name": "iShares S&P Mid-Cap 400 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJR": {
        "symbol": "IJR",
        "name": "iShares Core S&P Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJS": {
        "symbol": "IJS",
        "name": "iShares S&P Small-Cap 600 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJT": {
        "symbol": "IJT",
        "name": "iShares S&P Small-Cap 600 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJUL": {
        "symbol": "IJUL",
        "name": "Innovator International Developed Power Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IJUN": {
        "symbol": "IJUN",
        "name": "Innovator International Developed Power Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILCB": {
        "symbol": "ILCB",
        "name": "iShares Morningstar U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILCG": {
        "symbol": "ILCG",
        "name": "iShares Morningstar Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILCV": {
        "symbol": "ILCV",
        "name": "iShares Morningstar Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILDR": {
        "symbol": "ILDR",
        "name": "First Trust Innovation Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILF": {
        "symbol": "ILF",
        "name": "iShares Latin America 40 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILIT": {
        "symbol": "ILIT",
        "name": "iShares Lithium Miners and Producers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILOW": {
        "symbol": "ILOW",
        "name": "AB International Low Volatility Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ILTB": {
        "symbol": "ILTB",
        "name": "iShares Core 10+ Year USD Bond ETFof iShares Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMAR": {
        "symbol": "IMAR",
        "name": "Innovator International Developed Power Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMAX": {
        "symbol": "IMAX",
        "name": "Imax Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMAY": {
        "symbol": "IMAY",
        "name": "Innovator International Developed Power Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMCB": {
        "symbol": "IMCB",
        "name": "iShares Morningstar Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMCG": {
        "symbol": "IMCG",
        "name": "iShares Morningstar Mid-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMCV": {
        "symbol": "IMCV",
        "name": "iShares Morningstar Mid-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMFL": {
        "symbol": "IMFL",
        "name": "Invesco International Developed Dynamic Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMO": {
        "symbol": "IMO",
        "name": "Imperial Oil Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMOM": {
        "symbol": "IMOM",
        "name": "Alpha Architect International Quantitative Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMSI": {
        "symbol": "IMSI",
        "name": "Invesco Municipal Strategic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMTB": {
        "symbol": "IMTB",
        "name": "iShares Core 5-10 Year USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IMTM": {
        "symbol": "IMTM",
        "name": "iShares MSCI Intl Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INCE": {
        "symbol": "INCE",
        "name": "Franklin Income Equity Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INCM": {
        "symbol": "INCM",
        "name": "Franklin Income Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INCO": {
        "symbol": "INCO",
        "name": "Columbia India Consumer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDA": {
        "symbol": "INDA",
        "name": "iShares MSCI India ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDE": {
        "symbol": "INDE",
        "name": "Matthews India Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDF": {
        "symbol": "INDF",
        "name": "Range India Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDH": {
        "symbol": "INDH",
        "name": "WisdomTree India Hedged Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDL": {
        "symbol": "INDL",
        "name": "Direxion Daily MSCI India Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDO": {
        "symbol": "INDO",
        "name": "Indonesia Energy Corporation Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDS": {
        "symbol": "INDS",
        "name": "Pacer Industrial Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INDY": {
        "symbol": "INDY",
        "name": "iShares S&P India Nifty 50 Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INEQ": {
        "symbol": "INEQ",
        "name": "Columbia International Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFA": {
        "symbol": "INFA",
        "name": "Informatica Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFL": {
        "symbol": "INFL",
        "name": "Horizon Kinetics Inflation Beneficiaries ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFO": {
        "symbol": "INFO",
        "name": "Harbor PanAgora Dynamic Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFR": {
        "symbol": "INFR",
        "name": "ClearBridge Sustainable Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFU": {
        "symbol": "INFU",
        "name": "InfuSystem Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INFY": {
        "symbol": "INFY",
        "name": "Infosys Limited American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ING": {
        "symbol": "ING",
        "name": "ING Groep N.V. American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INGR": {
        "symbol": "INGR",
        "name": "Ingredion Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INKM": {
        "symbol": "INKM",
        "name": "SPDR SSgA Income Allocation ETF of SSgA Active Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INLX": {
        "symbol": "INLX",
        "name": "Intellinetics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INMU": {
        "symbol": "INMU",
        "name": "iShares Intermediate Muni Income Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INN": {
        "symbol": "INN",
        "name": "Summit Hotel Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INN PRE": {
        "symbol": "INN PRE",
        "name": "Summit Hotel Properties, Inc. 6.250% Series E Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INN PRF": {
        "symbol": "INN PRF",
        "name": "Summit Hotel Properties, Inc. 5.875% Series F Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INNO": {
        "symbol": "INNO",
        "name": "Harbor Disruptive Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INOV": {
        "symbol": "INOV",
        "name": "Innovator International Developed Power Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INQQ": {
        "symbol": "INQQ",
        "name": "INQQ The India Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INRO": {
        "symbol": "INRO",
        "name": "BlackRock ETF Trust iShares U.S. Industry Rotation Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INSI": {
        "symbol": "INSI",
        "name": "Insight Select Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INSP": {
        "symbol": "INSP",
        "name": "Inspire Medical Systems, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INST": {
        "symbol": "INST",
        "name": "Instructure Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INSW": {
        "symbol": "INSW",
        "name": "International Seaways, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INTEU": {
        "symbol": "INTEU",
        "name": "Integral Acquisition Corporation 1 Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INTEW": {
        "symbol": "INTEW",
        "name": "Integral Acquisition Corporation 1 Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INTF": {
        "symbol": "INTF",
        "name": "iShares International Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INTL": {
        "symbol": "INTL",
        "name": "Main International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INTT": {
        "symbol": "INTT",
        "name": "inTEST Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INUV": {
        "symbol": "INUV",
        "name": "Inuvo, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INV": {
        "symbol": "INV",
        "name": "Innventure, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INVH": {
        "symbol": "INVH",
        "name": "Invitation Homes Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "INVX": {
        "symbol": "INVX",
        "name": "Innovex International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IOCT": {
        "symbol": "IOCT",
        "name": "Innovator International Developed Power Buffer ETF-October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ION": {
        "symbol": "ION",
        "name": "ProShares S&P Global Core Battery Metals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IONQ": {
        "symbol": "IONQ",
        "name": "IonQ, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IONQ WS": {
        "symbol": "IONQ WS",
        "name": "IonQ, Inc. Redeemable warrants, each whole warrant exercisable for one share of Common Stock, each a",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IOO": {
        "symbol": "IOO",
        "name": "iShares Global 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IOPP": {
        "symbol": "IOPP",
        "name": "Simplify Tara India Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IOR": {
        "symbol": "IOR",
        "name": "Income Opportunity Realty Investors, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IOT": {
        "symbol": "IOT",
        "name": "Samsara Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IP": {
        "symbol": "IP",
        "name": "International Paper Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPAC": {
        "symbol": "IPAC",
        "name": "iShares Core MSCI Pacific ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPAV": {
        "symbol": "IPAV",
        "name": "Global X Infrastructure Development ex-U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPAY": {
        "symbol": "IPAY",
        "name": "Amplify Digital Payments ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPB": {
        "symbol": "IPB",
        "name": "Merrill Lynch Depositor Inc. 6.0518% Index Plus Trust Certificates Series 2003-1",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPDP": {
        "symbol": "IPDP",
        "name": "Dividend Performers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPG": {
        "symbol": "IPG",
        "name": "The Interpublic Group of Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPI": {
        "symbol": "IPI",
        "name": "Intrepid Potash, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPKW": {
        "symbol": "IPKW",
        "name": "Invesco International BuyBack Achievers  ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPO": {
        "symbol": "IPO",
        "name": "Renaissance IPO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPOS": {
        "symbol": "IPOS",
        "name": "Renaissance International IPO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IPPP": {
        "symbol": "IPPP",
        "name": "Preferred-Plus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQDE": {
        "symbol": "IQDE",
        "name": "FlexShares International Quality Dividend Defensive Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQDF": {
        "symbol": "IQDF",
        "name": "FlexShares International Quality Dividend Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQDG": {
        "symbol": "IQDG",
        "name": "WisdomTree International Quality Dividend Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQDY": {
        "symbol": "IQDY",
        "name": "FlexShares International Quality Dividend Dynamic Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQHI": {
        "symbol": "IQHI",
        "name": "NYLI MacKay ESG High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQI": {
        "symbol": "IQI",
        "name": "INVESCO QUALITY MUNICIPAL SECURITIES",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQIN": {
        "symbol": "IQIN",
        "name": "NYLI 500 International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQLT": {
        "symbol": "IQLT",
        "name": "iShares MSCI Intl Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQM": {
        "symbol": "IQM",
        "name": "Franklin Intelligent Machines ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQQQ": {
        "symbol": "IQQQ",
        "name": "ProShares Nasdaq-100 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQRA": {
        "symbol": "IQRA",
        "name": "NYLI CBRE Real Assets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQSI": {
        "symbol": "IQSI",
        "name": "NYLI Candriam International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQSM": {
        "symbol": "IQSM",
        "name": "NYLI Candriam U.S. Mid Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQSU": {
        "symbol": "IQSU",
        "name": "NYLI Candriam U.S. Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IQV": {
        "symbol": "IQV",
        "name": "IQVIA Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IR": {
        "symbol": "IR",
        "name": "Ingersoll Rand Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRET": {
        "symbol": "IRET",
        "name": "iREIT MarketVector Quality REIT Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRM": {
        "symbol": "IRM",
        "name": "Iron Mountain Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IROHU": {
        "symbol": "IROHU",
        "name": "Iron Horse Acquisitions Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRS": {
        "symbol": "IRS",
        "name": "IRSA Inversiones y Representaciones S.A. Global Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRS WS": {
        "symbol": "IRS WS",
        "name": "IRSA Inversiones y Representaciones S.A. Warrants to purchase Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRT": {
        "symbol": "IRT",
        "name": "Independence Realty Trust Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRTR": {
        "symbol": "IRTR",
        "name": "iShares LifePath Retirement ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IRVH": {
        "symbol": "IRVH",
        "name": "Global X Interest Rate Volatility & Inflation Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISCB": {
        "symbol": "ISCB",
        "name": "iShares Morningstar Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISCF": {
        "symbol": "ISCF",
        "name": "iShares International Small Cap Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISCG": {
        "symbol": "ISCG",
        "name": "iShares Morningstar Small-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISCV": {
        "symbol": "ISCV",
        "name": "iShares Morningstar Small-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISD": {
        "symbol": "ISD",
        "name": "PGIM High Yield Bond Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISDB": {
        "symbol": "ISDB",
        "name": "Invesco Short Duration Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISDR": {
        "symbol": "ISDR",
        "name": "Issuer Direct Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISEP": {
        "symbol": "ISEP",
        "name": "Innovator International Developed Power Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISHG": {
        "symbol": "ISHG",
        "name": "iShares International Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISHP": {
        "symbol": "ISHP",
        "name": "First Trust Exchange-Traded Fund VI First Trust S-Network E-Commerce ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISMD": {
        "symbol": "ISMD",
        "name": "Inspire Small/Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISPY": {
        "symbol": "ISPY",
        "name": "ProShares S&P 500 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISRA": {
        "symbol": "ISRA",
        "name": "VanEck Israel ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISTB": {
        "symbol": "ISTB",
        "name": "iShares Core 1-5 Year USD Bond ETF of iShares Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISVL": {
        "symbol": "ISVL",
        "name": "iShares International Developed Small Cap Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ISWN": {
        "symbol": "ISWN",
        "name": "Amplify BlackSwan ISWN ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IT": {
        "symbol": "IT",
        "name": "Gartner, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITA": {
        "symbol": "ITA",
        "name": "iShares U.S. Aerospace & Defense ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITAN": {
        "symbol": "ITAN",
        "name": "Sparkline Intangible Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITB": {
        "symbol": "ITB",
        "name": "iShares U.S. Home Construction ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDA": {
        "symbol": "ITDA",
        "name": "iShares LifePath Target Date 2025 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDB": {
        "symbol": "ITDB",
        "name": "iShares LifePath Target Date 2030 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDC": {
        "symbol": "ITDC",
        "name": "iShares LifePath Target Date 2035 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDD": {
        "symbol": "ITDD",
        "name": "iShares LifePath Target Date 2040 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDE": {
        "symbol": "ITDE",
        "name": "iShares LifePath Target Date 2045 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDF": {
        "symbol": "ITDF",
        "name": "iShares LifePath Target Date 2050 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDG": {
        "symbol": "ITDG",
        "name": "iShares LifePath Target Date 2055 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDH": {
        "symbol": "ITDH",
        "name": "iShares LifePath Target Date 2060 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITDI": {
        "symbol": "ITDI",
        "name": "iShares LifePath Target Date 2065 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITEQ": {
        "symbol": "ITEQ",
        "name": "Amplify BlueStar Israel Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITGR": {
        "symbol": "ITGR",
        "name": "Integer Holdings Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITM": {
        "symbol": "ITM",
        "name": "VanEck Intermediate Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITOT": {
        "symbol": "ITOT",
        "name": "iShares Core S&P Total U.S. Stock Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITP": {
        "symbol": "ITP",
        "name": "IT Tech Packaging, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITRG": {
        "symbol": "ITRG",
        "name": "Integra Resources Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITT": {
        "symbol": "ITT",
        "name": "ITT Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITUB": {
        "symbol": "ITUB",
        "name": "Itau Unibanco Holding S.A. American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITW": {
        "symbol": "ITW",
        "name": "Illinois Tool Works Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ITWO": {
        "symbol": "ITWO",
        "name": "ProShares Russell 2000 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IUS": {
        "symbol": "IUS",
        "name": "Invesco Exchange-Traded Self-Indexed Fund Trust Invesco RAFI Strategic US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IUSB": {
        "symbol": "IUSB",
        "name": "iShares Core Total USD Bond Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IUSG": {
        "symbol": "IUSG",
        "name": "iShares Core S&P U.S. Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IUSV": {
        "symbol": "IUSV",
        "name": "iShares Core S&P U.S. Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVAL": {
        "symbol": "IVAL",
        "name": "Alpha Architect International Quantitative Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVCAU": {
        "symbol": "IVCAU",
        "name": "Investcorp India Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVCAW": {
        "symbol": "IVCAW",
        "name": "Investcorp India Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVCBU": {
        "symbol": "IVCBU",
        "name": "Investcorp Europe Acquisition Corp I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVE": {
        "symbol": "IVE",
        "name": "iShares S&P 500 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVEG": {
        "symbol": "IVEG",
        "name": "iShares Emergent Food and AgTech Multisector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVES": {
        "symbol": "IVES",
        "name": "Amplify Global Cloud Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVLU": {
        "symbol": "IVLU",
        "name": "iShares MSCI Intl Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVOG": {
        "symbol": "IVOG",
        "name": "Vanguard S&P Mid-Cap 400 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVOL": {
        "symbol": "IVOL",
        "name": "Quadratic Interest Rate Volatility and Inflation Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVOO": {
        "symbol": "IVOO",
        "name": "Vanguard S&P Mid-Cap 400 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVOV": {
        "symbol": "IVOV",
        "name": "Vanguard S&P Mid-Cap 400 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVR": {
        "symbol": "IVR",
        "name": "Invesco Mortgage Capital Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVR PRB": {
        "symbol": "IVR PRB",
        "name": "INVESCO MORTGAGE CAPITAL INC. 7.75% Fixed-To-Floating Series B Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVR PRC": {
        "symbol": "IVR PRC",
        "name": "Invesco Mortgage Capital Inc. 7.5% Fixed-to-Floating Series C Cumulative Redeemable Preferred Stock,",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVRA": {
        "symbol": "IVRA",
        "name": "Invesco Real Assets ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVRS": {
        "symbol": "IVRS",
        "name": "iShares Future Metaverse Tech and Communications ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVT": {
        "symbol": "IVT",
        "name": "InvenTrust Properties Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVV": {
        "symbol": "IVV",
        "name": "iShares Core S&P 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVVB": {
        "symbol": "IVVB",
        "name": "iShares Large Cap Deep Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVVM": {
        "symbol": "IVVM",
        "name": "iShares Large Cap Moderate Buffer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVVW": {
        "symbol": "IVVW",
        "name": "iShares S&P 500 BuyWrite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVW": {
        "symbol": "IVW",
        "name": "iShares S&P 500 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IVZ": {
        "symbol": "IVZ",
        "name": "Invesco LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWB": {
        "symbol": "IWB",
        "name": "iShares Russell 1000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWC": {
        "symbol": "IWC",
        "name": "iShares Micro-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWD": {
        "symbol": "IWD",
        "name": "iShares Russell 1000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWDL": {
        "symbol": "IWDL",
        "name": "ETRACS 2x Leveraged US Value Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWF": {
        "symbol": "IWF",
        "name": "iShares Russell 1000 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWFG": {
        "symbol": "IWFG",
        "name": "NYLI Winslow Focused Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWFL": {
        "symbol": "IWFL",
        "name": "ETRACS 2x Leveraged US Growth Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWL": {
        "symbol": "IWL",
        "name": "iShares Russell Top 200 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWLG": {
        "symbol": "IWLG",
        "name": "NYLI Winslow Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWM": {
        "symbol": "IWM",
        "name": "iShares Russell 2000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWMI": {
        "symbol": "IWMI",
        "name": "NEOS Russell 2000 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWML": {
        "symbol": "IWML",
        "name": "ETRACS 2x Leveraged US Size Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWMW": {
        "symbol": "IWMW",
        "name": "iShares Russell 2000 BuyWrite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWMY": {
        "symbol": "IWMY",
        "name": "Defiance R2000 Enhanced Options & 0DTE Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWN": {
        "symbol": "IWN",
        "name": "iShares Russell 2000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWO": {
        "symbol": "IWO",
        "name": "iShares Russell 2000 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWP": {
        "symbol": "IWP",
        "name": "iShares Russell Mid-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWR": {
        "symbol": "IWR",
        "name": "iShares Russell Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWS": {
        "symbol": "IWS",
        "name": "iShares Russell Mid-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWTR": {
        "symbol": "IWTR",
        "name": "iShares MSCI Water Management Multisector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWV": {
        "symbol": "IWV",
        "name": "iShares Russell 3000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWX": {
        "symbol": "IWX",
        "name": "iShares Russell Top 200 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IWY": {
        "symbol": "IWY",
        "name": "iShares Russell Top 200 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IX": {
        "symbol": "IX",
        "name": "ORIX Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXAQU": {
        "symbol": "IXAQU",
        "name": "IX Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXC": {
        "symbol": "IXC",
        "name": "iShares Global Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXG": {
        "symbol": "IXG",
        "name": "iShares Global Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXJ": {
        "symbol": "IXJ",
        "name": "iShares Global Healthcare ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXN": {
        "symbol": "IXN",
        "name": "iShares Global Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXP": {
        "symbol": "IXP",
        "name": "iShares Global Comm Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IXUS": {
        "symbol": "IXUS",
        "name": "iShares Core MSCI Total International Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYC": {
        "symbol": "IYC",
        "name": "iShares U.S. Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYE": {
        "symbol": "IYE",
        "name": "iShares U.S. Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYF": {
        "symbol": "IYF",
        "name": "iShares U.S. Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYG": {
        "symbol": "IYG",
        "name": "iShares U.S. Financial Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYH": {
        "symbol": "IYH",
        "name": "iShares U.S. Healthcare ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYJ": {
        "symbol": "IYJ",
        "name": "iShares U.S. Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYK": {
        "symbol": "IYK",
        "name": "iShares U.S. Consumer Staples ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYLD": {
        "symbol": "IYLD",
        "name": "iShares Morningstar Multi-Asset Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYM": {
        "symbol": "IYM",
        "name": "iShares U.S. Basic Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYR": {
        "symbol": "IYR",
        "name": "iShares U.S. Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYT": {
        "symbol": "IYT",
        "name": "iShares U.S. Transportation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYW": {
        "symbol": "IYW",
        "name": "iShares U.S. Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYY": {
        "symbol": "IYY",
        "name": "iShares Dow Jones U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IYZ": {
        "symbol": "IYZ",
        "name": "iShares U.S. Telecommunications ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "IZRL": {
        "symbol": "IZRL",
        "name": "ARK Israel Innovative Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "J": {
        "symbol": "J",
        "name": "Jacobs Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JAAA": {
        "symbol": "JAAA",
        "name": "Janus Henderson AAA CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JADE": {
        "symbol": "JADE",
        "name": "JPMorgan Active Developing Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JAJL": {
        "symbol": "JAJL",
        "name": "Innovator Equity Defined Protection ETF - 6mo Jan/Jul",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JAND": {
        "symbol": "JAND",
        "name": "Innovator Premium Income 10 Barrier ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANH": {
        "symbol": "JANH",
        "name": "Innovator Premium Income 20 Barrier ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANJ": {
        "symbol": "JANJ",
        "name": "Innovator Premium Income 30 Barrier ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANP": {
        "symbol": "JANP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANQ": {
        "symbol": "JANQ",
        "name": "Innovator Premium Income 40 Barrier ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANT": {
        "symbol": "JANT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Jan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANW": {
        "symbol": "JANW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Jan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JANZ": {
        "symbol": "JANZ",
        "name": "TrueShares Structured Outcome (January) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JAVA": {
        "symbol": "JAVA",
        "name": "JPMorgan Active Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBBB": {
        "symbol": "JBBB",
        "name": "Janus Henderson B-BBB CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBGS": {
        "symbol": "JBGS",
        "name": "JBG SMITH Properties Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBI": {
        "symbol": "JBI",
        "name": "Janus International Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBK": {
        "symbol": "JBK",
        "name": "Lehman ABS Corp 3.50 Adj Corp Backed Tr Cert Goldman Cap I",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBL": {
        "symbol": "JBL",
        "name": "Jabil Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBND": {
        "symbol": "JBND",
        "name": "JPMorgan Active Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JBT": {
        "symbol": "JBT",
        "name": "John Bean Technologies Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCE": {
        "symbol": "JCE",
        "name": "Nuveen Core Equity Alpha Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCHI": {
        "symbol": "JCHI",
        "name": "JPMorgan Active China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCI": {
        "symbol": "JCI",
        "name": "Johnson Controls International plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCPB": {
        "symbol": "JCPB",
        "name": "JPMorgan Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCPI": {
        "symbol": "JCPI",
        "name": "JPMorgan Inflation Managed Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCTC": {
        "symbol": "JCTC",
        "name": "Jewett-Cameron Trading",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JCTR": {
        "symbol": "JCTR",
        "name": "JPMorgan Carbon Transition U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JDIV": {
        "symbol": "JDIV",
        "name": "JPMorgan Dividend Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JDOC": {
        "symbol": "JDOC",
        "name": "JPMorgan Healthcare Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JDST": {
        "symbol": "JDST",
        "name": "Direxion Daily Junior Gold Miners Index Bear 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JDVI": {
        "symbol": "JDVI",
        "name": "John Hancock Disciplined Value International Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEF": {
        "symbol": "JEF",
        "name": "Jefferies Financial Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JELD": {
        "symbol": "JELD",
        "name": "JELD-WEN Holding, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEMA": {
        "symbol": "JEMA",
        "name": "JPMorgan ActiveBuilders Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEMB": {
        "symbol": "JEMB",
        "name": "Janus Henderson Emerging Markets Debt Hard Currency ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEPI": {
        "symbol": "JEPI",
        "name": "JPMorgan Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEPQ": {
        "symbol": "JEPQ",
        "name": "J.P. Morgan Nasdaq Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JEQ": {
        "symbol": "JEQ",
        "name": "abrdn Japan Equity Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JETD": {
        "symbol": "JETD",
        "name": "MAX Airlines -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JETS": {
        "symbol": "JETS",
        "name": "U.S. Global Jets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JETU": {
        "symbol": "JETU",
        "name": "MAX Airlines 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JFR": {
        "symbol": "JFR",
        "name": "Nuveen Floating Rate Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JFWD": {
        "symbol": "JFWD",
        "name": "Jacob Forward ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JGH": {
        "symbol": "JGH",
        "name": "NUVEEN GLOBAL HIGH INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JGLO": {
        "symbol": "JGLO",
        "name": "JPMorgan Global Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JGRO": {
        "symbol": "JGRO",
        "name": "JPMorgan Active Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JGRW": {
        "symbol": "JGRW",
        "name": "Jensen Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHAC": {
        "symbol": "JHAC",
        "name": "John Hancock Fundamental All Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHCB": {
        "symbol": "JHCB",
        "name": "John Hancock Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHDV": {
        "symbol": "JHDV",
        "name": "John Hancock U.S. High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHEM": {
        "symbol": "JHEM",
        "name": "John Hancock Multifactor Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHG": {
        "symbol": "JHG",
        "name": "Janus Henderson Group plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHHY": {
        "symbol": "JHHY",
        "name": "John Hancock High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHI": {
        "symbol": "JHI",
        "name": "John Hancock Investors Trust Capital Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHID": {
        "symbol": "JHID",
        "name": "John Hancock International High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHMB": {
        "symbol": "JHMB",
        "name": "John Hancock Mortgage-Backed Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHMD": {
        "symbol": "JHMD",
        "name": "John Hancock Multifactor Developed International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHML": {
        "symbol": "JHML",
        "name": "John Hancock Multifactor Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHMM": {
        "symbol": "JHMM",
        "name": "John Hancock Multifactor Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHMU": {
        "symbol": "JHMU",
        "name": "John Hancock Dynamic Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHPI": {
        "symbol": "JHPI",
        "name": "John Hancock Preferred Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHS": {
        "symbol": "JHS",
        "name": "John Hancock Income Securities Trust Capital Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHSC": {
        "symbol": "JHSC",
        "name": "John Hancock Multifactor Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JHX": {
        "symbol": "JHX",
        "name": "JAMES HARDIE INDUSTRIES plc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JIG": {
        "symbol": "JIG",
        "name": "JPMorgan International Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JILL": {
        "symbol": "JILL",
        "name": "J.Jill, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JIRE": {
        "symbol": "JIRE",
        "name": "JPMorgan International Research Enhanced Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JIVE": {
        "symbol": "JIVE",
        "name": "JPMorgan International Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JKS": {
        "symbol": "JKS",
        "name": "JINKOSOLAR HOLDINGS CO",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JLL": {
        "symbol": "JLL",
        "name": "Jones Lang LaSalle, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JLQD": {
        "symbol": "JLQD",
        "name": "Janus Henderson Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JLS": {
        "symbol": "JLS",
        "name": "Nuveen Mortgage and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMBS": {
        "symbol": "JMBS",
        "name": "Janus Henderson Mortgage-Backed Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMEE": {
        "symbol": "JMEE",
        "name": "JPMorgan Small & Mid Cap Enhanced Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMHI": {
        "symbol": "JMHI",
        "name": "JPMorgan High Yield Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMIA": {
        "symbol": "JMIA",
        "name": "Jumia Technologies AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMID": {
        "symbol": "JMID",
        "name": "Janus Henderson Mid Cap Growth Alpha ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMM": {
        "symbol": "JMM",
        "name": "NUVEEN MULTI-MARKET INCOME FUND, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMOM": {
        "symbol": "JMOM",
        "name": "JPMorgan U.S. Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMSI": {
        "symbol": "JMSI",
        "name": "JPMorgan Sustainable Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMST": {
        "symbol": "JMST",
        "name": "JPMorgan Ultra-Short Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JMUB": {
        "symbol": "JMUB",
        "name": "JPMorgan Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JNEU": {
        "symbol": "JNEU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped June ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JNJ": {
        "symbol": "JNJ",
        "name": "Johnson & Johnson",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JNK": {
        "symbol": "JNK",
        "name": "SPDR Bloomberg High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JNPR": {
        "symbol": "JNPR",
        "name": "Juniper Networks Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JNUG": {
        "symbol": "JNUG",
        "name": "Direxion Daily Junior Gold Miners Index Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOB": {
        "symbol": "JOB",
        "name": "GEE Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOBY": {
        "symbol": "JOBY",
        "name": "Joby Aviation, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOBY WS": {
        "symbol": "JOBY WS",
        "name": "Joby Aviation, Inc.  Redeemable Warrants, each whole warrant exercisable for one share of Common Sto",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOE": {
        "symbol": "JOE",
        "name": "St. Joe Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOET": {
        "symbol": "JOET",
        "name": "Virtus Terranova U.S. Quality Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOF": {
        "symbol": "JOF",
        "name": "JAPAN SMALLER CAPITALIZATION FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JOJO": {
        "symbol": "JOJO",
        "name": "ATAC Credit Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPAN": {
        "symbol": "JPAN",
        "name": "Matthews Japan Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPC": {
        "symbol": "JPC",
        "name": "Nuveen Preferred & Income Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPEF": {
        "symbol": "JPEF",
        "name": "JPMorgan Equity Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPEM": {
        "symbol": "JPEM",
        "name": "JPMorgan Diversified Return Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPI": {
        "symbol": "JPI",
        "name": "Nuveen Preferred Securities & Income Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPIB": {
        "symbol": "JPIB",
        "name": "JPMorgan International Bond Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPIE": {
        "symbol": "JPIE",
        "name": "JPMorgan Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPIN": {
        "symbol": "JPIN",
        "name": "JPMorgan Diversified Return International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPLD": {
        "symbol": "JPLD",
        "name": "JPMorgan Limited Duration Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM": {
        "symbol": "JPM",
        "name": "JPMorgan Chase & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRC": {
        "symbol": "JPM PRC",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th interest in a share of 6.00% Non",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRD": {
        "symbol": "JPM PRD",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th  interest in a share of 5.75% No",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRJ": {
        "symbol": "JPM PRJ",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th interest in a share of JPMorgan",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRK": {
        "symbol": "JPM PRK",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th interest in a share of 4.55% Non",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRL": {
        "symbol": "JPM PRL",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th interest in a share of 4.625% No",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPM PRM": {
        "symbol": "JPM PRM",
        "name": "JPMorgan Chase & Co. Depositary Shares, each representing a 1/400th interest in a share of 4.20% Non",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPMB": {
        "symbol": "JPMB",
        "name": "JPMorgan USD Emerging Markets Sovereign Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPME": {
        "symbol": "JPME",
        "name": "JPMorgan Diversified Return US Mid Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPMO": {
        "symbol": "JPMO",
        "name": "YieldMax JPM Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPRE": {
        "symbol": "JPRE",
        "name": "JPMorgan Realty Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPSE": {
        "symbol": "JPSE",
        "name": "JPMorgan Diversified Return US Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPST": {
        "symbol": "JPST",
        "name": "JPMorgan Ultra-Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPSV": {
        "symbol": "JPSV",
        "name": "JPMorgan Active Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPUS": {
        "symbol": "JPUS",
        "name": "JPMorgan Diversified Return US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JPXN": {
        "symbol": "JPXN",
        "name": "iShares JPX-Nikkei 400 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JQC": {
        "symbol": "JQC",
        "name": "Nuveen Credit Strategies Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JQUA": {
        "symbol": "JQUA",
        "name": "JPMorgan U.S. Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JRE": {
        "symbol": "JRE",
        "name": "Janus Henderson U.S. Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JRI": {
        "symbol": "JRI",
        "name": "NUVEEN REAL ASSET INCOME AND GROWTH FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JRNY": {
        "symbol": "JRNY",
        "name": "ALPS Global Travel Beneficiaries ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JRS": {
        "symbol": "JRS",
        "name": "Nuveen Real Estate Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JSCP": {
        "symbol": "JSCP",
        "name": "JPMorgan Short Duration Core Plus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JSI": {
        "symbol": "JSI",
        "name": "Janus Henderson Securitized Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JSMD": {
        "symbol": "JSMD",
        "name": "Janus Detroit Street Trust Janus Henderson Small/Mid Cap Growth Alpha ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JSML": {
        "symbol": "JSML",
        "name": "Janus Detroit Street Trust Janus Henderson Small Cap Growth Alpha ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JSTC": {
        "symbol": "JSTC",
        "name": "Adasina Social Justice All Cap Global ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JTEK": {
        "symbol": "JTEK",
        "name": "JPMorgan U.S. Tech Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUCY": {
        "symbol": "JUCY",
        "name": "Aptus Enhanced Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULD": {
        "symbol": "JULD",
        "name": "Innovator Premium Income 10 Barrier ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULH": {
        "symbol": "JULH",
        "name": "Innovator Premium Income 20 Barrier ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULJ": {
        "symbol": "JULJ",
        "name": "Innovator Premium Income 30 Barrier ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULM": {
        "symbol": "JULM",
        "name": "FT Vest U.S. Equity Max Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULP": {
        "symbol": "JULP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULQ": {
        "symbol": "JULQ",
        "name": "Innovator Premium Income 40 Barrier ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULT": {
        "symbol": "JULT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Jul ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULU": {
        "symbol": "JULU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped Jul ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULW": {
        "symbol": "JULW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Jul ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JULZ": {
        "symbol": "JULZ",
        "name": "TrueShares Structured Outcome (July) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUNM": {
        "symbol": "JUNM",
        "name": "FT Vest U.S. Equity Max Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUNP": {
        "symbol": "JUNP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUNT": {
        "symbol": "JUNT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Jun ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUNW": {
        "symbol": "JUNW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Jun ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUNZ": {
        "symbol": "JUNZ",
        "name": "TrueShares Structured Outcome (June) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JUST": {
        "symbol": "JUST",
        "name": "Goldman Sachs JUST U.S. Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JVAL": {
        "symbol": "JVAL",
        "name": "JPMorgan U.S. Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JVSAR": {
        "symbol": "JVSAR",
        "name": "JV SPAC Acquisition Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JWN": {
        "symbol": "JWN",
        "name": "Nordstrom, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JWSM": {
        "symbol": "JWSM",
        "name": "Jaws Mustang Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JWSM U": {
        "symbol": "JWSM U",
        "name": "Jaws Mustang Acquisition Corp. Units, each consisting of one Class A ordinary share, and one-fourth",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JWSM WS": {
        "symbol": "JWSM WS",
        "name": "Jaws Mustang Acquisition Corp. Redeemable Warrants, each whole warrant exercisable for one Class A o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JXI": {
        "symbol": "JXI",
        "name": "iShares Global Utilities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JXN": {
        "symbol": "JXN",
        "name": "Jackson Financial Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "JXN PRA": {
        "symbol": "JXN PRA",
        "name": "Jackson Financial Inc. Depositary Shares, each representing a 1/1,000th interest in a share of Fixed",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "K": {
        "symbol": "K",
        "name": "Kellanova",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KACLU": {
        "symbol": "KACLU",
        "name": "Kairous Acquisition Corp. Limited Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KACLW": {
        "symbol": "KACLW",
        "name": "Kairous Acquisition Corp. Limited Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KAI": {
        "symbol": "KAI",
        "name": "Kadant Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KALL": {
        "symbol": "KALL",
        "name": "KraneShares MSCI All China Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KAPA": {
        "symbol": "KAPA",
        "name": "Kairos Pharma, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KAPR": {
        "symbol": "KAPR",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KAR": {
        "symbol": "KAR",
        "name": "OPENLANE, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KARS": {
        "symbol": "KARS",
        "name": "KraneShares Electric Vehicles and Future Mobility Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KAUG": {
        "symbol": "KAUG",
        "name": "Innovator US Small Cap Power Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KB": {
        "symbol": "KB",
        "name": "KB Financial Group Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBA": {
        "symbol": "KBA",
        "name": "KraneShares Bosera MSCI China A 50 Connect Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBDC": {
        "symbol": "KBDC",
        "name": "Kayne Anderson BDC, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBE": {
        "symbol": "KBE",
        "name": "SPDR S&P Bank ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBH": {
        "symbol": "KBH",
        "name": "KB Home",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBR": {
        "symbol": "KBR",
        "name": "KBR, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBUF": {
        "symbol": "KBUF",
        "name": "KraneShares 90% KWEB Defined Outcome January 2026 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBWB": {
        "symbol": "KBWB",
        "name": "Invesco KBW Bank ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBWD": {
        "symbol": "KBWD",
        "name": "Invesco KBW High Dividend Yield Financial ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBWP": {
        "symbol": "KBWP",
        "name": "Invesco KBW Property & Casualty Insurance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBWR": {
        "symbol": "KBWR",
        "name": "Invesco KBW Regional Banking ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KBWY": {
        "symbol": "KBWY",
        "name": "Invesco KBW Premium Yield Equity REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KCAI": {
        "symbol": "KCAI",
        "name": "KraneShares China Alpha Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KCCA": {
        "symbol": "KCCA",
        "name": "KraneShares California Carbon Allowance Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KCE": {
        "symbol": "KCE",
        "name": "SPDR S&P Capital Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KCSH": {
        "symbol": "KCSH",
        "name": "KraneShares Sustainable Ultra Short Duration Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KD": {
        "symbol": "KD",
        "name": "Kyndryl Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KDRN": {
        "symbol": "KDRN",
        "name": "Kingsbarn Tactical Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEAT": {
        "symbol": "KEAT",
        "name": "Keating Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEM": {
        "symbol": "KEM",
        "name": "KraneShares Dynamic Emerging Markets Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEMQ": {
        "symbol": "KEMQ",
        "name": "KraneShares Emerging Markets Consumer Technology Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEMX": {
        "symbol": "KEMX",
        "name": "KraneShares MSCI Emerging Markets Ex China Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEN": {
        "symbol": "KEN",
        "name": "KENON HOLDINGS LTD.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEP": {
        "symbol": "KEP",
        "name": "Korea Electric Power Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEUA": {
        "symbol": "KEUA",
        "name": "KraneShares European Carbon Allowance Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEX": {
        "symbol": "KEX",
        "name": "Kirby Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEY": {
        "symbol": "KEY",
        "name": "KeyCorp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEY PRI": {
        "symbol": "KEY PRI",
        "name": "KeyCorp Depositary Shares each representing a 1/40th ownership interest in a share of Fixed-to-Float",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEY PRJ": {
        "symbol": "KEY PRJ",
        "name": "KeyCorp Depositary Shares each representing a 1/40th ownership interest in a share of Fixed Rate Per",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEY PRK": {
        "symbol": "KEY PRK",
        "name": "KeyCorp Depositary Shares, each representing a 1/40th ownership interest in a share of Fixed Rate Pe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEY PRL": {
        "symbol": "KEY PRL",
        "name": "KeyCorp Depositary Shares each representing a 1/40th ownership interest in a share of Fixed Rate Per",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KEYS": {
        "symbol": "KEYS",
        "name": "Keysight Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KF": {
        "symbol": "KF",
        "name": "KOREA FUND, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KFRC": {
        "symbol": "KFRC",
        "name": "Kforce Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KFS": {
        "symbol": "KFS",
        "name": "Kingsway Financial Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KFY": {
        "symbol": "KFY",
        "name": "Korn Ferry",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KGC": {
        "symbol": "KGC",
        "name": "Kinross Gold Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KGRN": {
        "symbol": "KGRN",
        "name": "KraneShares MSCI China Clean Technology Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KGS": {
        "symbol": "KGS",
        "name": "Kodiak Gas Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KHPI": {
        "symbol": "KHPI",
        "name": "Kensington Hedged Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KHYB": {
        "symbol": "KHYB",
        "name": "KraneShares Asia Pacific High Income USD Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIE": {
        "symbol": "KIE",
        "name": "SPDR S&P Insurance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIM": {
        "symbol": "KIM",
        "name": "Kimco Realty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIM PRL": {
        "symbol": "KIM PRL",
        "name": "Kimco Realty Corporation Class L Depositary Shares, each of which represents a one-one thousandth fr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIM PRM": {
        "symbol": "KIM PRM",
        "name": "Kimco Realty Corporation Class M Depositary Shares, each of which represents a one-one thousandth fr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIM PRN": {
        "symbol": "KIM PRN",
        "name": "Kimco Realty Corporation Depositary Shares, each representing 1/1,000th interest in a share of 7.25%",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIND": {
        "symbol": "KIND",
        "name": "Nextdoor Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KIO": {
        "symbol": "KIO",
        "name": "KKR INCOME OPPORTUNITIES FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KJAN": {
        "symbol": "KJAN",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KJUL": {
        "symbol": "KJUL",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KJUN": {
        "symbol": "KJUN",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KKR": {
        "symbol": "KKR",
        "name": "KKR & Co. Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KKRS": {
        "symbol": "KKRS",
        "name": "KKR Group Finance Co. IX LLC 4.625% Subordinated Notes due 2061",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KLC": {
        "symbol": "KLC",
        "name": "KinderCare Learning Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KLG": {
        "symbol": "KLG",
        "name": "WK Kellogg Co",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KLIP": {
        "symbol": "KLIP",
        "name": "KraneShares China Internet and Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KLMT": {
        "symbol": "KLMT",
        "name": "Invesco MSCI Global Climate 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KLXY": {
        "symbol": "KLXY",
        "name": "KraneShares Global Luxury Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMB": {
        "symbol": "KMB",
        "name": "Kimberly-Clark Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMI": {
        "symbol": "KMI",
        "name": "Kinder Morgan, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMLM": {
        "symbol": "KMLM",
        "name": "KraneShares Mount Lucas Managed Futures Index Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMPB": {
        "symbol": "KMPB",
        "name": "Kemper Corporation 5.875% Fixed-Rate Reset Junior Subordinated Debentures due 2062",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMPR": {
        "symbol": "KMPR",
        "name": "Kemper Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMT": {
        "symbol": "KMT",
        "name": "Kennametal Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KMX": {
        "symbol": "KMX",
        "name": "CarMax Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KN": {
        "symbol": "KN",
        "name": "KNOWLES CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNCT": {
        "symbol": "KNCT",
        "name": "Invesco Next Gen Connectivity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNF": {
        "symbol": "KNF",
        "name": "Knife River Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNG": {
        "symbol": "KNG",
        "name": "FT Vest S&P 500 Dividend Aristocrats Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNGS": {
        "symbol": "KNGS",
        "name": "Roundhill S&P Dividend Monarchs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNGZ": {
        "symbol": "KNGZ",
        "name": "First Trust Exchange-Traded Fund VI First Trust S&P 500 Diversified Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNO": {
        "symbol": "KNO",
        "name": "AXS Knowledge Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNOP": {
        "symbol": "KNOP",
        "name": "KNOT OFFSHORE PARTNERS LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNOW": {
        "symbol": "KNOW",
        "name": "Fundamentals First ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNSL": {
        "symbol": "KNSL",
        "name": "Kinsale Capital Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNTK": {
        "symbol": "KNTK",
        "name": "Kinetik Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNW": {
        "symbol": "KNW",
        "name": "Know Labs, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KNX": {
        "symbol": "KNX",
        "name": "Knight-Swift Transportation Holdings Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KO": {
        "symbol": "KO",
        "name": "Coca-Cola Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOCG": {
        "symbol": "KOCG",
        "name": "FIS Knights of Columbus Global Belief ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOCT": {
        "symbol": "KOCT",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KODK": {
        "symbol": "KODK",
        "name": "EASTMAN KODAK COMPANY",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOF": {
        "symbol": "KOF",
        "name": "Coca-Cola FEMSA, S.A.B DE C.V",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOKU": {
        "symbol": "KOKU",
        "name": "Xtrackers MSCI Kokusai Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOLD": {
        "symbol": "KOLD",
        "name": "ProShares UltraShort Bloomberg Natural Gas",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOMP": {
        "symbol": "KOMP",
        "name": "SPDR S&P Kensho New Economies Composite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KONG": {
        "symbol": "KONG",
        "name": "Formidable Fortress ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOOL": {
        "symbol": "KOOL",
        "name": "North Shore Equity Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOP": {
        "symbol": "KOP",
        "name": "Koppers Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KORE": {
        "symbol": "KORE",
        "name": "KORE Group Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KORP": {
        "symbol": "KORP",
        "name": "American Century Diversified Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KORU": {
        "symbol": "KORU",
        "name": "Direxion Daily South Korea Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KOS": {
        "symbol": "KOS",
        "name": "Kosmos Energy Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KPOP": {
        "symbol": "KPOP",
        "name": "JAKOTA K-Pop and Korean Entertainment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KPRO": {
        "symbol": "KPRO",
        "name": "KraneShares 100% KWEB Defined Outcome January 2026 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KQQQ": {
        "symbol": "KQQQ",
        "name": "Kurv Technology Titans Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KR": {
        "symbol": "KR",
        "name": "The Kroger Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRBN": {
        "symbol": "KRBN",
        "name": "KraneShares Global Carbon Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRC": {
        "symbol": "KRC",
        "name": "Kilroy Realty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRE": {
        "symbol": "KRE",
        "name": "SPDR S&P Regional Banking ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KREF": {
        "symbol": "KREF",
        "name": "KKR Real Estate Finance Trust Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KREF PRA": {
        "symbol": "KREF PRA",
        "name": "KKR Real Estate Finance Trust Inc. 6.50% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRG": {
        "symbol": "KRG",
        "name": "Kite Realty Group Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRMA": {
        "symbol": "KRMA",
        "name": "Global X Conscious Companies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRO": {
        "symbol": "KRO",
        "name": "Kronos Worldwide, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KROP": {
        "symbol": "KROP",
        "name": "Global X AgTech & Food Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRP": {
        "symbol": "KRP",
        "name": "Kimbell Royalty Partners, LP Common Units representing Limited Partner Interests",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KRUZ": {
        "symbol": "KRUZ",
        "name": "Unusual Whales Subversive Republican Trading ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSA": {
        "symbol": "KSA",
        "name": "iShares MSCI Saudi Arabia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSEA": {
        "symbol": "KSEA",
        "name": "KraneShares Rockefeller Ocean Engagement ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSEP": {
        "symbol": "KSEP",
        "name": "Innovator U.S. Small Cap Power Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSM": {
        "symbol": "KSM",
        "name": "DWS Strategic Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSPY": {
        "symbol": "KSPY",
        "name": "KraneShares Hedgeye Hedged Equity Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSS": {
        "symbol": "KSS",
        "name": "Kohls Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KSTR": {
        "symbol": "KSTR",
        "name": "KraneShares SSE STAR Market 50 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KT": {
        "symbol": "KT",
        "name": "KT Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KTB": {
        "symbol": "KTB",
        "name": "Kontoor Brands, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KTEC": {
        "symbol": "KTEC",
        "name": "KraneShares Hang Seng TECH Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KTF": {
        "symbol": "KTF",
        "name": "DWS Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KTH": {
        "symbol": "KTH",
        "name": "Structured Products CorTS PECO Energy Cap Trust III, 8% Cert.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KTN": {
        "symbol": "KTN",
        "name": "Structured Products CorTS Aon Capital A, 8.205% Cert.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KUKE": {
        "symbol": "KUKE",
        "name": "Kuke Music Holding Limited American Depositary Shares, each representing one Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KULR": {
        "symbol": "KULR",
        "name": "KULR Technology Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KURE": {
        "symbol": "KURE",
        "name": "KraneShares MSCI All China Health Care Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KVACU": {
        "symbol": "KVACU",
        "name": "Keen Vision Acquisition Corporation Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KVLE": {
        "symbol": "KVLE",
        "name": "KraneShares Value Line Dynamic Dividend Equity Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KVUE": {
        "symbol": "KVUE",
        "name": "Kenvue Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KVYO": {
        "symbol": "KVYO",
        "name": "Klaviyo, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KW": {
        "symbol": "KW",
        "name": "KENNEDY-WILSON HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KWEB": {
        "symbol": "KWEB",
        "name": "KraneShares CSI China Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KWR": {
        "symbol": "KWR",
        "name": "Quaker Houghton",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KWT": {
        "symbol": "KWT",
        "name": "iShares MSCI Kuwait ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KXI": {
        "symbol": "KXI",
        "name": "iShares Global Consumer Staples ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "KYN": {
        "symbol": "KYN",
        "name": "Kayne Anderson Energy Infrastructure Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "L": {
        "symbol": "L",
        "name": "Loews Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAAC": {
        "symbol": "LAAC",
        "name": "Lithium Americas (Argentina) Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LABD": {
        "symbol": "LABD",
        "name": "Direxion Daily S&P Biotech Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LABU": {
        "symbol": "LABU",
        "name": "Direxion Daily S&P Biotech Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAC": {
        "symbol": "LAC",
        "name": "Lithium Americas Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAD": {
        "symbol": "LAD",
        "name": "Lithia Motors, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LADR": {
        "symbol": "LADR",
        "name": "LADDER CAPITAL CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LALT": {
        "symbol": "LALT",
        "name": "First Trust Multi-Strategy Alternative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LANV": {
        "symbol": "LANV",
        "name": "Lanvin Group Holdings Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LANV WS": {
        "symbol": "LANV WS",
        "name": "Lanvin Group Holdings Limited Redeemable Warrants, each whole warrant exercisable for one Ordinary S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAPR": {
        "symbol": "LAPR",
        "name": "Innovator Premium Income 15 Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAW": {
        "symbol": "LAW",
        "name": "CS Disco, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LAZ": {
        "symbol": "LAZ",
        "name": "Lazard, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LB": {
        "symbol": "LB",
        "name": "LandBridge Company LLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LBAY": {
        "symbol": "LBAY",
        "name": "Leatherback Long/Short Alternative Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LBO": {
        "symbol": "LBO",
        "name": "WHITEWOLF Publicly Listed Private Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LBRT": {
        "symbol": "LBRT",
        "name": "Liberty Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LC": {
        "symbol": "LC",
        "name": "LendingClub Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCDS": {
        "symbol": "LCDS",
        "name": "JPMorgan Fundamental Data Science Large Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCF": {
        "symbol": "LCF",
        "name": "Touchstone US Large Cap Focused ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCG": {
        "symbol": "LCG",
        "name": "Sterling Capital Focus Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCII": {
        "symbol": "LCII",
        "name": "LCI Industries",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCLG": {
        "symbol": "LCLG",
        "name": "Logan Capital Broad Innovative Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCR": {
        "symbol": "LCR",
        "name": "Leuthold Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCTD": {
        "symbol": "LCTD",
        "name": "BlackRock World ex U.S. Carbon Transition Readiness ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCTU": {
        "symbol": "LCTU",
        "name": "BlackRock U.S. Carbon Transition Readiness ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LCTX": {
        "symbol": "LCTX",
        "name": "Lineage Cell Therapeutics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDEM": {
        "symbol": "LDEM",
        "name": "iShares ESG MSCI EM Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDI": {
        "symbol": "LDI",
        "name": "loanDepot, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDOS": {
        "symbol": "LDOS",
        "name": "Leidos Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDP": {
        "symbol": "LDP",
        "name": "COHEN & STEERS LIMITED DURATION PREFERRED AND INCOME FUND INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDSF": {
        "symbol": "LDSF",
        "name": "First Trust Low Duration Strategic Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LDUR": {
        "symbol": "LDUR",
        "name": "PIMCO Enhanced Low Duration Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEA": {
        "symbol": "LEA",
        "name": "Lear Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEAD": {
        "symbol": "LEAD",
        "name": "Siren DIVCON Leaders Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEG": {
        "symbol": "LEG",
        "name": "Leggett & Platt, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEGR": {
        "symbol": "LEGR",
        "name": "First Trust Indxx Innovative Transaction & Process ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEGT": {
        "symbol": "LEGT",
        "name": "Legato Merger Corp. III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEGT U": {
        "symbol": "LEGT U",
        "name": "Legato Merger Corp III Units, each consisting of one Ordinary Share and one-half of one warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEGT WS": {
        "symbol": "LEGT WS",
        "name": "Legato Merger Corp. III Redeemable Warrants, each whole warrant exercisable for one ordinary share a",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEMB": {
        "symbol": "LEMB",
        "name": "iShares J.P. Morgan EM Local Currency Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEN": {
        "symbol": "LEN",
        "name": "Lennar Corporation Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEN B": {
        "symbol": "LEN B",
        "name": "Lennar Corporation Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEO": {
        "symbol": "LEO",
        "name": "BNY Mellon Strategic Municipals, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEU": {
        "symbol": "LEU",
        "name": "Centrus Energy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEV": {
        "symbol": "LEV",
        "name": "The Lion Electric Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEV WS": {
        "symbol": "LEV WS",
        "name": "The Lion Electric Company Redeemable Warrants, each whole warrant entitles the holder to purchase on",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEV WSA": {
        "symbol": "LEV WSA",
        "name": "The Lion Electric Company Warrants to purchase common shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEVI": {
        "symbol": "LEVI",
        "name": "Levi Strauss & Co. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LEXI": {
        "symbol": "LEXI",
        "name": "Alexis Practical Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAE": {
        "symbol": "LFAE",
        "name": "Stone Ridge 2048 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAF": {
        "symbol": "LFAF",
        "name": "Stone Ridge 2049 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAI": {
        "symbol": "LFAI",
        "name": "Stone Ridge 2050 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAJ": {
        "symbol": "LFAJ",
        "name": "Stone Ridge 2051 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAK": {
        "symbol": "LFAK",
        "name": "Stone Ridge 2052 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAL": {
        "symbol": "LFAL",
        "name": "Stone Ridge 2053 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAN": {
        "symbol": "LFAN",
        "name": "Stone Ridge 2054 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAO": {
        "symbol": "LFAO",
        "name": "Stone Ridge 2055 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAQ": {
        "symbol": "LFAQ",
        "name": "Stone Ridge 2056 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAR": {
        "symbol": "LFAR",
        "name": "Stone Ridge 2057 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAU": {
        "symbol": "LFAU",
        "name": "Stone Ridge 2058 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAV": {
        "symbol": "LFAV",
        "name": "Stone Ridge 2059 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAW": {
        "symbol": "LFAW",
        "name": "Stone Ridge 2060 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAX": {
        "symbol": "LFAX",
        "name": "Stone Ridge 2061 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFAZ": {
        "symbol": "LFAZ",
        "name": "Stone Ridge 2062 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFBB": {
        "symbol": "LFBB",
        "name": "Stone Ridge 2063 Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFEQ": {
        "symbol": "LFEQ",
        "name": "VanEck Long/Flat Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFT": {
        "symbol": "LFT",
        "name": "Lument Finance Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LFT PRA": {
        "symbol": "LFT PRA",
        "name": "Lument Finance Trust, Inc. 7.875% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGCY": {
        "symbol": "LGCY",
        "name": "Legacy Education Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGF A": {
        "symbol": "LGF A",
        "name": "Lions Gate Entertainment Corp. Class A Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGF B": {
        "symbol": "LGF B",
        "name": "Lions Gate Entertainment Corp. Class B Non-Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGH": {
        "symbol": "LGH",
        "name": "HCM Defender 500 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGHT": {
        "symbol": "LGHT",
        "name": "Langar Global HealthTech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGI": {
        "symbol": "LGI",
        "name": "Lazard Global Total Return & Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGL": {
        "symbol": "LGL",
        "name": "The LGL Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGL WS": {
        "symbol": "LGL WS",
        "name": "LGL Group Inc (The) Warrants to purchase Common Stock (expiring November 16, 2025)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGLV": {
        "symbol": "LGLV",
        "name": "SPDR SSGA US Large Cap Low Volatility Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGOV": {
        "symbol": "LGOV",
        "name": "First Trust Long Duration Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGRO": {
        "symbol": "LGRO",
        "name": "Level Four Large Cap Growth Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LGTY": {
        "symbol": "LGTY",
        "name": "Logility Supply Chain Solutions, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LH": {
        "symbol": "LH",
        "name": "Labcorp Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LHX": {
        "symbol": "LHX",
        "name": "L3Harris Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAB": {
        "symbol": "LIAB",
        "name": "Stone Ridge 2048 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAC": {
        "symbol": "LIAC",
        "name": "Stone Ridge 2049 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAE": {
        "symbol": "LIAE",
        "name": "Stone Ridge 2050 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAF": {
        "symbol": "LIAF",
        "name": "Stone Ridge 2051 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAG": {
        "symbol": "LIAG",
        "name": "Stone Ridge 2052 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAJ": {
        "symbol": "LIAJ",
        "name": "Stone Ridge 2053 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAK": {
        "symbol": "LIAK",
        "name": "Stone Ridge 2054 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAM": {
        "symbol": "LIAM",
        "name": "Stone Ridge 2055 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAO": {
        "symbol": "LIAO",
        "name": "Stone Ridge 2056 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAP": {
        "symbol": "LIAP",
        "name": "Stone Ridge 2057 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAQ": {
        "symbol": "LIAQ",
        "name": "Stone Ridge 2058 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAT": {
        "symbol": "LIAT",
        "name": "Stone Ridge 2059 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAU": {
        "symbol": "LIAU",
        "name": "Stone Ridge 2060 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAV": {
        "symbol": "LIAV",
        "name": "Stone Ridge 2061 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAW": {
        "symbol": "LIAW",
        "name": "Stone Ridge 2062 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIAX": {
        "symbol": "LIAX",
        "name": "Stone Ridge 2063 Inflation-Protected Longevity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LICY": {
        "symbol": "LICY",
        "name": "Li-Cycle Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIEN": {
        "symbol": "LIEN",
        "name": "Chicago Atlantic BDC, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LII": {
        "symbol": "LII",
        "name": "Lennox International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIMI": {
        "symbol": "LIMI",
        "name": "Themes Lithium & Battery Metal Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIT": {
        "symbol": "LIT",
        "name": "Global X Lithium & Battery Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LITB": {
        "symbol": "LITB",
        "name": "LightInTheBox Holding Co., Ltd. American Depositary Shares, each representing twelve ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LITP": {
        "symbol": "LITP",
        "name": "Sprott Lithium Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LIVR": {
        "symbol": "LIVR",
        "name": "Intelligent Livermore ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LJAN": {
        "symbol": "LJAN",
        "name": "Innovator Premium Income 15 Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LJUL": {
        "symbol": "LJUL",
        "name": "Innovator Premium Income 15 Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LKOR": {
        "symbol": "LKOR",
        "name": "FlexShares Credit-Scored US Long Corporate Bond Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LLAP": {
        "symbol": "LLAP",
        "name": "Terran Orbital Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LLDR": {
        "symbol": "LLDR",
        "name": "Global X Long-Term Treasury Ladder ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LLY": {
        "symbol": "LLY",
        "name": "Eli Lilly & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LLYX": {
        "symbol": "LLYX",
        "name": "Defiance Daily Target 2x Long LLY ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LMBO": {
        "symbol": "LMBO",
        "name": "Direxion Daily Crypto Industry Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LMBS": {
        "symbol": "LMBS",
        "name": "First Trust Exchange-Traded Fund IV First Trust Low Duration Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LMND": {
        "symbol": "LMND",
        "name": "Lemonade, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LMND WS": {
        "symbol": "LMND WS",
        "name": "Lemonade, Inc. Warrants to purchase Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LMT": {
        "symbol": "LMT",
        "name": "Lockheed Martin Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNC": {
        "symbol": "LNC",
        "name": "Lincoln National Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNC PRD": {
        "symbol": "LNC PRD",
        "name": "Lincoln National Corporation Depositary Shares Each Representing a 1/1,000th Interest in a Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LND": {
        "symbol": "LND",
        "name": "BrasilAgro - Companhia Brasileira de Propriedades Agra-colas",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNG": {
        "symbol": "LNG",
        "name": "Cheniere Energy Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNGG": {
        "symbol": "LNGG",
        "name": "Roundhill Alerian LNG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNGZ": {
        "symbol": "LNGZ",
        "name": "Range Global LNG Ecosystem Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LNN": {
        "symbol": "LNN",
        "name": "Lindsay Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOAR": {
        "symbol": "LOAR",
        "name": "Loar Holdings Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOB": {
        "symbol": "LOB",
        "name": "Live Oak Bancshares, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOCL": {
        "symbol": "LOCL",
        "name": "Local Bounti Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOCT": {
        "symbol": "LOCT",
        "name": "Innovator Premium Income 15 Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LODE": {
        "symbol": "LODE",
        "name": "Comstock Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOMA": {
        "symbol": "LOMA",
        "name": "Loma Negra Compa\u00c2\u00a4\u00c2\u00a1a Industrial Argentina Sociedad An\u00c2\u00a2nima American Depositary Shares (ADSs), each re",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LONZ": {
        "symbol": "LONZ",
        "name": "PIMCO Senior Loan Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOPP": {
        "symbol": "LOPP",
        "name": "Gabelli Love Our Planet & People ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOUP": {
        "symbol": "LOUP",
        "name": "Innovator Deepwater Frontier Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOW": {
        "symbol": "LOW",
        "name": "Lowe's Companies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LOWV": {
        "symbol": "LOWV",
        "name": "AB US Low Volatility Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LPA": {
        "symbol": "LPA",
        "name": "Logistic Properties of the Americas",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LPBBU": {
        "symbol": "LPBBU",
        "name": "Launch Two Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LPG": {
        "symbol": "LPG",
        "name": "DORIAN LPG LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LPL": {
        "symbol": "LPL",
        "name": "LG Display Co. Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LPX": {
        "symbol": "LPX",
        "name": "Louisiana-Pacific Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQAI": {
        "symbol": "LQAI",
        "name": "LG QRAFT AI-Powered U.S. Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQD": {
        "symbol": "LQD",
        "name": "iShares iBoxx $ Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQDB": {
        "symbol": "LQDB",
        "name": "iShares BBB Rated Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQDH": {
        "symbol": "LQDH",
        "name": "iShares Interest Rate Hedged Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQDI": {
        "symbol": "LQDI",
        "name": "iShares Inflation Hedged Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQDW": {
        "symbol": "LQDW",
        "name": "iShares Investment Grade Corporate Bond BuyWrite Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LQIG": {
        "symbol": "LQIG",
        "name": "SPDR MarketAxess Investment Grade 400 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRGC": {
        "symbol": "LRGC",
        "name": "AB US Large Cap Strategic Equities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRGE": {
        "symbol": "LRGE",
        "name": "ClearBridge Large Cap Growth ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRGF": {
        "symbol": "LRGF",
        "name": "iShares U.S. Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRGG": {
        "symbol": "LRGG",
        "name": "Macquarie Focused Large Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRN": {
        "symbol": "LRN",
        "name": "Stride, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRND": {
        "symbol": "LRND",
        "name": "New York Life Investments ETF Trust NYLI U.S. Large Cap R&D Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LRNZ": {
        "symbol": "LRNZ",
        "name": "TrueShares Technology, AI & Deep Learning ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSAF": {
        "symbol": "LSAF",
        "name": "LeaderShares AlphaFactor US Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSAT": {
        "symbol": "LSAT",
        "name": "LeaderShares AlphaFactor Tactical Focused ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSEQ": {
        "symbol": "LSEQ",
        "name": "Harbor Long-Short Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSF": {
        "symbol": "LSF",
        "name": "Laird Superfood, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSGR": {
        "symbol": "LSGR",
        "name": "Natixis Loomis Sayles Focused Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LSPD": {
        "symbol": "LSPD",
        "name": "Lightspeed Commerce Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LTC": {
        "symbol": "LTC",
        "name": "LTC Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LTH": {
        "symbol": "LTH",
        "name": "Life Time Group Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LTL": {
        "symbol": "LTL",
        "name": "ProShares Ultra Communication Services",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LTM": {
        "symbol": "LTM",
        "name": "LATAM Airlines Group S.A. American Depositary Shares (each representing two thousand (2,000) shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LTPZ": {
        "symbol": "LTPZ",
        "name": "PIMCO 15+ Year U.S. TIPS Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LU": {
        "symbol": "LU",
        "name": "Lufax Holding Ltd. American Depositary Shares, each representing two (2) Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LUMN": {
        "symbol": "LUMN",
        "name": "Lumen Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LUV": {
        "symbol": "LUV",
        "name": "Southwest Airlines Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LUX": {
        "symbol": "LUX",
        "name": "Tema Luxury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LUXX": {
        "symbol": "LUXX",
        "name": "Roundhill S&P Global Luxury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVHD": {
        "symbol": "LVHD",
        "name": "Franklin U.S. Low Volatility High Dividend Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVHI": {
        "symbol": "LVHI",
        "name": "Franklin International Low Volatility High Dividend Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVOL": {
        "symbol": "LVOL",
        "name": "American Century Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVS": {
        "symbol": "LVS",
        "name": "Las Vegas Sands Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVWR": {
        "symbol": "LVWR",
        "name": "LiveWire Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LVWR WS": {
        "symbol": "LVWR WS",
        "name": "LiveWire Group, Inc. Warrants, each exercisable for one share of Common Stock at an exercise price o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LW": {
        "symbol": "LW",
        "name": "Lamb Weston Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LXFR": {
        "symbol": "LXFR",
        "name": "Luxfer Holdings PLC Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LXP": {
        "symbol": "LXP",
        "name": "LXP Industrial Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LXP PRC": {
        "symbol": "LXP PRC",
        "name": "LXP Industrial Trust 6.5% Series C Cumulative Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LXU": {
        "symbol": "LXU",
        "name": "LSB INDUSTRIES INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LYB": {
        "symbol": "LYB",
        "name": "LyondellBasell Industries N.V. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LYG": {
        "symbol": "LYG",
        "name": "Lloyds Banking Group PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LYLD": {
        "symbol": "LYLD",
        "name": "Cambria Large Cap Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LYV": {
        "symbol": "LYV",
        "name": "Live Nation Entertainment Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LZB": {
        "symbol": "LZB",
        "name": "La-Z-Boy Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LZM": {
        "symbol": "LZM",
        "name": "Lifezone Metals Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "LZM WS": {
        "symbol": "LZM WS",
        "name": "Lifezone Metals Limited Warrants, each whole warrant exercisable for one Ordinary Share at an exerci",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "M": {
        "symbol": "M",
        "name": "Macy's Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MA": {
        "symbol": "MA",
        "name": "Mastercard Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAA": {
        "symbol": "MAA",
        "name": "Mid-America Apartment Communities, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAA PRI": {
        "symbol": "MAA PRI",
        "name": "Mid-America Apartment Communities, Inc. 8.50% Series I Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAC": {
        "symbol": "MAC",
        "name": "The Macerich Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MACIU": {
        "symbol": "MACIU",
        "name": "Melar Acquisition Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MADE": {
        "symbol": "MADE",
        "name": "iShares U.S. Manufacturing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAG": {
        "symbol": "MAG",
        "name": "MAG Silver Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAGA": {
        "symbol": "MAGA",
        "name": "Point Bridge America First ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAGG": {
        "symbol": "MAGG",
        "name": "Madison Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAGQ": {
        "symbol": "MAGQ",
        "name": "Roundhill Daily Inverse Magnificent Seven ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAGS": {
        "symbol": "MAGS",
        "name": "Listed Funds Trust Roundhill Magnificent Seven ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAGX": {
        "symbol": "MAGX",
        "name": "Roundhill Daily 2X Long Magnificent Seven ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAIA": {
        "symbol": "MAIA",
        "name": "MAIA Biotechnology, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAIN": {
        "symbol": "MAIN",
        "name": "Main Street Capital Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAKX": {
        "symbol": "MAKX",
        "name": "ProShares S&P Kensho Smart Factories ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAMB": {
        "symbol": "MAMB",
        "name": "Monarch Ambassador Income Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAN": {
        "symbol": "MAN",
        "name": "ManpowerGroup",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MANU": {
        "symbol": "MANU",
        "name": "MANCHESTER UNITED PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAPP": {
        "symbol": "MAPP",
        "name": "Harbor Multi Asset Explorers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARB": {
        "symbol": "MARB",
        "name": "First Trust Merger Arbitrage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARM": {
        "symbol": "MARM",
        "name": "FT Vest U.S. Equity Max Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MART": {
        "symbol": "MART",
        "name": "AllianzIM U.S. Large Cap Buffer10 Mar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARW": {
        "symbol": "MARW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Mar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARXR": {
        "symbol": "MARXR",
        "name": "Mars Acquisition Corp. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARXU": {
        "symbol": "MARXU",
        "name": "Mars Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MARZ": {
        "symbol": "MARZ",
        "name": "TrueShares Structured Outcome (March) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAS": {
        "symbol": "MAS",
        "name": "Masco Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MATV": {
        "symbol": "MATV",
        "name": "Mativ Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MATX": {
        "symbol": "MATX",
        "name": "Matsons, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAV": {
        "symbol": "MAV",
        "name": "Pioneer Municipal High Income Advantage Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAX": {
        "symbol": "MAX",
        "name": "MediaAlpha, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAXI": {
        "symbol": "MAXI",
        "name": "Simplify Bitcoin Strategy PLUS Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAXJ": {
        "symbol": "MAXJ",
        "name": "iShares Large Cap Max Buffer Jun ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAYP": {
        "symbol": "MAYP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAYT": {
        "symbol": "MAYT",
        "name": "AllianzIM U.S. Large Cap Buffer10 May ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAYU": {
        "symbol": "MAYU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped May ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAYW": {
        "symbol": "MAYW",
        "name": "AllianzIM U.S. Large Cap Buffer20 May ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MAYZ": {
        "symbol": "MAYZ",
        "name": "TrueShares Structured Outcome (May) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBB": {
        "symbol": "MBB",
        "name": "iShares MBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBBB": {
        "symbol": "MBBB",
        "name": "VanEck Moody's Analytics BBB Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBC": {
        "symbol": "MBC",
        "name": "MasterBrand, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBCC": {
        "symbol": "MBCC",
        "name": "Monarch Blue Chips Core Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBI": {
        "symbol": "MBI",
        "name": "MBIA Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBND": {
        "symbol": "MBND",
        "name": "SPDR Nuveen Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBNE": {
        "symbol": "MBNE",
        "name": "SPDR Nuveen Municipal Bond ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBOX": {
        "symbol": "MBOX",
        "name": "Freedom Day Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBS": {
        "symbol": "MBS",
        "name": "Angel Oak Mortgage-Backed Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBSD": {
        "symbol": "MBSD",
        "name": "FlexShares Disciplined Duration MBS Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MBSF": {
        "symbol": "MBSF",
        "name": "Regan Floating Rate MBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MC": {
        "symbol": "MC",
        "name": "MOELIS & COMPANY",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCAAU": {
        "symbol": "MCAAU",
        "name": "Mountain & Co. I Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCAAW": {
        "symbol": "MCAAW",
        "name": "Mountain & Co. I Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCAGR": {
        "symbol": "MCAGR",
        "name": "Mountain Crest Acquisition Corp. V Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCAGU": {
        "symbol": "MCAGU",
        "name": "Mountain Crest Acquisition Corp. V Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCB": {
        "symbol": "MCB",
        "name": "Metropolitan Bank Holding Corp. Common Stock, $0.01 par value per share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCD": {
        "symbol": "MCD",
        "name": "McDonald's Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCDS": {
        "symbol": "MCDS",
        "name": "JPMorgan Fundamental Data Science Mid Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCH": {
        "symbol": "MCH",
        "name": "Matthews China Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCHI": {
        "symbol": "MCHI",
        "name": "iShares MSCI China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCHS": {
        "symbol": "MCHS",
        "name": "Matthews China Discovery Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCI": {
        "symbol": "MCI",
        "name": "Barings Corporate Investors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCK": {
        "symbol": "MCK",
        "name": "McKesson Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCN": {
        "symbol": "MCN",
        "name": "Madison Covered Call & Equity Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCO": {
        "symbol": "MCO",
        "name": "Moody's Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCR": {
        "symbol": "MCR",
        "name": "MFS Charter Income Trust Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCS": {
        "symbol": "MCS",
        "name": "The Marcus Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCSE": {
        "symbol": "MCSE",
        "name": "Martin Currie Sustainable International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCW": {
        "symbol": "MCW",
        "name": "Mister Car Wash, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MCY": {
        "symbol": "MCY",
        "name": "Mercury General Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MD": {
        "symbol": "MD",
        "name": "Pediatrix Medical Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDCP": {
        "symbol": "MDCP",
        "name": "Victory Portfolios II VictoryShares THB Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDEV": {
        "symbol": "MDEV",
        "name": "First Trust Indxx Medical Devices ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDIV": {
        "symbol": "MDIV",
        "name": "First Trust Exchange-Traded Fund VI Multi-Asset Diversified Income Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDLV": {
        "symbol": "MDLV",
        "name": "Morgan Dempsey Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDPL": {
        "symbol": "MDPL",
        "name": "Monarch Dividend Plus Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDST": {
        "symbol": "MDST",
        "name": "Westwood Salient Enhanced Midstream Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDT": {
        "symbol": "MDT",
        "name": "Medtronic plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDU": {
        "symbol": "MDU",
        "name": "MDU Resources Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDV": {
        "symbol": "MDV",
        "name": "Modiv Industrial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDV PRA": {
        "symbol": "MDV PRA",
        "name": "Modiv Industrial, Inc. 7.375% Series A Cumulative Redeemable Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDY": {
        "symbol": "MDY",
        "name": "SPDR S&P MidCap 400 ETF Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDYG": {
        "symbol": "MDYG",
        "name": "SPDR S&P 400 Mid Cap Growth ETF (Based on S&P MidCap 400 Growth Index--symbol MUV)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MDYV": {
        "symbol": "MDYV",
        "name": "SPDR S&P 400 Mid Cap Value ETF (based on S&P MidCap 400 Value Index--symbol: MGD)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEAR": {
        "symbol": "MEAR",
        "name": "iShares Short Maturity Municipal Bond Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEC": {
        "symbol": "MEC",
        "name": "Mayville Engineering Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MED": {
        "symbol": "MED",
        "name": "Medifast, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEDI": {
        "symbol": "MEDI",
        "name": "Harbor Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEDX": {
        "symbol": "MEDX",
        "name": "Horizon Kinetics Medical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEG": {
        "symbol": "MEG",
        "name": "Montrose Environmental Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEGI": {
        "symbol": "MEGI",
        "name": "NYLI CBRE Global Infrastructure Megatrends Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEI": {
        "symbol": "MEI",
        "name": "Methode Electronics",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEM": {
        "symbol": "MEM",
        "name": "Matthews Emerging Markets Equity Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEMS": {
        "symbol": "MEMS",
        "name": "Matthews Emerging Markets Discovery Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEMX": {
        "symbol": "MEMX",
        "name": "Matthews Emerging Markets ex China Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MER PRK": {
        "symbol": "MER PRK",
        "name": "Bank of America Corporation Income Capital Obligation Notes initially due December 15, 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MET": {
        "symbol": "MET",
        "name": "MetLife, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MET PRA": {
        "symbol": "MET PRA",
        "name": "Metlife, Inc. Floating Rate Non Cuml Series A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MET PRE": {
        "symbol": "MET PRE",
        "name": "MetLife, Inc. Depositary Shares, each representing a 1/1,000th interest in a share of 5.625% Non-Cum",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MET PRF": {
        "symbol": "MET PRF",
        "name": "MetLife, Inc. Depositary Shares, each representing a 1/1,000th interest in a share of 4.75% Non-Cumu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "METD": {
        "symbol": "METD",
        "name": "Direxion Daily META Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "METU": {
        "symbol": "METU",
        "name": "Direxion Daily META Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "METV": {
        "symbol": "METV",
        "name": "Roundhill Ball Metaverse ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MEXX": {
        "symbol": "MEXX",
        "name": "Direxion Daily MSCI Mexico Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFA": {
        "symbol": "MFA",
        "name": "MFA Financial, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFA PRB": {
        "symbol": "MFA PRB",
        "name": "MFA Financial, Inc. 7.50% Series B Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFA PRC": {
        "symbol": "MFA PRC",
        "name": "MFA Financial, Inc. 6.50% Series C Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFAN": {
        "symbol": "MFAN",
        "name": "MFA Financial Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFAO": {
        "symbol": "MFAO",
        "name": "MFA Financial, Inc. 9.000% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFC": {
        "symbol": "MFC",
        "name": "Manulife Financial Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFDX": {
        "symbol": "MFDX",
        "name": "PIMCO RAFI Multi-Factor International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFEM": {
        "symbol": "MFEM",
        "name": "PIMCO RAFI Dynamic Multi-Factor Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFG": {
        "symbol": "MFG",
        "name": "Mizuho Financial Group, Inc. American Depositary Shares (Each representing one-fifth of an Ordinary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFLX": {
        "symbol": "MFLX",
        "name": "First Trust Exchange-Traded Fund VIII First Trust Flexible Municipal High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFM": {
        "symbol": "MFM",
        "name": "MFS Municipal Income Trust Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFUL": {
        "symbol": "MFUL",
        "name": "Mindful Conservative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFUS": {
        "symbol": "MFUS",
        "name": "PIMCO RAFI Dynamic Multi-Factor U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MFUT": {
        "symbol": "MFUT",
        "name": "Cambria Chesapeake Pure Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MG": {
        "symbol": "MG",
        "name": "Mistras Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGA": {
        "symbol": "MGA",
        "name": "Magna International",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGC": {
        "symbol": "MGC",
        "name": "Vanguard Mega Cap 300 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGF": {
        "symbol": "MGF",
        "name": "MFS Government Markets Income Trust Common Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGK": {
        "symbol": "MGK",
        "name": "Vanguard Mega Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGLD": {
        "symbol": "MGLD",
        "name": "The Marygold Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGM": {
        "symbol": "MGM",
        "name": "MGM RESORTS INTERNATIONAL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGMT": {
        "symbol": "MGMT",
        "name": "Ballast Small/Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGNR": {
        "symbol": "MGNR",
        "name": "American Beacon GLG Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGOV": {
        "symbol": "MGOV",
        "name": "First Trust Intermediate Government Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGR": {
        "symbol": "MGR",
        "name": "Affiliated Managers Group, Inc. 5.875% Junior Subordinated Notes due 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGRB": {
        "symbol": "MGRB",
        "name": "Affiliated Managers Group, Inc. 4.750% Junior Subordinated Notes due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGRD": {
        "symbol": "MGRD",
        "name": "Affiliated Managers Group, Inc. 4.200% Junior Subordinated Notes due 2061",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGRE": {
        "symbol": "MGRE",
        "name": "Affiliated Managers Group, Inc. 6.750% Junior Subordinated Notes due 2064",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGRO": {
        "symbol": "MGRO",
        "name": "VanEck Morningstar Wide Moat Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGV": {
        "symbol": "MGV",
        "name": "Vanguard Mega Cap  Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MGY": {
        "symbol": "MGY",
        "name": "Magnolia Oil & Gas Corporation Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHD": {
        "symbol": "MHD",
        "name": "Blackrock Muniholdings Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHF": {
        "symbol": "MHF",
        "name": "Western Asset Municipal High Income Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHH": {
        "symbol": "MHH",
        "name": "Mastech Digital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHI": {
        "symbol": "MHI",
        "name": "Pioneer Municipal High Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHK": {
        "symbol": "MHK",
        "name": "Mohawk Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHLA": {
        "symbol": "MHLA",
        "name": "Maiden Holdings, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHN": {
        "symbol": "MHN",
        "name": "BLACKROCK MUNIHOLDINGS NEW YORK QUALITY FUND, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHNC": {
        "symbol": "MHNC",
        "name": "MAIDEN HOLDINGS NORTH AMERICA, LTD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MHO": {
        "symbol": "MHO",
        "name": "M/I Homes, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MI": {
        "symbol": "MI",
        "name": "NFT Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MID": {
        "symbol": "MID",
        "name": "American Century Mid Cap Growth Impact ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIDE": {
        "symbol": "MIDE",
        "name": "Xtrackers S&P MidCap 400 ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIDU": {
        "symbol": "MIDU",
        "name": "Direxion Daily Mid Cap Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIG": {
        "symbol": "MIG",
        "name": "VanEck Moody's Analytics IG Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MILN": {
        "symbol": "MILN",
        "name": "Global X Funds Global X Millennial Consumer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIN": {
        "symbol": "MIN",
        "name": "MFS Intermediate Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MINN": {
        "symbol": "MINN",
        "name": "Mairs & Power Minnesota Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MINO": {
        "symbol": "MINO",
        "name": "PIMCO Municipal Income Opportunities Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MINT": {
        "symbol": "MINT",
        "name": "PIMCO Enhanced Short Maturity Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MINV": {
        "symbol": "MINV",
        "name": "Matthews Asia Innovators Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIO": {
        "symbol": "MIO",
        "name": "Pioneer Municipal High Income Opportunities Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIR": {
        "symbol": "MIR",
        "name": "Mirion Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MISL": {
        "symbol": "MISL",
        "name": "First Trust Indxx Aerospace & Defense ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITAU": {
        "symbol": "MITAU",
        "name": "Coliseum Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITN": {
        "symbol": "MITN",
        "name": "AG Mortgage Investment Trust, Inc. 9.500% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITP": {
        "symbol": "MITP",
        "name": "AG Mortgage Investment Trust, Inc. 9.500% Senior Notes due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITQ": {
        "symbol": "MITQ",
        "name": "Moving iMage Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITT": {
        "symbol": "MITT",
        "name": "AG MORTGAGE INVESTMENT TRUST, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITT PRA": {
        "symbol": "MITT PRA",
        "name": "AG MORTGAGE INVESTMENT TRUST, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITT PRB": {
        "symbol": "MITT PRB",
        "name": "AG MORTGAGE INVESTMENT TRUST INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MITT PRC": {
        "symbol": "MITT PRC",
        "name": "AG Mortgage Investment Trust, Inc. 8.00% Series C Fixed-to-Floating Rate Cumulative Redeemable Prefe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MIY": {
        "symbol": "MIY",
        "name": "BLACKROCK MUNIYIELD MICHIGAN QUALITY FUND, INC. i",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MJ": {
        "symbol": "MJ",
        "name": "Amplify Alternative Harvest ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MJUS": {
        "symbol": "MJUS",
        "name": "Amplify U.S. Alternative Harvest ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKAM": {
        "symbol": "MKAM",
        "name": "MKAM ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKC": {
        "symbol": "MKC",
        "name": "McCormick & Company, Incorporated Non-VTG CS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKC V": {
        "symbol": "MKC V",
        "name": "McCormick & Company, Incorporated Voting CS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKFG": {
        "symbol": "MKFG",
        "name": "Markforged Holding Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKL": {
        "symbol": "MKL",
        "name": "Markel Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MKOR": {
        "symbol": "MKOR",
        "name": "Matthews Korea Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ML": {
        "symbol": "ML",
        "name": "MoneyLion Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ML WS": {
        "symbol": "ML WS",
        "name": "MoneyLion Inc. Warrants, each whole warrant exercisable 1/30th of a share of Class A common stock at",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLDR": {
        "symbol": "MLDR",
        "name": "Global X Intermediate-Term Treasury Ladder ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLI": {
        "symbol": "MLI",
        "name": "Mueller Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLM": {
        "symbol": "MLM",
        "name": "Martin Marietta Materials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLN": {
        "symbol": "MLN",
        "name": "VanEck Long Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLNK": {
        "symbol": "MLNK",
        "name": "MeridianLink, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLP": {
        "symbol": "MLP",
        "name": "Maui Land & Pineapple Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLPA": {
        "symbol": "MLPA",
        "name": "Global X MLP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLPB": {
        "symbol": "MLPB",
        "name": "ETRACS Alerian MLP Infrastructure Index ETN Series B due April 2, 2040",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLPD": {
        "symbol": "MLPD",
        "name": "Global X MLP & Energy Infrastructure Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLPR": {
        "symbol": "MLPR",
        "name": "ETRACS Quarterly Pay 1.5X Leveraged Alerian MLP Index ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLPX": {
        "symbol": "MLPX",
        "name": "Global X MLP & Energy Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLR": {
        "symbol": "MLR",
        "name": "Miller Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MLSS": {
        "symbol": "MLSS",
        "name": "Milestone Scientific, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMA": {
        "symbol": "MMA",
        "name": "Alta Global Group Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMC": {
        "symbol": "MMC",
        "name": "Marsh & McLennan Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMCA": {
        "symbol": "MMCA",
        "name": "NYLI MacKay California Muni Intermediate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMD": {
        "symbol": "MMD",
        "name": "NYLI MacKay DefinedTerm Muni Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMI": {
        "symbol": "MMI",
        "name": "MARCUS & MILLICHAP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMIN": {
        "symbol": "MMIN",
        "name": "NYLI MacKay Muni Insured ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMIT": {
        "symbol": "MMIT",
        "name": "NYLI MacKay Muni Intermediate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMKT": {
        "symbol": "MMKT",
        "name": "Texas Capital Government Money Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMLG": {
        "symbol": "MMLG",
        "name": "First Trust Multi-Manager Large Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMM": {
        "symbol": "MMM",
        "name": "3M Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMS": {
        "symbol": "MMS",
        "name": "MAXIMUS, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMSC": {
        "symbol": "MMSC",
        "name": "First Trust Multi-Manager Small Cap Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMT": {
        "symbol": "MMT",
        "name": "MFS Multimarket Income Trust Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMTM": {
        "symbol": "MMTM",
        "name": "SPDR S&P 1500 Momentum Tilt ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMU": {
        "symbol": "MMU",
        "name": "Western Asset Managed Municipals Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MMVWW": {
        "symbol": "MMVWW",
        "name": "MultiMetaVerse Holdings Limited Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNA": {
        "symbol": "MNA",
        "name": "NYLI Merger Arbitrage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNBD": {
        "symbol": "MNBD",
        "name": "ALPS Intermediate Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNR": {
        "symbol": "MNR",
        "name": "Mach Natural Resources LP Common Units representing Limited Partner Interests",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNSO": {
        "symbol": "MNSO",
        "name": "MINISO Group Holding Limited American Depositary Shares, each representing four Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNTL": {
        "symbol": "MNTL",
        "name": "Tema Neuroscience and Mental Health ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNTN": {
        "symbol": "MNTN",
        "name": "Everest Consolidator Acquisition Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNTN U": {
        "symbol": "MNTN U",
        "name": "Everest Consolidator Acquisition Corporation Units, each consisting of one share of Class A common s",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MNTN WS": {
        "symbol": "MNTN WS",
        "name": "Everest Consolidator Acquisition Corporation Warrants, each whole warrant exercisable for one share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MO": {
        "symbol": "MO",
        "name": "Altria Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOAT": {
        "symbol": "MOAT",
        "name": "VanEck Morningstar Wide Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOBBW": {
        "symbol": "MOBBW",
        "name": "Mobilicom Limited Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOBXW": {
        "symbol": "MOBXW",
        "name": "Mobix Labs, Inc Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOD": {
        "symbol": "MOD",
        "name": "Modine Manufacturing Co",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MODG": {
        "symbol": "MODG",
        "name": "Topgolf Callaway Brands Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MODL": {
        "symbol": "MODL",
        "name": "VictoryShares WestEnd U.S. Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOG A": {
        "symbol": "MOG A",
        "name": "Moog Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOG B": {
        "symbol": "MOG B",
        "name": "MOOG INC CL B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOGU": {
        "symbol": "MOGU",
        "name": "MOGU Inc. American Depositary Shares, each representing three-hundred (300) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOH": {
        "symbol": "MOH",
        "name": "Molina Healthcare, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOO": {
        "symbol": "MOO",
        "name": "VanEck Agribusiness ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOOD": {
        "symbol": "MOOD",
        "name": "Relative Sentiment Tactical Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MORT": {
        "symbol": "MORT",
        "name": "VanEck Mortgage REIT Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOS": {
        "symbol": "MOS",
        "name": "The Mosaic Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOTE": {
        "symbol": "MOTE",
        "name": "VanEck Morningstar ESG Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOTG": {
        "symbol": "MOTG",
        "name": "VanEck Morningstar Global Wide Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOTI": {
        "symbol": "MOTI",
        "name": "VanEck Morningstar International Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOTO": {
        "symbol": "MOTO",
        "name": "SmartETFs Smart Transportation & Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MOV": {
        "symbol": "MOV",
        "name": "Movado Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MP": {
        "symbol": "MP",
        "name": "MP Materials Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPA": {
        "symbol": "MPA",
        "name": "BLACKROCK MUNIYIELD PENNSYLVANIA QUALITY FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPAY": {
        "symbol": "MPAY",
        "name": "Akros Monthly Payout ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPC": {
        "symbol": "MPC",
        "name": "MARATHON PETROLEUM CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPLN": {
        "symbol": "MPLN",
        "name": "MultiPlan Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPLX": {
        "symbol": "MPLX",
        "name": "MPLX LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPRO": {
        "symbol": "MPRO",
        "name": "Monarch ProCap Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPTI": {
        "symbol": "MPTI",
        "name": "M-tron Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPU": {
        "symbol": "MPU",
        "name": "Mega Matrix Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPV": {
        "symbol": "MPV",
        "name": "Barings Participation Investors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPW": {
        "symbol": "MPW",
        "name": "Medical Properties Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MPX": {
        "symbol": "MPX",
        "name": "Marine Products Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MQQQ": {
        "symbol": "MQQQ",
        "name": "Tradr 2X Long Triple Q Monthly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MQT": {
        "symbol": "MQT",
        "name": "Blackrock Muni Yield Quality Fund II, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MQY": {
        "symbol": "MQY",
        "name": "Blackrock Muni Yield Quality Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRAD": {
        "symbol": "MRAD",
        "name": "SmartETFs Advertising & Marketing Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRC": {
        "symbol": "MRC",
        "name": "MRC GLOBAL INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRCP": {
        "symbol": "MRCP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRGR": {
        "symbol": "MRGR",
        "name": "ProShares Merger ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRK": {
        "symbol": "MRK",
        "name": "Merck & Co., Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRNY": {
        "symbol": "MRNY",
        "name": "YieldMax MRNA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRO": {
        "symbol": "MRO",
        "name": "Marathon Oil Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRSK": {
        "symbol": "MRSK",
        "name": "Toews Agility Shares Managed Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MRT": {
        "symbol": "MRT",
        "name": "Marti Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS": {
        "symbol": "MS",
        "name": "Morgan Stanley",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRA": {
        "symbol": "MS PRA",
        "name": "Morgan Stanley Floating Rate non-cumulative Preferred Series A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRE": {
        "symbol": "MS PRE",
        "name": "MORGAN STANLEY DEP SHARES EACH REPRESENTING 1/1,000TH OF SHARE OF FIXED TO FLOATING RATE SER E",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRF": {
        "symbol": "MS PRF",
        "name": "MORGAN STANLEY SERIES F",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRI": {
        "symbol": "MS PRI",
        "name": "MORGAN STANLEY  Depositary Shares, each representing 1/1,000th of a share of Fixed-to-Floating Rate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRK": {
        "symbol": "MS PRK",
        "name": "Morgan Stanley Depositary Shares, each representing 1/1,000th of a share of Fixed-to-Floating Rate N",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRL": {
        "symbol": "MS PRL",
        "name": "Morgan Stanley Depositary Shares, each representing 1/1,000th of a share of 4.875% Non-Cumulative Pr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRO": {
        "symbol": "MS PRO",
        "name": "Morgan Stanley Depositary Shares, each representing 1/1,000th of a share of 4.250% Non-Cumulative Pr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRP": {
        "symbol": "MS PRP",
        "name": "Morgan Stanley Depositary Shares, each representing 1/1,000th of a share of 6.500% Non-Cumulative Pr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MS PRQ": {
        "symbol": "MS PRQ",
        "name": "Morgan Stanley Depositary Shares, each representing 1/1,000th of a share of 6.625% Non-Cumulative Pr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSA": {
        "symbol": "MSA",
        "name": "Mine Safety Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSB": {
        "symbol": "MSB",
        "name": "Mesabi Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSC": {
        "symbol": "MSC",
        "name": "Studio City International Holdings Limited American depositary shares, each representing four Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSCI": {
        "symbol": "MSCI",
        "name": "MSCI, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSD": {
        "symbol": "MSD",
        "name": "Morgan Stanley Emerging Markets Debt Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSDL": {
        "symbol": "MSDL",
        "name": "Morgan Stanley Direct Lending Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFD": {
        "symbol": "MSFD",
        "name": "Direxion Daily MSFT Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFL": {
        "symbol": "MSFL",
        "name": "GraniteShares 2x Long MSFT Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFO": {
        "symbol": "MSFO",
        "name": "YieldMax MSFT Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFU": {
        "symbol": "MSFU",
        "name": "Direxion Shares ETF Trust Direxion Daily MSFT Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFX": {
        "symbol": "MSFX",
        "name": "T-Rex 2X Long Microsoft Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSFY": {
        "symbol": "MSFY",
        "name": "Kurv Yield Premium Strategy Microsoft (MSFT) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSGE": {
        "symbol": "MSGE",
        "name": "Madison Square Garden Entertainment Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSGS": {
        "symbol": "MSGS",
        "name": "Madison Square Garden Sports Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSI": {
        "symbol": "MSI",
        "name": "Motorola Solutions, Inc. New",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSM": {
        "symbol": "MSM",
        "name": "MSC Industrial Direct Co., Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSMR": {
        "symbol": "MSMR",
        "name": "McElhenny Sheffield Managed Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSN": {
        "symbol": "MSN",
        "name": "Emerson Radio Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSOS": {
        "symbol": "MSOS",
        "name": "AdvisorShares Pure US Cannabis ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSOX": {
        "symbol": "MSOX",
        "name": "AdvisorShares MSOS 2X Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSSAR": {
        "symbol": "MSSAR",
        "name": "Metal Sky Star Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSSS": {
        "symbol": "MSSS",
        "name": "Monarch Select Subsector Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTB": {
        "symbol": "MSTB",
        "name": "LHA Market State Tactical Beta ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTI": {
        "symbol": "MSTI",
        "name": "Madison Short-Term Strategic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTQ": {
        "symbol": "MSTQ",
        "name": "LHA Market State Tactical Q ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTU": {
        "symbol": "MSTU",
        "name": "T-Rex 2X Long MSTR Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTX": {
        "symbol": "MSTX",
        "name": "Defiance Daily Target 1.75x Long MSTR ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTY": {
        "symbol": "MSTY",
        "name": "YieldMax MSTR Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSTZ": {
        "symbol": "MSTZ",
        "name": "T-Rex 2X Inverse MSTR Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MSVX": {
        "symbol": "MSVX",
        "name": "LHA Market State Alpha Seeker ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MT": {
        "symbol": "MT",
        "name": "ArcelorMittal",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTA": {
        "symbol": "MTA",
        "name": "Metalla Royalty & Streaming Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTAL": {
        "symbol": "MTAL",
        "name": "Metals Acquisition Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTB": {
        "symbol": "MTB",
        "name": "M&T Bank Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTB PRH": {
        "symbol": "MTB PRH",
        "name": "M&T Bank Corporation Perpetual Fixed-to-Floating Rate Non- Cumulative Preferred Stock, Series H",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTB PRJ": {
        "symbol": "MTB PRJ",
        "name": "M&T Bank Corporation Depositary Shares each representing a 1/400th ownership interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTBA": {
        "symbol": "MTBA",
        "name": "Simplify MBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTD": {
        "symbol": "MTD",
        "name": "Mettler-Toledo International",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTDR": {
        "symbol": "MTDR",
        "name": "MATADOR RESOURCES COMPANY",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTEST": {
        "symbol": "MTEST",
        "name": "NYSE Chicago Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTEST A": {
        "symbol": "MTEST A",
        "name": "NYSE CHICAGO TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTG": {
        "symbol": "MTG",
        "name": "MGIC Investment Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTGP": {
        "symbol": "MTGP",
        "name": "WisdomTree Mortgage Plus Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTH": {
        "symbol": "MTH",
        "name": "Meritage Homes Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTN": {
        "symbol": "MTN",
        "name": "Vail Resorts, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTNB": {
        "symbol": "MTNB",
        "name": "Matinas BioPharma Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTR": {
        "symbol": "MTR",
        "name": "Mesa Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTRN": {
        "symbol": "MTRN",
        "name": "Materion Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTUL": {
        "symbol": "MTUL",
        "name": "ETRACS 2x Leveraged MSCI US Momentum Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTUM": {
        "symbol": "MTUM",
        "name": "iShares MSCI USA Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTUS": {
        "symbol": "MTUS",
        "name": "Metallus Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTW": {
        "symbol": "MTW",
        "name": "The Manitowoc Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTX": {
        "symbol": "MTX",
        "name": "Minerals Technologies Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MTZ": {
        "symbol": "MTZ",
        "name": "MasTec, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUA": {
        "symbol": "MUA",
        "name": "Blackrock Muni Assets Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUB": {
        "symbol": "MUB",
        "name": "iShares National Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUC": {
        "symbol": "MUC",
        "name": "BLACKROCK MUNIHOLDINGS CALIFORNIA QUALITY FUND, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUD": {
        "symbol": "MUD",
        "name": "Direxion Daily MU Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUE": {
        "symbol": "MUE",
        "name": "BLACKROCK MUNIHOLDINGS QUALITY FUND II, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUFG": {
        "symbol": "MUFG",
        "name": "Mitsubishi UFJ Financial Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUI": {
        "symbol": "MUI",
        "name": "BlackRock Municipal Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUJ": {
        "symbol": "MUJ",
        "name": "BLACKROCK MUNIHOLDINGS NEW JERSEY QUALITY FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUNI": {
        "symbol": "MUNI",
        "name": "PIMCO Intermediate Municipal Bond Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUR": {
        "symbol": "MUR",
        "name": "Murphy Oil Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUSA": {
        "symbol": "MUSA",
        "name": "MURPHY USA INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUSI": {
        "symbol": "MUSI",
        "name": "American Century Multisector Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUSQ": {
        "symbol": "MUSQ",
        "name": "MUSQ Global Music Industry ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUST": {
        "symbol": "MUST",
        "name": "Columbia Multi-Sector Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUU": {
        "symbol": "MUU",
        "name": "Direxion Daily MU Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MUX": {
        "symbol": "MUX",
        "name": "McEwen Mining Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVAL": {
        "symbol": "MVAL",
        "name": "VanEck Morningstar Wide Moat Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVF": {
        "symbol": "MVF",
        "name": "BlackRock MuniVest Fund, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVFD": {
        "symbol": "MVFD",
        "name": "Monarch Volume Factor Dividend Tree Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVFG": {
        "symbol": "MVFG",
        "name": "Monarch Volume Factor Global Unconstrained Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVO": {
        "symbol": "MVO",
        "name": "MV Oil Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVPA": {
        "symbol": "MVPA",
        "name": "Miller Value Partners Appreciation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVPL": {
        "symbol": "MVPL",
        "name": "Miller Value Partners Leverage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVPS": {
        "symbol": "MVPS",
        "name": "Amplify Thematic All-Stars ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVRL": {
        "symbol": "MVRL",
        "name": "ETRACS Monthly Pay 1.5X Leveraged Mortgage REIT ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVT": {
        "symbol": "MVT",
        "name": "Blackrock Munivest Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MVV": {
        "symbol": "MVV",
        "name": "ProShares Ultra MidCap400",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MWA": {
        "symbol": "MWA",
        "name": "Mueller Water Products, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MWG": {
        "symbol": "MWG",
        "name": "Multi Ways Holdings Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MX": {
        "symbol": "MX",
        "name": "Magnachip Semiconductor Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MXC": {
        "symbol": "MXC",
        "name": "Mexco Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MXE": {
        "symbol": "MXE",
        "name": "The Mexico Equity and Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MXF": {
        "symbol": "MXF",
        "name": "MEXICO FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MXI": {
        "symbol": "MXI",
        "name": "iShares Global Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCF": {
        "symbol": "MYCF",
        "name": "SPDR SSGA My2026 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCG": {
        "symbol": "MYCG",
        "name": "SPDR SSGA My2027 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCH": {
        "symbol": "MYCH",
        "name": "SPDR SSGA My2028 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCI": {
        "symbol": "MYCI",
        "name": "SPDR SSGA My2029 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCJ": {
        "symbol": "MYCJ",
        "name": "SPDR SSGA My2030 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCK": {
        "symbol": "MYCK",
        "name": "SPDR SSGA My2031 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCL": {
        "symbol": "MYCL",
        "name": "SPDR SSGA My2032 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCM": {
        "symbol": "MYCM",
        "name": "SPDR SSGA My2033 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYCN": {
        "symbol": "MYCN",
        "name": "SPDR SSGA My2034 Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYD": {
        "symbol": "MYD",
        "name": "Blackrock Muniyield Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYE": {
        "symbol": "MYE",
        "name": "Myers Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYI": {
        "symbol": "MYI",
        "name": "BLACKROCK MUNIYIELD QUALITY FUND III, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYLD": {
        "symbol": "MYLD",
        "name": "Cambria Micro and SmallCap Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYMF": {
        "symbol": "MYMF",
        "name": "SPDR SSGA My2026 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYMG": {
        "symbol": "MYMG",
        "name": "SPDR SSGA My2027 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYMH": {
        "symbol": "MYMH",
        "name": "SPDR SSGA My2028 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYMI": {
        "symbol": "MYMI",
        "name": "SPDR SSGA My2029 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYMJ": {
        "symbol": "MYMJ",
        "name": "SPDR SSGA My2030 Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYN": {
        "symbol": "MYN",
        "name": "BLACKROCK MUNIYIELD NEW YORK QUALITY FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYND": {
        "symbol": "MYND",
        "name": "Mynd.ai, Inc. American Depositary Shares, each representing ten (10) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYO": {
        "symbol": "MYO",
        "name": "Myomo Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYPSW": {
        "symbol": "MYPSW",
        "name": "PLAYSTUDIOS, Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYTE": {
        "symbol": "MYTE",
        "name": "MYT Netherlands Parent B.V. American Depositary Shares, each representing one Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MYY": {
        "symbol": "MYY",
        "name": "ProShares Short MidCap400",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "MZZ": {
        "symbol": "MZZ",
        "name": "ProShares UltraShort MidCap400",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NABL": {
        "symbol": "NABL",
        "name": "N-able, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAC": {
        "symbol": "NAC",
        "name": "Nuveen California Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NACP": {
        "symbol": "NACP",
        "name": "Impact Shares NAACP Minority Empowerment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAD": {
        "symbol": "NAD",
        "name": "Nuveen Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAIL": {
        "symbol": "NAIL",
        "name": "Direxion Daily Homebuilders & Supplies Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAK": {
        "symbol": "NAK",
        "name": "Northern Dynasty Minerals, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAN": {
        "symbol": "NAN",
        "name": "Nuveen New York Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NANC": {
        "symbol": "NANC",
        "name": "Unusual Whales Subversive Democratic Trading ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NANR": {
        "symbol": "NANR",
        "name": "SPDR S&P North American Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAPA": {
        "symbol": "NAPA",
        "name": "The Duckhorn Portfolio, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAPR": {
        "symbol": "NAPR",
        "name": "Innovator Growth-100 Power Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAT": {
        "symbol": "NAT",
        "name": "Nordic American Tanker",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NATL": {
        "symbol": "NATL",
        "name": "NCR Atleos Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAUG": {
        "symbol": "NAUG",
        "name": "Innovator Growth-100 Power Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NAZ": {
        "symbol": "NAZ",
        "name": "Nuveen Arizona Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBB": {
        "symbol": "NBB",
        "name": "Nuveen Taxable Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBCC": {
        "symbol": "NBCC",
        "name": "Neuberger Berman Next Generation Connected Consumer ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBCE": {
        "symbol": "NBCE",
        "name": "Neuberger Berman China Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBCM": {
        "symbol": "NBCM",
        "name": "Neuberger Berman Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBCR": {
        "symbol": "NBCR",
        "name": "Neuberger Berman Core Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBCT": {
        "symbol": "NBCT",
        "name": "Neuberger Berman Carbon Transition & Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBDS": {
        "symbol": "NBDS",
        "name": "Neuberger Berman Disrupters ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBFC": {
        "symbol": "NBFC",
        "name": "Neuberger Berman Flexible Credit Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBGR": {
        "symbol": "NBGR",
        "name": "Neuberger Berman Global Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBH": {
        "symbol": "NBH",
        "name": "Neuberger Berman Municipal Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBHC": {
        "symbol": "NBHC",
        "name": "NATIONAL BANK HOLDINGS CORP.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBIS": {
        "symbol": "NBIS",
        "name": "Nebius Group N.V. Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBJP": {
        "symbol": "NBJP",
        "name": "Neuberger Berman Japan Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBOS": {
        "symbol": "NBOS",
        "name": "Neuberger Berman Option Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBR": {
        "symbol": "NBR",
        "name": "Nabors Industries Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBSD": {
        "symbol": "NBSD",
        "name": "Neuberger Berman Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBSM": {
        "symbol": "NBSM",
        "name": "Neuberger Berman Small-Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBXG": {
        "symbol": "NBXG",
        "name": "Neuberger Berman Next Generation Connectivity Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NBY": {
        "symbol": "NBY",
        "name": "NovaBay Pharmaceuticals, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NC": {
        "symbol": "NC",
        "name": "NACCO Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCA": {
        "symbol": "NCA",
        "name": "Nuveen California Municipal Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCDL": {
        "symbol": "NCDL",
        "name": "Nuveen Churchill Direct Lending Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCL": {
        "symbol": "NCL",
        "name": "Northann Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCLH": {
        "symbol": "NCLH",
        "name": "Norwegian Cruise Line Holdings Ltd. Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCNCW": {
        "symbol": "NCNCW",
        "name": "noco-noco Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCPB": {
        "symbol": "NCPB",
        "name": "Nuveen Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCPLW": {
        "symbol": "NCPLW",
        "name": "Netcapital Inc. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCV": {
        "symbol": "NCV",
        "name": "Virtus Convertible & Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCV PRA": {
        "symbol": "NCV PRA",
        "name": "Virtus Convertible & Income Fund 5.625% Series A Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCZ": {
        "symbol": "NCZ",
        "name": "Virtus Convertible & Income Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NCZ PRA": {
        "symbol": "NCZ PRA",
        "name": "Virtus Convertible & Income Fund II 5.50% Series A Cumulative Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDIA": {
        "symbol": "NDIA",
        "name": "Global X India Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDIV": {
        "symbol": "NDIV",
        "name": "Amplify Natural Resources Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDMO": {
        "symbol": "NDMO",
        "name": "Nuveen Dynamic Municipal Opportunities Fund Common Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDOW": {
        "symbol": "NDOW",
        "name": "Anydrus Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDP": {
        "symbol": "NDP",
        "name": "TORTOISE ENERGY INDEPENDENCE FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NDVG": {
        "symbol": "NDVG",
        "name": "Nuveen Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NE": {
        "symbol": "NE",
        "name": "Noble Corporation plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NE WS": {
        "symbol": "NE WS",
        "name": "Noble Corporation plc Tranche 1 Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NE WSA": {
        "symbol": "NE WSA",
        "name": "Noble Corporation plc Tranche 2 Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEA": {
        "symbol": "NEA",
        "name": "Nuveen AMT-Free Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEAR": {
        "symbol": "NEAR",
        "name": "iShares Short Duration Bond Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEE": {
        "symbol": "NEE",
        "name": "Nextra Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEE PRN": {
        "symbol": "NEE PRN",
        "name": "NextEra Energy Capital Holdings, Inc. Series N Junior Subordinated Debentures due March 1, 2079",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEE PRR": {
        "symbol": "NEE PRR",
        "name": "NextEra Energy, Inc. 6.926% Corporate Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEE PRS": {
        "symbol": "NEE PRS",
        "name": "NextEra Energy, Inc. 7.299% Corporate Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEM": {
        "symbol": "NEM",
        "name": "Newmont Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEN": {
        "symbol": "NEN",
        "name": "New England Realty Associates Limited Partnership",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEOVW": {
        "symbol": "NEOVW",
        "name": "NeoVolta Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEP": {
        "symbol": "NEP",
        "name": "NextEra Energy Partners, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NERD": {
        "symbol": "NERD",
        "name": "Roundhill Video Games ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NET": {
        "symbol": "NET",
        "name": "Cloudflare, Inc. Class A common stock, par value $0.001 per share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NETDU": {
        "symbol": "NETDU",
        "name": "Nabors Energy Transition Corp. II Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NETL": {
        "symbol": "NETL",
        "name": "NETLease Corporate Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NETZ": {
        "symbol": "NETZ",
        "name": "TCW Transform Systems ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEU": {
        "symbol": "NEU",
        "name": "NewMarket Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEUE": {
        "symbol": "NEUE",
        "name": "NeueHealth, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEWP": {
        "symbol": "NEWP",
        "name": "New Pacific Metals Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEWZ": {
        "symbol": "NEWZ",
        "name": "StockSnips AI-Powered Sentiment US All Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NEXA": {
        "symbol": "NEXA",
        "name": "Nexa Resources S.A. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFG": {
        "symbol": "NFG",
        "name": "National Fuel Gas Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFGC": {
        "symbol": "NFGC",
        "name": "New Found Gold Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFJ": {
        "symbol": "NFJ",
        "name": "Virtus Dividend, Interest & Premium Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFLP": {
        "symbol": "NFLP",
        "name": "Kurv Yield Premium Strategy Netflix (NFLX) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFLT": {
        "symbol": "NFLT",
        "name": "Virtus Newfleet Multi-Sector Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFLU": {
        "symbol": "NFLU",
        "name": "T-Rex 2X Long NFLX Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFLY": {
        "symbol": "NFLY",
        "name": "YieldMax NFLX Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFRA": {
        "symbol": "NFRA",
        "name": "FlexShares STOXX Global Broad Infrastructure Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFTY": {
        "symbol": "NFTY",
        "name": "First Trust India Nifty 50 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFXL": {
        "symbol": "NFXL",
        "name": "Direxion Daily NFLX Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NFXS": {
        "symbol": "NFXS",
        "name": "Direxion Daily NFLX Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NG": {
        "symbol": "NG",
        "name": "NovaGold Resources Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGD": {
        "symbol": "NGD",
        "name": "New Gold Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGG": {
        "symbol": "NGG",
        "name": "National Grid PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGL": {
        "symbol": "NGL",
        "name": "NGL ENERGY PARTNERS LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGL PRB": {
        "symbol": "NGL PRB",
        "name": "NGL Energy Partners LP 9.00% Class B Fixed-to-Floating Rate Cumulative Redeemable Perpetual Preferre",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGL PRC": {
        "symbol": "NGL PRC",
        "name": "NGL Energy Partners LP 9.625% Class C Fixed-to-Floating Rate Cumulative Redeemable Perpetual Preferr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGS": {
        "symbol": "NGS",
        "name": "Natural Gas Services Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGVC": {
        "symbol": "NGVC",
        "name": "NATURAL GROCERS BY VITAMIN COTTAGE, INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NGVT": {
        "symbol": "NGVT",
        "name": "Ingevity Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NHC": {
        "symbol": "NHC",
        "name": "National Healthcare Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NHI": {
        "symbol": "NHI",
        "name": "National Health Investors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NHS": {
        "symbol": "NHS",
        "name": "Neuberger Berman High Yield Strategies Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NI": {
        "symbol": "NI",
        "name": "NiSource Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIC": {
        "symbol": "NIC",
        "name": "Nicolet Bankshares,Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIE": {
        "symbol": "NIE",
        "name": "Virtus Equity & Convertible Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIKL": {
        "symbol": "NIKL",
        "name": "Sprott Nickel Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIM": {
        "symbol": "NIM",
        "name": "Nuveen Select Maturities Municipal Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NINE": {
        "symbol": "NINE",
        "name": "Nine Energy Service, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIO": {
        "symbol": "NIO",
        "name": "NIO Inc. American depositary shares, each representing one Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NITE": {
        "symbol": "NITE",
        "name": "The Nightview Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIXT": {
        "symbol": "NIXT",
        "name": "Research Affiliates Deletions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIXX": {
        "symbol": "NIXX",
        "name": "Nixxy, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NIXXW": {
        "symbol": "NIXXW",
        "name": "Nixxy, Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NJAN": {
        "symbol": "NJAN",
        "name": "Innovator Growth-100 Power Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NJNK": {
        "symbol": "NJNK",
        "name": "Columbia U.S. High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NJR": {
        "symbol": "NJR",
        "name": "New Jersey Resources Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NJUL": {
        "symbol": "NJUL",
        "name": "Innovator Growth-100 Power Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NJUN": {
        "symbol": "NJUN",
        "name": "Innovator Growth-100 Power Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NKE": {
        "symbol": "NKE",
        "name": "Nike, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NKX": {
        "symbol": "NKX",
        "name": "Nuveen California AMT-Free Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NL": {
        "symbol": "NL",
        "name": "NL Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLOP": {
        "symbol": "NLOP",
        "name": "Net Lease Office Properties",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLR": {
        "symbol": "NLR",
        "name": "VanEck Uranium and Nuclear ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLY": {
        "symbol": "NLY",
        "name": "Annaly Capital Management. Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLY PRF": {
        "symbol": "NLY PRF",
        "name": "Annaly Capital Management, Inc. 6.95% Series F Fixed-to-Floating Rate Cumulative Redeemable Preferre",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLY PRG": {
        "symbol": "NLY PRG",
        "name": "Annaly Capital Management, Inc. 6.50% Series G Fixed-to-Floating Rate Cumulative Redeemable Preferre",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NLY PRI": {
        "symbol": "NLY PRI",
        "name": "Annaly Capital Management, Inc. 6.750% Series I Fixed-to-Floating Rate Cumulative Redeemable Preferr",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMAI": {
        "symbol": "NMAI",
        "name": "Nuveen Multi-Asset Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMB": {
        "symbol": "NMB",
        "name": "Simplify National Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMCO": {
        "symbol": "NMCO",
        "name": "Nuveen Municipal Credit Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMG": {
        "symbol": "NMG",
        "name": "Nouveau Monde Graphite Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMI": {
        "symbol": "NMI",
        "name": "Nuveen Municipal Income",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NML": {
        "symbol": "NML",
        "name": "Neuberger Berman Energy Infrastructure and Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMM": {
        "symbol": "NMM",
        "name": "Navios Maritime Partners L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMR": {
        "symbol": "NMR",
        "name": "Nomura Holdings, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMS": {
        "symbol": "NMS",
        "name": "Nuveen Minnesota Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMT": {
        "symbol": "NMT",
        "name": "Nuveen Massachusetts Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NMZ": {
        "symbol": "NMZ",
        "name": "Nuveen Municipal High Income Opportunity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NNI": {
        "symbol": "NNI",
        "name": "Nelnet, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NNN": {
        "symbol": "NNN",
        "name": "NNN REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NNVC": {
        "symbol": "NNVC",
        "name": "NanoViricides Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NNY": {
        "symbol": "NNY",
        "name": "Nuveen New York Municipal Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOA": {
        "symbol": "NOA",
        "name": "North American Construction Group Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOAH": {
        "symbol": "NOAH",
        "name": "Noah Holdings Limited American Depositary Shares (each representing five (5) Ordinary shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOBL": {
        "symbol": "NOBL",
        "name": "ProShares S&P 500 Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOC": {
        "symbol": "NOC",
        "name": "Northrop Grumman Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOCT": {
        "symbol": "NOCT",
        "name": "Innovator Growth-100 Power Buffer ETF- October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOG": {
        "symbol": "NOG",
        "name": "Northern Oil and Gas, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOK": {
        "symbol": "NOK",
        "name": "Nokia Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOM": {
        "symbol": "NOM",
        "name": "Nuveen Missouri Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOMD": {
        "symbol": "NOMD",
        "name": "Nomad Foods Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NORW": {
        "symbol": "NORW",
        "name": "Global X MSCI Norway ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOTE": {
        "symbol": "NOTE",
        "name": "FiscalNote Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOTE WS": {
        "symbol": "NOTE WS",
        "name": "FiscalNote Holdings, Inc. Warrants to purchase one share of Class A common stock, each at an exercis",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOV": {
        "symbol": "NOV",
        "name": "NOV Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVA": {
        "symbol": "NOVA",
        "name": "Sunnova Energy International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVP": {
        "symbol": "NOVP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVV": {
        "symbol": "NOVV",
        "name": "Nova Vision Acquisition Corp. Ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVVR": {
        "symbol": "NOVVR",
        "name": "Nova Vision Acquisition Corp. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVVU": {
        "symbol": "NOVVU",
        "name": "Nova Vision Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVVW": {
        "symbol": "NOVVW",
        "name": "Nova Vision Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOVZ": {
        "symbol": "NOVZ",
        "name": "TrueShares Structured Outcome (November) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NOW": {
        "symbol": "NOW",
        "name": "SERVICENOW, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPCT": {
        "symbol": "NPCT",
        "name": "Nuveen Core Plus Impact Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPFD": {
        "symbol": "NPFD",
        "name": "Nuveen Variable Rate Preferred & Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPFI": {
        "symbol": "NPFI",
        "name": "Nuveen Preferred and Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPK": {
        "symbol": "NPK",
        "name": "National Presto Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPO": {
        "symbol": "NPO",
        "name": "Enpro Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPV": {
        "symbol": "NPV",
        "name": "Nuveen Virginia Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPWR": {
        "symbol": "NPWR",
        "name": "NET Power Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NPWR WS": {
        "symbol": "NPWR WS",
        "name": "NET Power Inc. Redeemable warrants, each whole warrant exercisable for one share of Class A Common S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NQP": {
        "symbol": "NQP",
        "name": "Nuveen Pennsylvania Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NR": {
        "symbol": "NR",
        "name": "Newpark Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRDY": {
        "symbol": "NRDY",
        "name": "Nerdy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NREF": {
        "symbol": "NREF",
        "name": "NexPoint Real Estate Finance, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NREF PRA": {
        "symbol": "NREF PRA",
        "name": "NexPoint Real Estate Finance, Inc. 8.50% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRES": {
        "symbol": "NRES",
        "name": "Xtrackers RREEF Global Natural Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRG": {
        "symbol": "NRG",
        "name": "NRG Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRGV": {
        "symbol": "NRGV",
        "name": "Energy Vault Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRK": {
        "symbol": "NRK",
        "name": "Nuveen New York AMT-Free Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRO": {
        "symbol": "NRO",
        "name": "Neuberger Berman Real Estate Sec. Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRP": {
        "symbol": "NRP",
        "name": "Natural Resource Partners L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRSH": {
        "symbol": "NRSH",
        "name": "Aztlan North America Nearshoring Stock Selection ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRT": {
        "symbol": "NRT",
        "name": "North European Oil Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRUC": {
        "symbol": "NRUC",
        "name": "National Rural Utilities Cooperative Finance Corporation 5.500% Subordinated Notes due 2064 (Subordi",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NRXS": {
        "symbol": "NRXS",
        "name": "Neuraxis, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSA": {
        "symbol": "NSA",
        "name": "National Storage Affiliates Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSA PRA": {
        "symbol": "NSA PRA",
        "name": "National Storage Affiliates Trust 6.000 Percent Series A Cumulative Redeemable Preferred Shares of B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSA PRB": {
        "symbol": "NSA PRB",
        "name": "National Storage Affiliates Trust 6.000% Series B Cumulative Redeemable Preferred Shares of Benefici",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSC": {
        "symbol": "NSC",
        "name": "Norfolk Southern Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSCR": {
        "symbol": "NSCR",
        "name": "Nuveen Sustainable Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSCS": {
        "symbol": "NSCS",
        "name": "Nuveen Small Cap Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSEP": {
        "symbol": "NSEP",
        "name": "Innovator Growth-100 Power Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSI": {
        "symbol": "NSI",
        "name": "National Security Emerging Markets Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NSP": {
        "symbol": "NSP",
        "name": "Insperity, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTB": {
        "symbol": "NTB",
        "name": "The Bank of N.T. Butterfield & Son Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST": {
        "symbol": "NTEST",
        "name": "NYSE Test Ticker",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST G": {
        "symbol": "NTEST G",
        "name": "NYSE Pillar Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST H": {
        "symbol": "NTEST H",
        "name": "Dedicated Test Symbol for Citadel",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST I": {
        "symbol": "NTEST I",
        "name": "Dedicated Test Symbol for Citadel",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST J": {
        "symbol": "NTEST J",
        "name": "Dedicated Test Symbol for IMC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST K": {
        "symbol": "NTEST K",
        "name": "Dedicated Test Symbol for IMC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST L": {
        "symbol": "NTEST L",
        "name": "Dedicated Test Symbol for GTS Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST M": {
        "symbol": "NTEST M",
        "name": "Dedicated Test Symbol for GTS Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST N": {
        "symbol": "NTEST N",
        "name": "Dedicated Test Symbol for Virtu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST O": {
        "symbol": "NTEST O",
        "name": "Dedicated Test Symbol for Virtu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST P": {
        "symbol": "NTEST P",
        "name": "Dedicated Test Symbol for Cryan",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST Q": {
        "symbol": "NTEST Q",
        "name": "Dedicated Test Symbol for Cryan",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST Y": {
        "symbol": "NTEST Y",
        "name": "NY Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTEST Z": {
        "symbol": "NTEST Z",
        "name": "NYSE TEST SYMBOL SIFMA / DTCC Eligible",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTG": {
        "symbol": "NTG",
        "name": "Tortoise Midstream Energy Fund, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTIP": {
        "symbol": "NTIP",
        "name": "Network-1 Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTR": {
        "symbol": "NTR",
        "name": "Nutrien Ltd. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTSE": {
        "symbol": "NTSE",
        "name": "WisdomTree Emerging Markets Efficient Core Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTSI": {
        "symbol": "NTSI",
        "name": "WisdomTree International Efficient Core Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTST": {
        "symbol": "NTST",
        "name": "NetSTREIT Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTSX": {
        "symbol": "NTSX",
        "name": "WisdomTree U.S. Efficient Core Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTZ": {
        "symbol": "NTZ",
        "name": "Natuzzi, S.p.A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NTZG": {
        "symbol": "NTZG",
        "name": "Nuveen Global Net Zero Transition ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NU": {
        "symbol": "NU",
        "name": "Nu Holdings Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUAG": {
        "symbol": "NUAG",
        "name": "Nuveen Enhanced Yield U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUBD": {
        "symbol": "NUBD",
        "name": "Nuveen ESG U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUDM": {
        "symbol": "NUDM",
        "name": "Nuveen ESG International Developed Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUDV": {
        "symbol": "NUDV",
        "name": "Nuveen ESG Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUE": {
        "symbol": "NUE",
        "name": "Nucor Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUEM": {
        "symbol": "NUEM",
        "name": "Nuveen ESG Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUGO": {
        "symbol": "NUGO",
        "name": "Nuveen Growth Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUGT": {
        "symbol": "NUGT",
        "name": "Direxion Daily Gold Miners Index Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUHY": {
        "symbol": "NUHY",
        "name": "Nuveen ESG High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUKZ": {
        "symbol": "NUKZ",
        "name": "Range Nuclear Renaissance Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NULC": {
        "symbol": "NULC",
        "name": "Nuveen ESG Large-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NULG": {
        "symbol": "NULG",
        "name": "Nuveen ESG Large-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NULV": {
        "symbol": "NULV",
        "name": "Nuveen ESG Large-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUMG": {
        "symbol": "NUMG",
        "name": "Nuveen ESG Mid-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUMV": {
        "symbol": "NUMV",
        "name": "Nuveen ESG Mid-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NURE": {
        "symbol": "NURE",
        "name": "Nuveen Short-Term REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUS": {
        "symbol": "NUS",
        "name": "NuSkin Enterprises, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUSA": {
        "symbol": "NUSA",
        "name": "Nuveen ESG 1-5 Year U.S. Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUSB": {
        "symbol": "NUSB",
        "name": "Nuveen Ultra Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUSC": {
        "symbol": "NUSC",
        "name": "Nuveen ESG Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUSI": {
        "symbol": "NUSI",
        "name": "Nationwide Nasdaq-100 Risk-Managed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUV": {
        "symbol": "NUV",
        "name": "Nuveen Municipal Value Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUVB": {
        "symbol": "NUVB",
        "name": "Nuvation Bio Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUVB WS": {
        "symbol": "NUVB WS",
        "name": "Nuvation Bio Inc. Redeemable warrants, each whole warrant exercisable for one share of Class A commo",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NUW": {
        "symbol": "NUW",
        "name": "Nuveen AMT-Free Municipal Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVACW": {
        "symbol": "NVACW",
        "name": "NorthView Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVBT": {
        "symbol": "NVBT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Nov ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVBW": {
        "symbol": "NVBW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Nov ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVD": {
        "symbol": "NVD",
        "name": "GraniteShares ETF Trust GraniteShares 2x Short NVDA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDD": {
        "symbol": "NVDD",
        "name": "Direxion Daily NVDA Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDL": {
        "symbol": "NVDL",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long NVDA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDQ": {
        "symbol": "NVDQ",
        "name": "T-Rex 2X Inverse NVIDIA Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDS": {
        "symbol": "NVDS",
        "name": "Investment Managers Series Trust II Tradr 1.5X Short NVDA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDU": {
        "symbol": "NVDU",
        "name": "Direxion Shares ETF Trust Direxion Daily NVDA Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDW": {
        "symbol": "NVDW",
        "name": "Tradr 1.75X Long NVDA Weekly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDX": {
        "symbol": "NVDX",
        "name": "T-Rex 2X Long NVIDIA Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVDY": {
        "symbol": "NVDY",
        "name": "YieldMax NVDA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVG": {
        "symbol": "NVG",
        "name": "Nuveen AMT-Free Municipal Credit Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVGS": {
        "symbol": "NVGS",
        "name": "NAVIGATOR HOLDINGS LTD.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVIR": {
        "symbol": "NVIR",
        "name": "Horizon Kinetics Energy and Remediation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVO": {
        "symbol": "NVO",
        "name": "Novo-Nordisk A/S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVR": {
        "symbol": "NVR",
        "name": "NVR, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVRI": {
        "symbol": "NVRI",
        "name": "Enviri Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVRO": {
        "symbol": "NVRO",
        "name": "NEVRO CORP.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVS": {
        "symbol": "NVS",
        "name": "Novartis AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVST": {
        "symbol": "NVST",
        "name": "Envista Holdings Corporation Common stock, $0.01 par value per share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NVT": {
        "symbol": "NVT",
        "name": "nVent Electric plc Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NWG": {
        "symbol": "NWG",
        "name": "NatWest Group plc American Depositary Shares, (each representing two (2) Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NWLG": {
        "symbol": "NWLG",
        "name": "Nuveen Winslow Large-Cap Growth ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NWN": {
        "symbol": "NWN",
        "name": "Northwest Natural Holding Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NX": {
        "symbol": "NX",
        "name": "Quanex Building Products Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXC": {
        "symbol": "NXC",
        "name": "NUVEEN CA SEL TAX-FREE INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXDT": {
        "symbol": "NXDT",
        "name": "NexPoint Diversified Real Estate Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXDT PRA": {
        "symbol": "NXDT PRA",
        "name": "NexPoint Diversified Real Estate Trust 5.50% Series A Cumulative Preferred Shares ($25.00 liquidatio",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXE": {
        "symbol": "NXE",
        "name": "NexGen Energy Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXG": {
        "symbol": "NXG",
        "name": "NXG NextGen Infrastructure Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXJ": {
        "symbol": "NXJ",
        "name": "Nuveen New Jersey Quality Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXLIW": {
        "symbol": "NXLIW",
        "name": "Nexalin Technology, Inc. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXN": {
        "symbol": "NXN",
        "name": "NUVEEN NY SEL TAX-FREE INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXP": {
        "symbol": "NXP",
        "name": "NUVEEN SELECT TAX-FREE INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXRT": {
        "symbol": "NXRT",
        "name": "NexPoint Residential Trust Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXTE": {
        "symbol": "NXTE",
        "name": "AXS Green Alpha ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXTG": {
        "symbol": "NXTG",
        "name": "First Trust Indxx NextG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXTI": {
        "symbol": "NXTI",
        "name": "Simplify Next Intangible Core Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NXTV": {
        "symbol": "NXTV",
        "name": "Simplify Next Intangible Value Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYC": {
        "symbol": "NYC",
        "name": "American Strategic Investment Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYCB": {
        "symbol": "NYCB",
        "name": "New York Community Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYCB PRA": {
        "symbol": "NYCB PRA",
        "name": "New York Community Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYCB PRU": {
        "symbol": "NYCB PRU",
        "name": "New York Community Capital Trust V (BONUSES)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYF": {
        "symbol": "NYF",
        "name": "iShares New York Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NYT": {
        "symbol": "NYT",
        "name": "New York Times Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NZAC": {
        "symbol": "NZAC",
        "name": "SPDR MSCI ACWI Climate Paris Aligned ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NZF": {
        "symbol": "NZF",
        "name": "Nuveen Municipal Credit Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "NZUS": {
        "symbol": "NZUS",
        "name": "SPDR MSCI USA Climate Paris Aligned ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "O": {
        "symbol": "O",
        "name": "Realty Income Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OACP": {
        "symbol": "OACP",
        "name": "OneAscent Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAEM": {
        "symbol": "OAEM",
        "name": "OneAscent Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAIM": {
        "symbol": "OAIM",
        "name": "OneAscent International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAK PRA": {
        "symbol": "OAK PRA",
        "name": "Brookfield Oaktree Holdings, LLC 6.625% Series A Preferred units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAK PRB": {
        "symbol": "OAK PRB",
        "name": "Brookfield Oaktree Holdings, LLC 6.550% Series B Preferred Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAKUU": {
        "symbol": "OAKUU",
        "name": "Oak Woods Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OAKUW": {
        "symbol": "OAKUW",
        "name": "Oak Woods Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OALC": {
        "symbol": "OALC",
        "name": "OneAscent Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OARK": {
        "symbol": "OARK",
        "name": "YieldMax Innovation Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OASC": {
        "symbol": "OASC",
        "name": "OneAscent Small Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBDC": {
        "symbol": "OBDC",
        "name": "Blue Owl Capital Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBDE": {
        "symbol": "OBDE",
        "name": "Blue Owl Capital Corporation III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBE": {
        "symbol": "OBE",
        "name": "Obsidian Energy Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBIL": {
        "symbol": "OBIL",
        "name": "US Treasury 12 Month Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBK": {
        "symbol": "OBK",
        "name": "Origin Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBND": {
        "symbol": "OBND",
        "name": "SPDR Loomis Sayles Opportunistic Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OBOR": {
        "symbol": "OBOR",
        "name": "Kraneshares MSCI One Belt One Road Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OC": {
        "symbol": "OC",
        "name": "Owens Corning",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCCIM": {
        "symbol": "OCCIM",
        "name": "OFS Credit Company, Inc. 7.875% Series F Term Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCEN": {
        "symbol": "OCEN",
        "name": "NYLI Clean Oceans ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCFS": {
        "symbol": "OCFS",
        "name": "Otter Creek Focus Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCFT": {
        "symbol": "OCFT",
        "name": "OneConnect Financial Technology Co., Ltd. American Depositary Shares, each representing thirty (30)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCIO": {
        "symbol": "OCIO",
        "name": "ClearShares OCIO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTA": {
        "symbol": "OCTA",
        "name": "Octane All-Cap Value Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTD": {
        "symbol": "OCTD",
        "name": "Innovator Premium Income 10 Barrier ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTH": {
        "symbol": "OCTH",
        "name": "Innovator Premium Income 20 Barrier ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTJ": {
        "symbol": "OCTJ",
        "name": "Innovator Premium Income 30 Barrier ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTP": {
        "symbol": "OCTP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTQ": {
        "symbol": "OCTQ",
        "name": "Innovator Premium Income 40 Barrier ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTT": {
        "symbol": "OCTT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Oct ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTU": {
        "symbol": "OCTU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped Oct ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTW": {
        "symbol": "OCTW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Oct ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OCTZ": {
        "symbol": "OCTZ",
        "name": "TrueShares Structured Outcome (October) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ODC": {
        "symbol": "ODC",
        "name": "Oil-Dri Corporation of America",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ODDS": {
        "symbol": "ODDS",
        "name": "Pacer BlueStar Digital Entertainment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ODV": {
        "symbol": "ODV",
        "name": "Osisko Development Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OEC": {
        "symbol": "OEC",
        "name": "Orion S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OEF": {
        "symbol": "OEF",
        "name": "iShares S&P 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OEUR": {
        "symbol": "OEUR",
        "name": "ALPS O'Shares Europe Quality Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OFG": {
        "symbol": "OFG",
        "name": "OFG BANCORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OFOS": {
        "symbol": "OFOS",
        "name": "Range Global Offshore Oil Services Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGCP": {
        "symbol": "OGCP",
        "name": "Empire State Realty OP, L.P. Series 60",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGE": {
        "symbol": "OGE",
        "name": "OGE Energy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGEN": {
        "symbol": "OGEN",
        "name": "Oragenics Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGIG": {
        "symbol": "OGIG",
        "name": "ALPS  O'Shares Global Internet Giants ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGN": {
        "symbol": "OGN",
        "name": "Organon & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGS": {
        "symbol": "OGS",
        "name": "ONE GAS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OGSP": {
        "symbol": "OGSP",
        "name": "Obra High Grade Structured Products ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OHI": {
        "symbol": "OHI",
        "name": "Omega Healthcare Investors Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OI": {
        "symbol": "OI",
        "name": "O-I Glass, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OIA": {
        "symbol": "OIA",
        "name": "INVESCO MUNICIPAL INCOME OPPORTUNITIES TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OIH": {
        "symbol": "OIH",
        "name": "VanEck Oil Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OII": {
        "symbol": "OII",
        "name": "Oceaneering International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OILD": {
        "symbol": "OILD",
        "name": "MicroSectors Oil & Gas Exp. & Prod. -3x Inverse Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OILK": {
        "symbol": "OILK",
        "name": "ProShares K-1 Free Crude Oil ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OILT": {
        "symbol": "OILT",
        "name": "Texas Capital Texas Oil Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OILU": {
        "symbol": "OILU",
        "name": "MicroSectors Oil & Gas Exp. & Prod. 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OIS": {
        "symbol": "OIS",
        "name": "OIL STATES INTERNATIONAL, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OKE": {
        "symbol": "OKE",
        "name": "Oneok, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OKLO": {
        "symbol": "OKLO",
        "name": "Oklo Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OKUR": {
        "symbol": "OKUR",
        "name": "OnKure Therapeutics, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OLN": {
        "symbol": "OLN",
        "name": "Olin Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OLO": {
        "symbol": "OLO",
        "name": "Olo Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OLP": {
        "symbol": "OLP",
        "name": "One Liberty Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMC": {
        "symbol": "OMC",
        "name": "Omnicom Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMCC": {
        "symbol": "OMCC",
        "name": "Old Market Capital Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMF": {
        "symbol": "OMF",
        "name": "OneMain Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMFL": {
        "symbol": "OMFL",
        "name": "Invesco Russell 1000 Dynamic Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMFS": {
        "symbol": "OMFS",
        "name": "Invesco Russell 2000 Dynamic Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OMI": {
        "symbol": "OMI",
        "name": "Owens & Minor, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OND": {
        "symbol": "OND",
        "name": "ProShares On-Demand ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONEO": {
        "symbol": "ONEO",
        "name": "SPDR Russell 1000 Momentum Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONEQ": {
        "symbol": "ONEQ",
        "name": "Fidelity Nasdaq Composite Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONEV": {
        "symbol": "ONEV",
        "name": "SPDR Russell 1000 Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONEY": {
        "symbol": "ONEY",
        "name": "SPDR Russell 1000 Yield Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONIT": {
        "symbol": "ONIT",
        "name": "Onity Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONL": {
        "symbol": "ONL",
        "name": "Orion Office REIT Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONLN": {
        "symbol": "ONLN",
        "name": "ProShares Online Retail ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONMDW": {
        "symbol": "ONMDW",
        "name": "OneMedNet Corp Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONOF": {
        "symbol": "ONOF",
        "name": "Global X Adaptive U.S. Risk Management ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONON": {
        "symbol": "ONON",
        "name": "On Holding AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONTF": {
        "symbol": "ONTF",
        "name": "ON24, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONTO": {
        "symbol": "ONTO",
        "name": "Onto Innovation Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONYXU": {
        "symbol": "ONYXU",
        "name": "Onyx Acquisition Co. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ONYXW": {
        "symbol": "ONYXW",
        "name": "Onyx Acquisition Co. I Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OOMA": {
        "symbol": "OOMA",
        "name": "Ooma, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OOSP": {
        "symbol": "OOSP",
        "name": "Obra Opportunistic Structured Products ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OOTO": {
        "symbol": "OOTO",
        "name": "Direxion Daily Travel & Vacation Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPAD": {
        "symbol": "OPAD",
        "name": "Offerpad Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPER": {
        "symbol": "OPER",
        "name": "ClearShares Ultra-Short Maturity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPFI": {
        "symbol": "OPFI",
        "name": "OppFi Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPFI WS": {
        "symbol": "OPFI WS",
        "name": "OppFi Inc. Warrants, each whole warrant exercisable for one share of Class A common stock at an exer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPP": {
        "symbol": "OPP",
        "name": "RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPP PRA": {
        "symbol": "OPP PRA",
        "name": "RiverNorth/DoubleLine Strategic Opportunity Fund, Inc. 4.375% Series A Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPP PRB": {
        "symbol": "OPP PRB",
        "name": "RiverNorth/DoubleLine Strategic Opportunity Fund, Inc.4.75% Series B Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPTT": {
        "symbol": "OPTT",
        "name": "Ocean Power Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPTZ": {
        "symbol": "OPTZ",
        "name": "Optimize Strategy Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OPY": {
        "symbol": "OPY",
        "name": "Oppenheimer Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OR": {
        "symbol": "OR",
        "name": "Osisko Gold Royalties Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORA": {
        "symbol": "ORA",
        "name": "Ormat Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORAN": {
        "symbol": "ORAN",
        "name": "Orange",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORC": {
        "symbol": "ORC",
        "name": "Orchid Island Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORCL": {
        "symbol": "ORCL",
        "name": "Oracle Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORI": {
        "symbol": "ORI",
        "name": "Old Republic International Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORLA": {
        "symbol": "ORLA",
        "name": "Orla Mining Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ORN": {
        "symbol": "ORN",
        "name": "Orion Group Holdings, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OSCR": {
        "symbol": "OSCR",
        "name": "Oscar Health, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OSCV": {
        "symbol": "OSCV",
        "name": "Opus Small Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OSEA": {
        "symbol": "OSEA",
        "name": "Harbor International Compounders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OSK": {
        "symbol": "OSK",
        "name": "Oshkosh Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OSTX": {
        "symbol": "OSTX",
        "name": "OS Therapies Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OTIS": {
        "symbol": "OTIS",
        "name": "Otis Worldwide Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUNZ": {
        "symbol": "OUNZ",
        "name": "VanEck Merk Gold ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUSA": {
        "symbol": "OUSA",
        "name": "ALPS  O'Shares U.S. Quality Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUSM": {
        "symbol": "OUSM",
        "name": "ALPS  O'Shares U.S. Small-Cap Quality Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUST": {
        "symbol": "OUST",
        "name": "Ouster, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUST WS": {
        "symbol": "OUST WS",
        "name": "Ouster, Inc. Warrants, every 10 warrants are exercisable for one share of Common Stock at an exercis",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUST WSA": {
        "symbol": "OUST WSA",
        "name": "Ouster, Inc. Warrants, every 10 warrants are exercisable for 0.6153 shares of Common Stock at an exe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OUT": {
        "symbol": "OUT",
        "name": "OUTFRONT Media Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVB": {
        "symbol": "OVB",
        "name": "Overlay Shares Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVF": {
        "symbol": "OVF",
        "name": "Overlay Shares Foreign Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVL": {
        "symbol": "OVL",
        "name": "Overlay Shares Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVLH": {
        "symbol": "OVLH",
        "name": "Overlay Shares Hedged Large Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVM": {
        "symbol": "OVM",
        "name": "Overlay Shares Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVS": {
        "symbol": "OVS",
        "name": "Overlay Shares Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVT": {
        "symbol": "OVT",
        "name": "Overlay Shares Short Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OVV": {
        "symbol": "OVV",
        "name": "Ovintiv Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OWL": {
        "symbol": "OWL",
        "name": "Blue Owl Capital Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OWLT": {
        "symbol": "OWLT",
        "name": "Owlet, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OWNS": {
        "symbol": "OWNS",
        "name": "CCM Affordable Housing MBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OXBRW": {
        "symbol": "OXBRW",
        "name": "Oxbridge Re Holdings Limited Warrant expiring 3/26/2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OXM": {
        "symbol": "OXM",
        "name": "Oxford Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OXY": {
        "symbol": "OXY",
        "name": "Occidental Petroleum Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OXY WS": {
        "symbol": "OXY WS",
        "name": "Occidental Petroleum Corporation Warrants to Purchase Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OZ": {
        "symbol": "OZ",
        "name": "Belpointe PREP, LLC Class A Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "OZEM": {
        "symbol": "OZEM",
        "name": "Roundhill GLP-1 & Weight Loss ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAAA": {
        "symbol": "PAAA",
        "name": "PGIM AAA CLO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAAS": {
        "symbol": "PAAS",
        "name": "Pan American Silver Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAB": {
        "symbol": "PAB",
        "name": "PGIM Active Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PABD": {
        "symbol": "PABD",
        "name": "iShares Paris-Aligned Climate MSCI World ex USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PABU": {
        "symbol": "PABU",
        "name": "iShares Paris-Aligned Climate MSCI USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAC": {
        "symbol": "PAC",
        "name": "Grupo Aeroportuario del Pacifico, S.A.B. de C.V. Amer. Dep. Shares (each rep. 10 Ser. B shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PACK": {
        "symbol": "PACK",
        "name": "Ranpak Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PACS": {
        "symbol": "PACS",
        "name": "PACS Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAG": {
        "symbol": "PAG",
        "name": "Penske Automotive Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAGS": {
        "symbol": "PAGS",
        "name": "PagSeguro Digital Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAI": {
        "symbol": "PAI",
        "name": "Western Asset Investment Grade Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PALC": {
        "symbol": "PALC",
        "name": "Pacer Lunt Large Cap Multi-Factor Alternator ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PALL": {
        "symbol": "PALL",
        "name": "abrdn Physical Palladium Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAM": {
        "symbol": "PAM",
        "name": "PAMPA ENERGIA S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAMC": {
        "symbol": "PAMC",
        "name": "Pacer Lunt MidCap Multi-Factor Alternator ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAPI": {
        "symbol": "PAPI",
        "name": "Parametric Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAPL": {
        "symbol": "PAPL",
        "name": "Pineapple Financial Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAPR": {
        "symbol": "PAPR",
        "name": "Innovator U.S. Equity Power Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAR": {
        "symbol": "PAR",
        "name": "PAR Technology Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PARR": {
        "symbol": "PARR",
        "name": "Par Pacific Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PATH": {
        "symbol": "PATH",
        "name": "UiPath, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PATN": {
        "symbol": "PATN",
        "name": "Pacer Nasdaq International Patent Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAUG": {
        "symbol": "PAUG",
        "name": "Innovator U.S. Equity Power Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAVE": {
        "symbol": "PAVE",
        "name": "Global X U.S. Infrastructure Development ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAWZ": {
        "symbol": "PAWZ",
        "name": "ProShares Pet Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAXS": {
        "symbol": "PAXS",
        "name": "PIMCO Access Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAY": {
        "symbol": "PAY",
        "name": "Paymentus Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PAYC": {
        "symbol": "PAYC",
        "name": "PAYCOM SOFTWARE, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PB": {
        "symbol": "PB",
        "name": "Prosperity Bancshares Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBA": {
        "symbol": "PBA",
        "name": "PEMBINA PIPELINE CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBAP": {
        "symbol": "PBAP",
        "name": "PGIM US Large-Cap Buffer 20 ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBAU": {
        "symbol": "PBAU",
        "name": "PGIM US Large-Cap Buffer 20 ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBD": {
        "symbol": "PBD",
        "name": "Invesco Global Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBDC": {
        "symbol": "PBDC",
        "name": "Putnam BDC Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBDE": {
        "symbol": "PBDE",
        "name": "PGIM US Large-Cap Buffer 20 ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBE": {
        "symbol": "PBE",
        "name": "Invesco Biotechnology & Genome ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBF": {
        "symbol": "PBF",
        "name": "PBF ENERGY INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBFB": {
        "symbol": "PBFB",
        "name": "PGIM US Large-Cap Buffer 20 ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBFR": {
        "symbol": "PBFR",
        "name": "PGIM Laddered Fund of Buffer 20 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBH": {
        "symbol": "PBH",
        "name": "Prestige Consumer Healthcare Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBI": {
        "symbol": "PBI",
        "name": "Pitney Bowes Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBI PRB": {
        "symbol": "PBI PRB",
        "name": "PITNEY BOWES INC .6.70% Notes Due 2043,",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBJ": {
        "symbol": "PBJ",
        "name": "Invesco Food & Beverage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBJA": {
        "symbol": "PBJA",
        "name": "PGIM US Large-Cap Buffer 20 ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBJL": {
        "symbol": "PBJL",
        "name": "PGIM US Large-Cap Buffer 20 ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBJN": {
        "symbol": "PBJN",
        "name": "PGIM US Large-Cap Buffer 20 ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBL": {
        "symbol": "PBL",
        "name": "PGIM Portfolio Ballast ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBMR": {
        "symbol": "PBMR",
        "name": "PGIM US Large-Cap Buffer 20 ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBMY": {
        "symbol": "PBMY",
        "name": "PGIM US Large-Cap Buffer 20 ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBNV": {
        "symbol": "PBNV",
        "name": "PGIM US Large-Cap Buffer 20 ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBOC": {
        "symbol": "PBOC",
        "name": "PGIM US Large-Cap Buffer 20 ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBP": {
        "symbol": "PBP",
        "name": "Invesco S and P 500 BuyWrite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBR": {
        "symbol": "PBR",
        "name": "PETROLEO BRASILEIRO S.A.-PETROBRAS ADS (REP 1 COMMON SHARE)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBR A": {
        "symbol": "PBR A",
        "name": "Petroleo Brasileiro S.A.-Petrobras",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBSE": {
        "symbol": "PBSE",
        "name": "PGIM US Large-Cap Buffer 20 ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBT": {
        "symbol": "PBT",
        "name": "Permian Basin Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBTP": {
        "symbol": "PBTP",
        "name": "Invesco 0-5 Yr US TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBUS": {
        "symbol": "PBUS",
        "name": "Invesco MSCI USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PBW": {
        "symbol": "PBW",
        "name": "Invesco WilderHill Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCCE": {
        "symbol": "PCCE",
        "name": "Polen Capital China Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCEF": {
        "symbol": "PCEF",
        "name": "Invesco CEF Income Composite ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCEM": {
        "symbol": "PCEM",
        "name": "Polen Capital Emerging Markets ex-China Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCF": {
        "symbol": "PCF",
        "name": "High Income Securities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG": {
        "symbol": "PCG",
        "name": "PG&E Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRA": {
        "symbol": "PCG PRA",
        "name": "Pacific Gas and Electric Company 6% 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRB": {
        "symbol": "PCG PRB",
        "name": "Pacific Gas and Electric Company 5 1/2% 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRC": {
        "symbol": "PCG PRC",
        "name": "Pacific Gas and Electric Company 5% 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRD": {
        "symbol": "PCG PRD",
        "name": "Pacific Gas and Electric Company 5% Red. 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRE": {
        "symbol": "PCG PRE",
        "name": "Pacific Gas and Electric Company 5% Red. 1st Pfd.A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRG": {
        "symbol": "PCG PRG",
        "name": "Pacific Gas and Electric Company 4.80% Red. 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRH": {
        "symbol": "PCG PRH",
        "name": "Pacific Gas and Electric Company 4.50% Red. 1st Pfd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCG PRI": {
        "symbol": "PCG PRI",
        "name": "Pacific Gas & Electric Company 4.36% Red 1st Pfd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCGG": {
        "symbol": "PCGG",
        "name": "Polen Capital Global Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCIG": {
        "symbol": "PCIG",
        "name": "Polen Capital International Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCK": {
        "symbol": "PCK",
        "name": "PIMCO CALIFORNIA MUNICIPAL INCOME FUND II COMMON SHARES OF BENEFICIAL INT.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCM": {
        "symbol": "PCM",
        "name": "PCM Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCN": {
        "symbol": "PCN",
        "name": "PIMCO Corporate & Income Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCOR": {
        "symbol": "PCOR",
        "name": "Procore Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCQ": {
        "symbol": "PCQ",
        "name": "Pimco California Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCRB": {
        "symbol": "PCRB",
        "name": "Putnam ESG Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PCY": {
        "symbol": "PCY",
        "name": "Invesco Emerging Markets Sovereign Debt ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PD": {
        "symbol": "PD",
        "name": "PagerDuty, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDBA": {
        "symbol": "PDBA",
        "name": "Invesco Agriculture Commodity Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDBC": {
        "symbol": "PDBC",
        "name": "Invesco Actively Managed Exch-Traded Commodity Fd Tr Invesco Optimum Yield Diversified Commodity Str",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDCC": {
        "symbol": "PDCC",
        "name": "Pearl Diver Credit Company Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDEC": {
        "symbol": "PDEC",
        "name": "Innovator U.S. Equity Power Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDI": {
        "symbol": "PDI",
        "name": "PIMCO Dynamic Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDM": {
        "symbol": "PDM",
        "name": "Piedmont Office Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDN": {
        "symbol": "PDN",
        "name": "Invesco FTSE RAFI Developed Markets ex-U.S. Small-Mid ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDO": {
        "symbol": "PDO",
        "name": "PIMCO Dynamic Income Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDP": {
        "symbol": "PDP",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDS": {
        "symbol": "PDS",
        "name": "Precision Drilling Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDT": {
        "symbol": "PDT",
        "name": "John Hancock Premium Dividend Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PDX": {
        "symbol": "PDX",
        "name": "PIMCO Dynamic Income Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEB": {
        "symbol": "PEB",
        "name": "Pebblebrook Hotel Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEB PRE": {
        "symbol": "PEB PRE",
        "name": "Pebblebrook Hotel Trust 6.375% Series E Cumulative Redeemable Preferred Shares of Beneficial Interes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEB PRF": {
        "symbol": "PEB PRF",
        "name": "Pebblebrook Hotel Trust 6.3% Series F Cumulative Redeemable Preferred Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEB PRG": {
        "symbol": "PEB PRG",
        "name": "Pebblebrook Hotel Trust 6.375% Series G Cumulative Redeemable Preferred Shares of Beneficial Interes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEB PRH": {
        "symbol": "PEB PRH",
        "name": "Pebblebrook Hotel Trust 5.700% Series H Cumulative Redeemable Preferred Shares of Beneficial Interes",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PED": {
        "symbol": "PED",
        "name": "PEDEVCO Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEG": {
        "symbol": "PEG",
        "name": "Public Service Enterprise Group Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEJ": {
        "symbol": "PEJ",
        "name": "Invesco Leisure and Entertainment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEMX": {
        "symbol": "PEMX",
        "name": "Putnam Emerging Markets ex-China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEN": {
        "symbol": "PEN",
        "name": "Penumbra, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEO": {
        "symbol": "PEO",
        "name": "Adams Natural Resources Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PERF": {
        "symbol": "PERF",
        "name": "Perfect Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PERF WS": {
        "symbol": "PERF WS",
        "name": "Perfect Corp. Warrants, each exercisable for one Class A Ordinary Share at a price of $11.50 per sha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEX": {
        "symbol": "PEX",
        "name": "ProShares Global Listed Private Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEXL": {
        "symbol": "PEXL",
        "name": "Pacer US Export Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEY": {
        "symbol": "PEY",
        "name": "Invesco High Yield Equity Dividend Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PEZ": {
        "symbol": "PEZ",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Consumer Cyclicals Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFD": {
        "symbol": "PFD",
        "name": "Flaherty & Crumrine Preferred and Income Fund Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFE": {
        "symbol": "PFE",
        "name": "Pfizer Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFEB": {
        "symbol": "PFEB",
        "name": "Innovator U.S. Equity Power Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFF": {
        "symbol": "PFF",
        "name": "iShares Trust iShares Preferred and Income Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFFA": {
        "symbol": "PFFA",
        "name": "Virtus InfraCap U.S. Preferred Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFFD": {
        "symbol": "PFFD",
        "name": "Global X U.S. Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFFL": {
        "symbol": "PFFL",
        "name": "ETRACS Monthly Pay 2xLeveraged Preferred Stock ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFFR": {
        "symbol": "PFFR",
        "name": "InfraCap REIT Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFFV": {
        "symbol": "PFFV",
        "name": "Global X Variable Rate Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFGC": {
        "symbol": "PFGC",
        "name": "Performance Food Group Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFH": {
        "symbol": "PFH",
        "name": "Prudential Financial, Inc. 4.125% Junior Subordinated Notes due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFI": {
        "symbol": "PFI",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Financial Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFIG": {
        "symbol": "PFIG",
        "name": "Invesco Fundamental Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFIX": {
        "symbol": "PFIX",
        "name": "Simplify Interest Rate Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFL": {
        "symbol": "PFL",
        "name": "PIMCO Income Strategy  Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFLD": {
        "symbol": "PFLD",
        "name": "AAM Low Duration Preferred and Income Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFLT": {
        "symbol": "PFLT",
        "name": "PennantPark Floating Rate Capital Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFM": {
        "symbol": "PFM",
        "name": "Invesco Dividend Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFN": {
        "symbol": "PFN",
        "name": "PIMCO Income Strategy Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFO": {
        "symbol": "PFO",
        "name": "Flaherty & Crumrine Preferred and Income Opportunity Fund Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFRL": {
        "symbol": "PFRL",
        "name": "PGIM Floating Rate Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFS": {
        "symbol": "PFS",
        "name": "Provident Financial Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFSI": {
        "symbol": "PFSI",
        "name": "PennyMac Financial Services, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFUT": {
        "symbol": "PFUT",
        "name": "Putnam Sustainable Future ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PFXF": {
        "symbol": "PFXF",
        "name": "VanEck Preferred Securities ex Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PG": {
        "symbol": "PG",
        "name": "Procter & Gamble Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGF": {
        "symbol": "PGF",
        "name": "Invesco Financial Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGHY": {
        "symbol": "PGHY",
        "name": "Invesco Global ex-US High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGJ": {
        "symbol": "PGJ",
        "name": "Invesco Golden Dragon China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGP": {
        "symbol": "PGP",
        "name": "PIMCO Global StockPLUS & Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGR": {
        "symbol": "PGR",
        "name": "Progressive Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGRE": {
        "symbol": "PGRE",
        "name": "PARAMOUNT GROUP, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGRO": {
        "symbol": "PGRO",
        "name": "Putnam Focused Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGRU": {
        "symbol": "PGRU",
        "name": "PropertyGuru Group Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGX": {
        "symbol": "PGX",
        "name": "Invesco Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PGZ": {
        "symbol": "PGZ",
        "name": "PRINCIPAL REAL ESTATE INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PH": {
        "symbol": "PH",
        "name": "Parker-Hannifin Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHB": {
        "symbol": "PHB",
        "name": "Invesco Fundamental High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHD": {
        "symbol": "PHD",
        "name": "Pioneer Floating Rate Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHDG": {
        "symbol": "PHDG",
        "name": "Invesco S&P 500 Downside Hedged ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHEQ": {
        "symbol": "PHEQ",
        "name": "Parametric Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHG": {
        "symbol": "PHG",
        "name": "KONINKLIJKE PHILIPS  N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHGE": {
        "symbol": "PHGE",
        "name": "BiomX Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHGE U": {
        "symbol": "PHGE U",
        "name": "BiomX Inc. Units each consisting of one share of common stock and one Warrant entitling the holder t",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHI": {
        "symbol": "PHI",
        "name": "PLDT Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHIN": {
        "symbol": "PHIN",
        "name": "PHINIA Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHK": {
        "symbol": "PHK",
        "name": "PIMCO High Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHM": {
        "symbol": "PHM",
        "name": "Pultegroup, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHO": {
        "symbol": "PHO",
        "name": "Invesco Water Resources ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHR": {
        "symbol": "PHR",
        "name": "Phreesia, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHT": {
        "symbol": "PHT",
        "name": "Pioneer High Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHX": {
        "symbol": "PHX",
        "name": "PHX Minerals Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYD": {
        "symbol": "PHYD",
        "name": "Putnam ESG High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYL": {
        "symbol": "PHYL",
        "name": "PGIM Active High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYS": {
        "symbol": "PHYS",
        "name": "Sprott Physical Gold Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYT": {
        "symbol": "PHYT",
        "name": "Pyrophyte Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYT U": {
        "symbol": "PHYT U",
        "name": "Pyrophyte Acquisition Corp. Units, each consisting of one share of Class A common stock and one-half",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PHYT WS": {
        "symbol": "PHYT WS",
        "name": "Pyrophyte Acquisition Corp. Warrants, each whole warrant exercisable for one Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PICB": {
        "symbol": "PICB",
        "name": "Invesco International Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PICK": {
        "symbol": "PICK",
        "name": "iShares MSCI Global Metals & Mining Producers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PID": {
        "symbol": "PID",
        "name": "Invesco International Dividend Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIE": {
        "symbol": "PIE",
        "name": "Invesco Exchange-Traded Fund Trust II Invesco Dorsey Wright Emerging Markets Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIFI": {
        "symbol": "PIFI",
        "name": "ClearShares Piton Intermediate Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PII": {
        "symbol": "PII",
        "name": "Polaris Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PILL": {
        "symbol": "PILL",
        "name": "Direxion Daily Pharmaceutical & Medical Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIM": {
        "symbol": "PIM",
        "name": "Putnam Master Intermediate Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIN": {
        "symbol": "PIN",
        "name": "Invesco India ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PINE": {
        "symbol": "PINE",
        "name": "Alpine Income Property Trust, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PINK": {
        "symbol": "PINK",
        "name": "Simplify Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PINS": {
        "symbol": "PINS",
        "name": "Pinterest, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIO": {
        "symbol": "PIO",
        "name": "Invesco Global Water ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIPR": {
        "symbol": "PIPR",
        "name": "Piper Sandler Companies",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIT": {
        "symbol": "PIT",
        "name": "VanEck Commodity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PIZ": {
        "symbol": "PIZ",
        "name": "Invesco Exchange-Traded Fund Trust II Invesco Dorsey Wright Developed Markets Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJAN": {
        "symbol": "PJAN",
        "name": "Innovator U.S. Equity Power Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJBF": {
        "symbol": "PJBF",
        "name": "PGIM Jennison Better Future ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJFG": {
        "symbol": "PJFG",
        "name": "PGIM Jennison Focused Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJFM": {
        "symbol": "PJFM",
        "name": "PGIM Jennison Focused Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJFV": {
        "symbol": "PJFV",
        "name": "PGIM Jennison Focused Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJIO": {
        "symbol": "PJIO",
        "name": "PGIM Jennison International Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJP": {
        "symbol": "PJP",
        "name": "Invesco Pharmaceuticals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJT": {
        "symbol": "PJT",
        "name": "PJT Partners Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJUL": {
        "symbol": "PJUL",
        "name": "Innovator U.S. Equity Power Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PJUN": {
        "symbol": "PJUN",
        "name": "Innovator U.S. Equity Power Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PK": {
        "symbol": "PK",
        "name": "Park Hotels & Resorts Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKB": {
        "symbol": "PKB",
        "name": "Invesco Building & Construction ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKE": {
        "symbol": "PKE",
        "name": "Park Aerospace Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKG": {
        "symbol": "PKG",
        "name": "Packaging Corp of America",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKST": {
        "symbol": "PKST",
        "name": "Peakstone Realty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKW": {
        "symbol": "PKW",
        "name": "Invesco BuyBack Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PKX": {
        "symbol": "PKX",
        "name": "POSCO Holdings Inc. American Depositary Shares (Each representing 1/4th of a share of Common Stock)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PL": {
        "symbol": "PL",
        "name": "Planet Labs PBC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PL WS": {
        "symbol": "PL WS",
        "name": "Planet Labs PBC Redeemable warrants, each whole warrant exercisable for one share of Class A common",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLAG": {
        "symbol": "PLAG",
        "name": "Planet Green Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLAOU": {
        "symbol": "PLAOU",
        "name": "Patria Latin American Opportunity Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLD": {
        "symbol": "PLD",
        "name": "PROLOGIS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLDR": {
        "symbol": "PLDR",
        "name": "Putnam Sustainable Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLG": {
        "symbol": "PLG",
        "name": "Platinum Group Metals LTD.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLMJU": {
        "symbol": "PLMJU",
        "name": "Plum Acquisition Corp. III Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLMJW": {
        "symbol": "PLMJW",
        "name": "Plum Acquisition Corp. III Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLNT": {
        "symbol": "PLNT",
        "name": "Planet Fitness, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLOW": {
        "symbol": "PLOW",
        "name": "DOUGLAS DYNAMICS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLTM": {
        "symbol": "PLTM",
        "name": "GraniteShares Platinum Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLTR": {
        "symbol": "PLTR",
        "name": "Palantir Technologies Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLTY": {
        "symbol": "PLTY",
        "name": "YieldMax PLTR Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLX": {
        "symbol": "PLX",
        "name": "Protalix BioTherapeutics, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PLYM": {
        "symbol": "PLYM",
        "name": "Plymouth Industrial REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PM": {
        "symbol": "PM",
        "name": "Philip Morris International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMAR": {
        "symbol": "PMAR",
        "name": "Innovator U.S. Equity Power Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMAY": {
        "symbol": "PMAY",
        "name": "Innovator U.S. Equity Power Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMBS": {
        "symbol": "PMBS",
        "name": "PIMCO Mortgage-Backed Securities Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMF": {
        "symbol": "PMF",
        "name": "PIMCO MUNICIPAL INCOME FUND COMMON SHARES OF BENEFICIAL INTERST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMIO": {
        "symbol": "PMIO",
        "name": "PGIM Municipal Income Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PML": {
        "symbol": "PML",
        "name": "PIMCO MUNICIPAL INCOME FUND II COMMON SHARES OF BENEFICIAL INT.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMM": {
        "symbol": "PMM",
        "name": "Putnam Managed Municipal Income",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMNT": {
        "symbol": "PMNT",
        "name": "Perfect Moment Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMO": {
        "symbol": "PMO",
        "name": "Putnam Municipal Opportunities Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMT": {
        "symbol": "PMT",
        "name": "PennyMac Mortgage Investment Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMT PRA": {
        "symbol": "PMT PRA",
        "name": "PennyMac Mortgage Investment Trust 8.125% Series A Fixed-to-Floating Rate Cumulative Redeemable Pref",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMT PRB": {
        "symbol": "PMT PRB",
        "name": "PennyMac Mortgage Investment Trust 8.00% Series B Fixed-to-Floating Rate Cumulative Redeemable Prefe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMT PRC": {
        "symbol": "PMT PRC",
        "name": "PennyMac Mortgage Investment Trust 6.75% Series C Cumulative Redeemable Preferred Shares of Benefici",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMTU": {
        "symbol": "PMTU",
        "name": "PennyMac Mortgage Investment Trust 8.50% Senior Notes due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PMX": {
        "symbol": "PMX",
        "name": "PIMCO Municipal Income Fund III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNC": {
        "symbol": "PNC",
        "name": "PNC Financial Services Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNF": {
        "symbol": "PNF",
        "name": "PIMCO NEW YORK MUNICIPAL INCOME FUND COMMON SHARES OF BENEFICIAL INTEREST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNI": {
        "symbol": "PNI",
        "name": "PIMCO NEW YORK MUNICIPAL INCOME FUND II COMMON SHARES OF BENEFICIAL INT.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNNT": {
        "symbol": "PNNT",
        "name": "Pennant Investment Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNOV": {
        "symbol": "PNOV",
        "name": "Innovator U.S. Equity Power Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNQI": {
        "symbol": "PNQI",
        "name": "Invesco NASDAQ Internet ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNR": {
        "symbol": "PNR",
        "name": "Pentair plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNST": {
        "symbol": "PNST",
        "name": "Pinstripes Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNST WS": {
        "symbol": "PNST WS",
        "name": "Pinstripes Holdings, Inc. Warrants, each whole warrant exercisable for one share of Class A common s",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PNW": {
        "symbol": "PNW",
        "name": "Pinnacle West Capital Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "POCT": {
        "symbol": "POCT",
        "name": "Innovator U.S. Equity Power Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "POR": {
        "symbol": "POR",
        "name": "Portland General Electric Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "POST": {
        "symbol": "POST",
        "name": "POST HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "POWA": {
        "symbol": "POWA",
        "name": "Invesco Bloomberg Pricing Power ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PP": {
        "symbol": "PP",
        "name": "The Meet Kevin Pricing Power ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPA": {
        "symbol": "PPA",
        "name": "Invesco Aerospace & Defense ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPEM": {
        "symbol": "PPEM",
        "name": "Putnam PanAgora ESG Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPG": {
        "symbol": "PPG",
        "name": "PPG Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPH": {
        "symbol": "PPH",
        "name": "VanEck Pharmaceutical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPI": {
        "symbol": "PPI",
        "name": "AXS Astoria Inflation Sensitive ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPIE": {
        "symbol": "PPIE",
        "name": "Putnam PanAgora ESG International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPL": {
        "symbol": "PPL",
        "name": "PPL Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPLT": {
        "symbol": "PPLT",
        "name": "abrdn Physical Platinum Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPT": {
        "symbol": "PPT",
        "name": "Putnam Premier Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPTY": {
        "symbol": "PPTY",
        "name": "U.S. Diversified Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PPYAU": {
        "symbol": "PPYAU",
        "name": "Papaya Growth Opportunity Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PQDI": {
        "symbol": "PQDI",
        "name": "Principal Spectrum Tax-Advantaged Dividend Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PR": {
        "symbol": "PR",
        "name": "Permian Resources Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRA": {
        "symbol": "PRA",
        "name": "ProAssurance Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRAE": {
        "symbol": "PRAE",
        "name": "PlanRock Alternative Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRAY": {
        "symbol": "PRAY",
        "name": "FIS Christian Stock Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PREF": {
        "symbol": "PREF",
        "name": "Principal Spectrum Preferred Securities Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRF": {
        "symbol": "PRF",
        "name": "Invesco FTSE RAFI US 1000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRFD": {
        "symbol": "PRFD",
        "name": "PIMCO Preferred and Capital Securities Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRFZ": {
        "symbol": "PRFZ",
        "name": "Invesco FTSE RAFI US 1500 Small-Mid ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRG": {
        "symbol": "PRG",
        "name": "PROG Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRGO": {
        "symbol": "PRGO",
        "name": "PERRIGO COMPANY PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRH": {
        "symbol": "PRH",
        "name": "Prudential Financial, Inc. 5.950% Junior Subordinated Notes due 2062",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRI": {
        "symbol": "PRI",
        "name": "PRIMERICA, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRD": {
        "symbol": "PRIF PRD",
        "name": "Priority Income Fund, Inc. 7.00% Series D Term Preferred Stock due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRF": {
        "symbol": "PRIF PRF",
        "name": "Priority Income Fund, Inc. 6.625% Series F Term Preferred Stock due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRG": {
        "symbol": "PRIF PRG",
        "name": "Priority Income Fund, Inc. 6.25% Series G Preferred Stock Due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRH": {
        "symbol": "PRIF PRH",
        "name": "Priority Income Fund, Inc. 6.00% Series H Term Preferred Stock due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRI": {
        "symbol": "PRIF PRI",
        "name": "Priority Income Fund, Inc. 6.125% Series I Term Preferred Stock due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRJ": {
        "symbol": "PRIF PRJ",
        "name": "Priority Income Fund, Inc. 6.000% Series J Term Preferred Stock due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRK": {
        "symbol": "PRIF PRK",
        "name": "Priority Income Fund, Inc. 7.000% Series K Cumulative Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIF PRL": {
        "symbol": "PRIF PRL",
        "name": "Priority Income Fund, Inc. 6.375% Series L Term Preferred Stock Due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRIM": {
        "symbol": "PRIM",
        "name": "Primoris Services Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRK": {
        "symbol": "PRK",
        "name": "Park National Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRKS": {
        "symbol": "PRKS",
        "name": "United Parks & Resorts Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRLB": {
        "symbol": "PRLB",
        "name": "PROTO LABS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRLHU": {
        "symbol": "PRLHU",
        "name": "Pearl Holdings Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRLHW": {
        "symbol": "PRLHW",
        "name": "Pearl Holdings Acquisition Corp Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRM": {
        "symbol": "PRM",
        "name": "Perimeter Solutions, SA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRMN": {
        "symbol": "PRMN",
        "name": "PlanRock Market Neutral Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRMW": {
        "symbol": "PRMW",
        "name": "Primo Water Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRN": {
        "symbol": "PRN",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Industrials Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRNT": {
        "symbol": "PRNT",
        "name": "The 3D Printing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRO": {
        "symbol": "PRO",
        "name": "Pros Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRS": {
        "symbol": "PRS",
        "name": "Prudential Financial, Inc. 5.625% Junior Subordinated Notes due 2058",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRT": {
        "symbol": "PRT",
        "name": "PermRock Royalty Trust Trust Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PRU": {
        "symbol": "PRU",
        "name": "Prudential Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA": {
        "symbol": "PSA",
        "name": "Public Storage",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRF": {
        "symbol": "PSA PRF",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 5.15% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRG": {
        "symbol": "PSA PRG",
        "name": "Public Storage Depositary Shares, Each Representing 1/1,000 of a 5.05% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRH": {
        "symbol": "PSA PRH",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 5.60% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRI": {
        "symbol": "PSA PRI",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.875% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRJ": {
        "symbol": "PSA PRJ",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.700% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRK": {
        "symbol": "PSA PRK",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.75% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRL": {
        "symbol": "PSA PRL",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.625% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRM": {
        "symbol": "PSA PRM",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.125% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRN": {
        "symbol": "PSA PRN",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 3.875% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRO": {
        "symbol": "PSA PRO",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 3.900% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRP": {
        "symbol": "PSA PRP",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.000% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRQ": {
        "symbol": "PSA PRQ",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 3.950% Cumulative Preferred  Share o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRR": {
        "symbol": "PSA PRR",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.00% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSA PRS": {
        "symbol": "PSA PRS",
        "name": "Public Storage Depositary Shares Each Representing 1/1,000 of a 4.100% Cumulative Preferred Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSBD": {
        "symbol": "PSBD",
        "name": "Palmer Square Capital BDC Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSC": {
        "symbol": "PSC",
        "name": "Principal Exchange-Traded Funds Principal U.S. Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCC": {
        "symbol": "PSCC",
        "name": "Invesco S&P SmallCap Consumer Staples ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCD": {
        "symbol": "PSCD",
        "name": "Invesco S&P SmallCap Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCE": {
        "symbol": "PSCE",
        "name": "Invesco S&P SmallCap Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCF": {
        "symbol": "PSCF",
        "name": "Invesco S&P SmallCap Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCH": {
        "symbol": "PSCH",
        "name": "Invesco S&P SmallCap Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCI": {
        "symbol": "PSCI",
        "name": "Invesco S&P SmallCap Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCJ": {
        "symbol": "PSCJ",
        "name": "Pacer Swan SOS Conservative (July) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCM": {
        "symbol": "PSCM",
        "name": "Invesco S&P SmallCap Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCQ": {
        "symbol": "PSCQ",
        "name": "Pacer Swan SOS Conservative (October) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCT": {
        "symbol": "PSCT",
        "name": "Invesco S&P SmallCap Information Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCU": {
        "symbol": "PSCU",
        "name": "Invesco Exchange-Traded Fund Trust II Invesco S&P SmallCap Utilities & Communication Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCW": {
        "symbol": "PSCW",
        "name": "Pacer Swan SOS Conservative (April) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSCX": {
        "symbol": "PSCX",
        "name": "Pacer Swan SOS Conservative (January) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSDM": {
        "symbol": "PSDM",
        "name": "PGIM Short Duration Multi-Sector Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSEC PRA": {
        "symbol": "PSEC PRA",
        "name": "Prospect Capital Corporation 5.35% Series A Fixed Rate Cumulative Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSEP": {
        "symbol": "PSEP",
        "name": "Innovator U.S. Equity Power Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSET": {
        "symbol": "PSET",
        "name": "Principal Exchange-Traded Funds Principal Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSF": {
        "symbol": "PSF",
        "name": "COHEN & STEERS SELECT PREFERRED AND INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFD": {
        "symbol": "PSFD",
        "name": "Pacer Swan SOS Flex (January) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFE": {
        "symbol": "PSFE",
        "name": "Paysafe Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFE WS": {
        "symbol": "PSFE WS",
        "name": "Paysafe Limited Warrants, exercisable for one Common Share of Paysafe Limited at a price of $138.00",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFF": {
        "symbol": "PSFF",
        "name": "Pacer Swan SOS Fund of Funds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFJ": {
        "symbol": "PSFJ",
        "name": "Pacer Swan SOS Flex (July) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFM": {
        "symbol": "PSFM",
        "name": "Pacer Swan SOS Flex (April) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSFO": {
        "symbol": "PSFO",
        "name": "Pacer Swan SOS Flex (October) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSH": {
        "symbol": "PSH",
        "name": "PGIM Short Duration High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSI": {
        "symbol": "PSI",
        "name": "Invesco Semiconductors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSIL": {
        "symbol": "PSIL",
        "name": "AdvisorShares Psychedelics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSK": {
        "symbol": "PSK",
        "name": "SPDR ICE Preferred Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSL": {
        "symbol": "PSL",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Consumer Staples Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSLV": {
        "symbol": "PSLV",
        "name": "Sprott Physical Silver Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSMD": {
        "symbol": "PSMD",
        "name": "Pacer Swan SOS Moderate (January) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSMJ": {
        "symbol": "PSMJ",
        "name": "Pacer Swan SOS Moderate (July) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSMO": {
        "symbol": "PSMO",
        "name": "Pacer Swan SOS Moderate (October) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSMR": {
        "symbol": "PSMR",
        "name": "Pacer Swan SOS Moderate (April) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSN": {
        "symbol": "PSN",
        "name": "Parsons Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSO": {
        "symbol": "PSO",
        "name": "Pearson plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSP": {
        "symbol": "PSP",
        "name": "Invesco Global Listed Private Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSQ": {
        "symbol": "PSQ",
        "name": "ProShares Short QQQ",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSQA": {
        "symbol": "PSQA",
        "name": "Palmer Square CLO Senior Debt ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSQH": {
        "symbol": "PSQH",
        "name": "PSQ Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSQH WS": {
        "symbol": "PSQH WS",
        "name": "PSQ Holdings, Inc. Warrants, each exercisable for one share of Class A common stock at an exercise p",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSQO": {
        "symbol": "PSQO",
        "name": "Palmer Square Credit Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSR": {
        "symbol": "PSR",
        "name": "Invesco Active U.S. Real Estate Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PST": {
        "symbol": "PST",
        "name": "ProShares Trust UltraShort Lehman 7-10 Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSTG": {
        "symbol": "PSTG",
        "name": "Pure Storage, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSTL": {
        "symbol": "PSTL",
        "name": "Postal Realty Trust, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSTP": {
        "symbol": "PSTP",
        "name": "Innovator Power Buffer Step-Up Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSTR": {
        "symbol": "PSTR",
        "name": "PeakShares Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSWD": {
        "symbol": "PSWD",
        "name": "Xtrackers Cybersecurity Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PSX": {
        "symbol": "PSX",
        "name": "PHILLIPS 66",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTA": {
        "symbol": "PTA",
        "name": "Cohen & Steers Tax-Advantaged Preferred Securities and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTBD": {
        "symbol": "PTBD",
        "name": "Pacer Trendpilot US Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEC": {
        "symbol": "PTEC",
        "name": "Global X PropTech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEST": {
        "symbol": "PTEST",
        "name": "ARCA Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEST W": {
        "symbol": "PTEST W",
        "name": "Test Symbol Class W",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEST X": {
        "symbol": "PTEST X",
        "name": "Test Symbol Class X",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEST Y": {
        "symbol": "PTEST Y",
        "name": "Test Symbol Class Y",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEST Z": {
        "symbol": "PTEST Z",
        "name": "NYSE Arca TEST SYMBOL SIFMA / DTCC Eligible",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTEU": {
        "symbol": "PTEU",
        "name": "Pacer TrendpilotTM European Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTF": {
        "symbol": "PTF",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Technology Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTH": {
        "symbol": "PTH",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Healthcare Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTIN": {
        "symbol": "PTIN",
        "name": "Pacer Trendpilot International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTIR": {
        "symbol": "PTIR",
        "name": "GraniteShares 2x Long PLTR Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTL": {
        "symbol": "PTL",
        "name": "Inspire 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTLC": {
        "symbol": "PTLC",
        "name": "Pacer Trendpilot US Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTMC": {
        "symbol": "PTMC",
        "name": "Pacer Trendpilot US Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTN": {
        "symbol": "PTN",
        "name": "Palatin Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTNQ": {
        "symbol": "PTNQ",
        "name": "Pacer Trendpilot 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTRB": {
        "symbol": "PTRB",
        "name": "PGIM Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PTY": {
        "symbol": "PTY",
        "name": "PIMCO Corporate & Income Opportunity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUI": {
        "symbol": "PUI",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Utilities Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUK": {
        "symbol": "PUK",
        "name": "PRUDENTIAL PLC ADS (REP 2 ORD SHARES)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PULS": {
        "symbol": "PULS",
        "name": "PGIM Ultra Short Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PULT": {
        "symbol": "PULT",
        "name": "Putnam ESG Ultra Short ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUMP": {
        "symbol": "PUMP",
        "name": "ProPetro Holding Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUSH": {
        "symbol": "PUSH",
        "name": "PGIM Ultra Short Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUTD": {
        "symbol": "PUTD",
        "name": "Cboe Validus S&P 500 Dynamic PutWrite Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PUTW": {
        "symbol": "PUTW",
        "name": "WisdomTree PutWrite Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PVAL": {
        "symbol": "PVAL",
        "name": "Putnam Focused Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PVH": {
        "symbol": "PVH",
        "name": "PVH Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PVI": {
        "symbol": "PVI",
        "name": "Invesco Floating Rate Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PVL": {
        "symbol": "PVL",
        "name": "Permianville Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PW": {
        "symbol": "PW",
        "name": "Power REIT",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PW PRA": {
        "symbol": "PW PRA",
        "name": "Power REIT  7.75% Series A Cumulative Redeemable Perpetual Preferred Stock, Liquidation Preference $",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWB": {
        "symbol": "PWB",
        "name": "Invesco Large Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWER": {
        "symbol": "PWER",
        "name": "Macquarie Energy Transition ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWR": {
        "symbol": "PWR",
        "name": "Quanta Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWS": {
        "symbol": "PWS",
        "name": "Pacer WealthShield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWUP": {
        "symbol": "PWUP",
        "name": "PowerUp Acquisition Corp. Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWV": {
        "symbol": "PWV",
        "name": "Invesco Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PWZ": {
        "symbol": "PWZ",
        "name": "Invesco California AMT-Free Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PX": {
        "symbol": "PX",
        "name": "P10, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PXE": {
        "symbol": "PXE",
        "name": "Invesco Energy Exploration & Production ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PXF": {
        "symbol": "PXF",
        "name": "Invesco FTSE RAFI Developed Markets ex-U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PXH": {
        "symbol": "PXH",
        "name": "Invesco FTSE RAFI Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PXI": {
        "symbol": "PXI",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Energy Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PXJ": {
        "symbol": "PXJ",
        "name": "Invesco Oil & Gas Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PY": {
        "symbol": "PY",
        "name": "Principal Exchange-Traded Funds Principal Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PYLD": {
        "symbol": "PYLD",
        "name": "PIMCO Multi Sector Bond Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PYN": {
        "symbol": "PYN",
        "name": "PIMCO New York Municipal Income Fund III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PYPY": {
        "symbol": "PYPY",
        "name": "YieldMax PYPL Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PYT": {
        "symbol": "PYT",
        "name": "Merrill Lynch Depositor PPLUS  Floating Rate Call TR Cert. Ser GSC-2(Goldman Sachs)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PYZ": {
        "symbol": "PYZ",
        "name": "Invesco Exchange-Traded Fund Trust Invesco Dorsey Wright Basic Materials Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PZA": {
        "symbol": "PZA",
        "name": "Invesco National AMT-Free Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PZC": {
        "symbol": "PZC",
        "name": "PIMCO California Municipal Income Fund III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PZG": {
        "symbol": "PZG",
        "name": "Paramount Gold Nevada Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "PZT": {
        "symbol": "PZT",
        "name": "Invesco New York AMT-Free Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QABA": {
        "symbol": "QABA",
        "name": "First Trust NASDAQ ABA Community Bank Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QAI": {
        "symbol": "QAI",
        "name": "NYLI Hedge Multi-Strategy Tracker ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QARP": {
        "symbol": "QARP",
        "name": "Xtrackers Russell 1000 US Quality at a Reasonable Price ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QAT": {
        "symbol": "QAT",
        "name": "iShares Trust iShares MSCI Qatar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QBER": {
        "symbol": "QBER",
        "name": "TrueShares Quarterly Bear Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QBTS": {
        "symbol": "QBTS",
        "name": "D-Wave Quantum Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QBTS WS": {
        "symbol": "QBTS WS",
        "name": "D-Wave Quantum Inc. Warrants, each whole warrant exercisable for 1.4541326 shares of Common Stock at",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QBUF": {
        "symbol": "QBUF",
        "name": "Innovator Nasdaq-100 10 Buffer ETF Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QBUL": {
        "symbol": "QBUL",
        "name": "TrueShares Quarterly Bull Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QCAP": {
        "symbol": "QCAP",
        "name": "FT Vest Nasdaq-100 Conservative Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QCJL": {
        "symbol": "QCJL",
        "name": "FT Vest Nasdaq-100 Conservative Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QCLN": {
        "symbol": "QCLN",
        "name": "First Trust NASDAQ Clean Edge Green Energy Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QCLR": {
        "symbol": "QCLR",
        "name": "Global X NASDAQ 100 Collar 95-110 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QCON": {
        "symbol": "QCON",
        "name": "American Century Quality Convertible Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QD": {
        "symbol": "QD",
        "name": "Qudian Inc. American Depositary Shares, each representing one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDCC": {
        "symbol": "QDCC",
        "name": "Global X S&P 500 Quality Dividend Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDEC": {
        "symbol": "QDEC",
        "name": "FT Vest Growth-100 Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDEF": {
        "symbol": "QDEF",
        "name": "FlexShares Quality Dividend Defensive Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDF": {
        "symbol": "QDF",
        "name": "FlexShares Quality Dividend Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDIV": {
        "symbol": "QDIV",
        "name": "Global X S&P 500 Quality Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDPL": {
        "symbol": "QDPL",
        "name": "Pacer Metaurus US Large Cap Dividend Multiplier 400 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDTE": {
        "symbol": "QDTE",
        "name": "Roundhill Innovation-100 0DTE Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QDVO": {
        "symbol": "QDVO",
        "name": "Amplify CWP Growth & Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QEFA": {
        "symbol": "QEFA",
        "name": "SPDR MSCI EAFE StrategicFactors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QEMM": {
        "symbol": "QEMM",
        "name": "SPDR MSCI Emerging Markets StrategicFactors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QETAR": {
        "symbol": "QETAR",
        "name": "Quetta Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QETAU": {
        "symbol": "QETAU",
        "name": "Quetta Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QETH": {
        "symbol": "QETH",
        "name": "Invesco Galaxy Ethereum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QFLR": {
        "symbol": "QFLR",
        "name": "Innovator Nasdaq-100 Managed Floor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QGEN": {
        "symbol": "QGEN",
        "name": "QIAGEN N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QGRO": {
        "symbol": "QGRO",
        "name": "American Century U.S. Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QGRW": {
        "symbol": "QGRW",
        "name": "WisdomTree U.S. Quality Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QHDG": {
        "symbol": "QHDG",
        "name": "Innovator Hedged Nasdaq-100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QHY": {
        "symbol": "QHY",
        "name": "WisdomTree U.S. High Yield Corporate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QID": {
        "symbol": "QID",
        "name": "ProShares UltraShort QQQ",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QIG": {
        "symbol": "QIG",
        "name": "WisdomTree U.S. Corporate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QINT": {
        "symbol": "QINT",
        "name": "American Century Quality Diversified International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QIS": {
        "symbol": "QIS",
        "name": "Simplify Multi-QIS Alternative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QJUN": {
        "symbol": "QJUN",
        "name": "FT Vest Growth-100 Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLC": {
        "symbol": "QLC",
        "name": "FlexShares US Quality Large Cap Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLD": {
        "symbol": "QLD",
        "name": "ProShares Ultra QQQ",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLTA": {
        "symbol": "QLTA",
        "name": "iShares Aaa - A Rated Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLTY": {
        "symbol": "QLTY",
        "name": "GMO U.S. Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLV": {
        "symbol": "QLV",
        "name": "FlexShares US Quality Low Volatility Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLVD": {
        "symbol": "QLVD",
        "name": "FlexShares Developed Markets ex-US Quality Low Volatility Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QLVE": {
        "symbol": "QLVE",
        "name": "FlexShares Emerging Markets Quality Low Volatility Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QMAG": {
        "symbol": "QMAG",
        "name": "FT Vest Nasdaq-100 Moderate Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QMAR": {
        "symbol": "QMAR",
        "name": "FT Vest Growth-100 Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QMID": {
        "symbol": "QMID",
        "name": "WisdomTree U.S. MidCap Quality Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QMMY": {
        "symbol": "QMMY",
        "name": "FT Vest Nasdaq-100 Moderate Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QMOM": {
        "symbol": "QMOM",
        "name": "Alpha Architect U.S. Quantitative Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QOMOR": {
        "symbol": "QOMOR",
        "name": "Qomolangma Acquisition Corp. Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QOMOU": {
        "symbol": "QOMOU",
        "name": "Qomolangma Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QOMOW": {
        "symbol": "QOMOW",
        "name": "Qomolangma Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QOWZ": {
        "symbol": "QOWZ",
        "name": "Invesco Nasdaq Free Cash Flow Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QPFF": {
        "symbol": "QPFF",
        "name": "American Century Quality Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QPX": {
        "symbol": "QPX",
        "name": "AdvisorShares Q Dynamic Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQA": {
        "symbol": "QQA",
        "name": "Invesco QQQ Income Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQEW": {
        "symbol": "QQEW",
        "name": "First Trust NASDAQ-100 Equal Weighted Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQH": {
        "symbol": "QQH",
        "name": "HCM Defender 100 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQJG": {
        "symbol": "QQJG",
        "name": "Invesco ESG NASDAQ Next Gen 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQMG": {
        "symbol": "QQMG",
        "name": "Invesco ESG NASDAQ 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQ": {
        "symbol": "QQQ",
        "name": "Invesco QQQ Trust, Series 1",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQA": {
        "symbol": "QQQA",
        "name": "ProShares Nasdaq-100 Dorsey Wright Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQD": {
        "symbol": "QQQD",
        "name": "Direxion Daily Magnificent 7 Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQE": {
        "symbol": "QQQE",
        "name": "Direxion NASDAQ-100 Equal Weighted Index Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQG": {
        "symbol": "QQQG",
        "name": "Pacer Nasdaq 100 Top 50 Cash Cows Growth Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQI": {
        "symbol": "QQQI",
        "name": "NEOS Nasdaq 100 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQJ": {
        "symbol": "QQQJ",
        "name": "Invesco NASDAQ Next Gen 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQM": {
        "symbol": "QQQM",
        "name": "Invesco NASDAQ 100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQN": {
        "symbol": "QQQN",
        "name": "VictoryShares Nasdaq Next 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQP": {
        "symbol": "QQQP",
        "name": "Tradr 2X Long Triple Q Quarterly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQS": {
        "symbol": "QQQS",
        "name": "Invesco NASDAQ Future Gen 200 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQT": {
        "symbol": "QQQT",
        "name": "Defiance Nasdaq 100 Income Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQU": {
        "symbol": "QQQU",
        "name": "Direxion Daily Magnificent 7 Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQW": {
        "symbol": "QQQW",
        "name": "Tradr 2X Long Triple Q Weekly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQQY": {
        "symbol": "QQQY",
        "name": "Tidal Trust II Defiance Nasdaq 100 Enhanced Options & 0DTE Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QQXT": {
        "symbol": "QQXT",
        "name": "First Trust NASDAQ - 100 Ex-Technology Sector Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QRFT": {
        "symbol": "QRFT",
        "name": "Qraft AI-Enhanced US Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QRMI": {
        "symbol": "QRMI",
        "name": "Global X NASDAQ 100 Risk Managed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QS": {
        "symbol": "QS",
        "name": "QuantumScape Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSIG": {
        "symbol": "QSIG",
        "name": "WisdomTree U.S. Short Term Corporate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSIX": {
        "symbol": "QSIX",
        "name": "Pacer Metaurus Nasdaq 100 Dividend Multiplier 600 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSML": {
        "symbol": "QSML",
        "name": "WisdomTree U.S. SmallCap Quality Growth Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSPT": {
        "symbol": "QSPT",
        "name": "FT Vest Nasdaq-100 Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSR": {
        "symbol": "QSR",
        "name": "Restaurant Brands International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QSWN": {
        "symbol": "QSWN",
        "name": "Amplify BlackSwan Tech & Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTAP": {
        "symbol": "QTAP",
        "name": "Innovator Growth Accelerated Plus ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTEC": {
        "symbol": "QTEC",
        "name": "First Trust NASDAQ-100 Technology  Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTJA": {
        "symbol": "QTJA",
        "name": "Innovator Growth Accelerated Plus ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTJL": {
        "symbol": "QTJL",
        "name": "Innovator Growth Accelerated Plus ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTOC": {
        "symbol": "QTOC",
        "name": "Innovator Growth Accelerated Plus ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTR": {
        "symbol": "QTR",
        "name": "Global X NASDAQ 100 Tail Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTUM": {
        "symbol": "QTUM",
        "name": "Defiance Quantum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QTWO": {
        "symbol": "QTWO",
        "name": "Q2 Holdings Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QUAD": {
        "symbol": "QUAD",
        "name": "QUAD/GRAPHICS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QUAL": {
        "symbol": "QUAL",
        "name": "iShares MSCI USA Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QULL": {
        "symbol": "QULL",
        "name": "ETRACS 2x Leveraged MSCI US Quality Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QUS": {
        "symbol": "QUS",
        "name": "SPDR MSCI USA StrategicFactors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QUVU": {
        "symbol": "QUVU",
        "name": "Hartford Quality Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVAL": {
        "symbol": "QVAL",
        "name": "Alpha Architect U.S. Quantitative Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVCC": {
        "symbol": "QVCC",
        "name": "QVC, Inc. 6.250% Senior Secured Notes due 2068",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVCD": {
        "symbol": "QVCD",
        "name": "QVC, Inc. 6.375% Senior Secured Notes due 2067",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVML": {
        "symbol": "QVML",
        "name": "Invesco S&P 500 QVM Multi-factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVMM": {
        "symbol": "QVMM",
        "name": "Invesco S&P MidCap 400 QVM Multi-factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVMS": {
        "symbol": "QVMS",
        "name": "Invesco S&P SmallCap 600 QVM Multi-factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QVOY": {
        "symbol": "QVOY",
        "name": "Q3 All-Season Active Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QWLD": {
        "symbol": "QWLD",
        "name": "SPDR MSCI World StrategicFactors ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QXQ": {
        "symbol": "QXQ",
        "name": "SGI Enhanced Nasdaq-100 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QYLD": {
        "symbol": "QYLD",
        "name": "Global X Funds Global X NASDAQ-100 Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QYLE": {
        "symbol": "QYLE",
        "name": "Global X Nasdaq 100 ESG Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "QYLG": {
        "symbol": "QYLG",
        "name": "Global X Nasdaq 100 Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "R": {
        "symbol": "R",
        "name": "Ryder System, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RA": {
        "symbol": "RA",
        "name": "Brookfield Real Assets Income Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAAX": {
        "symbol": "RAAX",
        "name": "VanEck Inflation Allocation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RACE": {
        "symbol": "RACE",
        "name": "Ferrari N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAFE": {
        "symbol": "RAFE",
        "name": "PIMCO RAFI ESG U.S. ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAMP": {
        "symbol": "RAMP",
        "name": "LiveRamp Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RATE": {
        "symbol": "RATE",
        "name": "Global X Interest Rate Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAVI": {
        "symbol": "RAVI",
        "name": "FlexShares Ultra-Short Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAYC": {
        "symbol": "RAYC",
        "name": "Rayliant Quantamental China Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAYD": {
        "symbol": "RAYD",
        "name": "Rayliant Quantitative Developed Market Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAYE": {
        "symbol": "RAYE",
        "name": "Rayliant Quantamental Emerging Market ex-China Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAYJ": {
        "symbol": "RAYJ",
        "name": "Rayliant SMDAM Japan Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RAYS": {
        "symbol": "RAYS",
        "name": "Global X Solar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBA": {
        "symbol": "RBA",
        "name": "RB Global, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBC": {
        "symbol": "RBC",
        "name": "RBC Bearings Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBCP": {
        "symbol": "RBCP",
        "name": "RBC Bearings Incorporated 5.00% Series A Mandatory Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBLD": {
        "symbol": "RBLD",
        "name": "First Trust Alerian US NextGen Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBLX": {
        "symbol": "RBLX",
        "name": "Roblox Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBOT": {
        "symbol": "RBOT",
        "name": "Vicarious Surgical Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBOT WS": {
        "symbol": "RBOT WS",
        "name": "Vicarious Surgical Inc. Warrants, each whole warrant exercisable for 1/30th of a share of Class A Co",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBRK": {
        "symbol": "RBRK",
        "name": "Rubrik, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RBUF": {
        "symbol": "RBUF",
        "name": "Innovator U.S. Small Cap 10 Buffer ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RC": {
        "symbol": "RC",
        "name": "Ready Capital Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RC PRC": {
        "symbol": "RC PRC",
        "name": "Ready Capital Corporation 6.25% Series C Cumulative Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RC PRE": {
        "symbol": "RC PRE",
        "name": "Ready Capital Corporation 6.50% Series E Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCB": {
        "symbol": "RCB",
        "name": "Ready Capital Corporation 6.20% Senior Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCC": {
        "symbol": "RCC",
        "name": "Ready Capital Corporation 5.75% Senior Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCFA": {
        "symbol": "RCFA",
        "name": "Perception Capital Corp. IV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCFA U": {
        "symbol": "RCFA U",
        "name": "Perception Capital Corp. IV Units, each consisting of one Class A ordinary share and one-half of one",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCFA WS": {
        "symbol": "RCFA WS",
        "name": "Perception Capital Corp. IV Warrants, each whole warrant exercisable for one Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCG": {
        "symbol": "RCG",
        "name": "RENN Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCI": {
        "symbol": "RCI",
        "name": "Rogers Communications, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCL": {
        "symbol": "RCL",
        "name": "Royal Caribbean Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCS": {
        "symbol": "RCS",
        "name": "PIMCO STRATEGIC INCOME FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RCUS": {
        "symbol": "RCUS",
        "name": "Arcus Biosciences, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDDT": {
        "symbol": "RDDT",
        "name": "Reddit, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDFI": {
        "symbol": "RDFI",
        "name": "Rareview Dynamic Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDIV": {
        "symbol": "RDIV",
        "name": "Invesco S&P Ultra Dividend Revenue ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDN": {
        "symbol": "RDN",
        "name": "Radian Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDOG": {
        "symbol": "RDOG",
        "name": "ALPS REIT Dividend Dogs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDTE": {
        "symbol": "RDTE",
        "name": "Roundhill Small Cap 0DTE Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDVI": {
        "symbol": "RDVI",
        "name": "FT Vest Rising Dividend Achievers Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDVY": {
        "symbol": "RDVY",
        "name": "First Trust Rising Dividend Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDW": {
        "symbol": "RDW",
        "name": "Redwire Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDW WS": {
        "symbol": "RDW WS",
        "name": "Redwire Corporation Redeemable Warrants, each whole warrant exercisable for one share of Common Stoc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RDY": {
        "symbol": "RDY",
        "name": "Dr. Reddy's Laboratories Limited American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REAI": {
        "symbol": "REAI",
        "name": "Tidal ETF Trust Intelligent Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RECS": {
        "symbol": "RECS",
        "name": "Columbia Research Enhanced Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REET": {
        "symbol": "REET",
        "name": "iShares Global REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REGL": {
        "symbol": "REGL",
        "name": "ProShares S&P MidCap 400 Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REI": {
        "symbol": "REI",
        "name": "Ring Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REIT": {
        "symbol": "REIT",
        "name": "ALPS Active REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REK": {
        "symbol": "REK",
        "name": "ProShares Short Real Estate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REKT": {
        "symbol": "REKT",
        "name": "Direxion Daily Crypto Industry Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RELX": {
        "symbol": "RELX",
        "name": "RELX PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REM": {
        "symbol": "REM",
        "name": "iShares Mortgage Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REMX": {
        "symbol": "REMX",
        "name": "VanEck Rare Earth and Strategic Metals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RENEU": {
        "symbol": "RENEU",
        "name": "Cartesian Growth Corporation II Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RENW": {
        "symbol": "RENW",
        "name": "Harbor Energy Transition Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REPX": {
        "symbol": "REPX",
        "name": "Riley Exploration Permian, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RERE": {
        "symbol": "RERE",
        "name": "ATRenew Inc. American Depositary Shares (every three of which representing two Class A ordinary shar",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RES": {
        "symbol": "RES",
        "name": "RPC, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RETL": {
        "symbol": "RETL",
        "name": "Direxion Daily Retail Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REVG": {
        "symbol": "REVG",
        "name": "REV Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REVS": {
        "symbol": "REVS",
        "name": "Columbia Research Enhanced Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REW": {
        "symbol": "REW",
        "name": "Proshares UltraShort Technology",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REX": {
        "symbol": "REX",
        "name": "REX American Resources Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REXR": {
        "symbol": "REXR",
        "name": "REXFORD INDUSTRIAL REALTY, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REXR PRB": {
        "symbol": "REXR PRB",
        "name": "Rexford Industrial Realty, Inc. 5.875% Series B Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REXR PRC": {
        "symbol": "REXR PRC",
        "name": "Rexford Industrial Realty, Inc. 5.625% Series C Cumulative Redeemable Preferred Stock, par value $0.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REZ": {
        "symbol": "REZ",
        "name": "iShares Residential and Multisector Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "REZI": {
        "symbol": "REZI",
        "name": "Resideo Technologies, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RF": {
        "symbol": "RF",
        "name": "Regions Financial Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RF PRC": {
        "symbol": "RF PRC",
        "name": "Regions Financial Corporation Depositary Shares, each Representing a 1/40th Interest in a Share of 5",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RF PRE": {
        "symbol": "RF PRE",
        "name": "Regions Financial Corporation Depositary Shares, Each Representing a 1/40th Interest in a Share of 4",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RF PRF": {
        "symbol": "RF PRF",
        "name": "Regions Financial Corporation Depositary Shares Each Representing a 1/40th Interest in a Share of No",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFAC": {
        "symbol": "RFAC",
        "name": "RF Acquisition Corp. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFACR": {
        "symbol": "RFACR",
        "name": "RF Acquisition Corp. Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFACW": {
        "symbol": "RFACW",
        "name": "RF Acquisition Corp. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFCI": {
        "symbol": "RFCI",
        "name": "RiverFront Dynamic Core Income",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFDA": {
        "symbol": "RFDA",
        "name": "RiverFront Dynamic US Dividend Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFDI": {
        "symbol": "RFDI",
        "name": "First Trust RiverFront Dynamic Developed International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFEM": {
        "symbol": "RFEM",
        "name": "First Trust RiverFront Dynamic Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFEU": {
        "symbol": "RFEU",
        "name": "First Trust RiverFront Dynamic Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFFC": {
        "symbol": "RFFC",
        "name": "ALPS Active Equity Opportunity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFG": {
        "symbol": "RFG",
        "name": "Invesco S&P MidCap 400 Pure Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFI": {
        "symbol": "RFI",
        "name": "Cohen and Steers Total Return Realty Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFL": {
        "symbol": "RFL",
        "name": "Rafael Holdings, Inc. Class B Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFLR": {
        "symbol": "RFLR",
        "name": "Innovator U.S. Small Cap Managed Floor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFM": {
        "symbol": "RFM",
        "name": "RiverNorth Flexible Municipal Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFMZ": {
        "symbol": "RFMZ",
        "name": "RiverNorth Flexible Municipal Income Fund II, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RFV": {
        "symbol": "RFV",
        "name": "Invesco S&P MidCap 400 Pure Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RGA": {
        "symbol": "RGA",
        "name": "Reinsurance Group of America, Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RGR": {
        "symbol": "RGR",
        "name": "Sturm, Ruger & Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RGT": {
        "symbol": "RGT",
        "name": "Royce Global Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RH": {
        "symbol": "RH",
        "name": "RH",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHCB": {
        "symbol": "RHCB",
        "name": "BNY Mellon Responsible Horizons Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHE": {
        "symbol": "RHE",
        "name": "Regional Health Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHE PRA": {
        "symbol": "RHE PRA",
        "name": "Regional Health Properties, Inc. Regional Health Properties, Inc. 10.875 percent Series A Cumulative",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHI": {
        "symbol": "RHI",
        "name": "Robert Half Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHP": {
        "symbol": "RHP",
        "name": "Ryman Hospitality Properties, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHRX": {
        "symbol": "RHRX",
        "name": "RH Tactical Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RHTX": {
        "symbol": "RHTX",
        "name": "RH Tactical Outlook ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIET": {
        "symbol": "RIET",
        "name": "Hoya Capital High Dividend Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIG": {
        "symbol": "RIG",
        "name": "Transocean LTD.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIGS": {
        "symbol": "RIGS",
        "name": "RiverFront Strategic Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RINC": {
        "symbol": "RINC",
        "name": "AXS Real Estate Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RINF": {
        "symbol": "RINF",
        "name": "ProShares Inflation Expectations ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RING": {
        "symbol": "RING",
        "name": "iShares MSCI Global Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIO": {
        "symbol": "RIO",
        "name": "Rio Tinto plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RISN": {
        "symbol": "RISN",
        "name": "Inspire Tactical Balanced ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RISR": {
        "symbol": "RISR",
        "name": "FolioBeyond Alternative Income and Interest Rate Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITA": {
        "symbol": "RITA",
        "name": "ETFB Green SRI REITs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITM": {
        "symbol": "RITM",
        "name": "Rithm Capital Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITM PRA": {
        "symbol": "RITM PRA",
        "name": "Rithm Capital Corp.7.50% Series A Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITM PRB": {
        "symbol": "RITM PRB",
        "name": "Rithm Capital Corp. 7.125% Series B Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITM PRC": {
        "symbol": "RITM PRC",
        "name": "Rithm Capital Corp. 6.375% Series C Fixed-to-Floating Rate Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RITM PRD": {
        "symbol": "RITM PRD",
        "name": "Rithm Capital Corp. 7.00% Fixed-Rate Reset Series D Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIV": {
        "symbol": "RIV",
        "name": "RiverNorth Opportunities Fund, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RIV PRA": {
        "symbol": "RIV PRA",
        "name": "RiverNorth Opportunities Fund, Inc. 6.00% Series A Perpetual Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RJF": {
        "symbol": "RJF",
        "name": "Raymond James Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RJF PRB": {
        "symbol": "RJF PRB",
        "name": "Raymond James Financial, Inc. Depositary Shares, each representing a 1/40th interest in a share of 6",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RJMG": {
        "symbol": "RJMG",
        "name": "FT Raymond James Multicap Growth Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RKT": {
        "symbol": "RKT",
        "name": "Rocket Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RL": {
        "symbol": "RL",
        "name": "Ralph Lauren Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLGT": {
        "symbol": "RLGT",
        "name": "Radiant Logistics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLI": {
        "symbol": "RLI",
        "name": "RLI Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLJ": {
        "symbol": "RLJ",
        "name": "RLJ Lodging Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLJ PRA": {
        "symbol": "RLJ PRA",
        "name": "RLJ Lodging Trust $1.95 Series A Cumulative Convertible Preferred Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLTY": {
        "symbol": "RLTY",
        "name": "Cohen & Steers Real Estate Opportunities and Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLX": {
        "symbol": "RLX",
        "name": "RLX Technology Inc. American Depositary Shares, each representing the right to receive one (1) Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RLY": {
        "symbol": "RLY",
        "name": "SPDR SSgA Multi-Asset Real Return ETF of SSgA Active Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RM": {
        "symbol": "RM",
        "name": "REGIONAL MANAGEMENT CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMAX": {
        "symbol": "RMAX",
        "name": "RE/MAX HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMCA": {
        "symbol": "RMCA",
        "name": "Rockefeller California Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMD": {
        "symbol": "RMD",
        "name": "ResMed Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMI": {
        "symbol": "RMI",
        "name": "RiverNorth Opportunistic Municipal Income Fund, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMIF": {
        "symbol": "RMIF",
        "name": "LHA Risk-Managed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMM": {
        "symbol": "RMM",
        "name": "RiverNorth Managed Duration Municipal Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMMZ": {
        "symbol": "RMMZ",
        "name": "RiverNorth Managed Duration Municipal Income Fund II, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMNY": {
        "symbol": "RMNY",
        "name": "Rockefeller New York Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMOP": {
        "symbol": "RMOP",
        "name": "Rockefeller Opportunistic Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMPL PR": {
        "symbol": "RMPL PR",
        "name": "RiverNorth Capital and Income Fund, Inc. 5.875%% Series A Term Preferred Stock $0.0001 par value per",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RMT": {
        "symbol": "RMT",
        "name": "Royce Micro-Cap Trust Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RND": {
        "symbol": "RND",
        "name": "First Trust Bloomberg R&D Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNEM": {
        "symbol": "RNEM",
        "name": "First Trust Emerging Markets Equity Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNEW": {
        "symbol": "RNEW",
        "name": "VanEck Green Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNG": {
        "symbol": "RNG",
        "name": "RINGCENTRAL, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNGR": {
        "symbol": "RNGR",
        "name": "Ranger Energy Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNP": {
        "symbol": "RNP",
        "name": "Cohen & Steers REIT and Preferred and Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNR": {
        "symbol": "RNR",
        "name": "RenaissanceRe Holdings Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNR PRF": {
        "symbol": "RNR PRF",
        "name": "RenaissanceRe Holdings Ltd. Depositary Shares, each Representing a 1/1,000th Interest in a 5.750% Se",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNR PRG": {
        "symbol": "RNR PRG",
        "name": "RenaissanceRe Holdings Ltd. Depositary Shares, each representing a 1/1, 000th interest in a share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNRG": {
        "symbol": "RNRG",
        "name": "Global X Funds Global X Renewable Energy Producers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNSC": {
        "symbol": "RNSC",
        "name": "First Trust Small Cap US Equity Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNST": {
        "symbol": "RNST",
        "name": "Renasant Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RNWZ": {
        "symbol": "RNWZ",
        "name": "TrueShares Eagle Global Renewable Energy Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROAM": {
        "symbol": "ROAM",
        "name": "Hartford Multifactor Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROBO": {
        "symbol": "ROBO",
        "name": "ROBO Global Robotics & Automation Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROBT": {
        "symbol": "ROBT",
        "name": "First Trust Nasdaq Artificial Intelligence and Robotics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROCLU": {
        "symbol": "ROCLU",
        "name": "Roth CH Acquisition V Co. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RODE": {
        "symbol": "RODE",
        "name": "Hartford Multifactor Diversified International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RODM": {
        "symbol": "RODM",
        "name": "Hartford Multifactor Developed Markets (ex-US) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROE": {
        "symbol": "ROE",
        "name": "EA Series Trust Astoria US Equal Weight Quality Kings ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROG": {
        "symbol": "ROG",
        "name": "Rogers Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROIS": {
        "symbol": "ROIS",
        "name": "Hartford Multifactor International Small Company ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROK": {
        "symbol": "ROK",
        "name": "Rockwell Automation, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROKT": {
        "symbol": "ROKT",
        "name": "SPDR S&P Kensho Final Frontiers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROL": {
        "symbol": "ROL",
        "name": "Rollins, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROM": {
        "symbol": "ROM",
        "name": "ProShares Ultra Technology",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROMO": {
        "symbol": "ROMO",
        "name": "Strategy Shares Newfound/ReSolve Robust Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROOF": {
        "symbol": "ROOF",
        "name": "NYLI CBRE NextGen Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RORO": {
        "symbol": "RORO",
        "name": "ATAC US Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROSC": {
        "symbol": "ROSC",
        "name": "Hartford Multifactor Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ROUS": {
        "symbol": "ROUS",
        "name": "Hartford Multifactor US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RPAR": {
        "symbol": "RPAR",
        "name": "RPAR Risk Parity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RPG": {
        "symbol": "RPG",
        "name": "Invesco S&P 500 Pure Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RPHS": {
        "symbol": "RPHS",
        "name": "Regents Park Hedged Market Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RPM": {
        "symbol": "RPM",
        "name": "RPM International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RPV": {
        "symbol": "RPV",
        "name": "Invesco S&P 500 Pure Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RQI": {
        "symbol": "RQI",
        "name": "COHEN & STEERS QUALITY INCOME REALTY FUND, INC. COMMON SHARES",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RRAC": {
        "symbol": "RRAC",
        "name": "Rigel Resource Acquisition Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RRAC U": {
        "symbol": "RRAC U",
        "name": "Rigel Resource Acquisition Corp. Units, each consisting of one Class A ordinary share, and one-half",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RRAC WS": {
        "symbol": "RRAC WS",
        "name": "Rigel Resource Acquisition Corp Warrants, each whole warrant exercisable for one Class A ordinary sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RRC": {
        "symbol": "RRC",
        "name": "Range Resources Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RRX": {
        "symbol": "RRX",
        "name": "Regal Rexnord Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RS": {
        "symbol": "RS",
        "name": "Reliance, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSBT": {
        "symbol": "RSBT",
        "name": "Return Stacked Bonds & Managed Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSBY": {
        "symbol": "RSBY",
        "name": "Return Stacked Bonds & Futures Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSEE": {
        "symbol": "RSEE",
        "name": "Rareview Systematic Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSF": {
        "symbol": "RSF",
        "name": "RiverNorth Capital and Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSG": {
        "symbol": "RSG",
        "name": "Republic Services Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSHO": {
        "symbol": "RSHO",
        "name": "Tema American Reshoring ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSI": {
        "symbol": "RSI",
        "name": "Rush Street Interactive, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSJN": {
        "symbol": "RSJN",
        "name": "FT Vest U.S. Equity Equal Weight Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSKD": {
        "symbol": "RSKD",
        "name": "Riskified Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSP": {
        "symbol": "RSP",
        "name": "Invesco S&P 500 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPA": {
        "symbol": "RSPA",
        "name": "Invesco S&P 500 Equal Weight Income Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPC": {
        "symbol": "RSPC",
        "name": "Invesco Exchange-Traded Fund Trust Invesco S&P 500 Equal Weight Communication Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPD": {
        "symbol": "RSPD",
        "name": "Invesco S&P 500 Equal Weight Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPE": {
        "symbol": "RSPE",
        "name": "Invesco ESG S&P 500 Equal Weight ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPF": {
        "symbol": "RSPF",
        "name": "Invesco S&P 500 Equal Weight Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPG": {
        "symbol": "RSPG",
        "name": "Invesco S&P 500 Equal Weight Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPH": {
        "symbol": "RSPH",
        "name": "Invesco S&P 500 Equal Weight Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPM": {
        "symbol": "RSPM",
        "name": "Invesco S&P 500 Equal Weight Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPN": {
        "symbol": "RSPN",
        "name": "Invesco S&P 500 Equal Weight Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPR": {
        "symbol": "RSPR",
        "name": "Invesco S&P 500 Equal Weight Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPS": {
        "symbol": "RSPS",
        "name": "Invesco S&P 500 Equal Weight Consumer Staples ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPT": {
        "symbol": "RSPT",
        "name": "Invesco S&P 500 Equal Weight Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSPU": {
        "symbol": "RSPU",
        "name": "Invesco S&P 500 Equal Weight Utilities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSSB": {
        "symbol": "RSSB",
        "name": "Return Stacked Global Stocks & Bonds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSSE": {
        "symbol": "RSSE",
        "name": "FT Vest U.S. Equity Equal-Weight Buffer ETF September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSSL": {
        "symbol": "RSSL",
        "name": "Global X Russell 2000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSST": {
        "symbol": "RSST",
        "name": "Return Stacked U.S. Stocks & Managed Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RSSY": {
        "symbol": "RSSY",
        "name": "Return Stacked U.S. Stocks & Futures Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RTAI": {
        "symbol": "RTAI",
        "name": "Rareview Tax Advantaged Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RTH": {
        "symbol": "RTH",
        "name": "VanEck Retail ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RTO": {
        "symbol": "RTO",
        "name": "Rentokil Initial plc American Depositary Shares (each representing five (5) Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RTRE": {
        "symbol": "RTRE",
        "name": "Rareview Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RTX": {
        "symbol": "RTX",
        "name": "RTX Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RULE": {
        "symbol": "RULE",
        "name": "Adaptive Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RUNN": {
        "symbol": "RUNN",
        "name": "Strategic Trust Running Oak Efficient Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVER": {
        "symbol": "RVER",
        "name": "Trenchless Fund ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVLV": {
        "symbol": "RVLV",
        "name": "Revolve Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVNU": {
        "symbol": "RVNU",
        "name": "Xtrackers Municipal Infrastructure Revenue Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVP": {
        "symbol": "RVP",
        "name": "Retractable Technologies, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVRB": {
        "symbol": "RVRB",
        "name": "Reverb ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVT": {
        "symbol": "RVT",
        "name": "Royce Small-Cap Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RVTY": {
        "symbol": "RVTY",
        "name": "Revvity, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWJ": {
        "symbol": "RWJ",
        "name": "Invesco S&P SmallCap 600 Revenue ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWK": {
        "symbol": "RWK",
        "name": "Invesco S&P MidCap 400 Revenue ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWL": {
        "symbol": "RWL",
        "name": "Invesco S&P 500 Revenue ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWM": {
        "symbol": "RWM",
        "name": "ProShares Short Russell2000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWO": {
        "symbol": "RWO",
        "name": "SPDR  Dow Jones  Global Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWR": {
        "symbol": "RWR",
        "name": "SPDR Dow Jones REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWT": {
        "symbol": "RWT",
        "name": "Redwood Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWT PRA": {
        "symbol": "RWT PRA",
        "name": "Redwood Trust, Inc. 10.00% Series A Fixed-Rate Reset Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWTN": {
        "symbol": "RWTN",
        "name": "Redwood Trust, Inc. 9.125% Senior Notes Due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWTO": {
        "symbol": "RWTO",
        "name": "Redwood Trust, Inc. 9.00% Senior Notes Due 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RWX": {
        "symbol": "RWX",
        "name": "SPDR Dow Jones  International Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RXD": {
        "symbol": "RXD",
        "name": "ProShares UltraShort Health Care",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RXI": {
        "symbol": "RXI",
        "name": "iShares Global Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RXL": {
        "symbol": "RXL",
        "name": "ProShares Ultra Health Care",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RXO": {
        "symbol": "RXO",
        "name": "RXO, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RY": {
        "symbol": "RY",
        "name": "Royal Bank of Canada",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYAM": {
        "symbol": "RYAM",
        "name": "Rayonier Advanced Materials Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYAN": {
        "symbol": "RYAN",
        "name": "Ryan Specialty Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYDE": {
        "symbol": "RYDE",
        "name": "Ryde Group Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYI": {
        "symbol": "RYI",
        "name": "RYERSON HOLDING CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYLD": {
        "symbol": "RYLD",
        "name": "Global X Russell 2000 Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYLG": {
        "symbol": "RYLG",
        "name": "Global X Russell 2000 Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYN": {
        "symbol": "RYN",
        "name": "Rayonier Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RYSE": {
        "symbol": "RYSE",
        "name": "Vest 10 Year Interest Rate Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RZB": {
        "symbol": "RZB",
        "name": "Reinsurance Group of America, Incorporated 5.75% Fixed-To-Floating Rate Subordinated Debentures due",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RZC": {
        "symbol": "RZC",
        "name": "Reinsurance Group of America, Incorporated 7.125% Fixed-Rate Reset Subordinated Debentures due 2052",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RZG": {
        "symbol": "RZG",
        "name": "Invesco S&P SmallCap 600 Pure Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "RZV": {
        "symbol": "RZV",
        "name": "Invesco S&P SmallCap 600 Pure Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "S": {
        "symbol": "S",
        "name": "SentinelOne, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SA": {
        "symbol": "SA",
        "name": "Seabridge Gold, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAA": {
        "symbol": "SAA",
        "name": "ProShares Ulta SmallCap600",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SABA": {
        "symbol": "SABA",
        "name": "Saba Capital Income & Opportunities Fund II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SACC": {
        "symbol": "SACC",
        "name": "Sachem Capital Corp. 6.875% Notes due 2024",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SACH": {
        "symbol": "SACH",
        "name": "Sachem Capital Corp. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SACH PRA": {
        "symbol": "SACH PRA",
        "name": "Sachem Capital Corp. 7.75% Series A Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAEF": {
        "symbol": "SAEF",
        "name": "Schwab Ariel ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAFE": {
        "symbol": "SAFE",
        "name": "Safehold Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAGP": {
        "symbol": "SAGP",
        "name": "Strategas Global Policy Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAH": {
        "symbol": "SAH",
        "name": "Sonic Automotive, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAJ": {
        "symbol": "SAJ",
        "name": "Saratoga Investment Corp. 8.00% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAM": {
        "symbol": "SAM",
        "name": "Boston Beer Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAMM": {
        "symbol": "SAMM",
        "name": "Strategas Macro Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAMT": {
        "symbol": "SAMT",
        "name": "Strategas Macro Thematic Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAN": {
        "symbol": "SAN",
        "name": "Banco Santander S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAND": {
        "symbol": "SAND",
        "name": "Sandstorm Gold Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAP": {
        "symbol": "SAP",
        "name": "SAP SE",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAR": {
        "symbol": "SAR",
        "name": "SARATOGA INVESTMENT CORP. NEW",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SARK": {
        "symbol": "SARK",
        "name": "Investment Managers Series Trust II Tradr 2X Short Innovation Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SARO": {
        "symbol": "SARO",
        "name": "StandardAero, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAT": {
        "symbol": "SAT",
        "name": "Saratoga Investment Corp. 6.00% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SATO": {
        "symbol": "SATO",
        "name": "Invesco Alerian Galaxy Crypto Economy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SATX": {
        "symbol": "SATX",
        "name": "SatixFy Communications Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAUG": {
        "symbol": "SAUG",
        "name": "FT Vest U.S. Small Cap Moderate Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAVE": {
        "symbol": "SAVE",
        "name": "Spirit Airlines, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAWG": {
        "symbol": "SAWG",
        "name": "AAM Sawgrass US Large Cap Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAWS": {
        "symbol": "SAWS",
        "name": "AAM Sawgrass US Small Cap Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAY": {
        "symbol": "SAY",
        "name": "Saratoga Investment Corp. 8.125% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SAZ": {
        "symbol": "SAZ",
        "name": "Saratoga Investment Corp. 8.50% Notes due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SB": {
        "symbol": "SB",
        "name": "Safe Bulkers, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SB PRC": {
        "symbol": "SB PRC",
        "name": "SAFE BULKERS, INC. 8.00%  SER C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SB PRD": {
        "symbol": "SB PRD",
        "name": "Safe Bulkers, Inc. 8.00% Series D",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBB": {
        "symbol": "SBB",
        "name": "ProShares Short SmallCap600",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBBA": {
        "symbol": "SBBA",
        "name": "Scorpio Tankers Inc. 7.00% Senior Notes due 2025",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBEV": {
        "symbol": "SBEV",
        "name": "Splash Beverage Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBEV WS": {
        "symbol": "SBEV WS",
        "name": "Splash Beverage Group, Inc. Warrants to purchase one whole share of Common Stock at an exercise pric",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBH": {
        "symbol": "SBH",
        "name": "Sally Beauty Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBI": {
        "symbol": "SBI",
        "name": "Western Asset Intermediate Muni Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBIO": {
        "symbol": "SBIO",
        "name": "ALPS Medical Breakthroughs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBIT": {
        "symbol": "SBIT",
        "name": "ProShares UltraShort Bitcoin ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBND": {
        "symbol": "SBND",
        "name": "Columbia Short Duration Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBR": {
        "symbol": "SBR",
        "name": "Sabine Royalty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBS": {
        "symbol": "SBS",
        "name": "COMPANHIA DE SANEAMENTO B SICO DO ESTADO DE SAO PAULO-SABESP ADS'S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBSW": {
        "symbol": "SBSW",
        "name": "Sibanye-Stillwater American Depositary Shares, each representing four ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXC": {
        "symbol": "SBXC",
        "name": "SilverBox Corp III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXC U": {
        "symbol": "SBXC U",
        "name": "SilverBox Corp III Units, each consisting of one Class A Common Stock and one-third of one redeemabl",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXC WS": {
        "symbol": "SBXC WS",
        "name": "SilverBox Corp III Redeemable warrants, each whole warrant exercisable for one share of Class A Comm",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXD": {
        "symbol": "SBXD",
        "name": "SilverBox Corp IV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXD U": {
        "symbol": "SBXD U",
        "name": "SilverBox Corp IV Units, each consisting of one Class A ordinary share and one-third of one redeemab",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SBXD WS": {
        "symbol": "SBXD WS",
        "name": "SilverBox Corp IV Redeemable warrants, each whole warrant exercisable for one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCAP": {
        "symbol": "SCAP",
        "name": "InfraCap Small Cap Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCC": {
        "symbol": "SCC",
        "name": "ProShares UltraShort Consumer Discretionary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCC": {
        "symbol": "SCCC",
        "name": "Sachem Capital Corp. 7.75% Notes due 2025",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCD": {
        "symbol": "SCCD",
        "name": "Sachem Capital Corp. 6.00% Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCE": {
        "symbol": "SCCE",
        "name": "Sachem Capital Corp. 6.00% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCF": {
        "symbol": "SCCF",
        "name": "Sachem Capital Corp. 7.125% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCG": {
        "symbol": "SCCG",
        "name": "Sachem Capital Corp. 8.00% Notes due 2027",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCCO": {
        "symbol": "SCCO",
        "name": "Southern Copper Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCD": {
        "symbol": "SCD",
        "name": "LMP Capital and Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCDL": {
        "symbol": "SCDL",
        "name": "ETRACS 2x Leveraged US Dividend Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCDS": {
        "symbol": "SCDS",
        "name": "JPMorgan Fundamental Data Science Small Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRG": {
        "symbol": "SCE PRG",
        "name": "SCE TRUST II 5.10% Trust Preference Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRH": {
        "symbol": "SCE PRH",
        "name": "SCE TRUST III 5.75% Fixed-to-Floating Rate Trust Preference Securities of SCE Trust III",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRJ": {
        "symbol": "SCE PRJ",
        "name": "SCE TRUST IV 5.375% Fixed-to-Floating Rate Trust Preference Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRK": {
        "symbol": "SCE PRK",
        "name": "SCE TRUST V",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRL": {
        "symbol": "SCE PRL",
        "name": "SCE TRUST VI 5.00% Trust Preference Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRM": {
        "symbol": "SCE PRM",
        "name": "SCE Trust VII 7.50% Trust Preference Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCE PRN": {
        "symbol": "SCE PRN",
        "name": "SCE Trust VIII 6.95% Trust Preference Securities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHA": {
        "symbol": "SCHA",
        "name": "Schwab U.S Small- Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHB": {
        "symbol": "SCHB",
        "name": "Schwab U.S. Broad Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHC": {
        "symbol": "SCHC",
        "name": "Schwab International Small-Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHD": {
        "symbol": "SCHD",
        "name": "Schwab US Dividend Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHE": {
        "symbol": "SCHE",
        "name": "Schwab Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHF": {
        "symbol": "SCHF",
        "name": "Schwab International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHG": {
        "symbol": "SCHG",
        "name": "Schwab U.S. Large-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHH": {
        "symbol": "SCHH",
        "name": "Schwab U.S. REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHI": {
        "symbol": "SCHI",
        "name": "Schwab 5-10 Year Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHJ": {
        "symbol": "SCHJ",
        "name": "Schwab 1-5 Year Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHK": {
        "symbol": "SCHK",
        "name": "Schwab 1000 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHM": {
        "symbol": "SCHM",
        "name": "Schwab U.S. Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHO": {
        "symbol": "SCHO",
        "name": "Schwab Short-Term U.S. Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHP": {
        "symbol": "SCHP",
        "name": "Schwab U.S. TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHQ": {
        "symbol": "SCHQ",
        "name": "Schwab Long-Term U.S. Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHR": {
        "symbol": "SCHR",
        "name": "Schwab Intermediately-Term U.S. Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHV": {
        "symbol": "SCHV",
        "name": "Schwab U.S. Large-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHW": {
        "symbol": "SCHW",
        "name": "The Charles Schwab Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHW PRD": {
        "symbol": "SCHW PRD",
        "name": "Charles Schwab Corporation Depositary Shares each representing 1/40th interest in a share of 5.95% N",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHW PRJ": {
        "symbol": "SCHW PRJ",
        "name": "The Charles Schwab Corporation Depositary Shares, Each Representing a 1/40th Interest in a Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHX": {
        "symbol": "SCHX",
        "name": "Schwab U.S. Large- Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHY": {
        "symbol": "SCHY",
        "name": "Schwab International Dividend Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCHZ": {
        "symbol": "SCHZ",
        "name": "Schwab US Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCI": {
        "symbol": "SCI",
        "name": "Service Corporation International",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCIO": {
        "symbol": "SCIO",
        "name": "First Trust Structured Credit Income Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCJ": {
        "symbol": "SCJ",
        "name": "iShares MSCI Japan Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCL": {
        "symbol": "SCL",
        "name": "Stepan Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCLZ": {
        "symbol": "SCLZ",
        "name": "Swan Enhanced Dividend Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCM": {
        "symbol": "SCM",
        "name": "STELLUS CAPITAL INVESTMENT CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCMB": {
        "symbol": "SCMB",
        "name": "Schwab Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCO": {
        "symbol": "SCO",
        "name": "ProShares UltraShort Bloomberg Crude Oil",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCPX": {
        "symbol": "SCPX",
        "name": "Scorpius Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCS": {
        "symbol": "SCS",
        "name": "Steelcase, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCUS": {
        "symbol": "SCUS",
        "name": "Schwab Ultra-Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCYB": {
        "symbol": "SCYB",
        "name": "Schwab High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SCZ": {
        "symbol": "SCZ",
        "name": "iShares MSCI EAFE Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SD": {
        "symbol": "SD",
        "name": "SandRidge Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDCI": {
        "symbol": "SDCI",
        "name": "USCF SummerHaven Dynamic Commodity Strategy No K-1 Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDCP": {
        "symbol": "SDCP",
        "name": "Virtus Newfleet Short Duration Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDD": {
        "symbol": "SDD",
        "name": "ProShares UltraShort SmallCap600",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDEM": {
        "symbol": "SDEM",
        "name": "Global X MSCI SuperDividend Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDFI": {
        "symbol": "SDFI",
        "name": "AB Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDG": {
        "symbol": "SDG",
        "name": "iShares Trust iShares MSCI Global Sustainable Development Goals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDHC": {
        "symbol": "SDHC",
        "name": "Smith Douglas Homes Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDHY": {
        "symbol": "SDHY",
        "name": "PGIM Short Duration High Yield Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDIV": {
        "symbol": "SDIV",
        "name": "Global X SuperDividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDOG": {
        "symbol": "SDOG",
        "name": "ALPS Sector Dividend Dogs ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDOW": {
        "symbol": "SDOW",
        "name": "ProShares UltraPro Short Dow 30",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDP": {
        "symbol": "SDP",
        "name": "ProShares UltraShort Utilities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDRL": {
        "symbol": "SDRL",
        "name": "Seadrill Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDS": {
        "symbol": "SDS",
        "name": "ProShares UltraShort S&P500",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDSI": {
        "symbol": "SDSI",
        "name": "American Century Short Duration Strategic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDVD": {
        "symbol": "SDVD",
        "name": "FT Vest SMID Rising Dividend Achievers Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDVY": {
        "symbol": "SDVY",
        "name": "First Trust SMID Cap Rising Dividend Achievers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SDY": {
        "symbol": "SDY",
        "name": "SPDR S&P Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SE": {
        "symbol": "SE",
        "name": "Sea Limited American Depositary Shares, each representing one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEA": {
        "symbol": "SEA",
        "name": "U.S. Global Sea to Sky Cargo ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEAL PRA": {
        "symbol": "SEAL PRA",
        "name": "Seapeak LLC 9.00% Series A Cumulative Redeemable Perpetual Preferred Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEAL PRB": {
        "symbol": "SEAL PRB",
        "name": "Seapeak LLC 8.50% Series B Fixed-to-Floating Rate Cumulative Redeemable Perpetual Preferred Units",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEB": {
        "symbol": "SEB",
        "name": "Seaboard Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SECR": {
        "symbol": "SECR",
        "name": "NYLI MacKay Securitized Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SECT": {
        "symbol": "SECT",
        "name": "Main Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEDA": {
        "symbol": "SEDA",
        "name": "SDCL EDGE Acquisition Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEDA U": {
        "symbol": "SEDA U",
        "name": "SDCL EDGE Acquisition Corporation Units, each consisting of one Class A ordinary share and one-half",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEDA WS": {
        "symbol": "SEDA WS",
        "name": "SDCL EDGE Acquisition Corporation Redeemable warrants, each whole warrant exercisable for one share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEE": {
        "symbol": "SEE",
        "name": "Sealed Air Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEEM": {
        "symbol": "SEEM",
        "name": "SEI Select Emerging Markets Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEF": {
        "symbol": "SEF",
        "name": "ProShares Short Financials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEG": {
        "symbol": "SEG",
        "name": "Seaport Entertainment Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEI": {
        "symbol": "SEI",
        "name": "Solaris Energy Infrastructure, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIE": {
        "symbol": "SEIE",
        "name": "SEI Select International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIM": {
        "symbol": "SEIM",
        "name": "SEI Enhanced U.S. Large Cap Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIQ": {
        "symbol": "SEIQ",
        "name": "SEI Enhanced U.S. Large Cap Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIS": {
        "symbol": "SEIS",
        "name": "SEI Select Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIV": {
        "symbol": "SEIV",
        "name": "SEI Enhanced U.S. Large Cap Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEIX": {
        "symbol": "SEIX",
        "name": "Virtus SEIX Senior Loan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SELV": {
        "symbol": "SELV",
        "name": "SEI Enhanced Low Volatility U.S. Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEM": {
        "symbol": "SEM",
        "name": "SELECT MEDICAL HOLDINGS CORP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEMI": {
        "symbol": "SEMI",
        "name": "Columbia Semiconductor and Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEMR": {
        "symbol": "SEMR",
        "name": "SEMrush Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SENS": {
        "symbol": "SENS",
        "name": "Senseonics Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPM": {
        "symbol": "SEPM",
        "name": "FT Vest U.S. Equity Max Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPP": {
        "symbol": "SEPP",
        "name": "PGIM US Large-Cap Buffer 12 ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPT": {
        "symbol": "SEPT",
        "name": "AllianzIM U.S. Large Cap Buffer10 Sep ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPU": {
        "symbol": "SEPU",
        "name": "AllianzIM U.S. Equity Buffer15 Uncapped Sep ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPW": {
        "symbol": "SEPW",
        "name": "AllianzIM U.S. Large Cap Buffer20 Sep ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SEPZ": {
        "symbol": "SEPZ",
        "name": "TrueShares Structured Outcome (September) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SER": {
        "symbol": "SER",
        "name": "Serina Therapeutics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SES": {
        "symbol": "SES",
        "name": "SES AI Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SES WS": {
        "symbol": "SES WS",
        "name": "SES AI Corporation  Warrants, each whole warrant exercisable for one share of Class A Common Stock a",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SETH": {
        "symbol": "SETH",
        "name": "ProShares Short Ether ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SETM": {
        "symbol": "SETM",
        "name": "Sprott Funds Trust Sprott Critical Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SF": {
        "symbol": "SF",
        "name": "Stifel Financial Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SF PRB": {
        "symbol": "SF PRB",
        "name": "Stifel Financial Corp. Depositary Shares, Each Representing 1/1,000th Interest in a Share of 6.25% N",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SF PRC": {
        "symbol": "SF PRC",
        "name": "Stifel Financial Corp. Depositary Shares, Each Representing 1/1, 000th Interest in a Share of 6.125%",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SF PRD": {
        "symbol": "SF PRD",
        "name": "Stifel Financial Corp. Depositary Shares, Each Representing 1/1,000th Interest in a Share of 4.50% N",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFB": {
        "symbol": "SFB",
        "name": "Stifel Financial Corp.5.20 percent Senior Notes due 2047",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFBS": {
        "symbol": "SFBS",
        "name": "ServisFirst Bancshares Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFEB": {
        "symbol": "SFEB",
        "name": "FT Vest U.S. Small Cap Moderate Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFL": {
        "symbol": "SFL",
        "name": "SFL Corporation Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFLO": {
        "symbol": "SFLO",
        "name": "VictoryShares Small Cap Free Cash Flow ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFLR": {
        "symbol": "SFLR",
        "name": "Innovator Equity Managed Floor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFY": {
        "symbol": "SFY",
        "name": "SoFi Select 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFYF": {
        "symbol": "SFYF",
        "name": "SoFi Social 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SFYX": {
        "symbol": "SFYX",
        "name": "SoFi Next 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SG": {
        "symbol": "SG",
        "name": "Sweetgreen, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGDJ": {
        "symbol": "SGDJ",
        "name": "Sprott Junior Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGDM": {
        "symbol": "SGDM",
        "name": "Sprott Gold Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGHC": {
        "symbol": "SGHC",
        "name": "Super Group (SGHC) Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGLC": {
        "symbol": "SGLC",
        "name": "SGI U.S. Large Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGN": {
        "symbol": "SGN",
        "name": "Signing Day Sports, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGOL": {
        "symbol": "SGOL",
        "name": "abrdn Physical Gold Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGOV": {
        "symbol": "SGOV",
        "name": "iShares 0-3 Month Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SGU": {
        "symbol": "SGU",
        "name": "Star Group, L.P. Common Units Representing Limited Partner Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SH": {
        "symbol": "SH",
        "name": "ProShares Short S&P500",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHAG": {
        "symbol": "SHAG",
        "name": "WisdomTree Yield Enhanced U.S. Short-Term Aggregate Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHAK": {
        "symbol": "SHAK",
        "name": "Shake Shack Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHCO": {
        "symbol": "SHCO",
        "name": "Soho House & Co Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHDG": {
        "symbol": "SHDG",
        "name": "Soundwatch Hedged Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHE": {
        "symbol": "SHE",
        "name": "SPDR MSCI USA Gender Diversity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHEH": {
        "symbol": "SHEH",
        "name": "Shell plc ADRhedged",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHEL": {
        "symbol": "SHEL",
        "name": "Shell plc American Depositary Shares (Each represents two Ordinary shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHG": {
        "symbol": "SHG",
        "name": "Shinhan Financial Group Co Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHLD": {
        "symbol": "SHLD",
        "name": "Global X Defense Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHM": {
        "symbol": "SHM",
        "name": "SPDR Nuveen Bloomberg Short Term Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHNY": {
        "symbol": "SHNY",
        "name": "MicroSectors Gold 3x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHO": {
        "symbol": "SHO",
        "name": "Sunstone Hotel Investors, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHO PRH": {
        "symbol": "SHO PRH",
        "name": "Sunstone Hotel Investors, Inc. 6.125% Series H Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHO PRI": {
        "symbol": "SHO PRI",
        "name": "Sunstone Hotel Investors, Inc. 5.70% Series I Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHOC": {
        "symbol": "SHOC",
        "name": "Strive U.S. Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHOP": {
        "symbol": "SHOP",
        "name": "Shopify Inc. Class A subordinate voting shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHPP": {
        "symbol": "SHPP",
        "name": "Pacer Industrials and Logistics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHRT": {
        "symbol": "SHRT",
        "name": "Gotham Short Strategies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHRY": {
        "symbol": "SHRY",
        "name": "First Trust Exchange-Traded Fund VI First Trust Bloomberg Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHUS": {
        "symbol": "SHUS",
        "name": "Stratified LargeCap Hedged ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHV": {
        "symbol": "SHV",
        "name": "iShares Short Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHW": {
        "symbol": "SHW",
        "name": "The Sherwin-Williams Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHY": {
        "symbol": "SHY",
        "name": "iShares 1-3 Year Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHYD": {
        "symbol": "SHYD",
        "name": "VanEck Short High Yield Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHYG": {
        "symbol": "SHYG",
        "name": "iShares 0-5 Year High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SHYL": {
        "symbol": "SHYL",
        "name": "Xtrackers Short Duration High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SID": {
        "symbol": "SID",
        "name": "Companhia Siderurgica Nacional S.A. (CSN)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIF": {
        "symbol": "SIF",
        "name": "SIFCO Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIFI": {
        "symbol": "SIFI",
        "name": "Harbor Scientific Alpha Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIG": {
        "symbol": "SIG",
        "name": "Signet Jewelers Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIHY": {
        "symbol": "SIHY",
        "name": "Harbor Scientific Alpha High-Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SII": {
        "symbol": "SII",
        "name": "Sprott Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIJ": {
        "symbol": "SIJ",
        "name": "ProShares UltraShort Industrials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIL": {
        "symbol": "SIL",
        "name": "Global X Silver Miners ETF (NEW)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SILA": {
        "symbol": "SILA",
        "name": "Sila Realty Trust, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SILJ": {
        "symbol": "SILJ",
        "name": "Amplify Junior Silver Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SILV": {
        "symbol": "SILV",
        "name": "SilverCrest Metals Inc. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIM": {
        "symbol": "SIM",
        "name": "Groupo Simec, S.A.B de C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIMAU": {
        "symbol": "SIMAU",
        "name": "SIM Acquisition Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIMS": {
        "symbol": "SIMS",
        "name": "SPDR S&P Kensho Intelligent Structures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIO": {
        "symbol": "SIO",
        "name": "Touchstone Strategic Income Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SITC": {
        "symbol": "SITC",
        "name": "SITE Centers Corp. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SITC PRA": {
        "symbol": "SITC PRA",
        "name": "SITE Centers Corp. Depositary Shares, each representing a 1/20th interest in a share of 6.375% Class",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SITE": {
        "symbol": "SITE",
        "name": "SiteOne Landscape Supply, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIVR": {
        "symbol": "SIVR",
        "name": "abrdn Physical Silver Shares ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXA": {
        "symbol": "SIXA",
        "name": "ETC 6 Meridian Mega Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXD": {
        "symbol": "SIXD",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 Jun/Dec ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXF": {
        "symbol": "SIXF",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 Feb/Aug ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXG": {
        "symbol": "SIXG",
        "name": "Defiance Connective Technologies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXH": {
        "symbol": "SIXH",
        "name": "ETC 6 Meridian Hedged Equity Index Option ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXJ": {
        "symbol": "SIXJ",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 Jan/Jul ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXL": {
        "symbol": "SIXL",
        "name": "ETC 6 Meridian Low Beta Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXO": {
        "symbol": "SIXO",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 Apr/Oct ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXP": {
        "symbol": "SIXP",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 Mar/Sep ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXS": {
        "symbol": "SIXS",
        "name": "ETC 6 Meridian Small Cap Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIXZ": {
        "symbol": "SIXZ",
        "name": "AllianzIM U.S. Large Cap 6 Month Buffer10 May/Nov ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SIZE": {
        "symbol": "SIZE",
        "name": "iShares MSCI USA Size Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJB": {
        "symbol": "SJB",
        "name": "ProShares Short High Yield",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJCP": {
        "symbol": "SJCP",
        "name": "SanJac Alpha Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJLD": {
        "symbol": "SJLD",
        "name": "SanJac Alpha Low Duration ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJM": {
        "symbol": "SJM",
        "name": "The J.M. Smucker Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJNK": {
        "symbol": "SJNK",
        "name": "SPDR Bloomberg Short Term High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJT": {
        "symbol": "SJT",
        "name": "San Juan Basin Royalty Trust UBI",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SJW": {
        "symbol": "SJW",
        "name": "SJW Group Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKE": {
        "symbol": "SKE",
        "name": "Skeena Resources Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKF": {
        "symbol": "SKF",
        "name": "ProShares UltraShort Financials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKGRU": {
        "symbol": "SKGRU",
        "name": "SK Growth Opportunities Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKIL": {
        "symbol": "SKIL",
        "name": "Skillsoft Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKK": {
        "symbol": "SKK",
        "name": "SKK Holdings Limited Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKLZ": {
        "symbol": "SKLZ",
        "name": "Skillz Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKM": {
        "symbol": "SKM",
        "name": "SK Telecom Co., Ltd.  American Depositary Shares (Each representing 5/9th of a Ordinary Share of SK",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKOR": {
        "symbol": "SKOR",
        "name": "FlexShares Credit-Scored US Corporate Bond Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKRE": {
        "symbol": "SKRE",
        "name": "Tuttle Capital Daily 2X Inverse Regional Banks ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKT": {
        "symbol": "SKT",
        "name": "Tanger Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKX": {
        "symbol": "SKX",
        "name": "Skechers U.S.A., Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKY": {
        "symbol": "SKY",
        "name": "Champion Homes, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKYH": {
        "symbol": "SKYH",
        "name": "Sky Harbour Group Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKYH WS": {
        "symbol": "SKYH WS",
        "name": "Sky Harbour Group Corporation Warrants, each whole warrant exercisable for one share of Class A comm",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKYQ": {
        "symbol": "SKYQ",
        "name": "Sky Quarry Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKYU": {
        "symbol": "SKYU",
        "name": "ProShares Ultra Nasdaq Cloud Computing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SKYY": {
        "symbol": "SKYY",
        "name": "First Trust Cloud Computing ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLB": {
        "symbol": "SLB",
        "name": "Schlumberger Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLDR": {
        "symbol": "SLDR",
        "name": "Global X Short-Term Treasury Ladder ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLF": {
        "symbol": "SLF",
        "name": "Sun Life Financial Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLG": {
        "symbol": "SLG",
        "name": "SL Green Realty Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLG PRI": {
        "symbol": "SLG PRI",
        "name": "SL GREEN REALTY CORP 6.50% Series I Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLGN": {
        "symbol": "SLGN",
        "name": "Silgan Holdings Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLI": {
        "symbol": "SLI",
        "name": "Standard Lithium Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLND": {
        "symbol": "SLND",
        "name": "Southland Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLND WS": {
        "symbol": "SLND WS",
        "name": "Southland Holdings, Inc. Redeemable Warrants, each whole warrant exercisable for shares of common st",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLQD": {
        "symbol": "SLQD",
        "name": "iShares 0-5 Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLQT": {
        "symbol": "SLQT",
        "name": "SelectQuote, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLSR": {
        "symbol": "SLSR",
        "name": "Solaris Resources Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLV": {
        "symbol": "SLV",
        "name": "iShares Silver Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLVM": {
        "symbol": "SLVM",
        "name": "Sylvamo Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLVO": {
        "symbol": "SLVO",
        "name": "UBS AG ETRACS Silver Shares Covered Call ETNs due April 21, 2033",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLVP": {
        "symbol": "SLVP",
        "name": "iShares MSCI Global Silver and Metals Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLX": {
        "symbol": "SLX",
        "name": "VanEck Steel ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLYG": {
        "symbol": "SLYG",
        "name": "SPDR S&P 600 Small Cap Growth ETF (based on S&P SmallCap 600 Growth Index--symbol: CKG)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SLYV": {
        "symbol": "SLYV",
        "name": "SPDR S&P 600 Small Cap Value ETF (based on S&P SmallCap 600 Value Index symbol--CVK)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SM": {
        "symbol": "SM",
        "name": "SM Energy Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMAR": {
        "symbol": "SMAR",
        "name": "Smartsheet Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMAX": {
        "symbol": "SMAX",
        "name": "iShares Large Cap Max Buffer Sep ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMAY": {
        "symbol": "SMAY",
        "name": "FT Vest U.S. Small Cap Moderate Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMB": {
        "symbol": "SMB",
        "name": "VanEck Short Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMBK": {
        "symbol": "SMBK",
        "name": "SmartFinancial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMC": {
        "symbol": "SMC",
        "name": "Summit Midstream Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMCF": {
        "symbol": "SMCF",
        "name": "Themes US Small Cap Cash Flow Champions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMCO": {
        "symbol": "SMCO",
        "name": "Hilton Small-MidCap Opportunity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMCP": {
        "symbol": "SMCP",
        "name": "AlphaMark Actively Managed Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMCX": {
        "symbol": "SMCX",
        "name": "Defiance Daily Target 2X Long SMCI ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMCY": {
        "symbol": "SMCY",
        "name": "YieldMax SMCI Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMDD": {
        "symbol": "SMDD",
        "name": "ProShares UltraPro Short MidCap400",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMDV": {
        "symbol": "SMDV",
        "name": "ProShares Russell 2000 Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMFG": {
        "symbol": "SMFG",
        "name": "Sumitomo Mitsui Financial Group, Inc American Depositary Shares (each representing 3/5th of a share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMG": {
        "symbol": "SMG",
        "name": "The Scotts Miracle-Gro Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMH": {
        "symbol": "SMH",
        "name": "VanEck Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMHB": {
        "symbol": "SMHB",
        "name": "ETRACS 2xMonthly Pay Leveraged US Small Cap High Dividend ETN Series B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMHI": {
        "symbol": "SMHI",
        "name": "SEACOR Marine Holdings Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMHX": {
        "symbol": "SMHX",
        "name": "VanEck Fabless Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMI": {
        "symbol": "SMI",
        "name": "VanEck HIP Sustainable Muni ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMIG": {
        "symbol": "SMIG",
        "name": "Bahl & Gaynor Small/Mid Cap Income Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMIN": {
        "symbol": "SMIN",
        "name": "iShares MSCI India Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMIZ": {
        "symbol": "SMIZ",
        "name": "Zacks Small/Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMLF": {
        "symbol": "SMLF",
        "name": "iShares U.S. Small Cap Equity Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMLL": {
        "symbol": "SMLL",
        "name": "Harbor Active Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMLV": {
        "symbol": "SMLV",
        "name": "SPDR SSGA US Small Cap Low Volatility Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMMD": {
        "symbol": "SMMD",
        "name": "iShares Russell 2500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMMU": {
        "symbol": "SMMU",
        "name": "PIMCO Short Term Municipal Bond Active Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMMV": {
        "symbol": "SMMV",
        "name": "iShares MSCI USA Small-Cap Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMN": {
        "symbol": "SMN",
        "name": "ProShares UltraShort Materials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMOG": {
        "symbol": "SMOG",
        "name": "VanEck Low Carbon Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMOT": {
        "symbol": "SMOT",
        "name": "VanEck Morningstar SMID Moat ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMP": {
        "symbol": "SMP",
        "name": "Standard Motor Products",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMR": {
        "symbol": "SMR",
        "name": "NuScale Power Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMR WS": {
        "symbol": "SMR WS",
        "name": "NuScale Power Corporation Warrants, each exercisable for one share of Class A Common Stock at an exe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMRI": {
        "symbol": "SMRI",
        "name": "Bushido Capital US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMRT": {
        "symbol": "SMRT",
        "name": "SmartRent, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMST": {
        "symbol": "SMST",
        "name": "Defiance Daily Target 1.5x Short MSTR ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMTH": {
        "symbol": "SMTH",
        "name": "ALPS Smith Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SMWB": {
        "symbol": "SMWB",
        "name": "Similarweb Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SN": {
        "symbol": "SN",
        "name": "SharkNinja, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNA": {
        "symbol": "SNA",
        "name": "Snap-on Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNAP": {
        "symbol": "SNAP",
        "name": "Snap Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNAV": {
        "symbol": "SNAV",
        "name": "Mohr Sector Nav ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNDA": {
        "symbol": "SNDA",
        "name": "Sonida Senior Living, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNDR": {
        "symbol": "SNDR",
        "name": "Schneider National, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNN": {
        "symbol": "SNN",
        "name": "Smith & Nephew plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNOV": {
        "symbol": "SNOV",
        "name": "FT Vest U.S. Small Cap Moderate Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNOW": {
        "symbol": "SNOW",
        "name": "Snowflake Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNOY": {
        "symbol": "SNOY",
        "name": "YieldMax SNOW Option Income Strategy  ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNPD": {
        "symbol": "SNPD",
        "name": "Xtrackers S&P ESG Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNPE": {
        "symbol": "SNPE",
        "name": "Xtrackers S&P 500 ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNPG": {
        "symbol": "SNPG",
        "name": "Xtrackers S&P 500 Growth ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNPV": {
        "symbol": "SNPV",
        "name": "Xtrackers S&P 500 Value ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNSR": {
        "symbol": "SNSR",
        "name": "Global X Funds Global X Internet of Things ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNV": {
        "symbol": "SNV",
        "name": "Synovus Financial Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNV PRD": {
        "symbol": "SNV PRD",
        "name": "Synovus Financial Corp. Fixed-to-Floating Rate Non-Cumulative Perpetual Preferred Stock, Series D Li",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNV PRE": {
        "symbol": "SNV PRE",
        "name": "Synovus Financial Corp. 5.875% Fixed-Rate Reset Non-Cumulative Perpetual Preferred Stock, Series E",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SNX": {
        "symbol": "SNX",
        "name": "TD SYNNEX Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SO": {
        "symbol": "SO",
        "name": "The Southern Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOAR": {
        "symbol": "SOAR",
        "name": "Volato Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOBO": {
        "symbol": "SOBO",
        "name": "South Bow Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOC": {
        "symbol": "SOC",
        "name": "Sable Offshore Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOC WS": {
        "symbol": "SOC WS",
        "name": "Sable Offshore Corp. Warrants, each whole warrant exercisable for one share of Common Stock at an ex",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOCL": {
        "symbol": "SOCL",
        "name": "Global X Funds Global X Social Media ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOFR": {
        "symbol": "SOFR",
        "name": "Amplify Samsung SOFR ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOJC": {
        "symbol": "SOJC",
        "name": "The Southern Company Series 2017B 5.25% Junior Subordinated Notes due December 1, 2077",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOJD": {
        "symbol": "SOJD",
        "name": "The Southern Company Series 2020A 4.95% Junior Subordinated Notes due January 30, 2080",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOJE": {
        "symbol": "SOJE",
        "name": "The Southern Company Series 2020C 4.20% Junior Subordinated Notes due October 15, 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOL": {
        "symbol": "SOL",
        "name": "Emeren Group Ltd American Depositary Shares, each representing 10 shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOLR": {
        "symbol": "SOLR",
        "name": "SmartETFs Sustainable Energy II ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOLV": {
        "symbol": "SOLV",
        "name": "Solventum Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SON": {
        "symbol": "SON",
        "name": "Sonoco Products Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SONY": {
        "symbol": "SONY",
        "name": "Sony Group Corporation American Depositary Shares (Each Representing One Share of Dollar Validated C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOR": {
        "symbol": "SOR",
        "name": "Source Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOS": {
        "symbol": "SOS",
        "name": "SOS Limited American Depositary Shares, each representing ten (10) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOVF": {
        "symbol": "SOVF",
        "name": "Sovereign's Capital Flourish Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXL": {
        "symbol": "SOXL",
        "name": "Direxion Daily Semiconductor Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXM": {
        "symbol": "SOXM",
        "name": "Tradr 2X Long SOXX Monthly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXQ": {
        "symbol": "SOXQ",
        "name": "Invesco PHLX Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXS": {
        "symbol": "SOXS",
        "name": "Direxion Daily Semiconductor Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXW": {
        "symbol": "SOXW",
        "name": "Tradr 2X Long SOXX Weekly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOXX": {
        "symbol": "SOXX",
        "name": "iShares Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SOYB": {
        "symbol": "SOYB",
        "name": "Teucrium Soybean Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPAB": {
        "symbol": "SPAB",
        "name": "SPDR Portfolio Aggregate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPAM": {
        "symbol": "SPAM",
        "name": "Themes Cybersecurity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPAQ": {
        "symbol": "SPAQ",
        "name": "Horizon Kinetics SPAC Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPAX": {
        "symbol": "SPAX",
        "name": "Robinson Alternative Yield Pre-merger SPAC ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPB": {
        "symbol": "SPB",
        "name": "Spectrum Brands Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPBC": {
        "symbol": "SPBC",
        "name": "Simplify U.S. Equity PLUS GBTC ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPBO": {
        "symbol": "SPBO",
        "name": "SPDR Portfolio Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPC": {
        "symbol": "SPC",
        "name": "CrossingBridge Pre-Merger SPAC ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPCE": {
        "symbol": "SPCE",
        "name": "Virgin Galactic Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPCX": {
        "symbol": "SPCX",
        "name": "The SPAC and New Issue ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPCZ": {
        "symbol": "SPCZ",
        "name": "RiverNorth Enhanced Pre-Merger SPAC ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPD": {
        "symbol": "SPD",
        "name": "Simplify US Equity PLUS Downside Convexity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPDG": {
        "symbol": "SPDG",
        "name": "SPDR Portfolio S&P Sector Neutral Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPDN": {
        "symbol": "SPDN",
        "name": "Direxion Daily S&P 500 Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPDV": {
        "symbol": "SPDV",
        "name": "AAM S&P 500 High Dividend Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPDW": {
        "symbol": "SPDW",
        "name": "SPDR Portfolio Developed World ex-US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPE": {
        "symbol": "SPE",
        "name": "Special Opportunities Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPE PRC": {
        "symbol": "SPE PRC",
        "name": "Special Opportunities Fund, Inc. 2.75% Convertible Preferred Stock, Series C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPEM": {
        "symbol": "SPEM",
        "name": "SPDR Portfolio Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPEU": {
        "symbol": "SPEU",
        "name": "SPDR Portfolio Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPFF": {
        "symbol": "SPFF",
        "name": "Global X SuperIncome Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPG": {
        "symbol": "SPG",
        "name": "Simon Property Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPG PRJ": {
        "symbol": "SPG PRJ",
        "name": "Simon Property Group, Inc. 8 3/8% Series J",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPGI": {
        "symbol": "SPGI",
        "name": "S&P Global Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPGM": {
        "symbol": "SPGM",
        "name": "SPDR Portfolio MSCI Global Stock Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPGP": {
        "symbol": "SPGP",
        "name": "Invesco S&P 500 GARP ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPH": {
        "symbol": "SPH",
        "name": "Suburban Propane Partners L P",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPHB": {
        "symbol": "SPHB",
        "name": "Invesco S&P 500 High Beta ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPHD": {
        "symbol": "SPHD",
        "name": "Invesco S&P 500 High Dividend Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPHQ": {
        "symbol": "SPHQ",
        "name": "Invesco S&P 500 Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPHR": {
        "symbol": "SPHR",
        "name": "Sphere Entertainment Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPHY": {
        "symbol": "SPHY",
        "name": "SPDR Portfolio High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPIB": {
        "symbol": "SPIB",
        "name": "SPDR Portfolio Intermediate Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPIN": {
        "symbol": "SPIN",
        "name": "SPDR SSGA US Equity Premium Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPIP": {
        "symbol": "SPIP",
        "name": "SPDR Portfolio TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPIR": {
        "symbol": "SPIR",
        "name": "Spire Global, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPLB": {
        "symbol": "SPLB",
        "name": "SPDR Portfolio Long Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPLG": {
        "symbol": "SPLG",
        "name": "SPDR Portfolio S&P 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPLP": {
        "symbol": "SPLP",
        "name": "STEEL PARTNERS HOLDINGS L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPLP PRA": {
        "symbol": "SPLP PRA",
        "name": "Steel Partners Holdings L.P. 6.0% Series A Preferred Units, no par value",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPLV": {
        "symbol": "SPLV",
        "name": "Invesco S&P 500 Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPMB": {
        "symbol": "SPMB",
        "name": "SPDR Portfolio Mortgage Backed Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPMC": {
        "symbol": "SPMC",
        "name": "Sound Point Meridian Capital, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPMD": {
        "symbol": "SPMD",
        "name": "SPDR Portfolio S&P 400 Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPMO": {
        "symbol": "SPMO",
        "name": "Invesco S&P 500 Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPMV": {
        "symbol": "SPMV",
        "name": "Invesco S&P 500 Minimum Variance ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPNT": {
        "symbol": "SPNT",
        "name": "SiriusPoint Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPNT PRB": {
        "symbol": "SPNT PRB",
        "name": "SiriusPoint Ltd. 8.00% Resettable Fixed Rate Preference Shares, Series B, $25.00 liquidation prefere",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPOT": {
        "symbol": "SPOT",
        "name": "Spotify Technology S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPPP": {
        "symbol": "SPPP",
        "name": "Sprott Physical Platinum and Palladium Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPQ": {
        "symbol": "SPQ",
        "name": "Simplify US Equity PLUS QIS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPR": {
        "symbol": "SPR",
        "name": "Spirit Aerosystems Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPRE": {
        "symbol": "SPRE",
        "name": "SP Funds S&P Global REIT Sharia ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPRU": {
        "symbol": "SPRU",
        "name": "Spruce Power Holding Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPRX": {
        "symbol": "SPRX",
        "name": "Spear Alpha ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPSB": {
        "symbol": "SPSB",
        "name": "SPDR Portfolio Short Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPSK": {
        "symbol": "SPSK",
        "name": "SP Funds Dow Jones Global Sukuk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPSM": {
        "symbol": "SPSM",
        "name": "SPDR Portfolio S&P 600 Small Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTB": {
        "symbol": "SPTB",
        "name": "SPDR Portfolio Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTE": {
        "symbol": "SPTE",
        "name": "SP Funds S&P Global Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTI": {
        "symbol": "SPTI",
        "name": "SPDR Portfolio Intermediate Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTL": {
        "symbol": "SPTL",
        "name": "SPDR Portfolio Long Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTM": {
        "symbol": "SPTM",
        "name": "SPDR Portfolio S&P 1500 Composite Stock Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPTS": {
        "symbol": "SPTS",
        "name": "SPDR Portfolio Short Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPUC": {
        "symbol": "SPUC",
        "name": "Simplify US Equity PLUS Upside Convexity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPUS": {
        "symbol": "SPUS",
        "name": "SP Funds S&P 500 Sharia  ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPUU": {
        "symbol": "SPUU",
        "name": "Direxion Daily S&P 500 Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPVM": {
        "symbol": "SPVM",
        "name": "Invesco S&P 500 Value with Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPVU": {
        "symbol": "SPVU",
        "name": "Invesco S&P 500 Enhanced Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPWO": {
        "symbol": "SPWO",
        "name": "SP Funds S&P World (ex-US) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXC": {
        "symbol": "SPXC",
        "name": "SPX Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXE": {
        "symbol": "SPXE",
        "name": "ProShares S&P 500 Ex-Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXL": {
        "symbol": "SPXL",
        "name": "Direxion Daily S&P 500  Bull 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXN": {
        "symbol": "SPXN",
        "name": "ProShares S&P 500 Ex-Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXS": {
        "symbol": "SPXS",
        "name": "Direxion Daily S&P 500 Bear 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXT": {
        "symbol": "SPXT",
        "name": "ProShares S&P 500 Ex-Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXU": {
        "symbol": "SPXU",
        "name": "ProShares UltraPro Short S&P 500",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXV": {
        "symbol": "SPXV",
        "name": "ProShares S&P 500 Ex-Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPXX": {
        "symbol": "SPXX",
        "name": "NUVEEN S&P 500 DYNAMIC OVERWRITE FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPY": {
        "symbol": "SPY",
        "name": "SPDR S&P 500 ETF Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYB": {
        "symbol": "SPYB",
        "name": "Tradr 2X Long SPY Weekly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYC": {
        "symbol": "SPYC",
        "name": "Simplify US Equity PLUS Convexity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYD": {
        "symbol": "SPYD",
        "name": "SPDR Portfolio S&P 500 High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYG": {
        "symbol": "SPYG",
        "name": "SPDR Portfolio S&P 500 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYI": {
        "symbol": "SPYI",
        "name": "NEOS S&P 500 High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYM": {
        "symbol": "SPYM",
        "name": "Tradr 2X Long SPY Monthly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYQ": {
        "symbol": "SPYQ",
        "name": "Tradr 2X Long SPY Quarterly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYT": {
        "symbol": "SPYT",
        "name": "Defiance S&P 500 Income Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYU": {
        "symbol": "SPYU",
        "name": "MAX S&P 500 4x Leveraged ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYV": {
        "symbol": "SPYV",
        "name": "SPDR Portfolio S&P 500 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SPYX": {
        "symbol": "SPYX",
        "name": "SPDR S&P 500 Fossil Fuel Free ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQ": {
        "symbol": "SQ",
        "name": "Block, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQEW": {
        "symbol": "SQEW",
        "name": "LeaderShares Equity Skew ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQLV": {
        "symbol": "SQLV",
        "name": "Royce Quant Small-Cap Quality Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQM": {
        "symbol": "SQM",
        "name": "Sociedad Quimica y Minera de Chile SA",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQNS": {
        "symbol": "SQNS",
        "name": "Sequans Communications S.A. American Depositary Shares (each representing ten (10) Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQQQ": {
        "symbol": "SQQQ",
        "name": "ProShares UltraPro Short QQQ",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQSP": {
        "symbol": "SQSP",
        "name": "Squarespace, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SQY": {
        "symbol": "SQY",
        "name": "YieldMax SQ Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SR": {
        "symbol": "SR",
        "name": "Spire Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SR PRA": {
        "symbol": "SR PRA",
        "name": "Spire Inc. Depositary Shares, each representing a 1/1,000th interest in a share of 5.90% Series A Cu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRE": {
        "symbol": "SRE",
        "name": "Sempra",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SREA": {
        "symbol": "SREA",
        "name": "Sempra 5.750% Junior Subordinated Notes due 2079",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRET": {
        "symbol": "SRET",
        "name": "Global X SuperDividend REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRFM": {
        "symbol": "SRFM",
        "name": "Surf Air Mobility Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRG": {
        "symbol": "SRG",
        "name": "Seritage Growth Properties Class A common shares of beneficial interest, par value $0.01",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRG PRA": {
        "symbol": "SRG PRA",
        "name": "Seritage Growth Properties 7.00% Series A Cumulative Redeemable Preferred Shares of Beneficial Inter",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRHQ": {
        "symbol": "SRHQ",
        "name": "SRH U.S. Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRHR": {
        "symbol": "SRHR",
        "name": "SRH REIT Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRI": {
        "symbol": "SRI",
        "name": "Stoneridge, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRL": {
        "symbol": "SRL",
        "name": "Scully Royalty Ltd. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRLN": {
        "symbol": "SRLN",
        "name": "SPDR Blackstone Senior Loan ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SROI": {
        "symbol": "SROI",
        "name": "Calamos Antetokounmpo Global Sustainable Equities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRS": {
        "symbol": "SRS",
        "name": "ProShares UltraShort Real Estate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRTY": {
        "symbol": "SRTY",
        "name": "ProShares UltraPro Short Russell2000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRV": {
        "symbol": "SRV",
        "name": "NXG Cushing Midstream Energy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SRVR": {
        "symbol": "SRVR",
        "name": "Pacer Data & Infrastructure Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSB": {
        "symbol": "SSB",
        "name": "SouthState Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSD": {
        "symbol": "SSD",
        "name": "Simpson Manufacturing Co., Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSFI": {
        "symbol": "SSFI",
        "name": "Day Hagan/Ned Davis Research Smart Sector Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSG": {
        "symbol": "SSG",
        "name": "ProShares UltraShort Semiconductors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSL": {
        "symbol": "SSL",
        "name": "Sasol Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSO": {
        "symbol": "SSO",
        "name": "ProShares Ultra S&P500",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSPX": {
        "symbol": "SSPX",
        "name": "Janus Henderson US Sustainable EquityETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSPY": {
        "symbol": "SSPY",
        "name": "Stratified LargeCap Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SST": {
        "symbol": "SST",
        "name": "System1, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SST WS": {
        "symbol": "SST WS",
        "name": "System1, Inc. Warrants, each whole warrant exercisable for one share of Class A Common Stock at an e",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSTK": {
        "symbol": "SSTK",
        "name": "SHUTTERSTOCK, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSUS": {
        "symbol": "SSUS",
        "name": "Day Hagan Ned Davis Research Smart Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSXU": {
        "symbol": "SSXU",
        "name": "Day Hagan/Ned Davis Research Smart Sector International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SSY": {
        "symbol": "SSY",
        "name": "SunLink Health Systems, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ST": {
        "symbol": "ST",
        "name": "Sensata Technologies Holding plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STAG": {
        "symbol": "STAG",
        "name": "STAG INDUSTRIAL, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STAX": {
        "symbol": "STAX",
        "name": "Macquarie Tax-Free USA Short Term ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STBF": {
        "symbol": "STBF",
        "name": "Performance Trust Short Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STC": {
        "symbol": "STC",
        "name": "Stewart Information Services Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STCE": {
        "symbol": "STCE",
        "name": "Schwab Crypto Thematic ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STE": {
        "symbol": "STE",
        "name": "STERIS plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STEL": {
        "symbol": "STEL",
        "name": "Stellar Bancorp, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STEM": {
        "symbol": "STEM",
        "name": "Stem, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STEW": {
        "symbol": "STEW",
        "name": "SRH Total Return Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STG": {
        "symbol": "STG",
        "name": "Sunlands Technology Group, American Depositary Shares,  American Depositary Shares, every two (2) AD",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STIP": {
        "symbol": "STIP",
        "name": "iShares 0-5 Year TIPS Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STK": {
        "symbol": "STK",
        "name": "Columbia Seligman Premium Technology Growth Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STLA": {
        "symbol": "STLA",
        "name": "Stellantis N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STM": {
        "symbol": "STM",
        "name": "STMicroelectronics N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STN": {
        "symbol": "STN",
        "name": "Stantec, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STNC": {
        "symbol": "STNC",
        "name": "Hennessy Stance ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STNG": {
        "symbol": "STNG",
        "name": "Scorpio Tankers Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STOT": {
        "symbol": "STOT",
        "name": "SPDR DoubleLine Short Duration Total Return Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STPZ": {
        "symbol": "STPZ",
        "name": "PIMCO 1-5 Year U.S. TIPS Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STR": {
        "symbol": "STR",
        "name": "Sitio Royalties Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STRV": {
        "symbol": "STRV",
        "name": "Strive 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STRW": {
        "symbol": "STRW",
        "name": "Strawberry Fields REIT, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STT": {
        "symbol": "STT",
        "name": "State Street Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STT PRG": {
        "symbol": "STT PRG",
        "name": "State Street Corporation Depositary shares, each representing a 1/4,000th ownership interest in a sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STVN": {
        "symbol": "STVN",
        "name": "Stevanato Group S.p.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STWD": {
        "symbol": "STWD",
        "name": "STARWOOD PROPERTY TRUST, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXD": {
        "symbol": "STXD",
        "name": "Strive 1000 Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXE": {
        "symbol": "STXE",
        "name": "Strive Emerging Markets Ex-China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXG": {
        "symbol": "STXG",
        "name": "Strive 1000 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXI": {
        "symbol": "STXI",
        "name": "Strive International Developed Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXK": {
        "symbol": "STXK",
        "name": "EA Series Trust Strive Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXM": {
        "symbol": "STXM",
        "name": "Strive MidCap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXS": {
        "symbol": "STXS",
        "name": "Stereotaxis, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXT": {
        "symbol": "STXT",
        "name": "Strive Total Return Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STXV": {
        "symbol": "STXV",
        "name": "Strive 1000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "STZ": {
        "symbol": "STZ",
        "name": "Constellation Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SU": {
        "symbol": "SU",
        "name": "Suncor Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUB": {
        "symbol": "SUB",
        "name": "iShares Short-Term National Muni Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUI": {
        "symbol": "SUI",
        "name": "Sun Communities, Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUM": {
        "symbol": "SUM",
        "name": "Summit Materials, Inc. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUN": {
        "symbol": "SUN",
        "name": "SUNOCO L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUP": {
        "symbol": "SUP",
        "name": "Superior Industries International, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUPL": {
        "symbol": "SUPL",
        "name": "ProShares Supply Chain Logistics ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUPP": {
        "symbol": "SUPP",
        "name": "TCW Transform Supply Chain ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUPV": {
        "symbol": "SUPV",
        "name": "Grupo Supervielle S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SURE": {
        "symbol": "SURE",
        "name": "AdvisorShares Insider Advantage ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SURI": {
        "symbol": "SURI",
        "name": "Simplify Propel Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUSA": {
        "symbol": "SUSA",
        "name": "iShares MSCI USA ESG Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUSB": {
        "symbol": "SUSB",
        "name": "iShares Trust iShares ESG Aware 1-5 Year USD Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUSC": {
        "symbol": "SUSC",
        "name": "iShares Trust iShares ESG Aware USD Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUSL": {
        "symbol": "SUSL",
        "name": "iShares ESG MSCI USA Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SUZ": {
        "symbol": "SUZ",
        "name": "Suzano S.A. American Depositary Shares (each representing one Ordinary Share ) representing one Ordi",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVA": {
        "symbol": "SVA",
        "name": "Sinovac Biotech, Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVAL": {
        "symbol": "SVAL",
        "name": "iShares US Small Cap Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVIIU": {
        "symbol": "SVIIU",
        "name": "Spring Valley Acquisition Corp. II Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVIX": {
        "symbol": "SVIX",
        "name": "-1x Short VIX Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVM": {
        "symbol": "SVM",
        "name": "Silvercorp Metals Inc. Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVOL": {
        "symbol": "SVOL",
        "name": "Simplify Volatility Premium ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVT": {
        "symbol": "SVT",
        "name": "Servotronics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVV": {
        "symbol": "SVV",
        "name": "Savers Value Village, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SVXY": {
        "symbol": "SVXY",
        "name": "ProShares Short VIX Short-Term Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SW": {
        "symbol": "SW",
        "name": "Smurfit Westrock plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWAN": {
        "symbol": "SWAN",
        "name": "Amplify BlackSwan Growth & Treasure Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWI": {
        "symbol": "SWI",
        "name": "SolarWinds Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWK": {
        "symbol": "SWK",
        "name": "Stanley Black & Decker, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWP": {
        "symbol": "SWP",
        "name": "SWP Growth & Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWX": {
        "symbol": "SWX",
        "name": "Southwest Gas Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SWZ": {
        "symbol": "SWZ",
        "name": "Swiss Helvetia Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SXC": {
        "symbol": "SXC",
        "name": "SUNCOKE ENERGY INC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SXI": {
        "symbol": "SXI",
        "name": "Standex International Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SXQG": {
        "symbol": "SXQG",
        "name": "ETC 6 Meridian Quality Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SXT": {
        "symbol": "SXT",
        "name": "Sensient Technology Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SXUS": {
        "symbol": "SXUS",
        "name": "Janus Henderson International Sustainable Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYF": {
        "symbol": "SYF",
        "name": "SYNCHRONY FINANCIAL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYF PRA": {
        "symbol": "SYF PRA",
        "name": "Synchrony Financial Depositary Shares, each Representing a 1/40th Interest in a Share of 5.625% Fixe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYF PRB": {
        "symbol": "SYF PRB",
        "name": "Synchrony Financial Depositary Shares Each Representing a 1/40th Interest in a Share of 8.250% Fixed",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYFI": {
        "symbol": "SYFI",
        "name": "AB Short Duration High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYK": {
        "symbol": "SYK",
        "name": "Stryker Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYLD": {
        "symbol": "SYLD",
        "name": "Cambria Shareholder Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYNB": {
        "symbol": "SYNB",
        "name": "Putnam BioRevolution ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYNX": {
        "symbol": "SYNX",
        "name": "Silynxcom Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SYY": {
        "symbol": "SYY",
        "name": "Sysco Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SZK": {
        "symbol": "SZK",
        "name": "ProShares UltraShort Consumer Staples",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "SZNE": {
        "symbol": "SZNE",
        "name": "Pacer CFRA- Stoval Equal Weight Seasonal Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "T": {
        "symbol": "T",
        "name": "AT&T Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "T PRA": {
        "symbol": "T PRA",
        "name": "AT&T Inc. Depositary Shares, each representing a 1/1,000th interest in a share of 5.000% Perpetual P",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "T PRC": {
        "symbol": "T PRC",
        "name": "AT&T Inc. Depositary Shares, each representing a 1/1,000th interest in a share of 4.750% Perpetual P",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAC": {
        "symbol": "TAC",
        "name": "TransAlta Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TACK": {
        "symbol": "TACK",
        "name": "Fairlead Tactical Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAFI": {
        "symbol": "TAFI",
        "name": "AB Tax-Aware Short Duration Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAFL": {
        "symbol": "TAFL",
        "name": "AB Tax-Aware Long Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAFM": {
        "symbol": "TAFM",
        "name": "AB Tax-Aware Intermediate Municipal ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAGG": {
        "symbol": "TAGG",
        "name": "T. Rowe Price QM U.S. Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAGS": {
        "symbol": "TAGS",
        "name": "Teucrium Agricultural Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAIL": {
        "symbol": "TAIL",
        "name": "Cambria Tail Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAK": {
        "symbol": "TAK",
        "name": "Takeda Pharmaceutical Company Limited American Depositary Shares (each representing 1/2 of a share o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAL": {
        "symbol": "TAL",
        "name": "TAL Education Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TALO": {
        "symbol": "TALO",
        "name": "Talos Energy, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAN": {
        "symbol": "TAN",
        "name": "Invesco Solar ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAP": {
        "symbol": "TAP",
        "name": "Molson Coors Beverage Company Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAP A": {
        "symbol": "TAP A",
        "name": "Molson Coors Beverage Company Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TARK": {
        "symbol": "TARK",
        "name": "Investment Managers Series Trust II Tradr 2X Long Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAXE": {
        "symbol": "TAXE",
        "name": "Intermediate Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAXF": {
        "symbol": "TAXF",
        "name": "American Century Diversified Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TAXX": {
        "symbol": "TAXX",
        "name": "BondBloxx IR+M Tax Aware Short Duration ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBB": {
        "symbol": "TBB",
        "name": "AT&T Inc. 5.350 Percent Global Notes due 2066",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBBB": {
        "symbol": "TBBB",
        "name": "BBB Foods Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBC": {
        "symbol": "TBC",
        "name": "AT&T Inc. 5.625% Global Notes due 2067",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBF": {
        "symbol": "TBF",
        "name": "ProShares Short 20+ Year Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBFC": {
        "symbol": "TBFC",
        "name": "The Brinsmere Fund - Conservative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBFG": {
        "symbol": "TBFG",
        "name": "The Brinsmere Fund - Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBG": {
        "symbol": "TBG",
        "name": "TBG Dividend Focus ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBI": {
        "symbol": "TBI",
        "name": "Trueblue, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBIL": {
        "symbol": "TBIL",
        "name": "US Treasury 3 Month Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBJL": {
        "symbol": "TBJL",
        "name": "Innovator 20+ Year Treasury Bond 9 Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBLL": {
        "symbol": "TBLL",
        "name": "Invesco Short Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBLU": {
        "symbol": "TBLU",
        "name": "Tortoise Global Water ESG Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBN": {
        "symbol": "TBN",
        "name": "Tamboran Resources Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBT": {
        "symbol": "TBT",
        "name": "ProShares Trust UltraShort Lehman 20+ Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBUX": {
        "symbol": "TBUX",
        "name": "T. Rowe Price Ultra Short-Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TBX": {
        "symbol": "TBX",
        "name": "ProShares Short 7-10 Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TCAF": {
        "symbol": "TCAF",
        "name": "T. Rowe Price Capital Appreciation Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TCHI": {
        "symbol": "TCHI",
        "name": "iShares MSCI China Multisector Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TCHP": {
        "symbol": "TCHP",
        "name": "T. Rowe Price Blue Chip Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TCI": {
        "symbol": "TCI",
        "name": "Transcontinental Realty Investors, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TCS": {
        "symbol": "TCS",
        "name": "THE CONTAINER STORE GROUP, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TD": {
        "symbol": "TD",
        "name": "Toronto Dominion Bank",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDC": {
        "symbol": "TDC",
        "name": "TERADATA CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDF": {
        "symbol": "TDF",
        "name": "Templeton Dragon Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDG": {
        "symbol": "TDG",
        "name": "TransDigm Group Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDI": {
        "symbol": "TDI",
        "name": "Touchstone Dynamic International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDIV": {
        "symbol": "TDIV",
        "name": "First Trust Exchange-Traded Fund VI First Trust NASDAQ Technology Dividend Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDOC": {
        "symbol": "TDOC",
        "name": "Teladoc Health, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDS": {
        "symbol": "TDS",
        "name": "Telephone and Data Systems Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDS PRU": {
        "symbol": "TDS PRU",
        "name": "Telephone and Data Systems, Inc. Depositary Shares, Each Representing a 1/1,000th Interest in a 6.62",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDS PRV": {
        "symbol": "TDS PRV",
        "name": "Telephone and Data Systems, Inc. Depositary Shares, Each Representing a 1/1,000th Interest in a 6.00",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDSB": {
        "symbol": "TDSB",
        "name": "Exchange Listed Funds Trust Cabana Target Beta ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDSC": {
        "symbol": "TDSC",
        "name": "Cabana Target Drawdown 10 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDTF": {
        "symbol": "TDTF",
        "name": "FlexShares iBoxx 5-Year Target Duration TIPS Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDTT": {
        "symbol": "TDTT",
        "name": "FlexShares iBoxx 3-Year Target Duration TIPS Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDV": {
        "symbol": "TDV",
        "name": "ProShares S&P Technology Dividend Aristocrats ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDVG": {
        "symbol": "TDVG",
        "name": "T. Rowe Price Dividend Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDVI": {
        "symbol": "TDVI",
        "name": "FT Vest Technology Dividend Target Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDW": {
        "symbol": "TDW",
        "name": "Tidewater, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDW WS": {
        "symbol": "TDW WS",
        "name": "Tidewater Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TDY": {
        "symbol": "TDY",
        "name": "Teledyne Technologies Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEAF": {
        "symbol": "TEAF",
        "name": "Tortoise Sustainable and Social Impact Term Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TECB": {
        "symbol": "TECB",
        "name": "iShares U.S. Tech Breakthrough Multisector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TECK": {
        "symbol": "TECK",
        "name": "Teck Resources Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TECL": {
        "symbol": "TECL",
        "name": "Direxion Daily Technology Bull 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TECS": {
        "symbol": "TECS",
        "name": "Direxion Daily Technology Bear 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEF": {
        "symbol": "TEF",
        "name": "Telefonica, S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEI": {
        "symbol": "TEI",
        "name": "Templeton Emerging Markets Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEKX": {
        "symbol": "TEKX",
        "name": "SPDR Galaxy Transformative Tech Accelerators ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEL": {
        "symbol": "TEL",
        "name": "TE Connectivity plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TELZ": {
        "symbol": "TELZ",
        "name": "Tellurian Inc. 8.25% Senior Notes due 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEMP": {
        "symbol": "TEMP",
        "name": "JPMorgan Climate Change Solutions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEN": {
        "symbol": "TEN",
        "name": "Tsakos Energy Navigation Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEN PRE": {
        "symbol": "TEN PRE",
        "name": "Tsakos Energy Navigation Limited Series E Fixed-to-Floating Rate Cumulative Redeemable Perpetual Pre",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEN PRF": {
        "symbol": "TEN PRF",
        "name": "Tsakos Energy Navigation Limited Series F Fixed-to-Floating Rate Cumulative Redeemable Perpetual Pre",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEO": {
        "symbol": "TEO",
        "name": "Telecom Argentina S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEQI": {
        "symbol": "TEQI",
        "name": "T. Rowe Price Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TETEU": {
        "symbol": "TETEU",
        "name": "Technology & Telecommunication Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TETEW": {
        "symbol": "TETEW",
        "name": "Technology & Telecommunication Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEVA": {
        "symbol": "TEVA",
        "name": "Teva Pharmaceutical Industries Limited American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TEX": {
        "symbol": "TEX",
        "name": "Terex Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFC": {
        "symbol": "TFC",
        "name": "Truist Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFC PRI": {
        "symbol": "TFC PRI",
        "name": "Truist Financial Corporation Depositary Shares, each representing 1/4,000th interest in a share of S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFC PRO": {
        "symbol": "TFC PRO",
        "name": "Truist Financial Corporation Depositary Shares, Each Representing a 1/1,000th Interest in a  Share o",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFC PRR": {
        "symbol": "TFC PRR",
        "name": "Truist Financial Corporation Depositary Shares, each representing 1/1,000th interest in a share of S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFI": {
        "symbol": "TFI",
        "name": "SPDR Nuveen Bloomberg Municipal Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFII": {
        "symbol": "TFII",
        "name": "TFI International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFJL": {
        "symbol": "TFJL",
        "name": "Innovator 20+ Year Treasury Bond 5 Floor ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFLO": {
        "symbol": "TFLO",
        "name": "iShares Treasury Floating Rate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFLR": {
        "symbol": "TFLR",
        "name": "T. Rowe Price Floating Rate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFPM": {
        "symbol": "TFPM",
        "name": "Triple Flag Precious Metals Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFPN": {
        "symbol": "TFPN",
        "name": "Blueprint Chesapeake Multi-Asset Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFSA": {
        "symbol": "TFSA",
        "name": "Terra Income Fund 6, LLC 7.00% Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TFX": {
        "symbol": "TFX",
        "name": "Teleflex Incorporated",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TG": {
        "symbol": "TG",
        "name": "Tredegar Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGAAU": {
        "symbol": "TGAAU",
        "name": "Target Global Acquisition I Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGB": {
        "symbol": "TGB",
        "name": "Taseko Mines Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGI": {
        "symbol": "TGI",
        "name": "Triumph Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGLR": {
        "symbol": "TGLR",
        "name": "LAFFER TENGLER Equity Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGLS": {
        "symbol": "TGLS",
        "name": "Tecnoglass Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGNA": {
        "symbol": "TGNA",
        "name": "TEGNA Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGRT": {
        "symbol": "TGRT",
        "name": "T. Rowe Price Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGRW": {
        "symbol": "TGRW",
        "name": "T. Rowe Price Growth Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGS": {
        "symbol": "TGS",
        "name": "Transportadora de Gas del Sur S.A. ADS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TGT": {
        "symbol": "TGT",
        "name": "Target Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THC": {
        "symbol": "THC",
        "name": "Tenet Healthcare Corporation New",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THCPU": {
        "symbol": "THCPU",
        "name": "Thunder Bridge Capital Partners IV, Inc. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THD": {
        "symbol": "THD",
        "name": "iShares MSCI Thailand ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THG": {
        "symbol": "THG",
        "name": "The Hanover Insurance Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THIR": {
        "symbol": "THIR",
        "name": "THOR Index Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THLV": {
        "symbol": "THLV",
        "name": "THOR Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THM": {
        "symbol": "THM",
        "name": "International Tower Hill Mines, Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THNQ": {
        "symbol": "THNQ",
        "name": "ROBO Global Artificial Intelligence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THNR": {
        "symbol": "THNR",
        "name": "Amplify Weight Loss Drug & Treatment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THO": {
        "symbol": "THO",
        "name": "Thor Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THQ": {
        "symbol": "THQ",
        "name": "abrdn Healthcare Opportunities Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THR": {
        "symbol": "THR",
        "name": "THERMON GROUP HOLDINGS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THRO": {
        "symbol": "THRO",
        "name": "iShares U.S. Thematic Rotation Active ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THS": {
        "symbol": "THS",
        "name": "Treehouse Foods, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THTA": {
        "symbol": "THTA",
        "name": "SoFi Enhanced Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THW": {
        "symbol": "THW",
        "name": "abrdn World Healthcare Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THY": {
        "symbol": "THY",
        "name": "Toews Agility Shares Dynamic Tactical Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "THYF": {
        "symbol": "THYF",
        "name": "T. Rowe Price U.S. High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TILL": {
        "symbol": "TILL",
        "name": "Teucrium Agricultural Strategy No K-1 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TILT": {
        "symbol": "TILT",
        "name": "FlexShares Morningstar US Market Factors Tilt Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIMB": {
        "symbol": "TIMB",
        "name": "TIM S.A. American Depositary Shares (Each representing 5 Common Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIME": {
        "symbol": "TIME",
        "name": "Clockwise Core Equity & Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TINT": {
        "symbol": "TINT",
        "name": "ProShares Smart Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TINY": {
        "symbol": "TINY",
        "name": "ProShares Nanotechnology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIP": {
        "symbol": "TIP",
        "name": "iShares TIPS Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIPX": {
        "symbol": "TIPX",
        "name": "SPDR Bloomberg 1-10 Year TIPS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIPZ": {
        "symbol": "TIPZ",
        "name": "PIMCO Broad U.S. TIPS Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TISI": {
        "symbol": "TISI",
        "name": "Team, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TIXT": {
        "symbol": "TIXT",
        "name": "TELUS International (Cda) Inc. Subordinate Voting Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TJUL": {
        "symbol": "TJUL",
        "name": "Innovator Equity Defined Protection ETF - 2 Yr to July 2025",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TJX": {
        "symbol": "TJX",
        "name": "TJX Companies, Inc. (The)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TK": {
        "symbol": "TK",
        "name": "Teekay Corporation Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TKC": {
        "symbol": "TKC",
        "name": "TURKCELL ILETISIM HIZMETLERI A.S. ADS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TKO": {
        "symbol": "TKO",
        "name": "TKO Group Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TKR": {
        "symbol": "TKR",
        "name": "The Timken Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLGY": {
        "symbol": "TLGY",
        "name": "TLGY Acquisition Corporation Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLH": {
        "symbol": "TLH",
        "name": "iShares 10-20 Year Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLK": {
        "symbol": "TLK",
        "name": "PT Telekomunikasi Indonesia",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLT": {
        "symbol": "TLT",
        "name": "iShares 20+ Year Treasury Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLTD": {
        "symbol": "TLTD",
        "name": "FlexShares Morningstar Developed Markets ex-US Factor Tilt Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLTE": {
        "symbol": "TLTE",
        "name": "FlexShares Morningstar Emerging Markets Factor Tilt Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLTM": {
        "symbol": "TLTM",
        "name": "Tradr 2X Long TLT Monthly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLTQ": {
        "symbol": "TLTQ",
        "name": "Tradr 1.75X Long TLT Quarterly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLTW": {
        "symbol": "TLTW",
        "name": "iShares 20+ Year Treasury Bond BuyWrite Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TLYS": {
        "symbol": "TLYS",
        "name": "Tilly's Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TM": {
        "symbol": "TM",
        "name": "Toyota Motor Corporation American Depositary Shares (Each representing ten Ordinary Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMAT": {
        "symbol": "TMAT",
        "name": "Main Thematic Innovation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMDV": {
        "symbol": "TMDV",
        "name": "ProShares Russell US Dividend Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TME": {
        "symbol": "TME",
        "name": "Tencent Music Entertainment Group American Depositary Shares, each representing two Class A Ordinary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMET": {
        "symbol": "TMET",
        "name": "iShares Transition-Enabling Metals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMF": {
        "symbol": "TMF",
        "name": "Direxion Daily 20+ Year Treasury Bull 3X Shares (based on the NYSE 20 Year Plus Treasury Bond Index;",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFC": {
        "symbol": "TMFC",
        "name": "Motley Fool 100 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFE": {
        "symbol": "TMFE",
        "name": "Motley Fool Capital Efficiency 100 Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFG": {
        "symbol": "TMFG",
        "name": "Motley Fool Global Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFM": {
        "symbol": "TMFM",
        "name": "Motley Fool Mid-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFS": {
        "symbol": "TMFS",
        "name": "Motley Fool Small-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMFX": {
        "symbol": "TMFX",
        "name": "Motley Fool Next Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMHC": {
        "symbol": "TMHC",
        "name": "Taylor Morrison Home Corporation Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMO": {
        "symbol": "TMO",
        "name": "Thermo Fisher Scientific, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMP": {
        "symbol": "TMP",
        "name": "Tompkins Financial Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMQ": {
        "symbol": "TMQ",
        "name": "Trilogy Metals Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMSL": {
        "symbol": "TMSL",
        "name": "T. Rowe Price Small-Mid Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMTCR": {
        "symbol": "TMTCR",
        "name": "TMT Acquisition Corp Rights",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMTCU": {
        "symbol": "TMTCU",
        "name": "TMT Acquisition Corp Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TMV": {
        "symbol": "TMV",
        "name": "Direxion Daily 20+ Year Treasury Bear 3X Shares (based on the NYSE 20 Year Plus Treasury Bond Index;",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TNA": {
        "symbol": "TNA",
        "name": "Direxion Daily Small Cap Bull 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TNC": {
        "symbol": "TNC",
        "name": "TENNANT COMPANY",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TNET": {
        "symbol": "TNET",
        "name": "TRINET GROUP, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TNK": {
        "symbol": "TNK",
        "name": "Teekay Tankers Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TNL": {
        "symbol": "TNL",
        "name": "Travel + Leisure Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOAK": {
        "symbol": "TOAK",
        "name": "Twin Oak Short Horizon Absolute Return ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOGA": {
        "symbol": "TOGA",
        "name": "Tremblant Global ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOK": {
        "symbol": "TOK",
        "name": "iShares MSCI Kokusai Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOKE": {
        "symbol": "TOKE",
        "name": "Cambria Cannabis ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOL": {
        "symbol": "TOL",
        "name": "Toll Brothers, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOLL": {
        "symbol": "TOLL",
        "name": "Tema Monopolies and Oligopolies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOLZ": {
        "symbol": "TOLZ",
        "name": "ProShares DJ Brookfield Global Infrastructure ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOON": {
        "symbol": "TOON",
        "name": "Kartoon Studios, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOPS": {
        "symbol": "TOPS",
        "name": "TOP Ships Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOST": {
        "symbol": "TOST",
        "name": "Toast, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOTL": {
        "symbol": "TOTL",
        "name": "SSgA Active ETF Trust SPDR DoubleLine Total Return Tactical ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOTR": {
        "symbol": "TOTR",
        "name": "T. Rowe Price Total Return ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOUS": {
        "symbol": "TOUS",
        "name": "T. Rowe Price International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TOVX": {
        "symbol": "TOVX",
        "name": "Theriva Biologics, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPB": {
        "symbol": "TPB",
        "name": "Turning Point Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPC": {
        "symbol": "TPC",
        "name": "Tutor Perini Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPET": {
        "symbol": "TPET",
        "name": "Trio Petroleum Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPH": {
        "symbol": "TPH",
        "name": "Tri Pointe Homes, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPHD": {
        "symbol": "TPHD",
        "name": "Timothy Plan High Dividend Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPHE": {
        "symbol": "TPHE",
        "name": "TIMOTHY PLAN HIGH DIVIDEND STOCK ENHANCED ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPIF": {
        "symbol": "TPIF",
        "name": "Timothy Plan International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPL": {
        "symbol": "TPL",
        "name": "Texas Pacific Land Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPLC": {
        "symbol": "TPLC",
        "name": "Timothy Plan US Large/Mid Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPLE": {
        "symbol": "TPLE",
        "name": "TIMOTHY PLAN US LARGE/MID CAP CORE ENHANCED ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPMN": {
        "symbol": "TPMN",
        "name": "Timothy Plan Market Neutral ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPOR": {
        "symbol": "TPOR",
        "name": "Direxion Daily Transportation Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPR": {
        "symbol": "TPR",
        "name": "Tapestry, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPSC": {
        "symbol": "TPSC",
        "name": "Timothy Plan US Small Cap Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPTA": {
        "symbol": "TPTA",
        "name": "Terra Property Trust, Inc. 6.00% Notes due 2026",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPVG": {
        "symbol": "TPVG",
        "name": "TRIPLEPOINT VENTURE GROWTH BDC CORP.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPX": {
        "symbol": "TPX",
        "name": "TEMPUR SEALY INTERNATIONAL, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPYP": {
        "symbol": "TPYP",
        "name": "Tortoise North American Pipeline ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TPZ": {
        "symbol": "TPZ",
        "name": "Tortoise Power and Energy Infrastructure Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TQQQ": {
        "symbol": "TQQQ",
        "name": "ProShares  UltraPro QQQ",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TR": {
        "symbol": "TR",
        "name": "Tootsie Roll Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRAK": {
        "symbol": "TRAK",
        "name": "ReposiTrak, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRC": {
        "symbol": "TRC",
        "name": "Tejon Ranch Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TREX": {
        "symbol": "TREX",
        "name": "Trex Company, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRFK": {
        "symbol": "TRFK",
        "name": "Pacer Data and Digital Revolution ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRFM": {
        "symbol": "TRFM",
        "name": "AAM Transformers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRGP": {
        "symbol": "TRGP",
        "name": "Targa Resources Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRI": {
        "symbol": "TRI",
        "name": "Thomson Reuters Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRN": {
        "symbol": "TRN",
        "name": "Trinity Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRND": {
        "symbol": "TRND",
        "name": "Pacer Trendpilot Fund of Funds ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRNO": {
        "symbol": "TRNO",
        "name": "Terreno Realty Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TROX": {
        "symbol": "TROX",
        "name": "TRONOX LIMITED CL A ORDINARY SHARES",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRP": {
        "symbol": "TRP",
        "name": "TC Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRSY": {
        "symbol": "TRSY",
        "name": "Xtrackers US 0-1 Year Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRT": {
        "symbol": "TRT",
        "name": "Trio-Tech International",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTN PRA": {
        "symbol": "TRTN PRA",
        "name": "Triton International Limited 8.50% Series A Cumulative Redeemable Perpetual Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTN PRB": {
        "symbol": "TRTN PRB",
        "name": "Triton International Limited 8.00% Series B Cumulative Redeemable Perpetual Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTN PRC": {
        "symbol": "TRTN PRC",
        "name": "Triton International Limited 7.375% Series C Cumulative Redeemable Perpetual Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTN PRD": {
        "symbol": "TRTN PRD",
        "name": "Triton International Limited 6.875% Series D Cumulative Redeemable Perpetual Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTN PRE": {
        "symbol": "TRTN PRE",
        "name": "Triton International Limited 5.75% Series E Cumulative Redeemable Perpetual Preference Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTX": {
        "symbol": "TRTX",
        "name": "TPG RE Finance Trust, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTX PRC": {
        "symbol": "TRTX PRC",
        "name": "TPG RE Finance Trust, Inc. 6.25% Series C Cumulative Redeemable Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRTY": {
        "symbol": "TRTY",
        "name": "Cambria Trinity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRU": {
        "symbol": "TRU",
        "name": "TransUnion",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRV": {
        "symbol": "TRV",
        "name": "The Travelers Companies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TRX": {
        "symbol": "TRX",
        "name": "TRX Gold Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TS": {
        "symbol": "TS",
        "name": "Tenaris S. A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSDD": {
        "symbol": "TSDD",
        "name": "GraniteShares ETF Trust GraniteShares 2x Short TSLA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSE": {
        "symbol": "TSE",
        "name": "Trinseo PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSEC": {
        "symbol": "TSEC",
        "name": "Touchstone Securitized Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSEP": {
        "symbol": "TSEP",
        "name": "FT Vest Emerging Market Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSI": {
        "symbol": "TSI",
        "name": "TCW Strategic Income Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSL": {
        "symbol": "TSL",
        "name": "GraniteShares 1.25x Long TSLA  Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLL": {
        "symbol": "TSLL",
        "name": "Direxion Shares ETF Trust Direxion Daily TSLA Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLP": {
        "symbol": "TSLP",
        "name": "Kurv Yield Premium Strategy Tesla (TSLA) ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLQ": {
        "symbol": "TSLQ",
        "name": "Investment Managers Series Trust II Tradr 2X Short TSLA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLR": {
        "symbol": "TSLR",
        "name": "GraniteShares ETF Trust GraniteShares 2x Long TSLA Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLS": {
        "symbol": "TSLS",
        "name": "Direxion Daily TSLA Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLT": {
        "symbol": "TSLT",
        "name": "T-REX 2X Long Tesla Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLW": {
        "symbol": "TSLW",
        "name": "Tradr 1.5X Long TSLA Weekly ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLX": {
        "symbol": "TSLX",
        "name": "Sixth Street Specialty Lending, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLY": {
        "symbol": "TSLY",
        "name": "YieldMax TSLA Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSLZ": {
        "symbol": "TSLZ",
        "name": "T-Rex 2X Inverse Tesla Daily Target ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSM": {
        "symbol": "TSM",
        "name": "Taiwan Semiconductor Manufacturing Company Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSME": {
        "symbol": "TSME",
        "name": "Thrivent Small-Mid Cap ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSMX": {
        "symbol": "TSMX",
        "name": "Direxion Daily TSM Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSMY": {
        "symbol": "TSMY",
        "name": "YieldMax TSM Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSMZ": {
        "symbol": "TSMZ",
        "name": "Direxion Daily TSM Bear 1X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSN": {
        "symbol": "TSN",
        "name": "Tyson Foods, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSPA": {
        "symbol": "TSPA",
        "name": "T. Rowe Price U.S. Equity Research ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSPY": {
        "symbol": "TSPY",
        "name": "TappAlpha SPY Growth & Daily Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TSQ": {
        "symbol": "TSQ",
        "name": "TOWNSQUARE MEDIA, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TT": {
        "symbol": "TT",
        "name": "Trane Technologies plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTAC": {
        "symbol": "TTAC",
        "name": "FCF US Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTAI": {
        "symbol": "TTAI",
        "name": "FCF International Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTC": {
        "symbol": "TTC",
        "name": "Toro Company (The)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTE": {
        "symbol": "TTE",
        "name": "TotalEnergies SE",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTI": {
        "symbol": "TTI",
        "name": "TETRA Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTP": {
        "symbol": "TTP",
        "name": "TORTOISE PIPELINE & ENERGY FUND, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TTT": {
        "symbol": "TTT",
        "name": "ProShares UltraPro Short 20+ Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TU": {
        "symbol": "TU",
        "name": "Telus Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUA": {
        "symbol": "TUA",
        "name": "Simplify Short Term Treasury Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUG": {
        "symbol": "TUG",
        "name": "STF Tactical Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUGN": {
        "symbol": "TUGN",
        "name": "STF Tactical Growth & Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUR": {
        "symbol": "TUR",
        "name": "iShares MSCI Turkey ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUSI": {
        "symbol": "TUSI",
        "name": "Touchstone Ultra Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TUYA": {
        "symbol": "TUYA",
        "name": "Tuya Inc. American Depositary Shares, each representing one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TV": {
        "symbol": "TV",
        "name": "Grupo Televisa S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TVAL": {
        "symbol": "TVAL",
        "name": "T. Rowe Price Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TVC": {
        "symbol": "TVC",
        "name": "Tennessee Valley Authority Power Bonds 1998 Series D due June 1, 2028",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TVE": {
        "symbol": "TVE",
        "name": "Tennessee Valley Authority Power Bonds 1999 Series A due May 1, 2029",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWI": {
        "symbol": "TWI",
        "name": "Titan International, Inc.(Delaware)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWIO": {
        "symbol": "TWIO",
        "name": "Trajan Wealth Income Opportunities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWLO": {
        "symbol": "TWLO",
        "name": "Twilio Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWM": {
        "symbol": "TWM",
        "name": "ProShares UltraShort Russell2000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWN": {
        "symbol": "TWN",
        "name": "Taiwan Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWO": {
        "symbol": "TWO",
        "name": "Two Harbors Investment Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWO PRA": {
        "symbol": "TWO PRA",
        "name": "Two Harbors Investment Corp 8.125% Series A Fixed-to-Floating Rate Cumulative Redeemable Preferred S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWO PRB": {
        "symbol": "TWO PRB",
        "name": "Two Harbors Investment Corp 7.625% Series B Fixed-to-Floating Rate Cumulative Redeemable Preferred S",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TWO PRC": {
        "symbol": "TWO PRC",
        "name": "Two Harbors Investment Corp 7.25% Series C Fixed-to-Floating Rate Cumulative Redeemable Preferred St",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TX": {
        "symbol": "TX",
        "name": "Ternium S.A. American Depositary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TXNM": {
        "symbol": "TXNM",
        "name": "TXNM Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TXO": {
        "symbol": "TXO",
        "name": "TXO Partners, L.P.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TXS": {
        "symbol": "TXS",
        "name": "Texas Capital Texas Equity Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TXSS": {
        "symbol": "TXSS",
        "name": "Texas Capital Texas Small Cap Equity Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TXT": {
        "symbol": "TXT",
        "name": "Textron, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TY": {
        "symbol": "TY",
        "name": "TRI-Continental Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TY PR": {
        "symbol": "TY PR",
        "name": "Tri-Continental Corporation $2.50 Cumulative Preferred",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYA": {
        "symbol": "TYA",
        "name": "Simplify Intermediate Term Treasury Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYD": {
        "symbol": "TYD",
        "name": "Direxion Daily 7-10 Year Treasury Bull 3X Shares (based on the NYSE 7-10 Year Treasury Bond Index; s",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYG": {
        "symbol": "TYG",
        "name": "Tortoise Energy Infrastructure Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYL": {
        "symbol": "TYL",
        "name": "Tyler Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYLD": {
        "symbol": "TYLD",
        "name": "Cambria Tactical Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYLG": {
        "symbol": "TYLG",
        "name": "Global X Information Technology Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TYO": {
        "symbol": "TYO",
        "name": "Direxion Daily 7-10 Year Treasury Bear 3X Shares (based on the NYSE 7-10 Year Treasury Bond Index; s",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "TZA": {
        "symbol": "TZA",
        "name": "Direxion Daily Small Cap Bear 3x Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "U": {
        "symbol": "U",
        "name": "Unity Software Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UA": {
        "symbol": "UA",
        "name": "Under Armour, Inc. Class C Common Stock, $0.0003 1/3 par value",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAA": {
        "symbol": "UAA",
        "name": "Under Armour, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAE": {
        "symbol": "UAE",
        "name": "iShares Trust iShares MSCI UAE ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAMY": {
        "symbol": "UAMY",
        "name": "United States Antimony Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAN": {
        "symbol": "UAN",
        "name": "CVR Partners, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAPR": {
        "symbol": "UAPR",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAUG": {
        "symbol": "UAUG",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UAVS": {
        "symbol": "UAVS",
        "name": "AgEagle Aerial Systems, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBER": {
        "symbol": "UBER",
        "name": "Uber Technologies, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBND": {
        "symbol": "UBND",
        "name": "Victory Portfolios II VictoryShares Core Plus Intermediate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBOT": {
        "symbol": "UBOT",
        "name": "Direxion Daily Robotics, Artificial Intelligence & Automation Index Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBR": {
        "symbol": "UBR",
        "name": "ProShares Ultra MSCI Brazil Capped",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBRL": {
        "symbol": "UBRL",
        "name": "GraniteShares 2x Long UBER Daily ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBS": {
        "symbol": "UBS",
        "name": "UBS Group AG",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UBT": {
        "symbol": "UBT",
        "name": "ProShares Ultra 20+ Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCB": {
        "symbol": "UCB",
        "name": "United Community Banks, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCB PRI": {
        "symbol": "UCB PRI",
        "name": "United Community Banks, Inc. Depositary Shares, each representing 1/1000th interest in a share of Se",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCC": {
        "symbol": "UCC",
        "name": "ProShares Ultra Consumer Discretionary",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCIB": {
        "symbol": "UCIB",
        "name": "ETRACS UBS Bloomberg Constant Maturity Commodity Index (CMCI) Total Return ETN Series B due April 5,",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCO": {
        "symbol": "UCO",
        "name": "ProShares Ultra Bloomberg Crude Oil",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCON": {
        "symbol": "UCON",
        "name": "First Trust TCW Unconstrained Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCRD": {
        "symbol": "UCRD",
        "name": "Victory Portfolios II VictoryShares Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UCYB": {
        "symbol": "UCYB",
        "name": "ProShares Ultra Nasdaq Cybersecurity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDEC": {
        "symbol": "UDEC",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDI": {
        "symbol": "UDI",
        "name": "USCF Dividend Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDIV": {
        "symbol": "UDIV",
        "name": "Franklin U.S. Core Dividend Tilt Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDN": {
        "symbol": "UDN",
        "name": "Invesco DB US Dollar Index Bearish Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDOW": {
        "symbol": "UDOW",
        "name": "ProShares UltraPro Dow 30",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UDR": {
        "symbol": "UDR",
        "name": "UDR, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UE": {
        "symbol": "UE",
        "name": "UBRAN EDGE PROPERTIES",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UEC": {
        "symbol": "UEC",
        "name": "Uranium Energy Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UEVM": {
        "symbol": "UEVM",
        "name": "VictoryShares Emerging Markets Value Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UFEB": {
        "symbol": "UFEB",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UFI": {
        "symbol": "UFI",
        "name": "UNIFI, Inc. New",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UFIV": {
        "symbol": "UFIV",
        "name": "US Treasury 5 Year Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UFO": {
        "symbol": "UFO",
        "name": "Procure Space ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UGA": {
        "symbol": "UGA",
        "name": "United States Gasoline Fund, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UGE": {
        "symbol": "UGE",
        "name": "ProShares Ultra Consumer Staples",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UGI": {
        "symbol": "UGI",
        "name": "UGI Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UGL": {
        "symbol": "UGL",
        "name": "ProShares Ultra Gold",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UGP": {
        "symbol": "UGP",
        "name": "Ultrapar Participacoes S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UHAL": {
        "symbol": "UHAL",
        "name": "U-Haul Holding Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UHAL B": {
        "symbol": "UHAL B",
        "name": "U-Haul Holding Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UHS": {
        "symbol": "UHS",
        "name": "Universal Health Services, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UHT": {
        "symbol": "UHT",
        "name": "Universal Health Realty Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UI": {
        "symbol": "UI",
        "name": "Ubiquiti Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UIS": {
        "symbol": "UIS",
        "name": "Unisys Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UITB": {
        "symbol": "UITB",
        "name": "Victory Portfolios II VictoryShares Core Intermediate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UIVM": {
        "symbol": "UIVM",
        "name": "VictoryShares International Value Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UJAN": {
        "symbol": "UJAN",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UJB": {
        "symbol": "UJB",
        "name": "ProShares Ultra High Yield",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UJUL": {
        "symbol": "UJUL",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UJUN": {
        "symbol": "UJUN",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UL": {
        "symbol": "UL",
        "name": "Unilever plc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ULE": {
        "symbol": "ULE",
        "name": "ProShares Ultra Euro",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ULS": {
        "symbol": "ULS",
        "name": "UL Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ULST": {
        "symbol": "ULST",
        "name": "SPDR SSgA Ultra Short Term Bond ETF of SSgA Active Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ULTY": {
        "symbol": "ULTY",
        "name": "YieldMax Ultra Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ULVM": {
        "symbol": "ULVM",
        "name": "VictoryShares US Value Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMAC": {
        "symbol": "UMAC",
        "name": "Unusual Machines, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMAR": {
        "symbol": "UMAR",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMAY": {
        "symbol": "UMAY",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMC": {
        "symbol": "UMC",
        "name": "United Microelectronic Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMDD": {
        "symbol": "UMDD",
        "name": "ProShares UltraPro MidCap400",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMH": {
        "symbol": "UMH",
        "name": "UMH Properties, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMH PRD": {
        "symbol": "UMH PRD",
        "name": "UMH Properties, Inc. 6.375% Series D Cumulative Redeemable Preferred Stock, Liquidation Preference $",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMI": {
        "symbol": "UMI",
        "name": "USCF Midstream Energy Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UMMA": {
        "symbol": "UMMA",
        "name": "Wahed Dow Jones Islamic World ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNF": {
        "symbol": "UNF",
        "name": "Unifirst Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNFI": {
        "symbol": "UNFI",
        "name": "United Natural Foods Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNG": {
        "symbol": "UNG",
        "name": "United States Natural Gas Fund, LP Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNH": {
        "symbol": "UNH",
        "name": "UNITEDHEALTH GROUP INCORPORATED (Delaware)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNIY": {
        "symbol": "UNIY",
        "name": "WisdomTree Voya Yield Enhanced USD Universal Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNL": {
        "symbol": "UNL",
        "name": "United States 12 Month Natural Gas Fund, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNM": {
        "symbol": "UNM",
        "name": "Unum Group",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNMA": {
        "symbol": "UNMA",
        "name": "Unum Group 6.250% Junior Subordinated Notes due 2058",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNOV": {
        "symbol": "UNOV",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UNP": {
        "symbol": "UNP",
        "name": "Union Pacific Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UOCT": {
        "symbol": "UOCT",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UP": {
        "symbol": "UP",
        "name": "Wheels Up Experience Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPAR": {
        "symbol": "UPAR",
        "name": "UPAR Ultra Risk Parity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPGD": {
        "symbol": "UPGD",
        "name": "Invesco Bloomberg Analyst Rating Improvers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPGR": {
        "symbol": "UPGR",
        "name": "Xtrackers US Green Infrastructure Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPRO": {
        "symbol": "UPRO",
        "name": "ProShares UltraPro S&P 500",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPS": {
        "symbol": "UPS",
        "name": "United Parcel Service, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPV": {
        "symbol": "UPV",
        "name": "ProShares Ultra FTSE Europe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UPW": {
        "symbol": "UPW",
        "name": "ProShares Ultra Utilities",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URA": {
        "symbol": "URA",
        "name": "Global X Uranium ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URAA": {
        "symbol": "URAA",
        "name": "Direxion Daily Uranium Industry Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URAN": {
        "symbol": "URAN",
        "name": "Themes Uranium & Nuclear ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URAX": {
        "symbol": "URAX",
        "name": "Defiance Daily Target 2x Long Uranium ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URE": {
        "symbol": "URE",
        "name": "ProShares Ultra Real Estate",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URG": {
        "symbol": "URG",
        "name": "Ur-Energy Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URI": {
        "symbol": "URI",
        "name": "United Rentals, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URNJ": {
        "symbol": "URNJ",
        "name": "Sprott Junior Uranium Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URNM": {
        "symbol": "URNM",
        "name": "Sprott Uranium Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URTH": {
        "symbol": "URTH",
        "name": "iShares MSCI World ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "URTY": {
        "symbol": "URTY",
        "name": "ProShares UltraPro Russell2000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USA": {
        "symbol": "USA",
        "name": "Liberty All-Star Equity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USAC": {
        "symbol": "USAC",
        "name": "USA COMPRESSION PARTNERS LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USAI": {
        "symbol": "USAI",
        "name": "Pacer American Energy Independence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USAS": {
        "symbol": "USAS",
        "name": "Americas Gold and Silver Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB": {
        "symbol": "USB",
        "name": "U.S. Bancorp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRA": {
        "symbol": "USB PRA",
        "name": "U.S. Bancorp Depositary Shares (each representing a 1/100th interest in a share of Series A Non-Cumu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRH": {
        "symbol": "USB PRH",
        "name": "US Bancorp Depositary Shares (1/1,000 Interest in Ser. B Non-Cum. Prptl. Prfrd. Stock)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRP": {
        "symbol": "USB PRP",
        "name": "U.S. Bancorp Depositary Shares each representing a 1/1,000th interest in a share of Series K Non-Cum",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRQ": {
        "symbol": "USB PRQ",
        "name": "U.S. Bancorp Depositary Shares, Each Representing a 1/1, 000th Interest in a Share of Series L Non-",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRR": {
        "symbol": "USB PRR",
        "name": "U.S. Bancorp Depositary Shares, Each Representing a 1/1, 000th Interest in a Share of Series M Non-",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USB PRS": {
        "symbol": "USB PRS",
        "name": "U.S. Bancorp Depositary Shares, each representing a 1/1, 000th interest in a share of Series O Non-",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USCA": {
        "symbol": "USCA",
        "name": "Xtrackers MSCI USA Climate Action Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USCF": {
        "symbol": "USCF",
        "name": "Themes US Cash Flow Champions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USCI": {
        "symbol": "USCI",
        "name": "US Commodity Index",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USCL": {
        "symbol": "USCL",
        "name": "iShares Climate Conscious & Transition MSCI USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USD": {
        "symbol": "USD",
        "name": "ProShares Ultra Semiconductors",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USDU": {
        "symbol": "USDU",
        "name": "WisdomTree Bloomberg U.S. Dollar Bullish Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USDX": {
        "symbol": "USDX",
        "name": "SGI Enhanced Core ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USE": {
        "symbol": "USE",
        "name": "USCF Energy Commodity Strategy Absolute Return Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USEP": {
        "symbol": "USEP",
        "name": "Innovator U.S. Equity Ultra Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USFD": {
        "symbol": "USFD",
        "name": "US Foods Holding Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USFI": {
        "symbol": "USFI",
        "name": "BrandywineGLOBAL - U.S. Fixed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USFR": {
        "symbol": "USFR",
        "name": "WisdomTree Floating Rate Treasury Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USG": {
        "symbol": "USG",
        "name": "USCF GOLD STRATEGY PLUS INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USHY": {
        "symbol": "USHY",
        "name": "iShares Broad USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USIG": {
        "symbol": "USIG",
        "name": "iShares Broad USD Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USIN": {
        "symbol": "USIN",
        "name": "WisdomTree 7-10 Year Laddered Treasury Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USL": {
        "symbol": "USL",
        "name": "United States 12 Month Oil Fund, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USM": {
        "symbol": "USM",
        "name": "United States Cellular Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USMC": {
        "symbol": "USMC",
        "name": "Principal Exchange-Traded Funds Principal U.S. Mega-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USMF": {
        "symbol": "USMF",
        "name": "WisdomTree U.S. Multifactor Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USML": {
        "symbol": "USML",
        "name": "ETRACS 2x Leveraged MSCI US Minimum Volatility Factor TR ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USMV": {
        "symbol": "USMV",
        "name": "iShares MSCI USA Min Vol Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USNA": {
        "symbol": "USNA",
        "name": "USANA Health Sciences Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USNZ": {
        "symbol": "USNZ",
        "name": "Xtrackers Net Zero Pathway Paris Aligned US Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USO": {
        "symbol": "USO",
        "name": "United States Oil Fund, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USOI": {
        "symbol": "USOI",
        "name": "UBS AG ETRACS Crude Oil Shares Covered Call ETNs due April 24, 2037",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USOY": {
        "symbol": "USOY",
        "name": "Defiance Oil Enhanced Options Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USPH": {
        "symbol": "USPH",
        "name": "US Physical Therapy Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USPX": {
        "symbol": "USPX",
        "name": "Franklin U.S. Equity Index ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USRD": {
        "symbol": "USRD",
        "name": "Themes US R&D Champions ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USRT": {
        "symbol": "USRT",
        "name": "iShares Core U.S. REIT ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USSE": {
        "symbol": "USSE",
        "name": "Segall Bryant & Hamill Select Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USSG": {
        "symbol": "USSG",
        "name": "Xtrackers MSCI USA ESG Leaders Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USSH": {
        "symbol": "USSH",
        "name": "WisdomTree 1-3 Year Laddered Treasury Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UST": {
        "symbol": "UST",
        "name": "ProShares Ultra 7-10 Year Treasury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USTB": {
        "symbol": "USTB",
        "name": "Victory Portfolios II VictoryShares Short-Term Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USVM": {
        "symbol": "USVM",
        "name": "VictoryShares US Small Mid Cap Value Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USVN": {
        "symbol": "USVN",
        "name": "US Treasury 7 Year Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "USXF": {
        "symbol": "USXF",
        "name": "iShares ESG Advanced MSCI USA ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTEN": {
        "symbol": "UTEN",
        "name": "US Treasury 10 Year Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTES": {
        "symbol": "UTES",
        "name": "Virtus Reaves Utilities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTF": {
        "symbol": "UTF",
        "name": "Cohen & Steers  Infrastructure Fund, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTG": {
        "symbol": "UTG",
        "name": "Reaves Utility Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTHY": {
        "symbol": "UTHY",
        "name": "US Treasury 30 Year Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTI": {
        "symbol": "UTI",
        "name": "Universal Technical Institute, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTL": {
        "symbol": "UTL",
        "name": "Unitil Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTRE": {
        "symbol": "UTRE",
        "name": "US Treasury 3 Year Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTRN": {
        "symbol": "UTRN",
        "name": "Vesper US Large Cap Short-Term Reversal Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTSL": {
        "symbol": "UTSL",
        "name": "Direxion Daily Utilities Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTWO": {
        "symbol": "UTWO",
        "name": "US Treasury 2 Year Note ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTWY": {
        "symbol": "UTWY",
        "name": "US Treasury 20 Year Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UTZ": {
        "symbol": "UTZ",
        "name": "Utz Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UUP": {
        "symbol": "UUP",
        "name": "Invesco DB US Dollar Index Bullish Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UUU": {
        "symbol": "UUU",
        "name": "Universal Security Instruments, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UUUU": {
        "symbol": "UUUU",
        "name": "Energy Fuels Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UVE": {
        "symbol": "UVE",
        "name": "UNIVERSAL INSURANCE HLDG, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UVIX": {
        "symbol": "UVIX",
        "name": "2x Long VIX Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UVV": {
        "symbol": "UVV",
        "name": "Universal Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UVXY": {
        "symbol": "UVXY",
        "name": "ProShares Ultra VIX Short-Term Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UWM": {
        "symbol": "UWM",
        "name": "ProShares Ultra Russell2000",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UWMC": {
        "symbol": "UWMC",
        "name": "UWM Holdings Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UWMC WS": {
        "symbol": "UWMC WS",
        "name": "UWM Holdings Corporation Warrants, each exercisable for one share of Class A Common Stock at an exer",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UXI": {
        "symbol": "UXI",
        "name": "ProShares Ultra Industrials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UYG": {
        "symbol": "UYG",
        "name": "ProShares Ultra Financials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UYLD": {
        "symbol": "UYLD",
        "name": "Angel Oak UltraShort Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UYM": {
        "symbol": "UYM",
        "name": "ProShares Ultra Materials",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UZD": {
        "symbol": "UZD",
        "name": "United States Cellular Corporation 6.250% Senior Notes due 2069",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UZE": {
        "symbol": "UZE",
        "name": "United States Cellular Corporation 5.500% Senior Notes due 2070",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "UZF": {
        "symbol": "UZF",
        "name": "United States Cellular Corporation 5.500% Senior Notes due 2070",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "V": {
        "symbol": "V",
        "name": "VISA Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VABS": {
        "symbol": "VABS",
        "name": "Virtus Newfleet ABS/MBS ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VAC": {
        "symbol": "VAC",
        "name": "MARRIOTT VACATIONS WORLDWIDE CORPORATION",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VACH": {
        "symbol": "VACH",
        "name": "Voyager Acquisition Corp Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VACHW": {
        "symbol": "VACHW",
        "name": "Voyager Acquisition Corp Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VAL": {
        "symbol": "VAL",
        "name": "Valaris Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VAL WS": {
        "symbol": "VAL WS",
        "name": "Valaris Limited Warrants to purchase Common Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VALE": {
        "symbol": "VALE",
        "name": "VALE S.A.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VALQ": {
        "symbol": "VALQ",
        "name": "American Century U.S. Quality Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VAMO": {
        "symbol": "VAMO",
        "name": "Cambria Value and Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VATE": {
        "symbol": "VATE",
        "name": "INNOVATE Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VAW": {
        "symbol": "VAW",
        "name": "Vanguard Materials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VB": {
        "symbol": "VB",
        "name": "Vanguard Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VBF": {
        "symbol": "VBF",
        "name": "INVESCO  BOND FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VBK": {
        "symbol": "VBK",
        "name": "Vanguard Small-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VBND": {
        "symbol": "VBND",
        "name": "Vident U.S. Bond Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VBR": {
        "symbol": "VBR",
        "name": "Vanguard Small-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCAR": {
        "symbol": "VCAR",
        "name": "Simplify Volt RoboCar Disruption and Tech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCEB": {
        "symbol": "VCEB",
        "name": "Vanguard ESG U.S. Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCIT": {
        "symbol": "VCIT",
        "name": "Vanguard Intermediate-Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCLN": {
        "symbol": "VCLN",
        "name": "Virtus Duff & Phelps Clean Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCLT": {
        "symbol": "VCLT",
        "name": "Vanguard Long-Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCR": {
        "symbol": "VCR",
        "name": "Vanguard Consumer Discretionary ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCRB": {
        "symbol": "VCRB",
        "name": "Vanguard Core Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCSH": {
        "symbol": "VCSH",
        "name": "Vanguard Short-Term Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VCV": {
        "symbol": "VCV",
        "name": "INVESCO  CALIFORNIA VALUE MUNI INCOME TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VDC": {
        "symbol": "VDC",
        "name": "Vanguard Consumer Staples ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VDE": {
        "symbol": "VDE",
        "name": "Vanguard Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEA": {
        "symbol": "VEA",
        "name": "Vanguard FTSE Developed Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEEV": {
        "symbol": "VEEV",
        "name": "Veeva Systems Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEGA": {
        "symbol": "VEGA",
        "name": "AdvisorShares STAR Global Buy-Write ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEGI": {
        "symbol": "VEGI",
        "name": "iShares MSCI Agriculture Producers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEGN": {
        "symbol": "VEGN",
        "name": "US Vegan Climate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEL": {
        "symbol": "VEL",
        "name": "Velocity Financial, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEMY": {
        "symbol": "VEMY",
        "name": "Virtus Stone Harbor Emerging Markets High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VERS": {
        "symbol": "VERS",
        "name": "ProShares Metaverse ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VET": {
        "symbol": "VET",
        "name": "VERMILION ENERGY INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VETZ": {
        "symbol": "VETZ",
        "name": "Academy Veteran Impact ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VEU": {
        "symbol": "VEU",
        "name": "Vanguard FTSE All-World Ex-US ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFC": {
        "symbol": "VFC",
        "name": "V.F. Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFH": {
        "symbol": "VFH",
        "name": "Vanguard Financials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFL": {
        "symbol": "VFL",
        "name": "abrdn National Municipal Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFLO": {
        "symbol": "VFLO",
        "name": "VictoryShares Free Cash Flow ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFMF": {
        "symbol": "VFMF",
        "name": "Vanguard U.S. Multifactor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFMO": {
        "symbol": "VFMO",
        "name": "Vanguard U.S. Momentum Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFMV": {
        "symbol": "VFMV",
        "name": "Vanguard U.S. Minimum Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFQY": {
        "symbol": "VFQY",
        "name": "Vanguard U.S. Quality Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VFVA": {
        "symbol": "VFVA",
        "name": "Vanguard U.S. Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGI": {
        "symbol": "VGI",
        "name": "VIRTUS GLOBAL MULTI-SECTOR INCOME FUND",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGIT": {
        "symbol": "VGIT",
        "name": "Vanguard Intermediate-Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGK": {
        "symbol": "VGK",
        "name": "Vanguard FTSE Europe ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGLT": {
        "symbol": "VGLT",
        "name": "Vanguard Long-Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGM": {
        "symbol": "VGM",
        "name": "Invesco  Trust Investment Grade Municipals",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGSH": {
        "symbol": "VGSH",
        "name": "Vanguard Short-Term Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGSR": {
        "symbol": "VGSR",
        "name": "Vert Global Sustainable Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGT": {
        "symbol": "VGT",
        "name": "Vanguard Information Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VGZ": {
        "symbol": "VGZ",
        "name": "Vista Gold Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VHC": {
        "symbol": "VHC",
        "name": "VirnetX Holding Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VHI": {
        "symbol": "VHI",
        "name": "Valhi, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VHT": {
        "symbol": "VHT",
        "name": "Vanguard Health Care ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VICE": {
        "symbol": "VICE",
        "name": "AdvisorShares Vice ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VICI": {
        "symbol": "VICI",
        "name": "VICI Properties Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIDI": {
        "symbol": "VIDI",
        "name": "Vident International Equity Strategy",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIG": {
        "symbol": "VIG",
        "name": "Vanguard Dividend Appreciation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIGI": {
        "symbol": "VIGI",
        "name": "Vanguard International Dividend Appreciation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIK": {
        "symbol": "VIK",
        "name": "Viking Holdings Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VINE": {
        "symbol": "VINE",
        "name": "Fresh Vine Wine, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIOG": {
        "symbol": "VIOG",
        "name": "Vanguard S&P Small-Cap 600 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIOO": {
        "symbol": "VIOO",
        "name": "Vanguard S&P Small-Cap 600 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIOV": {
        "symbol": "VIOV",
        "name": "Vanguard S&P Small-Cap 600 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIPS": {
        "symbol": "VIPS",
        "name": "Vipshop Holdings Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIRS": {
        "symbol": "VIRS",
        "name": "Pacer BioThreat Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIS": {
        "symbol": "VIS",
        "name": "Vanguard Industrials ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIST": {
        "symbol": "VIST",
        "name": "Vista Energy S.A.B. de C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIV": {
        "symbol": "VIV",
        "name": "Telefonica Brasil, S.A. American Depositary Shares (Each representing One Common Share)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIXM": {
        "symbol": "VIXM",
        "name": "ProShares VIX Mid-Term Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VIXY": {
        "symbol": "VIXY",
        "name": "ProShares VIX Short-Term Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VKI": {
        "symbol": "VKI",
        "name": "Invesco Advantage Municipal Income Trust II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VKQ": {
        "symbol": "VKQ",
        "name": "Invesco Municipal Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLLU": {
        "symbol": "VLLU",
        "name": "Harbor AlphaEdge Large Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLN": {
        "symbol": "VLN",
        "name": "Valens Semiconductor Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLN WS": {
        "symbol": "VLN WS",
        "name": "Valens Semiconductor Ltd. Warrants, each warrant to purchase one-half of one Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLO": {
        "symbol": "VLO",
        "name": "Valero Energy Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLRS": {
        "symbol": "VLRS",
        "name": "CONTROLADORA VUELA COMPA\u00c2\u00a5IA DE AVIACION, S.A.B. DE C.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLT": {
        "symbol": "VLT",
        "name": "Invesco  High Income Trust II",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLTO": {
        "symbol": "VLTO",
        "name": "Veralto Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLU": {
        "symbol": "VLU",
        "name": "SPDR S&P 1500 Value Tilt ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VLUE": {
        "symbol": "VLUE",
        "name": "iShares MSCI USA Value Factor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMAX": {
        "symbol": "VMAX",
        "name": "Hartford US Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMBS": {
        "symbol": "VMBS",
        "name": "Vanguard Mortgage-Backed Securities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMC": {
        "symbol": "VMC",
        "name": "Vulcan Materials Company(Holding Company)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMCA": {
        "symbol": "VMCA",
        "name": "Valuence Merger Corp. I Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMCAU": {
        "symbol": "VMCAU",
        "name": "Valuence Merger Corp. I Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMCAW": {
        "symbol": "VMCAW",
        "name": "Valuence Merger Corp. I Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMI": {
        "symbol": "VMI",
        "name": "Valmont Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMO": {
        "symbol": "VMO",
        "name": "Invesco Municipal Opportunity Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VMOT": {
        "symbol": "VMOT",
        "name": "Alpha Architect Value Momentum Trend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNAM": {
        "symbol": "VNAM",
        "name": "Global X MSCI Vietnam ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNCE": {
        "symbol": "VNCE",
        "name": "VINCE HOLDING CORP.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNLA": {
        "symbol": "VNLA",
        "name": "Janus Henderson Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNM": {
        "symbol": "VNM",
        "name": "VanEck Vietnam ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNO": {
        "symbol": "VNO",
        "name": "Vornado Realty Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNO PRL": {
        "symbol": "VNO PRL",
        "name": "VORNADO REALTY TRUST 5.40% Series L Cumulative Redeemable Preferred Shares of Beneficial Interest, l",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNO PRM": {
        "symbol": "VNO PRM",
        "name": "Vornado Realty Trust 5.25% Series M Cumulative Redeemable Preferred Shares of Beneficial Interest, l",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNO PRN": {
        "symbol": "VNO PRN",
        "name": "Vornado Realty Trust 5.25% Series N Cumulative Redeemable Preferred Shares of Beneficial Interest, l",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNO PRO": {
        "symbol": "VNO PRO",
        "name": "Vornado Realty Trust 4.45% Series O Cumulative Redeemable Preferred Shares, Liquidation Preference $",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNQ": {
        "symbol": "VNQ",
        "name": "Vanguard Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNQI": {
        "symbol": "VNQI",
        "name": "Vanguard Global ex-U.S. Real Estate ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNRX": {
        "symbol": "VNRX",
        "name": "VolitionRX Limited Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNSE": {
        "symbol": "VNSE",
        "name": "Natixis Vaughan Nelson Select ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VNT": {
        "symbol": "VNT",
        "name": "Vontier Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VO": {
        "symbol": "VO",
        "name": "Vanguard Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOC": {
        "symbol": "VOC",
        "name": "VOC ENERGY TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOE": {
        "symbol": "VOE",
        "name": "Vanguard Mid-Cap Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VONE": {
        "symbol": "VONE",
        "name": "Vanguard Russell 1000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VONG": {
        "symbol": "VONG",
        "name": "Vanguard Russell 1000 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VONV": {
        "symbol": "VONV",
        "name": "Vanguard Russell 1000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOO": {
        "symbol": "VOO",
        "name": "Vanguard S&P 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOOG": {
        "symbol": "VOOG",
        "name": "Vanguard S&P 500 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOOV": {
        "symbol": "VOOV",
        "name": "Vanguard S&P 500 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOT": {
        "symbol": "VOT",
        "name": "Vanguard Mid-Cap Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOTE": {
        "symbol": "VOTE",
        "name": "TCW Transform 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOX": {
        "symbol": "VOX",
        "name": "Vanguard Communication Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOYA": {
        "symbol": "VOYA",
        "name": "VOYA FINANCIAL, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VOYA PRB": {
        "symbol": "VOYA PRB",
        "name": "Voya Financial, Inc. Depositary Shares, each representing a 1/40th interest in a share of 5.35% Fixe",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPC": {
        "symbol": "VPC",
        "name": "Virtus Private Credit Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPG": {
        "symbol": "VPG",
        "name": "Vishay Precision Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPL": {
        "symbol": "VPL",
        "name": "Vanguard FTSE Pacific ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPLS": {
        "symbol": "VPLS",
        "name": "Vanguard Core Plus Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPU": {
        "symbol": "VPU",
        "name": "Vanguard Utilities ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VPV": {
        "symbol": "VPV",
        "name": "Invesco  Pennsylvania Value Municipal Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRAI": {
        "symbol": "VRAI",
        "name": "Virtus Real Asset Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRE": {
        "symbol": "VRE",
        "name": "Veris Residential, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRIG": {
        "symbol": "VRIG",
        "name": "Invesco Variable Rate Investment Grade ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRN": {
        "symbol": "VRN",
        "name": "Veren Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRP": {
        "symbol": "VRP",
        "name": "Invesco Variable Rate Preferred ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRT": {
        "symbol": "VRT",
        "name": "Vertiv Holdings Co Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VRTS": {
        "symbol": "VRTS",
        "name": "Virtus Investment Partners, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSAC": {
        "symbol": "VSAC",
        "name": "Vision Sensing Acquisition Corp. Class A Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSACU": {
        "symbol": "VSACU",
        "name": "Vision Sensing Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSACW": {
        "symbol": "VSACW",
        "name": "Vision Sensing Acquisition Corp. Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSCO": {
        "symbol": "VSCO",
        "name": "Victorias Secret & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSDA": {
        "symbol": "VSDA",
        "name": "VictoryShares Dividend Accelerator ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSGX": {
        "symbol": "VSGX",
        "name": "Vanguard ESG International Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSH": {
        "symbol": "VSH",
        "name": "Vishay Intertechnology, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSHY": {
        "symbol": "VSHY",
        "name": "Virtus Newfleet Short Duration High Yield Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSLU": {
        "symbol": "VSLU",
        "name": "Applied Finance Valuation Large Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSMV": {
        "symbol": "VSMV",
        "name": "VictoryShares US Multi-Factor Minimum Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSS": {
        "symbol": "VSS",
        "name": "Vanguard FTSE All-World EX-US Small-Cap Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VST": {
        "symbol": "VST",
        "name": "Vistra Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSTO": {
        "symbol": "VSTO",
        "name": "Vista Outdoor Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VSTS": {
        "symbol": "VSTS",
        "name": "Vestis Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VT": {
        "symbol": "VT",
        "name": "Vanguard Total World Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTAK": {
        "symbol": "VTAK",
        "name": "Catheter Precision, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTC": {
        "symbol": "VTC",
        "name": "Vanguard Total Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTEB": {
        "symbol": "VTEB",
        "name": "Vanguard Tax-Exempt Bond Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTEC": {
        "symbol": "VTEC",
        "name": "Vanguard California Tax-Exempt Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTEI": {
        "symbol": "VTEI",
        "name": "Vanguard Intermediate-Term Tax-Exempt Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTES": {
        "symbol": "VTES",
        "name": "Vanguard Short-Term Tax-Exempt Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTEX": {
        "symbol": "VTEX",
        "name": "VTEX",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTHR": {
        "symbol": "VTHR",
        "name": "Vanguard Russell 3000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTI": {
        "symbol": "VTI",
        "name": "Vanguard Total Stock Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTIP": {
        "symbol": "VTIP",
        "name": "Vanguard Short-Term Inflation-Protected Securities Index Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTLE": {
        "symbol": "VTLE",
        "name": "Vital Energy, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTMX": {
        "symbol": "VTMX",
        "name": "Corporacion Inmobiliaria Vesta, S.A.B de C.V., American Depositary Shares, each representing ten (10",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTN": {
        "symbol": "VTN",
        "name": "Invesco  Trust for Investment Grade New York Municipals",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTOL": {
        "symbol": "VTOL",
        "name": "Bristow Group Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTR": {
        "symbol": "VTR",
        "name": "Ventas, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTS": {
        "symbol": "VTS",
        "name": "Vitesse Energy, Inc..",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTV": {
        "symbol": "VTV",
        "name": "Vanguard Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTWG": {
        "symbol": "VTWG",
        "name": "Vanguard Russell 2000 Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTWO": {
        "symbol": "VTWO",
        "name": "Vanguard Russell 2000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VTWV": {
        "symbol": "VTWV",
        "name": "Vanguard Russell 2000 Value ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VUG": {
        "symbol": "VUG",
        "name": "Vanguard Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VUSB": {
        "symbol": "VUSB",
        "name": "Vanguard Ultra-Short Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VUSE": {
        "symbol": "VUSE",
        "name": "Vident U.S. Equity Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VV": {
        "symbol": "VV",
        "name": "Vanguard Large-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VVI": {
        "symbol": "VVI",
        "name": "Viad Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VVR": {
        "symbol": "VVR",
        "name": "Invesco  Senior Income Trust",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VVV": {
        "symbol": "VVV",
        "name": "Valvoline Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VVX": {
        "symbol": "VVX",
        "name": "V2X, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VWI": {
        "symbol": "VWI",
        "name": "Arch Indices VOI Absolute Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VWID": {
        "symbol": "VWID",
        "name": "Virtus WMC International Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VWO": {
        "symbol": "VWO",
        "name": "Vanguard FTSE Emerging Markets ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VWOB": {
        "symbol": "VWOB",
        "name": "Vanguard Emerging Markets Government Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VXF": {
        "symbol": "VXF",
        "name": "Vanguard Extended Market ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VXUS": {
        "symbol": "VXUS",
        "name": "Vanguard Total International Stock ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VXX": {
        "symbol": "VXX",
        "name": "iPath Series B S&P 500 VIX Short-Term Futures ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VXZ": {
        "symbol": "VXZ",
        "name": "iPath Series B S&P 500 VIX Mid-Term Futures ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VYM": {
        "symbol": "VYM",
        "name": "Vanguard High Dividend Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VYMI": {
        "symbol": "VYMI",
        "name": "Vanguard International High Dividend Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VYX": {
        "symbol": "VYX",
        "name": "NCR Voyix Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VZ": {
        "symbol": "VZ",
        "name": "Verizon Communications",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VZIO": {
        "symbol": "VZIO",
        "name": "VIZIO Holding Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "VZLA": {
        "symbol": "VZLA",
        "name": "Vizsla Silver Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "W": {
        "symbol": "W",
        "name": "Wayfair Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAB": {
        "symbol": "WAB",
        "name": "Wabtec Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WABF": {
        "symbol": "WABF",
        "name": "Western Asset Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAL": {
        "symbol": "WAL",
        "name": "Western Alliance Bancorporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAL PRA": {
        "symbol": "WAL PRA",
        "name": "Western Alliance Bancorporation Depositary Shares, Each Representing a 1/ 400th Interest in a Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WALDW": {
        "symbol": "WALDW",
        "name": "Waldencast plc Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WANT": {
        "symbol": "WANT",
        "name": "Direxion Daily Consumer Discretionary Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAT": {
        "symbol": "WAT",
        "name": "Waters Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAVSU": {
        "symbol": "WAVSU",
        "name": "Western Acquisition Ventures Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WAVSW": {
        "symbol": "WAVSW",
        "name": "Western Acquisition Ventures Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBAT": {
        "symbol": "WBAT",
        "name": "WisdomTree Battery Value Chain and Innovation Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBIF": {
        "symbol": "WBIF",
        "name": "WBI BullBear Value 3000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBIG": {
        "symbol": "WBIG",
        "name": "WBI BullBear Yield 3000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBIL": {
        "symbol": "WBIL",
        "name": "WBI BullBear Quality 3000 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBIY": {
        "symbol": "WBIY",
        "name": "WBI Power FactorTM High Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBND": {
        "symbol": "WBND",
        "name": "Western Asset Total Return ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBS": {
        "symbol": "WBS",
        "name": "Webster Financial Corporation Waterbury",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBS PRF": {
        "symbol": "WBS PRF",
        "name": "Webster Financial Corporation Depositary Shares, Each Representing 1/1,000th Interest in a Share of",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBS PRG": {
        "symbol": "WBS PRG",
        "name": "Webster Financial Corporation Depositary Shares, each representing a 1/40th interest in a share of 6",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBX": {
        "symbol": "WBX",
        "name": "Wallbox N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WBX WS": {
        "symbol": "WBX WS",
        "name": "Wallbox N.V. Warrants, each warrant to purchase one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCBR": {
        "symbol": "WCBR",
        "name": "WisdomTree Cybersecurity Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCC": {
        "symbol": "WCC",
        "name": "Wesco International Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCC PRA": {
        "symbol": "WCC PRA",
        "name": "WESCO International, Inc. Depositary Shares each representing 1/1,000th interest in a share of Serie",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCEO": {
        "symbol": "WCEO",
        "name": "Hypatia Women CEO ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCLD": {
        "symbol": "WCLD",
        "name": "WisdomTree Cloud Computing Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCME": {
        "symbol": "WCME",
        "name": "First Trust WCM Developing World Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCMI": {
        "symbol": "WCMI",
        "name": "First Trust WCM International Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCN": {
        "symbol": "WCN",
        "name": "Waste Connections, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WCT": {
        "symbol": "WCT",
        "name": "Wellchange Holdings Company Limited Ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WD": {
        "symbol": "WD",
        "name": "Walker & Dunlop, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDH": {
        "symbol": "WDH",
        "name": "Waterdrop Inc. American Depositary Shares (each representing the right to receive 10 Class A Ordinar",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDI": {
        "symbol": "WDI",
        "name": "Western Asset Diversified Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDIV": {
        "symbol": "WDIV",
        "name": "SPDR S&P Global Dividend ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDNA": {
        "symbol": "WDNA",
        "name": "WisdomTree BioRevolution Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDS": {
        "symbol": "WDS",
        "name": "Woodside Energy Group Limited American Depositary Shares, each representing one Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WDTE": {
        "symbol": "WDTE",
        "name": "Defiance S&P 500 Enhanced Options & 0DTE Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEA": {
        "symbol": "WEA",
        "name": "Western Asset Premier Bond Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEAT": {
        "symbol": "WEAT",
        "name": "Teucrium Wheat Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEAV": {
        "symbol": "WEAV",
        "name": "Weave Communications, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEBL": {
        "symbol": "WEBL",
        "name": "Direxion Daily Dow Jones Internet Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEBS": {
        "symbol": "WEBS",
        "name": "Direxion Daily Dow Jones Internet Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEC": {
        "symbol": "WEC",
        "name": "WEC Energy Group, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEED": {
        "symbol": "WEED",
        "name": "Roundhill Cannabis ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEEI": {
        "symbol": "WEEI",
        "name": "Westwood Salient Enhanced Energy Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEEL": {
        "symbol": "WEEL",
        "name": "Peerless Option Income Wheel ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEIX": {
        "symbol": "WEIX",
        "name": "Dynamic Short Short-Term Volatility Futures ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEL": {
        "symbol": "WEL",
        "name": "Integrated Wellness Acquisition Corp",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEL U": {
        "symbol": "WEL U",
        "name": "Integrated Wellness Acquisition Corp Units, each consisting of one Class A ordinary share and one-ha",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEL WS": {
        "symbol": "WEL WS",
        "name": "Integrated Wellness Acquisition Corp Redeemable Warrants",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WELL": {
        "symbol": "WELL",
        "name": "Welltower Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WES": {
        "symbol": "WES",
        "name": "Western Midstream Partners, LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WEX": {
        "symbol": "WEX",
        "name": "WEX Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WF": {
        "symbol": "WF",
        "name": "Woori Financial Group Inc. American Depositary Shares (each representing three (3) shares of Common",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC": {
        "symbol": "WFC",
        "name": "Wells Fargo & Co.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRA": {
        "symbol": "WFC PRA",
        "name": "Wells Fargo & Company Depositary Shares, each representing a 1/1, 000th interest in a share of Non-C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRC": {
        "symbol": "WFC PRC",
        "name": "Wells Fargo & Company Depositary Shares, each representing a 1/1, 000th interest in a share of Non-C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRD": {
        "symbol": "WFC PRD",
        "name": "Wells Fargo & Company Depositary Shares, each representing a 1/1, 000th interest in a share of Non-C",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRL": {
        "symbol": "WFC PRL",
        "name": "WELLS FARGO 7.5% NON CUMV PERP CONV",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRY": {
        "symbol": "WFC PRY",
        "name": "Wells Fargo & Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFC PRZ": {
        "symbol": "WFC PRZ",
        "name": "Wells Fargo & Company Depositary Shares, each representing a 1/1,000th interest in a share of Non-Cu",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFG": {
        "symbol": "WFG",
        "name": "West Fraser Timber Co. Ltd",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WFH": {
        "symbol": "WFH",
        "name": "Direxion Work From Home ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WGMI": {
        "symbol": "WGMI",
        "name": "Valkyrie Bitcoin Miners ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WGO": {
        "symbol": "WGO",
        "name": "Winnebago Industries, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WH": {
        "symbol": "WH",
        "name": "Wyndham Hotels & Resorts, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WHD": {
        "symbol": "WHD",
        "name": "Cactus, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WHG": {
        "symbol": "WHG",
        "name": "WESTWOOD HOLDINGS GROUP, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WHR": {
        "symbol": "WHR",
        "name": "Whirlpool Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WIA": {
        "symbol": "WIA",
        "name": "Western Asset Inflation-Linked Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WINC": {
        "symbol": "WINC",
        "name": "Western Asset Short Duration Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WINN": {
        "symbol": "WINN",
        "name": "Harbor Long-Term Growers ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WINVU": {
        "symbol": "WINVU",
        "name": "WinVest Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WINVW": {
        "symbol": "WINVW",
        "name": "WinVest Acquisition Corp. Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WIP": {
        "symbol": "WIP",
        "name": "SPDR FTSE International Government Inflation-Protected Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WISE": {
        "symbol": "WISE",
        "name": "Themes Generative Artificial Intelligence ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WIT": {
        "symbol": "WIT",
        "name": "Wipro Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WIW": {
        "symbol": "WIW",
        "name": "Western Asset Inflation-Linked Opportunities & Income Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WK": {
        "symbol": "WK",
        "name": "Workiva Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WKC": {
        "symbol": "WKC",
        "name": "World Kinect Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLDR": {
        "symbol": "WLDR",
        "name": "Affinity World Leaders Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLK": {
        "symbol": "WLK",
        "name": "Westlake Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLKP": {
        "symbol": "WLKP",
        "name": "WESTLAKE CHEMICAL PARTNERS LP",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLTG": {
        "symbol": "WLTG",
        "name": "WealthTrust DBS Long Term Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLY": {
        "symbol": "WLY",
        "name": "John Wiley & Sons, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WLYB": {
        "symbol": "WLYB",
        "name": "John Wiley & Sons, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WM": {
        "symbol": "WM",
        "name": "Waste Management, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WMB": {
        "symbol": "WMB",
        "name": "Williams Companies Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WMK": {
        "symbol": "WMK",
        "name": "Weis Markets, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WMS": {
        "symbol": "WMS",
        "name": "ADVANCED DRAINAGE SYSTEMS, INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WMT": {
        "symbol": "WMT",
        "name": "Walmart Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WNC": {
        "symbol": "WNC",
        "name": "Wabash National Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WNDY": {
        "symbol": "WNDY",
        "name": "Global X Wind Energy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WNS": {
        "symbol": "WNS",
        "name": "WNS (Holdings) Limited",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WOLF": {
        "symbol": "WOLF",
        "name": "Wolfspeed, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WOMN": {
        "symbol": "WOMN",
        "name": "Impact Shares YWCA Women's Empowerment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WOOD": {
        "symbol": "WOOD",
        "name": "iShares Global Timber & Forestry ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WOR": {
        "symbol": "WOR",
        "name": "Worthington Enterprises, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WOW": {
        "symbol": "WOW",
        "name": "WideOpenWest, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WPC": {
        "symbol": "WPC",
        "name": "W.P. Carey Inc. (REIT)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WPM": {
        "symbol": "WPM",
        "name": "Wheaton Precious Metals Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WPP": {
        "symbol": "WPP",
        "name": "WPP PLC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRB": {
        "symbol": "WRB",
        "name": "W.R. Berkley Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRB PRE": {
        "symbol": "WRB PRE",
        "name": "W.R. Berkley Corporation 5.70% Subordinated Debentures due 2058",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRB PRF": {
        "symbol": "WRB PRF",
        "name": "W.R. Berkley Corporation 5.10% Subordinated Debentures due 2059",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRB PRG": {
        "symbol": "WRB PRG",
        "name": "W.R. Berkley Corporation 4.25% Subordinated Debentures due 2060",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRB PRH": {
        "symbol": "WRB PRH",
        "name": "W.R. Berkley Corporation 4.125% Subordinated Debentures due 2061",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRBY": {
        "symbol": "WRBY",
        "name": "Warby Parker Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRN": {
        "symbol": "WRN",
        "name": "Western Copper and Gold Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WRND": {
        "symbol": "WRND",
        "name": "New York Life Investments ETF Trust NYLI Global Equity R&D Leaders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WS": {
        "symbol": "WS",
        "name": "Worthington Steel, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WSM": {
        "symbol": "WSM",
        "name": "Williams-Sonoma, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WSO": {
        "symbol": "WSO",
        "name": "Watsco, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WSO B": {
        "symbol": "WSO B",
        "name": "Watsco, Inc. Class B",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WSR": {
        "symbol": "WSR",
        "name": "Whitestone REIT",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WST": {
        "symbol": "WST",
        "name": "West Pharmaceutical Services, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WT": {
        "symbol": "WT",
        "name": "WisdomTree, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTAI": {
        "symbol": "WTAI",
        "name": "WisdomTree Artificial Intelligence and Innovation Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTBN": {
        "symbol": "WTBN",
        "name": "WisdomTree Bianco Total Return Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTI": {
        "symbol": "WTI",
        "name": "W&T Offshore, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTID": {
        "symbol": "WTID",
        "name": "MicroSectors Energy -3x Inverse Leveraged ETNs",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTIU": {
        "symbol": "WTIU",
        "name": "MicroSectors Energy 3x Leveraged ETNs",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTM": {
        "symbol": "WTM",
        "name": "White Mountains Insurance Group Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTMA": {
        "symbol": "WTMA",
        "name": "Welsbach Technology Metals Acquisition Corp. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTMAU": {
        "symbol": "WTMAU",
        "name": "Welsbach Technology Metals Acquisition Corp. Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTMF": {
        "symbol": "WTMF",
        "name": "WisdomTree Managed Futures Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTRE": {
        "symbol": "WTRE",
        "name": "WisdomTree New Economy Real Estate Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTRG": {
        "symbol": "WTRG",
        "name": "Essential Utilities, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTS": {
        "symbol": "WTS",
        "name": "Watts Water Technologies, Inc. Class A",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTTR": {
        "symbol": "WTTR",
        "name": "Select Water Solutions, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WTV": {
        "symbol": "WTV",
        "name": "WisdomTree U.S. Value Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WU": {
        "symbol": "WU",
        "name": "The Western Union Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WUCT": {
        "symbol": "WUCT",
        "name": "ETRACS Whitney US Critical Technologies ETN",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WUGI": {
        "symbol": "WUGI",
        "name": "AXS Esoterica NextG Economy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WUSA": {
        "symbol": "WUSA",
        "name": "Simplify Wolfe US Equity 150/50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WWJD": {
        "symbol": "WWJD",
        "name": "Inspire International ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WWR": {
        "symbol": "WWR",
        "name": "Westwater Resources, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WWW": {
        "symbol": "WWW",
        "name": "Wolverine World Wide, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WY": {
        "symbol": "WY",
        "name": "Weyerhaeuser Company",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "WYY": {
        "symbol": "WYY",
        "name": "WidePoint Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "X": {
        "symbol": "X",
        "name": "United States Steel Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XAIX": {
        "symbol": "XAIX",
        "name": "Xtrackers Artificial Intelligence and Big Data ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XAPR": {
        "symbol": "XAPR",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XAR": {
        "symbol": "XAR",
        "name": "SPDR S&P Aerospace & Defense ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XAUG": {
        "symbol": "XAUG",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XB": {
        "symbol": "XB",
        "name": "BondBloxx B-Rated USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBAP": {
        "symbol": "XBAP",
        "name": "Innovator U.S. Equity Accelerated 9 Buffer ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBB": {
        "symbol": "XBB",
        "name": "BondBloxx BB-Rated USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBI": {
        "symbol": "XBI",
        "name": "SPDR S&P Biotech ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBIL": {
        "symbol": "XBIL",
        "name": "US Treasury 6 Month Bill ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBJA": {
        "symbol": "XBJA",
        "name": "Innovator U.S. Equity Accelerated 9 Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBJL": {
        "symbol": "XBJL",
        "name": "Innovator U.S. Equity Accelerated 9 Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XBOC": {
        "symbol": "XBOC",
        "name": "Innovator U.S. Equity Accelerated 9 Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XC": {
        "symbol": "XC",
        "name": "WisdomTree Emerging Markets Ex-China Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XCCC": {
        "symbol": "XCCC",
        "name": "BondBloxx CCC-Rated USD High Yield Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XCEM": {
        "symbol": "XCEM",
        "name": "Columbia EM Core ex-China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XCLR": {
        "symbol": "XCLR",
        "name": "Global X S&P 500 Collar 95-110 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XCNY": {
        "symbol": "XCNY",
        "name": "SPDR S&P Emerging Markets ex-China ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XCOR": {
        "symbol": "XCOR",
        "name": "FundX ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDAP": {
        "symbol": "XDAP",
        "name": "Innovator U.S. Equity Accelerated ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDAT": {
        "symbol": "XDAT",
        "name": "Franklin Exponential Data ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDEC": {
        "symbol": "XDEC",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDJA": {
        "symbol": "XDJA",
        "name": "Innovator U.S. Equity Accelerated ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDJL": {
        "symbol": "XDJL",
        "name": "Innovator U.S. Equity Accelerated ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDOC": {
        "symbol": "XDOC",
        "name": "Innovator U.S. Equity Accelerated ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDQQ": {
        "symbol": "XDQQ",
        "name": "Innovator Growth Accelerated ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDSQ": {
        "symbol": "XDSQ",
        "name": "Innovator U.S. Equity Accelerated ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XDTE": {
        "symbol": "XDTE",
        "name": "Roundhill S&P 500 0DTE Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XEMD": {
        "symbol": "XEMD",
        "name": "BondBloxx JP Morgan USD Emerging Markets 1-10 Year Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XES": {
        "symbol": "XES",
        "name": "SPDR S&P Oil & Gas  Equipment & Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFEB": {
        "symbol": "XFEB",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - February",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFIV": {
        "symbol": "XFIV",
        "name": "BondBloxx Bloomberg Five Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF\u00c3\u00bf",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFIX": {
        "symbol": "XFIX",
        "name": "F/m Opportunistic Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFLT": {
        "symbol": "XFLT",
        "name": "XAI Octagon Floating Rate and Alternative Income Trust Common Shares of Beneficial Interest",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFLT PRA": {
        "symbol": "XFLT PRA",
        "name": "XAI Octagon Floating Rate & Alternative Income Trust 6.50% Series 2026 Term Preferred Shares, (Liqui",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XFLX": {
        "symbol": "XFLX",
        "name": "FundX Flexible ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHB": {
        "symbol": "XHB",
        "name": "SPDR S&P Homebuilders ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHE": {
        "symbol": "XHE",
        "name": "SPDR S&P Health Care Equipment ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHLF": {
        "symbol": "XHLF",
        "name": "BondBloxx Bloomberg Six Month Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHR": {
        "symbol": "XHR",
        "name": "Xenia Hotels & Resorts, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHS": {
        "symbol": "XHS",
        "name": "SPDR S&P Health Care Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYC": {
        "symbol": "XHYC",
        "name": "BondBloxx USD High Yield Bond Consumer Cyclicals Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYD": {
        "symbol": "XHYD",
        "name": "BondBloxx USD High Yield Bond Consumer Non-Cyclicals Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYE": {
        "symbol": "XHYE",
        "name": "BondBloxx USD High Yield Bond Energy Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYF": {
        "symbol": "XHYF",
        "name": "BondBloxx USD High Yield Bond Financial & REIT Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYH": {
        "symbol": "XHYH",
        "name": "BondBloxx USD High Yield Bond Healthcare Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYI": {
        "symbol": "XHYI",
        "name": "BondBloxx USD High Yield Bond Industrial Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XHYT": {
        "symbol": "XHYT",
        "name": "BondBloxx USD High Yield Bond Telecom, Media & Technology Sector ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XIDE": {
        "symbol": "XIDE",
        "name": "FT Vest U.S. Equity Buffer & Premium Income ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XIJN": {
        "symbol": "XIJN",
        "name": "FT Vest U.S. Equity Buffer & Premium Income ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XIMR": {
        "symbol": "XIMR",
        "name": "FT Vest U.S. Equity Buffer & Premium Income  ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XIN": {
        "symbol": "XIN",
        "name": "Xinyuan Real Estate Co. LTD. American Depositary Shares (each Representing twenty Common Shares)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XISE": {
        "symbol": "XISE",
        "name": "FT Vest U.S. Equity Buffer & Premium Income ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XITK": {
        "symbol": "XITK",
        "name": "SPDR FactSet Innovative Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XJAN": {
        "symbol": "XJAN",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XJH": {
        "symbol": "XJH",
        "name": "iShares ESG Screened S&P Mid-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XJR": {
        "symbol": "XJR",
        "name": "iShares ESG Screened S&P Small-Cap ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XJUL": {
        "symbol": "XJUL",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XJUN": {
        "symbol": "XJUN",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLB": {
        "symbol": "XLB",
        "name": "Materials Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLC": {
        "symbol": "XLC",
        "name": "The Communication Services Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLE": {
        "symbol": "XLE",
        "name": "Energy Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLF": {
        "symbol": "XLF",
        "name": "Financial Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLG": {
        "symbol": "XLG",
        "name": "Invesco S&P 500 Top 50 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLI": {
        "symbol": "XLI",
        "name": "Industrial Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLK": {
        "symbol": "XLK",
        "name": "Technology Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLP": {
        "symbol": "XLP",
        "name": "Consumers Staples Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLRE": {
        "symbol": "XLRE",
        "name": "Real Estate Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLSR": {
        "symbol": "XLSR",
        "name": "SPDR SSGA US Sector Rotation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLU": {
        "symbol": "XLU",
        "name": "Utilities Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLV": {
        "symbol": "XLV",
        "name": "Health Care Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XLY": {
        "symbol": "XLY",
        "name": "Consumer Discretionary Select Sector SPDR Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMAR": {
        "symbol": "XMAR",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMAY": {
        "symbol": "XMAY",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - May",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XME": {
        "symbol": "XME",
        "name": "SPDR S&P Metals & Mining ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMHQ": {
        "symbol": "XMHQ",
        "name": "Invesco S&P MidCap Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMLV": {
        "symbol": "XMLV",
        "name": "Invesco S&P MidCap Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMMO": {
        "symbol": "XMMO",
        "name": "Invesco S&P MidCap Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMPT": {
        "symbol": "XMPT",
        "name": "VanEck CEF Muni Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XMVM": {
        "symbol": "XMVM",
        "name": "Invesco S&P MidCap Value with Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XNAV": {
        "symbol": "XNAV",
        "name": "FundX Aggressive ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XNOV": {
        "symbol": "XNOV",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - November",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XNTK": {
        "symbol": "XNTK",
        "name": "SPDR NYSE Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XOCT": {
        "symbol": "XOCT",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XOM": {
        "symbol": "XOM",
        "name": "Exxon Mobil Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XOMO": {
        "symbol": "XOMO",
        "name": "YieldMax XOM Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XONE": {
        "symbol": "XONE",
        "name": "BondBloxx Bloomberg One Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XOP": {
        "symbol": "XOP",
        "name": "SPDR S&P Oil & Gas Exploration & Production ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XOVR": {
        "symbol": "XOVR",
        "name": "EntrepreneurShares Series Trust ERShares Private-Public Crossover ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPER": {
        "symbol": "XPER",
        "name": "Xperi Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPEV": {
        "symbol": "XPEV",
        "name": "XPeng Inc. American depositary shares, each representing two Class A ordinary shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPH": {
        "symbol": "XPH",
        "name": "SPDR S&P Pharmaceuticals ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPL": {
        "symbol": "XPL",
        "name": "Solitario Resources Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPND": {
        "symbol": "XPND",
        "name": "First Trust Expanded Technology ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPO": {
        "symbol": "XPO",
        "name": "XPO, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPOF": {
        "symbol": "XPOF",
        "name": "Xponential Fitness, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPP": {
        "symbol": "XPP",
        "name": "ProShares Ultra FTSE China 50 of PROSHARES TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XPRO": {
        "symbol": "XPRO",
        "name": "Expro Group Holdings N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XRLV": {
        "symbol": "XRLV",
        "name": "Invesco S&P 500 ex-Rate Sensitive Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XRLX": {
        "symbol": "XRLX",
        "name": "FundX Conservative ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XRMI": {
        "symbol": "XRMI",
        "name": "Global X S&P 500 Risk Managed Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XRT": {
        "symbol": "XRT",
        "name": "SPDR S&P Retail ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSD": {
        "symbol": "XSD",
        "name": "SPDR S&P Semiconductor ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSEP": {
        "symbol": "XSEP",
        "name": "FT Vest U.S. Equity Enhance & Moderate Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSHD": {
        "symbol": "XSHD",
        "name": "Invesco S&P SmallCap High Dividend Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSHQ": {
        "symbol": "XSHQ",
        "name": "Invesco S&P SmallCap Quality ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSLV": {
        "symbol": "XSLV",
        "name": "Invesco S&P SmallCap Low Volatility ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSMO": {
        "symbol": "XSMO",
        "name": "Invesco S&P SmallCap Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSOE": {
        "symbol": "XSOE",
        "name": "WisdomTree Emerging Markets ex-State-Owned Enterprises Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSVM": {
        "symbol": "XSVM",
        "name": "Invesco S&P SmallCap Value with Momentum ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSVN": {
        "symbol": "XSVN",
        "name": "BondBloxx Bloomberg Seven Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF\u00c3\u00bf",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XSW": {
        "symbol": "XSW",
        "name": "SPDR S&P Software & Services ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XT": {
        "symbol": "XT",
        "name": "iShares Exponential Technologies ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTAP": {
        "symbol": "XTAP",
        "name": "Innovator U.S. Equity Accelerated Plus ETF - April",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTEN": {
        "symbol": "XTEN",
        "name": "BondBloxx Bloomberg Ten Year Target Duration US Treasury ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTJA": {
        "symbol": "XTJA",
        "name": "Innovator U.S. Equity Accelerated Plus ETF - January",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTJL": {
        "symbol": "XTJL",
        "name": "Innovator U.S. Equity Accelerated Plus ETF - July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTL": {
        "symbol": "XTL",
        "name": "SPDR S&P Telecom ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTN": {
        "symbol": "XTN",
        "name": "SPDR S&P Transportation ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTNT": {
        "symbol": "XTNT",
        "name": "Xtant Medical Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTOC": {
        "symbol": "XTOC",
        "name": "Innovator U.S. Equity Accelerated Plus ETF - October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTR": {
        "symbol": "XTR",
        "name": "Global X S&P 500 Tail Risk ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTRE": {
        "symbol": "XTRE",
        "name": "BondBloxx Bloomberg Three Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF\u00c3\u00bf",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTWO": {
        "symbol": "XTWO",
        "name": "BondBloxx Bloomberg Two Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XTWY": {
        "symbol": "XTWY",
        "name": "BondBloxx Bloomberg Twenty Year Target Duration US\u00c3\u00bfTreasury\u00c3\u00bfETF\u00c3\u00bf",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XUSP": {
        "symbol": "XUSP",
        "name": "Innovator Uncapped Accelerated U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XVOL": {
        "symbol": "XVOL",
        "name": "Acruence Active Hedge U.S. Equity ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XVV": {
        "symbol": "XVV",
        "name": "iShares ESG Screened S&P 500 ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XXCH": {
        "symbol": "XXCH",
        "name": "Direxion Daily MSCI Emerging Markets ex China Bull 2X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XYF": {
        "symbol": "XYF",
        "name": "X Financial American Depositary Shares, each representing six Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XYL": {
        "symbol": "XYL",
        "name": "Xylem Inc",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XYLD": {
        "symbol": "XYLD",
        "name": "Global X S&P 500 Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XYLE": {
        "symbol": "XYLE",
        "name": "Global X S&P 500 ESG Covered Call ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "XYLG": {
        "symbol": "XYLG",
        "name": "Global X S&P 500 Covered Call & Growth ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YALA": {
        "symbol": "YALA",
        "name": "Yalla Group Limited American Depositary Shares, each representing one Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YALL": {
        "symbol": "YALL",
        "name": "God Bless America ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YANG": {
        "symbol": "YANG",
        "name": "Direxion Daily FTSE  China Bear 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YBIT": {
        "symbol": "YBIT",
        "name": "YieldMax Bitcoin Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YBTC": {
        "symbol": "YBTC",
        "name": "Roundhill Bitcoin Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YCBD": {
        "symbol": "YCBD",
        "name": "cbdMD, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YCBD PRA": {
        "symbol": "YCBD PRA",
        "name": "cbdMD, Inc. 8.0% Series A Cumulative Convertible Preferred Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YCL": {
        "symbol": "YCL",
        "name": "ProShares Ultra Yen",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YCS": {
        "symbol": "YCS",
        "name": "ProShares UltraShort Yen",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YDEC": {
        "symbol": "YDEC",
        "name": "FT Vest International Equity Moderate Buffer ETF - December",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YEAR": {
        "symbol": "YEAR",
        "name": "AB Ultra Short Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YELP": {
        "symbol": "YELP",
        "name": "YELP INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YETH": {
        "symbol": "YETH",
        "name": "Roundhill Ether Covered Call Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YETI": {
        "symbol": "YETI",
        "name": "YETI Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YEXT": {
        "symbol": "YEXT",
        "name": "Yext, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YINN": {
        "symbol": "YINN",
        "name": "Direxion Daily FTSE  China Bull 3X Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YJUN": {
        "symbol": "YJUN",
        "name": "FT Vest International Equity Moderate Buffer ETF - June",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YLD": {
        "symbol": "YLD",
        "name": "Principal Active High Yield ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YLDE": {
        "symbol": "YLDE",
        "name": "ClearBridge Dividend Strategy ESG ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YMAG": {
        "symbol": "YMAG",
        "name": "YieldMax Magnificent 7 Fund of Option Income ETFs",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YMAR": {
        "symbol": "YMAR",
        "name": "FT Vest International Equity Moderate Buffer ETF - March",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YMAX": {
        "symbol": "YMAX",
        "name": "YieldMax Universe Fund of Option Income ETFs",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YMM": {
        "symbol": "YMM",
        "name": "Full Truck Alliance Co. Ltd. American Depositary Shares (each representing 20 Class A Ordinary Share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YOLO": {
        "symbol": "YOLO",
        "name": "AdvisorShares Pure Cannabis ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YOTAR": {
        "symbol": "YOTAR",
        "name": "Yotta Acquisition Corporation Right",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YOTAU": {
        "symbol": "YOTAU",
        "name": "Yotta Acquisition Corporation Unit",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YOTAW": {
        "symbol": "YOTAW",
        "name": "Yotta Acquisition Corporation Warrant",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YOU": {
        "symbol": "YOU",
        "name": "Clear Secure, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YPF": {
        "symbol": "YPF",
        "name": "YPF Sociedad Anonima",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YQQQ": {
        "symbol": "YQQQ",
        "name": "YieldMax Short N100 Option Income Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YRD": {
        "symbol": "YRD",
        "name": "Yiren Digital Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YSEP": {
        "symbol": "YSEP",
        "name": "FT Vest International Equity Moderate Buffer ETF - September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YSG": {
        "symbol": "YSG",
        "name": "Yatsen Holding Limited American Depositary Shares, each representing twenty (20) Class A Ordinary Sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YUM": {
        "symbol": "YUM",
        "name": "Yum! Brands, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YUMC": {
        "symbol": "YUMC",
        "name": "Yum China Holdings, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YXI": {
        "symbol": "YXI",
        "name": "ProShares Short FTSE China 50 of PROSHARES TRUST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "YYY": {
        "symbol": "YYY",
        "name": "Amplify High Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZALT": {
        "symbol": "ZALT",
        "name": "Innovator U.S. Equity 10 Buffer ETF - Quarterly",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZAUG": {
        "symbol": "ZAUG",
        "name": "Innovator Equity Defined Protection ETF - 1 Yr August",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZAZZT": {
        "symbol": "ZAZZT",
        "name": "Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZBH": {
        "symbol": "ZBH",
        "name": "Zimmer Biomet Holdings, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZBZX": {
        "symbol": "ZBZX",
        "name": "BATS LISTED TEST STOCK FOR CTS CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZBZZT": {
        "symbol": "ZBZZT",
        "name": "Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZCZZT": {
        "symbol": "ZCZZT",
        "name": "Test Symbol",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZDGE": {
        "symbol": "ZDGE",
        "name": "Zedge, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZECP": {
        "symbol": "ZECP",
        "name": "Zacks Earnings Consistent Portfolio ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZENA": {
        "symbol": "ZENA",
        "name": "ZenaTech, Inc. Common Stock",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZEPP": {
        "symbol": "ZEPP",
        "name": "Zepp Health Corporation American depositary shares, each representing sixteen Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZETA": {
        "symbol": "ZETA",
        "name": "Zeta Global Holdings Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZEXIT": {
        "symbol": "ZEXIT",
        "name": "IEX TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZGN": {
        "symbol": "ZGN",
        "name": "Ermenegildo Zegna N.V.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZH": {
        "symbol": "ZH",
        "name": "Zhihu Inc. American Depositary Shares, each representing three (3) Class A Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZHDG": {
        "symbol": "ZHDG",
        "name": "Zega Buy & Hedge ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZIEXT": {
        "symbol": "ZIEXT",
        "name": "IEX TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZIG": {
        "symbol": "ZIG",
        "name": "The Acquirers Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZIM": {
        "symbol": "ZIM",
        "name": "ZIM Integrated Shipping Services Ltd.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZIP": {
        "symbol": "ZIP",
        "name": "ZipRecruiter, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZIVB": {
        "symbol": "ZIVB",
        "name": "-1x Short VIX Mid-Term Futures Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZJK": {
        "symbol": "ZJK",
        "name": "ZJK Industrial Co., Ltd. Ordinary Shares",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZJUL": {
        "symbol": "ZJUL",
        "name": "Innovtor Equity Defined Protection ETF - 1 Yr July",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZJZZT": {
        "symbol": "ZJZZT",
        "name": "OPRA TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZK": {
        "symbol": "ZK",
        "name": "ZEEKR Intelligent Technology Holding Limited American Depositary Shares (each representing ten (10)",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZKH": {
        "symbol": "ZKH",
        "name": "ZKH Group Limited American Depositary Shares, each representing thirty-five (35) Class A Ordinary Sh",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZOCT": {
        "symbol": "ZOCT",
        "name": "Innovator Equity Defined Protection ETF - 1 Yr October",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZOM": {
        "symbol": "ZOM",
        "name": "Zomedica Corp.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZONE": {
        "symbol": "ZONE",
        "name": "CleanCore Solutions Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZROZ": {
        "symbol": "ZROZ",
        "name": "PIMCO 25+ Year Zero Coupon U.S. Treasury Index Exchange-Traded Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZSB": {
        "symbol": "ZSB",
        "name": "USCF Sustainable Battery Metals Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZSC": {
        "symbol": "ZSC",
        "name": "USCF Sustainable Commodity Strategy Fund",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZSEP": {
        "symbol": "ZSEP",
        "name": "Innovator Equity Defined Protection ETF - 1 Yr September",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZSL": {
        "symbol": "ZSL",
        "name": "ProShares UltraShort Silver",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTAX": {
        "symbol": "ZTAX",
        "name": "X-Square Municipal Income ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTEN": {
        "symbol": "ZTEN",
        "name": "F/m 10-Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTEST": {
        "symbol": "ZTEST",
        "name": "BATS LISTED TEST STOCK FOR CTS CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTO": {
        "symbol": "ZTO",
        "name": "ZTO Express (Cayman) Inc. American Depositary Shares, each representing one Class A ordinary share",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTR": {
        "symbol": "ZTR",
        "name": "Virtus Total Return Fund Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTRE": {
        "symbol": "ZTRE",
        "name": "F/m 3-Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTS": {
        "symbol": "ZTS",
        "name": "ZOETIS INC.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZTWO": {
        "symbol": "ZTWO",
        "name": "F/m 2-Year Investment Grade Corporate Bond ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZUO": {
        "symbol": "ZUO",
        "name": "Zuora, Inc.",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZVIA": {
        "symbol": "ZVIA",
        "name": "Zevia PBC",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZVV": {
        "symbol": "ZVV",
        "name": "NYSE Arca Listed Test Stock For CTS CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZVZZC": {
        "symbol": "ZVZZC",
        "name": "NASDAQ TEST STOCK Nextshares Test Security",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZVZZT": {
        "symbol": "ZVZZT",
        "name": "SUPER Montage TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZWS": {
        "symbol": "ZWS",
        "name": "Zurn Elkay Water Solutions Corporation",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZWZZT": {
        "symbol": "ZWZZT",
        "name": "NASDAQ TEST STOCK",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZXIET": {
        "symbol": "ZXIET",
        "name": "IEX TEST SYMBOL",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZXZZT": {
        "symbol": "ZXZZT",
        "name": "SUPERMONTAGE TEST",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZZK": {
        "symbol": "ZZK",
        "name": "NYSE Arca Listed Test Stock For CTS CQS",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "ZZZ": {
        "symbol": "ZZZ",
        "name": "Cyber Hornet S&P 500 and Bitcoin 75/25 Strategy ETF",
        "country": "",
        "sector": "",
        "industry": ""
    },
    "BTC": {
        "symbol": "BTC",
        "name": "Bitcoin",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ETH": {
        "symbol": "ETH",
        "name": "Ethereum",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "BNB": {
        "symbol": "BNB",
        "name": "Binance Coin",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "XRP": {
        "symbol": "XRP",
        "name": "Ripple",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ADA": {
        "symbol": "ADA",
        "name": "Cardano",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "SOL": {
        "symbol": "SOL",
        "name": "Solana",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "DOGE": {
        "symbol": "DOGE",
        "name": "Dogecoin",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "DOT": {
        "symbol": "DOT",
        "name": "Polkadot",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "AVAX": {
        "symbol": "AVAX",
        "name": "Avalanche",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "SHIB": {
        "symbol": "SHIB",
        "name": "Shiba Inu",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "MATIC": {
        "symbol": "MATIC",
        "name": "Polygon",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "LTC": {
        "symbol": "LTC",
        "name": "Litecoin",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "UNI": {
        "symbol": "UNI",
        "name": "Uniswap",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "LINK": {
        "symbol": "LINK",
        "name": "Chainlink",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ATOM": {
        "symbol": "ATOM",
        "name": "Cosmos",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "XLM": {
        "symbol": "XLM",
        "name": "Stellar",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ETC": {
        "symbol": "ETC",
        "name": "Ethereum Classic",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ALGO": {
        "symbol": "ALGO",
        "name": "Algorand",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "XMR": {
        "symbol": "XMR",
        "name": "Monero",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "VET": {
        "symbol": "VET",
        "name": "VeChain",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "TRX": {
        "symbol": "TRX",
        "name": "TRON",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "ICP": {
        "symbol": "ICP",
        "name": "Internet Computer",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "FTM": {
        "symbol": "FTM",
        "name": "Fantom",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "AXS": {
        "symbol": "AXS",
        "name": "Axie Infinity",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "NEAR": {
        "symbol": "NEAR",
        "name": "NEAR Protocol",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "FIL": {
        "symbol": "FIL",
        "name": "Filecoin",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "EGLD": {
        "symbol": "EGLD",
        "name": "Elrond",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "THETA": {
        "symbol": "THETA",
        "name": "Theta Network",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "XTZ": {
        "symbol": "XTZ",
        "name": "Tezos",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "EOS": {
        "symbol": "EOS",
        "name": "EOS",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "KLAY": {
        "symbol": "KLAY",
        "name": "Klaytn",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "AAVE": {
        "symbol": "AAVE",
        "name": "Aave",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "QNT": {
        "symbol": "QNT",
        "name": "Quant",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "RUNE": {
        "symbol": "RUNE",
        "name": "THORChain",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    },
    "GRT": {
        "symbol": "GRT",
        "name": "The Graph",
        "country": "Global",
        "sector": "Cryptocurrency",
        "industry": "Blockchain"
    }
}