import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./ContactUs.css"
import { sendContact } from '../../api/be-client';
import social_1 from "../../assets/social_1.png";
import social_2 from "../../assets/social_2.png";
import toast from 'react-hot-toast';
import { appContext } from '../../App';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [error, setError] = useState('');
    const nav = useNavigate();
    const { userLogged, userName } = useContext(appContext);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if any required fields are empty
        if (!formData.name.trim() || !formData.email.trim() || !formData.message.trim()) {
            setError('Name, email and message are required');
            return;
        }

        // Convert form data into a readable string
        const contentString = `
Name: ${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}
Subject: ${formData.subject}
Message: ${formData.message}
        `.trim();

        if (userLogged) {
            contentString += `\n(from registered user: ${userName})`
        }

        const res = await sendContact(contentString, formData.email)
        if (res) {
            toast.success("Message Sent", {duration: 10000})
            // alert('Message sent');
            nav('/home');
        } else {
            setError('Failed to send message. Try again.');
        }
    };

    return (
        <div className='contact-container'>
            <h2 className='contact-title'> Have a question? Need assistance? </h2>
            <hr id='contact-hr'></hr>
            <div className='contact-inner-container'>

                <form onSubmit={handleSubmit} className="contact-form">
                    <p className='contact-form-title'> Get in touch with us, and we'll respond shortly. </p>
                    <input
                        className='contact-input'
                        placeholder='Name'
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <input
                        className='contact-input'
                        placeholder='Email'
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    <input
                        className='contact-input'
                        placeholder='Phone'
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                    />
                    <input
                        className='contact-input'
                        placeholder='Subject'
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                    />
                    <textarea
                        className='contact-input'
                        style={{ paddingTop: "20px" }}
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                    />
                    {error && <p className="error-message">{error}</p>}                    
                    <button type="submit" className="contact-btn">Send Message</button>
                </form>

                <div className='contact-inner-right'>
                    <div className='contact-inner-right1'>
                        <p>
                            support@buyorsell.com
                        </p>
                        <div className='contact-social'>
                            <img className='contact-social-img' src={social_1} alt="" />
                            <p>
                                @but_or_sell_stock
                            </p>
                        </div>
                        <div className='contact-social'>
                            <img src={social_2} className='contact-social-img' alt="" />
                            <p>
                                +972 73 786 4932
                            </p>
                        </div>
                        <p>
                            Monday - Friday: 9:00 AM - 5:00 PM (EST)
                        </p>
                        <br />
                        <br />
                        Kent Avenue 25
                        <br />
                        Brooklyn, NY 11249
                        <br />
                        United States
                    </div>
                    <div className='contact-inner-right2'>
                        If you are looking to integrate your trading system with our stock analysis platform, please contact us at:
                        <br />
                        <br />
                        support@stocks-analysis.com
                        <br />
                        <br />

                        Our team will assist you with API documentation and setup to meet your needs.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;