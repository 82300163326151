import React, { useContext, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../App";
import { GOOGLE_AUTH_CLIENT_ID } from "../../api/config";
import { getTokenByProviderCredential } from "../../api/authApi";

import videoSrc from '../../assets/loginVideo.mp4';
import image1 from "../../assets/login-1-1.png";
import image2 from "../../assets/login-1-2.png";
import image3 from "../../assets/login-1-3.png";
import { red } from "@mui/material/colors";

const clientId = GOOGLE_AUTH_CLIENT_ID;


const LoginInnerBox = ({ img, txt1, txt2 }) => {
  return <div className="inner-box-container">
    <img className='login-box-img' src={img} alt="" />
    <h5 className="login-box-title"> {txt1} </h5>
    <p className="login-box-text"> {txt2}</p>
  </div>
}

const Login = () => {
  const nav = useNavigate();

  const { setUserLogged, setUserName, userLogged } = useContext(appContext);

  useEffect(() => {
    if (userLogged) {
      nav("/home");
    }
  }, []);

  const onSuccess = async (response) => {
    // console.log("Login Success at FE side:", response);
    const userName = await getTokenByProviderCredential(
      response.credential,
      "google"
    );
    setUserName(userName);
    setUserLogged(true);
    nav("/home");
  };

  const onFailure = (response) => {
    console.log("Login Failed:", response);
  };

  return <div className="login-container">

    <div className="login-1-container">
      <video autoPlay loop muted className="login-1-video">
        <source src={videoSrc} type="video/mp4" />
      </video>

      <div className="login-inner-container">

        <h2 className='login-title'>
          Gain Real-Time Analysis and <br />Stock Market Data
        </h2>
        <p className="login-1-text1">
        At BUYORSELL, we help you make informed choices using real-time <br />
        Technical or fundamental  analysis for any U.S stock market listing or any cryptocurrency trading. <br />          
        </p>
        <div className="login-boxes-container">
          <LoginInnerBox img={image1} txt1="REAL-TIME INSIGHTS" txt2='Stay updated on market trends' />
          <LoginInnerBox img={image2} txt1="PERSONALIZED ANALYSIS" txt2='Receive tailored recommendations' />
          <LoginInnerBox img={image3} txt1="PORTFOLIO TRACKING" txt2='Manage and optimize your investments with ease' />
          
        </div>
        <h3 className="login-1-title2"> Sign up today and start investing smarter! </h3>
        <div className="lgoin-google-btn">
          <GoogleOAuthProvider clientId={clientId}>
            <div className="google-auth-container">
              <GoogleLogin
                clientId={clientId}
                onSuccess={onSuccess}
                onFailure={onFailure}
                buttonText="Continue with Google"
                style={{ color: "red" }}
              />
            </div>
          </GoogleOAuthProvider>
        </div>
        <p className="login-inner-footer">
          Get exclusive insights and data-driven analysis to make <br /> smarter investment decisions.
        </p>
      </div>
    </div>
  </div>
};

export default Login;