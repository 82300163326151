import React, { useContext, useState } from "react";
import "./SiteHeader.css";
import bosLogo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { appContext } from "../../App";
import { Modal } from "react-bootstrap";
import searchIcon from '../../assets/searchIcon.png';

const Header = ({ setShowSearchModal }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { userLogged, setUserLogged, userName } = useContext(appContext);
  const nav = useNavigate();

  const handleLogout = () => {
    setUserLogged(false);
    localStorage.removeItem("bos-token");
    nav("/login");
  };

  return (
    <>
      <div id="header-header">
        <p id="header-header-text">Make financial analysis easy with us</p>
      </div>

      <div className="header-container">
        <div className="header-left-container">
          <img onClick={() => nav("/home")} id="header-logo-img" src={bosLogo} alt="" />
          <div className="header-links only-pc">
            <div className="header-link" onClick={() => nav("/home")}>HOME</div>
            <div className="header-link" onClick={() => nav("/about")}>ABOUT</div>
            <div className="header-link" onClick={() => nav("/how-it-works")}>HOW IT WORKS</div>
            <div className="header-link" onClick={() => nav("/contact-us")}>CONTACT</div>
            <div className="header-link" onClick={() => nav("/faq")}>FAQ</div>
          </div>
        </div>
        <div className="only-pc header-right-container">
          <div className="relative">
            <img id='header-search-icon' src={searchIcon} alt="Search" />
            <input
              id="header-search"
              onChange={() => setShowSearchModal(true)}
              placeholder="Search for a stock..."
            />
          </div>
          {!userLogged && (
            <button className="header-link" onClick={() => nav("/login")}>Sign-In</button>
          )}
          {userLogged && (
            <>
              <span className="header-link logged-name"> {userName} </span>
              <button onClick={handleLogout} className="header-link">Logout</button>
            </>
          )}
          <button className="header-link" onClick={()=>{nav("/contact-us")}}>Have a question?</button>
        </div>
       
        <div className="only-mobile header-right-container-mobile">
        <span className="header-link logged-name"> {userName} </span>
          <span onClick={() => setShowSearchModal(true)}>
            <img id='header-search-icon' src={searchIcon} alt="Search" />            
          </span>
          <span onClick={() => setShowMobileMenu(true)}>&#x2630;</span>
        </div>
        <MobileMenu setShow={setShowMobileMenu} show={showMobileMenu} handleLogout={handleLogout} />
      </div>
    </>
  );
};

export default Header;

const MobileMenu = ({ show, setShow, handleLogout }) => {
  const nav = useNavigate();
  const { userLogged, userName } = useContext(appContext);

  const handleNavigation = (path) => {
    nav(path);
    setShow(false); // Close the modal after navigation
  };
  

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body className="header-mobile-menu-body">
        <div className="header-mobile-menu-item" onClick={() => handleNavigation("/home")}>
          HOME
        </div>
        <div className="header-mobile-menu-item" onClick={() => handleNavigation("/about")}>
          ABOUT
        </div>
        <div className="header-mobile-menu-item" onClick={() => handleNavigation("/how-it-works")}>
          HOW IT WORKS
        </div>
        <div className="header-mobile-menu-item" onClick={() => handleNavigation("/contact-us")}>
          CONTACT
        </div>
        <div className="header-mobile-menu-item" onClick={() => handleNavigation("/faq")}>
          FAQ
        </div>
        <br />
        <br />
        {!userLogged && (
          <button className="header-link" onClick={() => handleNavigation("/login")}>Sign-In</button>
        )}
        {userLogged && (
          <>
            <p className="header-link logged-name"> {userName} </p>
            <button onClick={handleLogout} className="header-link">Logout</button>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
