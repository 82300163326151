
export async function getCryptoPrices() {
    const CACHE_KEY = 'bos-crypto-prices';
    const CACHE_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
    // Check if cached data exists and is still valid
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        const now = new Date().getTime();

        if (now - timestamp < CACHE_DURATION) {
            // If cached data is still valid, return it
            return data;
        }
    }

    // If no valid cached data, fetch new data from the API
    const url = 'https://api.coingecko.com/api/v3/coins/markets';
    const params = new URLSearchParams({
        vs_currency: 'usd',
        order: 'market_cap_desc',
        per_page: 20,
        page: 1
    });

    try {
        const response = await fetch(`${url}?${params}`);
        
        if (response.ok) {
            const data = await response.json();

            // Save the new data to localStorage with a timestamp
            const cacheEntry = {
                timestamp: new Date().getTime(),
                data: data
            };
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheEntry));

            return data;
        } else {
            return `Error: ${response.status}`;
        }
    } catch (error) {
        return `Error: ${error.message}`;
    }
}